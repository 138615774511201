import React, { useState, useEffect } from 'react'
import ViewCardDesk from '../../../components/dashboard/ViewCardDesk'
import ViewTableDesk from '../../../components/dashboard/ViewTableDesk'
import ViewPieDesk from '../../../components/dashboard/ViewPieDesk'
import ViewBarDesk from '../../../components/dashboard/ViewBarDesk'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { ApiGet } from '../../../helper/API/ApiData'


const ProjectDashboard = () => {

  let startYear = 2021
  let currentYear = new Date().getFullYear()
  const yearOption = [...Array(currentYear - startYear + 1).keys()].map((e) => {
    return { label: (e + startYear).toString(), value: (e + startYear).toString() };
  })

  const [year, setyear] = useState({ label: currentYear.toString(), value: currentYear.toString() })

  const [task, setTask] = useState([])
  const [project, setProject] = useState([])
  const [projectByMonthYearWise, setProjectByMonthYearWise] = useState([])
  const [projectCountByStatus, setProjectCountByStatus] = useState([])

  const getTaskDashboardData = useSelector((state) => state.task_Dashboard.taskDashboard)

  useEffect(() => {
    ApiGet('project/get_project_dashboard?projectType=Project')
      .then((res) => {
        setProject(res.data.map((ele) => {
          return {
            ProjectName: ele.partyName,
            ProjectManager: ele.projectManager.name
          }
        }))
      })

    ApiGet('project/get_project_count_by_status?projectType=Project')
      .then((res) => {
        let tempProjectCount = [['Label', 'Count']];
        res.data.map((ele) => {
          tempProjectCount.push([ele.label, ele.count])
        })
        setProjectCountByStatus(tempProjectCount)
      })

  }, [])

  useEffect(() => {
    ApiGet(`project/get_project_count_by_month_year_wise?year=${year.value}&projectType=Project`)
      .then((res) => {
        let tempProject = [['Month', 'Count']];
        res.data.map((ele) => {
          tempProject.push([ele.month, ele.count])
        })
        setProjectByMonthYearWise(tempProject)
      })
  }, [year])
  

  useEffect(() => {
    if (getTaskDashboardData && getTaskDashboardData.data) {
      setTask(getTaskDashboardData.data.map((ele) => {
        return {
          'Project Name': ele.projectName,
          'Subject': ele.subject,
          'Status': ele.status,
        }
      }))
    }
  }, [getTaskDashboardData])

  return (
    <div className="p-0 m-0">
      {/* <div className='col-6'>
              <ViewCardDesk
                data={{
                  content: 'Grand Payable',
                  count: <CurrencyFormat value={totalPurchaseAmount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />,
                  color: '#198754'
                }}
              />
            </div> */}

      {/* <div className="col-6">
              <ViewCardDesk
                data={{
                  content: 'Grand Receivable',
                  count: <CurrencyFormat value={totalSaleAmount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />,
                  color: '#dc3545',
                  // icon: faCoffee,
                }}
              />
            </div> */}
      <Row>
        <Col lg={6} md={6}>
          <Col lg={12}>
            {projectCountByStatus.length > 0 && <ViewPieDesk data={projectCountByStatus} title="Project Status Summary" />}
          </Col>
        </Col>

        <Col lg={6} md={6}>
          <Col lg={12}>
            {projectByMonthYearWise.length > 0 && <ViewBarDesk
              data={projectByMonthYearWise}
              title="Project Summary"
              range='Year'
              rangeOptions={yearOption}
              initialRangeVal={year}
              RangeValMethod={setyear}
            />}
          </Col>
        </Col>

        <Col lg={6} md={6}>
          <Col lg={12} className="mt-4">
            {project.length > 0 && <ViewTableDesk data={project} title={"Recent Project"} link="/project"/>}
          </Col>
        </Col>

        <Col lg={6} md={6}>
          <Col lg={12} className="mt-4">
            {task.length > 0 && <ViewTableDesk data={task} title={"Recent Task"} link="/task"/>}
          </Col>
        </Col>

      </Row>
    </div>
  )
}

export default ProjectDashboard