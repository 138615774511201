import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_ASSETS, ADD_ASSETS_ERROR, ADD_ASSETS_LOADING, DELETE_ASSETS, DELETE_ASSETS_ERROR, DELETE_ASSETS_LOADING, EDIT_ASSETS, EDIT_ASSETS_ERROR, EDIT_ASSETS_LOADING, GET_ALL_ASSETS, GET_ALL_ASSETS_ERROR, GET_ALL_ASSETS_LOADING, GET_ALL_ASSETS_NON_PROJECT, GET_ALL_ASSETS_NON_PROJECT_ERROR, GET_ALL_ASSETS_NON_PROJECT_LOADING, GET_ASSETS_BY_ID, GET_ASSETS_BY_ID_ERROR, GET_ASSETS_BY_ID_LOADING, GET_ASSETS_BY_PROJECT, GET_ASSETS_BY_PROJECT_ERROR, GET_ASSETS_BY_PROJECT_LOADING, IS_LOADING } from "../type"

export const addAssetsAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_ASSETS_LOADING,
            payload: true
        })
        await ApiPost('assets/add_assets', body)
            .then((res) => {
                dispatch({
                    type: ADD_ASSETS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: ADD_ASSETS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_ASSETS_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_ASSETS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAssetsByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ASSETS_BY_ID_LOADING,
            payload: true
        })
        await ApiGet(`assets/get_assets_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_ASSETS_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ASSETS_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ASSETS_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ASSETS_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAllAssetsAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_ASSETS_LOADING,
            payload: true
        })
        await ApiGet(`assets/get_all_assets`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_ASSETS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_ASSETS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_ASSETS_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_ASSETS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAllAssetsNonProjectAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_ASSETS_NON_PROJECT_LOADING,
            payload: true
        })
        await ApiGet(`assets/get_all_assets_non_project`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_ASSETS_NON_PROJECT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_ASSETS_NON_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_ASSETS_NON_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_ASSETS_NON_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAssetsByProjectAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ASSETS_BY_PROJECT_LOADING,
            payload: true
        })
        await ApiGet(`assets/get_assets_by_project/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_ASSETS_BY_PROJECT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ASSETS_BY_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ASSETS_BY_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ASSETS_BY_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const editAssetsAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_ASSETS_LOADING,
            payload: true
        })
        await ApiPut(`assets/edit_assets`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_ASSETS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: EDIT_ASSETS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_ASSETS_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_ASSETS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const deleteAssetsAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_ASSETS_LOADING,
            payload: true
        })
        await ApiDelete(`assets/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_ASSETS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: DELETE_ASSETS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_ASSETS_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_ASSETS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}