import { ADD_BILL_ENTRY, ADD_BILL_ENTRY_ERROR, ADD_BILL_ENTRY_LOADING, DELETE_BILL_ENTRY, DELETE_BILL_ENTRY_ERROR, DELETE_BILL_ENTRY_LOADING, EDIT_BILL_ENTRY, EDIT_BILL_ENTRY_ERROR, EDIT_BILL_ENTRY_LOADING, GET_ALL_BILL_ENTRY, GET_ALL_BILL_ENTRY_ERROR, GET_ALL_BILL_ENTRY_LOADING, GET_BILL_ENTRY_BY_ID, GET_BILL_ENTRY_BY_ID_ERROR, GET_BILL_ENTRY_BY_ID_LOADING } from "../type"

const getBillEntryByIdData = {
    loading:false,
    getBillEntryById : [],
    error:'',
};

const getAllBillEntryData = {
    loading:false,
    getAllBillEntry : [],
    error:'',
};

const addBillEntryData = {
    loading:false,
    addBillEntry : [],
    error:'',
};

const editBillEntryData = {
    loading:false,
    editBillEntry : [],
    error:'',
};

const deleteBillEntryData = {
    loading:false,
    deleteBillEntry : [],
    error:'',
};

export const getBillEntryByIdReducer = (state = getBillEntryByIdData, action) => {
    switch (action.type) {
        case GET_BILL_ENTRY_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_BILL_ENTRY_BY_ID:
            return {
                ...state,
                getBillEntryById: action.payload,
            };
        case GET_BILL_ENTRY_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllBillEntryReducer = (state = getAllBillEntryData, action) => {
    switch (action.type) {
        case GET_ALL_BILL_ENTRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_BILL_ENTRY:
            return {
                ...state,
                getAllBillEntry: action.payload,
            };
        case GET_ALL_BILL_ENTRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const addBillEntryReducer = (state = addBillEntryData, action) => {
    switch (action.type) {
        case ADD_BILL_ENTRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_BILL_ENTRY:
            return {
                ...state,
                addBillEntry: action.payload,
            };
        case ADD_BILL_ENTRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editBillEntryReducer = (state = editBillEntryData, action) => {
    switch (action.type) {
        case EDIT_BILL_ENTRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_BILL_ENTRY:
            return {
                ...state,
                editBillEntry: action.payload,
            };
        case EDIT_BILL_ENTRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteBillEntryReducer = (state = deleteBillEntryData, action) => {
    switch (action.type) {
        case DELETE_BILL_ENTRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_BILL_ENTRY:
            return {
                ...state,
                deleteBillEntry: action.payload,
            };
        case DELETE_BILL_ENTRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}