import { ADD_PRODUCT, ADD_PRODUCT_ERROR, ADD_PRODUCT_LOADING, DELETE_PRODUCT, DELETE_PRODUCT_ERROR, DELETE_PRODUCT_LOADING, EDIT_PRODUCT, EDIT_PRODUCT_ERROR, EDIT_PRODUCT_LOADING, GET_ALL_PRODUCT, GET_ALL_PRODUCT_ERROR, GET_ALL_PRODUCT_LOADING, GET_ALL_PRODUCT_VALUE, GET_ALL_PRODUCT_VALUE_ERROR, GET_ALL_PRODUCT_VALUE_LOADING, GET_PRODUCT_BY_FINAL_MATERIAL, GET_PRODUCT_BY_FINAL_MATERIAL_ERROR, GET_PRODUCT_BY_FINAL_MATERIAL_LOADING, GET_PRODUCT_BY_ID, GET_PRODUCT_BY_ID_ERROR, GET_PRODUCT_BY_ID_LOADING, GET_PRODUCT_DROPDOWN_DATA, GET_PRODUCT_DROPDOWN_DATA_ERROR, GET_PRODUCT_DROPDOWN_DATA_LOADING, GET_PRODUCT_LEDGER, GET_PRODUCT_LEDGER_ERROR, GET_PRODUCT_LEDGER_LOADING, IMPORT_FILE, IMPORT_FILE_ERROR, IMPORT_FILE_LOADING } from "../type"

const addProductData = {
    loading: false,
    addProduct: [],
    error: '',
};

const getAllProductData = {
    loading: false,
    getAllProduct: [],
    error: '',
};

const getAllProductValueData = {
    loading: false,
    getAllProductValue: [],
    error: '',
};


const getProductByIdData = {
    loading: false,
    getProductById: [],
    error: '',
}

const getProductDropDownData = {
    loading: false,
    getProductDropDown: [],
    error: '',
}

const importFileData = {
    loading: false,
    importFile: [],
    error: '',
}

const getProductByFinalMaterialData = {
    loading: false,
    getProductByFinalMaterial: [],
    error: '',
}

const getProductLedgerData = {
    loading: false,
    getProductLedger: [],
    error: '',
}
const deletePoductData = {
    loading: false,
    deletePoduct: [],
    error: '',
}
const editProductData = {
    loading: false,
    editPoduct: [],
    error: '',
}

export const addProductReducer = (state = addProductData, action) => {
    switch (action.type) {
        case ADD_PRODUCT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_PRODUCT:
            return {
                ...state,
                addProduct: action.payload,
            };
        case ADD_PRODUCT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllProductReducer = (state = getAllProductData, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_PRODUCT:
            return {
                ...state,
                getAllProduct: action.payload,
            };
        case GET_ALL_PRODUCT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProductByIdReducer = (state = getProductByIdData, action) => {
    switch (action.type) {
        case GET_PRODUCT_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PRODUCT_BY_ID:
            return {
                ...state,
                getProductById: action.payload,
            };
        case GET_PRODUCT_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProductDropDownReducer = (state = getProductDropDownData, action) => {
    switch (action.type) {
        case GET_PRODUCT_DROPDOWN_DATA_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PRODUCT_DROPDOWN_DATA:
            return {
                ...state,
                getProductDropDown: action.payload,
            };
        case GET_PRODUCT_DROPDOWN_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const importFileReducer = (state = importFileData, action) => {
    switch (action.type) {
        case IMPORT_FILE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case IMPORT_FILE:
            return {
                ...state,
                importFile: action.payload,
            };
        case IMPORT_FILE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProductByFinalMaterialReducer = (state = getProductByFinalMaterialData, action) => {
    switch (action.type) {
        case GET_PRODUCT_BY_FINAL_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PRODUCT_BY_FINAL_MATERIAL:
            return {
                ...state,
                getProductByFinalMaterial: action.payload,
            };
        case GET_PRODUCT_BY_FINAL_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProductLedgerReducer = (state = getProductLedgerData, action) => {
    switch (action.type) {
        case GET_PRODUCT_LEDGER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PRODUCT_LEDGER:
            return {
                ...state,
                getProductLedger: action.payload,
            };
        case GET_PRODUCT_LEDGER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
export const editProductReducer = (state = editProductData, action) => {
    switch (action.type) {
        case EDIT_PRODUCT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_PRODUCT:
            return {
                ...state,
                editProduct: action.payload,
            };
        case EDIT_PRODUCT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
export const deleteProductReducer = (state = deletePoductData, action) => {
    switch (action.type) {
        case DELETE_PRODUCT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_PRODUCT:
            return {
                ...state,
                deleteProduct: action.payload,
            };
        case DELETE_PRODUCT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllProductValueReducer = (state = getAllProductValueData, action) => {
    
    switch (action.type) {
        case GET_ALL_PRODUCT_VALUE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case GET_ALL_PRODUCT_VALUE:
            return {
                ...state,
                getAllProductValue: action.payload,
            };

        case GET_ALL_PRODUCT_VALUE_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}