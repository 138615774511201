import { ADD_USER, ADD_USER_ERROR, ADD_USER_LOADING, DELETE_USER, DELETE_USER_ERROR, DELETE_USER_LOADING, EDIT_USER, EDIT_USER_ERROR, EDIT_USER_LOADING, GET_ALL_USER, GET_ALL_USER_ERROR, GET_ALL_USER_LOADING, GET_PROJECT_MANAGER, GET_PROJECT_MANAGER_ERROR, GET_PROJECT_MANAGER_LOADING, GET_USER_BY_ID, GET_USER_BY_ID_ERROR, GET_USER_BY_ID_LOADING, LOGIN, LOGIN_ERROR, LOGIN_LOADING } from "../type"

const loginData = {
    loading:false,
    login : undefined,
    error:'',
};

const getUserByIdData = {
    loading:false,
    getUserById:[],
    error:'',
}

const getAllUserData = {
    loading:false,
    getAllUser:[],
    error:'',
}

const editUserData = {
    loading:false,
    editUser:[],
    error:'',
}

const addUserData = {
    loading:false,
    addUser:[],
    error:'',
}

const deleteUserData = {
    loading:false,
    deleteUser:[],
    error:'',
}

const getProjectManagerData = {
    loading:false,
    getProjectManager:[],
    error:'',
}

export const loginReducer = (state = loginData, action) => {
    switch (action.type) {
        case LOGIN_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case LOGIN:
            return {
                ...state,
                login: action.payload,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getUserByIdReducer = (state = getUserByIdData, action) => {
    switch (action.type) {
        case GET_USER_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_USER_BY_ID:
            return {
                ...state,
                getUserById: action.payload,
            };
        case GET_USER_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllUserReducer = (state = getAllUserData, action) => {
    switch (action.type) {
        case GET_ALL_USER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_USER:
            return {
                ...state,
                getAllUser: action.payload,
            };
        case GET_ALL_USER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editUserReducer = (state = editUserData, action) => {
    switch (action.type) {
        case EDIT_USER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_USER:
            return {
                ...state,
                editUser: action.payload,
            };
        case EDIT_USER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const addUserReducer = (state = addUserData, action) => {
    switch (action.type) {
        case ADD_USER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_USER:
            return {
                ...state,
                addUser: action.payload,
            };
        case ADD_USER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteUserReducer = (state = deleteUserData, action) => {
    switch (action.type) {
        case DELETE_USER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_USER:
            return {
                ...state,
                deleteUser: action.payload,
            };
        case DELETE_USER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProjectManagerReducer = (state = getProjectManagerData, action) => {
    switch (action.type) {
        case GET_PROJECT_MANAGER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PROJECT_MANAGER:
            return {
                ...state,
                getProjectManager: action.payload,
            };
        case GET_PROJECT_MANAGER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}