import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_CUSTOMER, ADD_CUSTOMER_ERROR, ADD_CUSTOMER_LOADING, DELETE_CUSTOMER, DELETE_CUSTOMER_ERROR, DELETE_CUSTOMER_LOADING, EDIT_CUSTOMER, EDIT_CUSTOMER_ERROR, EDIT_CUSTOMER_LOADING, GET_ALL_CUSTOMER, GET_ALL_CUSTOMER_ERROR, GET_ALL_CUSTOMER_LOADING, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_ERROR, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING, GET_CUSTOMER_BY_ID, GET_CUSTOMER_BY_ID_ERROR, GET_CUSTOMER_BY_ID_LOADING, GET_CUSTOMER_DROPDOWN, GET_CUSTOMER_DROPDOWN_ERROR, GET_CUSTOMER_DROPDOWN_LOADING, GET_SUPPLIER_DROPDOWN, GET_SUPPLIER_DROPDOWN_ERROR, GET_SUPPLIER_DROPDOWN_LOADING, IS_LOADING } from "../type"

export const addCustomerAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_CUSTOMER_LOADING,
            payload: true
        })
        await ApiPost('customer/add_customer', body)
            .then((res) => {
                dispatch({
                    type: ADD_CUSTOMER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: ADD_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_CUSTOMER_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAllCustomerAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_CUSTOMER_LOADING,
            payload: true
        })
        await ApiGet('customer/get_all_customers')
            .then((res) => {
                dispatch({
                    type: GET_ALL_CUSTOMER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_CUSTOMER_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}




export const getCustomerByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_CUSTOMER_BY_ID_LOADING,
            payload: true
        })
        await ApiGet(`customer/get_customer_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_CUSTOMER_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_CUSTOMER_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_CUSTOMER_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_CUSTOMER_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const editCustomerAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_CUSTOMER_LOADING,
            payload: true
        })
        await ApiPut('customer/edit_customer', body)
            .then((res) => {
                dispatch({
                    type: EDIT_CUSTOMER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: EDIT_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_CUSTOMER_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const deleteCustomerAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_CUSTOMER_LOADING,
            payload: true
        })
        await ApiDelete(`customer/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_CUSTOMER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: DELETE_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_CUSTOMER_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getCustomerDropDownAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_CUSTOMER_DROPDOWN_LOADING,
            payload: true
        })
        await ApiGet(`customer/get_customer_dropdown`)
            .then((res) => {
                dispatch({
                    type: GET_CUSTOMER_DROPDOWN,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_CUSTOMER_DROPDOWN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_CUSTOMER_DROPDOWN_ERROR,
            payload: err
        })

        dispatch({
            type: GET_CUSTOMER_DROPDOWN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getSupplierDropDownAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_SUPPLIER_DROPDOWN_LOADING,
            payload: true
        })
        await ApiGet(`customer/get_supplier_dropdown`)
            .then((res) => {
                dispatch({
                    type: GET_SUPPLIER_DROPDOWN,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_SUPPLIER_DROPDOWN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_SUPPLIER_DROPDOWN_ERROR,
            payload: err
        })

        dispatch({
            type: GET_SUPPLIER_DROPDOWN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}