import React, { useEffect } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import Datepickericon from '../../assets/image/date-picker-icon.png'

const ProductLeadger = ({ show, onHide, productId }) => {
    
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                centered
                size="xl"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Project Leadger</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                                <div className="users-label ">
                                    <label>Start Date</label>
                                    <div className="position-relative select-icon user-type">
                                        <ReactDatePicker id='Remarkdate' dateFormat="dd/MM/yyyy" selectsRange={true} placeholderText="Select start Date" autoComplete='off' />
                                    </div>
                                    <img src={Datepickericon} alt="" className='datepickericon' />
                                </div>

                                {/* {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>} */}
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                                <div className="users-label ">
                                    <label>End Date</label>
                                    <div className="position-relative select-icon user-type">
                                        <ReactDatePicker id='Remarkdate' dateFormat="dd/MM/yyyy" selectsRange={true} placeholderText="Select End Date " autoComplete='off' />
                                    </div>
                                    <img src={Datepickericon} alt="" className='datepickericon' />
                                </div>

                                {/* {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>} */}
                            </div>
                        </Col>
                    </Row>
                    <div className='border-bottom pb-3'>
                        <Row>
                            <Col lg={6}>
                                <div className="users-form-info add-remark-modal mx-0 mt-3">
                                    <div className="">
                                        <div className='users-label'>
                                            <label>Type</label>
                                        </div>
                                        <div className='d-flex justify-content-start types_input ms-3 gap-3 mt-2'>
                                            <div className='d-flex align-items-center'>
                                                <input id='sell' type='checkbox' className='me-1 ' />
                                                <label htmlFor='sell'>Sell</label>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <input type='checkbox' id='purchase' className='me-1 ' />
                                                <label htmlFor='purchase'>Purchase</label>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <input type='checkbox' id='return' className='me-1 ' />
                                                <label htmlFor='return'>Return</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {formErrors?.invoiceNo && <span style={{ color: "red" }}>{formErrors.invoiceNo}</span>} */}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                                    <div className='users-label'>
                                        <label></label>
                                    </div>
                                    <div className="users-label justify-content-end gap-2 d-flex flex-wrap d-block-sm">
                                        <button className='adduser-btn'>Apply Filter</button>
                                        <button className='adduser-btn'>Clear Filter</button>
                                        <button className='adduser-btn mt-2 mt-sm-0'>Export</button>
                                    </div>
                                    {/* {formErrors?.amount && <span style={{ color: "red" }}>{formErrors.amount}</span>} */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col lg={7}>
                            <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                                <div className="users-label d-flex justify-content-between type_of_product">
                                    <div className='d-flex'>
                                        <label>Sell: <span>1000</span></label>
                                    </div>
                                    <div className='d-flex'>
                                        <label>Purchase: <span>1000</span></label>
                                    </div>
                                    <div className='d-flex'>
                                        <label>Return: <span>1000</span></label>
                                    </div>
                                </div>
                                {/* {formErrors?.amount && <span style={{ color: "red" }}>{formErrors.amount}</span>} */}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                                <div className="users-label d-flex justify-content-end type_of_product">
                                    <div className='d-flex '>
                                        <label>On Hand:</label>
                                        <p>1000</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <table className='table viewer-product-modal product_ledger'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Quntity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01/04/2022</td>
                                    <td>Sell</td>
                                    <td>555</td>
                                </tr>
                                <tr>
                                    <td>01/04/2022</td>
                                    <td>Sell</td>
                                    <td>555</td>
                                </tr>
                                <tr>
                                    <td>01/04/2022</td>
                                    <td>Sell</td>
                                    <td>555</td>
                                </tr>
                                <tr>
                                    <td>01/04/2022</td>
                                    <td>Sell</td>
                                    <td>555</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='adduser-btn'></button> */}
                    <button className='cancel-button' onClick={onHide}>Cancel</button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ProductLeadger