import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import img from '../../assets/image/select.png'
import { UserRoles } from '../../helper/Constant';
import { addUserAction, editUserAction, getUserByIdAction } from '../../redux/actions/userAction';
import { ADD_USER, EDIT_USER } from '../../redux/type';

const AddUsers = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const userId = queryParams.get("userId")

  const [rotateArrow, setrotateArrow] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    role: ""
  });
  const [formErrors, setFormErrors] = useState()
  const [editId, setEditId] = useState()
  const mes = `User ${editId ? "updated" : "added"} successfully`;
  const err = "email is already exist"
  const toastSuccess = () => toast.success(mes);
  const toastError = () => toast.error('user is already exist');
  const toastErr = () => toast.error(err);

  const data = useSelector((state) => state.get_User_By_Id.getUserById.data)
  const editUserData = useSelector((state) => state.edit_User.editUser)
  const addUserData = useSelector((state) => state.add_User.addUser)

  useEffect(() => {
    if (userId) {
      setEditId(userId)
      dispatch(getUserByIdAction(userId))
    }
  }, [userId])

  useEffect(() => {
    if (data && userId) {
      setUserData({ name: data.name, email: data.email, password: data.password, role: data.role })
    }
  }, [data])

  const onChangeHandler = (e, name) => {
    setFormErrors({ ...formErrors, [name]: '' })
    if (e.target.value !== "Select Role") {
      setUserData({ ...userData, [e.target.name]: e.target.value })
    }
  }


  const validation = () => {
    let flag = false
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    if (!userData.name) {
      error.name = "Please enter username"
      flag = true
    }
    if (!userData.email) {
      error.email = "Please enter email ";
      flag = true
    } else if (!userData.email.match(regex)) {
      error.email = "Enter valid email";
      flag = true
    }
    if (!userData.password) {
      error.password = "Please enter password";
      flag = true
    }
    else if (userData.password.length < 8) {
      error.password = "Minimum lenght is 8 ";
      flag = true
    }
    if (!userData.role) {
      error.role = "Please select role"
      flag = true
    }
    setFormErrors(error);
    return flag;
  }


  const onSave = () => {
    if (validation()) {
      return
    }
    dispatch(addUserAction(userData))
    setUserData({ name: "", email: "", password: "", role: "" })
  }

  useEffect(() => {
    if (addUserData && addUserData.message === "user is already exist") {
      dispatch({
        type: ADD_USER,
        payload: []
      })
      toastError()
    }
    else if (addUserData && addUserData.data) {
      dispatch({
        type: ADD_USER,
        payload: []
      })
      navigate('/users')
      toastSuccess()
    }
  }, [addUserData])


  const onEdit = () => {
    if (validation()) {
      return
    }
    let editData = {
      ...userData,
      id: editId,
    }
    dispatch(editUserAction(editData))
    setUserData({ name: "", email: "", password: "", role: "" })
  }

  useEffect(() => {
    if (editUserData && editUserData.message === err) {
      dispatch({
        type: EDIT_USER,
        payload: []
      })
      toastErr(true)
    } else if (editUserData && editUserData.data) {
      dispatch({
        type: EDIT_USER,
        payload: []
      })
      navigate('/users')
      toastSuccess(true)
    }
  }, [editUserData])

  return (
    <div className='parent-dashboard'>
      <div className='mb-4 set-page-heade'>
        <div className='d-flex flex-column'>
          <h2 className='mb-0'>Users</h2>
        </div>
        <div>
          {editId ? <button className="adduser-btn" onClick={() => onEdit()}>Edit</button> : <button className="adduser-btn" onClick={() => onSave()}>Save</button>}
          <button className="cancel-button ms-3" onClick={() => { navigate('/users') }}>Cancel</button>
        </div>
      </div>
      <div className="users-form">
        <div className="users-form-body addEnquiry">
          <Row>
            <Col lg={6}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your username"
                    value={userData.name}
                    onChange={(e) => onChangeHandler(e, "name")}
                    name="name"
                    autoComplete="off"
                  />
                </div>
                {formErrors?.name && <label style={{ color: "red" }}>{formErrors.name}</label>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={userData.email}
                    onChange={(e) => onChangeHandler(e, "email")}
                    name="email"
                    autoComplete="off"
                  />
                </div>
                {formErrors?.email && <label style={{ color: "red" }}>{formErrors.email}</label>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Password</label>
                  <input
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter password"
                    type="text"
                    value={userData.password}
                    onChange={(e) => onChangeHandler(e, "password")}
                    name="password"
                    autoComplete="off"
                  />
                </div>
                {formErrors?.password && <label style={{ color: "red" }}>{formErrors.password}</label>}
              </div>
            </Col>
            <Col lg={6}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Role</label>
                  <div
                    className="position-relative select-icon"
                    style={{ width: "max-content" }}
                  >
                    <img src={img} className={`${rotateArrow && "rotateArrow"}`} alt="" />
                    <select className="form-control" value={userData.role} onBlur={() => { setrotateArrow(false) }} onClick={() => { setrotateArrow(!rotateArrow) }} onChange={(e) => onChangeHandler(e, "role")} name="role">
                      <option value="Select Role">Select Role</option>
                      <option value={UserRoles.Admin} >Admin</option>
                      <option value={UserRoles.Sales}>Sales</option>
                      <option value={UserRoles.Project}>Project</option>
                      <option value={UserRoles.Store}>Store & Purcase</option>
                      <option value={UserRoles.Account}>Account</option>
                      <option value={UserRoles.Liasoning}>Liasoning</option>
                      <option value={UserRoles.Viewer}>Viewer</option>
                      <option value={UserRoles.Maintance}>Maintance</option>
                    </select>
                  </div>
                </div>
                {formErrors?.role && <label style={{ color: "red" }}>{formErrors.role}</label>}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default AddUsers