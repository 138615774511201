import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import viewImg from '../../assets/image/eye.svg'
import AddBill from '../../components/modals/AddBill';
import { Col, Row } from 'react-bootstrap';
import { getAllCustomerAction } from '../../redux/actions/customerAction';
import { getAllAccountCountAction, getAllBillEntryByCustomerAction } from '../../redux/actions/accountAction';
import FollowUpPayment from '../../components/modals/FollowUpPayment';
import CurrencyFormat from 'react-currency-format';
import { DELETE_BILL_ENTRY } from '../../redux/type';
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import editIcon from '../../assets/image/edit.svg';
import ViewBill from '../../components/view-modals/ViewBill';
import { useLocation } from 'react-router-dom';
import { dateFormate } from '../../helper/utils';
import removeImg from '../../assets/image/delete-red.png'
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { deleteBillEntryAction } from '../../redux/actions/addBillAction';
import AddPaybleReceivable from '../../components/modals/PaybleReceivable';
import usePaginationHooks from '../../Hooks/paginationHooks';
import img from '../../assets/image/select.png';
import Datepickericon from '../../assets/image/date-picker-icon.png';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';

const AccountByCustomer = () => {
    const gridRef = useRef();
    const dispatch = useDispatch()
    const location = useLocation()
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Account]
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get("id")
    const [perPage, setPerPage] = useState('10')
    const [bill, setBill] = useState([]);
    const [addBillEntryTog, setAddBillEntryTog] = useState(false);
    const [paybleTog, setPaybleTog] = useState(false);
    const [followUpTog, setFollowUpTog] = useState(false);
    const [addBillEntryId, setAddBillEntryId] = useState('')
    const [filter, setFilter] = useState({
        startDate: '',
        endDate: '',
        customer: [],
        billType: 'Purchase',
    })
    const { getAllBillEntryByCustomers, getAllProjects } = usePaginationHooks()
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit);
    const [columnDefsBill, setcolumnDefsBill] = useState([]);
    const [viewPaymentId, setViewPaymentId] = useState('')
    const [viewBillTog, setViewBillTog] = useState(false)
    const [viewBillId, setViewBillId] = useState('')
    const [deletepopup, setDeletepopup] = useState(false);
    const [paramsValue, setParamsValue] = useState();
    const [customerName, setCustomerName] = useState('')
    const [total, setTotal] = useState(0)
    const [nameTog, setNameTog] = useState('')
    const [rotateArrow, setrotateArrow] = useState(false);
    const [column_filter, setColumn_Filter] = useState({ column: "", value: "", startDate: "", endDate: "", projects: [] });
    const [api_column_filter, setApi_Column_Filter] = useState({ column: "", value: "", startDate: "", endDate: "", projects: [] });
    const [over_due_days_entry_count, setOver_Due_Days_Entry_Count] = useState({ red: 0, green: 0, yellow: 0, green_entry_total: 0, yellow_entry_total: 0, red_entry_total: 0 });
    const [projectOption, setProjectOption] = useState([]);

    const toastSuccess = () => toast.success('BillEntry deleted successfully');

    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

    const getAllBillEntryData = useSelector((state) => state.get_All_Bill_By_Customer.getAllBillEntryByCustomer)
    const deleteBillEntryData = useSelector((state) => state.delete_Bill_Entry.deleteBillEntry)
    const editBillEntryData = useSelector((state) => state.edit_Bill_Entry.editBillEntry)
    const addBillEntryData = useSelector((state) => state.add_Bill_Entry.addBillEntry)

    const getNoOfDays = (days) => {
        if (days >= 0 && days <= 30) {
            return (<span title={days === 0 ? "Today" : `${days} day ago.`}>
                <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.6117 26.6903L19.2817 2.02767C18.9769 1.41837 18.5085 0.905948 17.9289 0.547819C17.3493 0.18969 16.6814 0 16 0C15.3186 0 14.6507 0.18969 14.0711 0.547819C13.4915 0.905948 13.0231 1.41837 12.7183 2.02767L0.388326 26.6903C0.108219 27.2495 -0.0242064 27.8711 0.00363596 28.4958C0.0314783 29.1206 0.218663 29.7279 0.5474 30.26C0.876138 30.7921 1.33551 31.2312 1.88185 31.5358C2.42818 31.8403 3.04334 32.0001 3.66886 32H28.3311C28.9567 32.0001 29.5718 31.8403 30.1182 31.5358C30.6645 31.2312 31.1239 30.7921 31.4526 30.26C31.7813 29.7279 31.9685 29.1206 31.9964 28.4958C32.0242 27.8711 31.8918 27.2495 31.6117 26.6903ZM16 26.2858C15.7739 26.2858 15.5529 26.2187 15.365 26.0932C15.177 25.9676 15.0305 25.7891 14.944 25.5803C14.8575 25.3714 14.8348 25.1416 14.8789 24.92C14.923 24.6983 15.0319 24.4946 15.1917 24.3348C15.3516 24.175 15.5553 24.0661 15.777 24.022C15.9987 23.9779 16.2286 24.0006 16.4374 24.0871C16.6463 24.1736 16.8248 24.32 16.9504 24.508C17.076 24.6959 17.143 24.9169 17.143 25.1429C17.143 25.446 17.0226 25.7367 16.8083 25.951C16.5939 26.1654 16.3032 26.2858 16 26.2858ZM17.143 20.5715C17.143 20.8746 17.0226 21.1653 16.8083 21.3796C16.5939 21.594 16.3032 21.7144 16 21.7144C15.6968 21.7144 15.4061 21.594 15.1917 21.3796C14.9774 21.1653 14.857 20.8746 14.857 20.5715V11.4287C14.857 11.1256 14.9774 10.8349 15.1917 10.6206C15.4061 10.4063 15.6968 10.2859 16 10.2859C16.3032 10.2859 16.5939 10.4063 16.8083 10.6206C17.0226 10.8349 17.143 11.1256 17.143 11.4287V20.5715Z" fill="#00B674" />
                </svg>
            </span>);
        } else if (days > 30 && days <= 45) {
            return (<span title={`${days} day ago.`}>
                <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.6117 26.6903L19.2817 2.02767C18.9769 1.41837 18.5085 0.905948 17.9289 0.547819C17.3493 0.18969 16.6814 0 16 0C15.3186 0 14.6507 0.18969 14.0711 0.547819C13.4915 0.905948 13.0231 1.41837 12.7183 2.02767L0.388326 26.6903C0.108219 27.2495 -0.0242064 27.8711 0.00363596 28.4958C0.0314783 29.1206 0.218663 29.7279 0.5474 30.26C0.876138 30.7921 1.33551 31.2312 1.88185 31.5358C2.42818 31.8403 3.04334 32.0001 3.66886 32H28.3311C28.9567 32.0001 29.5718 31.8403 30.1182 31.5358C30.6645 31.2312 31.1239 30.7921 31.4526 30.26C31.7813 29.7279 31.9685 29.1206 31.9964 28.4958C32.0242 27.8711 31.8918 27.2495 31.6117 26.6903ZM16 26.2858C15.7739 26.2858 15.5529 26.2187 15.365 26.0932C15.177 25.9676 15.0305 25.7891 14.944 25.5803C14.8575 25.3714 14.8348 25.1416 14.8789 24.92C14.923 24.6983 15.0319 24.4946 15.1917 24.3348C15.3516 24.175 15.5553 24.0661 15.777 24.022C15.9987 23.9779 16.2286 24.0006 16.4374 24.0871C16.6463 24.1736 16.8248 24.32 16.9504 24.508C17.076 24.6959 17.143 24.9169 17.143 25.1429C17.143 25.446 17.0226 25.7367 16.8083 25.951C16.5939 26.1654 16.3032 26.2858 16 26.2858ZM17.143 20.5715C17.143 20.8746 17.0226 21.1653 16.8083 21.3796C16.5939 21.594 16.3032 21.7144 16 21.7144C15.6968 21.7144 15.4061 21.594 15.1917 21.3796C14.9774 21.1653 14.857 20.8746 14.857 20.5715V11.4287C14.857 11.1256 14.9774 10.8349 15.1917 10.6206C15.4061 10.4063 15.6968 10.2859 16 10.2859C16.3032 10.2859 16.5939 10.4063 16.8083 10.6206C17.0226 10.8349 17.143 11.1256 17.143 11.4287V20.5715Z" fill="#FFC300" />
                </svg>
            </span>);
        } else if (days > 45) {
            return (<span title={`${days} day ago.`}>
                <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.6117 26.6903L19.2817 2.02767C18.9769 1.41837 18.5085 0.905948 17.9289 0.547819C17.3493 0.18969 16.6814 0 16 0C15.3186 0 14.6507 0.18969 14.0711 0.547819C13.4915 0.905948 13.0231 1.41837 12.7183 2.02767L0.388326 26.6903C0.108219 27.2495 -0.0242064 27.8711 0.00363596 28.4958C0.0314783 29.1206 0.218663 29.7279 0.5474 30.26C0.876138 30.7921 1.33551 31.2312 1.88185 31.5358C2.42818 31.8403 3.04334 32.0001 3.66886 32H28.3311C28.9567 32.0001 29.5718 31.8403 30.1182 31.5358C30.6645 31.2312 31.1239 30.7921 31.4526 30.26C31.7813 29.7279 31.9685 29.1206 31.9964 28.4958C32.0242 27.8711 31.8918 27.2495 31.6117 26.6903ZM16 26.2858C15.7739 26.2858 15.5529 26.2187 15.365 26.0932C15.177 25.9676 15.0305 25.7891 14.944 25.5803C14.8575 25.3714 14.8348 25.1416 14.8789 24.92C14.923 24.6983 15.0319 24.4946 15.1917 24.3348C15.3516 24.175 15.5553 24.0661 15.777 24.022C15.9987 23.9779 16.2286 24.0006 16.4374 24.0871C16.6463 24.1736 16.8248 24.32 16.9504 24.508C17.076 24.6959 17.143 24.9169 17.143 25.1429C17.143 25.446 17.0226 25.7367 16.8083 25.951C16.5939 26.1654 16.3032 26.2858 16 26.2858ZM17.143 20.5715C17.143 20.8746 17.0226 21.1653 16.8083 21.3796C16.5939 21.594 16.3032 21.7144 16 21.7144C15.6968 21.7144 15.4061 21.594 15.1917 21.3796C14.9774 21.1653 14.857 20.8746 14.857 20.5715V11.4287C14.857 11.1256 14.9774 10.8349 15.1917 10.6206C15.4061 10.4063 15.6968 10.2859 16 10.2859C16.3032 10.2859 16.5939 10.4063 16.8083 10.6206C17.0226 10.8349 17.143 11.1256 17.143 11.4287V20.5715Z" fill="#E4222E" />
                </svg>
            </span>);
        } else {
            return (<></>);
        }
    }

    const columnDefsBills = [
        {
            field: 'alldata',
            headerName: '',
            maxWidth: 65,
            sortable: false,
            cellRendererFramework: (params) => {
                return (<div>
                    {(params?.value?.pay === "In" && params?.value?.status === "Pending") ? getNoOfDays(params?.value?.days) : <></>}
                </div>)
            }
        },
        {
            field: 'date',
            cellRendererFramework: (params) => <div>
                <span>{dateFormate(params.value, "Date")}</span>
            </div>
        },
        {
            field: 'invoiceNo',
            headerName: 'Invoice No.',
            cellRendererFramework: (params) => {
                return (<div>
                    <span>{params.value}</span>
                </div>)
            }
        },
        {
            field: 'amount',
            cellRendererFramework: (params) => <div>
                <span><CurrencyFormat value={params.value?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
            </div>
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            cellRendererFramework: (params) => {
                return (<div>
                    <span>{params?.value}</span>
                </div>)
            }
        },
        { field: 'type' },
        {
            headerName: 'Action',
            field: "_id",
            sortable: false,
            cellRendererFramework: (params) => <div>
                {(params.data?._id === userData?.id || userRole === UserRoles.Admin) && <>
                    {/* <img src={editIcon} onClick={() => { onEditBill(params.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" /> */}
                    {(params.data?._id === userData.id || userRole === UserRoles.Admin) && <>
                        {/* <img src={editIcon} onClick={() => { onEditBill(params.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" /> */}
                        <img src={removeImg} onClick={() => { setDeletepopup(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: '20px' }} className='ms-3' alt="" title="Delete" />
                    </>}
                    <img src={viewImg} onClick={() => { setViewBillTog(true); setViewBillId(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
                </>
                }
            </div>
        }
    ]

    const NoRolecolumnDefsBills = [
        { field: 'date' },
        {
            field: 'amount',
            cellRendererFramework: (params) => <div>
                <span><CurrencyFormat value={params.value} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
            </div>
        },
        { field: 'type' },
        {
            headerName: 'Action',
            field: "_id",
            sortable: false,
            cellRendererFramework: (params) => <div>
                <img src={viewImg} onClick={() => { setViewBillTog(true); setViewBillId(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
            </div>
        }
    ]

    const onEditBill = (id) => {
        setAddBillEntryTog(true)
        setAddBillEntryId(id)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEditBill(params.data._id);
        }
    }

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1,
        };
    }, []);

    useEffect(() => {
        if (RolesForAdd.includes(userRole)) {
            setcolumnDefsBill(columnDefsBills)
        } else {
            setcolumnDefsBill(NoRolecolumnDefsBills)
        }
        dispatch(getAllCustomerAction())
        dispatch(getAllAccountCountAction())
    }, [])

    useEffect(() => {
        getAllProjects("", "", "", "", "", "", "").then((res) => {
            if (res?.data) {
                let temp = res?.data?.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }));
                setProjectOption([...temp.map((ele) => ({ value: ele._id, label: ele.partyName }))]);
            }
        })
    }, []);

    const onDelete = (id) => {
        dispatch(deleteBillEntryAction(id))
    }

    useEffect(() => {
        if (deleteBillEntryData && deleteBillEntryData.data && deleteBillEntryData.data.status === 200) {
            dispatch({
                type: DELETE_BILL_ENTRY,
                payload: []
            })
            setDeletepopup(false)
            toastSuccess(true)
        }
    }, [deleteBillEntryData])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const applyFilter = () => {
        if ((column_filter.column && column_filter.value) || (column_filter.startDate && column_filter.endDate) || (column_filter.projects?.length)) {
            setApi_Column_Filter({ column: column_filter.column, value: column_filter.value, startDate: column_filter.startDate, endDate: column_filter.endDate, projects: column_filter.projects });
        }
    }

    const clearFilter = () => {
        if (column_filter?.column || column_filter?.value || column_filter?.startDate || column_filter?.endDate || column_filter?.projects?.length) {
            setColumn_Filter({ column: "", value: "", startDate: "", endDate: "", projects: [] });
        }
        if (api_column_filter?.column || api_column_filter?.value || api_column_filter?.startDate || api_column_filter?.endDate || api_column_filter?.projects?.length) {
            setApi_Column_Filter({ column: "", value: "", startDate: "", endDate: "", projects: [] });
        }
    }

    const openCalender = (id) => {
        document.getElementById(id).click();
    }

    useEffect(() => {
        if (gridApi || editBillEntryData?.status === 200 || deleteBillEntryData?.status === 200) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ?? "";
                    const sorted_order = params?.sortModel[0]?.sort ?? "";
                    const custom_filter_column_name = api_column_filter.column ?? "";
                    const custom_filter_column_value = api_column_filter.value ?? "";
                    const startDate = api_column_filter.startDate ?? "";
                    const endDate = api_column_filter.endDate ?? "";
                    const projectId = JSON.stringify(api_column_filter?.projects?.length ? api_column_filter?.projects : []);

                    getAllBillEntryByCustomers(page, limit, id, sorted_column, sorted_order, custom_filter_column_name, custom_filter_column_value, startDate, endDate, projectId)
                        .then(res => {
                            // setaccountData(res.data)
                            setOver_Due_Days_Entry_Count({
                                red: res.red_counts ?? 0,
                                green: res.green_counts ?? 0,
                                yellow: res.yellow_counts ?? 0,
                                green_entry_total: res?.green_entry_total ?? 0,
                                yellow_entry_total: res?.yellow_entry_total ?? 0,
                                red_entry_total: res?.red_entry_total ?? 0
                            });
                            // let tempTotal = 0;
                            setTotal(res?.totalAmount ?? 0)
                            setCustomerName(res?.data[0]?.customer?.accountName)
                            params.successCallback(res?.data?.map((ele) => {
                                // if (ele.pay === "In") {
                                //     tempTotal = tempTotal + ele.amount
                                // } else if (ele.pay === "Out") {
                                //     tempTotal = tempTotal - ele.amount
                                // }
                                if (res?.data[0]?.billType === "Purchase") {
                                    setNameTog("Payable")
                                } else if (res?.data[0]?.billType === "Sale") {
                                    setNameTog("Receivable")
                                }
                                return {
                                    _id: ele._id,
                                    date: ele.date,
                                    amount: ele.amount,
                                    type: ele.pay === "In" ? "Invoice" : "Payment",
                                    invoiceNo: ele.invoiceNo ?? "-",
                                    status: (ele?.pay === "Out") ? "Done" : (ele?.status ?? "-"),
                                    alldata: ele,
                                }
                            }), res.totalCount ? res.totalCount : 0)
                            // setTotal(tempTotal)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

    }, [gridApi, limit, editBillEntryData, deleteBillEntryData, api_column_filter]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <h2>Account</h2>
                    <span style={{ fontSize: '20px' }}>Customer Name: <span style={{ fontWeight: "600" }}>{customerName}</span></span>
                    <span className="my-2 my-lg-0" style={{ fontSize: '20px' }}>{nameTog}: <span style={{ fontWeight: "600" }}>{total && <CurrencyFormat value={total.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />}</span></span>
                </div>
                <div className='mb-4 set-page-heade'>
                    <div className="my-2 my-lg-0 d-flex flex-column">
                        <span className="d-flex align-items-center gap-2" style={{ fontSize: '20px' }}>
                            Over Due Days Entry Count (Upto 30 days)
                            <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.6117 26.6903L19.2817 2.02767C18.9769 1.41837 18.5085 0.905948 17.9289 0.547819C17.3493 0.18969 16.6814 0 16 0C15.3186 0 14.6507 0.18969 14.0711 0.547819C13.4915 0.905948 13.0231 1.41837 12.7183 2.02767L0.388326 26.6903C0.108219 27.2495 -0.0242064 27.8711 0.00363596 28.4958C0.0314783 29.1206 0.218663 29.7279 0.5474 30.26C0.876138 30.7921 1.33551 31.2312 1.88185 31.5358C2.42818 31.8403 3.04334 32.0001 3.66886 32H28.3311C28.9567 32.0001 29.5718 31.8403 30.1182 31.5358C30.6645 31.2312 31.1239 30.7921 31.4526 30.26C31.7813 29.7279 31.9685 29.1206 31.9964 28.4958C32.0242 27.8711 31.8918 27.2495 31.6117 26.6903ZM16 26.2858C15.7739 26.2858 15.5529 26.2187 15.365 26.0932C15.177 25.9676 15.0305 25.7891 14.944 25.5803C14.8575 25.3714 14.8348 25.1416 14.8789 24.92C14.923 24.6983 15.0319 24.4946 15.1917 24.3348C15.3516 24.175 15.5553 24.0661 15.777 24.022C15.9987 23.9779 16.2286 24.0006 16.4374 24.0871C16.6463 24.1736 16.8248 24.32 16.9504 24.508C17.076 24.6959 17.143 24.9169 17.143 25.1429C17.143 25.446 17.0226 25.7367 16.8083 25.951C16.5939 26.1654 16.3032 26.2858 16 26.2858ZM17.143 20.5715C17.143 20.8746 17.0226 21.1653 16.8083 21.3796C16.5939 21.594 16.3032 21.7144 16 21.7144C15.6968 21.7144 15.4061 21.594 15.1917 21.3796C14.9774 21.1653 14.857 20.8746 14.857 20.5715V11.4287C14.857 11.1256 14.9774 10.8349 15.1917 10.6206C15.4061 10.4063 15.6968 10.2859 16 10.2859C16.3032 10.2859 16.5939 10.4063 16.8083 10.6206C17.0226 10.8349 17.143 11.1256 17.143 11.4287V20.5715Z" fill="#00B674" />
                            </svg>
                            : {over_due_days_entry_count.green}
                        </span>
                        <span className="d-flex align-items-center gap-2" style={{ fontSize: '20px' }}>
                            Total Amount : <span style={{ fontWeight: "600" }}><CurrencyFormat value={over_due_days_entry_count.green_entry_total.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
                        </span>
                    </div>
                    <div className="my-2 my-lg-0 d-flex flex-column">
                        <span className="d-flex align-items-center gap-2" style={{ fontSize: '20px' }}>
                            Over Due Days Entry Count (31-45 days)
                            <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.6117 26.6903L19.2817 2.02767C18.9769 1.41837 18.5085 0.905948 17.9289 0.547819C17.3493 0.18969 16.6814 0 16 0C15.3186 0 14.6507 0.18969 14.0711 0.547819C13.4915 0.905948 13.0231 1.41837 12.7183 2.02767L0.388326 26.6903C0.108219 27.2495 -0.0242064 27.8711 0.00363596 28.4958C0.0314783 29.1206 0.218663 29.7279 0.5474 30.26C0.876138 30.7921 1.33551 31.2312 1.88185 31.5358C2.42818 31.8403 3.04334 32.0001 3.66886 32H28.3311C28.9567 32.0001 29.5718 31.8403 30.1182 31.5358C30.6645 31.2312 31.1239 30.7921 31.4526 30.26C31.7813 29.7279 31.9685 29.1206 31.9964 28.4958C32.0242 27.8711 31.8918 27.2495 31.6117 26.6903ZM16 26.2858C15.7739 26.2858 15.5529 26.2187 15.365 26.0932C15.177 25.9676 15.0305 25.7891 14.944 25.5803C14.8575 25.3714 14.8348 25.1416 14.8789 24.92C14.923 24.6983 15.0319 24.4946 15.1917 24.3348C15.3516 24.175 15.5553 24.0661 15.777 24.022C15.9987 23.9779 16.2286 24.0006 16.4374 24.0871C16.6463 24.1736 16.8248 24.32 16.9504 24.508C17.076 24.6959 17.143 24.9169 17.143 25.1429C17.143 25.446 17.0226 25.7367 16.8083 25.951C16.5939 26.1654 16.3032 26.2858 16 26.2858ZM17.143 20.5715C17.143 20.8746 17.0226 21.1653 16.8083 21.3796C16.5939 21.594 16.3032 21.7144 16 21.7144C15.6968 21.7144 15.4061 21.594 15.1917 21.3796C14.9774 21.1653 14.857 20.8746 14.857 20.5715V11.4287C14.857 11.1256 14.9774 10.8349 15.1917 10.6206C15.4061 10.4063 15.6968 10.2859 16 10.2859C16.3032 10.2859 16.5939 10.4063 16.8083 10.6206C17.0226 10.8349 17.143 11.1256 17.143 11.4287V20.5715Z" fill="#FFC300" />
                            </svg>
                            : {over_due_days_entry_count.yellow}
                        </span>
                        <span className="d-flex align-items-center gap-2" style={{ fontSize: '20px' }}>
                            Total Amount : <span style={{ fontWeight: "600" }}><CurrencyFormat value={over_due_days_entry_count.yellow_entry_total.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
                        </span>
                    </div>
                    <div className="my-2 my-lg-0 d-flex flex-column">
                        <span className="d-flex align-items-center gap-2" style={{ fontSize: '20px' }}>
                            Over Due Days Entry Count (More than 45 days)
                            <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.6117 26.6903L19.2817 2.02767C18.9769 1.41837 18.5085 0.905948 17.9289 0.547819C17.3493 0.18969 16.6814 0 16 0C15.3186 0 14.6507 0.18969 14.0711 0.547819C13.4915 0.905948 13.0231 1.41837 12.7183 2.02767L0.388326 26.6903C0.108219 27.2495 -0.0242064 27.8711 0.00363596 28.4958C0.0314783 29.1206 0.218663 29.7279 0.5474 30.26C0.876138 30.7921 1.33551 31.2312 1.88185 31.5358C2.42818 31.8403 3.04334 32.0001 3.66886 32H28.3311C28.9567 32.0001 29.5718 31.8403 30.1182 31.5358C30.6645 31.2312 31.1239 30.7921 31.4526 30.26C31.7813 29.7279 31.9685 29.1206 31.9964 28.4958C32.0242 27.8711 31.8918 27.2495 31.6117 26.6903ZM16 26.2858C15.7739 26.2858 15.5529 26.2187 15.365 26.0932C15.177 25.9676 15.0305 25.7891 14.944 25.5803C14.8575 25.3714 14.8348 25.1416 14.8789 24.92C14.923 24.6983 15.0319 24.4946 15.1917 24.3348C15.3516 24.175 15.5553 24.0661 15.777 24.022C15.9987 23.9779 16.2286 24.0006 16.4374 24.0871C16.6463 24.1736 16.8248 24.32 16.9504 24.508C17.076 24.6959 17.143 24.9169 17.143 25.1429C17.143 25.446 17.0226 25.7367 16.8083 25.951C16.5939 26.1654 16.3032 26.2858 16 26.2858ZM17.143 20.5715C17.143 20.8746 17.0226 21.1653 16.8083 21.3796C16.5939 21.594 16.3032 21.7144 16 21.7144C15.6968 21.7144 15.4061 21.594 15.1917 21.3796C14.9774 21.1653 14.857 20.8746 14.857 20.5715V11.4287C14.857 11.1256 14.9774 10.8349 15.1917 10.6206C15.4061 10.4063 15.6968 10.2859 16 10.2859C16.3032 10.2859 16.5939 10.4063 16.8083 10.6206C17.0226 10.8349 17.143 11.1256 17.143 11.4287V20.5715Z" fill="#E4222E" />
                            </svg>
                            : {over_due_days_entry_count.red}
                        </span>
                        <span className="d-flex align-items-center gap-2" style={{ fontSize: '20px' }}>
                            Total Amount : <span style={{ fontWeight: "600" }}><CurrencyFormat value={over_due_days_entry_count.red_entry_total.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
                        </span>
                    </div>
                </div>
                <div className='mb-4 bg-white'>
                    <Row>
                        <Col xxl={3} xl={6} lg={4} sm={6} xs={12} className='d-flex justify-content-center'>
                            <div className="users-form-info date-picker add-remark-modal my-2 my-xxl-0" style={{ width: "100%" }}>
                                <div className="users-label enquery-datepicker">
                                    <div className="position-relative select-icon user-type">
                                        <ReactDatePicker
                                            id='Remarkdate'
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange={true}
                                            placeholderText="Select Start & End Date"
                                            startDate={column_filter.startDate}
                                            endDate={column_filter.endDate}
                                            onChange={(e) => setColumn_Filter((pre) => ({ ...pre, startDate: e[0], endDate: e[1] }))}
                                            autoComplete='off'
                                        />
                                        <img src={Datepickericon} onClick={() => openCalender('Remarkdate')} alt="" style={{ position: "absolute", right: "4%", top: "28%", height: "24px", width: "24px", opacity: 0.6 }} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={2} xl={6} lg={4} sm={6} xs={12} className='d-flex justify-content-center'>
                            <div className="users-form-info add-remark-modal my-2 my-xxl-0 d-flex align-items-center" style={{ width: "100%" }}>
                                <Select
                                    options={projectOption}
                                    isMulti={true}
                                    placeholder={"Select Project"}
                                    value={projectOption.filter((item) => column_filter?.projects?.includes(item?.value))}
                                    onChange={(e) => {
                                        const projectIds = e?.map(item => item.value);
                                        setColumn_Filter((pre) => ({ ...pre, projects: projectIds }));
                                    }}
                                    className="w-100"
                                />
                            </div>
                        </Col>
                        <Col xxl={2} xl={6} lg={4} sm={6} xs={12} className='d-flex justify-content-center'>
                            <div className="position-relative header-select my-2 my-xxl-0 users-label" style={{ width: "100%" }}>
                                <img src={img} className={`${rotateArrow && "rotateArrow"}`} width="18px" alt="" style={{ top: 12 }} />
                                <select
                                    className="form-control"
                                    style={{ width: "100%" }}
                                    value={column_filter.column}
                                    onBlur={() => setrotateArrow(false)}
                                    onClick={() => setrotateArrow(pre => !pre)}
                                    onChange={(e) => setColumn_Filter((pre) => ({ ...pre, column: e.target.value }))}
                                >
                                    <option className='d-none' value="">Select Column</option>
                                    <option value="amount">Amount</option>
                                    <option value="invoiceNo">Invoice No.</option>
                                </select>
                            </div>
                        </Col>
                        <Col xxl={2} xl={6} lg={6} sm={6} xs={12} className='d-flex justify-content-center'>
                            <div className='my-2 my-xxl-0 users-label' style={{ width: "100%" }}>
                                <input
                                    type="text"
                                    style={{ width: "100%" }}
                                    className="form-control search-button"
                                    placeholder="Enter Value..."
                                    autoComplete="off"
                                    value={column_filter.value}
                                    onChange={(e) => setColumn_Filter((pre) => ({ ...pre, value: e.target.value?.trimStart() }))}
                                />
                            </div>
                        </Col>
                        <Col xxl={3} xl={6} lg={6} sm={6} xs={12} className='d-flex justify-content-center'>
                            <button className='adduser-btn my-2 my-xxl-0 mx-2' style={{ maxHeight: "44px" }} onClick={() => applyFilter()}>Apply Filter</button>
                            <button className='adduser-btn my-2 my-xxl-0 mx-2' style={{ maxHeight: "44px" }} onClick={() => clearFilter()}>Clear Filter</button>
                        </Col>
                    </Row>
                </div>
                <div className='row'>
                    <Col lg={12}>
                        {bill &&
                            <div className="ag-theme-alpine accByCid" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                                <AgGridReact
                                    pagination={true}
                                    columnDefs={columnDefsBill}
                                    rowModelType={"infinite"}
                                    paginationPageSize={limit}
                                    cacheBlockSize={limit}
                                    onGridReady={onGridReady}
                                    rowSelection={'single'}
                                    onCellClicked={(event) => {
                                        const rowData = event.data;
                                        if (rowData?._id === userData.id || userRole === UserRoles.Admin) {
                                            handleRowClick(event)
                                        }
                                    }}
                                    rowHeight={45}
                                    defaultColDef={defaultColDef}
                                    overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                                />
                                <div className='set-pagination-dropdaun'>
                                    <p>Show</p>
                                    <select value={limit} onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                    </select>
                                    <p>Entries</p>
                                </div>
                            </div>
                        }
                    </Col>
                </div>
            </div>
            {addBillEntryTog && <AddBill show={addBillEntryTog} onHide={() => { setAddBillEntryTog(false); setAddBillEntryId(''); }} addBillEntryId={addBillEntryId} />}
            {followUpTog && <FollowUpPayment show={followUpTog} onHide={() => { setFollowUpTog(false); setViewPaymentId('') }} viewPaymentId={viewPaymentId} billTypes={filter.billType} />}
            {viewBillTog && <ViewBill show={viewBillTog} onHide={() => { setViewBillTog(false); setViewBillId('') }} viewBillId={viewBillId} />}
            {deletepopup &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => { onDelete(paramsValue) }}
                    onCancel={() => { setDeletepopup(false); setParamsValue('') }}
                    focusCancelBtn
                >
                    You want to delete it
                </SweetAlert>
            }
        </>
    )
}

export default AccountByCustomer