import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Routes from './routes/Routes';
// import "ag-grid-enterprise";

// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-material.css";
import store from './redux/store'
import { RoleProvider } from './helper/RoleProvider';
import Projectreport from './pages/project-report/Projectreport';

function App() {
  return (
    <React.Suspense fallback={false}>

      <Provider store={store}>
        <BrowserRouter>
          <RoleProvider>
            <Routes />
          </RoleProvider>
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  );
}

export default App;
