import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import STORAGEKEY from "../config/APP/app.config";
import { ApiGet } from "./API/ApiData";
import AuthStorage from "./AuthStorage";
import { forAuthLayout, forLayout, UserRoles } from "./Constant";

export const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    // const forLayout = ["/"]
    // const forAuthLayout = ["/dashboard", "/customer", "/account", "/bank", "/payble-receivable", "/product", "/purchase", "/purchase/add", "/sale", "/task", "/sale/add", "/enquiry", "/enquiry/add", "/enquiry/approve", "/project", "/project/edit", "/daily-report", "/daily-report/add", "/daily-report/edit", "/noc", "/noc/add", "/noc/edit", "/users", "/users/add", "/users/edit", "/roles", "/setting", '/stock-summary']
    const forLayouts = forLayout
    const forAuthLayouts = forAuthLayout
    const [roleName, setRoleName] = useState("");
    const RolesArr = [UserRoles.Account, UserRoles.Admin, UserRoles.Liasoning, UserRoles.Project, UserRoles.Viewer, UserRoles.Sales, UserRoles.Store, UserRoles.Maintance]

    const redirectToCurrentPath = () => {
        navigate(`${location.pathname}${location.search}`);
    }

    useEffect(() => {
        if (AuthStorage.isUserAuthenticated()) {
            ApiGet("users/verify_user")
                .then((res) => {
                    if (res.message === "User verified") {
                        AuthStorage.setStorageJsonData(STORAGEKEY.userData, res.data, true);
                        AuthStorage.setStorageData(STORAGEKEY.token, res.data.token, true);
                        setRoleName(res.data.role)
                        if (!forAuthLayouts.includes(location.pathname)) {
                            if (res.data.role === "" || !res.data.role || !RolesArr.includes(res.data.role)) {
                                navigate('/error')
                            } else {
                                if (location.pathname === "/project-report") {
                                    redirectToCurrentPath()
                                } else {
                                    navigate('/dashboard')
                                }
                            }
                        } else {
                            if (location.pathname === "/project-report") {
                                redirectToCurrentPath()
                            }
                        }
                    } else {
                        if (location.pathname === "/project-report") {
                            redirectToCurrentPath()
                        }
                    }
                })
                .catch((err) => {
                    AuthStorage.deauthenticateUser();
                })
        } else {
            if (forLayouts.includes(location.pathname)) {
                if (location.pathname === "/project-report") {
                    redirectToCurrentPath()
                }
                // navigate(location.pathname);
            } else {
                navigate('/');
            }
        }
        // const findRoutes = roleWiseRoutes.filter((item) => item.path === location.pathname)
        // if (!findRoutes.length) {
        //     if(true) {
        //         setIsRouteMatch(false)
        //     }
        // }
    }, [location.pathname]);

    return <RoleContext.Provider value={roleName}>
        {children}
    </RoleContext.Provider>
}