import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";
import { toast } from 'react-toastify'
import { getProductDropDownAction } from '../../redux/actions/productAction';
import { useLocation } from 'react-router-dom';
import { GET_QUOTATION_BY_ID } from '../../redux/type';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import Select from 'react-select';
import { UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import { addQuotationAction, editQuotationAction, getQuotationByIdAction } from '../../redux/actions/quotationAction';

const GenerateQuatation = ({ show, onHide, enquiryId, quotationId, setQuotationId, IsShowEditButton }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const projctId = queryParams.get("projectId")
    const [project, setProject] = useState('')
    const [filteredProducts, setFilteredProducts] = useState([])
    const [commonDiscount, setCommonDiscount] = useState()
    const [productOption, setProductOption] = useState([])
    const [productReq, setProductReq] = useState([
        {
            productId: '',
            productName: '',
            productCode: '',
            reqQuantity: '',
            // discount: 0,
            retailSalePrice: "",
            // deliveredQuantity: '0',
            desc: '',
            make: '',
            // priority: '',
            unit: '',
            showQty: 0
        },
    ]);
    const purchaseRequestAdded = () => toast.success('Purchase request Added');
    const purchaseRequestUpdate = () => toast.success('Purchase request update Successfully');
    const PRError = () => toast.error("Purchase Request not found");
    const [formErrors, setFormErrors] = useState()
    const [rotateArrow, setRotateArrow] = useState('');
    const [product, setProduct] = useState([])
    const [finalMaterial, setFinalMaterial] = useState([])
    const [projectId, setProjectId] = useState('')
    const [projectType, setProjectType] = useState('')
    const [projectOption, setProjectOption] = useState([])
    const [tempObj, setTempObj] = useState({});
    const [extraUsedProducts, setExtraUsedProducts] = useState([])
    const [showConfirmation, setShowConfirmation] = useState(false)
    const getPurchaseRequestByIdData = useSelector((state) => state.get_Purchase_Request_By_Id.getPurchaseRequestById)
    const getAllProject = useSelector((state) => state.get_All_Project.getAllProject.data)
    const getProductData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    const getFinalMaterial = useSelector((state) => state.get_Final_Material_By_Project.getFinalMaterialByProject.data)
    const getQuotationById = useSelector((state) => state.get_Quotation_By_Id.getQuotationById)
    const userRole = useContext(RoleContext);
    const [quotationName, setQuotationName] = useState('')

    useEffect(() => {
        if (projctId) {
            setProjectId(projctId)
        }
    }, [projctId])

    // useEffect(() => {
    //     if (getFinalMaterial?.length)
    //         setFinalMaterial(getFinalMaterial?.map(item => { return { ...item, productId: item?.productId?._id, productCode: item?.productId?.productCode } }))
    //     else
    //         setFinalMaterial([])
    // }, [getFinalMaterial])

    useEffect(() => {
        dispatch(getProductDropDownAction())
        // dispatch(getFinalMaterialByProject(project))
    }, [])

    useEffect(() => {
        if (!projectId) {
            let body = '';
            let role = '';
            if (userRole === UserRoles.Maintance) {
                role = 'Maintenance'
            } else if (userRole === UserRoles.Project) {
                role = 'Project'
            }
            dispatch(getAllProjectAction(body, role))
        }
    }, [projectId])

    useEffect(() => {
        if (getAllProject) {
            let temp = getAllProject.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
            setProjectOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.partyName
                }
            }))
        }
    }, [getAllProject])

    // useEffect(() => {
    //     if (getProductData) {
    //         let temp = getProductData.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }));
    //         let selectedProducts = productReq?.map(item => item.productCode);
    //         let finalMaterialData = finalMaterial.map(item => {
    //             return item.productId
    //         });
    //         let productOptions = temp.filter(it => finalMaterialData.includes(it._id)).map((item) => {
    //             // let productOptions = (finalMaterialData.length ? temp.filter(it => finalMaterialData.includes(it._id)) : temp).map((item) => {
    //             return {
    //                 value: item.productCode,
    //                 label: item.productCode ? `(${item.productCode}) ${item.productName}` : item.productName
    //             }
    //         });
    //         setProduct(productOptions.filter(item => !selectedProducts?.includes(item.productCode)));
    //     }
    // }, [getProductData, finalMaterial, productReq])

    const validation = () => {
        let flag = false;
        const error = {};
        // if (!project) {
        //     error.project = "Please select project";
        //     flag = true;
        // }
        if (quotationName === '') {
            error.quotationName = 'Quotation Name is Required'
            flag = true;
        }
        if (productReq.length) {
            productReq.map((item, i) => {
                if (item.productCode === "") {
                    error[`productCode${i + 1}`] = 'Required'
                    flag = true;
                }
                if (item.reqQuantity === "") {
                    error[`reqQuantity${i + 1}`] = 'Required'
                    flag = true;
                }
                else if (item.reqQuantity == 0) {
                    error[`reqQuantity${i + 1}`] = 'Not valid'
                    flag = true;
                }
                if (item.retailSalePrice === "") {
                    error[`retailSalePrice${i + 1}`] = 'Required';
                    flag = true;
                }
                if (item.retailSalePrice === 0) {
                    error[`retailSalePrice${i + 1}`] = 'Not valid';
                    flag = true;
                }
                // if (item.priority === "") {
                //     error[`priority${i + 1}`] = 'Required'
                //     flag = true;
                // }
                if (item.unit === "") {
                    error[`unit${i + 1}`] = 'Required'
                    flag = true;
                }
            })
        }
        setFormErrors(error);
        return flag;
    };

    const Save = () => {
        if (validation()) {
            return
        }
        const body = {
            enquiryId: enquiryId,
            isFinal: false,
            commonDiscount: commonDiscount,
            // project: project,
            quotationName: quotationName,
            items: productReq.map((ele) => {
                return {
                    productCode: ele.productCode,
                    productId: ele.productId,
                    productName: getProductData.find((e) => e.productCode === ele.productCode).productName,
                    reqQuantity: +ele.reqQuantity,
                    unit: ele.unit,
                    // discount: +ele.discount,
                    retailSalePrice: +ele.retailSalePrice,
                    // priority: ele.priority,
                    make: ele.make,
                    desc: ele.desc,
                }
            })
        }
        dispatch(addQuotationAction(body))
        onHide()
        setQuotationId('')
    }

    useEffect(() => {
        if (quotationId) {
            dispatch(getQuotationByIdAction(quotationId))
        }
    }, [quotationId])

    useEffect(() => {
        if (quotationId === '') {
            dispatch({
                type: GET_QUOTATION_BY_ID,
                payload: []
            })
        }
    }, [quotationId])

    useEffect(() => {
        if (getQuotationById && getQuotationById.data && quotationId) {
            setProject(getQuotationById.data.project)
            setProductReq(getQuotationById.data.items)
            if (getQuotationById.data.commonDiscount) {
                setCommonDiscount(getQuotationById.data.commonDiscount)
            }
            setQuotationName(getQuotationById.data.quotationName)
        }
    }, [getQuotationById])

    const onEdit = () => {
        if (validation()) {
            return
        }
        const body = {
            id: quotationId,
            enquiryId: enquiryId,
            commonDiscount: commonDiscount,
            quotationName: quotationName,
            items: productReq.map((ele) => {
                return {
                    productId: ele.productId,
                    productCode: ele.productCode,
                    productName: getProductData.find((e) => e.productCode === ele.productCode).productName,
                    reqQuantity: ele.reqQuantity,
                    discount: ele.discount,
                    retailSalePrice: +ele.retailSalePrice,
                    unit: ele.unit,
                    // priority: ele.priority,
                    make: ele.make,
                    desc: ele.desc,
                }
            })
        }
        dispatch(editQuotationAction(body))
        onHide()
        setQuotationId('')
    }

    useEffect(() => {
        let data = productReq.map((item) => {
            return {
                ...item,
                showQty: getProductData?.find((ele) => ele?._id === item.productId)?.stock
            }
        })
        setProductReq(data)
    }, [getProductData])

    const handleChangeData = (e, i, name) => {
        setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' });
        let val = [...productReq];
        let regex = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (name === 'productCode') {
            val[i].productName = e.label
            const selectProduct = productReq.map(item => item.productId)
            if (selectProduct.includes(e.value)) {
                toast.error("You already selected this Product!");
                val[i].productCode = "";
                val[i].productId = "";
                val[i].showQty = "";
                val[i].unit = "";
                val[i].retailSalePrice = "";
            } else {
                val[i].productCode = getProductData?.find((ele) => ele?._id === e.value)?.productCode;
                let productId = getProductData?.find((ele) => ele?._id === e.value)?._id
                val[i].productId = productId
                val[i].showQty = getProductData?.find((ele) => ele?._id === e.value)?.stock
                val[i].unit = getProductData?.find((ele) => ele?._id === e.value)?.unitOfMeasurement;
                val[i].retailSalePrice = getProductData?.find((ele) => ele?._id === e.value)?.retailSalePrice;
            }
            setProductReq(val);
        } else if (name === "reqQuantity") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                val[i].reqQuantity = e.target.value;
                setProductReq(val);
                setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' });
            }
        } else if (name === "retailSalePrice") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                val[i].retailSalePrice = e.target.value;
                setProductReq(val);
                setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' });
            }
        } else if (name === "deliveredQuantity") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                val[i].deliveredQuantity = e.target.value;
                setProductReq(val);
            }
        } else if (name === "desc") {
            val[i].desc = e.target.value;
            setProductReq(val);
        // } else if (name === "discount") {
        //     let numberRex = /^(?:\d*\.\d{0,2}|\d+)$/;
        //     if (
        //         !e.target.value ||
        //         e.target.value === "" ||
        //         numberRex.test(e.target.value)
        //     ) {
        //         val[i].discount = e.target.value;
        //         setProductReq(val);
        //     }
        } else if (name === "make") {
            val[i].make = e.target.value;
            setProductReq(val);
        }
        // else if (name === "priority") {
        //     val[i].priority = e.target.value;
        //     setProductReq(val);
        // }
        else if (name === "unit") {
            val[i].unit = e.target.value;
            setProductReq(val);
        }
    };

    const addData = () => {
        let val = [...productReq];
        val.push({
            productId: '',
            productName: '',
            productCode: '',
            reqQuantity: '',
            // discount: 0,
            retailSalePrice: "",
            // deliveredQuantity: '0',
            desc: '',
            make: '',
            // priority: '',
            unit: '',
            showQty: 0
        });
        setProductReq(val);
    };

    const Remove = (i) => {
        if (productReq.length > 1) {
            let val = [...productReq];
            val.splice(i, 1);
            setProductReq(val);
        }
    }

    const Blur = () => {
        setRotateArrow('')
    }

    const Click = (name) => {
        if (rotateArrow === name) {
            setRotateArrow('')
        } else {
            setRotateArrow(name)
        }
    }

    const selectValue = (value, name) => {
        if (name === "project") {
            return projectOption.find((ele) => ele.value === value)
        } else if (name === "productCode") {
            return productOption.find((ele) => ele.value === value)
        }
    }

    useEffect(() => {
        if (getProductData) {
            let temp = getProductData.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }))
            setProductOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.productCode ? `${ele.productName}` : ele.productName
                }
            }))
        }
    }, [getProductData]);

    const handleChangeProject = (e) => {
        setFormErrors({ ...formErrors, project: '' })
        setProject(e.value)
        setProductReq([{
            productId: '',
            productName: '',
            productCode: '',
            reqQuantity: '',
            // deliveredQuantity: '0',
            desc: '',
            make: '',
            // priority: '',
            unit: '',
            showQty: 0
        }])
    }

    // const onConfirm = () => {
    //     if (addPRId) {
    //         dispatch(editPurchaseRequestAction({ ...PRData, adminNotification: extraUsedProducts.join(",") }))
    //     } else {
    //         dispatch(addPurchaseRequestAction({ ...PRData, adminNotification: extraUsedProducts.join(",") }))
    //     }
    // }

    useEffect(() => {
        return () => {
            setQuotationId('')
        }
    }, []);

    const handleChange = (e, i) => {
        const option = productOption?.find((item) => item.value === e.value);
        handleChangeData(option, i, 'productCode');
    }

    useEffect(() => {
        setTempObj({
            label: productReq[0].productName,
            value: productReq[0].productId
        });
    }, [productReq]);

    useEffect(() => {
        setFilteredProducts([...filteredProducts, tempObj]);
    }, [tempObj]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            // size="xl"
            dialogClassName="modal-90w"
            keyboard={false}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Create Quotation"}</Modal.Title>
            </Modal.Header>

            <Modal.Body className='generate-quatation-modal over-flow-scroll'>
                <div className='quotation-section'>
                    <Row>
                        <Col lg={3}>
                            <div className="small-input mt-2">
                                <div className="users-form-info ms-3">
                                    <div className="users-form-info add-remark-modal mx-0">
                                        <div className='d-flex justify-content-between align-items-center mb-0'>
                                            <label className='fw-bold'>Quotation Name</label>
                                        </div>
                                        <div className="users-label">
                                            <div>
                                                <input type="text" placeholder='Quotation Name' onChange={(e) => setQuotationName(e.target.value)} value={quotationName} />
                                            </div>
                                        </div>
                                    </div>
                                    {formErrors && formErrors?.quotationName && <label style={{ color: "red" }}>{formErrors.quotationName}</label>}
                                </div>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="small-input mt-2">
                                <div className="users-form-info ms-3">
                                    <div className="users-form-info add-remark-modal mx-0">
                                        <div className='d-flex justify-content-between align-items-center mb-0'>
                                            <label className='fw-bold'>Common Discount(%)</label>
                                        </div>
                                        <div className="users-label">
                                            <div className="">
                                                <input type="Number" placeholder='Common Discount' onChange={(e) => setCommonDiscount(e.target.value)} value={commonDiscount} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {productReq?.length > 0 && productReq?.map((data, i) => (
                    <div key={i}>
                        <div style={{ display: "flex" }}>
                            <h5 className='mt-4 pt-3' style={{ width: "20px" }}>{i + 1}</h5>
                            <Row style={{ flex: 1 }}>
                                <Col xs={12}>
                                    <Row>
                                        <Col xxl={5} md={4} sm={6}>
                                            <div className="mt-3 small-input">
                                                <div className="users-form-info">
                                                    <div className="multi-select">
                                                        <label>Product</label>
                                                        <Select
                                                            cacheOptions
                                                            placeholder="Select Product"
                                                            onBlur={() => Blur()}
                                                            value={data.productId ? selectValue(data.productId, "productCode") : filteredProducts.label}
                                                            options={productOption}
                                                            onChange={(e) => handleChange(e, i)}
                                                            style={{ marginTop: "unset", paddingRight: 30, minHeight: 42 }}
                                                            maxMenuHeight={100}
                                                        />
                                                    </div>
                                                    {data.unit && <label style={{ fontWeight: "bold", textAlign: 'end', width: '100%' }}>{data.unit}</label>}
                                                    {formErrors && formErrors[`productCode${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`productCode${i + 1}`]}</label>}
                                                    {/* {productReq[i].productCode && <label>Product Code : {productReq[i].productCode}</label>} */}
                                                </div>
                                            </div>
                                            {/* <div style={{ textAlign: 'end' }}>{data.unit}</div> */}
                                        </Col>
                                        <Col xxl={1} md={4} sm={6}>
                                            <div className="mt-3 small-input">
                                                <div className="users-form-info">
                                                    <div className="users-label">
                                                        <label>Req Qty</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Req qty"
                                                            onChange={(e) => handleChangeData(e, i, 'reqQuantity')}
                                                            value={data.reqQuantity}
                                                        // disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                                        />
                                                        {data.productId && <label title='On Hand' style={{ fontSize: "14px" }}>OH: {getProductData?.find((ele) => ele?._id === data.productId)?.stock}</label>}
                                                    </div>
                                                    {formErrors && formErrors[`reqQuantity${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`reqQuantity${i + 1}`]}</label>}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={1} md={4} sm={6}>
                                            <div className="mt-3 small-input">
                                                <div className="users-form-info">
                                                    <div className="users-label">
                                                        <label title='Retail Sale Price'>RSP</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Retail Sale Price"
                                                            onChange={(e) => handleChangeData(e, i, 'retailSalePrice')}
                                                            value={data.retailSalePrice}
                                                        />
                                                    </div>
                                                    {data?.productId && <label style={{ fontSize: "14px", fontWeight: "bold" }} title='Buying Price'>BP: {getProductData?.find((ele) => ele?._id === data.productId)?.buyingPrice}</label>}
                                                    {formErrors && formErrors[`retailSalePrice${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`retailSalePrice${i + 1}`]}</label>}
                                                </div>
                                            </div>
                                        </Col>
                                        {/* {PR === 'store' && <Col xl={1} lg={6} md={6}>
                                            <div className="mt-3 small-input me-5 me-xl-0">
                                                <div className="users-form-info ms-3 w-100">
                                                    <div className="users-label">
                                                        <label>Delv Qty</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter delivered qty"
                                                            onChange={(e) => handleChangeData(e, i, 'deliveredQuantity')}
                                                            value={data.deliveredQuantity}
                                                            disabled={getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                                        />
                                                    </div>
                                                    {formErrors && formErrors[`deliveredQuantity${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`deliveredQuantity${i + 1}`]}</label>}
                                                </div>
                                            </div>
                                        </Col>} */}
                                        {/* <Col xl={2} lg={6} md={6}>
                                        <div className="mt-3 small-input me-5 me-xl-0">
                                            <div className="users-form-info ms-3 w-100">
                                                <div className="users-label">
                                                    <label>Priority</label>
                                                    <div className="users-label">
                                                        <div className="position-relative select-icon" >
                                                            <img src={img} className={`${rotateArrow === `priority${i}` && "rotateArrow"}`} alt="" />
                                                            <select className="form-control" value={data.priority} onBlur={() => Blur()} onClick={() => Click(`priority${i}`)} onChange={(e) => handleChangeData(e, i, 'priority')} >
                                                                <option value="">Priority</option>
                                                                <option value={Priority.Low}>Low</option>
                                                                <option value={Priority.Medium}>Medium</option>
                                                                <option value={Priority.High}>High</option>
                                                                <option value={Priority.Urgent}>Urgent</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formErrors && formErrors[`priority${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`priority${i + 1}`]}</label>}
                                            </div>
                                        </div>
                                    </Col> */}
                                        {/* <Col xxl={1} md={4} sm={6}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-3 w-100">
                                                <div className="users-label">
                                                    <label>Discount</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="discount"
                                                        onChange={(e) => handleChangeData(e, i, 'discount')}
                                                        value={data.discount}
                                                    />
                                                </div>
                                                {/* {formErrors && formErrors[`discount${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`discount${i + 1}`]}</label>} */}
                                        {/* </div>
                                        </div>
                                    </Col> */}
                                        {/* <Col xxl={1} md={4} sm={6}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-3 w-100">
                                                <div className="users-label">
                                                    <label>Unit</label>
                                                    <div className="users-label">
                                                        <div className="position-relative select-icon" >
                                                            <img src={img} className={`${rotateArrow === `unit${i}` && "rotateArrow"}`} alt="" />
                                                            <select className="form-control" value={data.unit} onBlur={() => Blur()} onClick={() => Click(`unit${i}`)} onChange={(e) => handleChangeData(e, i, 'unit')} >
                                                                <option value="">Unit</option>
                                                                <option value={Unit.KG}>KG</option>
                                                                <option value={Unit.LTR}>LTR</option>
                                                                <option value={Unit.NOS}>NOS</option>
                                                                <option value={Unit.MTR}>MTR</option>
                                                                <option value={Unit.FEET}>FEET</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formErrors && formErrors[`unit${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`unit${i + 1}`]}</label>}
                                            </div>
                                        </div>
                                    </Col> */}
                                        <Col xxl={2} md={4} sm={6} className="pr">
                                            <div className="mt-3 small-input">
                                                <div className="users-form-info">
                                                    <div className="users-label">
                                                        <label>Description</label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Description"
                                                            onChange={(e) => handleChangeData(e, i, 'desc')}
                                                            value={data.desc}
                                                            // disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                                            rows={1}
                                                        />
                                                    </div>
                                                    {formErrors && formErrors[`desc${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`desc${i + 1}`]}</label>}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={2} md={4} sm={6} className="pr">
                                            <div className="mt-3 small-input">
                                                <div className="users-form-info">
                                                    <div className="users-label">
                                                        <label>Make</label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Make"
                                                            onChange={(e) => handleChangeData(e, i, 'make')}
                                                            rows={1}
                                                            value={data.make}
                                                        // disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                                        />
                                                    </div>
                                                    {formErrors && formErrors[`make${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`make${i + 1}`]}</label>}
                                                </div>
                                            </div>
                                        </Col>
                                        {<Col xxl={1} md={2} sm={6} className="ms-xl-0 ms-3 mt-3 mt-xl-5">
                                            {getPurchaseRequestByIdData?.data?.status !== "Delivered" && <div className="users-form-info text-xl-center mx-0">
                                                <div className="">
                                                    <label> </label>
                                                    {i === productReq.length - 1 && (
                                                        <img
                                                            src={Plusicon}
                                                            onClick={() => { addData() }}
                                                            width="30"
                                                            style={{ cursor: "pointer" }}
                                                            alt=""
                                                        />
                                                    )}
                                                    {productReq.length > 1 && <img
                                                        src={Minusicon}
                                                        onClick={() => Remove(i)}
                                                        width="35"
                                                        style={{ cursor: "pointer" }}
                                                        alt=""
                                                    />}
                                                </div>
                                            </div>}
                                        </Col>}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        {productReq.length > i + 1 && <hr />}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                {(quotationId && IsShowEditButton) && <Button className='adduser-btn' onClick={() => { onEdit() }}> Edit </Button>}
                <Button className='adduser-btn' onClick={() => { Save() }} >Save</Button>
                <button className='cancel-button' onClick={() => { onHide() }}>Cancel</button>
            </Modal.Footer>
            {/* {showConfirmation &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText={"Ok"}
                    confirmBtnBsStyle={"danger"}
                    title="Are you sure?"
                    onConfirm={() => { onConfirm() }}
                    onCancel={() => { setShowConfirmation(true), onHide() }}
                    focusCancelBtn
                >Your {PR === "store" ? "Delivered" : "Requested"} product is more then final material. <br />In this case we send one notification to admin.

                </SweetAlert>
            } */}
        </Modal>
    )
}

export default GenerateQuatation