import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import img from "../../assets/image/select.png";
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";
import { toast } from 'react-toastify'
import { getProductByFinalMaterialAction, getProductDropDownAction } from '../../redux/actions/productAction';
import { Unit, UserRoles } from '../../helper/Constant';
import Select from 'react-select';
import { RoleContext } from '../../helper/RoleProvider';
import { addReturnMaterialAction, editReturnMaterialAction, getAllReturnMaterialAction, getReturnMaterialByIdAction } from '../../redux/actions/returnMaterialAction';
import ReactDatePicker from 'react-datepicker';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import usePaginationHooks from '../../Hooks/paginationHooks';
import { isAxiosError } from "axios"
import InvalidDataConfirmation from '../view-modals/InvalidData';

const AddReturnMaterial = ({ show, onHide, EditId }) => {

    const dispatch = useDispatch()
    const userRole = useContext(RoleContext);
    const [invalidDataModal, setInvalidDataModal] = useState(false)
    const [project, setProject] = useState('')
    const [returnDate, setReturnDate] = useState(new Date())
    const [returnMaterial, setReturnMaterial] = useState([
        {
            productCode: '',
            retailSalePrice: '',
            productId: '',
            productName: '',
            quantity: '',
            price: '',
            desc: '',
            make: '',
            unit: '',
            onHand: '',
        },
    ]);
    const addReturnMaterial = () => toast.success('Add Return Material Successfully');
    const editReturnMaterial = () => toast.success('Edit Return Material Successfully');
    const [formErrors, setFormErrors] = useState()
    const [rotateArrow, setRotateArrow] = useState('');
    const [product, setProduct] = useState([])
    const [projectOption, setProjectOption] = useState([])
    const productData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    const addReturnMaterialData = useSelector((state) => state.add_Return_Material.addReturnMaterial)
    const getReturnMaterialByIdData = useSelector((state) => state.get_Return_Material_By_Id.getReturnMaterialById)
    const editReturnMaterialData = useSelector((state) => state.edit_Return_Material.editReturnMaterial)
    const getAllProject = useSelector((state) => state.get_All_Project.getAllProject.data)
    const { getAllPRProductByProject } = usePaginationHooks()
    // const getProductByFinalMaterialData = useSelector((state) => state.get_Product_By_Final_Material.getProductByFinalMaterial.data)


    // State for project wise products only
    const [deliveredProducts, setDeliveredProducts] = useState([])

    useEffect(() => {
        if (project || EditId) {
            getAllPRProductByProject('', '', '', 'Delivered', '', '', (project || EditId))
                .then((res) => {
                    setDeliveredProducts(res?.data ?? [])
                })
                .catch((error) => {
                    if (isAxiosError(error)) {
                        toast.error(error?.response?.data?.message)
                    } else {
                        toast.error(error?.message)
                    }
                })
        }
    }, [project, EditId])

    useEffect(() => {
        let body = '';
        dispatch(getAllProjectAction(body))
        dispatch(getProductDropDownAction())
        if (EditId) {
            dispatch(getReturnMaterialByIdAction(EditId))
        }
    }, [EditId])

    // useEffect(() => {
    //     if (project) {
    //         dispatch(getProductByFinalMaterialAction(project))
    //     }
    // }, [project])

    useEffect(() => {
        if (getReturnMaterialByIdData && getReturnMaterialByIdData.data && EditId) {
            setProject(getReturnMaterialByIdData.data.project)
            setReturnDate(new Date(getReturnMaterialByIdData.data?.returnDate))
            setReturnDate(new Date(getReturnMaterialByIdData.data?.returnDate))
            setReturnMaterial(getReturnMaterialByIdData.data.items.map((ele, i) => {
                const deliveredProd = deliveredProducts?.find((prod) => {
                    return prod?.productCode === ele?.productCode || prod?.productName === ele?.productName
                })
                return {
                    productCode: ele?.productCode,
                    retailSalePrice: productData?.find((item) => item?._id === ele.productId)?.retailSalePrice,
                    productId: ele.productId ? ele.productId : '',
                    productName: ele.productName ? ele.productName : '',
                    quantity: ele.quantity ? ele.quantity : '',
                    desc: ele.desc ? ele.desc : '',
                    make: ele.make ? ele.make : '',
                    unit: ele.unit ? ele.unit : '',
                    price: ele.price ? ele.price : '',
                    onHand: Number(deliveredProd?.deliveredQuantity ?? 0) - Number(deliveredProd?.totalUsedQty ?? 0),
                }
            }))
        }
    }, [getReturnMaterialByIdData, productData, EditId, deliveredProducts])

    useEffect(() => {
        if (getAllProject) {
            let temp = getAllProject.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
            setProjectOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.partyName
                }
            }))
        }
    }, [getAllProject])


    useEffect(() => {
        if (productData && productData.length > 0) {
            let temp = productData?.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }))
            setProduct(temp.map((item) => {
                return {
                    value: item.productCode,
                    label: item.productCode ? `${item.productName}` : item.productName,
                }
            }))
        }
    }, [productData])

    const validation = () => {
        let flag = false;
        const error = {};
        if (!project) {
            error.project = "Please select project";
            flag = true;
        }
        if (returnMaterial.length) {
            returnMaterial.map((item, i) => {
                const isDeliveredProd = deliveredProducts?.find((prod) => prod?.productCode === item?.productCode || prod?.productName === item?.productName);
                const isAvailable = productData?.find((prod) => prod?.productCode === item?.productCode || prod?.productName === item?.productName);
                if (item.productName === "") {
                    error[`productName${i + 1}`] = 'Required'
                    flag = true;
                } else if (!isDeliveredProd || !isAvailable) {
                    error[`productName${i + 1}`] = 'Not available or not delivered'
                    flag = false;
                }

                if (item.quantity === "") {
                    error[`quantity${i + 1}`] = 'Required'
                    flag = true;
                }
                else if (item.quantity == 0) {
                    error[`quantity${i + 1}`] = 'Required'
                    flag = true;
                }
                // if (item.price === "") {
                //     error[`price${i + 1}`] = 'Required'
                //     flag = true;
                // }
                // if (item.unit === "") {
                //     error[`unit${i + 1}`] = 'Required'
                //     flag = true;
                // }
                if (item.quantity > Number(item?.onHand ?? 0) && !!isDeliveredProd) {
                    error[`quantity${i + 1}`] = `Greater than OH`
                    flag = true;
                }
            })
        }
        setFormErrors(error);
        return flag;
    };

    const isUnmatchProduct = () => {
        let flag = false;
        if (returnMaterial.length) {
            const unMatchProdAvail = returnMaterial.find((rmProd) => {
                const deliveredProd = deliveredProducts?.find((prod) => {
                    return prod?.productCode === rmProd?.productCode || prod?.productName === rmProd?.productName
                })
                const available = productData?.find((prod) => prod?.productCode === rmProd?.productCode || prod?.productName === rmProd?.productName);
                return (!deliveredProd || !available)
            })
            flag = !!unMatchProdAvail
        }
        return flag;
    }

    const Save = () => {
        const body = {
            project: project,
            returnDate: returnDate,
            items: returnMaterial.map((ele) => {
                return {
                    // productId: productData.find((item) => item.productCode === ele.productCode)._id,
                    productCode: ele.productCode,
                    productName: productData.find((item) => item.productCode === ele.productCode).productName,
                    quantity: ele.quantity,
                    desc: ele.desc,
                    make: ele.make,
                    unit: ele.unit ?? Unit.FEET,
                    price: ele.price ?? 0,
                }
            })
        }
        dispatch(addReturnMaterialAction(body))
    }

    useEffect(() => {
        if (addReturnMaterialData && addReturnMaterialData.data && addReturnMaterialData.status === 200) {
            addReturnMaterial(true)
            dispatch(getAllReturnMaterialAction())
            onHide()
        }
    }, [addReturnMaterialData])

    const Edit = () => {
        const body = {
            id: EditId,
            project: project,
            returnDate: returnDate,
            items: returnMaterial.map((ele) => {
                const prod = productData.find((item) => item.productCode === ele.productCode)
                return {
                    // productId: productData.find((item) => item.productCode === ele.productCode)._id,
                    productCode: ele.productCode,
                    productName: prod?.productName ?? ele?.productName,
                    quantity: ele.quantity,
                    desc: ele.desc,
                    make: ele.make,
                    unit: ele.unit ?? Unit.FEET,
                    price: ele.price ?? 0,
                }
            })
        }
        dispatch(editReturnMaterialAction(body))
    }

    const onSubmit = () => {
        if (validation()) {
            return
        }
        if (isUnmatchProduct()) {
            setInvalidDataModal(true)
        } else {
            handleConfirm();
        }
    }

    const handleConfirm = () => {
        if (EditId) {
            Edit()
        } else {
            Save()
        }
    }

    useEffect(() => {
        if (editReturnMaterialData && editReturnMaterialData.data) {
            if (editReturnMaterialData.status === 200) {
                editReturnMaterial(true)
                dispatch(getAllReturnMaterialAction())
                onHide()
            }
        }
    }, [editReturnMaterialData])

    const handleChangeData = (e, i, name) => {
        setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' })
        let val = [...returnMaterial];
        let regex = /^[0-9\b]+$/;
        let regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (name === 'productName') {
            const product = productData?.find((ele) => ele.productCode === e.value);
            const deliveredProd = deliveredProducts?.find((prod) => prod.productCode === e.value || prod.productName === e.label);
            val[i].productCode = e.value
            val[i].productName = e.label
            val[i].productCode = product?.productCode
            val[i].retailSalePrice = product?.retailSalePrice
            val[i].price = product?.buyingPrice
            val[i].unit = product?.unitOfMeasurement
            val[i].onHand = Number(deliveredProd?.deliveredQuantity ?? 0) - Number(deliveredProd?.totalUsedQty ?? 0)
        } else if (name === "quantity") {
            if (e.target.value === "" || regexwithdecimal.test(e.target.value)) {
                val[i].quantity = e.target.value;
            }
        } else if (name === "deliveredQuantity") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                val[i].deliveredQuantity = e.target.value;
            }
        }
        // else if (name === "price") {
        //     if (e.target.value === "" || regexwithdecimal.test(e.target.value)) {
        //         val[i].price = e.target.value;
        //     }
        // } 
        else if (name === "desc") {
            val[i].desc = e.target.value;
        } else if (name === "make") {
            val[i].make = e.target.value;
        } else if (name === "priority") {
            val[i].priority = e.target.value;
        }
        // else if (name === "unit") {
        //     val[i].unit = e.target.value;
        // }
        setReturnMaterial(val);
    };

    const addData = () => {
        let val = [...returnMaterial];
        val.push({
            productCode: '',
            productName: '',
            quantity: '',
            desc: '',
            make: '',
            unit: '',
            price: '',
            retailSalePrice: '',
            onHand: '',
        });
        setReturnMaterial(val);
    };

    const Remove = (i) => {
        if (returnMaterial.length > 1) {
            let val = [...returnMaterial];
            val.splice(i, 1);
            setReturnMaterial(val);
        }
    }

    const Blur = () => {
        setRotateArrow('')
    }

    const Click = (name) => {
        if (rotateArrow === name) {
            setRotateArrow('')
        } else {
            setRotateArrow(name)
        }
    }

    const selectValue = (value, name) => {
        if (name === "project") {
            return projectOption.find((ele) => ele.value === value)
        } else if (name === "productName") {
            return product.find((ele) => ele.value === value)
        }
    }

    const handleChangeProject = (e) => {
        setProject(e.value)
        setFormErrors({ ...formErrors, project: '' })
        setReturnMaterial([
            {
                productCode: '',
                retailSalePrice: '',
                productId: '',
                productName: '',
                quantity: '',
                price: '',
                desc: '',
                make: '',
                unit: '',
                onHand: '',
            }
        ])
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{EditId ? "Edit Return Material" : "Add Return Material"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='over-flow-scroll'>
                <Row>
                    <Col lg={4}>
                        <div className="small-input mt-3">
                            <div className="users-form-info ms-3">
                                <div className="users-form-info add-remark-modal mx-0">
                                    <div className="multi-select w-100">
                                        <label>Project</label>
                                        <Select
                                            value={selectValue(project, "project")}
                                            onChange={(e) => handleChangeProject(e)}
                                            options={projectOption}
                                        />
                                    </div>
                                </div>
                                {formErrors?.project && <span style={{ color: "red" }}>{formErrors.project}</span>}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mt-3 small-input me-5 me-xl-0">
                            <div className="users-form-info w-100">
                                <div className="users-label enquery-datepicker">
                                    <label>Return Date</label>
                                    <ReactDatePicker id='currentDate' dateFormat="dd/MM/yyyy" selected={returnDate} onChange={(e) => setReturnDate(e)} autoComplete='off' disabled={userRole !== UserRoles.Admin} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr />
                {/* <div className='over-flow-scroll'> */}
                {returnMaterial.length > 0 && returnMaterial.map((data, i) => {
                    return (
                        <div key={i}>
                            <div style={{ display: "flex" }}>
                                <h5 className='mt-4 pt-3' style={{ width: "20px" }}>{i + 1}</h5>
                                <Row style={{ flex: 1 }}>
                                    <Col xs={12}>
                                        <Row>
                                            <Col xl={5} lg={6} md={6}>
                                                <div className="mt-3 small-input me-5 me-xl-0">
                                                    <div className="users-form-info ms-3 w-100">
                                                        <div className="multi-select">
                                                            <label>Product Name</label>
                                                            {/* <Select
                                                            placeholder="Select Product"
                                                            value={data.productCode && selectValue(data.productCode, "productName")}
                                                            onChange={(e) => handleChangeData(e, i, 'productName')}
                                                            options={product}
                                                        /> */}
                                                            {/* <div className="users-label">
                                                            <div className="position-relative select-icon" >
                                                                <img src={img} className={`${rotateArrow === `priority${i}-${i}` && "rotateArrow"}`} alt="" style={{ marginTop: 2, marginRight: 4 }} />
                                                                <select
                                                                    className="form-control"
                                                                    value={data?.productCode}
                                                                    onBlur={() => Blur()}
                                                                    onClick={() => Click(`priority${i}-${i}`)}
                                                                    onChange={(e) => {
                                                                        const option = product.find((item) => item.value === e.target.value);
                                                                        handleChangeData(option, i, 'productName');
                                                                    }}
                                                                    style={{ marginTop: "unset", paddingRight: 30 }}
                                                                >
                                                                    <option value="" style={{ display: "none" }}>Select Product</option>
                                                                    {product?.map((ele, index) => <option key={index} value={ele.value} style={{ overflowWrap: "break-word" }}>{ele.label}</option>)}
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                            <Select
                                                                cacheOptions
                                                                placeholder="Select Product"
                                                                onBlur={() => Blur()}
                                                                value={product.find(item => item.value === data.productCode)}
                                                                options={product}
                                                                onChange={(e) => {
                                                                    const option = product?.find((item) => item.value === e.value);
                                                                    handleChangeData(option, i, 'productName');
                                                                }}
                                                                style={{ marginTop: "unset", paddingRight: 30, minHeight: 42 }}
                                                                maxMenuHeight={200}
                                                            />
                                                        </div>
                                                        {formErrors && formErrors[`productName${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`productName${i + 1}`]}</label>}
                                                        {/* {returnMaterial[i].productCode && <label>Product Code : {returnMaterial[i].productCode}</label>} */}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={1} lg={6} md={6}>
                                                <div className="mt-3 small-input me-5 me-xl-0">
                                                    <div className="users-form-info ms-3 w-100">
                                                        <div className="users-label">
                                                            <label>Qty</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Quantity"
                                                                onChange={(e) => handleChangeData(e, i, 'quantity')}
                                                                value={data.quantity}
                                                            />
                                                        </div>
                                                        {!!(data?.productCode || data?.productName) && (
                                                            <p
                                                                title='On Hand'
                                                                style={{
                                                                    fontSize: "14px",
                                                                    marginBottom: 0,
                                                                    fontWeight: 600
                                                                }}
                                                            >
                                                                OH : {Number(data?.onHand ?? 0)}
                                                            </p>
                                                        )}
                                                        {formErrors && formErrors[`quantity${i + 1}`] && (
                                                            <p
                                                                style={{
                                                                    color: "red",
                                                                    marginBottom: 0

                                                                }}
                                                            >
                                                                {formErrors[`quantity${i + 1}`]}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            {/* <Col xl={1} lg={6} md={6}>
                                            <div className="mt-3 small-input me-5 me-xl-0">
                                                <div className="users-form-info ms-3 w-100">
                                                    <div className="users-label">
                                                        <label>Price</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Price"
                                                            onChange={(e) => handleChangeData(e, i, 'price')}
                                                            value={data.price}
                                                        />
                                                    </div>
                                                    {formErrors && formErrors[`price${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`price${i + 1}`]}</label>}
                                                    {returnMaterial[i].retailSalePrice && <label>Price : {returnMaterial[i].retailSalePrice}</label>}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xl={1} lg={6} md={6}>
                                            <div className="mt-3 small-input me-5 me-xl-0">
                                                <div className="users-form-info ms-3 w-100">
                                                    <div className="users-label">
                                                        <label>Unit</label>
                                                        <div className="users-label">
                                                            <div className="position-relative select-icon" >
                                                                <img src={img} className={`${rotateArrow === `unit${i}` && "rotateArrow"}`} alt="" />
                                                                <select className="form-control" value={data.unit} onBlur={() => Blur()} onClick={() => Click(`unit${i}`)} onChange={(e) => handleChangeData(e, i, 'unit')}>
                                                                    <option value="">Unit</option>
                                                                    <option value={Unit.KG}>KG</option>
                                                                    <option value={Unit.LTR}>LTR</option>
                                                                    <option value={Unit.NOS}>NOS</option>
                                                                    <option value={Unit.MTR}>MTR</option>
                                                                    <option value={Unit.FEET}>FEET</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {formErrors && formErrors[`unit${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`unit${i + 1}`]}</label>}
                                                </div>
                                            </div>
                                        </Col> */}
                                            <Col xl={3} lg={6} md={6} className="pr">
                                                <div className="mt-3 small-input me-5 me-xl-0">
                                                    <div className="users-form-info ms-3 w-100">
                                                        <div className="users-label">
                                                            <label>Description</label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Description"
                                                                onChange={(e) => handleChangeData(e, i, 'desc')}
                                                                value={data.desc}
                                                                rows={1}
                                                            />
                                                        </div>
                                                        {formErrors && formErrors[`desc${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`desc${i + 1}`]}</label>}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={2} lg={6} md={6} className="pr">
                                                <div className="mt-3 small-input me-5 me-xl-0">
                                                    <div className="users-form-info ms-3 w-100">
                                                        <div className="users-label">
                                                            <label>Make</label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Make"
                                                                onChange={(e) => handleChangeData(e, i, 'make')}
                                                                value={data.make}
                                                                rows={1}
                                                            />
                                                        </div>
                                                        {formErrors && formErrors[`make${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`make${i + 1}`]}</label>}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={1} lg={2} md={2} sm={2} xs={2} className="ms-xl-0 ms-3 mt-3 mt-xl-5">
                                                <div className="users-form-info text-xl-center mx-0">
                                                    <div className="">
                                                        <label> </label>
                                                        {i === returnMaterial.length - 1 && (
                                                            <img
                                                                src={Plusicon}
                                                                onClick={() => { addData() }}
                                                                width="30"
                                                                style={{ cursor: "pointer" }}
                                                                alt=""
                                                            />
                                                        )}
                                                        {returnMaterial.length > 1 && <img
                                                            src={Minusicon}
                                                            onClick={() => Remove(i)}
                                                            width="35"
                                                            style={{ cursor: "pointer" }}
                                                            alt=""
                                                        />}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )
                })}
                {/* </div> */}
            </Modal.Body>
            <Modal.Footer>
                <Button className='adduser-btn' onClick={onSubmit} >{EditId ? "Edit" : "Save"}</Button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>
            <InvalidDataConfirmation
                show={invalidDataModal}
                onHide={() => setInvalidDataModal(false)}
                onConfirm={handleConfirm}
            />
        </Modal>
    )
}

export default AddReturnMaterial