import { ADD_EMPLOYEE_REPORT, ADD_EMPLOYEE_REPORT_ERROR, ADD_EMPLOYEE_REPORT_LOADING, DELETE_EMPLOYEE_REPORT, DELETE_EMPLOYEE_REPORT_ERROR, DELETE_EMPLOYEE_REPORT_LOADING, EDIT_EMPLOYEE_REPORT, EDIT_EMPLOYEE_REPORT_ERROR, EDIT_EMPLOYEE_REPORT_LOADING, GET_ALL_EMPLOYEE_REPORT, GET_ALL_EMPLOYEE_REPORT_ERROR, GET_ALL_EMPLOYEE_REPORT_LOADING, GET_EMPLOYEE_REPORT_BY_ID, GET_EMPLOYEE_REPORT_BY_ID_ERROR, GET_EMPLOYEE_REPORT_BY_ID_LOADING } from "../type"

const addEmployeeReportData = {
    loading: false,
    addEmployeeReport: [],
    error: '',
};

const getEmployeeReportByIdData = {
    loading: false,
    getEmployeeReportById: [],
    error: '',
};

const getAllEmployeeReportData = {
    loading: false,
    getAllEmployeeReport: [],
    error: '',
};

const editEmployeeReportData = {
    loading: false,
    editEmployeeReport: [],
    error: '',
};

const deleteEmployeeReportData = {
    loading: false,
    deleteEmployeeReport: [],
    error: '',
};

export const addEmployeeReportReducer = (state = addEmployeeReportData, action) => {
    switch (action.type) {
        case ADD_EMPLOYEE_REPORT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_EMPLOYEE_REPORT:
            return {
                ...state,
                addEmployeeReport: action.payload,
            };
        case ADD_EMPLOYEE_REPORT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getEmployeeReportByIdReducer = (state = getEmployeeReportByIdData, action) => {
    switch (action.type) {
        case GET_EMPLOYEE_REPORT_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_EMPLOYEE_REPORT_BY_ID:
            return {
                ...state,
                getEmployeeReportById: action.payload,
            };
        case GET_EMPLOYEE_REPORT_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllEmployeeReportReducer = (state = getAllEmployeeReportData, action) => {
    switch (action.type) {
        case GET_ALL_EMPLOYEE_REPORT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_EMPLOYEE_REPORT:
            return {
                ...state,
                getAllEmployeeReport: action.payload,
            };
        case GET_ALL_EMPLOYEE_REPORT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editEmployeeReportReducer = (state = editEmployeeReportData, action) => {
    switch (action.type) {
        case EDIT_EMPLOYEE_REPORT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_EMPLOYEE_REPORT:
            return {
                ...state,
                editEmployeeReport: action.payload,
            };
        case EDIT_EMPLOYEE_REPORT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteEmployeeReportReducer = (state = deleteEmployeeReportData, action) => {
    switch (action.type) {
        case DELETE_EMPLOYEE_REPORT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_EMPLOYEE_REPORT:
            return {
                ...state,
                deleteEmployeeReport: action.payload,
            };
        case DELETE_EMPLOYEE_REPORT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
