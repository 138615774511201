import { GET_NOTIFICATION_BY_USER_ID, GET_NOTIFICATION_BY_USER_ID_ERROR, GET_NOTIFICATION_COUNT, GET_NOTIFICATION_COUNT_ERROR, READ_NOTIFICATION, READ_NOTIFICATION_ERROR } from "../type"

const getNotificationCountData = {
    getNotificationCount : [],
    error:'',
};

const readNotificationData = {
    readNotification : [],
    error:'',
};

const getNotificationByUserIdData = {
    getNotificationByUserId : [],
    error:'',
};

export const getNotificationCountReducer = (state = getNotificationCountData, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_COUNT:
            return {
                ...state,
                getNotificationCount: action.payload,
            };
        case GET_NOTIFICATION_COUNT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const readNotificationReducer = (state = readNotificationData, action) => {
    switch (action.type) {
        case READ_NOTIFICATION:
            return {
                ...state,
                readNotification: action.payload,
            };
        case READ_NOTIFICATION_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getNotificationByUserIdReducer = (state = getNotificationByUserIdData, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_BY_USER_ID:
            return {
                ...state,
                getNotificationByUserId: action.payload,
            };
        case GET_NOTIFICATION_BY_USER_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}