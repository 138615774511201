import { ADD_TERM_AND_CONDITION, ADD_TERM_AND_CONDITION_ERROR, ADD_TERM_AND_CONDITION_LOADING, DELETE_TERM_AND_CONDITION, DELETE_TERM_AND_CONDITION_ERROR, DELETE_TERM_AND_CONDITION_LOADING, EDIT_TERM_AND_CONDITION, EDIT_TERM_AND_CONDITION_ERROR, EDIT_TERM_AND_CONDITION_LOADING, GET_ALL_TERM_AND_CONDITION, GET_ALL_TERM_AND_CONDITION_ERROR, GET_ALL_TERM_AND_CONDITION_LOADING } from "../type";

const addTermAndConditionData = {
    loading: false,
    addTermAndCondition: [],
    error: '',
}

const getAllTermAndConditionData = {
    loading: false,
    getAllTermAndCondition: [],
    error: '',
}

const deleteTermAndConditionData = {
    loading: false,
    deleteTermAndCondition: [],
    error: '',
}
const editTermAndConditionData = {
    loading: false,
    editTermAndCondition: [],
    error: '',
}

export const addTermAndConditionReducer = (state = addTermAndConditionData, action) => {
    switch (action.type) {
        case ADD_TERM_AND_CONDITION_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_TERM_AND_CONDITION:
            return {
                ...state,
                addTermAndCondition: action.payload,
            };
        case ADD_TERM_AND_CONDITION_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllTermAndConditionReducer = (state = getAllTermAndConditionData, action) => {
    switch (action.type) {
        case GET_ALL_TERM_AND_CONDITION_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_TERM_AND_CONDITION:
            return {
                ...state,
                getAllTermAndCondition: action.payload,
            };
        case GET_ALL_TERM_AND_CONDITION_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editTermAndConditionReducer = (state = editTermAndConditionData, action) => {
    switch (action.type) {
        case EDIT_TERM_AND_CONDITION_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_TERM_AND_CONDITION:
            return {
                ...state,
                editTermAndCondition: action.payload,
            };
        case EDIT_TERM_AND_CONDITION_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
export const deleteTermAndConditionReducer = (state = deleteTermAndConditionData, action) => {
    switch (action.type) {
        case DELETE_TERM_AND_CONDITION_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_TERM_AND_CONDITION:
            return {
                ...state,
                deleteTermAndCondition: action.payload,
            };
        case DELETE_TERM_AND_CONDITION_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}


