import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import removeImg from '../../assets/image/delete-red.png'
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import { DELETE_EMPLOYEE_REPORT } from '../../redux/type';
import editIcon from '../../assets/image/edit.svg';
import { dateFormate } from '../../helper/utils';
import viewImg from '../../assets/image/eye.svg'
import { deleteEmployeeReport, getAllEmployeeReport } from '../../redux/actions/employeeReportAction';
import ViewEmployeeReport from '../../components/view-modals/ViewEmployeeReport';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const StaffDailyreport = () => {

    const gridRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Project, UserRoles.Maintance, UserRoles.Sales, UserRoles.Store, UserRoles.Account, UserRoles.Liasoning]
    const [deletepopup, setDeletepopup] = useState(false);
    const [columnDef, setColumnDefs] = useState([]);
    const [deleteId, setDeleteId] = useState()
    const [dailyReportTog, setDailyReportTog] = useState(false)
    const [dailyReportId, setDailyReportId] = useState('')
    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)
    const { getAllEmployeeReports } = usePaginationHooks()
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit);
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    // const getAllEmployeeReportData = useSelector((state) => state.get_All_Employee_Report.getAllEmployeeReport)
    const deleteEmployeeReportData = useSelector((state) => state.delete_Employee_Report.deleteEmployeeReport)
    const editEmployeeReportData = useSelector((state) => state.edit_Employee_Report.editEmployeeReport)

    // useEffect(() => {
    //     dispatch(getAllEmployeeReport())
    // }, [])

    // useEffect(() => {
    //     if (getAllEmployeeReportData && getAllEmployeeReportData.data && getAllEmployeeReportData.data.length > 0) {
    //         setDailyReport(getAllEmployeeReportData.data.map((ele) => {
    //             return {
    //                 date: ele.date ? ele.date : '',
    //                 km: ele.km ? ele.km : '',
    //                 employeeId: ele.employeeId ? ele.employeeId : '',
    //                 totalDuration: ele.totalDuration ? ele.totalDuration : '',
    //                 createdBy: ele.createdBy ? ele.createdBy : '',
    //                 _id: ele._id ? ele._id : '',
    //             }
    //         }))
    //     }
    // }, [getAllEmployeeReportData])


    const onEdit = (id) => {
        navigate(`edit?dailyreportId=${id}`)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    const onDelete = (id) => {
        dispatch(deleteEmployeeReport(id))
        setDeletepopup(false)
    }

    useEffect(() => {
        if (deleteEmployeeReportData && deleteEmployeeReportData.data && deleteEmployeeReportData.data.status === 200) {
            dispatch({
                type: DELETE_EMPLOYEE_REPORT,
                payload: []
            })
            // dispatch(getAllEmployeeReport())
            toast.success(deleteEmployeeReportData.data.message);
        }
    }, [deleteEmployeeReportData])

    useEffect(() => {
        if (RolesForAdd.includes(userRole)) {
            setColumnDefs(columnDefs)
        } else {
            setColumnDefs(NoRolecolumnDefs)
        }
    }, [])

    const columnDefs = [
        {
            headerName: 'Date',
            field: "date",

            cellRendererFramework: (params) =>
                <div>
                    <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
                </div>
        },
        { headerName: 'Employee', field: 'employeeId.name' },
        { field: 'totalDuration' },
        {
            headerName: 'Travel', field: 'km',
            cellRendererFramework: (params) =>
                <div>
                    <span>{`${params.value} KM`}</span>
                </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            sortable: false,
            cellRendererFramework: (params) => <div>
                {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) &&
                    <>
                        {/* <img src={editIcon} style={{ cursor: "pointer", height: "20px" }} onClick={() => onEdit(params?.value)} alt="" title="Edit" /> */}
                        <img src={removeImg} style={{ cursor: "pointer", height: "20px" }} className='ms-3' onClick={() => { setDeletepopup(true); setDeleteId(params?.value) }} alt="" title="Delete" /></>}
                <img src={viewImg} style={{ cursor: "pointer", height: "20px" }} className='ms-3' onClick={() => { setDailyReportTog(true); setDailyReportId(params?.value) }} alt="" title="View" />
            </div>
        }
    ]

    const NoRolecolumnDefs = [
        {
            headerName: 'Date',
            field: "date",

            cellRendererFramework: (params) =>
                <div>
                    <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
                </div>
        },
        { headerName: 'Employee', field: 'employeeId.name' },
        { field: 'totalDuration' },
        {
            headerName: 'Action',
            field: "_id",
            sortable: false,
            cellRendererFramework: (params) => <div>
                <img src={viewImg} style={{ cursor: "pointer", height: "20px" }} className='ms-3' onClick={() => { setDailyReportTog(true); setDailyReportId(params?.value) }} alt="" title="View" />
            </div>
        }
    ]

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    const onHide = () => {
        setDailyReportTog(false)
        setDailyReportId('')
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || deleteEmployeeReportData?.data?.status === 200 || editEmployeeReportData?.data?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllEmployeeReports(page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            // setgetAllReturnMaterialdata(res.data)
                            params.successCallback(res?.data.map((ele) => {
                                return {
                                    date: ele.date ? ele.date : '',
                                    km: ele.km ? ele.km : '',
                                    employeeId: ele.employeeId ? ele.employeeId : '',
                                    totalDuration: ele.totalDuration ? ele.totalDuration : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

        // setisFilter(false)
    }, [gridApi, limit, deleteEmployeeReportData, editEmployeeReportData, filter.search]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>{'Staff Daily Report'}</h2>
                    </div>
                    <div className='d-flex'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn me-3' onClick={() => clearFilter()}>Clear Filter</button>
                        <button className='adduser-btn' onClick={() => { navigate('add') }}>{'Add Staff Daily Report'}</button>
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDef}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        onCellClicked={(event) => {
                            const rowData = event.data;
                            if (rowData?.createdBy?._id === userData.id || userRole === UserRoles.Admin) {
                                handleRowClick(event)
                            }
                        }}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />

                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
            </div>
            {dailyReportTog && <ViewEmployeeReport show={dailyReportTog} onHide={() => onHide()} dailyReportId={dailyReportId} />}
            {deletepopup &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(deleteId)}
                    onCancel={() => { setDeletepopup(false) }}
                    focusCancelBtn
                >
                    You want to delete it
                </SweetAlert>
            }
        </>
    )
}

export default StaffDailyreport