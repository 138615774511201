import React, { useContext, useEffect } from 'react'
import linkarrow from "../../assets/img/link-arrow.png"
import { Link } from 'react-router-dom'
import AdminDashboard from './components/AdminDashboard'
import { RoleContext } from '../../helper/RoleProvider'
import SalesDashboard from './components/SalesDashboard'
import LiasoningDashboard from './components/LiasoningDashboard'
import ProjectDashboard from './components/ProjectDashboard'
import AccountDashboard from './components/AccountDashboard'
import StoreDashboard from './components/StoreDashboard'
import MaintenanceDashboard from './components/MaintenanceDashboard'
import { UserRoles } from '../../helper/Constant'
import { taskDashboardAction } from '../../redux/actions/taskAction'
import { useDispatch } from 'react-redux'

const Dashboard = () => {
  const dispatch = useDispatch()
  const roleName = useContext(RoleContext);

  useEffect(() => {
    dispatch(taskDashboardAction(''))
  }, [])

  return (
    <div className='p-4'>
      {/* <div className='mb-4 set-page-heade'>
        <div className='d-flex flex-column'>
          <h2>Dashboard</h2>
          
        </div>
      </div> */}
      {/* <div className="users-form"> */}
      {roleName === UserRoles.Admin && <AdminDashboard></AdminDashboard>}
      {roleName === UserRoles.Sales && <SalesDashboard></SalesDashboard>}
      {roleName === UserRoles.Liasoning && <LiasoningDashboard></LiasoningDashboard>}
      {roleName === UserRoles.Project && <ProjectDashboard></ProjectDashboard>}
      {roleName === UserRoles.Account && <AccountDashboard></AccountDashboard>}
      {roleName === UserRoles.Store && <StoreDashboard></StoreDashboard>}
      {roleName === UserRoles.Maintance && <MaintenanceDashboard></MaintenanceDashboard>}
      {/* <div className='dashboard-fack-text'>
          <h1>Coming soon</h1>
          
        </div> */}
      {/* </div> */}
    </div>
  )
}

export default Dashboard