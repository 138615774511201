import { ApiGet } from "../../helper/API/ApiData"
import { GET_NOTIFICATION_BY_USER_ID, GET_NOTIFICATION_BY_USER_ID_ERROR, GET_NOTIFICATION_COUNT, GET_NOTIFICATION_COUNT_ERROR, READ_NOTIFICATION, READ_NOTIFICATION_ERROR } from "../type"

export const getNotificationCountAction = () => async (dispatch) => {
    try {
        await ApiGet('notification/get_notification_count')
            .then((res) => {
                dispatch({
                    type: GET_NOTIFICATION_COUNT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
    }
    catch (err) {

        dispatch({
            type: GET_NOTIFICATION_COUNT_ERROR,
            payload: err
        })
    }
}

export const readNotificationAction = (id) => async (dispatch) => {
    try {
        await ApiGet(`notification/read_notification/${id}`)
            .then((res) => {
                dispatch({
                    type: READ_NOTIFICATION,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
    }
    catch (err) {

        dispatch({
            type: READ_NOTIFICATION_ERROR,
            payload: err
        })
    }
}

export const getNotificationByUserIdAction = () => async (dispatch) => {
    try {
        await ApiGet(`notification/get_notification_by_user_id`)
            .then((res) => {
                dispatch({
                    type: GET_NOTIFICATION_BY_USER_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

    }
    catch (err) {

        dispatch({
            type: GET_NOTIFICATION_BY_USER_ID_ERROR,
            payload: err
        })

    }
}