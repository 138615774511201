import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteFinalMaterial, getFinalMaterialByParantId } from "../../redux/actions/finalMaterial";
import { useState } from "react";
import { UserRoles } from '../../helper/Constant';
import SweetAlert from "react-bootstrap-sweetalert";
import AuthStorage from "../../helper/AuthStorage";
import STORAGEKEY from "../../config/APP/app.config";
import { useContext } from "react";
import removeImg from '../../assets/image/delete-red.png'
import { RoleContext } from "../../helper/RoleProvider";
import { GET_FINAL_MATERIAL_BY_PARANT_ID } from "../../redux/type";
const usrData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

const DeatailsComponent = (props) => {
    const { data } = props;
    const [deletepopup, setDeletepopup] = useState(false);
    const [paramsValue, setParamsValue] = useState();
    const userRole = useContext(RoleContext);
    const [finalMaterialData, setFinalMaterialData] = useState([])
    const dispatch = useDispatch()
    const getFinalMaterialByIdparantdata = useSelector((state) => state.get_Final_Material_By_Parant_Id.getFinalMaterialByParantId)
    useEffect(() => {
        if (data._id) {
            dispatch(getFinalMaterialByParantId(data._id))
        }
    }, [data])

    useEffect(() => {
        if (getFinalMaterialByIdparantdata && getFinalMaterialByIdparantdata.data) {
            const temp = getFinalMaterialByIdparantdata.data.map((ele) => {
                return {
                    productName: ele.productName,
                    quantity: ele.quantity,
                    unit: ele.unit,
                    buyingPrice: ele.buyingPrice,
                    retailSalePrice: ele.retailSalePrice,
                    labourCharge: ele.labourCharge,
                    _id: ele._id
                }
            })
            setFinalMaterialData(temp)
        }
    }, [getFinalMaterialByIdparantdata])

    const columnDefs = [
        {
            field: 'productName',
        },
        { field: 'quantity' },
        { field: 'unit' },
        { field: 'buyingPrice' },
        { field: 'retailSalePrice' },
        { field: 'labourCharge' },
        // { field: 'make' },
        // { field: 'desc', headerName: 'Description' },
        // {
        //     field: "createdBy",
        //     cellRendererFramework: (params) => <div>
        //         {params?.value?.name}
        //     </div>
        // },
        // { field: 'remark', headerName: 'Remark' },
        {
            headerName: 'Action',
            field: "_id",

            cellRendererFramework: (params) =>
                <div>
                    {/* {(params.data?.createdBy?._id === usrData.id || userRole === UserRoles.Admin || params.data?.projectManager._id === usrData.id) &&
                        <><img src={editIcon} style={{ cursor: "pointer", height: "20px" }} onClick={() => onEdit(params?.value)} title="Edit" alt='' /></>} */}
                    {(userRole === UserRoles?.Admin || params.data?.createdBy?._id === usrData.id) && <img src={removeImg} onClick={() => { setDeletepopup(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' />}
                    {/* {<img src={Plusicon} onClick={() => { setIsSubproduct({ open: true, id: params?.value, productId: params.data.productId._id }); console.log('params.data', params.data) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Add" alt='' />} */}
                </div>
        }
    ]

    const onDelete = (id) => {
        dispatch(deleteFinalMaterial(id))
    }
    useEffect(() => {
        return () => {
            dispatch({
                type: GET_FINAL_MATERIAL_BY_PARANT_ID,
                payload: []
            })
        }
    }, [])

    return (
        <div style={{ padding: "10px 50px", backgroundColor: "azure" }}>

            <div className="ag-theme-balham-dark" style={{ height: 200 }}>
                <AgGridReact
                    rowData={finalMaterialData}
                    rowHeight={30}
                    columnDefs={columnDefs}
                    defaultColDef={{ flex: 1 }}
                />
            </div>
            {deletepopup &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => { onDelete(paramsValue) }}
                    onCancel={() => { setDeletepopup(false); setParamsValue('') }}
                    focusCancelBtn
                >
                    You want to delete it
                </SweetAlert>
            }
        </div>
    );
};

export default DeatailsComponent;