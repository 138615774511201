import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_CONTRACTOR, EDIT_CONTRACTOR } from '../../redux/type';
import { toast } from "react-toastify";
import { fileRemoveAction, fileUploadAction } from '../../redux/actions/generalAction';
import Viewicon from '../../assets/image/view.png'
import { addContractorAction, editContractorAction, getAllContractorAction, getContractorByIdAction } from '../../redux/actions/contractorAction';
import { API } from '../../config/API/api.config';
import removeImg from '../../assets/image/delete-red.png'

const AddContractor = ({ show, onHide, contractorId }) => {

    const dispatch = useDispatch()
    const error = "GSTIN Already Exists.";
    const toastUpdate = () => toast.success("Contractor update successfully");
    const toastAdd = () => toast.success("Contractor added successfully");
    const [contractor, setContractor] = useState({
        name: "",
        mobileNo: "",
        adhaarNo: "",
        documentFile: "",
        bank_name: "",
        account_number: "",
        ifsc: "",
        pan_number: "",
    })
    const [file, setFile] = useState(undefined);
    const [formErrors, setFormErrors] = useState()
    const [fileError, setFileError] = useState('')

    const getContarctorData = useSelector((state) => state.get_Contractor_By_Id.getContractorById)
    const addContractorData = useSelector((state) => state.add_Contractor.addContractor)
    const editContractorData = useSelector((state) => state.edit_Contractor.editContractor)
    const fileData = useSelector((state) => state.file_Upload.fileUpload);

    useEffect(() => {
        if (contractorId !== "") {
            dispatch(getContractorByIdAction(contractorId))
        }
    }, [contractorId])

    useEffect(() => {
        if (getContarctorData && getContarctorData.data && contractorId) {
            setContractor({
                name: getContarctorData.data.name,
                mobileNo: getContarctorData.data.mobileNo,
                adhaarNo: getContarctorData.data.adhaarNo,
                documentFile: getContarctorData.data.documentFile,
                bank_name: getContarctorData.data.bank_name,
                account_number: getContarctorData.data.account_number,
                ifsc: getContarctorData.data.ifsc,
                pan_number: getContarctorData.data.pan_number,
            })
        }
    }, [getContarctorData])

    const handleChange = (e, name) => {
        let re = /^[0-9\b]+$/;
        setFormErrors({ ...formErrors, [name]: '' });
        if (name === "adhaarNo" || name === "mobileNo" || name === "account_number") {
            if (e.target.value === '' || re.test(e.target.value)) {
                setContractor({ ...contractor, [name]: e.target.value?.trimStart() });
            }
        } else {
            setContractor({ ...contractor, [name]: e.target.value?.trimStart() });
        }
    }

    const validation = () => {
        let flag = false;
        const error = {};

        if (!contractor?.name?.length) {
            error.name = "Please enter name";
            flag = true;
        }
        if (!contractor?.mobileNo?.length) {
            error.mobileNo = "Please enter mobile number";
            flag = true;
        } else if (contractor?.mobileNo?.length < 10) {
            error.mobileNo = "Please enter valid mobile number";
            flag = true;
        }
        if (!contractor?.adhaarNo?.length) {
            error.adhaarNo = "Please enter aadhaar number";
            flag = true;
        } else if (contractor?.adhaarNo?.length < 12) {
            error.adhaarNo = "Please enter valid aadhaar number";
            flag = true;
        }
        if (!contractor?.documentFile?.length) {
            error.documentFile = "Please select document file";
            flag = true;
        }

        setFormErrors(error);
        return flag;
    }

    const Save = () => {
        if (validation()) {
            return
        }
        if (fileError === '') {
            dispatch(addContractorAction(contractor))
        }
    }

    useEffect(() => {
        if (addContractorData && addContractorData.data) {
            if (addContractorData.message === "Contractor created successfully.") {
                dispatch({
                    type: ADD_CONTRACTOR,
                    payload: []
                })
                // dispatch(getAllContractorAction())
                toastAdd(true)
                onHide()
            }
        }
    }, [addContractorData])

    const Edit = () => {
        if (validation()) {
            return
        }
        let data = {
            ...contractor,
            id: contractorId
        }
        dispatch(editContractorAction(data))
    }

    useEffect(() => {
        if (editContractorData && editContractorData.data) {
            if (editContractorData.data.message === "Contractor updated successfully.") {
                dispatch({
                    type: EDIT_CONTRACTOR,
                    payload: []
                })
                // dispatch(getAllContractorAction())
                toastUpdate(true)
                onHide()
            }
        }
    }, [editContractorData])

    const UploadClick = () => {
        document.getElementById('documentFile').click()
    }

    useEffect(() => {
        if (file) {
            if (contractor.documentFile) {
                fileRemove(contractor.documentFile, '')
            }
            let formData = new FormData();
            formData.append("files", file);
            dispatch(fileUploadAction(formData));
            setContractor({ ...contractor, documentFile: file.name })
        }
    }, [file]);

    useEffect(() => {
        if (fileData) {
            setContractor({ ...contractor, documentFile: fileData.url })
        }
    }, [fileData])

    const fileClick = (name) => {
        window.open(`${API.endpoint}/${name}`, "_blank")
    }

    const fileRemove = (files, name) => {
        let body = {
            file: files.split('/')[1] ? files.split('/')[1] : files
        }
        dispatch(fileRemoveAction(body))
        if (name === "removeFile") {
            setContractor({ ...contractor, documentFile: '' })
        }
    }

    const cancel = () => {
        if (!contractorId) {
            if (contractor.documentFile) {
                fileRemove(contractor.documentFile, '')
            }
        }
        onHide()
    }

    const fileUpload = (e) => {
        let firsttemp = e.target.files[0].name.split('.')
        let fileexten = ['pdf', 'jpeg', 'jpg', 'png']
        if (fileexten.includes(firsttemp[firsttemp.length - 1])) {
            setFileError('')
            setFile(e.target.files[0])
        } else {
            setFile()
            setFileError('Please select valid document file')
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{contractorId ? "Edit Contractor" : "Add Contractor"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="name"
                                    value={contractor.name}
                                    onChange={(e) => handleChange(e, 'name')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.name && <span style={{ color: "red" }}>{formErrors.name}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Mobile No</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter mobile no"
                                    name="mobileNo"
                                    value={contractor.mobileNo}
                                    onChange={(e) => handleChange(e, 'mobileNo')}
                                    autoComplete="off"
                                    maxLength={10}
                                />
                            </div>
                            {formErrors?.mobileNo && <span style={{ color: "red" }}>{formErrors.mobileNo}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Aadhaar No</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter aadhaar no"
                                    name="adhaarno"
                                    value={contractor.adhaarNo}
                                    onChange={(e) => handleChange(e, 'adhaarNo')}
                                    autoComplete="off"
                                    maxLength={12}
                                />
                            </div>
                            {formErrors?.adhaarNo && <span style={{ color: "red" }}>{formErrors.adhaarNo}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Bank Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="bank_name"
                                    value={contractor.bank_name}
                                    onChange={(e) => handleChange(e, 'bank_name')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.bank_name && <span style={{ color: "red" }}>{formErrors.bank_name}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Account Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="account_number"
                                    value={contractor.account_number}
                                    onChange={(e) => handleChange(e, 'account_number')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.account_number && <span style={{ color: "red" }}>{formErrors.account_number}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>IFSC</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="ifsc"
                                    value={contractor.ifsc}
                                    onChange={(e) => handleChange(e, 'ifsc')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.ifsc && <span style={{ color: "red" }}>{formErrors.ifsc}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>PAN Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="pan_number"
                                    value={contractor.pan_number}
                                    onChange={(e) => handleChange(e, 'pan_number')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.pan_number && <span style={{ color: "red" }}>{formErrors.pan_number}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mt-3">
                            <div className="users-form-info m-0">
                                <label style={{ fontWeight: "600" }}>Document File</label>
                                <div className="upload-file" onClick={() => UploadClick()}>
                                    <label className='upload-file-lable' >Upload here</label>
                                    <input
                                        type="file"
                                        className="form-control d-none"
                                        id='documentFile'
                                        // onChange={(e) => { setFile(e.target.files[0]) }}
                                        onChange={(e) => { fileUpload(e) }}
                                    />
                                </div>
                                {contractor.documentFile ?
                                    <label style={{ color: "green", display: "flex", marginTop: '10px' }}>
                                        <img src={Viewicon} alt="View" width="20" height="20" style={{ marginRight: "5px", cursor: 'pointer' }} onClick={() => fileClick(contractor.documentFile)} />
                                        <img src={removeImg} alt="View" width="20" height="20" style={{ marginRight: "5px", cursor: 'pointer' }} onClick={() => fileRemove(contractor.documentFile, "removeFile")} />
                                        Uploaded File : {contractor.documentFile.split('/')[1] ? contractor.documentFile.split('/')[1] : contractor.documentFile}</label>
                                    :
                                    formErrors?.documentFile && <label style={{ color: "red" }}>{formErrors.documentFile}</label>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {fileError !== '' && <label style={{ color: 'red' }}>{fileError}</label>}
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { contractorId ? Edit() : Save() }}>{contractorId ? "Edit" : "Save"}</button>
                <button className='cancel-button' onClick={() => cancel()}>Cancel</button>
            </Modal.Footer>

        </Modal>
    )
}

export default AddContractor