import { GET_ENQUIRY_BY_MONTH_YEAR_WISE, GET_ENQUIRY_BY_MONTH_YEAR_WISE_ERROR, GET_ENQUIRY_BY_MONTH_YEAR_WISE_LOADING, GET_ENQUIRY_COUNT_BY_STATUS, GET_ENQUIRY_COUNT_BY_STATUS_ERROR, GET_ENQUIRY_COUNT_BY_STATUS_LOADING, GET_RECENT_ENQUIRY, GET_RECENT_ENQUIRY_ERROR, GET_RECENT_ENQUIRY_LOADING } from "../type"

const getRecentEnquiryData = {
    loading:false,
    getRecentEnquiry : [],
    error:'',
};

const getEnquiryByMonthYearWiseData = {
    loading:false,
    getEnquiryByMonthYearWise : [],
    error:'',
};

const getEnquiryCountByStatusData = {
    loading:false,
    getEnquiryCountByStatus : [],
    error:'',
};

export const getRecentEnquiryReducer = (state = getRecentEnquiryData, action) => {
    switch (action.type) {
        case GET_RECENT_ENQUIRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_RECENT_ENQUIRY:
            return {
                ...state,
                getRecentEnquiry: action.payload,
            };
        case GET_RECENT_ENQUIRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getEnquiryByMonthYearWiseReducer = (state = getEnquiryByMonthYearWiseData, action) => {
    switch (action.type) {
        case GET_ENQUIRY_BY_MONTH_YEAR_WISE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ENQUIRY_BY_MONTH_YEAR_WISE:
            return {
                ...state,
                getEnquiryByMonthYearWise: action.payload,
            };
        case GET_ENQUIRY_BY_MONTH_YEAR_WISE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getEnquiryCountByStatusReducer = (state = getEnquiryCountByStatusData, action) => {
    switch (action.type) {
        case GET_ENQUIRY_COUNT_BY_STATUS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ENQUIRY_COUNT_BY_STATUS:
            return {
                ...state,
                getEnquiryCountByStatus: action.payload,
            };
        case GET_ENQUIRY_COUNT_BY_STATUS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}