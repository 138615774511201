import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_EMPLOYEE_REPORT, ADD_EMPLOYEE_REPORT_ERROR, ADD_EMPLOYEE_REPORT_LOADING, DELETE_EMPLOYEE_REPORT, DELETE_EMPLOYEE_REPORT_ERROR, DELETE_EMPLOYEE_REPORT_LOADING, EDIT_EMPLOYEE_REPORT, EDIT_EMPLOYEE_REPORT_ERROR, EDIT_EMPLOYEE_REPORT_LOADING, GET_ALL_EMPLOYEE_REPORT, GET_ALL_EMPLOYEE_REPORT_ERROR, GET_ALL_EMPLOYEE_REPORT_LOADING, GET_EMPLOYEE_REPORT_BY_ID, GET_EMPLOYEE_REPORT_BY_ID_ERROR, GET_EMPLOYEE_REPORT_BY_ID_LOADING, IS_LOADING } from "../type"

export const addEmployeeReport = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_EMPLOYEE_REPORT_LOADING,
            payload: true
        })

        await ApiPost('employee_reports/add_employee_reports', body)
            .then((res) => {
                dispatch({
                    type: ADD_EMPLOYEE_REPORT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_EMPLOYEE_REPORT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_EMPLOYEE_REPORT_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_EMPLOYEE_REPORT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getEmployeeReportById = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_EMPLOYEE_REPORT_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`employee_reports/get_employee_reports_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_EMPLOYEE_REPORT_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_EMPLOYEE_REPORT_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_EMPLOYEE_REPORT_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_EMPLOYEE_REPORT_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllEmployeeReport = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_EMPLOYEE_REPORT_LOADING,
            payload: true
        })

        await ApiGet(`employee_reports/get_all_employee_reports`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_EMPLOYEE_REPORT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_EMPLOYEE_REPORT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_EMPLOYEE_REPORT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_EMPLOYEE_REPORT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editEmployeeReport = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_EMPLOYEE_REPORT_LOADING,
            payload: true
        })

        await ApiPut(`employee_reports/edit_employee_reports`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_EMPLOYEE_REPORT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_EMPLOYEE_REPORT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_EMPLOYEE_REPORT_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_EMPLOYEE_REPORT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteEmployeeReport = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_EMPLOYEE_REPORT_LOADING,
            payload: true
        })

        await ApiDelete(`employee_reports/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_EMPLOYEE_REPORT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_EMPLOYEE_REPORT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_EMPLOYEE_REPORT_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_EMPLOYEE_REPORT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}