import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_TASK, ADD_TASK_ERROR, ADD_TASK_LOADING, DELETE_TASK, DELETE_TASK_ERROR, DELETE_TASK_LOADING, EDIT_TASK, EDIT_TASK_ERROR, EDIT_TASK_FOR_KANBAN, EDIT_TASK_FOR_KANBAN_ERROR, EDIT_TASK_FOR_KANBAN_LOADING, EDIT_TASK_LOADING, GET_ALL_TASK, GET_ALL_TASK_ERROR, GET_ALL_TASK_LOADING, GET_TASK_BY_ID, GET_TASK_BY_ID_ERROR, GET_TASK_BY_ID_LOADING, IS_LOADING, TASK_DASHBOARD, TASK_DASHBOARD_ERROR, TASK_DASHBOARD_LOADING } from "../type"

export const addTaskAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_TASK_LOADING,
            payload: true
        })

        await ApiPost(`task/add_task`, body)
            .then((res) => {
                dispatch({
                    type: ADD_TASK,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_TASK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_TASK_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_TASK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getTaskByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_TASK_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`task/get_task_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_TASK_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_TASK_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_TASK_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_TASK_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllTaskAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_TASK_LOADING,
            payload: true
        })

        await ApiPost(`task/get_all_task`, body)
            .then((res) => {
                dispatch({
                    type: GET_ALL_TASK,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_TASK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_TASK_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_TASK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editTaskAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_TASK_LOADING,
            payload: true
        })

        await ApiPut(`task/edit_task`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_TASK,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_TASK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_TASK_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_TASK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteTaskAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_TASK_LOADING,
            payload: true
        })

        await ApiDelete(`task/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_TASK,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_TASK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_TASK_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_TASK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editTaskForKanbanAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_TASK_FOR_KANBAN_LOADING,
            payload: true
        })

        await ApiPut('task/edit_task_for_kanban', body)
            .then((res) => {
                dispatch({
                    type: EDIT_TASK_FOR_KANBAN,
                    payload: res
                })
                dispatch(getAllTaskAction({ status: "", project: "", aasignTo: [], createdBy: [], type: "" }))
            }).catch((error) => {
                dispatch({
                    type: EDIT_TASK_FOR_KANBAN_ERROR,
                    payload: "Error"
                })
            })

        dispatch({
            type: EDIT_TASK_FOR_KANBAN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {
        dispatch({
            type: EDIT_TASK_FOR_KANBAN_ERROR,
            payload: 'ERR'
        })

        dispatch({
            type: EDIT_TASK_FOR_KANBAN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const taskDashboardAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: TASK_DASHBOARD_LOADING,
            payload: true
        })

        await ApiGet(`task/get_task_dashboard?type=${body}`)
            .then((res) => {
                dispatch({
                    type: TASK_DASHBOARD,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: TASK_DASHBOARD_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {
        dispatch({
            type: TASK_DASHBOARD_ERROR,
            payload: 'ERR'
        })

        dispatch({
            type: TASK_DASHBOARD_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}