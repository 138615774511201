import { toast } from "react-toastify"
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_PROJECT_MATERIAL, ADD_PROJECT_MATERIAL_ERROR, ADD_PROJECT_MATERIAL_LOADING, APPROVE_OR_REJECT_LABOR_BILL_RESPONSE, DELETE_PROJECT_MATERIAL, DELETE_PROJECT_MATERIAL_ERROR, DELETE_PROJECT_MATERIAL_LOADING, EDIT_PROJECT_MATERIAL, EDIT_PROJECT_MATERIAL_ERROR, EDIT_PROJECT_MATERIAL_LOADING, IS_LOADING } from "../type"

export const addProjectMaterialAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_PROJECT_MATERIAL_LOADING,
            payload: true
        })

        await ApiPost(`project_material/add_project_materal_history`, body)
            .then((res) => {
                dispatch({
                    type: ADD_PROJECT_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                if (error?.response?.data) {
                    dispatch({
                        type: ADD_PROJECT_MATERIAL,
                        payload: error?.response?.data
                    })
                } else {
                    console.log(error);
                }
            })

        dispatch({
            type: ADD_PROJECT_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_PROJECT_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_PROJECT_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editProjectMaterialAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_PROJECT_MATERIAL_LOADING,
            payload: true
        })

        await ApiPut(`project_material/edit_project_materal_history`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_PROJECT_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
                if (error?.response?.data?.message)
                    toast.error(error?.response?.data?.message)
            })

        dispatch({
            type: EDIT_PROJECT_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_PROJECT_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_PROJECT_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}


export const deleteProjectMaterialAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_PROJECT_MATERIAL_LOADING,
            payload: true
        })

        await ApiDelete(`project_material/delete_project_materal_history/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_PROJECT_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_PROJECT_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_PROJECT_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_PROJECT_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}
// export const getAllProjectDetailsAction = (res) => async (dispatch) => {
//     try {
//         dispatch({
//             type: IS_LOADING,
//             payload: true
//         })

//         dispatch({
//             type: GET_ALL_PROJECT_DETAILS_LOADING,
//             payload: true
//         })

//         await ApiGet(`project_details/get_all_project_details`)
//             .then((res) => {
//                 dispatch({
//                     type: GET_ALL_PROJECT_DETAILS,
//                     payload: res
//                 })
//             }).catch((error) => {
//                 console.log(error);
//             })

//         dispatch({
//             type: GET_ALL_PROJECT_DETAILS_LOADING,
//             payload: false
//         })

//         dispatch({
//             type: IS_LOADING,
//             payload: false
//         })

//     }
//     catch (err) {

//         dispatch({
//             type: GET_ALL_PROJECT_DETAILS_ERROR,
//             payload: err
//         })

//         dispatch({
//             type: GET_ALL_PROJECT_DETAILS_LOADING,
//             payload: false
//         })

//         dispatch({
//             type: IS_LOADING,
//             payload: false
//         })

//     }
// }

// export const getProjectDetailsByIdAction = (id) => async (dispatch) => {
//     try {
//         dispatch({
//             type: IS_LOADING,
//             payload: true
//         })

//         dispatch({
//             type: GET_PROJECT_DETAILS_BY_ID_LOADING,
//             payload: true
//         })

//         await ApiGet(`project_details/get_project_details_by_id/${id}`)
//             .then((res) => {
//                 dispatch({
//                     type: GET_PROJECT_DETAILS_BY_ID,
//                     payload: res
//                 })
//             }).catch((error) => {
//                 console.log(error);
//             })

//         dispatch({
//             type: GET_PROJECT_DETAILS_BY_ID_LOADING,
//             payload: false
//         })

//         dispatch({
//             type: IS_LOADING,
//             payload: false
//         })

//     }
//     catch (err) {

//         dispatch({
//             type: GET_PROJECT_DETAILS_BY_ID_ERROR,
//             payload: err
//         })

//         dispatch({
//             type: GET_PROJECT_DETAILS_BY_ID_LOADING,
//             payload: false
//         })

//         dispatch({
//             type: IS_LOADING,
//             payload: false
//         })

//     }
// }

// export const getProjectDetailsByProjectIdAction = (id) => async (dispatch) => {
//     try {
//         dispatch({
//             type: IS_LOADING,
//             payload: true
//         })

//         dispatch({
//             type: GET_PROJECT_DETAILS_BY_PROJECT_ID_LOADING,
//             payload: true
//         })

//         await ApiGet(`project_details/get_project_details_by_project_id/${id}`)
//             .then((res) => {
//                 dispatch({
//                     type: GET_PROJECT_DETAILS_BY_PROJECT_ID,
//                     payload: res
//                 })
//             }).catch((error) => {
//                 console.log(error);
//             })

//         dispatch({
//             type: GET_PROJECT_DETAILS_BY_PROJECT_ID_LOADING,
//             payload: false
//         })

//         dispatch({
//             type: IS_LOADING,
//             payload: false
//         })

//     }
//     catch (err) {

//         dispatch({
//             type: GET_PROJECT_DETAILS_BY_PROJECT_ID_ERROR,
//             payload: err
//         })

//         dispatch({
//             type: GET_PROJECT_DETAILS_BY_PROJECT_ID_LOADING,
//             payload: false
//         })

//         dispatch({
//             type: IS_LOADING,
//             payload: false
//         })

//     }
// }

export const ApprovedOrRejectLaborBill = (body) => async (dispatch) => {
    try {
        dispatch({ type: IS_LOADING, payload: true });

        await ApiPost(`project_material/approve_or_reject_labor_bill_by_admin`, body)
            .then((res) => {
                dispatch({
                    type: APPROVE_OR_REJECT_LABOR_BILL_RESPONSE,
                    payload: res
                })
            }).catch((error) => {
                if (error?.response?.data) {
                    dispatch({
                        type: APPROVE_OR_REJECT_LABOR_BILL_RESPONSE,
                        payload: error?.response?.data
                    })
                } else {
                    console.log(error);
                }
            })

        dispatch({ type: IS_LOADING, payload: false });


    } catch (err) {
        dispatch({ type: IS_LOADING, payload: false });
        console.log(err);
    }
}


