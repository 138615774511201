import React from 'react'
import Chart from 'react-google-charts'
import Select from 'react-select';

const ViewPieChart = (props) => {
    const { title, data, range, initialRangeVal, RangeValMethod, rangeOptions } = props;

    const pieOptions = {
        titlePosition: 'none',
        width: '100%',
        height: '100%',
        legend: {
            'position': 'right',
            'alignment': 'center',
            'textStyle': {
                fontSize: 18,
            }
        },
        chartArea: {
            left: "10%",
            top: "3%",
            height: "94%",
            width: "94%"
        },
        pieSliceText: 'value',
        pieSliceTextStyle: {
            fontSize: 18,
        },
        pieHole: 0.4,
    }

    return (
        <div className='chart-div pie'>
            <div className='d-flex justify-content-between w-100 round-chart'>
            <span className='chart-title' style={{ fontSize: "20px" }}>{`${title ?? 'Pie Chart'}`}</span>
            {range && <div className="select-chart mb-2">
                <label className="me-2">{range}: </label>
                <Select className="select-year"
                    value={initialRangeVal}
                    onChange={RangeValMethod}
                    options={rangeOptions}
                />
            </div>
            }
            </div>
            <div className='d-flex h-100 chart-bg'>
                <Chart
                    // width={'100%'}
                    height={'225px'}
                    chartType="PieChart"
                    loader={<div></div>}
                    data={data}
                    options={pieOptions}
                    rootProps={{ 'data-testid': '3' }}
                />
            </div>
        </div>
    )
}
export default ViewPieChart