import React, {useState, useEffect} from 'react'
import ViewCardDesk from '../../../components/dashboard/ViewCardDesk'
import ViewTableDesk from '../../../components/dashboard/ViewTableDesk'
// import ViewPieDesk from '../../../components/dashboard/ViewPieDesk'
// import ViewBarDesk from '../../../components/dashboard/ViewBarDesk'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import { ApiGet } from '../../../helper/API/ApiData'


const AccountDashboard = () => {
  const [accountCountData, setaccountCountData] = useState({})
  // const [tableData, settableData] = useState([])
  const [taskData, settaskData] = useState([])
  const taskList = useSelector((state) => state.task_Dashboard.taskDashboard)

  useEffect(() => {
    ApiGet('account/get_all_account_count')
      .then((res) => {
        setaccountCountData(res);
      })
      .catch((err) => {
        console.log(err);
      })
    
  }, [])
  
  let totalPurchaseAmount = 0
  let totalSaleAmount = 0

  if(accountCountData && accountCountData.data) {
    totalPurchaseAmount = accountCountData.data.totalPurchaseAmount
    totalSaleAmount = accountCountData.data.totalSaleAmount
  }

  useEffect(() => {
    if(taskList && taskList.data) {
      settaskData(taskList.data.map((element) => {
        const {projectName, status, subject, ...remains} = element
        const res = {
          'Subject' : subject ? subject : '',
          'Project Name' : projectName ? projectName : '',
          'Status' : status ? status : '',
        }
        return res;
      }))
    }
  }, [taskList])

  return (
      <div className="p-0 m-0">
        <div className="row">
          <div className="col-6">
            <div className='row'>
              <div className='col-6'>
                <ViewCardDesk 
                  data = {{
                    content : 'Grand Payable',
                    count : <CurrencyFormat value={totalPurchaseAmount?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />,
                    color : '#198754'
                  }}
                />
              </div>
              
              <div className="col-6">
                <ViewCardDesk 
                    data = {{
                      content : 'Grand Receivable',
                      count : <CurrencyFormat value={totalSaleAmount?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />,
                      color: '#dc3545',
                      // icon: faCoffee,
                    }}
                />  
              </div>

              {/* <div className="col-12">  
                <ViewPieDesk 
                  data={pieData}
                  title="Chart"
                />
              </div> */}

            </div>
          </div>
          
          <div className="col-6">
            {taskData && taskData.length > 0 && <ViewTableDesk 
              data={taskData}
              title = 'Recent Task'  
              link = '/task'
            />}
          </div>

        </div>
      </div>
  )
}

export default AccountDashboard