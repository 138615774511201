import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSiteReportsAction, getAllSiteReportsAction } from '../../redux/actions/dailySiteReportAction';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import removeImg from '../../assets/image/delete-red.png'
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import { DELETE_DAILY_REPORTS } from '../../redux/type';
import editIcon from '../../assets/image/edit.svg';
import { dateFormate } from '../../helper/utils';
import viewImg from '../../assets/image/eye.svg'
import ViewDailyReport from '../../components/view-modals/ViewDailyReport';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Dailyreport = () => {

  const gridRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userRole = useContext(RoleContext);
  const [limit, setlimit] = useState(Paginationlimit);
  const RolesForAdd = [UserRoles.Admin, UserRoles.Project, UserRoles.Maintance]
  const [deletepopup, setDeletepopup] = useState(false);
  const [columnDef, setColumnDefs] = useState([]);
  const [dailyReport, setDailyReport] = useState([])
  const toastSuccess = () => toast.success('Daily report deleted successfully');
  const data = useSelector((state) => state.get_All_Sites_Report.getAllSiteReports.data)
  const deleteData = useSelector((state) => state.delete_Site_Reports.deleteSiteReports)
  const editSiteReports = useSelector((state) => state.edit_Site_Reports.editSiteReports)
  const [deleteId, setDeleteId] = useState()
  const [dailyReportTog, setDailyReportTog] = useState(false)
  const [dailyReportId, setDailyReportId] = useState('')
  const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)
  const getAllProject = useSelector((state) => state.get_All_Project.getAllProject.data)
  const [gridApi, setGridApi] = useState(null);
  const { getAllSiteReport } = usePaginationHooks()
  const queryParams = new URLSearchParams(location.search)
  const projectId = queryParams.get("projectId")
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({ search: "" });

  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search)
    // const projectId = queryParams.get("projectId")
    // if (location.search) {
    //   if (projectId) {
    //     dispatch(getAllSiteReportsAction(projectId))
    //   } else {
    //     if (data === undefined || !data.length) {
    //       dispatch(getAllSiteReportsAction())
    //     }
    //   }
    // } else {
    //   dispatch(getAllSiteReportsAction())
    // }
    if (RolesForAdd.includes(userRole)) {
      setColumnDefs(columnDefs)
    } else {
      setColumnDefs(NoRolecolumnDefs)
    }
  }, [])

  useEffect(() => {
    let body = ''
    let role = '';
    if (userRole === UserRoles.Maintance) {
      role = 'Maintenance'
    } else if (userRole === UserRoles.Project) {
      role = 'Project'
    }
    dispatch(getAllProjectAction(body, role))
  }, [])

  // useEffect(() => {
  // if (data && getAllProject) {
  //   let temp = data
  //   if (userRole !== UserRoles.Admin) {
  //     temp = data.filter((ele) => getAllProject.find((project) => project?._id === ele?.projectId._id) != undefined)
  //   }

  //   setDailyReport(temp.map((ele) => {
  //     return {
  //       date: ele.date ? ele.date : '',
  //       projectId: ele.projectId ? ele.projectId : '',cccc
  //       totalMenPower: ele.totalMenPower ? ele.totalMenPower : '',
  //       workDetails: ele.workDetails ? ele.workDetails : '',
  //       createdBy: ele.createdBy ? ele.createdBy : '',
  //       _id: ele._id ? ele._id : '',
  //     }
  //   }))
  // }
  // if (data) {
  //   if(userRole === UserRoles.Maintance) {
  //     setDailyReport(data.filter((ele) => ele?.createdBy?._id == userData.id).map((ele) => {
  //       return {
  //         date: ele.date ? ele.date : '',
  //         projectId: ele.projectId ? ele.projectId : '',
  //         totalMenPower: ele.totalMenPower ? ele.totalMenPower : '',
  //         workDetails: ele.workDetails ? ele.workDetails : '',
  //         createdBy: ele.createdBy ? ele.createdBy : '',
  //         _id: ele._id ? ele._id : '',
  //       }
  //     }))
  //   } else {
  //     setDailyReport(data.map((ele) => {
  //       return {
  //         date: ele.date ? ele.date : '',
  //         projectId: ele.projectId ? ele.projectId : '',
  //         totalMenPower: ele.totalMenPower ? ele.totalMenPower : '',
  //         workDetails: ele.workDetails ? ele.workDetails : '',
  //         createdBy: ele.createdBy ? ele.createdBy : '',
  //         _id: ele._id ? ele._id : '',
  //       }
  //     }))
  //   }

  // }
  // }, [data, getAllProject])

  const onEdit = (id) => {
    navigate(`edit?dailyreportId=${id}`)
  }

  const onDelete = (id) => {
    dispatch(deleteSiteReportsAction(id))
    setDeletepopup(false)
  }

  useEffect(() => {
    if (deleteData && deleteData.data) {
      if (deleteData.data.message === "Daily Site Reports deleted successfully.") {
        dispatch({
          type: DELETE_DAILY_REPORTS,
          payload: []
        })
        // dispatch(getAllSiteReportsAction())
        toastSuccess(true)
      }
    }
  }, [deleteData])

  const columnDefs = [
    {
      headerName: 'Date',
      field: "date",
      maxWidth: 120,

      cellRendererFramework: (params) =>
        <div>
          <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
        </div>
    },

    { 
      headerName: 'Project Name', 
      field: 'projectId.partyName',
      flex: 1
     },
    { 
      headerName: 'Total Men Power', 
      field: 'totalMenPower',
      maxWidth: 140,
    },
    { 
      headerName: 'Work Details', 
      field: 'workDetails',
      maxWidth: 300,
    },
    {
      field: "createdBy",
      maxWidth: 150,
      cellRendererFramework: (params) => <div>
        {params?.value?.name}
      </div>
    },
    {
      headerName: 'Action',
      field: "_id",
      sortable: false,
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin || userRole === UserRoles.Maintance) &&
          <>
            {/* <img src={editIcon} style={{ cursor: "pointer", height: "20px" }} onClick={() => onEdit(params?.value)} alt="" title="Edit" /> */}
            <img src={removeImg} style={{ cursor: "pointer", height: "20px" }} className='ms-3' onClick={() => { setDeletepopup(true); setDeleteId(params?.value) }} alt="" title="Delete" /></>}
        <img src={viewImg} style={{ cursor: "pointer", height: "20px" }} className='ms-3' onClick={() => { setDailyReportTog(true); setDailyReportId(params?.value) }} alt="" title="View" />
      </div>
    }
  ]

  const NoRolecolumnDefs = [
    {
      headerName: 'Date',
      field: "date",

      cellRendererFramework: (params) =>
        <div>
          <span>{moment(params.value).format('DD MMM YYYY')}</span>
        </div>
    },
    { headerName: 'Site Name', field: 'projectId.partyName' },
    { headerName: 'Total Men Power', field: 'totalMenPower' },
    { headerName: 'Work Details', field: 'workDetails' },
    {
      field: "createdBy",
      cellRendererFramework: (params) => <div>
        {params.value.name}
      </div>
    },
    {
      headerName: 'Action',
      field: "_id",

      cellRendererFramework: (params) => <div>
        <img src={viewImg} style={{ cursor: "pointer", height: "20px" }} className='ms-3' onClick={() => { setDailyReportTog(true); setDailyReportId(params?.value) }} alt="" title="View" />
      </div>
    }
  ]

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      width: 'auto',
      filter: false,
      flex: 1
    };
  }, []);

  // const onFirstDataRendered = useCallback((params) => {
  //   gridRef.current.api.sizeColumnsToFit();
  // }, []);

  const onHide = () => {
    setDailyReportTog(false)
    setDailyReportId('')
  }

  // const onPageSizeChanged = useCallback(() => {
  //   var value = document.getElementById('page-size').value;
  //   setPerPage(value)
  //   gridRef.current.api.paginationSetPageSize(Number(value));
  // }, []);

  // const onGridSizeChanged = () => {
  //   sizeToFit()
  // }

  // const sizeToFit = useCallback(() => {
  //   if (gridRef?.current !== undefined) {
  //     gridRef?.current?.api?.sizeColumnsToFit();
  //   }
  // }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const clearFilter = () => {
    setFilter({ search: '' });
    setSearch('');
  }

  const debouncedHandleSearch = useCallback(debounce((value) => {
    setFilter({ ...filter, ["search"]: value });
  }, 1000), []);

  useEffect(() => {
    debouncedHandleSearch(search);
  }, [search]);

  useEffect(() => {
    if (gridApi || deleteData.data?.status === 200 || editSiteReports.data?.status === 200 || filter.search) {
      const dataSource = {
        getRows: (params) => {
          const page = params.endRow / limit;
          const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
          const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

          getAllSiteReport(projectId ? projectId : '', '', page, limit, filter.search, sorted_column, sorted_order)
            .then(res => {
              // setGetEnquiry(res.enquiryData)
              let temp = res.data
              if (temp && getAllProject) {
                if (userRole !== UserRoles.Admin) {
                  temp = temp.filter((ele) => getAllProject.find((project) => project?._id === ele?.projectId._id) != undefined)
                }
              }
              params.successCallback(temp.map((ele) => {
                return {
                  date: ele.date ? ele.date : '',
                  projectId: ele.projectId ? ele.projectId : '',
                  totalMenPower: ele.totalMenPower ? ele.totalMenPower : '',
                  workDetails: ele.workDetails ? ele.workDetails : '',
                  createdBy: ele.createdBy ? ele.createdBy : '',
                  _id: ele._id ? ele._id : '',
                }
              }), res.totalCount ? res.totalCount : 0)

              if (temp?.length <= 0) {
                gridApi.showNoRowsOverlay();
              } else {
                gridApi.hideOverlay();
              }
            })
        }
      };
      gridApi?.setDatasource(dataSource);
    }

    // setisFilter(false)
  }, [gridApi, projectId, userRole, deleteData, editSiteReports, limit, filter.search]);

  const handleRowClick = (params) => {
    if (params.colDef.field !== "_id") {
      return onEdit(params.data._id);
    }
  }

  return (
    <>
      <div className='position-relative p-4'>
        <div className='mb-4 set-page-heade'>
          <div className='d-flex flex-column'>
            <h2 className='mb-0'>Daily Report</h2>
          </div>
          <div className='d-flex'>
            <div className="me-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value.trimStart())}
                autoComplete="off"
              />
            </div>
            <button className='adduser-btn me-3' onClick={() => clearFilter()}>Clear Filter</button>
            {RolesForAdd.includes(userRole) && <button className='adduser-btn' onClick={() => { navigate('add') }}>Add Daily Report</button>}
          </div>

        </div>
        {dailyReport &&
          <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
            {/* <AgGridReact
              ref={gridRef}
              rowData={dailyReport}
              columnDefs={columnDef}
              defaultColDef={defaultColDef}
              style={{ width: "100%" }}
              pagination={true}
              paginationPageSize={perPage}
              onFirstDataRendered={onFirstDataRendered}
              onGridSizeChanged={onGridSizeChanged}
            /> */}
            <AgGridReact
              pagination={true}
              columnDefs={columnDefs}
              rowModelType={"infinite"}
              paginationPageSize={limit}
              cacheBlockSize={limit}
              onGridReady={onGridReady}
              rowHeight={45}
              rowSelection={'single'}
              onCellClicked={(event) => {
                const rowData = event.data;
                if (rowData?.createdBy?._id === userData.id || [UserRoles.Admin, UserRoles.Maintance].includes(userRole)) {
                  handleRowClick(event)
                }
              }}
              defaultColDef={defaultColDef}
              overlayNoRowsTemplate={'<h3>No Records Found</h3>'}

            ></AgGridReact>

            {/* <Pagination setlimit={setlimit} setPage={setPage} page={page} limit={limit} data={saleData} totalCount={totalCount} /> */}
            <div className='set-pagination-dropdaun'>
              <p>Show</p>
              <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                <option value="50">
                  50
                </option>
                <option value="100" selected={true}>100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
              <p>Entries</p>
            </div>
          </div>
        }
      </div>
      {dailyReportTog && <ViewDailyReport show={dailyReportTog} onHide={() => onHide()} dailyReportId={dailyReportId} headerText="Daily Reports" />}
      {deletepopup &&
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => onDelete(deleteId)}
          onCancel={() => { setDeletepopup(false) }}
          focusCancelBtn
        >
          You want to delete it
        </SweetAlert>
      }
    </>
  )
}

export default Dailyreport