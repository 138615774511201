import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import AddProduct from '../../components/modals/AddProduct'
import { toast } from 'react-toastify';
import removeImg from '../../assets/image/delete-red.png'
import { deleteProductAction, getAllProductAction, getProductLedger } from '../../redux/actions/productAction';
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import editIcon from '../../assets/image/edit.svg';
import ImportFile from '../../components/modals/ImportFile';
import viewImg from '../../assets/image/eye.svg'
import product_leadger from '../../assets/img/summary.png'
import ViewProduct from '../../components/view-modals/ViewProduct';
import { ADD_PRODUCT, GET_PRODUCT_BY_ID, GET_PRODUCT_LEDGER } from '../../redux/type';
import ProductLeadger from '../../components/modals/ProductLeadger';
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';
import { dateFormate } from '../../helper/utils';
import moment from 'moment';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Product = () => {
    const dispatch = useDispatch()

    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Store]
    const [productData, setProductData] = useState([]);
    const { getAllProducts } = usePaginationHooks()
    const [limit, setlimit] = useState(Paginationlimit);
    const [gridApi, setGridApi] = useState(null);
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [productTog, setProductTog] = useState(false);
    const [fileTog, setFileTog] = useState(false);
    const [productId, setProductId] = useState('')
    const toastSuccess = () => toast.success('Product delete successfully');
    const toastNoRecord = () => toast.warning('No records found!');
    const [columnDefs, setColumnDefs] = useState([]);
    const [viewProductTog, setViewProductTog] = useState(false)
    const [viewProductId, setViewProductId] = useState('')
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");

    const [showModal, setShowModal] = useState(false)

    // const getAllProduct = useSelector((state) => state.get_All_Product.getAllProduct)
    const deleteProduct = useSelector((state) => state.delete_Product.deleteProduct)
    const editProduct = useSelector((state) => state.edit_Product.editProduct)
    const addProduct = useSelector((state) => state.add_Product.addProduct)
    const getProductLedgerData = useSelector((state) => state.get_Product_Ledger.getProductLedger)

    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

    const columnDefsNews = [
        { field: 'productName', flex: 1, headerTooltip: "Product Name", },
        { field: 'productCode', maxWidth: 120, headerTooltip: "Product Code", },
        {
            field: 'hsnsacCode',
            maxWidth: 120,
            headerName: "HSN"
        },
        { field: 'retailSalePrice', maxWidth: 120, headerName: "RSP", headerTooltip: "Retail Sale Price", },
        { field: 'buyingPrice', maxWidth: 120, headerName: "BP", headerTooltip: "Buying Price", },
        { field: 'labourCharge', maxWidth: 75, headerName: "LC", headerTooltip: "Labour Charge", },
        { field: 'gst', maxWidth: 60, },
        { field: 'unitOfMeasurement', maxWidth: 70, headerName: "Unit", },
        { field: 'openingStock', maxWidth: 70, headerTooltip: "Opening Stock", },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 140,
            headerTooltip: "Created By",
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "productCode",
            sortable: false,
            minWidth: 140,
            maxWidth: 140,
            cellRendererFramework: (params) => <div>
                {(params.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin)
                    && <>
                        {/* <img src={editIcon} onClick={() => { onEdit(params?.data?._id) }} style={{ cursor: "pointer", height: '20px' }} title="Edit" alt='' /> */}
                        <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.data?._id) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' /></>}
                <img src={viewImg} onClick={() => { setViewProductTog(true); setViewProductId(params?.data?._id) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
                <img src={product_leadger} onClick={() => onProductLeadger(params?.value)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
            </div>
        }
    ]

    const NoRolecolumnDefsNew = [
        { field: 'productName', flex: 1, headerTooltip: "Product Name", },
        { field: 'productCode', maxWidth: 120, headerTooltip: "Product Code", },
        {
            field: 'hsnsacCode',
            maxWidth: 120,
            headerName: "HSN"
        },
        { field: 'retailSalePrice', maxWidth: 120, headerName: "RSP", headerTooltip: "Retail Sale Price", },
        { field: 'buyingPrice', maxWidth: 120, headerName: "BP", headerTooltip: "Buying Price", },
        { field: 'gst', maxWidth: 60, },
        { field: 'unitOfMeasurement', maxWidth: 70, headerName: "Unit", },
        { field: 'openingStock', maxWidth: 70, headerTooltip: "Opening Stock", },
        {
            headerName: 'Action',
            minWidth: 140,
            maxWidth: 180,
            field: "productCode",
            sortable: false,
            cellRendererFramework: (params) => <div>
                <img src={viewImg} onClick={() => { setViewProductTog(true); setViewProductId(params?.data?._id) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
            </div>
        }
    ]

    const onEdit = (id) => {
        setProductId(id)
        setProductTog(true)
    }

    const handleRowClick = (params) => {
        if (params.colDef.headerName !== "Action") {
            return onEdit(params.data._id);
        }
    }

    const onProductLeadger = (id) => {
        dispatch(getProductLedger(id))

        // setShowModal(true)
        // setProductId(id)
    }

    useEffect(() => {
        if (getProductLedgerData && getProductLedgerData?.data && getProductLedgerData?.data?.productLedger) {
            if (getProductLedgerData.data.productLedger.length > 0) {

                let data = getProductLedgerData.data.productLedger.map((ele) => {
                    return {
                        BillDate: ele.createdAt ? dateFormate(ele.createdAt, 'Date') : '',
                        Type: ele.type ? ele.type : '',
                        ProjectName: ele.partyName ? ele.partyName : '',
                        Quantity: ele.quantity ? ele.quantity : '',
                        Rate: ele.rate ? ele.rate : '',
                        Amount: ele.amount ? ele.amount : '',
                    }
                })
                const ws = XLSX.utils.json_to_sheet(data);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                FileSaver.saveAs(finalData, `${getProductLedgerData.data.productLedger[0].productName} ${moment().format('DD MMM YYYY hh:mm A')}` + '.xlsx');

                dispatch({
                    type: GET_PRODUCT_LEDGER,
                    payload: []
                })
            } else {
                toastNoRecord(true)
            }
        }
    }, [getProductLedgerData])

    const onDelete = (id) => {
        dispatch(deleteProductAction(id))
        setReject(false)
        toastSuccess(true)
    }

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        if (productData.data) {
            if (RolesForAdd.includes(userRole)) {
                setColumnDefs(columnDefsNews)
            } else {
                setColumnDefs(NoRolecolumnDefsNew)
            }
        }
    }, [productData])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || deleteProduct?.data?.status === 200 || editProduct?.data?.status === 200 || addProduct?.data?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllProducts(page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            setProductData(res)
                            params.successCallback(res.data.map((ele) => {
                                return {
                                    productName: ele.productName ? ele.productName : '',
                                    productCode: ele.productCode ? ele.productCode : '',
                                    hsnsacCode: ele.hsnsacCode ? ele.hsnsacCode : '',
                                    retailSalePrice: ele.retailSalePrice ? ele.retailSalePrice : '',
                                    buyingPrice: ele.buyingPrice ? ele.buyingPrice : '',
                                    labourCharge: ele.labourCharge ? ele.labourCharge : '-',
                                    gst: ele.gst ? ele.gst : '',
                                    unitOfMeasurement: ele.unitOfMeasurement ? ele.unitOfMeasurement : '',
                                    openingStock: ele.openingStock ? ele.openingStock : 0,
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

    }, [gridApi, limit, deleteProduct, editProduct, addProduct, filter.search]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>Product</h2>
                    </div>
                    <div className='d-flex'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn' onClick={() => clearFilter()}>Clear Filter</button>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => setProductTog(true)}>Add Product</button>}
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => setFileTog(true)}>Import File</button>}
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        onCellClicked={(event) => {
                            const rowData = event.data;
                            if (rowData?.createdBy?._id === userData.id || userRole === UserRoles.Admin) {
                                handleRowClick(event)
                            }
                        }}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                        tooltipShowDelay={0}
                    />

                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
            </div>
            {productTog && <AddProduct show={productTog} onHide={() => { setProductTog(false); setProductId(''); dispatch({ type: GET_PRODUCT_BY_ID, payload: [] }); dispatch({ type: ADD_PRODUCT, payload: [] }) }} productId={productId} />}
            {fileTog && <ImportFile show={fileTog} onHide={() => setFileTog(false)} />}
            {viewProductTog && <ViewProduct show={viewProductTog} onHide={() => { setShowModal(false); setProductId(''); setViewProductTog(false); setViewProductId('') }} productId={viewProductId} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
            {
                showModal && <ProductLeadger productId={productId} show={showModal} onHide={() => setShowModal(false)} />
            }
        </>
    )
}

export default Product   