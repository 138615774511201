import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProjectAction } from '../../redux/actions/projectAction'
import { dateFormate } from '../../helper/utils'
import { getSiteReportsByIdAction } from '../../redux/actions/dailySiteReportAction'

const ViewDailyReport = ({ show, onHide, dailyReportId, headerText }) => {

    const dispatch = useDispatch()

    const [dailyReport, setDailyReport] = useState({
        projectId: '',
        projectType: '',
        date: '',
        totalMenPower: '',
        workDetails: '',
        nextDayPlaning: '',
        remark: '',
    })

    const getSiteReportByIdData = useSelector((state) => state.get_Site_Report_By_Id.getSiteReportsById)
    const getAllProjectData = useSelector((state) => state.get_All_Project.getAllProject.data)

    useEffect(() => {
        let body = '';
        dispatch(getAllProjectAction(body))
    }, [])

    useEffect(() => {
        if (dailyReportId) {
            dispatch(getSiteReportsByIdAction(dailyReportId))
        }
    }, [dailyReportId])

    useEffect(() => {
        if (getSiteReportByIdData && getSiteReportByIdData.data && dailyReportId) {
            setDailyReport({
                projectId: getSiteReportByIdData.data.projectId?.partyName,
                projectType: getSiteReportByIdData.data.projectId?.projectType,
                date: new Date(getSiteReportByIdData.data.date),
                totalMenPower: getSiteReportByIdData.data.totalMenPower,
                workDetails: getSiteReportByIdData.data.workDetails,
                nextDayPlaning: getSiteReportByIdData.data.nextDayPlaning,
                remark: getSiteReportByIdData.data.remark,
            })
        }
    }, [getSiteReportByIdData])

    const selectValue = (value, name) => {
        if (name === "projectId") {
            return getAllProjectData.find((ele) => ele._id === value)?.partyName
        } else if (name === "projectType") {
            return getAllProjectData.find((ele) => ele._id === value)?.projectType
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                    <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                    <hr></hr>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-site-report-modal'>
                                <tr>
                                    <td>Project Name</td>
                                    <td>{dailyReport?.projectId}</td>
                                </tr>
                                <tr>
                                    <td>Project Type</td>
                                    <td>{dailyReport?.projectType}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-site-report-modal'>
                                <tr>
                                    <td>Date</td>
                                    <td>{dateFormate(dailyReport.date, "Date")}</td>
                                </tr>
                                <tr>
                                    <td>Total Men Power</td>
                                    <td>{dailyReport.totalMenPower}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-site-report-modal viewer-site-report-modal-details'>
                                <tr>
                                    <td className='vertical-top'>Work Detail</td>
                                    <td className='vertical-top'>{dailyReport.workDetails}</td>
                                </tr>
                                <tr>
                                    <td className='vertical-top'>Next Day Planing</td>
                                    <td className='vertical-top'>{dailyReport.nextDayPlaning}</td>
                                </tr>
                                <tr>
                                    <td className='vertical-top' >Remark</td>
                                    <td className='vertical-top'>{dailyReport.remark}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewDailyReport