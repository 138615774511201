import STORAGEKEY from "../../config/APP/app.config"
import { ApiGet, ApiPost, ApiPostNoAuth, ApiDelete } from "../../helper/API/ApiData"
import AuthStorage from "../../helper/AuthStorage"
import { GET_PROJECT_MANAGER_ERROR, GET_PROJECT_MANAGER, GET_PROJECT_MANAGER_LOADING, ADD_USER, ADD_USER_ERROR, ADD_USER_LOADING, DELETE_USER, DELETE_USER_ERROR, DELETE_USER_LOADING, EDIT_USER, EDIT_USER_ERROR, EDIT_USER_LOADING, GET_ALL_USER, GET_ALL_USER_ERROR, GET_ALL_USER_LOADING, GET_USER_BY_ID, GET_USER_BY_ID_ERROR, GET_USER_BY_ID_LOADING, IS_LOADING, LOGIN, LOGIN_ERROR, LOGIN_LOADING } from "../type"

export const loginAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: LOGIN_LOADING,
            payload: true
        })

        await ApiPostNoAuth('users/login', body)
            .then((res) => {
                if (res.message === 'Login Successfully') {
                    dispatch({
                        type: LOGIN,
                        payload: res
                    })
                    AuthStorage.setStorageData(STORAGEKEY.token, res.data.token, true);
                    AuthStorage.setStorageData(STORAGEKEY.userData, JSON.stringify(res.data), true)
                }
                else {
                    dispatch({
                        type: LOGIN,
                        payload: res
                    })
                }

            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: LOGIN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: LOGIN_ERROR,
            payload: err
        })

        dispatch({
            type: LOGIN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const addUserAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_USER_LOADING,
            payload: true
        })

        await ApiPost('users/add_user', body)
            .then((res) => {
                dispatch({
                    type: ADD_USER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_USER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_USER_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_USER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getUserByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_USER_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`users/getUserById/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_USER_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_USER_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_USER_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_USER_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editUserAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_USER_LOADING,
            payload: true
        })

        await ApiPost('users/edit_user', body)
            .then((res) => {
                dispatch({
                    type: EDIT_USER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })


        dispatch({
            type: EDIT_USER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_USER_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_USER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteUserAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_USER_LOADING,
            payload: true
        })

        await ApiDelete(`users/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_USER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_USER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_USER_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_USER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllUserAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_USER_LOADING,
            payload: true
        })

        await ApiGet('users/getAllUser',)
            .then((res) => {
                dispatch({
                    type: GET_ALL_USER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_USER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_USER_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_USER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getProjectManagerAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PROJECT_MANAGER_LOADING,
            payload: true
        })

        await ApiGet('users/get_project_manager_dropdown', body)
            .then((res) => {
                dispatch({
                    type: GET_PROJECT_MANAGER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_PROJECT_MANAGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PROJECT_MANAGER_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PROJECT_MANAGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}