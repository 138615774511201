import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from 'react'
import { AgGridReact } from "ag-grid-react";
import removeImg from '../../assets/image/delete-red.png'
import Term from "../../components/modals/Term";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteTermAndConditionAction, getTermAndConditionAction } from "../../redux/actions/termAndCondition";
import { Paginationlimit } from "../../helper/Constant";
import usePaginationHooks from "../../Hooks/paginationHooks";
import CopyImg from '../../assets/image/copy.png'
import editIcon from '../../assets/image/edit.svg';
import { toast } from "react-toastify";

const termAndCondition = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { getAllTermAndConditions } = usePaginationHooks()
    const [limit, setlimit] = useState(Paginationlimit);
    const [allTerm, setAllTerm] = useState([])
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef();
    const copyLinkSuccess = () => toast.success('Term And Condition copied.');
    // const getAllTermAndCondition = useSelector((state) => state.get_All_TermAndCondition.getAllTermAndCondition)
    const addTermAndCondition = useSelector((state) => state.add_TermAndCondition.addTermAndCondition)
    const editTermAndCondition = useSelector((state) => state.edit_TermAndCondition.editTermAndCondition)
    const deleteTermAndCondition = useSelector((state) => state.delete_TermAndCondition.deleteTermAndCondition)
    const [isOpen, setIsOpen] = useState({
        open: false,
        id: '',
        data: ''
    })

    // useEffect(() => {
    //     dispatch(getTermAndConditionAction())
    // }, [])

    const onCopy = (id) => {
        const data = allTerm?.find((ele) => ele._id === id)?.termAndCondition
        navigator?.clipboard?.writeText(data);
        copyLinkSuccess(true)
    }

    const onDelete = (id) => {
        dispatch(deleteTermAndConditionAction(id))
    }

    const onEdit = (id) => {
        const data = allTerm?.find((ele) => ele._id === id)?.termAndCondition
        setIsOpen({
            open: true,
            id: id,
            data: data
        })
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    const columnDefs = [
        {
            headerName: 'Term And Condition',
            field: 'termAndCondition',
            minWidth: 150,
            cellRendererFramework: (params) => <div>
                <span>{params?.value}</span>
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 150,
            sortable: false,
            cellRendererFramework: (params) => <div>
                {
                    <>
                        {/* <img src={editIcon} style={{ cursor: "pointer", height: "20px" }} onClick={() => onEdit(params?.value)} title="Edit" alt='' /> */}
                        <img src={removeImg} style={{ cursor: "pointer", height: "20px" }} className='ms-3' onClick={() => { onDelete(params?.value) }} alt="" title="Delete" />
                        <img src={CopyImg} onClick={() => onCopy(params?.value)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Copy project report link' alt='' />
                    </>
                }
            </div>
        }
    ]

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1
        };
    }, []);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (gridApi || addTermAndCondition?.status === 200 || deleteTermAndCondition?.status === 200 || editTermAndCondition?.status === 200) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllTermAndConditions(page, limit, sorted_column, sorted_order)
                        .then(res => {
                            setAllTerm(res.data)
                            params.successCallback(res?.data?.map((item) => {
                                return {
                                    termAndCondition: item.termAndCondition ? item.termAndCondition : '',
                                    _id: item._id ? item._id : '',
                                }
                            }), res?.totalCount ? res?.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

    }, [gridApi, limit, addTermAndCondition, deleteTermAndCondition, editTermAndCondition]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <h2 className='mb-0'>{'Term And Conditon'}</h2>
                    <button className='adduser-btn' onClick={() => {
                        setIsOpen({
                            open: true,
                            id: '',
                            data: ''
                        })
                    }}>{'Add Term And Condition'}</button>
                </div>
                <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        onCellClicked={handleRowClick}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />

                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
            </div>
            {isOpen.open && <Term show={isOpen} onHide={() => setIsOpen({
                open: false,
                id: '',
                data: ''
            })} />}
        </>
    )
}
export default termAndCondition;