import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import { getAccountAction } from '../../redux/actions/accountAction'

const FollowUpPayment = ({ show, onHide, viewPaymentId, billTypes }) => {

    const dispatch = useDispatch()

    const [bill, setBill] = useState({
        customer: "",
        date: "",
        paymentType: "",
        bankName: "",
        amount: "",
        billType: "",
        tds: "",
        chequeNo: "",
        chequeDate: "",
        paymentNarration: "",
        nextPaymentDate: "",
    })

    useEffect(() => {
        if (billTypes) {
            let body = {
                customer: []
            }
            let sDate = '';
            let eDate = '';
            let billType = billTypes;
            dispatch(getAccountAction(sDate, eDate, billType, body))
        }
    }, [billTypes])

    const accountData = useSelector((state) => state.get_Account.getAccount)

    useEffect(() => {
        if (accountData && accountData.data && accountData.data.outData && viewPaymentId) {
            accountData.data.outData.find((item) => item._id === viewPaymentId &&
                setBill({
                    customer: item.customer.accountName,
                    date: new Date(item.date),
                    paymentType: item.paymentType,
                    bankName: item.bankName,
                    amount: item.amount,
                    billType: item.billType,
                    tds: item.tds,
                    chequeNo: item.chequeNo,
                    chequeDate: item.chequeDate ? new Date(item.chequeDate) : '',
                    paymentNarration: item.paymentNarration,
                    nextPaymentDate: item.nextPaymentDate ? new Date(item.nextPaymentDate) : '',
                })
            )
        }
    }, [accountData, viewPaymentId])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="md"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Payble/Receivable</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={12}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Customer</td>
                                    <td>{bill.customer}</td>
                                </tr>
                                <tr>
                                    <td>Date</td>
                                    <td>{moment(bill.date).format("DD-MMM-YYYY")}</td>

                                </tr>
                                <tr>
                                    <td>Payment Type</td>
                                    <td>{bill.paymentType}</td>
                                </tr>
                                <tr>
                                    <td>Bank Name</td>
                                    <td>{bill.bankName}</td>
                                </tr>
                                <tr>
                                    <td>Amount</td>
                                    <td>{bill.amount}</td>
                                </tr>
                                <tr>
                                    <td>Bill Type</td>
                                    <td>{bill.billType}</td>
                                </tr>
                                <tr>
                                    <td>TDS</td>
                                    <td>{bill.tds}</td>
                                </tr>
                                <tr>
                                    <td>Cheque No</td>
                                    <td>{bill.chequeNo}</td>
                                </tr>
                                <tr>
                                    <td>Cheque Date</td>
                                    <td>{bill.chequeDate ? moment(bill.chequeDate).format("DD-MMM-YYYY") : ''}</td>
                                </tr>
                                <tr>
                                    <td>Next Payment Date</td>
                                    <td>{bill.nextPaymentDate ? moment(bill.nextPaymentDate).format("DD-MMM-YYYY") : ''}</td>
                                </tr>
                                <tr>
                                    <td>Payment Narration</td>
                                    <td>{bill.paymentNarration}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default FollowUpPayment