import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import { getTaskByIdAction } from '../../redux/actions/taskAction'
import ReactHtmlParser from 'react-html-parser';
import Avatar from 'react-avatar';

const ViewTask = ({ show, onHide, taskId }) => {

    const dispatch = useDispatch()

    const [task, setTask] = useState({
        projectName: '',
        description: '',
        dueDate: '',
        effort: '',
        priority: '',
        startDate: '',
        status: '',
        subject: '',
    })
    const [assignTo, setAssignTo] = useState([])
    const [comments, setComments] = useState([])
    const getTaskData = useSelector((state) => state.get_Task_By_Id.getTaskById)

    useEffect(() => {
        if (taskId) {
            dispatch(getTaskByIdAction(taskId))
        }
    }, [taskId])


    useEffect(() => {
        if (getTaskData && getTaskData.data && taskId) {
            setTask({
                projectName: getTaskData.data.projectName,
                description: getTaskData.data.description,
                dueDate: new Date(getTaskData.data.dueDate),
                effort: getTaskData.data.effort,
                priority: getTaskData.data.priority,
                startDate: new Date(getTaskData.data.startDate),
                status: getTaskData.data.status,
                subject: getTaskData.data.subject,
            })
            let tempAssignTo = [];
            let tempComment = [];
            getTaskData.data.assignToData.length > 0 && getTaskData.data.assignToData.map((item) => tempAssignTo.push(item.name))
            setAssignTo(tempAssignTo)
            getTaskData.data.comments.length > 0 && getTaskData.data.comments.map((item) => tempComment.push({ name: item.createdBy.name, comment: item.comment, createdAt: item.createdAt }))
            setComments(tempComment)
        }
    }, [getTaskData])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                    <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                    <hr></hr>
                </Row>
                <Row>
                    <Col lg={8}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-task-modal'>
                                <tr>
                                    <td>Subject</td>
                                    <td>{task.subject}</td>
                                </tr>
                                <tr>
                                    <td>Project Name</td>
                                    <td>{task.projectName}</td>
                                </tr>
                                <tr>
                                    <td className='vertical-top'>Assign To</td>
                                    <td>
                                        {assignTo.map((item, i) =>
                                            <span key={i}>{<Avatar name={item} round={true} size="25px" />}</span>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='vertical-top'>Description</td>
                                    <td className='vertical-top'>{ReactHtmlParser(task.description)}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-modal'>
                                <tr>
                                    <td>Start Date</td>
                                    <td>{moment(task.startDate).format('DD MMM YYYY')}</td>
                                </tr>
                                <tr>
                                    <td>Due Date</td>
                                    <td>{moment(task.dueDate).format('DD MMM YYYY')}</td>
                                </tr>
                                <tr>
                                    <td>Priority</td>
                                    <td>{task.priority}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>{task.status}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    {comments.length > 0 && <Col lg={12} className="">
                        <h2 className="Steps-header">Comments</h2>
                    </Col>}
                </Row>
                {comments.length > 0 &&
                    comments.map((item, i) =>
                        <div className='comment-box' key={i}>
                            <Row>
                                <Col lg={12}>
                                    <div className="users-form-info date-picker add-remark-modal mx-0 mt-1">
                                        <div>
                                            <div className='d-flex justify-content-between align-items-center users-label'>
                                                <div>
                                                    <label>{<Avatar name={item.name} round={true} size="30px" />}</label>
                                                    <label className='ms-3'>{item.name}</label>
                                                </div>
                                                <label>{moment(item.createdAt).format('lll')}</label>
                                            </div>
                                            <label>
                                                {ReactHtmlParser(item.comment)}
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}
        </Modal>
    )
}
export default ViewTask