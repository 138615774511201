import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_NOC, ADD_NOC_ERROR, ADD_NOC_LOADING, DELETE_NOC, DELETE_NOC_ERROR, DELETE_NOC_LOADING, EDIT_NOC, EDIT_NOC_ERROR, EDIT_NOC_LOADING, GET_ALL_NOCS, GET_ALL_NOCS_ERROR, GET_ALL_NOCS_LOADING, GET_NOC_BY_ID, GET_NOC_BY_ID_ERROR, GET_NOC_BY_ID_LOADING, IS_LOADING } from "../type"

export const addNocAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_NOC_LOADING,
            payload: true
        })
        await ApiPost('noc/add_noc', body)
            .then((res) => {
                dispatch({
                    type: ADD_NOC,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: ADD_NOC_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_NOC_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_NOC_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllNocsAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_NOCS_LOADING,
            payload: true
        })

        await ApiGet('noc/get_all_nocs')
            .then((res) => {
                dispatch({
                    type: GET_ALL_NOCS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_NOCS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_NOCS_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_NOCS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getNocByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_NOC_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`noc/get_noc_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_NOC_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_NOC_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_NOC_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_NOC_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteNocAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_NOC_LOADING,
            payload: true
        })

        await ApiDelete(`noc/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_NOC,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: DELETE_NOC_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_NOC_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_NOC_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editNocAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_NOC_LOADING,
            payload: true
        })
        await ApiPut('noc/edit_noc', body)
            .then((res) => {
                dispatch({
                    type: EDIT_NOC,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: EDIT_NOC_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_NOC_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_NOC_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}