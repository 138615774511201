import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { editQuotationAction } from '../../redux/actions/quotationAction';

const Finalize = ({ show, onHide, enquiryId }) => {
    const dispatch = useDispatch()
    const [quotationOption, setQuotationOption] = useState([]);
    const [option, setOption] = useState([]);
    const [preSelectedOption, setPreSelectedOption] = useState({});
    const [quotationId, setQuotationId] = useState("");
    const [error, seterror] = useState('')
    const getQuotationByEnquiryIdData = useSelector((state) => state.get_Quotation_By_ProjectId.getQuotationByEnquiryId)

    useEffect(() => {
        if (getQuotationByEnquiryIdData && getQuotationByEnquiryIdData.data) {
            const data = getQuotationByEnquiryIdData.data.map((item) => {
                return {
                    id: item._id,
                    project: item.project,
                    isFinal: item.isFinal,
                    items: item.items,
                    createdAt: item.createdAt,
                    updatedAt: item.updatedAt,
                    quotationNo: item.quotationNo,
                    quotationName: item.quotationName
                }
            })
            setQuotationOption(data)
        }
    }, [getQuotationByEnquiryIdData])

    const handleSave = () => {
        if (!quotationId) {
            seterror('Please Select Quotation');
        } else if (quotationId) {
            const body = {
                enquiryId,
                id: quotationId
            }
            dispatch(editQuotationAction(body));
            onHide()
        } else {
            seterror('error from Selection Quotation');
        }
    }

    const handleChange = (e) => {
        setQuotationId(e.value);
        setPreSelectedOption({ value: e.value, label: e.label });
        if (e.value) {
            seterror('');
        }
    }

    const findOption = quotationOption?.find((item) => item.isFinal === true);

    useEffect(() => {
        if (quotationOption) {
            setOption(quotationOption.map((ele, i) => {
                return {
                    value: ele?.id,
                    label: ele.quotationName
                }
            }))
        }
    }, [quotationOption]);

    useEffect(() => {
        setPreSelectedOption(option?.find((ele) => ele.value === findOption?.id));
    }, [option])

    return (
        <Modal
            show={show}
            onHide={onHide}
            keyboard={false}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Finalize Quotation"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <div>
                            <div>
                                <label>Select Final Quotation</label>
                                <Select
                                    cacheOptions
                                    value={preSelectedOption}
                                    options={option}
                                    onChange={(event) => handleChange(event)}
                                    style={{ marginTop: "unset", paddingRight: 30 }}
                                />
                            </div>
                            {error && <span style={{ color: "red" }}>{error}</span>}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={handleSave}>{"Save"}</button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>
        </Modal>
    )
}

export default Finalize;