import { ADD_ENQUIRY, ADD_ENQUIRY_ERROR, ADD_ENQUIRY_LOADING, APPROVAL_FOR_ADMIN_ENQUIRY, APPROVAL_FOR_ADMIN_ENQUIRY_ERROR, APPROVAL_FOR_ADMIN_ENQUIRY_LOADING, DELETE_ENQUIRY, DELETE_ENQUIRY_ERROR, DELETE_ENQUIRY_LOADING, EDIT_ENQUIRY, EDIT_ENQUIRY_ERROR, EDIT_ENQUIRY_LOADING, GET_ALL_ENQUIRY, GET_ALL_ENQUIRY_ERROR, GET_ALL_ENQUIRY_LOADING, GET_ENQUIRY_BY_ID, GET_ENQUIRY_BY_ID_ERROR, GET_ENQUIRY_BY_ID_LOADING, REJECT_ENQUIRY, REJECT_ENQUIRY_ERROR, REJECT_ENQUIRY_LOADING } from "../type"

const addEnquiryData = {
    loading:false,
    addEnquiry : [],
    error:'',
};

const getAllEnquiryData = {
    loading:false,
    getAllEnquiry : [],
    error:'',
};

const getEnquiryByIdData = {
    loading:false,
    getEnquiryById:[],
    error:'',
}

const rejectEnquiryData = {
    loading:false,
    rejectEnquiry:[],
    error:'',
}

const editEnquiryData = {
    loading:false,
    editEnquiry:[],
    error:'',
}

const deleteEnquiryData = {
    loading:false,
    deleteEnquiry:[],
    error:'',
}

const approvalForAdminEnquiryData  = {
    loading:false,
    approvalEnquiry:[],
    error:'',
}


export const approvalForAdminEnquiryReducer = (state = approvalForAdminEnquiryData, action) => {
    switch (action.type) {
        case APPROVAL_FOR_ADMIN_ENQUIRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case APPROVAL_FOR_ADMIN_ENQUIRY:
            return {
                ...state,
                approvalEnquiry: action.payload,
            };
        case APPROVAL_FOR_ADMIN_ENQUIRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}



export const addEnquiryReducer = (state = addEnquiryData, action) => {
    switch (action.type) {
        case ADD_ENQUIRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_ENQUIRY:
            return {
                ...state,
                addEnquiry: action.payload,
            };
        case ADD_ENQUIRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllEnquiryReducer = (state = getAllEnquiryData, action) => {
    switch (action.type) {
        case GET_ALL_ENQUIRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_ENQUIRY:
            return {
                ...state,
                getAllEnquiry: action.payload,
            };
        case GET_ALL_ENQUIRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getEnquiryByIdReducer = (state = getEnquiryByIdData, action) => {
    switch (action.type) {
        case GET_ENQUIRY_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ENQUIRY_BY_ID:
            return {
                ...state,
                getEnquiryById: action.payload,
            };
        case GET_ENQUIRY_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const rejectEnquiryReducer = (state = rejectEnquiryData, action) => {
    switch (action.type) {
        case REJECT_ENQUIRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case REJECT_ENQUIRY:
            return {
                ...state,
                rejectEnquiry: action.payload,
            };
        case REJECT_ENQUIRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editEnquiryReducer = (state = editEnquiryData, action) => {
    switch (action.type) {
        case EDIT_ENQUIRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_ENQUIRY:
            return {
                ...state,
                editEnquiry: action.payload,
            };
        case EDIT_ENQUIRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}



export const deleteEnquiryReducer = (state = deleteEnquiryData, action) => {
    switch (action.type) {
        case DELETE_ENQUIRY_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_ENQUIRY:
            return {
                ...state,
                deleteEnquiry: action.payload,
            };
        case DELETE_ENQUIRY_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
