import React, { useState, useEffect } from 'react'
import ViewCardDesk from '../../../components/dashboard/ViewCardDesk'
import ViewTableDesk from '../../../components/dashboard/ViewTableDesk'
import ViewPieDesk from '../../../components/dashboard/ViewPieDesk'
import ViewBarDesk from '../../../components/dashboard/ViewBarDesk'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { ApiGet } from '../../../helper/API/ApiData'
import CurrencyFormat from 'react-currency-format'
import { getAllAccountCountAction } from '../../../redux/actions/accountAction'
import { getEnquiryByMonthYearWiseAction, getEnquiryCountByStatusAction, getRecentEnquiryAction } from '../../../redux/actions/dashboardAction'
import { dateFormate } from '../../../helper/utils'
import { getAllProductValueAction } from '../../../redux/actions/productAction'

const AdminDashboard = () => {

  const dispatch = useDispatch()
  const [loader1, setloader1] = useState(true)
  const [loader2, setloader2] = useState(true)
  const [loader3, setloader3] = useState(true)

  const [task, setTask] = useState([])
  const [totalPurchaseAmount, setTotalPurchaseAmount] = useState()
  const [totalSaleAmount, setTotalSaleAmount] = useState()

  const [recentEnquiry, setRecentEnquiry] = useState([]) //table
  const [enquiryByMonthYearWise, setEnquiryByMonthYearWise] = useState([])
  const [enquiryCountByStatus, setEnquiryCountByStatus] = useState([])

  let startYear = 2021
  let currentYear = new Date().getFullYear()
  const yearOption = [...Array(currentYear - startYear + 1).keys()].map((e) => {
    return { label: (e + startYear).toString(), value: (e + startYear).toString() };
  })

  const [NOCCount, setNOCCount] = useState([])
  const [year, setyear] = useState({ label: currentYear.toString(), value: currentYear.toString() })
  const [countByMonth, setcountByMonth] = useState([])
  const [recentNoc, setRecentNoc] = useState([]) //table

  const [project, setProject] = useState([])
  const [year2, setyear2] = useState({ label: currentYear.toString(), value: currentYear.toString() })
  const [projectByMonthYearWise, setProjectByMonthYearWise] = useState([])
  const [projectCountByStatus, setProjectCountByStatus] = useState([])

  const [sell, setSell] = useState([])
  const [purchase, setPurchase] = useState([])
  const [type, setType] = useState({ label: "All", value: "" })
  const SalesType = [
    { label: "All", value: "" },
    { label: "New Project", value: "New Project" },
    { label: "Maintenance", value: "Maintenance" },
    { label: "MaterialTrading", value: "Material Trading" },
    { label: "Consulting", value: "Consulting" },
  ]

  const [year1, setyear1] = useState({ label: currentYear.toString(), value: currentYear.toString() })

  const payableReceivableCount = useSelector((state) => state.get_All_Account_Count.getAllAccountCount)
  const getRecentEnquiryData = useSelector((state) => state.get_Recent_Enquiry.getRecentEnquiry)
  const getEnquiryByMonthYearWiseData = useSelector((state) => state.get_Enquiry_By_Month_Year_Wise.getEnquiryByMonthYearWise)
  const getEnquiryCountByStatusData = useSelector((state) => state.get_Enquiry_Count_By_Status.getEnquiryCountByStatus)
  const getAllProductValueData = useSelector((state) => state.get_All_Product_Value.getAllProductValue)

  useEffect(() => {
    dispatch(getEnquiryByMonthYearWiseAction(year1.value))
  }, [year1])

  useEffect(() => {
    dispatch(getEnquiryCountByStatusAction(type.value))
  }, [type])

  useEffect(() => {

    dispatch(getAllAccountCountAction())
    dispatch(getRecentEnquiryAction())
    dispatch(getAllProductValueAction())

    ApiGet(`task/get_task_dashboard?type=Admin`)
      .then((res) => {
        setTask(res.data.map((ele) => {
          return {
            'Project Name': ele.projectName,
            'Subject': ele.subject,
            'Status': ele.status,
            'Assigned To': ele.assignToData.map((item) => item.name)
          }
        }))
      }).catch((error) => {
        console.log(error);
      })

    ApiGet('project/get_project_dashboard')
      .then((res) => {
        setProject(res.data.map((ele) => {
          return {
            'ProjectName': ele.partyName,
            'ProjectManager': ele.projectManager.name
          }
        }))
      }).catch((err) => {
        console.log(err);
      })

    ApiGet('project/get_project_count_by_status?projectType=')
      .then((res) => {
        let tempProjectCount = [['Label', 'Count']];
        res.data.map((ele) => {
          tempProjectCount.push([ele.label, ele.count])
        })
        setProjectCountByStatus(tempProjectCount)
      }).catch((err) => {
        console.log(err);
      })

    ApiGet('noc/get_noc_count_by_status')
      .then((res) => {
        let tempNoc = [['Label', 'Count']];
        res.data.map((ele) => {
          tempNoc.push([ele.label, ele.count])
        })
        setNOCCount(tempNoc)
      }).catch((err) => {
        console.log(err);
      })

    ApiGet('noc/get_recent_noc')
      .then((res) => {
        setRecentNoc(res.data.map((ele) => {
          return {
            'Customer Name': ele.customer.accountName,
            'Party Name': ele.partyName,
            'location': ele.location,
          }
        }))
      }).catch((err) => {
        console.log(err);
      })

    ApiGet('sell/get_sell_dashboard')
      .then((res) => {
        setSell(res.data.map((ele) => {
          return {
            CustomerName: ele.customerName,
            projectName: ele.projectName,
            TotalAmount: ele.totalAmount
          }
        }))
      }).catch((err) => {
        console.log(err);
      })

    ApiGet('purchase/get_recent_purchase')
      .then((res) => {
        setPurchase(res.data.map((ele) => {
          return {
            CustomerName: ele.customerName,
            TotalAmount: ele.totalAmount,
          }
        }))
      }).catch((err) => {
        console.log(err);
      })

  }, [])


  useEffect(() => {
    ApiGet(`noc/get_noc_count_by_month_year?year=${year.value}`)
      .then((res) => {
        setloader3(false)
        setcountByMonth([['Month', 'Count'], ...(res.data.map((element) => {
          return ([element.month.substring(0, 3), element.count]);
        }))])
      }).catch((err) => {
        console.log(err);
      })

  }, [year])

  useEffect(() => {
    ApiGet(`project/get_project_count_by_month_year_wise?year=${year2.value}&projectType=`)
      .then((res) => {
        let tempProject = [['Month', 'Count']];
        res.data.map((ele) => {
          tempProject.push([ele.month.substring(0, 3), ele.count])
        })
        setloader2(false)
        setProjectByMonthYearWise(tempProject)
      }).catch((err) => {
        console.log(err);
      })
  }, [year2])


  useEffect(() => {
    if (payableReceivableCount && payableReceivableCount.data)
      setTotalPurchaseAmount(payableReceivableCount.data?.totalPurchaseAmount)
    setTotalSaleAmount(payableReceivableCount.data?.totalSaleAmount)
  }, [payableReceivableCount])

  useEffect(() => {
    if (getRecentEnquiryData && getRecentEnquiryData.data) {
      setRecentEnquiry(getRecentEnquiryData.data.map((ele) => {
        return {
          PartyName: ele.partyName,
          EnquiryDate: dateFormate(ele.enquiryDate, "Date"),
          VisitDate: dateFormate(ele.visitDate, "Date"),
          Status: ele.status,
        }
      }))
    }
  }, [getRecentEnquiryData])

  useEffect(() => {
    if (getEnquiryByMonthYearWiseData && getEnquiryByMonthYearWiseData.data) {
      let temp = [['Month', 'Count']];
      getEnquiryByMonthYearWiseData.data.map((ele) => {
        temp.push([ele.month.substring(0, 3), ele.count])
      })
      setloader1(false)
      setEnquiryByMonthYearWise(temp)
    }
  }, [getEnquiryByMonthYearWiseData])

  useEffect(() => {
    if (getEnquiryCountByStatusData && getEnquiryCountByStatusData.data) {
      let temp = [['Label', 'Count']];
      getEnquiryCountByStatusData.data.map((ele) => {
        temp.push([ele.label, ele.count])
      })
      setEnquiryCountByStatus(temp)
    }
  }, [getEnquiryCountByStatusData])

  return (
    <div className="p-0 m-0">
      <Col lg={12} className="" style={{ justifyContent: 'space-around' }}>
        <Row>
          <Col lg={4} md={6} sm={12} className="dashboard-amount-card">
            <div
              style={{ backgroundColor: '#FFFFFF' }}
              className="card-div me-2 mb-4"
            >
              <Row className='w-100'>
                <Col xxl={7} md={12} sm={12} >
                  <h3>Grand Payable</h3>
                </Col>
                <Col xxl={5} md={12} sm={12} >
                  <h3>
                  <CurrencyFormat value={totalPurchaseAmount?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </h3>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} className="dashboard-amount-card">
            <div
              style={{ backgroundColor: '#FFFFFF' }}
              className="card-div me-2 mb-4"
            >
              <Row className='w-100'>
                <Col xxl={7} md={12} sm={12} >
                  <h3>Grand Receivable</h3>
                </Col>
                <Col xxl={5} md={12} sm={12} >
                  <h3>
                  <CurrencyFormat value={totalSaleAmount?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </h3>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} className="dashboard-amount-card">
            <div
              style={{ backgroundColor: '#FFFFFF' }}
              className="card-div me-2 mb-4"
            >
              <Row className='w-100'>
                <Col xxl={7} md={12} sm={12} >
                  <h3>Product Values</h3>
                </Col>
                <Col xxl={5} md={12} sm={12} >
                  <h3>
                  <CurrencyFormat value={getAllProductValueData?.data?.totalProductValue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </h3>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Row>

        <Col xl={6} lg={12} md={6}>
          <Col lg={12}>
            {enquiryCountByStatus.length > 0 && <ViewPieDesk
              data={enquiryCountByStatus}
              title="Enquiry Status Summary"
              range='Type'
              rangeOptions={SalesType}
              initialRangeVal={type}
              RangeValMethod={setType}
            />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6} className="mt-3 mt-xl-0">
          <Col lg={12}>
            {enquiryByMonthYearWise.length > 0 && <ViewBarDesk
              data={enquiryByMonthYearWise}
              title="Enquiry Summary"
              loader={loader1}
              range='Year'
              rangeOptions={yearOption}
              initialRangeVal={year1}
              RangeValMethod={setyear1} />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6} className="mt-3">
          <Col lg={12}>
            {projectCountByStatus.length > 0 && <ViewPieDesk data={projectCountByStatus} title="Project  Status Summary" />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6} className='mt-3'>
          <Col lg={12}>
            {projectByMonthYearWise.length > 0 && <ViewBarDesk
              data={projectByMonthYearWise}
              title="Project Summary"
              loader={loader2}
              range='Year'
              rangeOptions={yearOption}
              initialRangeVal={year2}
              RangeValMethod={setyear2}
            />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6} className="mt-3">
          <Col lg={12}>
            {NOCCount.length > 0 && <ViewPieDesk data={NOCCount} title="NOC  Status Summary" />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6} className='mt-3'>
          <Col lg={12}>
            {countByMonth.length > 0 && <ViewBarDesk
              data={countByMonth}
              title='NOC Summary'
              loader={loader3}
              range='Year'
              rangeOptions={yearOption}
              initialRangeVal={year}
              RangeValMethod={setyear}
            />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6}>
          <Col lg={12} className="mt-3">
            {recentEnquiry.length > 0 && <ViewTableDesk data={recentEnquiry} title={"Recent Enquiry"} link="/enquiry" />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6}>
          <Col lg={12} className="mt-3">
            {task.length > 0 && <ViewTableDesk data={task} title={"Recent Task"} link="/task" />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6}>
          <Col lg={12} className="mt-3">
            {project.length > 0 && <ViewTableDesk data={project} title={"Recent Project"} link="/project" />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6}>
          <Col lg={12} className="mt-3">
            {sell.length > 0 && <ViewTableDesk data={sell} title={"Recent Sale"} link="/sale" />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6}>
          <Col lg={12} className="mt-3">
            {purchase.length > 0 && <ViewTableDesk data={purchase} title={"Recent Purchase"} link="/purchase" />}
          </Col>
        </Col>

        <Col xl={6} lg={12} md={6}>
          <Col lg={12} className="mt-3">
            {recentNoc.length > 0 && <ViewTableDesk data={recentNoc} title={"Recent NOC"} link="/noc" />}
          </Col>
        </Col>

      </Row>
    </div>
  )
}

export default AdminDashboard