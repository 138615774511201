import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import img from '../../assets/image/select.png'
import Datepickericon from '../../assets/image/date-picker-icon.png'
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import { addSiteReportsAction, editSiteReportsByIdAction, getSiteReportsByIdAction } from '../../redux/actions/dailySiteReportAction';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify'
import { ADD_SITE_REPORTS, EDIT_SITE_REPORTS_BY_ID } from '../../redux/type';
import ReactDatePicker from 'react-datepicker';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';

const DailySiteReport = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const dailyreportId = queryParams.get("dailyreportId")
  const [formErrors, setFormErrors] = useState()
  const [editId, setEditId] = useState();
  const [projectOption, setProjectOption] = useState([]);
  const mes = `Daily report ${editId ? "updated" : "added"} successfully`;
  const toastSuccess = () => toast.success(mes);
  const userRole = useContext(RoleContext);

  const projectData = useSelector((state) => state.get_All_Project.getAllProject.data)
  const siteReportsByIdData = useSelector((state) => state.get_Site_Report_By_Id.getSiteReportsById.data)
  const editSiteData = useSelector((state) => state.edit_Site_Reports.editSiteReports)
  const addSiteData = useSelector((state) => state.add_Site_Reports.addSiteReports)
  const [dailySiteReportData, setDailySiteReportData] = useState({
    projectId: '',
    date: '',
    contractor: '',
    totalMenPower: '',
    workDetails: '',
    nextDayPlaning: '',
    remark: ''
  })
  const [projectType, setProjectType] = useState('')
  useEffect(() => {
    let body = '';
    let role = '';
    if (userRole === UserRoles.Maintance) {
      role = 'Maintenance'
    } else if (userRole === UserRoles.Project) {
      role = 'Project'
    }
    dispatch(getAllProjectAction(body, role))
  }, [])

  useEffect(() => {
    if (dailyreportId) {
      setEditId(dailyreportId)
      dispatch(getSiteReportsByIdAction(dailyreportId))
    }
  }, [dailyreportId])

  useEffect(() => {
    if (projectData) {
      let temp = projectData
      // if()

      temp = temp.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
      setProjectOption(temp.map((ele) => {
        return {
          value: ele._id,
          label: ele.partyName
        }
      }))

    }
  }, [projectData])

  useEffect(() => {
    if (siteReportsByIdData && dailyreportId) {
      setDailySiteReportData({
        projectId: siteReportsByIdData.projectId._id,
        date: new Date(siteReportsByIdData.date),
        contractor: siteReportsByIdData.contractor,
        totalMenPower: siteReportsByIdData.totalMenPower,
        workDetails: siteReportsByIdData.workDetails,
        nextDayPlaning: siteReportsByIdData.nextDayPlaning,
        remark: siteReportsByIdData.remark
      })
    }
  }, [siteReportsByIdData])

  useEffect(() => {
    if (dailySiteReportData.projectId) {
      setProjectType(projectData && projectData.find((ele) => ele?._id === dailySiteReportData?.projectId)?.projectType)
    } else {
      setProjectType('')
    }
  }, [dailySiteReportData.projectId])

  const onSave = () => {
    if (validation()) {
      return
    }
    dispatch(addSiteReportsAction(dailySiteReportData))
  }

  useEffect(() => {
    if (addSiteData && addSiteData.data) {
      dispatch({
        type: ADD_SITE_REPORTS,
        payload: []
      })
      navigate('/daily-report')
      toastSuccess(true)
    }
  }, [addSiteData])

  const onEdit = () => {
    if (validation()) {
      return
    }
    let editData = {
      ...dailySiteReportData,
      projectId: dailySiteReportData.projectId._id,
      id: editId
    }
    dispatch(editSiteReportsByIdAction(editData))
    toastSuccess(true)
  }

  useEffect(() => {
    if (editSiteData && editSiteData.data && editSiteData.data.data) {
      dispatch({
        type: EDIT_SITE_REPORTS_BY_ID,
        payload: []
      })
      navigate('/daily-report')
    }
  }, [editSiteData])

  const validation = () => {
    let flag = false
    const error = {};
    if (!dailySiteReportData.projectId) {
      error.projectId = "Please select project"
      flag = true
    }
    if (!dailySiteReportData.date) {
      error.date = "Please select date"
      flag = true
    }
    if (!dailySiteReportData.totalMenPower) {
      error.totalMenPower = "Please enter total men power"
      flag = true
    }
    if (!dailySiteReportData.workDetails) {
      error.workDetails = "Please enter work details"
      flag = true
    }
    if (!dailySiteReportData.nextDayPlaning) {
      error.nextDayPlaning = "Please enter next day planing"
      flag = true
    }
    if (!dailySiteReportData.remark) {
      error.remark = "Please enter remark"
      flag = true
    }
    setFormErrors(error);
    return flag
  }


  const onChangeHandler = (e, name) => {
    let re = /^[0-9\b]+$/;
    setFormErrors({ ...formErrors, [name]: '' })
    if (name === "totalMenPower") {
      if (e.target.value === "" || re.test(e.target.value)) {
        setDailySiteReportData({ ...dailySiteReportData, [name]: e.target.value })
      }
    } else if (name === "date") {
      setDailySiteReportData({ ...dailySiteReportData, [name]: e })
    } else if (name === "projectId") {
      setDailySiteReportData({ ...dailySiteReportData, [name]: e.value })
    } else {
      setDailySiteReportData({ ...dailySiteReportData, [name]: e.target.value })
    }
  }

  const openCalender = (id) => {
    document.getElementById(id).click()
  }

  const selectValue = (value, name) => {
    if (name === "projectId") {
      return projectOption.find((ele) => ele.value === value)
    }
  }

  return (
    <>
      <div className='p-4 daily-sitereport-section'>
        <div className='mb-4 set-page-heade'>
          <div>
            <h2 className='mb-0'>Daily Report</h2>
          </div>
          <div className='mt-3 mt-sm-0'>
            {editId ? <button className="adduser-btn" onClick={() => onEdit()}>Edit</button> : <button className="adduser-btn" onClick={() => onSave()}>Save</button>}
            <button className="cancel-button ms-3" onClick={() => navigate("/daily-report")}>Cancel</button>
          </div>
        </div>
        <div className="users-form addEnquiry">

          <Row>
            <Col lg={3} md={4} sm={6}>
              <div className="users-form-info add-remark-modal mx-0 mt-3">
                <div className="multi-select">
                  <label>Project</label>
                  <Select
                    placeholder="Select Project"
                    value={selectValue(dailySiteReportData.projectId._id, "projectId")}
                    onChange={(e) => onChangeHandler(e, "projectId")}
                    options={projectOption}
                    isDisabled={editId}
                  />
                </div>
                {formErrors?.projectId && <span style={{ color: "red" }}>{formErrors.projectId}</span>}
              </div>
            </Col>
            {projectType && <Col lg={3} md={4} sm={6}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Project Type</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter total men power"
                    value={projectType}
                    autoComplete="off"
                    disabled
                  />
                </div>
              </div>
            </Col>}
            <Col lg={3} md={4} sm={6}>
              <div className="users-form-info date-picker mt-3 enquery-datepicker">
                <div className="users-label">
                  <label>Date</label>
                  <ReactDatePicker id='Remarkdate' dateFormat="dd/MM/yyyy" selected={dailySiteReportData.date} placeholderText="Select date" onChange={(e) => { onChangeHandler(e, "date") }} autoComplete='off' />
                </div>
                <img src={Datepickericon} onClick={() => openCalender('Remarkdate')} alt="" className='datepickericon' id='Remarkdate' />
                {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>}
              </div>
            </Col>
            <Col lg={projectType ? 0 : 3}>
              <Col lg={projectType ? 12 : 12}>
                <div className="users-form-info mt-3">
                  <div className="users-label">
                    <label>Total Men Power</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter total men power"
                      onChange={(e) => { onChangeHandler(e, "totalMenPower") }}
                      value={dailySiteReportData.totalMenPower}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.totalMenPower && <label style={{ color: "red" }}>{formErrors.totalMenPower}</label>}
                </div>
              </Col>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Work Detail</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter work detail"
                    onChange={(e) => { onChangeHandler(e, "workDetails") }}
                    value={dailySiteReportData.workDetails}
                    rows={1}
                  />
                </div>
                {formErrors?.workDetails && <label style={{ color: "red" }}>{formErrors.workDetails}</label>}
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Next Day Planing</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter next day planing"
                    onChange={(e) => { onChangeHandler(e, "nextDayPlaning") }}
                    value={dailySiteReportData.nextDayPlaning}
                    rows={1}
                  />
                </div>
                {formErrors?.nextDayPlaning && <label style={{ color: "red" }}>{formErrors.nextDayPlaning}</label>}
              </div>
            </Col>
            <Col lg={3} md={4} sm={6}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Remark</label>
                  <textarea
                    type="tetx"
                    className="form-control"
                    placeholder="Enter remark"
                    onChange={(e) => { onChangeHandler(e, "remark") }}
                    value={dailySiteReportData.remark}
                    rows={1}
                  />
                </div>
                {formErrors?.remark && <label style={{ color: "red" }}>{formErrors.remark}</label>}
              </div>
            </Col>
          </Row>

          {/* <div className=" row">
            <div className="col-lg-6 mt-3">
              <div className="users-form-info ">
                <div className="users-label">
                  <label>Projects</label>
                  <div
                    className="position-relative select-icon"
                    style={{ width: "max-content" }}
                  >
                    <img src={img} className={`${rotateArrow && "rotateArrow"}`} alt="" />
                    <select className="form-control" onChange={(e) => { onChangeHandler(e, "projectId") }} value={dailySiteReportData.projectId} onClick={() => { setrotateArrow(!rotateArrow) }} onBlur={() => { setrotateArrow(false) }} disabled={editId}>
                      <option value="" >Select project</option>
                      {projectData && projectData.map((item, i) =>
                        < option key={i} value={item._id}>{item.partyName}</option>)}
                    </select>
                  </div>
                </div>
                {formErrors?.projectId && <label style={{ color: "red" }}>{formErrors.projectId}</label>}
              </div>
            </div>
            <div className="col-lg-6 mt-3">
              <div className="users-form-info date-picker">
                <div className="users-label">
                  <label>Date</label>
                  <ReactDatePicker id='Remarkdate' selected={dailySiteReportData.date} placeholderText="Select date" onChange={(e) => { onChangeHandler(e, "date") }} autoComplete='off' />
                </div>
                <img src={Datepickericon} onClick={() => openCalender('Remarkdate')} alt="" className='datepickericon' id='Remarkdate' />
                {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>}
              </div>
            </div>
          </div> */}
          {/* <div className=" row">
            <div className="col-lg-6 mt-3">
              <div className="users-form-info ">
                <div className="users-label">
                  <label>Total Men Power</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter total men power"
                    onChange={(e) => { onChangeHandler(e, "totalMenPower") }}
                    value={dailySiteReportData.totalMenPower}
                    autoComplete="off"
                  />
                </div>
                {formErrors?.totalMenPower && <label style={{ color: "red" }}>{formErrors.totalMenPower}</label>}
              </div>
            </div>
          </div> */}
          {/* <div className=" row">    */}
          {/* <div className="col-lg-6 mt-3">
              <div className="users-form-info ">
                <div className="users-label">
                  <label>Work Detail</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter work detail"
                    onChange={(e) => { onChangeHandler(e, "workDetails") }}
                    value={dailySiteReportData.workDetails}
                  />
                </div>
                {formErrors?.workDetails && <label style={{ color: "red" }}>{formErrors.workDetails}</label>}
              </div>
            </div> */}
          {/* <div className="col-lg-6 mt-3">
              <div className="users-form-info ">
                <div className="users-label">
                  <label>Next Day Planing</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter next day planing"
                    onChange={(e) => { onChangeHandler(e, "nextDayPlaning") }}
                    value={dailySiteReportData.nextDayPlaning}
                  />
                </div>
                {formErrors?.nextDayPlaning && <label style={{ color: "red" }}>{formErrors.nextDayPlaning}</label>}
              </div>
            </div> */}
          {/* </div>
          <div className=" row"> */}
          {/* <div className="col-lg-6 mt-3">
              <div className="users-form-info ">
                <div className="users-label">
                  <label>Remark</label>
                  <textarea
                    type="tetx"
                    className="form-control"
                    placeholder="Enter remark"
                    onChange={(e) => { onChangeHandler(e, "remark") }}
                    value={dailySiteReportData.remark}
                  />
                </div>
                {formErrors?.remark && <label style={{ color: "red" }}>{formErrors.remark}</label>}
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
      {/* {success &&
        <SweetAlert
          success
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="success"
          title="Are you sure?"
          onConfirm={() => {
            toastSuccess()
          }}
          onCancel={() => { setSuccess(false) }}
          focusCancelBtn
        >
          You want to update it
        </SweetAlert>
      } */}
    </>
  )
}

export default DailySiteReport