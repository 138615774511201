import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getSiteReportsNoAuthByIdAction } from '../../redux/actions/dailySiteReportAction';

const Viewprojectreport = ({ show, onHide, headerText, selectedReportId }) => {

    const dispatch = useDispatch();
    const [showMoadalData, setShowMoadalData] = useState()

    const modalData = useSelector(state => state.get_Site_Report_No_Auth_By_Id.getSiteReportsById)

    useEffect(() => {
        setShowMoadalData(modalData?.data)
    }, [modalData])


    useEffect(() => {
        if (selectedReportId) {
            dispatch(getSiteReportsNoAuthByIdAction(selectedReportId))
        }
    }, [selectedReportId])


    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <Row>
                    <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                    <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                    <hr></hr>
                </Row> */}
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-site-report-modal'>
                                <tr>
                                    <td>Project Name</td>
                                    <td>{showMoadalData?.projectId.partyName}</td>
                                </tr>
                                <tr>
                                    <td>Project Type</td>
                                    <td>{showMoadalData?.projectId.projectType}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-site-report-modal'>
                                <tr>
                                    <td>Date</td>
                                    <td>{moment(showMoadalData?.date).format('DD MMM YYYY')}</td>
                                </tr>
                                <tr>
                                    <td>Total Men Power</td>
                                    <td>{showMoadalData?.totalMenPower}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-site-report-modal viewer-site-report-modal-details'>
                                <tr>
                                    <td className='vertical-top'>Work Detail</td>
                                    <td className='vertical-top'>{showMoadalData?.workDetails}</td>
                                </tr>
                                <tr>
                                    <td className='vertical-top'>Next Day Planing</td>
                                    <td className='vertical-top'>{showMoadalData?.nextDayPlaning}</td>
                                </tr>
                                <tr>
                                    <td className='vertical-top'>remark</td>
                                    <td className='vertical-top'>{showMoadalData?.remark}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default Viewprojectreport