import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_QUOTATION, ADD_QUOTATION_ERROR, ADD_QUOTATION_LOADING, DELETE_QUOTATION, DELETE_QUOTATION_ERROR, DELETE_QUOTATION_LOADING, EDIT_QUOTATION, EDIT_QUOTATION_ERROR, EDIT_QUOTATION_LOADING, GET_QUOTATION_BY_ENQUIRYID, GET_QUOTATION_BY_ENQUIRYID_ERROR, GET_QUOTATION_BY_ENQUIRYID_LOADING, GET_QUOTATION_BY_ID, GET_QUOTATION_BY_ID_ERROR, GET_QUOTATION_BY_ID_LOADING, IS_LOADING } from "../type"


export const addQuotationAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_QUOTATION_LOADING,
            payload: true
        })

        await ApiPost(`quotation/add_quotation`, body)
            .then((res) => {
                dispatch({
                    type: ADD_QUOTATION,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_QUOTATION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_QUOTATION_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_QUOTATION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editQuotationAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_QUOTATION_LOADING,
            payload: true
        })

        await ApiPut(`quotation/edit_quotation`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_QUOTATION,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_QUOTATION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_QUOTATION_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_QUOTATION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}


export const getQuotationByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_QUOTATION_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`quotation/quotation_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_QUOTATION_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_QUOTATION_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_QUOTATION_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_QUOTATION_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}
export const deleteQuotationAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_QUOTATION_LOADING,
            payload: true
        })

        await ApiDelete(`quotation/delete_quotation/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_QUOTATION,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_QUOTATION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_QUOTATION_ERROR,
            payload: err
        })

        dispatch({
            type: GET_QUOTATION_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}
export const getQuotationByEnquiryIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_QUOTATION_BY_ENQUIRYID_LOADING,
            payload: true
        })

        await ApiGet(`quotation/quotation_by_enquiryId/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_QUOTATION_BY_ENQUIRYID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_QUOTATION_BY_ENQUIRYID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_QUOTATION_BY_ENQUIRYID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_QUOTATION_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

