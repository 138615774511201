import { ADD_PROJECT, ADD_PROJECT_ERROR, ADD_PROJECT_LOADING, DELETE_PROJECT, DELETE_PROJECT_ERROR, DELETE_PROJECT_LOADING, EDIT_PROJECT, EDIT_PROJECT_ERROR, EDIT_PROJECT_LOADING, GET_ALL_PROJECT, GET_ALL_PROJECT_ERROR, GET_ALL_PROJECT_LOADING, GET_PROJECT_BY_CUSTOMER, GET_PROJECT_BY_CUSTOMER_ERROR, GET_PROJECT_BY_CUSTOMER_LOADING, GET_PROJECT_BY_ID, GET_PROJECT_BY_ID_ERROR, GET_PROJECT_BY_ID_LOADING } from "../type";

const addProjectData = {
    loading: false,
    addProject: [],
    error: '',
}

const getAllProjectData = {
    loading: false,
    getAllProject: [],
    error: '',
}

const getProjectByIdData = {
    loading: false,
    getProjectById: [],
    error: '',
}

const getProjectByCustomerData = {
    loading: false,
    getProjectByCustomer: [],
    error: '',
}

const editProjectData = {
    loading: false,
    editProject: [],
    error: '',
}
const deleteProjectData = {
    loading: false,
    deleteProject: [],
    error: '',
}

export const addProjectReducer = (state = addProjectData, action) => {
    switch (action.type) {
        case ADD_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_PROJECT:
            return {
                ...state,
                addProject: action.payload,
            };
        case ADD_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllProjectReducer = (state = getAllProjectData, action) => {
    switch (action.type) {
        case GET_ALL_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_PROJECT:
            return {
                ...state,
                getAllProject: action.payload,
            };
        case GET_ALL_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProjectByIdReducer = (state = getProjectByIdData, action) => {
    switch (action.type) {
        case GET_PROJECT_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PROJECT_BY_ID:
            return {
                ...state,
                getProjectById: action.payload,
            };
        case GET_PROJECT_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProjectByCustomerReducer = (state = getProjectByCustomerData, action) => {
    switch (action.type) {
        case GET_PROJECT_BY_CUSTOMER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PROJECT_BY_CUSTOMER:
            return {
                ...state,
                getProjectByCustomer: action.payload,
            };
        case GET_PROJECT_BY_CUSTOMER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editProjectReducer = (state = editProjectData, action) => {
    switch (action.type) {
        case EDIT_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_PROJECT:
            return {
                ...state,
                editProject: action.payload,
            };
        case EDIT_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteProjectReducer = (state = deleteProjectData, action) => {
    switch (action.type) {
        case DELETE_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_PROJECT:
            return {
                ...state,
                deleteProject: action.payload,
            };
        case DELETE_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}