import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BellImg_green from '../../assets/image/bell_green.png'
import BellImg_oreng from '../../assets/image/bell_orenge.png'
import BellImg_red from '../../assets/image/bell_red.png'
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';
import moment from 'moment';
import { toast } from 'react-toastify';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";
import { getAllProductValueAction } from '../../redux/actions/productAction';
import CurrencyFormat from 'react-currency-format';

const StockSummary = () => {

    const userRole = useContext(RoleContext);
    const { getAllProducts } = usePaginationHooks()
    const toastNoRecord = () => toast.warning('No records found!');
    const [limit, setlimit] = useState(Paginationlimit);
    const [gridApi, setGridApi] = useState(null);
    // const userRole = AuthStorage.getStorageJsonData(STORAGEKEY.userData) 
    const RolesForAdd = [UserRoles.Admin, UserRoles.Store]
    const [ProductData, setProductData] = useState([]);
    const gridRef = useRef();
    const dispatch = useDispatch()
    const [columnDefs, setColumnDefs] = useState([]);
    const [filter, setFilter] = useState({ search: "" });
    const [sorting, setSorting] = useState({ column_name: '', column_order: '' });
    const [search, setSearch] = useState("");
    // const ProductData = useSelector((state) => state.get_All_Product.getAllProduct)
    const getAllProductValueData = useSelector((state) => state.get_All_Product_Value.getAllProductValue)

    const columnDefsNews = [
        {
            field: 'productName',
            flex: 1,
            headerName: 'PRODUCT NAME'
        },
        {
            field: 'unitOfMeasurement',
            maxWidth: 80,
            headerName: "UOM"
        },
        {
            field: 'purchaseAndOpeningStock',
            maxWidth: 155,
            headerName: "DR (IN) + OPENING",
            sortable: false,
        },
        {
            field: 'sell',
            maxWidth: 100,
            headerName: "CR (OUT)"
        },
        {
            field: 'onHand',
            maxWidth: 100,
            headerName: "ON HAND",
            sortable: false,
        },
        {
            headerName: 'MIN. QTY',
            maxWidth: 120,
            cellRendererFramework: (params) => {
                return (
                    <div>
                        {params.data?.onHand > params.data?.minQuntity && <> <img src={BellImg_green} className='me-3' style={{ cursor: "pointer", height: '20px' }} alt="" /><span >{params.data?.minQuntity}</span></>}
                        {params.data?.onHand === params.data?.minQuntity && <><img src={BellImg_oreng} className='me-3' style={{ cursor: "pointer", height: '20px' }} alt="" /><span>{params.data?.minQuntity}</span></>}
                        {params.data?.onHand < params.data?.minQuntity && <><img src={BellImg_red} className='me-3' style={{ cursor: "pointer", height: '20px' }} alt="" /><span>{params.data?.minQuntity}</span></>}
                    </div>
                )
            }
        }

    ]

    const NoRolecolumnDefsNew = [
        {
            field: 'productName',
            flex: 1,
            headerName: 'PRODUCT NAME'
        },
        {
            field: 'unitOfMeasurement',
            maxWidth: 80,
            headerName: "UOM"
        },
        {
            field: 'purchaseAndOpeningStock',
            minWidth: 155,
            headerName: "DR (IN) + OPENING",
            sortable: false,
        },
        {
            field: 'sell',
            minWidth: 100,
            headerName: "CR (OUT)"
        },
        {
            field: 'onHand',
            minWidth: 100,
            headerName: "ON HAND",
            sortable: false,
        },
        {
            headerName: 'MIN. QTY',
            minWidth: 120,
            cellRendererFramework: (params) => {
                return (
                    <div>
                        {params.data?.onHand > params.data?.minQuntity && <> <img src={BellImg_green} className='me-3' style={{ cursor: "pointer", height: '20px' }} alt="" /><span >{params.data?.minQuntity}</span></>}
                        {params.data?.onHand === params.data?.minQuntity && <><img src={BellImg_oreng} className='me-3' style={{ cursor: "pointer", height: '20px' }} alt="" /><span>{params.data?.minQuntity}</span></>}
                        {params.data?.onHand < params.data?.minQuntity && <><img src={BellImg_red} className='me-3' style={{ cursor: "pointer", height: '20px' }} alt="" /><span>{params.data?.minQuntity}</span></>}
                    </div>
                )
            }
        }

    ]

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        if (ProductData) {
            if (RolesForAdd.includes(userRole)) {
                setColumnDefs(columnDefsNews)
            } else {
                setColumnDefs(NoRolecolumnDefsNew)
            }
        }
    }, [ProductData])

    const exportReport = () => {
        getAllProducts("", "", filter.search, sorting.column_name, sorting.column_order).then(res => {
            if (res.data && res.data.length) {
                let data = res.data.map((ele) => {
                    return {
                        ProductName: ele.productName ?? '',
                        UnitOfMeasurement: ele.unitOfMeasurement ?? '',
                        PurchaseAndOpeningStock: ele.purchaseAndOpeningStock ?? '',
                        Sell: ele.sell ?? '',
                        OnHand: ele.onHand ?? '',
                        MinmumQuntity: ele.minQuntity ?? '',
                    }
                })
                const ws = XLSX.utils.json_to_sheet(data);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                FileSaver.saveAs(finalData, `Stock Summary ${moment().format('DD MMM YYYY hh:mm A')}` + '.xlsx');
            }
            else {
                toastNoRecord(true)
            }
        })
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    setSorting({ column_name: sorted_column, column_order: sorted_order });

                    getAllProducts(page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            setProductData(res?.data)
                            params.successCallback(res?.data?.map((item) => {
                                return {
                                    productName: item.productName ? item.productName : '',
                                    unitOfMeasurement: item.unitOfMeasurement ? item.unitOfMeasurement : '',
                                    purchaseAndOpeningStock: item.purchase + item.openingStock,
                                    sell: item.sell ? item.sell : '-',
                                    onHand: (item.purchase + item.openingStock) - item.sell,
                                    minQuntity: item.minQuntity ?? 0
                                }
                            }), res?.totalCount ? res?.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

    }, [gridApi, limit, filter.search]);

    useEffect(() => {
        if(!(getAllProductValueData && getAllProductValueData.data && getAllProductValueData.data.totalProductValue)){
            dispatch(getAllProductValueAction())   
        }
    }, [getAllProductValueData])

    return (
        <div className='position-relative p-4'>
            <div className='mb-4 set-page-heade'>
                <div className='d-flex w-100 justify-content-between align-items-center'>
                    <div className='d-flex flex-column'>
                        <h2>Stock Summary</h2>
                    </div>
                    <div className='d-flex'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn' onClick={() => clearFilter()}>Clear Filter</button>
                        <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => exportReport()}>{"Export"}</button>
                    </div>
                </div>
            </div>
            <div className='mb-4 bg-white position-relative'>
                <div className='text-center'>
                    <div style={{ fontSize: "14px", fontWeight: "600" }}>Note : STOCK STATE</div>
                    <div style={{ fontSize: "12px", fontWeight: "600" }}><img src={BellImg_green} width="20px" alt="" />= ABOVE MIN. <img src={BellImg_oreng} width="20px" alt="" />= EQUAL TO MIN. <img src={BellImg_red} width="20px" alt="" />= BELOW MIN/ZERO</div>
                </div>
                <div className='text-end stock-state-value fw-bold'>
                    Product Value
                    <div>
                        <CurrencyFormat value={getAllProductValueData?.data?.totalProductValue.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                    </div>
                </div>
            </div>
            <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                <AgGridReact
                    pagination={true}
                    columnDefs={columnDefs}
                    rowModelType={"infinite"}
                    paginationPageSize={limit}
                    cacheBlockSize={limit}
                    onGridReady={onGridReady}
                    rowHeight={60}
                    defaultColDef={defaultColDef}
                    overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                />

                <div className='set-pagination-dropdaun'>
                    <p>Show</p>
                    <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                        <option value="50">
                            50
                        </option>
                        <option value="100" selected={true}>100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                    <p>Entries</p>
                </div>
            </div>
        </div>
    )
}

export default StockSummary