import { ADD_ASSETS, ADD_ASSETS_ERROR, ADD_ASSETS_LOADING, DELETE_ASSETS, DELETE_ASSETS_ERROR, DELETE_ASSETS_LOADING, EDIT_ASSETS, EDIT_ASSETS_ERROR, EDIT_ASSETS_LOADING, GET_ALL_ASSETS, GET_ALL_ASSETS_LOADING, GET_ALL_ASSETS_NON_PROJECT, GET_ALL_ASSETS_NON_PROJECT_ERROR, GET_ALL_ASSETS_NON_PROJECT_LOADING, GET_ASSETS_BY_ID, GET_ASSETS_BY_ID_ERROR, GET_ASSETS_BY_ID_LOADING, GET_ASSETS_BY_PROJECT, GET_ASSETS_BY_PROJECT_ERROR, GET_ASSETS_BY_PROJECT_LOADING } from "../type"

const addAssetsData = {
    loading:false,
    addAssets : [],
    error:'',
};

const getAssetsByIdData = {
    loading:false,
    getAssetsById : [],
    error:'',
};

const getAllAssetsData = {
    loading:false,
    getAllAssets : [],
    error:'',
};

const getAllAssetsNonProjectData = {
    loading:false,
    getAllAssetsNonProject : [],
    error:'',
};

const getAssetsByProjectData = {
    loading:false,
    getAssetsByProject : [],
    error:'',
};

const editAssetsData = {
    loading:false,
    editAssets : [],
    error:'',
};

const deleteAssetsData = {
    loading:false,
    deleteAssets : [],
    error:'',
};

export const addAssetsReducer = (state = addAssetsData, action) => {
    switch (action.type) {
        case ADD_ASSETS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_ASSETS:
            return {
                ...state,
                addAssets: action.payload,
            };
        case ADD_ASSETS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAssetsByIdReducer = (state = getAssetsByIdData, action) => {
    switch (action.type) {
        case GET_ASSETS_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ASSETS_BY_ID:
            return {
                ...state,
                getAssetsById: action.payload,
            };
        case GET_ASSETS_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllAssetsReducer = (state = getAllAssetsData, action) => {
    switch (action.type) {
        case GET_ALL_ASSETS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_ASSETS:
            return {
                ...state,
                getAllAssets: action.payload,
            };
        case GET_ASSETS_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllAssetsNonProjectReducer = (state = getAllAssetsNonProjectData, action) => {
    switch (action.type) {
        case GET_ALL_ASSETS_NON_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_ASSETS_NON_PROJECT:
            return {
                ...state,
                getAllAssetsNonProject: action.payload,
            };
        case GET_ALL_ASSETS_NON_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAssetsByProjectReducer = (state = getAssetsByProjectData, action) => {
    switch (action.type) {
        case GET_ASSETS_BY_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ASSETS_BY_PROJECT:
            return {
                ...state,
                getAssetsByProject: action.payload,
            };
        case GET_ASSETS_BY_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editAssetsReducer = (state = editAssetsData, action) => {
    switch (action.type) {
        case EDIT_ASSETS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_ASSETS:
            return {
                ...state,
                editAssets: action.payload,
            };
        case EDIT_ASSETS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteAssetsReducer = (state = deleteAssetsData, action) => {
    switch (action.type) {
        case DELETE_ASSETS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_ASSETS:
            return {
                ...state,
                deleteAssets: action.payload,
            };
        case DELETE_ASSETS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}