import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllEnquiryAction, rejectEnquiryAction } from '../../redux/actions/enquiryAction';
import img from '../../assets/image/select.png';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import editIcon from '../../assets/image/edit.svg';
import approveImg from '../../assets/image/check.png'
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, SalesType, UserRoles } from '../../helper/Constant';
import rejectImg from '../../assets/image/reject.png'
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import ViewSales from '../../components/view-modals/ViewSales';
import { GET_ENQUIRY_BY_ID } from '../../redux/type';
import { dateFormate } from '../../helper/utils';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Enquiry = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userRole = useContext(RoleContext);
  const { getAllEnquiries } = usePaginationHooks()
  const [getEnquiry, setGetEnquiry] = useState([])
  const RolesForAdd = [UserRoles.Admin, UserRoles.Sales]
  const [select, setSelect] = useState('New')
  const [limit, setlimit] = useState(Paginationlimit);
  const [rotateArrow, setrotateArrow] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);
  const [reject, setReject] = useState();
  const [paramsValue, setParamsValue] = useState();
  const [view, setView] = useState(false)
  const [viewId, setViewId] = useState('')
  const toastSuccess = () => toast.success('Enquiry rejected successfully');
  const [columnDefsNew, setColumnDefsNew] = useState([]);
  const [reasonreject, setreasonreject] = useState()
  const [gridApi, setGridApi] = useState(null);
  const [filter, setFilter] = useState({ search: "" });
  const [search, setSearch] = useState("");
  const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)
  const deleteEnquiry = useSelector((state) => state.reject_Enquiry.rejectEnquiry)

  useEffect(() => {
    dispatch(getAllEnquiryAction())
  }, [])

  const approve = (id) => {
    if (id?.data?.projectType === SalesType.NewProject) {
      navigate(`approve?enquiryId=${id.value}`)
    } else if (id?.data?.projectType === SalesType.MaterialTrading) {
      navigate(`approve?enquiryId=${id.value}`)
    } else if (id?.data?.projectType === SalesType.Maintenance) {
      navigate(`maintenance?enquiryId=${id.value}`)
    } else if (id?.data?.projectType === SalesType.Consulting) {
      navigate(`/noc/counsulting?enquiryId=${id.value}`)
    }
  }

  const onEdit = (id) => {
    navigate(`edit?enquiryId=${id}`)
  }

  const onDelete = (id, body) => {
    dispatch(rejectEnquiryAction(id, body))
    setReject(false)
    toastSuccess(true)
    setreasonreject("");
  }

  const onView = (id) => {
    setView(true)
    setViewId(id)
  }

  const columnDefs = [
    { field: 'partyName', flex: 1, },
    { field: 'location', minWidth: 60, },
    {
      headerName: 'EnquiryDate',
      field: 'enquiryDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
      </div>
    },
    {
      headerName: 'VisitDate',
      field: 'visitDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
      </div>
    },
    // { field: 'quotationNumber' },
    { field: 'projectType', maxWidth: 120, },
    {
      field: "createdBy",
      minWidth: 60,
      maxWidth: 240,
      cellRendererFramework: (params) => <div>
        {params.value?.name}
      </div>
    },
    // {
    //   headerName: 'Action',
    //   field: "_id",
    //   sortable: false,
    //   cellRendererFramework: (params) => <div>
    //     {/* {userRole === UserRoles.Admin && <>
    //       <img src={approveImg} onClick={() => approve(params)} style={{ cursor: "pointer", height: "20px" }} title="Approve" alt='' />
    //       <img src={rejectImg} onClick={() => { setReject(true); setParamsValue(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Reject' alt='' />
    //     </>} */}
    //     {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Edit' alt='' />}
    //     <img src={viewImg} onClick={() => { onView(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
    //   </div>
    // }
  ]

  const columnNewDefs = [
    { field: 'partyName', flex: 1, },
    { field: 'location', minWidth: 60, },
    {
      headerName: 'EnquiryDate',
      field: 'enquiryDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : '-'}</span>
      </div>
    },
    {
      headerName: 'VisitDate',
      field: 'visitDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : '-'}</span>
      </div>
    },
    // { field: 'quotationNumber' },
    { field: 'projectType', maxWidth: 120, },
    {
      field: "createdBy",
      minWidth: 60,
      maxWidth: 240,
      cellRendererFramework: (params) => <div>
        {params.value?.name}
      </div>
    },
    {
      headerName: 'Action',
      field: "_id",
      minWidth: 60,
      maxWidth: 100,
      sortable: false,
      cellRendererFramework: (params) => <div>
        {/* {userRole === UserRoles.Admin && <>
          <img src={approveImg} onClick={() => approve(params)} style={{ cursor: "pointer", height: "20px" }} title="Approve" alt='' />
          <img src={rejectImg} onClick={() => { setReject(true); setParamsValue(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Reject' alt='' />
        </>} */}
        {/* {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Edit' alt='' />} */}
        <img src={viewImg} onClick={() => { onView(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
      </div>
    }
  ]

  const columnDefsReject = [
    { field: 'partyName', flex: 1, },
    { field: 'location', minWidth: 60, },
    {
      headerName: 'EnquiryDate',
      field: 'enquiryDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : '-'}</span>
      </div>
    },
    {
      headerName: 'VisitDate',
      field: 'visitDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : '-'}</span>
      </div>
    },
    // { field: 'quotationNumber' },
    { field: 'projectType', maxWidth: 120, },
    { field: 'rejectReason', minWidth: 60, },
    {
      field: "createdBy",
      minWidth: 60,
      maxWidth: 240,
      cellRendererFramework: (params) => <div>
        {params.value?.name}
      </div>
    },
  ]

  const columnDefsNews = [
    { field: 'partyName', flex: 1, },
    { field: 'location', minWidth: 60, },
    {
      headerName: 'EnquiryDate',
      field: 'enquiryDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, "Date") : '-'}</span>
      </div>

    },
    {
      headerName: 'VisitDate',
      field: 'visitDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : '-'}</span>
      </div>

    },
    // { field: 'quotationNumber' },
    { field: 'projectType', maxWidth: 120, },
    {
      field: "createdBy",
      minWidth: 60,
      maxWidth: 240,
      cellRendererFramework: (params) => <div>
        {params?.value?.name}
      </div>
    },
    {
      headerName: 'Action',
      field: "_id",
      minWidth: 140,
      maxWidth: 180,
      sortable: false,
      cellRendererFramework: (params) => <div>
        {userRole === UserRoles.Admin && <>
          <img src={approveImg} onClick={() => approve(params)} style={{ cursor: "pointer", height: "20px" }} title="Approve" alt='' />
          <img src={rejectImg} onClick={() => { setReject(true); setParamsValue(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Reject' alt='' />
        </>}
        {/* {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Edit' alt='' />} */}
        <img src={viewImg} onClick={() => { onView(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
      </div>
    }
  ]

  const NoRolecolumnDefsNew = [
    { field: 'partyName', flex: 1, },
    { field: 'location', minWidth: 60, },
    {
      headerName: 'EnquiryDate',
      field: 'enquiryDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
      </div>

    },
    {
      headerName: 'VisitDate',
      field: 'visitDate',
      maxWidth: 120,
      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
      </div>

    },
    // { field: 'quotationNumber' },
    { field: 'projectType', maxWidth: 120, },
    {
      field: "createdBy",
      minWidth: 60,
      maxWidth: 240,
      cellRendererFramework: (params) => <div>
        {params.value?.name}
      </div>
    },
    {
      headerName: 'Action',
      field: "_id",
      minWidth: 60,
      maxWidth: 100,
      sortable: false,
      cellRendererFramework: (params) => <div>
        <img src={viewImg} onClick={() => { onView(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
      </div>
    }
  ]

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      width: 'auto',
      filter: false,
      flex: 1
    };
  }, []);

  const onHide = () => {
    setView(false)
    dispatch({
      type: GET_ENQUIRY_BY_ID,
      payload: []
    })
  }

  useEffect(() => {
    if (getEnquiry) {
      if (RolesForAdd.includes(userRole)) {
        setColumnDefsNew(columnDefsNews)
      } else {
        setColumnDefsNew(NoRolecolumnDefsNew)
      }
      // getData()
    }
  }, [getEnquiry, select])

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const clearFilter = () => {
    setFilter({ search: '' });
    setSearch('');
    setSelect('New');
  }

  const debouncedHandleSearch = useCallback(debounce((value) => {
    setFilter({ ...filter, ["search"]: value });
  }, 1000), []);

  useEffect(() => {
    debouncedHandleSearch(search);
  }, [search]);

  useEffect(() => {
    if (gridApi || deleteEnquiry.status === 200 || filter.search) {
      const dataSource = {
        getRows: (params) => {
          const page = params.endRow / limit;
          const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
          const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

          getAllEnquiries(page, limit, select, filter.search, sorted_column, sorted_order)
            .then(res => {
              setGetEnquiry(res.enquiryData)
              params.successCallback(res.data.map((ele) => {
                return {
                  partyName: ele.partyName ? ele.partyName : '',
                  location: ele.location ? ele.location : '',
                  enquiryDate: ele.partyName ? ele.enquiryDate : '',
                  visitDate: ele.visitDate ? ele.visitDate : '',
                  quotationNumber: ele.quotationNumber ? ele.quotationNumber : '',
                  projectType: ele.projectType ? ele.projectType : '',
                  createdBy: ele.createdBy ? ele.createdBy : '',
                  rejectReason: ele.rejectReason ? ele.rejectReason : '',
                  _id: ele._id ? ele._id : '',
                }
              }), res.totalCount ? res.totalCount : 0);

              if (res?.data?.length <= 0) {
                gridApi.showNoRowsOverlay();
              } else {
                gridApi.hideOverlay();
              }
            })
        }
      };
      gridApi?.setDatasource(dataSource);
    }

    // setisFilter(false)
  }, [gridApi, select, limit, deleteEnquiry, filter.search]);

  const handleRowClick = (params) => {
    if (params.colDef.field !== "_id") {
      return onEdit(params.data._id);
    }
  }

  return (
    <>
      <div className='position-relative p-4'>
        <div className='mb-4 set-page-heade'>
          <div className='d-flex flex-column'>
            <h2 className='mb-0'>Sales </h2>
          </div>
          <div className='d-flex'>
            <div className="me-3">
              <input
                type="text"
                className="form-control search-button users-label"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value.trimStart())}
                autoComplete="off"
              />
            </div>
            <div className="position-relative header-select users-label" style={{ width: "max-content" }}>
              <img src={img} className={`${rotateArrow && "rotateArrow"}`} width="18px" alt="" />
              <select className="form-control mt-0" style={{ width: "190px" }} value={select} onBlur={() => { setrotateArrow(false) }} onClick={() => { setrotateArrow(!rotateArrow) }} onChange={(e) => setSelect(e.target.value)} name="role">
                <option value="New">New</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
                <option value="Approval For Admin">Approval For Admin</option>
              </select>
            </div>
            <button className='adduser-btn ms-3' onClick={() => clearFilter()}>Clear Filter</button>
            {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => { navigate('add') }}>Add Enquiry</button>}
          </div>
        </div>
        {enquiryData &&
          <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
            {/* {select && <AgGridReact
              ref={gridRef}
              rowData={enquiryData}
              columnDefs={select === 'New' ? columnDefsNew : select === 'Rejected' ? columnDefsReject : columnDefs}
              pagination={true}
              defaultColDef={defaultColDef}
              paginationPageSize={perPage}
              style={{ width: "100%" }}
              onFirstDataRendered={onFirstDataRendered}
              onGridSizeChanged={onGridSizeChanged}
            />
            } */}
            <AgGridReact
              pagination={true}
              columnDefs={select === 'Approval For Admin' ? columnDefsNew : select === 'New' ? columnNewDefs : select === 'Rejected' ? columnDefsReject : columnDefs}
              rowModelType={"infinite"}
              paginationPageSize={limit}
              cacheBlockSize={limit}
              onGridReady={onGridReady}
              rowHeight={45}
              defaultColDef={defaultColDef}
              rowSelection={'single'}
              onCellClicked={
                ['Approval For Admin','New'].includes(select) ? handleRowClick : undefined
              }
              overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
            />

            {/* <Pagination setlimit={setlimit} setPage={setPage} page={page} limit={limit} data={saleData} totalCount={totalCount} /> */}
            <div className='set-pagination-dropdaun'>
              <p>Show</p>
              <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                <option value="50">
                  50
                </option>
                <option value="100" selected={true}>100</option>
                <option value="150">150</option>
                <option value="200">200</option>
              </select>
              <p>Entries</p>
            </div>
          </div>
        }
      </div>
      {view && <ViewSales show={view} onHide={() => onHide()} saleId={viewId} />}
      {reject &&
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Reject"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => onDelete(paramsValue, { rejectReason: reasonreject })}
          onCancel={() => { setReject(false); setreasonreject("") }}
          focusCancelBtn
        >
          You want to Reject it

          {
            <form>
              <input
                type={'text'}
                // ref={renderProps.setAutoFocusInputRef}
                className="form-control"
                value={reasonreject}
                // onKeyDown={renderProps.onEnterKeyDownConfirm}
                onChange={(e) => { setreasonreject(e.target.value) }}
                placeholder={'Enter reason of reject Enquiry'}
              />
            </form>
          }
        </SweetAlert>
      }
    </>
  )
}

export default Enquiry   