import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Bars from '../../assets/img/bars.svg'
import profileImg from '../../assets/img/profileimg.svg'
import STORAGEKEY from '../../config/APP/app.config';
import AuthStorage from '../../helper/AuthStorage';
import { useNavigate } from 'react-router-dom';
import { LOGIN } from '../../redux/type';
import Avatar from 'react-avatar';
import { UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import NotificationImg from '../../assets/image/notification.png'
import { getNotificationByUserIdAction, getNotificationCountAction, readNotificationAction } from '../../redux/actions/norificationAction';
import moment from "moment";

const Header = ({ showSidebar, setSidebar }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const data = useSelector((state) => state.get_User_By_Id.getUserById.data)
  const [userData, setUserData] = useState(null)
  const [profileList, setProfileList] = useState(false)
  const [notification, setnotification] = useState('')
  const [notificationList, setnotificationList] = useState(false)

  const userRole = useContext(RoleContext);
  const RolesForAdd = [UserRoles.Admin]

  const notificationCount = useSelector((state) => state.get_Notification_Count.getNotificationCount)
  const getNotificationByUserId = useSelector((state) => state.get_Notification_By_User_Id.getNotificationByUserId)
  const readNotificationData = useSelector((state) => state.read_Notification.readNotification)

  useEffect(() => {
    const data = AuthStorage.getStorageJsonData(STORAGEKEY.userData)
    setUserData(data)
  }, [])

  useEffect(() => {
    notiCount()
    let temp = setInterval(() => {
      notiCount()
    }, 10000);
    return () => clearInterval(temp);
  }, []);

  const notiCount = () => {
    dispatch(getNotificationCountAction())
  }

  useEffect(() => {
    if (notificationCount) {
      if (notificationCount && notificationCount?.data > 99) {
        setnotification("99+")
      } else {
        setnotification(notificationCount?.data)
      }
    }
  }, [notificationCount])

  useEffect(() => {
    if (notificationList) {
      notiCount()
      dispatch(getNotificationByUserIdAction())
    }
  }, [notificationList])

  const clickNotification = (id, type, typeId) => {
    dispatch(readNotificationAction(id))
    console.log('type', type)
    console.log('typeId', typeId)
    if (type === "Task") {
      navigate(`/task?type=${typeId}`)
    } else if (type === "Project") {
      navigate(`/project/edit?projectId=${typeId}`)
    } else if (type === "ProjectPR") {
      navigate(`/purchaserequests?prid=${typeId}`)
    } else if (type === "SiteReport") {
      navigate(`/daily-report`)
    } else if (type === "Product") {
      // navigate(`/product?productId=${typeId}`)
      navigate(`/product`)
    } else if (type === "Sale") {
      navigate(`/sale`)
    } else if (type === "Purchase") {
      navigate(`/purchase`)
    } else if (type === "Liasoning") {
      navigate(`/noc`)
    } else if (type === "StorePR") {
      navigate(`/purchaserequest?prid=${typeId}`)
    } else if (type === "Remarks") {
      navigate(`/noc`)
    }
  }

  useEffect(() => {
    if (readNotificationData && readNotificationData.message === "Notification readed successfully.") {
      notiCount()
    }
  }, [readNotificationData])


  const logOut = () => {
    AuthStorage.deauthenticateUser()
    navigate("/")
    dispatch({
      type: LOGIN,
      payload: undefined
    })
  }

  return (
    <div className='header' >
      <figure className={`${showSidebar ? 'toggal-btn' : ''}`} onClick={() => setSidebar(!showSidebar)}>
        <img src={Bars} alt="bars" className='bars' />
      </figure>
      <div className={`profile position-relative ${showSidebar ? 'header-profile' : ''}`}>
        <button style={{ borderRadius: "50%", height: "45px", width: " 45px", padding: "0px", border: "none" }} onClick={() => setnotificationList(!notificationList)} onBlur={() => setnotificationList(false)}>
          <img src={NotificationImg} className="notification" />
          {notification ? <label className='notification-count'>{notification}</label> : <></>}
          {
            notificationList && getNotificationByUserId?.notificationCount?.length > 0 &&
            <div className='notification-list'>
              {getNotificationByUserId.notificationCount.map((ele, i) =>
                <div className='card-list-div' key={i} onClick={() => clickNotification(ele._id, ele.type, ele.typeId)}>
                  <p style={{ fontWeight: ele.isSeen ? "normal" : "bold" }}>{ele.message}</p>
                  <h6 style={{ textAlign: "right", fontSize: "10px" }}>{moment(ele.createdAt).format('DD/MM hh:mm a')}</h6>
                </div>
              )}
            </div>
          }
          {notificationList && getNotificationByUserId?.notificationCount?.length === 0 &&
            <div className='notification-list'>
              <div className='card-list-div'>
                <h5 style={{ textAlign: "center" }}>No Notifications</h5>
              </div>
            </div>
          }
        </button>
        <label>{userData?.name}</label>
        <button style={{ borderRadius: "50%", height: "45px", width: " 45px", padding: "0px", border: "none" }} onClick={() => setProfileList(!profileList)} onBlur={() => setProfileList(false)} >
          <Avatar name={userData?.name} round={true} size="45px" textSizeRatio={1.5} />
          {profileList &&
            <div className='profile-list'>
              {(RolesForAdd.includes(userRole) || userRole === UserRoles.Admin) && <p onClick={() => navigate('/bank')}>Bank</p>}
              <p onClick={() => logOut()}>Logout</p>
            </div>
          }
        </button>
      </div>
    </div>
  )
}

export default Header