import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import { Paginationlimit } from '../../helper/Constant';
import { ADD_PROJECT_MATERIAL } from '../../redux/type';
import { decimalFormat, downloadFiles } from '../../helper/utils';
import viewImg from '../../assets/image/eye.svg'
import { getProjectByIdAction } from '../../redux/actions/projectAction';
import usePaginationHooks from '../../Hooks/paginationHooks';
import ReactDatePicker from 'react-datepicker';
import debounce from "lodash/debounce";
import { Button, Col, Row, SplitButton, Dropdown } from 'react-bootstrap';
import { getAllContractorAction } from '../../redux/actions/contractorAction';
import { addProjectMaterialAction } from '../../redux/actions/projectMaterialAction';
import ProjectMaterialHistory from '../../components/modals/ProjectMaterialHistory';
import Plusicon from "../../assets/image/plus.png";
import LabourBillHistoryModel from '../../components/modals/LabourBillHistory';
import img from '../../assets/image/select.png';
import Datepickericon from '../../assets/image/date-picker-icon.png';
import { getAllCustomerAction, getCustomerDropDownAction } from '../../redux/actions/customerAction';
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';

const ProjectMaterial = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const toastNoRecord = () => toast.warning('No records found!');
    const [limit, setlimit] = useState(Paginationlimit);
    const [limit2, setlimit2] = useState(Paginationlimit);
    const [rmLimit, setRMLimit] = useState(Paginationlimit);
    const [gridApi, setGridApi] = useState(null);
    const [IsView, setIsView] = useState(false)
    const [IsShowLabourBillHistory, setIsShowLabourBillHistory] = useState(false)
    const [delQty, setDelQty] = useState(0)
    const [gridApi2, setGridApi2] = useState(null);
    const [rmGridApi, setRMGridApi] = useState(null);
    const [isLabourBill, setIsLabourBill] = useState(false);
    const [IsGenerateNewLabourBill, setIsGenerateNewLabourBill] = useState(false);
    const [contractorOption, setContractorOption] = useState([])
    const projectId = queryParams.get("projectId");
    const [pendingProduct, setPendingProduct] = useState([])
    const [deliveredProducts, setDeliveredProducts] = useState([])
    const [finalDeliveredProducts, setFinalDeliveredProducts] = useState([])
    const [deliveredProductsCount, setDeliveredProductsCount] = useState(0)
    const [formErrors, setformErrors] = useState({})
    const [contractor, setcontractor] = useState('');
    const [labourbill_date, setLabourBill_Date] = useState(new Date().toISOString());
    const [labourbill_customer, setLabourBill_Customer] = useState("");
    const [deliveredPSearch, setDeliveredPSearch] = useState("");
    const [unDeliveredPSearch, setUnDeliverdPSearch] = useState("");
    // Return material (RM)
    const [rmSearch, setRMSearch] = useState("");

    // Summary
    const [total, settotal] = useState(0)
    const [totalLC, setTotalLC] = useState(0)
    const [totalBP, setTotalBP] = useState(0)
    const [totalRSP, settotalRSP] = useState(0)
    const [totalRMP, settotalRMP] = useState(0)

    // To call the api on search
    const [deliveredSearchOn, setDeliveredPSearchOn] = useState("");
    const [unDeliveredPSearchOn, setUnDeliverdPSearchOn] = useState("");
    const [rmSearchOn, setRMSearchOn] = useState("");
    const [product, setProduct] = useState({
        productCode: '',
        productId: '',
        productName: '',
        delQuantity: '',
        used: '',
        date: '',
        contractor: '',
        returnQuantity: '',
        onHand: ''
    })
    const [rotateArrow, setrotateArrow] = useState(false);
    const [rotateArrow1, setrotateArrow1] = useState(false);
    const [rotateArrow2, setrotateArrow2] = useState(false);
    const [view_productId, setView_productId] = useState("");
    const [filter, setFilter] = useState({ deliveredPSearch: "", unDeliveredPSearch: "" });
    const [customerOption, setCustomerOption] = useState([]);
    const [getRMByProjectData, setGetRMByProjectData] = useState([])
    const [getFinalMaterialByProjectData, setGetFinalMaterialByProjectData] = useState([])
    const { getAllPRProductByProject, generateLabourBill, getReturnMaterialByProject, getFinalMaterialByProjects } = usePaginationHooks()
    const getAllContractor = useSelector((state) => state.get_All_Contractor.getAllContractor)
    const userData = useSelector((state) => state.get_Project_By_Id.getProjectById)
    const addProjectMaterial = useSelector((state) => state.add_Project_material.addProjectMaterial)
    const deleteProjectMaterial = useSelector((state) => state.delete_Project_material.deleteProjectMaterial)
    const editProjectMaterial = useSelector((state) => state.edit_Project_material.editProjectMaterial)
    const approved_reject_labor_bill = useSelector((state) => state.add_Project_material.approve_or_reject_labor_bill_by_admin);
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer.data);

    useEffect(() => {
        dispatch(getAllCustomerAction());
        // dispatch(getCustomerDropDownAction());
    }, [])

    useEffect(() => {
        if (customerData?.length) {
            let temp = customerData.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setCustomerOption(temp.map((ele) => {
                return {
                    label: ele.accountName,
                    value: ele._id
                }
            }));
        }
    }, [customerData]);

    useEffect(() => {
        if (addProjectMaterial && addProjectMaterial.status === 200 && addProjectMaterial.message === "Project Material History added successfully.") {
            toast.success("Project Material Added Successfully.");
            dispatch({
                type: ADD_PROJECT_MATERIAL,
                payload: []
            });
            setProduct({
                productName: '',
                productCode: '',
                delQuantity: '',
                productId: '',
                used: '',
                date: '',
                contractor: '',
                returnQuantity: '',
                onHand: ''
            });
        }
        if (addProjectMaterial && addProjectMaterial.status === 500) {
            toast.error(addProjectMaterial.message);
            dispatch({
                type: ADD_PROJECT_MATERIAL,
                payload: []
            });
        }
    }, [addProjectMaterial])

    const columnDefs = [
        { field: 'productName', flex: 1, },
        // { field: 'productCode', maxWidth: 150, },
        // { field: 'quantity' },
        { field: 'delivered', maxWidth: 120, },
        { field: 'used', maxWidth: 120, },
        {
            field: "onHand",
            headerName: 'On Hand',
            maxWidth: 120,
            cellRendererFramework: (params) => {
                return (
                    <>
                        <div>
                            {decimalFormat(params?.data?.onHand ?? 0)}
                        </div>
                    </>
                )
            },
        },
        { headerName: 'Return Qty', field: 'returnQuantity', maxWidth: 120, },
        { field: 'BilledQty', maxWidth: 120, },
        { field: 'unit', maxWidth: 120, },
        {
            headerName: 'Action',
            field: "_id",
            maxWidth: 140,
            sortable: false,
            cellRendererFramework: (params) =>
                <div>
                    <>
                        {params?.data?.used < params?.data?.delivered ?
                            <img src={Plusicon} style={{ cursor: "pointer", height: "20px" }} onClick={() => { onEdit(params.data) }} title="Add Used Quantity" alt='' />
                            :
                            <img src={Plusicon} style={{ cursor: "pointer", height: "20px", opacity: 0.5 }} onClick={() => { onClear() }} alt='' disabled />
                        }
                    </>
                    {/* <img src={removeImg} onClick={() => { }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' /> */}
                    <img src={viewImg} onClick={() => { setView_productId(params?.data?.productId); setIsView(true); setDelQty(params?.data?.delivered) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
                </div>
        }
    ];

    const columnDefs2 = [
        { field: 'productName', flex: 1, },
        { field: 'productCode', maxWidth: 200, },
        { field: 'quantity', maxWidth: 150, },
        // { field: 'delivered' },
        // { field: 'used' },
        { field: 'unit', maxWidth: 150, },
        // { field: 'make' },
        // { field: 'desc', headerName: 'Description' },
    ];

    const columnDefsRM = [
        { field: 'productName', flex: 1, },
        { field: 'productCode', maxWidth: 200, },
        { field: 'quantity', maxWidth: 150, },
        { field: 'price', maxWidth: 150, },
        // { field: 'delivered' },
        // { field: 'used' },
        { field: 'unit', maxWidth: 150, },
        // { field: 'make' },
        // { field: 'desc', headerName: 'Description' },
    ];

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1
        };
    }, []);

    const onEdit = (data) => {
        setProduct({
            productName: data.productName,
            productCode: data.productCode,
            delQuantity: data.delivered,
            productId: data.productId,
            returnQuantity: data?.returnQuantity ?? 0,
            used: '',
            date: '',
            contractor: '',
            onHand: Number(data?.delivered ?? 0) - Number(data?.used + Number(data?.returnQuantity ?? 0) ?? 0)
        })
    }

    const onClear = (data) => {
        setProduct({
            productName: '',
            productCode: '',
            delQuantity: '',
            productId: '',
            used: '',
            date: '',
            contractor: '',
            returnQuantity: '',
            onHand: ''
        });
        setformErrors({});
    }

    const validatation = () => {
        let flag = false;
        let formError = {};
        if (!product.used) {
            formError = { ...formError, used: "Required" }
            flag = true
        }
        if (product.used > product.delQuantity) {
            formError = { ...formError, used: "graterthan delQuantity" }
            flag = true
        }
        if (product.used > product.onHand) {
            formError = { ...formError, used: `${product.onHand} Qty Available` }
            flag = true
        }

        if (!product.date) {
            formError = { ...formError, date: "Required" }
            flag = true
        }
        if (!product.contractor) {
            formError = { ...formError, contractor: "Required" }
            flag = true
        }
        setformErrors(formError)
        return flag
    }

    const onsubmit = () => {
        if (validatation()) {
            return
        }
        const body = {
            projectId: projectId,
            productId: product.productId,
            usedQty: product.used,
            usedDate: product.date,
            contractor: product.contractor,
            totalDeliveredProductQuantity: product.delQuantity
        }
        dispatch(addProjectMaterialAction(body))
    }

    useEffect(() => {
        // dispatch(getPurchaseRequestByProjectAction(projectId))
        dispatch(getProjectByIdAction(projectId))
        dispatch(getAllContractorAction())
    }, [projectId])

    useEffect(() => {
        if (userData && userData.data && userData.data.contractor.length && getAllContractor.data) {
            const temp = getAllContractor.data?.filter((ele) => {
                if (userData.data.contractor.includes(ele._id)) {
                    return ele
                }
            })
            const temp2 = temp.length ? temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.name,
                }
            }) : []
            setContractorOption(temp2)
        }
    }, [getAllContractor, userData])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const onGridReady2 = (params) => {
        setGridApi2(params.api);
    };

    const onGridReadyRM = (params) => {
        setRMGridApi(params.api);
    }

    const debouncedDPHandleSearch = useCallback(debounce((value) => {
        setDeliveredPSearchOn(value);
        setFilter({ ...filter, ["deliveredPSearch"]: value });
    }, 1000), []);

    const debouncedUDPHandleSearch = useCallback(debounce((value) => {
        setUnDeliverdPSearchOn(value);
        setFilter({ ...filter, ["unDeliveredPSearch"]: value });
    }, 1000), []);

    const debouncedRMHandleSearch = useCallback(debounce((value) => {
        setRMSearchOn(value);
    }, 1000), []);

    useEffect(() => {
        debouncedDPHandleSearch(deliveredPSearch);
    }, [deliveredPSearch]);

    useEffect(() => {
        debouncedUDPHandleSearch(unDeliveredPSearch);
    }, [unDeliveredPSearch]);

    useEffect(() => {
        debouncedRMHandleSearch(rmSearch);
    }, [rmSearch]);

    useEffect(() => {
        if (gridApi || addProjectMaterial.status === 200 || editProjectMaterial.data?.status === 200 || deleteProjectMaterial.data?.status === 200 || approved_reject_labor_bill?.status === 200 || deliveredSearchOn) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllPRProductByProject(page, limit, deliveredSearchOn, 'Delivered', sorted_column, sorted_order, projectId)
                        .then(res => {
                            setDeliveredProducts(res?.data || []);
                            setDeliveredProductsCount(res.totalCount ? res.totalCount : 0)
                            params.successCallback(res.data.map((ele) => {
                                return {
                                    productCode: ele?.productCode ? ele?.productCode : '',
                                    productName: ele?.productName ? ele?.productName : '',
                                    // quantity: ele?.reqQuantity ? ele?.reqQuantity : '',
                                    BilledQty: ele.totalGenerateLaborbillQty ?? 0,
                                    delivered: ele?.deliveredQuantity ? ele?.deliveredQuantity : 0,
                                    productId: ele?.productId ? ele?.productId : '',
                                    unit: ele?.unit ? ele?.unit : 0,
                                    used: ele?.totalUsedQty ? ele?.totalUsedQty : 0,
                                    _id: ele?._id ? ele?._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

    }, [gridApi, limit, addProjectMaterial, editProjectMaterial, deleteProjectMaterial, IsGenerateNewLabourBill, approved_reject_labor_bill, deliveredSearchOn]);

    useEffect(() => {
        if (gridApi2 || unDeliveredPSearchOn) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit2;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllPRProductByProject(page, limit, unDeliveredPSearchOn, '', sorted_column, sorted_order, projectId)
                        .then(res => {
                            setPendingProduct(res.data)
                            params.successCallback(res.data.map((ele) => {
                                return {
                                    productCode: ele?.productCode ? ele?.productCode : '',
                                    productName: ele?.productName ? ele?.productName : '',
                                    // quantity: ele?.reqQuantity ? ele?.reqQuantity : '',
                                    quantity: ((Number(ele?.reqQuantity) ? Number(ele?.reqQuantity) : 0) - (Number(ele?.deliveredQuantity) ? Number(ele?.deliveredQuantity) : 0)),
                                    // delivered: ele?.deliveredQuantity ? ele?.deliveredQuantity : 0,
                                    productId: ele?.productId ? ele?.productId : '',
                                    unit: ele?.unit ? ele?.unit : 0,
                                    _id: ele?._id ? ele?._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi2.showNoRowsOverlay();
                            } else {
                                gridApi2.hideOverlay();
                            }
                        })
                }
            };
            gridApi2?.setDatasource(dataSource);
        }

    }, [gridApi2, limit2, unDeliveredPSearchOn]);

    useEffect(() => {
        if (rmGridApi || rmSearchOn) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / rmLimit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getReturnMaterialByProject(page, rmLimit, rmSearch, sorted_column, sorted_order, projectId).then((res) => {
                        setGetRMByProjectData(res?.data || []);
                        params.successCallback(res?.data?.map((ele) => {
                            return {
                                productCode: ele?.productCode ?? '',
                                productName: ele?.productName ?? '',
                                quantity: ele?.quantity ?? 0,
                                price: ele?.price ?? 0,
                                unit: ele?.unit ?? '',
                            }
                        }), res.totalCount ? res.totalCount : 0);

                        if (res?.data?.length <= 0) {
                            rmGridApi.showNoRowsOverlay();
                        } else {
                            rmGridApi.hideOverlay();
                        }
                    })
                }
            };
            rmGridApi?.setDatasource(dataSource);
        }

    }, [rmGridApi, rmLimit, rmSearchOn])

    useEffect(() => {
        if (projectId) {
            getFinalMaterialByProjects(projectId, "", "", "", "", "").then((res) => {
                setGetFinalMaterialByProjectData(res?.data || [])
            })
        }
    }, [projectId])

    useEffect(() => {
        const totalRMP = getRMByProjectData?.length ? getRMByProjectData?.reduce((accumulator, curr) => {
            const fmProd = getFinalMaterialByProjectData?.data?.find((fm) => fm?.productId?._id === curr?.productId || fm?.productId?.productCode === curr?.productCode)
            return accumulator + ((fmProd?.buyingPrice ?? 0) * curr?.quantity)
        }, 0) : 0
        settotalRMP(totalRMP)
    }, [getRMByProjectData, getFinalMaterialByProjectData])

    useEffect(() => {
        if (deliveredProducts?.length) {
            const _deliveredProducts = deliveredProducts?.map((prod) => {
                let fmProd = getFinalMaterialByProjectData?.data?.find((fm) => {
                    const cond = fm?.productId?._id === prod?.productId || fm?.productId?.productCode === prod?.productCode;

                    if (cond) {
                        return true;
                    } else if (!cond && fm?.subProduct?.length) {
                        return fm.subProduct.some((subProd) =>
                            subProd?.productId?._id === prod?.productId || subProd?.productId?.productCode === prod?.productCode
                        );
                    }
                    return false;
                });
                const condition = fmProd?.productId?._id === prod?.productId || fmProd?.productId?.productCode === prod?.productCode;
                if (!condition && fmProd?.subProduct?.length) {
                    fmProd = fmProd?.subProduct?.find((subProd) => subProd?.productId?._id === prod?.productId || subProd?.productId?.productCode === prod?.productCode);
                }
                const rmQty = getRMByProjectData?.length ? getRMByProjectData?.find((rm) => rm?.productCode === prod?.productCode || rm?.productId === prod?.productId) : 0
                return {
                    ...prod,
                    retailSalePrice: fmProd?.retailSalePrice ?? 0,
                    buyingPrice: fmProd?.buyingPrice ?? 0,
                    labourCharge: fmProd?.labourCharge ?? 0,
                    deliveredQuantity: prod?.deliveredQuantity,
                    returnQuantity: rmQty?.quantity ?? 0
                }
            })
            const totalBP = _deliveredProducts?.reduce((acc, current) => acc + (current?.buyingPrice * current?.deliveredQuantity), 0)
            const totalLC = _deliveredProducts?.reduce((acc, current) => acc + (current?.labourCharge * current?.deliveredQuantity), 0)
            const totalRSP = _deliveredProducts?.reduce((acc, current) => acc + (current?.retailSalePrice * current?.deliveredQuantity), 0)
            setTotalBP(totalBP)
            setTotalLC(totalLC)
            settotalRSP(totalRSP)
            settotal(totalRSP - (totalBP + totalLC + totalRMP))
            setFinalDeliveredProducts(_deliveredProducts);
        }
    }, [deliveredProducts, getFinalMaterialByProjectData, getRMByProjectData])

    useEffect(() => {
        if (getRMByProjectData?.length && gridApi && finalDeliveredProducts?.length) {
            const dataSource = {
                getRows: (params) => {
                    params.successCallback(finalDeliveredProducts.map((ele) => {
                        const deliveredQty = ele?.deliveredQuantity ? ele?.deliveredQuantity : 0;
                        const usedQty = ele?.totalUsedQty ? ele?.totalUsedQty : 0;
                        const returnQty = ele?.returnQuantity ? ele?.returnQuantity : 0;
                        return {
                            productCode: ele?.productCode ? ele?.productCode : '',
                            productName: ele?.productName ? ele?.productName : '',
                            // quantity: ele?.reqQuantity ? ele?.reqQuantity : '',
                            BilledQty: ele.totalGenerateLaborbillQty ?? 0,
                            delivered: ele?.deliveredQuantity ? ele?.deliveredQuantity : 0,
                            productId: ele?.productId ? ele?.productId : '',
                            unit: ele?.unit ? ele?.unit : 0,
                            used: ele?.totalUsedQty ? ele?.totalUsedQty : 0,
                            returnQuantity: ele?.returnQuantity ? ele?.returnQuantity : 0,
                            onHand: Number(deliveredQty) - Number(usedQty + Number(returnQty) ?? 0),
                            _id: ele?._id ? ele?._id : '',
                        }
                    }), deliveredProductsCount)

                    if (finalDeliveredProducts?.length <= 0) {
                        gridApi.showNoRowsOverlay();
                    } else {
                        gridApi.hideOverlay();
                    }
                }
            }
            gridApi?.setDatasource(dataSource);
        }
    }, [getRMByProjectData, gridApi, finalDeliveredProducts, deliveredProductsCount])

    const _total = useMemo(() => {
        return (totalRSP - (totalBP + totalLC + totalRMP) ?? 0)
    }, [
        totalBP,
        totalLC,
        totalRSP,
        totalRMP
    ])

    const ondownload = (status) => {
        if (!contractor) {
            toast.warning('Please Select Contractor');
        } else if (!labourbill_customer) {
            toast.warning('Please Select Customer');
        } else {
            generateLabourBill(contractor, projectId, status, labourbill_customer, labourbill_date).then(res => {
                if (res.data) {
                    if (res.data.length) {
                        downloadFiles(res.data, null, null);
                        setIsLabourBill(false);
                        setIsGenerateNewLabourBill(pre => !pre);
                        setcontractor('');
                        setLabourBill_Customer('');
                        setLabourBill_Date(new Date().toISOString());
                    } else {
                        toast.warning('Already Bill Generated Of Use Product Or Products do not have labor charge');
                        setIsGenerateNewLabourBill(pre => !pre);
                    }
                } else {
                    toast.warning('No Product Found');
                }
            });
        }
    }

    const exportDeliveredProducts = useCallback(() => {
        getAllPRProductByProject("", "", deliveredPSearch, 'Delivered', "", "", projectId)
            .then((res) => {
                if (res.data && res.data.length) {
                    const data = res.data.map((ele) => {
                        const rmQty = getRMByProjectData?.length ? getRMByProjectData?.find((rm) => rm?.productCode === ele?.productCode || rm?.productId === ele?.productId) : 0;
                        const onHandQty = Number(ele?.deliveredQuantity ?? 0) - (Number(ele?.totalUsedQty ?? 0) + Number(rmQty?.quantity ?? 0))
                        return {
                            ProductName: ele?.productName ?? '',
                            // ProductCode: ele?.productCode ?? '',
                            Dlivered: ele?.deliveredQuantity ?? 0,
                            Used: ele?.totalUsedQty ?? 0,
                            OnHand: onHandQty,
                            ReturnMaterial: rmQty?.quantity ?? 0,
                            BilledQty: ele?.totalGenerateLaborbillQty ?? 0,
                            Unit: ele?.unit ?? '',
                        }
                    })
                    const ws = XLSX.utils.json_to_sheet(data);
                    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                    FileSaver.saveAs(finalData, `Delivered Products ${moment().format('DD MMM YYYY hh:mm A')}` + '.xlsx');
                } else {
                    toastNoRecord(true)
                }
            })
    }, [deliveredPSearch, projectId, getRMByProjectData])

    return (
        <>
            <div className='position-relative p-4'>
                <div className="mb-4 set-page-heade">
                    <h2 className="mb-0">Project Material</h2>
                    <div className="noc-form-btn final-material-button d-flex">
                        <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => setIsLabourBill(true)}>Generate Labour Bill</button>
                        <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => setIsShowLabourBillHistory(true)}>Labour Bill History</button>
                    </div>
                </div>
                <Row className='mb-1'>
                    <Col xxl={2} lg={4} sm={6}>
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="multi-select">
                                    <label>Product Name</label>
                                    <input
                                        type='text'
                                        className="form-control"
                                        placeholder="Enter Product"
                                        value={product.productName}
                                        onChange={(e) => {
                                            setProduct({ ...product, productName: e.target.value })
                                        }}
                                        // options={productOption}
                                        disabled
                                    />
                                </div>
                                {/* {productReq.unit && <label style={{ fontWeight: "bold", textAlign: 'end', width: '100%' }}>{productReq.unit}</label>} */}
                                {/* {formErrors?.productId && <label style={{ color: "red" }}>{formErrors.productId}</label>} */}
                            </div>
                        </div>
                    </Col>
                    <Col xxl={1} lg={4} sm={6}>
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label">
                                    <label>Del Qty</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Del qty"
                                        value={product.delQuantity}
                                        onChange={(e) => {
                                            setProduct({ ...product, delQuantity: e.target.value })
                                        }}
                                        disabled
                                    />
                                </div>
                                {/* {formErrors?.reqQuantity && <label style={{ color: "red" }}>{formErrors.reqQuantity}</label>} */}
                            </div>
                        </div>
                    </Col>
                    <Col xxl={1} lg={4} sm={6}>
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label">
                                    <label>Used Qty</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Req qty"
                                        value={product.used}
                                        onChange={(e) => {
                                            let numberRex = /^(?:\d*\.\d{0,2}|\d+)$/;
                                            if (!e.target.value || e.target.value === "" || numberRex.test(e.target.value)) {
                                                setProduct({ ...product, used: e.target.value })
                                            }
                                            if (e.target.value) {
                                                setformErrors({ ...formErrors, used: '' })
                                            }
                                        }}
                                    />
                                </div>
                                {formErrors?.used && <label style={{ color: "red" }}>{formErrors.used}</label>}
                            </div>
                        </div>
                    </Col>
                    <Col xxl={2} lg={4} sm={6}>
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label enquery-datepicker">
                                    <label>Date</label>
                                    <ReactDatePicker
                                        id="EnquiryDatePicker"
                                        dateFormat="yyyy-MM-dd"
                                        selected={product.date ? new Date(product.date) : null}
                                        placeholderText="Select date"
                                        onChange={(e) => {
                                            setProduct({ ...product, date: moment(e).format('YYYY-MM-DD') })
                                            if (e) {
                                                setformErrors({ ...formErrors, date: '' })
                                            }
                                        }}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>}
                            </div>
                        </div>
                    </Col>
                    <Col xxl={2} lg={4} sm={6}>
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label">
                                    <label>Contractor</label>
                                    <div className="position-relative header-select w-100" style={{ backgroundColor: "#fff" }}>
                                        <img src={img} className={`${rotateArrow && "rotateArrow"}`} width="18px" alt="" />
                                        <select
                                            className="form-control w-100"
                                            style={{ width: '162px' }}
                                            value={product.contractor}
                                            onBlur={() => { setrotateArrow(false) }}
                                            onClick={() => { setrotateArrow((pre) => !pre) }}
                                            onChange={(e) => {
                                                setProduct({ ...product, contractor: e.target.value })
                                                if (e.target.value) {
                                                    setformErrors({ ...formErrors, contractor: '' })
                                                }
                                            }}
                                            name="role"
                                        >
                                            <option value="" disabled>Select</option>
                                            {contractorOption.length && contractorOption.map((ele) => <option value={ele.value}>{ele.label}</option>)}
                                        </select>
                                    </div>
                                </div>
                                {formErrors?.contractor && <label style={{ color: "red" }}>{formErrors.contractor}</label>}
                            </div>
                        </div>
                    </Col>
                    <Col xxl={3} lg={4} sm={6}>
                        <div className="mt-3 small-input">
                            <div className="users-form-info w-100">
                                <div className="users-label">
                                    <label></label>
                                    <div className="form-control border-0 bg-transparent">
                                        <Button className='adduser-btn me-3' disabled={!product.productName && !product.delQuantity && !product.productId} onClick={() => { onsubmit() }}>{'Save'}</Button>
                                        <button className='cancel-button' onClick={() => { onClear() }} >{'Clear'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} xs={12}>
                        <div className='mb-4 mt-4 total-price-details align-items-center justify-content-between'>
                            <div>
                                <span className='title me-2'><b>Total Retail Sale Price :</b></span>
                                <span>{` ${decimalFormat(totalRSP ?? 0)} `}</span>
                            </div>
                            <div>
                                <span className='title me-2'><b>Total Buying Price :</b></span>
                                <span>{` ${decimalFormat(totalBP ?? 0)} `}</span>
                            </div>
                            <div>
                                <span className='title me-2'><b>Total Labour Charge :</b></span>
                                <span>{` ${decimalFormat(totalLC ?? 0)} `}</span>
                            </div>
                            <div>
                                <span className='title me-2'><b>Total Return Material :</b></span>
                                <span>{` ${decimalFormat(totalRMP ?? 0)} `}</span>
                            </div>
                            <div>
                                <span className='title me-2'><b>Net Profit :</b></span>
                                <span> {` ${decimalFormat(_total ?? 0)} `}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="row my-3">
                    <div className="users-label users-form-info border-top border-bottom py-2 d-flex align-items-center justify-content-between">
                        <h2 className='Milestores-title mb-0'>Delivered Products</h2>
                        <div className='d-flex align-items-center'>
                            <input
                                type="text"
                                className="form-control search-button mt-0"
                                placeholder="Search..."
                                value={deliveredPSearch}
                                onChange={(e) => setDeliveredPSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                            <button className="adduser-btn ms-3 mt-3 mt-lg-0" onClick={() => exportDeliveredProducts()}>{"Export"}</button>
                        </div>
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "500px", width: "100%", position: 'relative', marginBottom: '20px' }}>
                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />
                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
                <div className="row my-3">
                    <div className="users-label users-form-info border-top border-bottom py-2 d-flex align-items-center justify-content-between">
                        <h2 className='Milestores-title mb-0'>Undelivered Products</h2>
                        <div>
                            <input
                                type="text"
                                className="form-control search-button mt-0"
                                placeholder="Search..."
                                value={unDeliveredPSearch}
                                onChange={(e) => setUnDeliverdPSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "500px", width: "100%", position: 'relative' }}>
                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefs2}
                        rowModelType={"infinite"}
                        paginationPageSize={limit2}
                        cacheBlockSize={limit2}
                        onGridReady={onGridReady2}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />
                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit2(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
                {/* Return Material */}
                <div className="row my-3">
                    <div className="users-label users-form-info border-top border-bottom py-2 d-flex align-items-center justify-content-between">
                        <h2 className='Milestores-title mb-0'>Return Material</h2>
                        <div>
                            <input
                                type="text"
                                className="form-control search-button mt-0"
                                placeholder="Search..."
                                value={rmSearch}
                                onChange={(e) => setRMSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "500px", width: "100%", position: 'relative' }}>
                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefsRM}
                        rowModelType={"infinite"}
                        paginationPageSize={rmLimit}
                        cacheBlockSize={rmLimit}
                        onGridReady={onGridReadyRM}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />
                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setRMLimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
                {IsView && <ProjectMaterialHistory show={IsView} onHide={() => setIsView(false)} productId={view_productId} delQty={delQty} />}
                {IsShowLabourBillHistory && <LabourBillHistoryModel show={IsShowLabourBillHistory} onHide={() => setIsShowLabourBillHistory(false)} />}
                {isLabourBill && <SweetAlert
                    showCancel
                    confirmBtnText="Generate"
                    confirmBtnBsStyle="danger"
                    focusCancelBtn
                    customButtons={
                        <React.Fragment>
                            <Button className="btn btn-md mx-2" variant='outline-danger' onClick={() => { setIsLabourBill(false); setcontractor('') }}>Cancel</Button>
                            <div className='generate-section'>
                                {/* <SplitButton
                                    variant="danger"
                                    title="Generate"
                                    onClick={() => ondownload("Approved")}
                                    onSelect={(eventKey) => { eventKey === "1" ? ondownload("Pending") : "" }}
                                    drop="down"
                                    flip={true}
                                >
                                    <Dropdown.Item eventKey="1">Send For Admin Approval</Dropdown.Item>
                                </SplitButton> */}
                                <Button className="btn btn-md mx-2" variant='danger' onClick={() => { ondownload("Pending") }}>Send For Admin Approval</Button>

                            </div>
                        </React.Fragment>
                    }
                >
                    <div className="mt-0 small-input">
                        <div className="users-form-info w-100">
                            <div className="users-label">
                                <label>Select Contractor</label>
                                <div className="position-relative header-select" style={{ width: "max-content", backgroundColor: "#fff" }}>
                                    <img src={img} className={`${rotateArrow1 && "rotateArrow"}`} width="18px" alt="" />
                                    <select
                                        className="form-control"
                                        style={{ minWidth: '475px' }}
                                        value={contractor}
                                        onBlur={() => { setrotateArrow1(false) }}
                                        onClick={() => { setrotateArrow1((pre) => !pre) }}
                                        onChange={(e) => { setcontractor(e.target.value) }}
                                    >
                                        <option value="" disabled>Select</option>
                                        {contractorOption.length && contractorOption.map((ele) => (<option value={ele.value}>{ele.label}</option>))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="users-form-info w-100 mt-2">
                            <div className="users-label">
                                <label>Select Customer</label>
                                <span title='Select contractor entry added as a customer which will be used to add Payable entry'>
                                    <svg
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 32 32"
                                        enableBackground="new 0 0 32 32"
                                        xmlSpace="preserve"
                                        height={25}
                                        width={25}
                                        style={{ marginLeft: 5 }}
                                    >
                                        <rect x={15} y={14} width={2} height={8} />
                                        <rect x={15} y={10} width={2} height={2} />
                                        <circle
                                            fill="none"
                                            stroke="#000000"
                                            strokeWidth={2}
                                            strokeMiterlimit={10}
                                            cx={16}
                                            cy={16}
                                            r={12}
                                        />
                                    </svg>
                                </span>
                                <div className="position-relative header-select" style={{ width: "max-content", backgroundColor: "#fff" }}>
                                    <img src={img} className={`${rotateArrow2 && "rotateArrow"}`} width="18px" alt="" />
                                    <select
                                        className="form-control"
                                        style={{ minWidth: '475px', maxWidth: "475px", paddingRight: 40 }}
                                        value={labourbill_customer}
                                        onBlur={() => { setrotateArrow2(false) }}
                                        onClick={() => { setrotateArrow2((pre) => !pre) }}
                                        onChange={(e) => { setLabourBill_Customer(e.target.value) }}
                                    >
                                        <option value="" disabled>Select</option>
                                        {customerOption?.length && customerOption?.map((ele) => (<option value={ele.value}>{ele.label}</option>))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="users-form-info date-picker enquery-datepicker mt-2 me-0">
                            <div className="users-label position-relative">
                                <label>LabourBill Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    style={{ minWidth: '475px' }}
                                    value={labourbill_date?.slice(0, 10)}
                                    onChange={(e) => setLabourBill_Date(new Date(e.target.value).toISOString())}
                                />
                                <img src={Datepickericon} alt="" style={{ top: 38, right: 10, opacity: 0.5 }} />
                            </div>
                        </div>
                    </div>
                </SweetAlert>}
            </div>
        </>
    )
}

export default ProjectMaterial