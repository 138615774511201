export const toastTimer = 5000

export const UserRoles = {
    Admin: "Admin",
    Sales: "Sales",
    Project: "Project",
    Store: "Store",
    Account: "Account",
    Liasoning: "Liasoning",
    Viewer: "Viewer",
    Maintance: "Maintance"
}

export const AllStatus = {
    approved: "Approved",
    active: "Active",
    Pending: "Pending",
    InProgress: "In Progress",
    OnHold: "On Hold",
    Done: "Done",
}

export const Status = {
    ToDo: "To Do",
    InProgress: "In Progress",
    Review: "Review",
    Hold: "Hold",
    Done: "Done",
    Approved: "Approved"
}

export const Unit = {
    KG: "KG",
    LTR: "LTR",
    NOS: "NOS",
    MTR: "MTR",
    FEET: "FEET",
}

export const Priority = {
    Low: "Low",
    Medium: "Medium",
    High: "High",
    Urgent: "Urgent"
}

export const SalesType = {
    NewProject: "New Project",
    Maintenance: "Maintenance",
    MaterialTrading: "Material Trading",
    Consulting: "Consulting",
}
export const Paginationlimit = 100

export const forLayout = ["/", "/project-report"]
export const forAuthLayout = ["/dashboard", "/customer", "/contractor", "/purchaserequest", "/termandcondition", "/purchaserequests", "/returnmaterial", "/asset", "/account", "/account/list", "/bank", "/payble-receivable", "/product", "/purchase", "/purchase/add", "/purchase/edit", "/sale", "/sale/add", "/sale/edit", "/task", "/enquiry", "/enquiry/add", "/enquiry/edit", "/enquiry/approve", "/enquiry/maintenance", "/project", "/project/edit", "/project/edit/ProjectMaterial", "/daily-report", "/daily-report/add", "/daily-report/edit", "/noc", "/noc/add", "/noc/edit", "/noc/counsulting", "/users", "/users/add", "/users/edit", "/roles", "/setting", '/stock-summary', "/staff-daily-report", "/staff-daily-report/add", "/staff-daily-report/edit", "/error"]