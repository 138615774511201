import { ADD_CONTRACTOR, ADD_CONTRACTOR_ERROR, ADD_CONTRACTOR_LOADING, DELETE_CONTRACTOR, DELETE_CONTRACTOR_ERROR, DELETE_CONTRACTOR_LOADING, EDIT_CONTRACTOR, EDIT_CONTRACTOR_ERROR, EDIT_CONTRACTOR_LOADING, GET_ALL_CONTRACTOR, GET_ALL_CONTRACTOR_ERROR, GET_ALL_CONTRACTOR_LOADING, GET_CONTRACTOR_BY_ID, GET_CONTRACTOR_BY_ID_ERROR, GET_CONTRACTOR_BY_ID_LOADING } from "../type"

const addContractorData = {
    loading:false,
    addContractor : [],
    error:'',
};

const getAllContractorData = {
    loading:false,
    getAllContractor : [],
    error:'',
};

const getContractorByIdData = {
    loading:false,
    getContractorById : [],
    error:'',
};

const editContractorData = {
    loading:false,
    editContractor : [],
    error:'',
};

const deleteContractorData = {
    loading:false,
    deleteContractor : [],
    error:'',
};

export const addContractorReducer = (state = addContractorData, action) => {
    switch (action.type) {
        case ADD_CONTRACTOR_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_CONTRACTOR:
            return {
                ...state,
                addContractor: action.payload,
            };
        case ADD_CONTRACTOR_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllContractorReducer = (state = getAllContractorData, action) => {
    switch (action.type) {
        case GET_ALL_CONTRACTOR_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_CONTRACTOR:
            return {
                ...state,
                getAllContractor: action.payload,
            };
        case GET_ALL_CONTRACTOR_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getContractorByIdReducer = (state = getContractorByIdData, action) => {
    switch (action.type) {
        case GET_CONTRACTOR_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_CONTRACTOR_BY_ID:
            return {
                ...state,
                getContractorById: action.payload,
            };
        case GET_CONTRACTOR_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editContractorReducer = (state = editContractorData, action) => {
    switch (action.type) {
        case EDIT_CONTRACTOR_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_CONTRACTOR:
            return {
                ...state,
                editContractor: action.payload,
            };
        case EDIT_CONTRACTOR_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteContractorReducer = (state = deleteContractorData, action) => {
    switch (action.type) {
        case DELETE_CONTRACTOR_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_CONTRACTOR:
            return {
                ...state,
                deleteContractor: action.payload,
            };
        case DELETE_CONTRACTOR_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}