import React from 'react'
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';


const ViewTableDesk = (props) => {
    const {data, title, link} = props;
    const navigate = useNavigate()
    // get table column
    const column = Object.keys(data[0]);

    // get table heading data
    const ThData = () => {
    
     return column.map((dat, index) => {
         return <th style={{fontSize: '16px'}} key={index}>{dat}</th>
     })
 }

    // get table row data
    const tdData = () => {
        return data.map((dat, index) => {
            return(
                <tr key={index} >
                    {column.map((v, ind) => Array.isArray(dat[v]) ? <td className='task-avatar'  key={ind}> {dat[v].map((ele, i) => <Avatar  name={ele} round={true} size="30px" key={i}/>)} </td> : <td style={{fontSize:"16px"}} key={ind}> {dat[v]} </td>)}
                </tr>
            )
        })
    }

  return (
    <div className = 'table-div'>
        <div className="d-flex align-items-center justify-content-between">
            <span className='chart-title' style = {{fontSize : '20px'}}>{`${title ?? 'Table'}`}</span>
            <div className='table-link' onClick = {() => navigate(link) }>View more</div>
        </div>    
        <table id='dashboard-table'>
            <thead>
                <tr>{ThData()}</tr>
            </thead>
            <tbody>
                {tdData()}
            </tbody>
        </table>
    </div>
  )
}

export default ViewTableDesk