import moment from 'moment';
import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';

const ApproveLabourBill = ({ approve_labour_bill_state, setApprove_Labour_Bill_State, OnApprove }) => {
    return (
        <Modal
            show={approve_labour_bill_state.isOpen}
            onHide={() => setApprove_Labour_Bill_State({ isOpen: false, approved_data_details: null })}
            centered
            // size="xl"
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Approved Labour Bill</Modal.Title>
            </Modal.Header>
            <Modal.Body className='over-flow-scroll'>
                <Row>
                    <Col xxl={2} lg={4} sm={6} xs={12}>
                        <div className="my-2 small-input me-2 me-xl-0">
                            <div className="users-form-info ms-1">
                                <div className="users-label">
                                    <label>Invoice No.</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ height: '47px' }}
                                        value={approve_labour_bill_state?.approved_data_details?.labour_bill_invoice_no}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={2} lg={4} sm={6} xs={12}>
                        <div className="my-2 small-input me-2 me-xl-0">
                            <div className="users-form-info ms-1">
                                <div className="users-label">
                                    <label>Invoice Date</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ height: '47px' }}
                                        value={moment(approve_labour_bill_state?.approved_data_details?.labour_bill_invoice_date).format("YYYY-MM-DD")}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={2} lg={4} sm={6} xs={12}>
                        <div className="my-2 small-input me-2 me-xl-0">
                            <div className="users-form-info ms-1">
                                <div className="users-label">
                                    <label>Contractor</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ height: '47px' }}
                                        value={approve_labour_bill_state?.approved_data_details?.contractor}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={2} lg={4} sm={6} xs={12}>
                        <div className="my-2 small-input me-2 me-xl-0">
                            <div className="users-form-info ms-1">
                                <div className="users-label">
                                    <label>Labour&nbsp;Bill&nbsp;Total</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ height: '47px' }}
                                        value={approve_labour_bill_state?.approved_data_details?.total_labour_bill_amount}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={2} lg={4} sm={6} xs={12}>
                        <div className="my-2 small-input me-2 me-xl-0">
                            <div className="users-form-info ms-1">
                                <div className="users-label">
                                    <label>Actual Payable</label>
                                    <input
                                        type="text"
                                        placeholder='Enter Actual Payable'
                                        className="form-control"
                                        style={{ height: '47px' }}
                                        value={approve_labour_bill_state?.approved_data_details?.final_total_of_labour_bill}
                                        onChange={(e) => {
                                            let numberRex = /^(?:\d*\.\d{0,2}|\d+)$/;
                                            if (!e.target.value || e.target.value === "" || numberRex.test(e.target.value)) {
                                                setApprove_Labour_Bill_State((pre) => ({ ...pre, approved_data_details: { ...pre.approved_data_details, final_total_of_labour_bill: e.target.value } }));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={2} lg={4} sm={6} xs={12}>
                        <div className="my-2 small-input me-2 me-xl-0">
                            <div className="users-form-info ms-1">
                                <div className="users-label">
                                    <label>Discount</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ height: '47px' }}
                                        value={((approve_labour_bill_state?.approved_data_details?.total_labour_bill_amount) - (Number(approve_labour_bill_state?.approved_data_details?.final_total_of_labour_bill) ?? 0)) < 0 ? 0 : ((approve_labour_bill_state?.approved_data_details?.total_labour_bill_amount) - (Number(approve_labour_bill_state?.approved_data_details?.final_total_of_labour_bill) ?? 0))}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='cancel-button' onClick={() => setApprove_Labour_Bill_State({ isOpen: false, approved_data_details: null })}>Cancel</button>
                <Button className='adduser-btn' onClick={OnApprove}> Approved </Button>
            </Modal.Footer>

        </Modal>
    );
}

export default ApproveLabourBill