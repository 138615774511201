import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { addSiteReportsReducer, deleteSiteReportsReducer, editSiteReportsReducer, getAllSiteReportsNoAuthReducer, getAllSiteReportsReducer, getSiteReportsByIdReducer, getSiteReportsByProjectIdReducer, getSiteReportsNoAuthByIdReducer } from './reducers/dailysiteReportReducers';
import { addEnquiryReducer, approvalForAdminEnquiryReducer, deleteEnquiryReducer, editEnquiryReducer, getAllEnquiryReducer, getEnquiryByIdReducer, rejectEnquiryReducer } from './reducers/enquiryReducer';
import { fileRemoveReducer, fileUploadReducer } from './reducers/general';
import { isLoadingReducer } from './reducers/loadingReducer';
import { isLoginReducer } from './reducers/loginReducer';
import { addNocReducer, deleteNocReducer, editNocReducer, getAllNocsReducer, getNocByIdReducer } from './reducers/nocReducer';
import { getAllProjectDetailsReducer, getProjectDetailsByIdReducer, getProjectDetailsByProjectIdReducer } from './reducers/projectDetailsReducer';
import { addProjectReducer, deleteProjectReducer, editProjectReducer, getAllProjectReducer, getProjectByCustomerReducer, getProjectByIdReducer } from './reducers/projectReducer';
import { addUserReducer, deleteUserReducer, editUserReducer, getAllUserReducer, getProjectManagerReducer, getUserByIdReducer, loginReducer } from './reducers/userReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { addCustomerReducer, deleteCustomerReducer, editCustomerReducer, getAllCustomerReducer, getCustomerByIdReducer, getCustomerDropDownReducer, getSupplierDropDownReducer } from './reducers/customerReducer';
import { addProductReducer, deleteProductReducer, editProductReducer, getAllProductReducer, getProductByFinalMaterialReducer, getProductByIdReducer, getProductDropDownReducer, getProductLedgerReducer, importFileReducer, getAllProductValueReducer } from './reducers/productReducer';
import { deletePurchaseReducer, editPurchaseReducer, getAllPurchaseReducer, getPurchaseByIdReducer } from './reducers/purchaseReducer';
import { addSaleReducer, deleteSaleReducer, editSaleReducer, getAllSaleReducer, getSaleByIdReducer } from './reducers/saleReducer';
import { addTaskReducer, deleteTaskReducer, editTaskForKanbanReducer, editTaskReducer, getAllTaskReducer, getTaskByIdReducer, taskDashboardReducer } from './reducers/taskReducer';
import { getBillEntryByIdReducer, getAllBillEntryReducer, addBillEntryReducer, editBillEntryReducer, deleteBillEntryReducer } from './reducers/addBillReducer';
import { getAccountReducer, getAllAccountCountReducer, getAllBillByCustomerReducer, getAllPendingBillByCoustmerReducer } from './reducers/accountReducer';
import { addBankReducer, deleteBankReducer, editBankReducer, getAllBankReducer, getBankByIdReducer, getBankDropDownReducer } from './reducers/bankReducer';
import { addTaskCommentReducer, deleteTaskCommentReducer, editTaskCommentReducer, getAllTaskCommentReducer, getCommentByTaskIdReducer, getTaskCommentByIdReducer } from './reducers/taskCommentReducer';
import { addPurchaseRequestReducer, deletePurchaseRequestReducer, editPurchaseRequestReducer, getAllPurchaseRequestReducer, getPurchaseRequestByIdReducer, getPurchaseRequestByProjectReducer } from './reducers/purchaseRequestReducer';
import { addContractorReducer, deleteContractorReducer, editContractorReducer, getAllContractorReducer, getContractorByIdReducer } from './reducers/contractorReducer';
import { addAssetsReducer, deleteAssetsReducer, editAssetsReducer, getAllAssetsNonProjectReducer, getAllAssetsReducer, getAssetsByIdReducer, getAssetsByProjectReducer } from './reducers/assetsReducer';
import { getNotificationByUserIdReducer, getNotificationCountReducer, readNotificationReducer } from './reducers/notificationReducer';
import { getEnquiryByMonthYearWiseReducer, getEnquiryCountByStatusReducer, getRecentEnquiryReducer } from './reducers/dashboardReducer';
import { addReturnMaterialReducer, deleteReturnMaterialReducer, editReturnMaterialReducer, getAllReturnMaterialReducer, getReturnMaterialByIdReducer, getReturnMaterialByProjectReducer } from './reducers/returnMaterial';
import { addFinalMaterialReducer, addFinalMaterialSubProductReducer, deleteFinalMaterialReducer, editFinalMaterialReducer, getAllFinalMaterialReducer, getFinalMaterialByIdReducer, getFinalMaterialByParantIdReducer, getFinalMaterialByProjectReducer } from './reducers/finalMaterialReducer';
import { addEmployeeReportReducer, deleteEmployeeReportReducer, editEmployeeReportReducer, getAllEmployeeReportReducer, getEmployeeReportByIdReducer } from './reducers/employeeReport';
import { addQuotationReducer, deleteQuotationReducer, editQuotationReducer, getQuotationByEnquiryIdReducer, getQuotationByIdReducer, getQuotationByProjectIdReducer } from './reducers/quotationReducer';
import { addTermAndConditionReducer, deleteTermAndConditionReducer, editTermAndConditionReducer, getAllTermAndConditionReducer } from './reducers/termAndConditionReucer';
import { addProjectMaterialReducer, deleteProjectMaterialReducer, editProjectMaterialReducer } from './reducers/projectMaterialReducer';


const middleware = [thunk];

const rootReducer = combineReducers({
    loading: isLoadingReducer,
    isLogin: isLoginReducer,

    login: loginReducer,
    get_User_By_Id: getUserByIdReducer,
    get_All_User: getAllUserReducer,
    edit_User: editUserReducer,
    add_User: addUserReducer,
    delete_User: deleteUserReducer,
    add_Enquiry: addEnquiryReducer,
    get_All_Enquiry: getAllEnquiryReducer,
    get_Enquiry_By_Id: getEnquiryByIdReducer,
    reject_Enquiry: rejectEnquiryReducer,
    edit_Enquiry: editEnquiryReducer,
    approval_Enquiry: approvalForAdminEnquiryReducer,
    delete_Enquiry: deleteEnquiryReducer,
    add_Project: addProjectReducer,
    get_All_Project: getAllProjectReducer,
    get_Project_By_Id: getProjectByIdReducer,
    edit_Project: editProjectReducer,
    delete_Project: deleteProjectReducer,
    get_All_Sites_Report: getAllSiteReportsReducer,
    get_Site_Report_By_Id: getSiteReportsByIdReducer,
    add_Site_Reports: addSiteReportsReducer,
    get_Site_Reports_By_Project_Id: getSiteReportsByProjectIdReducer,
    edit_Site_Reports: editSiteReportsReducer,
    delete_Site_Reports: deleteSiteReportsReducer,
    file_Upload: fileUploadReducer,
    file_Remove: fileRemoveReducer,
    add_Noc: addNocReducer,
    get_All_Nocs: getAllNocsReducer,
    get_Noc_By_Id: getNocByIdReducer,
    edit_Noc: editNocReducer,
    delete_Noc: deleteNocReducer,
    get_All_Project_Details: getAllProjectDetailsReducer,
    get_Project_Details_By_Id: getProjectDetailsByIdReducer,
    get_Project_Details_By_Project_Id: getProjectDetailsByProjectIdReducer,
    add_Customer: addCustomerReducer,
    get_All_Customer: getAllCustomerReducer,
    get_Customer_By_Id: getCustomerByIdReducer,
    edit_Customer: editCustomerReducer,
    delete_Customer: deleteCustomerReducer,
    get_Customer_Drop_Down: getCustomerDropDownReducer,
    get_Supplier_Drop_Down: getSupplierDropDownReducer,
    add_Product: addProductReducer,
    get_All_Product: getAllProductReducer,
    get_All_Product_Value: getAllProductValueReducer,
    delete_Product: deleteProductReducer,
    edit_Product: editProductReducer,
    get_Product_By_Id: getProductByIdReducer,
    import_File: importFileReducer,
    get_Project_By_Customer: getProjectByCustomerReducer,
    get_Project_Manager: getProjectManagerReducer,
    get_Purchase_By_Id: getPurchaseByIdReducer,
    get_All_Purchase: getAllPurchaseReducer,
    get_Product_Drop_Down: getProductDropDownReducer,
    get_Product_By_Final_Material: getProductByFinalMaterialReducer,
    get_Product_Ledger: getProductLedgerReducer,
    edit_Purchase: editPurchaseReducer,
    delete_Purchase: deletePurchaseReducer,
    add_Sale: addSaleReducer,
    get_Sale_By_Id: getSaleByIdReducer,
    get_All_Sale: getAllSaleReducer,
    edit_Sale: editSaleReducer,
    delete_Sale: deleteSaleReducer,
    add_Task: addTaskReducer,
    get_Task_By_Id: getTaskByIdReducer,
    get_All_Task: getAllTaskReducer,
    edit_Task: editTaskReducer,
    delete_Task: deleteTaskReducer,
    task_Dashboard: taskDashboardReducer,
    get_Bill_Entry_By_Id: getBillEntryByIdReducer,
    get_All_Bill_Entry: getAllBillEntryReducer,
    add_Bill_Entry: addBillEntryReducer,
    edit_Bill_Entry: editBillEntryReducer,
    delete_Bill_Entry: deleteBillEntryReducer,
    get_Account: getAccountReducer,
    get_All_Account_Count: getAllAccountCountReducer,
    get_ALL_Pending_Bill: getAllPendingBillByCoustmerReducer,
    get_All_Bill_By_Customer: getAllBillByCustomerReducer,
    add_Bank: addBankReducer,
    get_Bank_By_Id: getBankByIdReducer,
    get_All_Bank: getAllBankReducer,
    edit_Bank: editBankReducer,
    delete_Bank: deleteBankReducer,
    get_Bank_Drop_Down: getBankDropDownReducer,
    edit_Task_For_Kanban: editTaskForKanbanReducer,
    add_Task_Comment: addTaskCommentReducer,
    get_All_Task_Comment: getAllTaskCommentReducer,
    get_Task_Comment_By_Id: getTaskCommentByIdReducer,
    get_Comment_By_Task_Id: getCommentByTaskIdReducer,
    edit_Task_Comment: editTaskCommentReducer,
    delete_Task_Comment: deleteTaskCommentReducer,
    add_Purchase_Request: addPurchaseRequestReducer,
    get_Purchase_Request_By_Id: getPurchaseRequestByIdReducer,
    get_Purchase_Request_By_Project: getPurchaseRequestByProjectReducer,
    get_All_Purchase_Request: getAllPurchaseRequestReducer,
    edit_Purchase_Request: editPurchaseRequestReducer,
    delete_Purchase_Request: deletePurchaseRequestReducer,
    add_Contractor: addContractorReducer,
    get_All_Contractor: getAllContractorReducer,
    get_Contractor_By_Id: getContractorByIdReducer,
    edit_Contractor: editContractorReducer,
    delete_Contractor: deleteContractorReducer,
    add_Assets: addAssetsReducer,
    get_Assets_By_Id: getAssetsByIdReducer,
    get_All_Assets: getAllAssetsReducer,
    get_All_Assets_Non_Project: getAllAssetsNonProjectReducer,
    get_Assets_By_Project: getAssetsByProjectReducer,
    edit_Assets: editAssetsReducer,
    delete_Assets: deleteAssetsReducer,
    get_Notification_Count: getNotificationCountReducer,
    read_Notification: readNotificationReducer,
    get_Notification_By_User_Id: getNotificationByUserIdReducer,
    get_Recent_Enquiry: getRecentEnquiryReducer,
    get_Enquiry_By_Month_Year_Wise: getEnquiryByMonthYearWiseReducer,
    get_Enquiry_Count_By_Status: getEnquiryCountByStatusReducer,
    add_Return_Material: addReturnMaterialReducer,
    edit_Return_Material: editReturnMaterialReducer,
    get_All_Return_Material: getAllReturnMaterialReducer,
    get_Return_Material_By_Id: getReturnMaterialByIdReducer,
    get_Return_Material_By_Project: getReturnMaterialByProjectReducer,
    delete_Return_Material: deleteReturnMaterialReducer,
    add_Final_Material: addFinalMaterialReducer,
    add_Final_Material_Sub_product: addFinalMaterialSubProductReducer,
    get_Final_Material_By_Id: getFinalMaterialByIdReducer,
    get_Final_Material_By_Parant_Id: getFinalMaterialByParantIdReducer,
    get_All_Final_Material: getAllFinalMaterialReducer,
    edit_Final_Material: editFinalMaterialReducer,
    delete_Final_Material: deleteFinalMaterialReducer,
    get_Final_Material_By_Project: getFinalMaterialByProjectReducer,
    add_Employee_Report: addEmployeeReportReducer,
    get_Employee_Report_By_Id: getEmployeeReportByIdReducer,
    get_All_Employee_Report: getAllEmployeeReportReducer,
    edit_Employee_Report: editEmployeeReportReducer,
    delete_Employee_Report: deleteEmployeeReportReducer,
    get_All_Sites_Report_No_Auth: getAllSiteReportsNoAuthReducer,
    get_Site_Report_No_Auth_By_Id: getSiteReportsNoAuthByIdReducer,
    get_Quotation_By_ProjectId: getQuotationByEnquiryIdReducer,
    get_Quotation_By_Id: getQuotationByIdReducer,
    add_Quotation: addQuotationReducer,
    edit_Quotation: editQuotationReducer,
    delete_Quotation: deleteQuotationReducer,
    add_TermAndCondition: addTermAndConditionReducer,
    get_All_TermAndCondition: getAllTermAndConditionReducer,
    delete_TermAndCondition: deleteTermAndConditionReducer,
    edit_TermAndCondition: editTermAndConditionReducer,
    add_Project_material: addProjectMaterialReducer,
    edit_Project_material: editProjectMaterialReducer,
    delete_Project_material: deleteProjectMaterialReducer,
})

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;