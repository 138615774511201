import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { dateFormate } from '../../helper/utils'
import { getEmployeeReportById } from '../../redux/actions/employeeReportAction'
import { getAllUserAction } from '../../redux/actions/userAction'

const ViewEmployeeReport = ({ show, onHide, dailyReportId }) => {

    const dispatch = useDispatch()

    const [dailyReport, setDailyReport] = useState({
        employeeId: '',
        date: '',
        km: '',
        totalDuration: '',
    })
    const [extraDetails, setExtraDetails] = useState([])

    const employeeReportByIdData = useSelector((state) => state.get_Employee_Report_By_Id.getEmployeeReportById)
    const allUserData = useSelector((state) => state.get_All_User.getAllUser.data)

    useEffect(() => {
        dispatch(getAllUserAction())
    }, [])

    useEffect(() => {
        if (dailyReportId) {
            dispatch(getEmployeeReportById(dailyReportId))
        }
    }, [dailyReportId])

    useEffect(() => {
        if (employeeReportByIdData && employeeReportByIdData.data && dailyReportId) {
            setDailyReport({
                employeeId: selectValue(employeeReportByIdData.data.employeeId, "employeeId"),
                date: employeeReportByIdData.data.date && new Date(employeeReportByIdData.data.date),
                totalDuration: employeeReportByIdData.data.totalDuration,
                km: employeeReportByIdData.data.km,
            })
            setExtraDetails(employeeReportByIdData.data.items.length > 0 ?
                employeeReportByIdData.data.items.map((e) => {
                    return {
                        startTime: e.startTime,
                        endTime: e.endTime,
                        description: e.description,
                    }
                })
                : [])
        }
    }, [employeeReportByIdData])

    const selectValue = (value, name) => {
        if (name === "employeeId") {
            return allUserData?.find((ele) => ele?._id === value)?.name
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Staff Daily Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                    <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                    <hr></hr>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-site-report-modal'>
                                <tr>
                                    <td>Employee</td>
                                    <td>{dailyReport?.employeeId}</td>
                                </tr>
                                <tr>
                                    <td>Total Duration</td>
                                    <td>{dailyReport?.totalDuration}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-site-report-modal'>
                                <tr>
                                    <td>Date</td>
                                    <td>{dateFormate(dailyReport.date, "Date")}</td>
                                </tr>
                                <tr>
                                    <td>KM</td>
                                    <td>{dailyReport.km}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <table className='table viewer-employee-table'>
                            <tr>
                                <th>Sr</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Description</th>
                            </tr>
                            {extraDetails.map((item, i) =>
                                <tr key={i}>
                                    <td className='vertical-top'>{i + 1}</td>
                                    <td className='vertical-top'>{item.startTime}</td>
                                    <td className='vertical-top'>{item.endTime}</td>
                                    <td className='vertical-top'>{item.description}</td>
                                </tr>
                            )}
                        </table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ViewEmployeeReport