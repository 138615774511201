import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getEnquiryByIdAction } from '../../redux/actions/enquiryAction'
import { addProjectAction } from '../../redux/actions/projectAction'
import { toast } from 'react-toastify'
import { getAllCustomerAction } from '../../redux/actions/customerAction'
import AddCustomer from '../../components/modals/AddCustomer'
import { getAllUserAction, getProjectManagerAction } from '../../redux/actions/userAction'
import { API } from '../../config/API/api.config'
import { Col, Row } from 'react-bootstrap'
import { ADD_PROJECT } from '../../redux/type'
import { fileRemoveAction, fileUploadAction } from '../../redux/actions/generalAction'
import removeImg from '../../assets/image/delete-red.png'
import Viewicon from '../../assets/image/view.png'
import Select from 'react-select'
import { Unit } from '../../helper/Constant'
import { getAllProductAction, getProductDropDownAction } from '../../redux/actions/productAction'
import ViewFinalQuotation from '../../components/modals/ViewFinalQuotation'
import downloadWhite from "../../assets/image/download-white.png";
import { getQuotationByEnquiryIdAction } from '../../redux/actions/quotationAction'
import { downloadQuoatationFile } from '../../helper/utils'

const MaintenanceApprove = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search)
    const enquiryId = queryParams.get("enquiryId")
    const toastSuccess = () => toast.success('Enquiry approved successfully');
    const [preSelectedOption, setPreSelectedOption] = useState([])
    const [user, setUser] = useState({
        enq_id: '',
        projectType: '',
        partyName: '',
        location: '',
        contactPersonName: '',
        contactPersonNumber: '',
        finalQuatation: [],
        workOrder: [],
        customer: '',
        projectManager: '',
    })
    const [viewFinalQuotation, setViewFinalQuotation] = useState(false)
    const [productOption, setProductOption] = useState([])
    let numberRegex = /^[0-9\b]+$/;
    const unitOption = [
        { value: Unit.KG, label: Unit.KG },
        { value: Unit.LTR, label: Unit.LTR },
        { value: Unit.NOS, label: Unit.NOS },
        { value: Unit.MTR, label: Unit.MTR },
        { value: Unit.FEET, label: Unit.FEET },
    ]

    const [quotation, setQuotation] = useState([])
    const [customerOption, setCustomerOption] = useState([])
    const [formErrors, setFormErrors] = useState()
    const [customerTog, setCustomerTog] = useState(false)
    const [workOrderFile, setWorkOrderFile] = useState(undefined)
    const [userOption, setUserOption] = useState([])
    const userData = useSelector((state) => state.get_Enquiry_By_Id.getEnquiryById.data)
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer.data)
    const projectManagerData = useSelector((state) => state.get_Project_Manager.getProjectManager.data)
    const addProjectData = useSelector((state) => state.add_Project.addProject)
    const fileData = useSelector((state) => state.file_Upload.fileUpload)
    const getAllUserData = useSelector((state) => state.get_All_User.getAllUser)
    const getAllProductData = useSelector((state) => state.get_All_Product.getAllProduct)
    const productData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    const getQuotationByEnquiryIdData = useSelector((state) => state.get_Quotation_By_ProjectId.getQuotationByEnquiryId)

    useEffect(() => {
        dispatch(getAllCustomerAction())
        dispatch(getProjectManagerAction())
        dispatch(getAllUserAction())
        dispatch(getProductDropDownAction())
        dispatch(getAllProductAction())
        dispatch(getProductDropDownAction())
        if (enquiryId) {
            dispatch(getEnquiryByIdAction(enquiryId))
            dispatch(getQuotationByEnquiryIdAction(enquiryId))
        }
    }, [enquiryId])

    useEffect(() => {
        if (userData) {
            setUser({ ...user, enq_id: userData._id, partyName: userData.partyName, location: userData.location, contactPersonName: userData.contactPersonName, contactPersonNumber: userData.contactPersonNumber, workOrder: userData.workOrder ? [userData.workOrder] : [], projectType: userData.projectType })
            // setQuotation(userData.nextFollowups.map((ele) => {
            //     return {
            //         value: ele.quoationUpdate && ele.quoationUpdate,
            //         label: ele.quoationUpdate && ele.quoationUpdate.split('/')[1]
            //     }
            // }))
        }
    }, [userData])

    useEffect(()=>{
        if(preSelectedOption){
            setUser({ ...user, finalQuatation: preSelectedOption?.value});
        }
    },[preSelectedOption])

    useEffect(() => {
        if (getQuotationByEnquiryIdData.data)
            setQuotation(getQuotationByEnquiryIdData?.data?.map((ele, i) => {
                return {
                    label: ele.quotationName,
                    value: ele._id
                }
            }))
    }, [getQuotationByEnquiryIdData])


    useEffect(() => {
        if (getAllProductData && getAllProductData.data && getAllProductData.data.length > 0) {
            setProductOption(getAllProductData.data.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.productName
                }
            }))
        }
    }, [getAllProductData])
    useEffect(() => {
        if (customerData) {
            let temp = customerData.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setCustomerOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.accountName
                }
            }))
        }
    }, [customerData])

    useEffect(() => {
        if (getAllUserData && getAllUserData.data) {
            setUserOption(getAllUserData.data.map((ele) => {
                return {
                    value: ele._id,
                    label: `${ele.name} - ${ele.role}`
                }
            }))
        }
    }, [getAllUserData])

    const handleChange = (e, name) => {
        let re = /^[0-9\b]+$/;
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === 'finalQuatation') {
            setPreSelectedOption({ label: e.label, value: e.value })
        } else if (name === "projectManager") {
            setUser({ ...user, [name]: e.value })
        } else if (name === "contactPersonNumber") {
            if (e.target.value === '' || re.test(e.target.value)) {
                setUser({ ...user, [name]: e.target.value })
            }
        }
    }

    const Submit = () => {
        if (validation()) {
            return
        }
        dispatch(addProjectAction(user))
    }

    useEffect(() => {
        if (addProjectData && addProjectData.data) {
            if (addProjectData.message === "Equiry approved successfully.") {
                dispatch({
                    type: ADD_PROJECT,
                    payload: []
                })
                toastSuccess()
                navigate('/project')
            } else {
                toast.success(addProjectData.message)
            }
        }
    }, [addProjectData])


    const validation = () => {
        let flag = false
        const error = {};
        if (!user.enq_id) {
            error.enq_id = "Please enter enquiryid"
            flag = true
        }
        if (!user.partyName) {
            error.partyName = "Please enter partyname"
            flag = true
        }
        if (!user.location) {
            error.location = "Please enter location"
            flag = true
        }
        if (!user.contactPersonName) {
            error.contactPersonName = "Please enter contact person name"
            flag = true
        }
        if (!user.contactPersonNumber) {
            error.contactPersonNumber = "Please enter contact person number"
            flag = true
        } else if (user.contactPersonNumber.length < 10) {
            error.contactPersonNumber = "Please enter valid person number"
            flag = true
        }
        if (!user.projectManager) {
            error.projectManager = "Please select project manager"
            flag = true
        }
        if (!user.finalQuatation.length) {
            error.finalQuatation = "Please select final quotation"
            flag = true
        }
        if (!user.customer) {
            error.customer = "Please select customer";
            flag = true;
        }
        if (!user.workOrder.length) {
            error.workOrder = "Please select Work Order File";
            flag = true;
        }
        setFormErrors(error);
        return flag
    }

    const Hide = () => {
        setCustomerTog(false)
    }

    useEffect(() => {
        if (workOrderFile) {
            let formData = new FormData()
            formData.append("files", workOrderFile)
            dispatch(fileUploadAction(formData))
        }
    }, [workOrderFile])

    useEffect(() => {
        if (fileData) {
            let temp = [...user.workOrder];
            temp.push(fileData.url)
            setUser({ ...user, workOrder: temp })
        }
    }, [fileData])


    const fileRemove = (fileName, i, name) => {
        let body = {
            file: fileName.split('/')[1] ? fileName.split('/')[1] : fileName
        }
        dispatch(fileRemoveAction(body))
        if (name === 'removeWorkOrder') {
            let val = [...user.workOrder]
            val.splice(i, 1)
            setUser({ ...user, workOrder: val })
        }
    }

    const UploadClick = () => {
        document.getElementById('workOrder').click()
    }

    const fileOpen = (fileName) => {
        window.open(`${API.endpoint}/${fileName}`, "_blank")
    }

    const selectValue = (value, name) => {
        if (name === "customer") {
            return customerOption.find((item) => item.value === value)
        } else if (name === "finalQuatation") {
            return quotation?.filter((item) => value.includes(item.value))
        } else if (name === "projectManager") {
            return userOption.find((item) => item.value === value)
        } else if (name === "product") {
            return productOption.find((ele) => ele.value === value)
        } else if (name === "unit") {
            return unitOption.find((ele) => ele.value === value)
        }
    }

    const onDownload = async (id) => {
        const GST = customerData.find((ele) => ele._id === user.customer).gstin
        let data = getQuotationByEnquiryIdData.data.find((ele) => ele._id === id)
        const customerdata = customerData.find((ele) => ele._id === user.customer)
        try {
            let productdata = []
            data.items.map((element) => {
                const ele = getAllProductData.data.find((ele) => ele?.productCode === element?.productCode)
                productdata.push({ ...ele, quantity: element?.reqQuantity, discount: element?.discount ?? 0, retailSalePrice: element?.retailSalePrice ?? 0 })
            })

            // productdata.sort((a, b) => {
            //     if (a.productName < b.productName) return -1;
            //     if (a.productName > b.productName) return 1;
            //     return 0;
            // });
            
            data = {
                ...data,
                customerData: customerdata,
                GST: GST,
            }
            downloadQuoatationFile(productdata, 'Quotation', data)
        } catch (err) {
            console.log(err)
        }
    }
    const findOption = getQuotationByEnquiryIdData?.data?.find((item) => item.isFinal === true); // find finalize option

    useEffect(() => {
        if(findOption){    
        setPreSelectedOption(quotation?.find((ele) => ele.value === findOption._id));
        }
    }, [quotation])

    return (
        <>
            <div className='parent-dashboard approve-enquiry-section'>
                <div className='mb-4 set-page-heade'>
                    <div>
                        <h2 className='mb-0'>Approve Enquiry</h2>
                    </div>
                    <div>
                        <button className="adduser-btn ms-3" onClick={() => Submit()}>Approve</button>
                        <button className="cancel-button ms-3" onClick={() => navigate("/enquiry")}>Cancel</button>
                    </div>
                </div>
                <div className="users-form addEnquiry">

                    <Row>
                        <Col xl={4} md={6}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Project Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter party name"
                                        name="name"
                                        value={user.partyName}
                                        onChange={(e) => { setUser({ ...user, partyName: e.target.value }); setFormErrors({ ...formErrors, partyName: '' }) }}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.partyName && <label style={{ color: "red" }}>{formErrors.partyName}</label>}
                            </div>
                        </Col>
                        <Col xl={4} md={6}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Location</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter location"
                                        value={user.location}
                                        onChange={(e) => { setUser({ ...user, location: e.target.value }); setFormErrors({ ...formErrors, location: '' }) }}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.location && <label style={{ color: "red" }}>{formErrors.location}</label>}
                            </div>
                        </Col>
                        <Col xl={4} md={6}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Contact Person Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter contact person name"
                                        value={user.contactPersonName}
                                        onChange={(e) => { setUser({ ...user, contactPersonName: e.target.value }); setFormErrors({ ...formErrors, contactPersonName: '' }) }}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.contactPersonName && <label style={{ color: "red" }}>{formErrors.contactPersonName}</label>}
                            </div>
                        </Col>
                        <Col xl={4} md={6}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Contact Person No.</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter person number"
                                        value={user.contactPersonNumber}
                                        onChange={(e) => handleChange(e, 'contactPersonNumber')}
                                        autoComplete="off"
                                        maxLength={10}
                                    />
                                </div>
                                {formErrors?.contactPersonNumber && <label style={{ color: "red" }}>{formErrors.contactPersonNumber}</label>}
                            </div>
                        </Col>
                        <Col xl={4} md={6}>
                            <div className="users-form-info add-remark-modal mx-0 mt-3">
                                <div className="multi-select customer-width">
                                    <label>Final Quotation</label>
                                    <div className='d-flex' style={{ width: "100%" }}>
                                        <Select
                                            placeholder="Select Final Quotation"
                                            value={preSelectedOption ? preSelectedOption : ""}
                                            onChange={(e) => handleChange(e, "finalQuatation")}
                                            options={quotation}
                                        // isDisabled={!user.customer}
                                        // isMulti={true}
                                        />
                                        <div className=''>
                                            {/* <button className="btn btn-outline-secondary contractor-btn mt-0" type="button" style={{ height: "42px", marginTop: "5px", zIndex: "1" }} onClick={() => setViewFinalQuotation(true)}> */}
                                            <button disabled={!user.customer} className="btn btn-outline-secondary contractor-btn mt-0" type="button" style={{ height: "37px", marginTop: "5px", zIndex: "1" }} onClick={() => onDownload(user.finalQuatation)}>
                                                <img src={downloadWhite} alt="View" width="20" style={{ marginRight: "5px" }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {formErrors?.finalQuatation && <span style={{ color: "red" }}>{formErrors.finalQuatation}</span>}
                            </div>
                        </Col>
                        <Col xl={4} md={6}>
                            <div className="start-estimated-date">
                                <div className="users-form-info mt-3">
                                    <div className="users-form-info add-remark-modal approve_customer  mx-0 ">
                                        <label className='selector_label'>Customer</label>
                                        <div className="multi-select d-flex" >
                                            <Select
                                                value={selectValue(user.customer, "customer")}
                                                onChange={(e) => { setUser({ ...user, customer: e.value }); setFormErrors({ ...formErrors, customer: '' }) }}
                                                options={customerOption}
                                                placeholder="Select Customer"
                                                styles={{ width: '100%' }}
                                            />
                                            <div>

                                                <button class="btn btn-outline-secondary contractor-btn" type="button" style={{ height: "37px" }} onClick={() => setCustomerTog(true)}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    {formErrors?.customer && (<label style={{ color: "red" }}>{formErrors.customer}</label>)}
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} md={6}>
                            <div className="users-form-info finalquotation mt-3">
                                <div className="multi-select customer-width">
                                    <label>Project Manager</label>
                                    <Select
                                        placeholder="Select project manager"
                                        value={selectValue(user.projectManager, "projectManager")}
                                        onChange={(e) => handleChange(e, "projectManager")}
                                        options={userOption}
                                    />
                                </div>
                                {formErrors?.projectManager && <span style={{ color: "red" }}>{formErrors.projectManager}</span>}
                            </div>
                        </Col>
                        <Col xl={4} md={6}>
                            <div className="users-form-info mt-3">
                                <div className="">
                                    <label style={{ fontWeight: "600" }}>Work Order File</label>
                                    <div className="upload-file mt-1 small-file-input w-100" onClick={() => UploadClick()}>
                                        <label className='upload-file-lable' >Upload here</label>
                                        <input
                                            type="file"
                                            placeholder="Enter work order"
                                            className="form-control d-none"
                                            id='workOrder'
                                            onChange={(e) => setWorkOrderFile(e.target.files[0])}
                                            name="workOrder"
                                        />
                                    </div>
                                    {user.workOrder.length > 0 ? user.workOrder.map((ele, i) => <label style={{ color: "green", marginTop: '10px', display: "flex" }} key={i}>
                                        <img src={Viewicon} alt="View" width="20px" height="20px" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => fileOpen(ele)} />
                                        <img src={removeImg} alt="Remove" width="20px" height="20px" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => fileRemove(ele, i, 'removeWorkOrder')} />
                                        Uploaded File : {ele && ele.split('/')[1] ? ele.split('/')[1] : ele}
                                    </label>)
                                        :
                                        formErrors?.workOrder ? <label style={{ color: "red" }}>{formErrors.workOrder}</label> : ''}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            {customerTog && <AddCustomer show={customerTog} onHide={() => Hide()} customerId={''} />}
            {viewFinalQuotation && <ViewFinalQuotation show={viewFinalQuotation} onHide={() => setViewFinalQuotation(false)} select={selectValue(user.finalQuatation, "finalQuatation")} />}
        </>
    )
}

export default MaintenanceApprove