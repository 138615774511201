import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import img from "../../assets/image/select.png";
import ReactDatePicker from "react-datepicker";
import Datepickericon from "../../assets/image/date-picker-icon.png";
import { addTaskAction, editTaskAction, getAllTaskAction, getTaskByIdAction } from '../../redux/actions/taskAction';
import { getAllUserAction } from '../../redux/actions/userAction';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import Avatar from 'react-avatar';
import ReactHtmlParser from 'react-html-parser';
import removeImg from '../../assets/image/delete-red.png'
import { toast } from 'react-toastify';
import { ADD_TASK, ADD_TASK_COMMENT, DELETE_TASK_COMMENT, EDIT_TASK } from '../../redux/type';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import { addTaskCommentAction, deleteTaskCommentAction, editTaskCommentAction, getCommentByTaskIdAction } from '../../redux/actions/taskCommentAction';
import { Status, UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import editIcon from '../../assets/image/edit.svg';
import { dateFormate } from '../../helper/utils';
import moment from 'moment';
import { ApiDelete, ApiPost } from '../../helper/API/ApiData';
import { API } from '../../config/API/api.config';
import { fileRemoveAction } from '../../redux/actions/generalAction';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLocation, useNavigate } from 'react-router-dom';

const AddTask = ({ show, onHide, taskId }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search)
    const type = queryParams.get("type")
    const currentDate = moment().format()
    const userRole = useContext(RoleContext);
    const dataUser = AuthStorage.getStorageJsonData(STORAGEKEY.userData)
    const dispatch = useDispatch()
    const [task, setTask] = useState({
        projectId: '',
        projectName: '',
        subject: "",
        startDate: "",
        dueDate: "",
        priority: "",
        status: "To Do",
    })
    const [taskImages, setTaskImages] = useState([])
    const [oldTaskImages, setOldTaskImages] = useState([])
    const [filteredTaskImages, setfilteredTaskImages] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [createdBy, setCreatedBy] = useState('')
    const [commentCreatedBy, setCommentCreatedBy] = useState([])
    const toastSuccess = () => toast.success("Task added successfully");
    const toastSuccessUpdate = () => toast.success("Task updated successfully");
    const [formErrors, setFormErrors] = useState()
    const [rotateArrow, setrotateArrow] = useState('');
    const [userOption, setUserOption] = useState([])
    const [description, setDescription] = useState('')
    const [comment, setComment] = useState('')
    const [commentEditId, setCommentEditId] = useState('')
    const [commentData, setCommentData] = useState([])
    const [assignTo, setAssignTo] = useState([])
    const [selecteProject, setSelecteProject] = useState(true);
    const [projectOption, setProjectOption] = useState([])
    const commentDelete = () => toast.success('Comment delete successfully');
    const commentAdded = () => toast.success('Comment added successfully');
    const toastUpdate = () => toast.success('Comment update successfully');
    const toastError = () => toast.error('Task not found');
    const taskData = useSelector((state) => state.get_Task_By_Id.getTaskById)
    const userData = useSelector((state) => state.get_All_User.getAllUser)
    const projectData = useSelector((state) => state.get_All_Project.getAllProject.data)
    const commentByTaskIdData = useSelector((state) => state.get_Comment_By_Task_Id.getCommentByTaskId)
    const deleteTaskCommentData = useSelector((state) => state.delete_Task_Comment.deleteTaskComment)
    const addTaskData = useSelector((state) => state.add_Task.addTask)
    const updateTaskData = useSelector((state) => state.edit_Task.editTask)
    const addTaskCommentData = useSelector((state) => state.add_Task_Comment.addTaskComment)

    const imageType = ['png', 'jpg', 'jpeg', 'gif', 'ico', 'svg', 'tiff', 'tif', 'webp', 'bmp', 'avif']
    const videoType = ['avi', 'mp4', 'mpeg', 'ogv', 'ts', 'webm', '3gp', '3g2']

    useEffect(() => {
        let body = ''
        dispatch(getAllUserAction())
        dispatch(getAllProjectAction(body))
        if (taskId) {
            dispatch(getTaskByIdAction(taskId))
            dispatch(getCommentByTaskIdAction(taskId))
        }
    }, [taskId])

    useEffect(() => {
        if (projectData && userRole) {
            if (userRole === UserRoles.Project) {
                setProjectOption(projectData.filter((ele) => ele.projectType !== "Maintenance").map((item) => {
                    return {
                        value: item._id,
                        label: item.partyName
                    }
                }))
            } else if (userRole === UserRoles.Maintance) {
                setProjectOption(projectData.filter((ele) => ele.projectType === "Maintenance").map((item) => {
                    return {
                        value: item._id,
                        label: item.partyName
                    }
                }))
            } else {
                setProjectOption(projectData.map((item) => {
                    return {
                        value: item._id,
                        label: item.partyName
                    }
                }))
            }
        }
    }, [projectData])

    useEffect(() => {
        if (userData && userData.data) {
            setUserOption(userData.data.map((item) => {
                return {
                    label: item.name,
                    value: item._id
                }
            }))
        }
    }, [userData])

    useEffect(() => {
        if (taskData && taskData.data && taskId) {
            setTask({
                subject: taskData.data.subject,
                startDate: taskData.data.startDate ? new Date(taskData.data.startDate) : "",
                dueDate: taskData.data.dueDate ? new Date(taskData.data.dueDate) : "",
                priority: taskData.data.priority,
                status: taskData.data.status,
                projectName: taskData.data.projectName,
                projectId: taskData.data.project,
            })
            setCreatedBy(taskData.data.createdBy)
            setSelecteProject(taskData.data.project ? true : false)
            setDescription(taskData.data.description)
            setAssignTo(assignFind(taskData.data.assignTo))
            setTaskImages(taskData.data.files)
            setOldTaskImages(taskData.data.files)
        }
        if (taskData?.message === "Task not found") {
            onHide()
            toastError(true)
        }
    }, [taskData])

    useEffect(() => {
        if (commentByTaskIdData && commentByTaskIdData.data) {
            setCommentData(commentByTaskIdData.data)
            setCommentCreatedBy(commentByTaskIdData.data.map((ele) => ele.createdBy._id))
        }
    }, [commentByTaskIdData])

    useEffect(() => {
        if (deleteTaskCommentData && deleteTaskCommentData.data && deleteTaskCommentData.data.status === 200) {
            dispatch(getCommentByTaskIdAction(taskId))
            dispatch({
                type: DELETE_TASK_COMMENT,
                payload: []
            })
        }
    }, [deleteTaskCommentData])

    const assignFind = (id) => {
        return id.map((data) => userOption.find((item) => item.value === data))
    }

    const handleChange = (e, name) => {
        let re = /^[0-9\b]+$/;
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "effort") {
            if (e.target.value === '' || re.test(e.target.value)) {
                setTask({ ...task, [name]: e.target.value })
            }
        } else if (name === "projectId") {
            setFormErrors({ ...formErrors, "projectName": '', [name]: '' })
            setTask({ ...task, projectName: e.label, [name]: e.value })
        } else if (name === "startDate" || name === "dueDate") {
            setTask({ ...task, [name]: e })
        } else if (name === "assignTo") {
            setTask({ ...task, [name]: e.map((data) => data.value) })
        } else {
            setTask({ ...task, [name]: e.target.value })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        if (!task.subject) {
            error.subject = "Please enter subject"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const Save = () => {
        if (validation()) {
            return
        }
        let body = {
            project: task.projectId,
            projectName: task.projectName,
            subject: task.subject,
            startDate: task.startDate,
            dueDate: task.dueDate,
            priority: task.priority,
            status: task.status,
            assignTo: assignTo.map((item) => item.value),
            description: description,
            files: taskImages
        }
        dispatch(addTaskAction(body))
    }

    useEffect(() => {
        if (addTaskData && addTaskData.data) {
            if (addTaskData.message === "Task created successfully.") {
                dispatch({
                    type: ADD_TASK,
                    payload: []
                })
                onHide()
                dispatch(getAllTaskAction({ status: "", project: "", aasignTo: [], createdBy: [], type: "" }))
                toastSuccess(true)
                if (type === "kanban") {
                    navigate('/task?type=kanban')
                }
                if (type === "list") {
                    navigate('/task?type=list')
                }
            }
        }
    }, [addTaskData])

    const handleChangeAssign = (e) => {
        setAssignTo(e);
        setFormErrors({ ...formErrors, assignTo: '' })
    }

    const Edit = () => {
        if (validation()) {
            return
        }
        let data = {
            id: taskId,
            project: selecteProject ? task.projectId : '',
            projectName: task.projectName,
            subject: task.subject,
            startDate: task.startDate,
            dueDate: task.dueDate,
            priority: task.priority,
            status: task.status,
            assignTo: assignTo.map((item) => item.value),
            description: description,
            files: taskImages
        }
        dispatch(editTaskAction(data))
    }

    useEffect(() => {
        if (updateTaskData && updateTaskData.data) {
            if (updateTaskData.data.message === "Task updated successfully.") {
                dispatch({
                    type: EDIT_TASK,
                    payload: []
                })
                onHide()
                dispatch(getAllTaskAction({ status: "", project: "", aasignTo: [], createdBy: [], type: "" }))
                toastSuccessUpdate(true)
                if (type === "kanban") {
                    navigate('/task?type=kanban')
                }
                if (type === "list") {
                    navigate('/task?type=list')
                }
            }

        }
    }, [updateTaskData])

    const addComment = () => {
        const body = {
            taskId: taskId,
            comment: comment,
        }
        dispatch(addTaskCommentAction(body))
    }

    useEffect(() => {
        if (addTaskCommentData && addTaskCommentData.data) {
            if (addTaskCommentData.message === "Task Comment added successfully.") {
                dispatch({
                    type: ADD_TASK_COMMENT,
                    payload: []
                })
                dispatch(getCommentByTaskIdAction(taskId))
                setComment('')
                commentAdded(true)
            }
        }
    }, [addTaskCommentData])

    const editComment = (item, id) => {
        setComment(item)
        setCommentEditId(id)
    }

    const edit = () => {
        const body = {
            id: commentEditId,
            taskId: taskId,
            comment: comment,
        }
        dispatch(editTaskCommentAction(body))
        setCommentEditId('')
        setComment('')
        toastUpdate(true)
    }

    const deleteComment = (id) => {
        dispatch(deleteTaskCommentAction(id))
        commentDelete(true)
    }

    const openCalender = (id) => {
        document.getElementById(id).click();
    };

    const Blur = () => {
        setrotateArrow('')
    }

    const Click = (name) => {
        if (rotateArrow === name) {
            setrotateArrow('')
        } else {
            setrotateArrow(name)
        }
    }

    const handle = (e) => {
        setSelecteProject(!selecteProject)
        if (!e.target.checked) {
            setTask({ ...task, projectId: '', projectName: 'OTHER' })
        } else {
            setTask({ ...task, projectId: '' })
        }
    }

    const projectClick = () => {
        document.getElementById('ourProject').click()
    }

    const selectValue = (value, name) => {
        if (name === "projectId") {
            return projectOption.find((ele) => ele.value === value)
        }
    }

    const fileUpload = (file) => {
        let formData = new FormData();
        formData.append("files", file);
        ApiPost("general/file_upload", formData)
            .then((res) => {
                setTaskImages([
                    {
                        file: res.url,
                        taskId: "",
                        id: ""
                    },
                    ...taskImages
                ])
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const attechFile = () => {
        document.getElementById('taskimages').click()
    }

    const viewImage = (item) => {
        window.open(`${API.endpoint}/${item.file}`, "_blank")
    }

    const delteImage = (item) => {
        let body = {
            file: item.file.split('/')[1]
        }
        dispatch(fileRemoveAction(body))

        if (item.id) {
            removeFile(item)
        } else {
            let newFiles = [...taskImages]
            newFiles = newFiles.filter((it) => it.file !== item.file)
            setTaskImages(newFiles)
        }
    }

    const removeFile = (item) => {
        ApiDelete(`task/remove_task_file/${item.id}`)
            .then((res) => {
                let newFiles = [...taskImages]
                newFiles = newFiles.filter((it) => it.file !== item.file)
                setTaskImages(newFiles)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getFileType = (url) => {
        let fileType = url.split('.')[url.split('.').length - 1]
        return fileType
    }

    const onCancel = () => {
        let newImgArr = []
        taskImages.map((item) => {
            let data = oldTaskImages.filter((it) => it.file === item.file)
            if (!data.length) {
                newImgArr.push(item)
            }
        })
        setfilteredTaskImages(newImgArr)
        if (newImgArr.length) {
            setShowAlert(true)
        } else {
            onHide()
            if (type === "kanban") {
                navigate('/task?type=kanban')
            }
            if (type === "list") {
                navigate('/task?type=list')
            }
        }

    }

    const onConfirm = () => {
        filteredTaskImages.map((item) => {
            let body = {
                file: item.file.split('/')[1]
            }
            dispatch(fileRemoveAction(body))
        })
        onHide()
    }


    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>{taskId ? "Edit Task" : "Add Task"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <div className="users-form-info ms-0">
                        <input type={"checkbox"} className="me-3" checked={selecteProject} onChange={(e) => handle(e)} id="ourProject" disabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false} />
                        <label onClick={() => projectClick()}>Our Project</label>
                    </div>
                    {selecteProject &&
                        <Col lg={4}>
                            <div className="users-form-info add-remark-modal mx-0 mt-3">
                                <div className="multi-select">
                                    <label>Select Project</label>
                                    <Select
                                        placeholder="Select Project"
                                        value={selectValue(task.projectId, "projectId")}
                                        onChange={(e) => handleChange(e, "projectId")}
                                        options={projectOption}
                                        isDisabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false}
                                    />
                                </div>
                                {formErrors?.project && <span style={{ color: "red" }}>{formErrors.project}</span>}
                            </div>
                        </Col>}
                    {!selecteProject && <Col lg={4}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Project Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter project name"
                                    value={task.projectName}
                                    onChange={(e) => handleChange(e, 'projectName')}
                                    autoComplete="off"
                                    disabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false}
                                />
                            </div>
                            {formErrors?.projectName && <span style={{ color: "red" }}>{formErrors.projectName}</span>}
                        </div>
                    </Col>}
                    <Col lg={4}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Subject</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter subject"
                                    value={task.subject}
                                    onChange={(e) => handleChange(e, 'subject')}
                                    autoComplete="off"
                                    disabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false}
                                />
                            </div>
                            {formErrors?.subject && <span style={{ color: "red" }}>{formErrors.subject}</span>}
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3 enquery-datepicker">
                            <div className="users-label">
                                <label>Start Date</label>
                                <ReactDatePicker
                                    id="EnquiryDatePicker1"
                                    dateFormat="dd/MM/yyyy"
                                    selected={task.startDate}
                                    placeholderText="Select start date"
                                    onChange={(e) => { handleChange(e, 'startDate') }}
                                    autoComplete="off"
                                    disabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false}
                                />
                            </div>
                            {formErrors?.startDate && (<label style={{ color: "red" }}>{formErrors.startDate}</label>)}
                            <img
                                src={Datepickericon}
                                onClick={() => openCalender("EnquiryDatePicker1")}
                                alt=""
                                className="datepickericon"
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3 enquery-datepicker">
                            <div className="users-label">
                                <label>Due Date</label>
                                <ReactDatePicker
                                    id="EnquiryDatePicker2"
                                    dateFormat="dd/MM/yyyy"
                                    selected={task.dueDate}
                                    minDate={task.startDate}
                                    placeholderText="Select due date"
                                    onChange={(e) => { handleChange(e, 'dueDate') }}
                                    autoComplete="off"
                                    disabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false}
                                />
                            </div>
                            {formErrors?.dueDate && (
                                <label style={{ color: "red" }}>
                                    {formErrors.dueDate}
                                </label>
                            )}
                            <img
                                src={Datepickericon}
                                onClick={() => openCalender("EnquiryDatePicker2")}
                                alt=""
                                className="datepickericon"
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Priority</label>
                                <div className="position-relative select-icon user-type">
                                    <img src={img} className={`${rotateArrow === 'priority' && "rotateArrow"}`} alt="" />
                                    <select className="form-control " value={task.priority} onBlur={() => Blur()} onClick={() => Click('priority')} onChange={(e) => handleChange(e, "priority")} disabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false}>
                                        <option value="">Select priority</option>
                                        <option value="Low">Low</option>
                                        <option value="Medium">Medium</option>
                                        <option value="High">High</option>
                                        <option value="Urgent">Urgent</option>
                                    </select>
                                </div>
                            </div>
                            {formErrors?.priority && <span style={{ color: "red" }}>{formErrors.priority}</span>}
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Status</label>
                                <div className="position-relative select-icon user-type">
                                    <img src={img} className={`${rotateArrow === 'status' && "rotateArrow"}`} alt="" />
                                    <select className="form-control " value={task.status} onBlur={() => Blur()} onClick={() => Click('status')} onChange={(e) => handleChange(e, "status")}>
                                        <option value={Status.ToDo}>To Do</option>
                                        <option value={Status.InProgress}>In Progress</option>
                                        <option value={Status.Review}>Review</option>
                                        <option value={Status.Hold}>Hold</option>
                                        <option value={Status.Done}>Done</option>
                                        <option value={Status.Approved}>Approved</option>
                                    </select>
                                </div>
                            </div>
                            {formErrors?.status && <span style={{ color: "red" }}>{formErrors.status}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info add-remark-modal mx-0 mt-3">
                            <div className="multi-select">
                                <label>Assign To</label>
                                <Select
                                    value={assignTo}
                                    onChange={(e) => handleChangeAssign(e)}
                                    options={userOption}
                                    isMulti={true}
                                    isDisabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false}
                                />
                            </div>
                            {formErrors?.assignTo && <span style={{ color: "red" }}>{formErrors.assignTo}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <label className='mt-3'><b>Attachments</b></label>
                            <div className="upload-file mt-1 mb-3" onClick={() => attechFile()}>
                                <label className='upload-file-lable' >Click here to attach files</label>
                                <input
                                    type="file"
                                    className="form-control d-none"
                                    id="taskimages"
                                    onChange={(e) => fileUpload(e.target.files[0])}
                                    name="quoationUpdate"
                                />
                            </div>
                            <div className='atteched-files-section col-12 row'>
                                {taskImages?.length > 0 && taskImages.reverse().map((item, i) => (
                                    <>
                                        <div class="col-6 col-sm-6 col-sm-4 col-md-4 col-lg-2 pb-3 position-relative" key={i}>
                                            {imageType.includes(getFileType(item.file.split('/')[1])) ?
                                                <>
                                                    <img src={`${API.endpoint}/${item.file}`} alt={item.file} title={item.file.split('/')[1]} />
                                                    <div className='delete-icon-section'>
                                                        <img class="delete-icon" src="./assets/image/delete.svg" alt='delete' onClick={() => delteImage(item)} />
                                                    </div>
                                                    <div className='view-icon-section'>
                                                        <img class="eye-icon" src="./assets/image/eye.svg" alt='view' onClick={() => viewImage(item)} />
                                                    </div>
                                                </>
                                                : videoType.includes(getFileType(item.file.split('/')[1])) ?
                                                    <>
                                                        <img src="./assets/image/video_icon.png" alt={item.file} title={item.file.split('/')[1]} />
                                                        <div className='delete-icon-section'>
                                                            <img class="delete-icon" src="./assets/image/delete.svg" alt='delete' onClick={() => delteImage(item)} />
                                                        </div>
                                                        <div className='view-icon-section'>
                                                            <img class="eye-icon" src="./assets/image/eye.svg" alt='view' onClick={() => viewImage(item)} />
                                                        </div>
                                                    </>
                                                    :
                                                    getFileType(item.file.split('/')[1]) === "pdf" ?
                                                        <>
                                                            <img src="./assets/image/pdf_icon.png" alt={item.file} title={item.file.split('/')[1]} />
                                                            <div className='delete-icon-section'>
                                                                <img class="delete-icon" src="./assets/image/delete.svg" alt='delete' onClick={() => delteImage(item)} />
                                                            </div>
                                                            <div className='view-icon-section'>
                                                                <img class="eye-icon" src="./assets/image/eye.svg" alt='view' onClick={() => viewImage(item)} />
                                                            </div>
                                                        </>
                                                        :
                                                        (getFileType(item.file.split('/')[1]) === "doc" || getFileType(item.file.split('/')[1]) === "docx") ?
                                                            <>
                                                                <img src="./assets/image/word_icon.png" alt={item.file} title={item.file.split('/')[1]} />
                                                                <div className='delete-icon-section'>
                                                                    <img class="delete-icon" src="./assets/image/delete.svg" alt='delete' onClick={() => delteImage(item)} />
                                                                </div>
                                                                <div className='view-icon-section'>
                                                                    <img class="eye-icon" src="./assets/image/eye.svg" alt='view' onClick={() => viewImage(item)} />
                                                                </div>
                                                            </>
                                                            :
                                                            (getFileType(item.file.split('/')[1]) === "xls" || getFileType(item.file.split('/')[1]) === "xlsx") ?
                                                                <>
                                                                    <img src="./assets/image/excel_icon.png" alt={item.file} title={item.file.split('/')[1]} />
                                                                    <div className='delete-icon-section'>
                                                                        <img class="delete-icon" src="./assets/image/delete.svg" alt='delete' onClick={() => delteImage(item)} />
                                                                    </div>
                                                                    <div className='view-icon-section'>
                                                                        <img class="eye-icon" src="./assets/image/eye.svg" alt='view' onClick={() => viewImage(item)} />
                                                                    </div>
                                                                </>
                                                                :
                                                                (getFileType(item.file.split('/')[1]) === "csv") ?
                                                                    <>
                                                                        <img src="./assets/image/csv_icon.png" alt={item.file} title={item.file.split('/')[1]} />
                                                                        <div className='delete-icon-section'>
                                                                            <img class="delete-icon" src="./assets/image/delete.svg" alt='delete' onClick={() => delteImage(item)} />
                                                                        </div>
                                                                        <div className='view-icon-section'>
                                                                            <img class="eye-icon" src="./assets/image/eye.svg" alt='view' onClick={() => viewImage(item)} />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <img src="./assets/image/other_file.png" alt={item.file} title={item.file.split('/')[1]} />
                                                                        <div className='delete-icon-section'>
                                                                            <img class="delete-icon" src="./assets/image/delete.svg" alt='delete' onClick={() => delteImage(item)} />
                                                                        </div>
                                                                        <div className='view-icon-section'>
                                                                            <img class="eye-icon" src="./assets/image/eye.svg" alt='view' onClick={() => viewImage(item)} />
                                                                        </div>
                                                                    </>
                                            }
                                        </div>
                                    </>
                                ))
                                }
                            </div>
                        </div>

                    </Col>
                    <Col lg={12}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3 add-task-modal">
                            <div className="users-label">
                                <label>Description</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={description}
                                    config={{
                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                    }}
                                    onChange={(event, editor) => {
                                        setFormErrors({ ...formErrors, description: '' })
                                        setDescription(editor.getData())
                                    }}
                                    onReady={(editor) => setDescription(editor?.getData())}
                                    disabled={taskId ? userRole === UserRoles.Admin ? false : createdBy === dataUser.id ? false : true : false}
                                />
                            </div>
                            {formErrors?.description && <span style={{ color: "red" }}>{formErrors.description}</span>}
                        </div>
                    </Col>
                </Row>
                {taskId && <Row>
                    <Col lg={12}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3 add-task-modal">
                            <div className="users-label">
                                <label>Comments</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={comment}
                                    config={{
                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                                    }}
                                    onChange={(event, editor) => {
                                        setComment(editor.getData())
                                    }}
                                    onReady={(editor) => setComment(editor?.getData())}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3 gap-2  d-flex">
                            <Button className='adduser-btn mt-1' onClick={() => { commentEditId !== '' ? edit() : addComment() }} disabled={comment === ''}>{commentEditId !== '' ? "Edit" : "Add"}</Button>
                            <button className='cancel-button mt-1' onClick={() => { setComment(''); setCommentEditId('') }}>Clear</button>
                        </div>
                    </Col>
                </Row>}
                {taskId && commentData.length > 0 &&
                    commentData.map((item, i) =>
                        <div className='comment-box' key={i}>
                            <Row>
                                <Col lg={10}>
                                    <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                                        <div>
                                            <div className='d-flex justify-content-between align-items-center users-label'>
                                                <div>
                                                    <label>{<Avatar name={item.createdBy?.name} round={true} size="30px" />}</label>
                                                    <label className='ms-3'>{item.createdBy?.name}</label>
                                                </div>
                                                <label>{dateFormate(item.updatedAt, "FullDateTime")}</label>
                                            </div>
                                            <label>
                                                {ReactHtmlParser(item.comment)}
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                                        {userRole === UserRoles.Admin ? <><img src={editIcon} onClick={() => editComment(item.comment, item._id)} style={{ cursor: "pointer", height: '20px', marginTop: '20px' }} alt="Edit" />
                                            <img src={removeImg} onClick={() => deleteComment(item._id)} style={{ cursor: "pointer", height: "20px", marginTop: '20px' }} className='ms-3' alt="" /></>
                                            :
                                            dataUser.id === commentCreatedBy[i] && moment(currentDate).diff(moment(item.createdAt).format(), 'minutes') < 6 && <>
                                                <img src={editIcon} onClick={() => editComment(item.comment, item._id)} style={{ cursor: "pointer", height: '20px', marginTop: '20px' }} alt="" />
                                                <img src={removeImg} onClick={() => deleteComment(item._id)} style={{ cursor: "pointer", height: "20px", marginTop: '20px' }} className='ms-3' alt="" /></>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { taskId ? Edit() : Save() }}>{taskId ? "Edit" : 'Save'}</button>
                <button className='cancel-button' onClick={onCancel}>Cancel</button>
            </Modal.Footer>

            {showAlert &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => { setShowAlert(false) }}
                    focusCancelBtn
                >
                    You want to discard your changes.
                </SweetAlert>
            }

        </Modal >
    )
}

export default AddTask