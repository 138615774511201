import React, { useState, useEffect } from 'react'
import ViewCardDesk from '../../../components/dashboard/ViewCardDesk'
import ViewTableDesk from '../../../components/dashboard/ViewTableDesk'
import ViewPieDesk from '../../../components/dashboard/ViewPieDesk'
import ViewBarDesk from '../../../components/dashboard/ViewBarDesk'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { getEnquiryByMonthYearWiseAction, getEnquiryCountByStatusAction, getRecentEnquiryAction } from '../../../redux/actions/dashboardAction'
import { dateFormate } from '../../../helper/utils'


const SalesDashboard = () => {

  const dispatch = useDispatch()

  let startYear = 2021
  let currentYear = new Date().getFullYear()
  const yearOption = [...Array(currentYear - startYear + 1).keys()].map((e) => {
    return { label: (e + startYear).toString(), value: (e + startYear).toString() };
  })

  const [year, setyear] = useState({ label: currentYear.toString(), value: currentYear.toString() })

  const [Task, setTask] = useState([])
  const [recentEnquiry, setRecentEnquiry] = useState([])
  const [enquiryByMonthYearWise, setEnquiryByMonthYearWise] = useState([])
  const [enquiryCountByStatus, setEnquiryCountByStatus] = useState([])
  const [type, setType] = useState({ label: "All", value: "" })
  const SalesType = [
    { label: "All", value: "" },
    { label: "New Project", value: "New Project" },
    { label: "Maintenance", value: "Maintenance" },
    { label: "MaterialTrading", value: "Material Trading" },
    { label: "Consulting", value: "Consulting" },
  ]

  const getTaskDashboardData = useSelector((state) => state.task_Dashboard.taskDashboard)
  const getRecentEnquiryData = useSelector((state) => state.get_Recent_Enquiry.getRecentEnquiry)
  const getEnquiryByMonthYearWiseData = useSelector((state) => state.get_Enquiry_By_Month_Year_Wise.getEnquiryByMonthYearWise)
  const getEnquiryCountByStatusData = useSelector((state) => state.get_Enquiry_Count_By_Status.getEnquiryCountByStatus)

  useEffect(() => {
    dispatch(getRecentEnquiryAction())
  }, [])

  useEffect(() => {
    dispatch(getEnquiryCountByStatusAction(type.value))
  }, [type])
  

  useEffect(() => {
    dispatch(getEnquiryByMonthYearWiseAction(year.value))
  }, [year])


  useEffect(() => {
    if (getTaskDashboardData && getTaskDashboardData.data) {
      setTask(getTaskDashboardData.data.map((ele) => {
        return {
          ProjectName: ele.projectName,
          Subject: ele.subject,
          Status: ele.status,
        }
      }))
    }
  }, [getTaskDashboardData])

  useEffect(() => {
    if (getRecentEnquiryData && getRecentEnquiryData.data) {
      setRecentEnquiry(getRecentEnquiryData.data.map((ele) => {
        return {
          PartyName: ele.partyName,
          EnquiryDate: dateFormate(ele.enquiryDate, "Date"),
          VisitDate: dateFormate(ele.visitDate, "Date"),
          Status: ele.status,
          ProjectType: ele.projectType
        }
      }))
    }
  }, [getRecentEnquiryData])

  useEffect(() => {
    if (getEnquiryByMonthYearWiseData && getEnquiryByMonthYearWiseData.data) {
      let temp = [['Month', 'Count']];
      getEnquiryByMonthYearWiseData.data.map((ele) => {
        temp.push([ele.month.substring(0, 3), ele.count])
      })
      setEnquiryByMonthYearWise(temp)
    }
  }, [getEnquiryByMonthYearWiseData])

  useEffect(() => {
    if (getEnquiryCountByStatusData && getEnquiryCountByStatusData.data) {
      let temp = [['Label', 'Count']];
      getEnquiryCountByStatusData.data.map((ele) => {
        temp.push([ele.label, ele.count])
      })
      setEnquiryCountByStatus(temp)
    }
  }, [getEnquiryCountByStatusData])

  return (
    <div className="p-0 m-0">
      {/* <div className="row"> */}
      {/* <div className='col-6'>
              <ViewCardDesk
                data={{
                  content: 'Grand Payable',
                  count: <CurrencyFormat value={totalPurchaseAmount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />,
                  color: '#198754'
                }}
              />
            </div> */}

      {/* <div className="col-6">
              <ViewCardDesk
                data={{
                  content: 'Grand Receivable',
                  count: <CurrencyFormat value={totalSaleAmount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />,
                  color: '#dc3545',
                  // icon: faCoffee,
                }}
              />
            </div> */}
      <Row>
        <Col lg={6} md={6}>
          <Col lg={12}>
            {enquiryCountByStatus.length > 0 && <ViewPieDesk
              data={enquiryCountByStatus}
              title="Enquiry Status Summary"
              range='Type'
              rangeOptions={SalesType}
              initialRangeVal={type}
              RangeValMethod={setType}
            />}
          </Col>
        </Col>

        <Col lg={6} md={6}>
          <Col lg={12}>
            {enquiryByMonthYearWise.length > 0 && <ViewBarDesk
              data={enquiryByMonthYearWise}
              title="Enquiry Summary"
              range='Year'
              rangeOptions={yearOption}
              initialRangeVal={year}
              RangeValMethod={setyear}
            />}
          </Col>
        </Col>

        <Col lg={6} md={6}>
          <Col lg={12} className="mt-4">
            {recentEnquiry.length > 0 && <ViewTableDesk
              data={recentEnquiry}
              title={"Recent Enquiry"}
              link="/enquiry"
            />}
          </Col>
        </Col>

        <Col lg={6} md={6}>
          <Col lg={12} className="mt-4">
            {Task.length > 0 && <ViewTableDesk data={Task} title={"Recent Task"} link="/task" />}
          </Col>
        </Col>
      </Row>
      {/* </div> */}
    </div>
  )
}

export default SalesDashboard