import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fileRemoveAction, fileUploadAction } from '../../redux/actions/generalAction';
import Viewicon from '../../assets/image/view.png'
import { API } from '../../config/API/api.config';
import { v4 as uuidv4 } from "uuid";
import removeImg from '../../assets/image/delete-red.png'
import viewImg from '../../assets/image/eye.svg'
import { addAssetsAction, editAssetsAction, getAllAssetsNonProjectAction, getAssetsByIdAction } from '../../redux/actions/assetsAction';
import { ADD_ASSETS, EDIT_ASSETS, FILE_UPLOAD } from '../../redux/type';
import { toast } from 'react-toastify';
import View from '../../assets/image/eye.svg';
import SweetAlert from 'react-bootstrap-sweetalert';

const AssetsNonProject = ({ show, onHide, AssetsId }) => {

    const dispatch = useDispatch()
    const [asssetArray, setAsssetArray] = useState([])
    const [assets, setAssets] = useState({
        id: uuidv4(),
        name: "",
        file: "",
        project: '',
        isProject: false,
    })
    const [reject, setReject] = useState()
    const [paramsValue, setParamsValue] = useState()
    const [uploadFile, setUploadFile] = useState(undefined)
    const [formErrors, setFormErrors] = useState()
    const toastAssetsAdd = () => toast.success('Assets added successfully')
    const toastAssetsUpdate = () => toast.success('Assets updated successfully')
    const toastAssetsDelete = () => toast.success('Assets deleted successfully')
    const fileData = useSelector((state) => state.file_Upload.fileUpload);
    const addAssetsData = useSelector((state) => state.add_Assets.addAssets)
    const getAssetsByIdData = useSelector((state) => state.get_Assets_By_Id.getAssetsById)
    const updateAssetsData = useSelector((state) => state.edit_Assets.editAssets)

    useEffect(() => {
        if (AssetsId) {
            dispatch(getAssetsByIdAction(AssetsId))
        }
    }, [AssetsId])

    useEffect(() => {
        if (getAssetsByIdData && getAssetsByIdData.data) {
            setAssets({
                id: getAssetsByIdData.data._id,
                name: getAssetsByIdData.data.name,
                file: getAssetsByIdData.data.file,
                isProject: getAssetsByIdData.data.isProject,
                project: getAssetsByIdData.data.project,
            })
        }
    }, [getAssetsByIdData])

    useEffect(() => {
        if (uploadFile) {
            if (assets.file) {
                fileRemove(assets.file, '')
            }
            let formData = new FormData();
            formData.append("files", uploadFile);
            dispatch(fileUploadAction(formData));
        }
    }, [uploadFile])

    useEffect(() => {
        if (fileData) {
            setAssets({ ...assets, file: fileData.url })
        }
    }, [fileData])

    const fileRemove = (files, name) => {
        let body = {
            file: files.split('/')[1]
        }
        dispatch(fileRemoveAction(body))
        if (name === 'removeFile') {
            setAssets({ ...assets, file: '' })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        if (!assets.name) {
            error.name = "Please enter name"
            flag = true
        }
        if (!assets.file) {
            error.file = "Please select file"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const Add = () => {
        if (validation()) {
            return
        }
        let temp = [...asssetArray]
        temp.push(assets)
        setAsssetArray(temp)
        setAssets({
            id: uuidv4(),
            name: "",
            file: "",
            project: "",
            isProject: false,
        })
        dispatch({
            type: FILE_UPLOAD,
            payload: []
        })
    }

    const Save = () => {
        if (asssetArray.length) {
            let body = asssetArray.map((ele) => {
                return {
                    name: ele.name,
                    file: ele.file,
                    project: ele.project,
                    isProject: ele.isProject,
                }
            })
            dispatch(addAssetsAction(body))
        } else if (asssetArray.length < 1) {
            if (validation()) {
                return
            }
        }
    }

    useEffect(() => {
        if (addAssetsData && addAssetsData.message === "Assets created successfully.") {
            dispatch({
                type: ADD_ASSETS,
                payload: []
            })
            toastAssetsAdd(true)
            onHide()
            dispatch(getAllAssetsNonProjectAction())
        }
    }, [addAssetsData])

    const UploadClick = () => {
        document.getElementById('file').click()
    }

    const fileClick = (name) => {
        window.open(`${API.endpoint}/${name}`, "_blank")
    }

    const Delete = (id) => {
        setParamsValue(id)
        setReject(true)
    }

    const Edit = () => {
        if (validation()) {
            return
        }
        dispatch(editAssetsAction(assets))
    }

    useEffect(() => {
        if (updateAssetsData && updateAssetsData.data) {
            if (updateAssetsData.data.message === "Assets updated successfully.") {
                dispatch({
                    type: EDIT_ASSETS,
                    payload: []
                })
                onHide()
                toastAssetsUpdate(true)
                dispatch(getAllAssetsNonProjectAction())
            }
        }
    }, [updateAssetsData])

    const cancel = () => {
        if (!AssetsId) {
            if (assets.file) {
                fileRemove(assets.file, '')
            }
            if (asssetArray.length) {
                asssetArray.map((ele) => fileRemove(ele.file, ""))
            }
        }
        onHide()
    }

    const onDelete = (id) => {
        let fileDelete = asssetArray.find((ele) => ele.id === id).file
        fileRemove(fileDelete, '')
        setAsssetArray(asssetArray.filter((ele) => ele.id !== id))
        toastAssetsDelete(true)
        setReject(false)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Assets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='mb-5'>
                    <Col lg={6} md={6}>
                        <div>
                            <div className=" users-form-info users-label">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter name"
                                    value={assets.name}
                                    onChange={(e) => { setAssets({ ...assets, name: e.target.value }); setFormErrors({ ...formErrors, name: '' }) }}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.name && <span style={{ color: "red" }}>{formErrors.name}</span>}
                        </div>
                    </Col>
                    {!AssetsId && <Col lg={5} md={6}>
                        <div>
                            <div className="users-form-info m-0">
                                <label style={{ fontWeight: "600" }}>File</label>
                                <div className="upload-file mt-2" onClick={() => UploadClick()}>
                                    <label className='upload-file-lable' >Upload here</label>
                                    <input
                                        type="file"
                                        className="form-control d-none"
                                        id='file'
                                        onChange={(e) => { setUploadFile(e.target.files[0]) }}
                                    />
                                </div>
                                {assets.file ?
                                    <label style={{ color: "green", display: "flex" }}>
                                        <img src={Viewicon} alt="View" width="25" height="27" style={{ marginRight: "5px", cursor: 'pointer' }} onClick={() => fileClick(assets.file)} />
                                        <img src={removeImg} alt="View" width="20" height="20" style={{ marginRight: "5px", cursor: 'pointer' }} onClick={() => fileRemove(assets.file, "removeFile")} />
                                        Uploaded File : {assets.file.split('/')[1]}</label>
                                    :
                                    formErrors?.file && <label style={{ color: "red" }}>{formErrors.file}</label>}
                            </div>
                        </div>
                    </Col>}
                    {AssetsId && <Col lg={5}>
                        <div className="users-form-info">
                            <div className="users-label">
                                <label>File</label>
                                <div className="position-relative select-icon" >
                                    <img src={View} className="rotateArrow" alt="" onClick={() => fileClick(assets.file)} />
                                    <input
                                        type="text"
                                        className="form-control pe-5"
                                        id="exampleInputPassword1"
                                        value={assets?.file?.split('/')[1]}
                                        autoComplete="off"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>}
                    {!AssetsId && <Col lg={1} className='align-self-center mt-4'>
                        <div>
                            <button className='adduser-btn' onClick={() => Add()}>Add</button>
                        </div>
                    </Col>}
                </Row>
                {asssetArray.length > 0 && asssetArray.map((item, i) => {
                    return (
                        <Row className='mt-3' key={i}>
                            <Col lg={1}>
                                <div>{i + 1}</div>
                            </Col>
                            <Col lg={3}>
                                <div>{item.name}</div>
                            </Col>
                            <Col lg={6}>
                                <div>{item.file.split('/')[1]}</div>
                            </Col>
                            <Col lg={2}>
                                <img src={viewImg} onClick={() => fileClick(item.file)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
                                <img src={removeImg} onClick={() => Delete(item.id)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' />
                            </Col>
                        </Row>
                    )
                })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button className='adduser-btn' onClick={() => AssetsId ? Edit() : Save()} >{AssetsId ? "Edit" : "Save"}</Button>
                <button className='cancel-button' onClick={() => cancel()}>Cancel</button>
            </Modal.Footer>
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </Modal>
    )
}

export default AssetsNonProject