import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectByIdAction } from '../../redux/actions/projectAction'
import { dateFormate } from '../../helper/utils'
import { getPurchaseRequestByProjectAction } from '../../redux/actions/purchaseRequest'
import CopyImg from '../../assets/image/copy.png'
import { toast } from 'react-toastify'
import usePaginationHooks from '../../Hooks/paginationHooks'

const ViewProject = ({ show, onHide, projectId }) => {
    const dispatch = useDispatch()

    const [project, setProject] = useState({
        projectName: '',
        location: '',
        contactPersonName: '',
        contactPersonNumber: '',
        startDate: '',
        estimatedDelivery: '',
        contractor: '',
        finalQuatation: '',
        customer: '',
        workOrder: '',
        projectManager: '',
        status: '',
        file: '',
    })
    const [followUp, setFollowUp] = useState([
        {
            step: '',
            status: '',
            estimatedDelivery: '',
            description: '',
            deliveredOn: '',
        }
    ])
    const [PR, setPR] = useState([
        {
            status: '',
            item: [
                {
                    name: '',
                    unit: '',
                    priority: '',
                    reqQuantity: '',
                    deliveredQuantity: '',
                    desc: '',
                    make: '',
                }
            ]
        }
    ])

    const getProjectData = useSelector((state) => state.get_Project_By_Id.getProjectById)
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer?.data)
    const getAllContractor = useSelector((state) => state.get_All_Contractor.getAllContractor?.data)
    const projectManagerData = useSelector((state) => state.get_Project_Manager.getProjectManager?.data)
    const purchaseRequestByProjectId = useSelector((state) => state.get_Purchase_Request_By_Project.getPurchaseRequestByProject)

    const copyLinkSuccess = () => toast.success('Project report link copied.');
    const onCopy = (id) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/project-report?id=${id}`);
        copyLinkSuccess(true)
    }

    useEffect(() => {
        if (projectId) {
            dispatch(getProjectByIdAction(projectId))
            dispatch(getPurchaseRequestByProjectAction(projectId))
        }
    }, [projectId])

    useEffect(() => {
        if (getProjectData && getProjectData.data && projectId) {
            setProject({
                projectName: getProjectData.data.partyName,
                location: getProjectData.data.location,
                contactPersonName: getProjectData.data.contactPersonName,
                contactPersonNumber: getProjectData.data.contactPersonNumber,
                startDate: getProjectData.data.startDate ? new Date(getProjectData.data.startDate) : '',
                estimatedDelivery: getProjectData.data.estimatedDelivery ? new Date(getProjectData.data.estimatedDelivery) : '',
                contractor: getProjectData.data.contractor ? selectValue(getProjectData.data.contractor, 'contractor') : '',
                finalQuatation: getProjectData.data.finalQuatation,
                customer: getProjectData.data.customer ? selectValue(getProjectData.data.customer, "customer") : '',
                workOrder: getProjectData.data.workOrder,
                projectManager: getProjectData.data.projectManager ? selectValue(getProjectData.data.projectManager, 'projectManager') : '',
                status: getProjectData.data.status,
                file: getProjectData.data.file
            })
            setFollowUp(getProjectData.data.projectDetail.map((item) => {
                return {
                    step: item.step,
                    status: item.status,
                    estimatedDelivery: new Date(item.estimatedDelivery),
                    description: item.description,
                    deliveredOn: new Date(item.deliveredOn),
                }
            }))
        }
    }, [getProjectData])

    useEffect(() => {
        if (purchaseRequestByProjectId && purchaseRequestByProjectId.data) {
            setPR(purchaseRequestByProjectId.data.map((ele) => {
                return {
                    status: ele.status,
                    item: ele.items.map((item) => {
                        return {
                            name: item.productName,
                            unit: item.unit,
                            priority: item.priority,
                            reqQuantity: item.reqQuantity,
                            deliveredQuantity: item.deliveredQuantity,
                            desc: item.desc,
                            make: item.make,
                        }
                    })
                }
            }))
        }
    }, [purchaseRequestByProjectId])

    const selectValue = (value, name) => {
        if (name === "contractor") {
            return getAllContractor?.filter((ele) => value.includes(ele._id))
        } else if (name === "customer") {
            return customerData?.find((ele) => ele._id === value)?.accountName || ""
        } else if (name === "projectManager") {
            return projectManagerData?.find((ele) => ele._id === value)?.name || ""
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Project
                    <img src={CopyImg} onClick={() => onCopy(projectId)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Copy project report link' alt='' />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                    <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                    <hr></hr>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-project-modal'>
                                <tr>
                                    <td>Project Name</td>
                                    <td>{project.projectName}</td>
                                </tr>
                                <tr>
                                    <td>Contact Person Name</td>
                                    <td>{project.contactPersonName}</td>
                                </tr>
                                <tr>
                                    <td>Contact Person Number</td>
                                    <td>{project.contactPersonNumber}</td>
                                </tr>
                                <tr>
                                    <td>Contractor</td>
                                    <td>{project.contractor.length ? project.contractor?.map((ele) => ele.name).join(', ') : ''}</td>
                                </tr>
                                <tr>
                                    <td>Customer</td>
                                    <td>{project.customer}</td>
                                </tr>
                                <tr>
                                    <td>Project Manager</td>
                                    <td>{project.projectManager}</td>
                                </tr>
                                <tr>
                                    <td>Measurement Sheet</td>
                                    <td>{project.file.length > 0 ? project.file.map((ele, i) => <span key={i}>{ele.split('/')[1]} <br /></span>) : ''}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-project-modal'>
                                <tr>
                                    <td>Location</td>
                                    <td>{project.location}</td>
                                </tr>
                                <tr>
                                    <td>Start Date</td>
                                    <td>{project.startDate && dateFormate(project.startDate, 'Date')}</td>
                                </tr>
                                <tr>
                                    <td>Estimated Delivery Date</td>
                                    <td>{project.estimatedDelivery && dateFormate(project.estimatedDelivery, 'Date')}</td>
                                </tr>
                                {/* <tr>
                                    <td>Final Quotation</td>
                                    <td>{project.finalQuatation.length > 0 ? project.finalQuatation.map((ele, i) => <span key={i}>{ele.split('/')[1]}<br /></span>) : ''}</td>
                                </tr> */}
                                <tr>
                                    <td>Work Order File</td>
                                    <td>{project.workOrder.length > 0 ? project.workOrder.map((ele, i) => <span key={i}>{ele.split('/')[1]} <br /></span>) : ''}</td>
                                </tr>
                                <tr>
                                    <td>Project Status</td>
                                    <td>{project.status}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col lg={12} className="">
                        <h2 className="Steps-header">Steps</h2>
                    </Col>
                </Row>
                <table className='table viewer-project-steps-table'>
                    <tr>
                        <th>Sr</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Estimated Delivery</th>
                        <th>Delivered On</th>
                        <th>Description</th>
                    </tr>
                    {followUp.map((item, i) =>
                        <tr key={i}>
                            <td className='vertical-top'>{i + 1}</td>
                            <td className='vertical-top'>{item.step}</td>
                            <td className='vertical-top'>{item.status}</td>
                            <td className='vertical-top'>{dateFormate(project.estimatedDelivery, 'Date')}</td>
                            <td className='vertical-top'>{dateFormate(project.deliveredOn, 'Date')}</td>
                            <td className='vertical-top'>{item.description}</td>
                        </tr>

                    )}

                </table>
                {PR.length > 0 && <Row>
                    <Col lg={12} className="mt-3">
                        <h2 className="Steps-header">Purchase Request</h2>
                    </Col>
                </Row>}
                {PR.map((el, i) => <>
                    <table className='status-table' key={i}>
                        <tr>
                            <th>Status:</th>
                            <td>{el.status}</td>
                        </tr>
                    </table>
                    <table className='table'>

                        {el.item.map((ele, ind) =>
                            <>
                                <tr>
                                    <th>Sr</th>
                                    <td>{ind + 1}</td>
                                    <th>Name</th>
                                    <td colSpan={12}>{ele.name}</td>
                                </tr>
                                <tr>
                                    <th>Unit</th>
                                    <td style={{ width: "10%" }}>{ele.unit}</td>
                                    <th>Priority</th>
                                    <td>{ele.priority}</td>
                                    <th>Req Qty</th>
                                    <td>{ele.reqQuantity}</td>
                                    <th>Delv Qty</th>
                                    <td>{ele.deliveredQuantity}</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td colSpan={12}>{ele.desc}</td>
                                </tr>
                                <tr>
                                    <th>Make</th>
                                    <td colSpan={12}>{ele.make}</td>
                                </tr>
                                <br />
                            </>
                        )}
                    </table>
                </>
                )}
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewProject