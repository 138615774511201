import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProductAction, getProductByIdAction } from '../../redux/actions/productAction'

const ViewProduct = ({ show, onHide, productId }) => {

    const dispatch = useDispatch()

    const [product, setProduct] = useState({
        productName: '',
        productCode: '',
        hsnsacCode: '',
        retailSalePrice: '',
        buyingPrice: '',
        gst: '',
        unitOfMeasurement: '',
        openingStock: '',
        labourCharge: '',
        minQuntity: '',
    })
    const getProductByIdData = useSelector((state) => state.get_Product_By_Id.getProductById)

    useEffect(() => {
        if (productId) {
            dispatch(getProductByIdAction(productId))
        }
    }, [productId])

    useEffect(() => {
        if (getProductByIdData && getProductByIdData.data && productId) {
            setProduct({
                productName: getProductByIdData.data.productName,
                productCode: getProductByIdData.data.productCode,
                hsnsacCode: getProductByIdData.data.hsnsacCode,
                retailSalePrice: getProductByIdData.data.retailSalePrice,
                buyingPrice: getProductByIdData.data.buyingPrice,
                gst: getProductByIdData.data.gst,
                unitOfMeasurement: getProductByIdData.data.unitOfMeasurement,
                openingStock: getProductByIdData.data.openingStock,
                labourCharge: getProductByIdData.data.labourCharge,
                minQuntity: getProductByIdData.data.minQuntity,
            })
        }
    }, [getProductByIdData])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Product Name</td>
                                    <td>{product?.productName}</td>
                                </tr>
                                <tr>
                                    <td>Product Code</td>
                                    <td>{product?.productCode}</td>
                                </tr>
                                <tr>
                                    <td>HSN/SAC Code</td>
                                    <td>{product?.hsnsacCode}</td>
                                </tr>
                                <tr>
                                    <td>UOM</td>
                                    <td>{product?.unitOfMeasurement}</td>
                                </tr>
                                <tr>
                                    <td>GST</td>
                                    <td>{product?.gst}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Retail Sale Price</td>
                                    <td>{product?.retailSalePrice}</td>
                                </tr>
                                <tr>
                                    <td>Buying Price</td>
                                    <td>{product?.buyingPrice}</td>
                                </tr>
                                <tr>
                                    <td>Labour Charge</td>
                                    <td>{product?.labourCharge}</td>
                                </tr>
                                <tr>
                                    <td>Opening Stock</td>
                                    <td>{product?.openingStock}</td>
                                </tr>
                                <tr>
                                    <td>Minimum Quntity</td>
                                    <td>{product?.minQuntity}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewProduct