import { ADD_FINAL_MATERIAL, ADD_FINAL_MATERIAL_ERROR, ADD_FINAL_MATERIAL_LOADING, ADD_FINAL_MATERIAL_SUB_PRODUCT, ADD_FINAL_MATERIAL_SUB_PRODUCT_ERROR, ADD_FINAL_MATERIAL_SUB_PRODUCT_LOADING, DELETE_FINAL_MATERIAL, DELETE_FINAL_MATERIAL_ERROR, DELETE_FINAL_MATERIAL_LOADING, EDIT_FINAL_MATERIAL, EDIT_FINAL_MATERIAL_ERROR, EDIT_FINAL_MATERIAL_LOADING, GET_ALL_FINAL_MATERIAL, GET_ALL_FINAL_MATERIAL_ERROR, GET_ALL_FINAL_MATERIAL_LOADING, GET_FINAL_MATERIAL_BY_ID, GET_FINAL_MATERIAL_BY_ID_ERROR, GET_FINAL_MATERIAL_BY_ID_LOADING, GET_FINAL_MATERIAL_BY_PARANT_ID, GET_FINAL_MATERIAL_BY_PARANT_ID_ERROR, GET_FINAL_MATERIAL_BY_PARANT_ID_LOADING, GET_FINAL_MATERIAL_BY_PROJECT, GET_FINAL_MATERIAL_BY_PROJECT_ERROR, GET_FINAL_MATERIAL_BY_PROJECT_LOADING } from "../type"

const addFinalMaterialData = {
    loading: false,
    addFinalMaterial: [],
    error: '',
};
const addFinalMaterialSubProductData = {
    loading: false,
    addFinalMaterialSubProduct: [],
    error: '',
};

const getFinalMaterialByIdData = {
    loading: false,
    getFinalMaterialById: [],
    error: '',
};
const getFinalMaterialByParantIdData = {
    loading: false,
    getFinalMaterialByParantId: [],
    error: '',
};

const getAllFinalMaterialData = {
    loading: false,
    getAllFinalMaterial: [],
    error: '',
}

const editFinalMaterialData = {
    loading: false,
    editFinalMaterial: [],
    error: '',
}

const deleteFinalMaterialData = {
    loading: false,
    deleteFinalMaterial: [],
    error: '',
}

const getFinalMaterialByProjectData = {
    loading: false,
    getFinalMaterialByProject: [],
    error: '',
}

export const addFinalMaterialReducer = (state = addFinalMaterialData, action) => {
    switch (action.type) {
        case ADD_FINAL_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_FINAL_MATERIAL:
            return {
                ...state,
                addFinalMaterial: action.payload,
            };
        case ADD_FINAL_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
export const addFinalMaterialSubProductReducer = (state = addFinalMaterialSubProductData, action) => {
    switch (action.type) {
        case ADD_FINAL_MATERIAL_SUB_PRODUCT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_FINAL_MATERIAL_SUB_PRODUCT:
            return {
                ...state,
                addFinalMaterialSubProduct: action.payload,
            };
        case ADD_FINAL_MATERIAL_SUB_PRODUCT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getFinalMaterialByIdReducer = (state = getFinalMaterialByIdData, action) => {
    switch (action.type) {
        case GET_FINAL_MATERIAL_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_FINAL_MATERIAL_BY_ID:
            return {
                ...state,
                getFinalMaterialById: action.payload,
            };
        case GET_FINAL_MATERIAL_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
export const getFinalMaterialByParantIdReducer = (state = getFinalMaterialByParantIdData, action) => {
    switch (action.type) {
        case GET_FINAL_MATERIAL_BY_PARANT_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_FINAL_MATERIAL_BY_PARANT_ID:
            return {
                ...state,
                getFinalMaterialByParantId: action.payload,
            };
        case GET_FINAL_MATERIAL_BY_PARANT_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllFinalMaterialReducer = (state = getAllFinalMaterialData, action) => {
    switch (action.type) {
        case GET_ALL_FINAL_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_FINAL_MATERIAL:
            return {
                ...state,
                getAllFinalMaterial: action.payload,
            };
        case GET_ALL_FINAL_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editFinalMaterialReducer = (state = editFinalMaterialData, action) => {
    switch (action.type) {
        case EDIT_FINAL_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_FINAL_MATERIAL:
            return {
                ...state,
                editFinalMaterial: action.payload,
            };
        case EDIT_FINAL_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteFinalMaterialReducer = (state = deleteFinalMaterialData, action) => {
    switch (action.type) {
        case DELETE_FINAL_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_FINAL_MATERIAL:
            return {
                ...state,
                deleteFinalMaterial: action.payload,
            };
        case DELETE_FINAL_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getFinalMaterialByProjectReducer = (state = getFinalMaterialByProjectData, action) => {
    switch (action.type) {
        case GET_FINAL_MATERIAL_BY_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_FINAL_MATERIAL_BY_PROJECT:
            return {
                ...state,
                getFinalMaterialByProject: action.payload,
            };
        case GET_FINAL_MATERIAL_BY_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}