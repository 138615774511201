import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fileRemoveAction, fileUploadAction } from '../../redux/actions/generalAction';
import Viewicon from '../../assets/image/view.png'
import { API } from '../../config/API/api.config';
import { v4 as uuidv4 } from "uuid";
import removeImg from '../../assets/image/delete-red.png'
import viewImg from '../../assets/image/eye.svg'
import { addAssetsAction, deleteAssetsAction, editAssetsAction, getAssetsByProjectAction } from '../../redux/actions/assetsAction';
import { useLocation } from 'react-router-dom';
import { ADD_ASSETS, DELETE_ASSETS, EDIT_ASSETS, FILE_UPLOAD, GET_ASSETS_BY_PROJECT } from '../../redux/type';
import { toast } from 'react-toastify';
import editIcon from '../../assets/image/edit.svg';
import View from '../../assets/image/eye.svg'
import SweetAlert from 'react-bootstrap-sweetalert';

const AssetsProject = ({ show, onHide }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const projctId = queryParams.get("projectId")

    const [projectId, setProjectId] = useState('')
    const [asssetArray, setAsssetArray] = useState([])
    const [assets, setAssets] = useState({
        id: uuidv4(),
        name: "",
        file: "",
        project: projctId ? projctId : '',
        isProject: true,
    })
    const [reject, setReject] = useState()
    const [paramsValue, setParamsValue] = useState()
    const [listTog, setListTog] = useState('')
    const [editTog, setEditTog] = useState(false)
    const [editStateTog, setEditStateTog] = useState(false)
    const [uploadFile, setUploadFile] = useState(undefined)
    const [formErrors, setFormErrors] = useState()
    const toastAssetsAdd = () => toast.success('Assets added successfully')
    const toastAssetsUpdate = () => toast.success('Assets updated successfully')
    const toastAssetsDelete = () => toast.success('Assets deleted successfully')
    const fileData = useSelector((state) => state.file_Upload.fileUpload);
    const getAssetsByProjectData = useSelector((state) => state.get_Assets_By_Project.getAssetsByProject)
    const addAssetsData = useSelector((state) => state.add_Assets.addAssets)
    const deleteAssetsData = useSelector((state) => state.delete_Assets.deleteAssets)
    const updateAssetsData = useSelector((state) => state.edit_Assets.editAssets)

    useEffect(() => {
        if (projctId) {
            setProjectId(projctId)
            dispatch(getAssetsByProjectAction(projctId))
        }
    }, [projctId])

    useEffect(() => {
        if (uploadFile) {
            if (assets.file) {
                fileRemove(assets.file, '')
            }
            let formData = new FormData();
            formData.append("files", uploadFile);
            dispatch(fileUploadAction(formData));
        }
    }, [uploadFile])

    useEffect(() => {
        if (fileData) {
            setAssets({ ...assets, file: fileData.url })
        }
    }, [fileData])

    const fileRemove = (fileName, name) => {
        let body = {
            file: fileName?.split('/')[1]
        }
        dispatch(fileRemoveAction(body))
        if (name === "removeFile") {
            setAssets({ ...assets, file: "" })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        if (!assets.name) {
            error.name = "Please enter name"
            flag = true
        }
        if (!assets.file) {
            error.file = "Please select file"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const Add = () => {
        if (validation()) {
            return
        }
        let temp = [...asssetArray]
        temp.push(assets)
        setAsssetArray(temp)
        setAssets({
            id: uuidv4(),
            name: "",
            file: "",
            project: projectId,
            isProject: true,
        })
        dispatch({
            type: FILE_UPLOAD,
            payload: []
        })
    }

    const Save = () => {
        if (asssetArray.length) {
            let body = asssetArray.map((ele) => {
                return {
                    name: ele.name,
                    file: ele.file,
                    project: ele.project,
                    isProject: ele.isProject,
                }
            })
            dispatch(addAssetsAction(body))
        } else if (getAssetsByProjectData && getAssetsByProjectData.data && getAssetsByProjectData.data.length) {
            dispatch({
                type: GET_ASSETS_BY_PROJECT,
                payload: []
            })
            toastAssetsAdd()
            onHide()
        }
    }

    useEffect(() => {
        if (addAssetsData && addAssetsData.message === "Assets created successfully.") {
            dispatch({
                type: ADD_ASSETS,
                payload: []
            })
            toastAssetsAdd(true)
            onHide()
        }
    }, [addAssetsData])

    const UploadClick = () => {
        document.getElementById('file').click()
    }

    const fileClick = (name) => {
        window.open(`${API.endpoint}/${name}`, "_blank")
    }

    const Delete = (id) => {
        setParamsValue(id)
        setReject(true)
        setListTog('')
    }

    const DeleteForList = (id) => {
        setParamsValue(id)
        setReject(true)
        setListTog('delete')
    }

    useEffect(() => {
        if (deleteAssetsData && deleteAssetsData.data) {
            if (deleteAssetsData.data.message === "Assets deleted successfully.") {
                dispatch(getAssetsByProjectAction(projectId))
                dispatch({
                    type: DELETE_ASSETS,
                    payload: []
                })
                toastAssetsDelete(true)
            }
        }
    }, [deleteAssetsData])

    const Edit = (item) => {
        setEditTog(true)
        if (assets.file) {
            fileRemove(assets.file, 'removeFile')
        }
        setAssets({
            id: item._id,
            name: item.name,
            file: item.file,
            project: item.project,
            isProject: item.isProject,
        })
    }

    const editSingleData = () => {
        if (validation()) {
            return
        }
        dispatch(editAssetsAction(assets))
    }

    useEffect(() => {
        if (updateAssetsData && updateAssetsData.data) {
            if (updateAssetsData.data.message === "Assets updated successfully.") {
                dispatch({
                    type: EDIT_ASSETS,
                    payload: []
                })
                toastAssetsUpdate(true)
                dispatch(getAssetsByProjectAction(projectId))
                setAssets({
                    id: uuidv4(),
                    name: "",
                    file: "",
                    project: projectId,
                    isProject: true,
                })
                setEditTog(false)
            }
        }
    }, [updateAssetsData])

    const EditState = (item) => {
        setEditStateTog(true)
        if (assets.file) {
            fileRemove(assets.file, 'removeFile')
        }
        setAssets({
            id: item.id,
            name: item.name,
            file: item.file,
            project: item.project,
            isProject: item.isProject,
        })
    }

    const EditInState = () => {
        setAsssetArray(asssetArray.map((ele) => ele.id === assets.id ? assets : ele))
        setEditStateTog(false)
        toastAssetsUpdate(true)
        setAssets({
            id: uuidv4(),
            name: "",
            file: "",
            project: projectId,
            isProject: true,
        })
    }

    const cancel = () => {
        if (assets.file) {
            fileRemove(assets.file, '')
        }
        if (asssetArray.length) {
            asssetArray.map((ele) => fileRemove(ele.file, ''))
        }
        onHide()
    }

    const onDelete = (id) => {
        if (listTog === 'delete') {
            dispatch(deleteAssetsAction(id))
        } else {
            let filedelete = asssetArray.find((ele) => ele.id === id).file
            fileRemove(filedelete, '')
            setAsssetArray(asssetArray.filter((ele) => ele.id !== id))
            toastAssetsDelete(true)
        }
        setReject(false)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Assets</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='mb-5'>
                    <Col lg={5}>
                        <div className="mt-3">
                            <div className=" users-form-info users-label">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter name"
                                    value={assets.name}
                                    onChange={(e) => { setAssets({ ...assets, name: e.target.value }); setFormErrors({ ...formErrors, name: '' }) }}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.name && <span style={{ color: "red" }}>{formErrors.name}</span>}
                        </div>
                    </Col>
                    {(!editTog && !editStateTog) && <Col lg={5}>
                        <div className="mt-3">
                            <div className="users-form-info m-0">
                                <label style={{ fontWeight: "600" }}>File</label>
                                <div className="upload-file" onClick={() => UploadClick()}>
                                    <label className='upload-file-lable' >Upload here</label>
                                    <input
                                        type="file"
                                        className="form-control d-none"
                                        id='file'
                                        onChange={(e) => { setUploadFile(e.target.files[0]) }}
                                    />
                                </div>
                                {assets.file ?
                                    <label style={{ color: "green", display: "flex" }}>
                                        <img src={Viewicon} alt="View" width="20" height="20" style={{ marginRight: "5px", cursor: 'pointer' }} onClick={() => fileClick(assets.file)} />
                                        <img src={removeImg} alt="View" width="20" height="20" style={{ marginRight: "5px", cursor: 'pointer' }} onClick={() => fileRemove(assets.file, "removeFile")} />
                                        Uploaded File : {assets?.file?.split('/')[1]}</label>
                                    :
                                    formErrors?.file && <label style={{ color: "red" }}>{formErrors.file}</label>}
                            </div>
                        </div>
                    </Col>}
                    {(editTog || editStateTog) && <Col lg={5}>
                        <div className="users-form-info mt-3">
                            <div className="users-label">
                                <label>File</label>
                                <div className="position-relative select-icon" >
                                    <img src={View} className="rotateArrow" alt="" onClick={() => fileClick(assets.file)} />
                                    <input
                                        type="text"
                                        className="form-control pe-5"
                                        id="exampleInputPassword1"
                                        value={assets?.file?.split('/')[1]}
                                        autoComplete="off"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>}
                    <Col lg={2}>
                        <div className='mt-5'>
                            {!editStateTog && <button className='adduser-btn' onClick={() => editTog ? editSingleData() : Add()}>{editTog ? "Edit" : "Add"}</button>}
                            {editStateTog && <button className='adduser-btn' onClick={() => EditInState()}>Edit</button>}
                        </div>
                    </Col>
                </Row>
                {
                    getAssetsByProjectData && getAssetsByProjectData.data && getAssetsByProjectData.data.map((item, index) => {
                        return (
                            <Row className='mt-3' key={index}>
                                <Col lg={1}>
                                    <div>{index + 1}</div>
                                </Col>
                                <Col lg={3}>
                                    <div>{item.name}</div>
                                </Col>
                                <Col lg={6}>
                                    <div>{item.file.split('/')[1]}</div>
                                </Col>
                                <Col lg={2}>
                                    <img src={viewImg} onClick={() => fileClick(item.file)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
                                    <img src={editIcon} onClick={() => Edit(item)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Edit' alt='' />
                                    <img src={removeImg} onClick={() => DeleteForList(item._id)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' />
                                </Col>
                            </Row>
                        )
                    })
                }
                {asssetArray.length > 0 && asssetArray.map((item, i) => {
                    return (
                        <Row className='mt-3' key={i}>
                            <Col lg={1}>
                                <div>{getAssetsByProjectData?.data?.length + i + 1}</div>
                            </Col>
                            <Col lg={3}>
                                <div>{item.name}</div>
                            </Col>
                            <Col lg={6}>
                                <div>{item.file.split('/')[1]}</div>
                            </Col>
                            <Col lg={2}>
                                <img src={viewImg} onClick={() => fileClick(item.file)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
                                <img src={editIcon} onClick={() => EditState(item)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Edit' alt='' />
                                <img src={removeImg} onClick={() => Delete(item.id)} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' />
                            </Col>
                        </Row>
                    )
                })
                }
                {
                    (getAssetsByProjectData && getAssetsByProjectData?.data?.length < 1 && asssetArray.length < 1) && <label className='no-data-found'>No assets found</label>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button className='adduser-btn' onClick={() => Save()} >Save</Button>
                <button className='cancel-button' onClick={() => cancel()}>Cancel</button>
            </Modal.Footer>
            {
                reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </Modal>
    )
}

export default AssetsProject