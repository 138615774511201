import { GET_ALL_PROJECT_DETAILS, GET_ALL_PROJECT_DETAILS_ERROR, GET_ALL_PROJECT_DETAILS_LOADING, GET_PROJECT_DETAILS_BY_ID, GET_PROJECT_DETAILS_BY_ID_ERROR, GET_PROJECT_DETAILS_BY_ID_LOADING, GET_PROJECT_DETAILS_BY_PROJECT_ID, GET_PROJECT_DETAILS_BY_PROJECT_ID_ERROR, GET_PROJECT_DETAILS_BY_PROJECT_ID_LOADING } from "../type";

const getAllProjectDetailsData = {
    loading:false,
    getAllProjectDetails:[],
    error:'',
}

const getProjectDetailsByIdData = {
    loading:false,
    getProjectDetailsById:[],
    error:'',
}

const getProjectDetailsByProjectIdData = {
    loading:false,
    getProjectDetailsByProjectId:[],
    error:'',
}

export const getAllProjectDetailsReducer = (state = getAllProjectDetailsData, action) => {
    switch (action.type) {
        case GET_ALL_PROJECT_DETAILS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_PROJECT_DETAILS:
            return {
                ...state,
                getAllProjectDetails: action.payload,
            };
        case GET_ALL_PROJECT_DETAILS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProjectDetailsByIdReducer = (state = getProjectDetailsByIdData, action) => {
    switch (action.type) {
        case GET_PROJECT_DETAILS_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PROJECT_DETAILS_BY_ID:
            return {
                ...state,
                getProjectDetailsById: action.payload,
            };
        case GET_PROJECT_DETAILS_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getProjectDetailsByProjectIdReducer = (state = getProjectDetailsByProjectIdData, action) => {
    switch (action.type) {
        case GET_PROJECT_DETAILS_BY_PROJECT_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PROJECT_DETAILS_BY_PROJECT_ID:
            return {
                ...state,
                getProjectDetailsByProjectId: action.payload,
            };
        case GET_PROJECT_DETAILS_BY_PROJECT_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}