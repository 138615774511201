import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_TERM_AND_CONDITION, ADD_TERM_AND_CONDITION_ERROR, ADD_TERM_AND_CONDITION_LOADING, DELETE_TERM_AND_CONDITION, DELETE_TERM_AND_CONDITION_ERROR, DELETE_TERM_AND_CONDITION_LOADING, EDIT_TERM_AND_CONDITION, EDIT_TERM_AND_CONDITION_ERROR, EDIT_TERM_AND_CONDITION_LOADING, GET_ALL_TERM_AND_CONDITION, GET_ALL_TERM_AND_CONDITION_ERROR, GET_ALL_TERM_AND_CONDITION_LOADING, IS_LOADING } from "../type"

export const addTermAndConditionAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_TERM_AND_CONDITION_LOADING,
            payload: true
        })

        await ApiPost(`termAndCondition/add_term_and_consition`, body)
            .then((res) => {
                dispatch({
                    type: ADD_TERM_AND_CONDITION,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_TERM_AND_CONDITION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_TERM_AND_CONDITION_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_TERM_AND_CONDITION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getTermAndConditionAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_TERM_AND_CONDITION_LOADING,
            payload: true
        })

        await ApiGet(`termAndCondition/get_all_term_and_condition`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_TERM_AND_CONDITION,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_TERM_AND_CONDITION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_TERM_AND_CONDITION_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_TERM_AND_CONDITION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editTermAndConditionAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_TERM_AND_CONDITION_LOADING,
            payload: true
        })

        await ApiPut(`termAndCondition/edit_term_and_condition`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_TERM_AND_CONDITION,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_TERM_AND_CONDITION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_TERM_AND_CONDITION_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_TERM_AND_CONDITION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}
export const deleteTermAndConditionAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_TERM_AND_CONDITION_LOADING,
            payload: true
        })

        await ApiDelete(`termAndCondition/delete_term_and_condition/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_TERM_AND_CONDITION,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_TERM_AND_CONDITION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_TERM_AND_CONDITION_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_TERM_AND_CONDITION_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}