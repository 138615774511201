import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import { getEnquiryByIdAction } from '../../redux/actions/enquiryAction'

const ViewSales = ({ show, onHide, saleId }) => {

    const dispatch = useDispatch()

    const [sale, setSale] = useState({
        partyName: '',
        email: '',
        location: '',
        contactPersonName: '',
        contactPersonNumber: '',
        status: '',
        visitDate: '',
        quotationNumber: '',
        workOrder: '',
    })
    const [followUp, setFollowUp] = useState([
        {
            date: "",
            status: "",
            description: "",
            remark: "",
            quoationUpdate: "",
            id: '',
        }
    ])
    const getSaleData = useSelector((state) => state.get_Enquiry_By_Id.getEnquiryById)

    useEffect(() => {
        if (saleId) {
            dispatch(getEnquiryByIdAction(saleId))
        }
    }, [saleId])


    useEffect(() => {
        if (getSaleData && getSaleData.data && saleId) {
            setSale({
                partyName: getSaleData.data.partyName,
                email: getSaleData.data.email,
                location: getSaleData.data.location,
                contactPersonName: getSaleData.data.contactPersonName,
                contactPersonNumber: getSaleData.data.contactPersonNumber,
                visitDate: new Date(getSaleData.data.visitDate),
                quotationNumber: getSaleData.data.quotationNumber,
                workOrder: getSaleData.data.workOrder,
                status: getSaleData.data.status
            })
            setFollowUp(getSaleData.data.nextFollowups.map((item) => {
                return {
                    date: new Date(item.date),
                    status: item.status,
                    description: item.description,
                    remark: item.remark,
                    quoationUpdate: item.quoationUpdate,
                    id: item.id,
                }
            }))
        }
    }, [getSaleData])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Sale</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                    <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                    <hr></hr>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-modal'>
                                <tr>
                                    <td>Party Name</td>
                                    <td>{sale.partyName}</td>
                                </tr>
                                <tr>
                                    <td>Contact Person Name</td>
                                    <td>{sale.contactPersonName}</td>
                                </tr>
                                <tr>
                                    <td>Contact Person Number</td>
                                    <td>{sale.contactPersonNumber}</td>
                                </tr>
                                <tr>
                                    <td>Quotation Number</td>
                                    <td>{sale.quotationNumber}</td>
                                </tr>
                                <tr>
                                    <td>Work Order</td>
                                    <td>{sale.workOrder && sale.workOrder.split('/')[1]}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-modal'>
                                <tr>
                                    <td>E-mail</td>
                                    <td>{sale.email}</td>
                                </tr>
                                <tr>
                                    <td>Location</td>
                                    <td>{sale.location}</td>
                                </tr>
                                <tr>
                                    <td>Visit Date</td>
                                    <td>{moment(sale.visitDate).format("DD MMM YYYY")}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>{sale.status}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="">
                        <h2 className="Steps-header">Steps</h2>
                    </Col>
                </Row>
                <table className='table viewer-steps-table'>
                    <tr>
                        <th>Sr</th>
                        <th>Date</th>
                        <th>Status</th>
                        {/* <th>Quotation Update</th> */}
                        <th>Description</th>
                        <th>Remark </th>
                    </tr>
                    {followUp.map((item, i) =>
                        <tr key={i}>
                            <td className='vertical-top'>{i + 1}</td>
                            <td className='vertical-top'>{moment(item.date).format("DD MMM YYYY")}</td>
                            <td className='vertical-top'>{item.status}</td>
                            {/* <td className='vertical-top'>{item.quoationUpdate && item.quoationUpdate.split('/')[1]}</td> */}
                            <td className='vertical-top'>{item.description}</td>
                            <td className='vertical-top'>{item.remark}</td>
                        </tr>
                    )}

                </table>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewSales