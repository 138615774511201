import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_BANK, ADD_BANK_ERROR, ADD_BANK_LOADING, DELETE_BANK, DELETE_BANK_ERROR, DELETE_BANK_LOADING, EDIT_BANK, EDIT_BANK_ERROR, EDIT_BANK_LOADING, GET_ALL_BANK, GET_ALL_BANK_ERROR, GET_ALL_BANK_LOADING, GET_BANK_BY_ID, GET_BANK_BY_ID_ERROR, GET_BANK_BY_ID_LOADING, GET_BANK_DROP_DOWN, GET_BANK_DROP_DOWN_ERROR, GET_BANK_DROP_DOWN_LOADING, IS_LOADING } from "../type"

export const addBankAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_BANK_LOADING,
            payload: true
        })
        await ApiPost('bank/add_bank', body)
            .then((res) => {
                dispatch({
                    type: ADD_BANK,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: ADD_BANK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_BANK_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_BANK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getBankByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_BANK_BY_ID_LOADING,
            payload: true
        })
        await ApiGet(`bank/get_bank_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_BANK_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_BANK_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_BANK_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_BANK_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAllBankAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_BANK_LOADING,
            payload: true
        })
        await ApiGet(`bank/get_all_bank`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_BANK,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_BANK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_BANK_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_BANK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const editBankAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_BANK_LOADING,
            payload: true
        })
        await ApiPut(`bank/edit_bank`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_BANK,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: EDIT_BANK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_BANK_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_BANK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const deleteBankAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_BANK_LOADING,
            payload: true
        })
        await ApiDelete(`bank/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_BANK,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: DELETE_BANK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_BANK_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_BANK_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getBankDropDownAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_BANK_DROP_DOWN_LOADING,
            payload: true
        })
        await ApiGet(`bank/get_bank_drop_down`)
            .then((res) => {
                dispatch({
                    type: GET_BANK_DROP_DOWN,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_BANK_DROP_DOWN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_BANK_DROP_DOWN_ERROR,
            payload: err
        })

        dispatch({
            type: GET_BANK_DROP_DOWN_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}