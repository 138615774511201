import { ADD_NOC, ADD_NOC_ERROR, ADD_NOC_LOADING, DELETE_NOC, DELETE_NOC_ERROR, DELETE_NOC_LOADING, EDIT_NOC, EDIT_NOC_ERROR, EDIT_NOC_LOADING, GET_ALL_NOCS, GET_ALL_NOCS_ERROR, GET_ALL_NOCS_LOADING, GET_NOC_BY_ID, GET_NOC_BY_ID_ERROR, GET_NOC_BY_ID_LOADING } from "../type";

const addNocData = {
    loading: false,
    addNoc: [],
    error: '',
}

const getAllNocsData = {
    loading: false,
    getAllNocs: [],
    error: '',
}

const getNocByIdData = {
    loading: false,
    getNocById: [],
    error: '',
}

const editNocData = {
    loading: false,
    editNoc: [],
    error: '',
}
const deleteNocData = {
    loading: false,
    deleteNoc: [],
    error: '',
}

export const addNocReducer = (state = addNocData, action) => {
    switch (action.type) {
        case ADD_NOC_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_NOC:
            return {
                ...state,
                addNoc: action.payload,
            };
        case ADD_NOC_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllNocsReducer = (state = getAllNocsData, action) => {
    switch (action.type) {
        case GET_ALL_NOCS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_NOCS:
            return {
                ...state,
                getAllNocs: action.payload,
            };
        case GET_ALL_NOCS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getNocByIdReducer = (state = getNocByIdData, action) => {
    switch (action.type) {
        case GET_NOC_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_NOC_BY_ID:
            return {
                ...state,
                getNocById: action.payload,
            };
        case GET_NOC_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editNocReducer = (state = editNocData, action) => {
    switch (action.type) {
        case EDIT_NOC_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_NOC:
            return {
                ...state,
                editNoc: action.payload,
            };
        case EDIT_NOC_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
export const deleteNocReducer = (state = deleteNocData, action) => {
    switch (action.type) {
        case DELETE_NOC_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_NOC:
            return {
                ...state,
                deleteNoc: action.payload,
            };
        case DELETE_NOC_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}