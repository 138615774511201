import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'

const AuthLayout = ({ children }) => {
    const [showSidebar, setSidebar] = useState(true)
    const loading = useSelector((state) => state.loading.loading)
    useEffect(() => {
        if (window.innerWidth <= 768) {
            setSidebar(false)
        }
    }, [window.innerWidth])

    return (
        <>
            {loading ? <Loader /> : ''}
            <div className={`authlayout-dashborad ${showSidebar ? "authlayout-dashborad-1" : "1fr"}`}
            >
                {showSidebar &&
                    <div className='layout-siderbar'>
                        <Sidebar showSidebar={showSidebar} setSidebar={setSidebar} />
                    </div>}
                <div className="children-bg">
                    <Header showSidebar={showSidebar} setSidebar={setSidebar} />
                    {/* {loading ? <Loader /> : ''} */}
                    {children}

                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default AuthLayout
