export const IS_LOADING = "IS_LOADING"
export const IS_LOGIN = "IS_LOGIN"

export const LOGIN = "LOGIN"
export const LOGIN_LOADING = "LOGIN_LOADING"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const ADD_USER = "ADD_USER"
export const ADD_USER_LOADING = "ADD_USER_LOADING"
export const ADD_USER_ERROR = "ADD_USER_ERROR"

export const GET_USER_BY_ID = "GET_USER_BY_ID"
export const GET_USER_BY_ID_LOADING = "GET_USER_BY_ID_LOADING"
export const GET_USER_BY_ID_ERROR = "GET_USER_BY_ID_ERROR"

export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_LOADING = "EDIT_USER_LOADING"
export const EDIT_USER_ERROR = "EDIT_USER_ERROR"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_LOADING = "DELETE_USER_LOADING"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"

export const GET_ALL_USER = "GET_ALL_USER"
export const GET_ALL_USER_LOADING = "GET_ALL_USER_LOADING"
export const GET_ALL_USER_ERROR = "GET_ALL_USER_ERROR"

export const GET_PROJECT_MANAGER = "GET_PROJECT_MANAGER"
export const GET_PROJECT_MANAGER_LOADING = "GET_PROJECT_MANAGER_LOADING"
export const GET_PROJECT_MANAGER_ERROR = "GET_PROJECT_MANAGER_ERROR"



export const ADD_ENQUIRY = "ADD_ENQUIRY"
export const ADD_ENQUIRY_LOADING = "ADD_ENQUIRY_LOADING"
export const ADD_ENQUIRY_ERROR = "ADD_ENQUIRY_ERROR"

export const GET_ALL_ENQUIRY = "GET_ALL_ENQUIRY"
export const GET_ALL_ENQUIRY_LOADING = "GET_ALL_ENQUIRY_LOADING"
export const GET_ALL_ENQUIRY_ERROR = "GET_ALL_ENQUIRY_ERROR"

export const GET_ENQUIRY_BY_ID = "GET_ENQUIRY_BY_ID"
export const GET_ENQUIRY_BY_ID_LOADING = "GET_ENQUIRY_BY_ID_LOADING"
export const GET_ENQUIRY_BY_ID_ERROR = "GET_ENQUIRY_BY_ID_ERROR"

export const EDIT_ENQUIRY = "EDIT_ENQUIRY"
export const EDIT_ENQUIRY_LOADING = "EDIT_ENQUIRY_LOADING"
export const EDIT_ENQUIRY_ERROR = "EDIT_ENQUIRY_ERROR"

export const APPROVAL_FOR_ADMIN_ENQUIRY = "APPROVAL_FOR_ADMIN_ENQUIRY"
export const APPROVAL_FOR_ADMIN_ENQUIRY_LOADING = "PPROVAL_FOR_ADMIN_ENQUIRY_LOADING"
export const APPROVAL_FOR_ADMIN_ENQUIRY_ERROR = "APPROVAL_FOR_ADMIN_ENQUIRY_ERROR"

export const DELETE_ENQUIRY = "DELETE_ENQUIRY"
export const DELETE_ENQUIRY_LOADING = "DELETE_ENQUIRY_LOADING"
export const DELETE_ENQUIRY_ERROR = "DELETE_ENQUIRY_ERROR"

export const REJECT_ENQUIRY = "REJECT_ENQUIRY"
export const REJECT_ENQUIRY_LOADING = "REJECT_ENQUIRY_LOADING"
export const REJECT_ENQUIRY_ERROR = "REJECT_ENQUIRY_ERROR"



export const ADD_PROJECT = "ADD_PROJECT"
export const ADD_PROJECT_LOADING = "ADD_PROJECT_LOADING"
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR"

export const GET_ALL_PROJECT = "GET_ALL_PROJECT"
export const GET_ALL_PROJECT_LOADING = "GET_ALL_PROJECT_LOADING"
export const GET_ALL_PROJECT_ERROR = "GET_ALL_PROJECT_ERROR"

export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID"
export const GET_PROJECT_BY_ID_LOADING = "GET_PROJECT_BY_ID_LOADING"
export const GET_PROJECT_BY_ID_ERROR = "GET_PROJECT_BY_ID_ERROR"

export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_LOADING = "DELETE_PROJECT_LOADING"
export const DELETE_PROJECT_ERROR = "DELETE_PROJECT_ERROR"

export const EDIT_PROJECT = "EDIT_PROJECT"
export const EDIT_PROJECT_LOADING = "EDIT_PROJECT_LOADING"
export const EDIT_PROJECT_ERROR = "EDIT_PROJECT_ERROR"



export const ADD_PROJECT_DETAILS = "ADD_PROJECT_DETAILS"
export const ADD_PROJECT_DETAILS_LOADING = "ADD_PROJECT_DETAILS_LOADING"
export const ADD_PROJECT_DETAILS_ERROR = "ADD_PROJECT_DETAILS_ERROR"

export const GET_ALL_PROJECT_DETAILS = "GET_ALL_PROJECT_DETAILS"
export const GET_ALL_PROJECT_DETAILS_LOADING = "GET_ALL_PROJECT_DETAILS_LOADING"
export const GET_ALL_PROJECT_DETAILS_ERROR = "GET_ALL_PROJECT_DETAILS_ERROR"

export const GET_PROJECT_DETAILS_BY_ID = "GET_PROJECT_DETAILS_BY_ID"
export const GET_PROJECT_DETAILS_BY_ID_LOADING = "GET_PROJECT_DETAILS_BY_ID_LOADING"
export const GET_PROJECT_DETAILS_BY_ID_ERROR = "GET_PROJECT_DETAILS_BY_ID_ERROR"

export const GET_PROJECT_DETAILS_BY_PROJECT_ID = "GET_PROJECT_DETAILS_BY_PROJECT_ID"
export const GET_PROJECT_DETAILS_BY_PROJECT_ID_LOADING = "GET_PROJECT_DETAILS_BY_PROJECT_ID_LOADING"
export const GET_PROJECT_DETAILS_BY_PROJECT_ID_ERROR = "GET_PROJECT_DETAILS_BY_PROJECT_ID_ERROR"

export const EDIT_PROJECT_DETAILS = "EDIT_PROJECT_DETAILS"
export const EDIT_PROJECT_DETAILS_LOADING = "EDIT_PROJECT_DETAILS_LOADING"
export const EDIT_PROJECT_DETAILS_ERROR = "EDIT_PROJECT_DETAILS_ERROR"

export const DELETE_PROJECT_DETAILS = "DELETE_PROJECT_DETAILS"
export const DELETE_PROJECT_DETAILS_LOADING = "DELETE_PROJECT_DETAILS_LOADING"
export const DELETE_PROJECT_DETAILS_ERROR = "DELETE_PROJECT_DETAILS_ERROR"

export const GET_PROJECT_BY_CUSTOMER = "GET_PROJECT_BY_CUSTOMER"
export const GET_PROJECT_BY_CUSTOMER_LOADING = "GET_PROJECT_BY_CUSTOMER_LOADING"
export const GET_PROJECT_BY_CUSTOMER_ERROR = "GET_PROJECT_BY_CUSTOMER_ERROR"





export const ADD_NOC = "ADD_NOC"
export const ADD_NOC_LOADING = "ADD_NOC_LOADING"
export const ADD_NOC_ERROR = "ADD_NOC_ERROR"

export const GET_ALL_NOCS = "GET_ALL_NOCS"
export const GET_ALL_NOCS_LOADING = "GET_ALL_NOCS_LOADING"
export const GET_ALL_NOCS_ERROR = "GET_ALL_NOCS_ERROR"

export const GET_NOC_BY_ID = "GET_NOC_BY_ID"
export const GET_NOC_BY_ID_LOADING = "GET_NOC_BY_ID_LOADING"
export const GET_NOC_BY_ID_ERROR = "GET_NOC_BY_ID_ERROR"

export const DELETE_NOC = "DELETE_NOC"
export const DELETE_NOC_LOADING = "DELETE_NOC_LOADING"
export const DELETE_NOC_ERROR = "DELETE_NOC_ERROR"

export const EDIT_NOC = "EDIT_NOC"
export const EDIT_NOC_LOADING = "EDIT_NOC_LOADING"
export const EDIT_NOC_ERROR = "EDIT_NOC_ERROR"



export const GET_ALL_SITE_REPORTS = "GET_ALL_SITE_REPORTS"
export const GET_ALL_SITE_REPORTS_LOADING = "GET_ALL_SITE_REPORTS_LOADING"
export const GET_ALL_SITE_REPORTS_ERROR = "GET_ALL_SITE_REPORTS_ERROR"

export const GET_ALL_SITE_REPORTS_NO_AUTH = "GET_ALL_SITE_REPORTS_NO_AUTH"
export const GET_ALL_SITE_REPORTS_LOADING_NO_AUTH = "GET_ALL_SITE_REPORTS_LOADING_NO_AUTH"
export const GET_ALL_SITE_REPORTS_ERROR_NO_AUTH = "GET_ALL_SITE_REPORTS_ERROR_NO_AUTH"

export const GET_SITE_REPORTS_BY_ID = "GET_SITE_REPORTS_BY_ID"
export const GET_SITE_REPORTS_BY_ID_LOADING = "GET_SITE_REPORTS_BY_ID_LOADING"
export const GET_SITE_REPORTS_BY_ID_ERROR = "GET_SITE_REPORTS_BY_ID_ERROR"

export const GET_SITE_REPORTS_NO_AUTH_BY_ID = "GET_SITE_REPORTS_BY_ID"
export const GET_SITE_REPORTS_NO_AUTH_BY_ID_LOADING = "GET_SITE_REPORTS_BY_ID_LOADING"
export const GET_SITE_REPORTS_NO_AUTH_BY_ID_ERROR = "GET_SITE_REPORTS_BY_ID_ERROR"

export const ADD_SITE_REPORTS = "ADD_SITE_REPORTS"
export const ADD_SITE_REPORTS_LOADING = "ADD_SITE_REPORTS_LOADING"
export const ADD_SITE_REPORTS_ERROR = "ADD_SITE_REPORTS_ERROR"

export const GET_SITE_REPORTS_BY_PROJECT_ID = "GET_SITE_REPORTS_BY_PROJECT_ID"
export const GET_SITE_REPORTS_BY_PROJECT_ID_LOADING = "GET_SITE_REPORTS_BY_PROJECT_ID_LOADING"
export const GET_SITE_REPORTS_BY_PROJECT_ID_ERROR = "GET_SITE_REPORTS_BY_PROJECT_ID_ERROR"

export const EDIT_SITE_REPORTS_BY_ID = "EDIT_SITE_REPORTS_BY_ID"
export const EDIT_SITE_REPORTS_BY_ID_LOADING = "EDIT_SITE_REPORTS_BY_ID_LOADING"
export const EDIT_SITE_REPORTS_BY_ID_ERROR = "EDIT_SITE_REPORTS_BY_ID_ERROR"

export const DELETE_DAILY_REPORTS = "DELETE_DAILY_REPORTS"
export const DELETE_DAILY_REPORTS_LOADING = "DELETE_DAILY_REPORTS_LOADING"
export const DELETE_DAILY_REPORTS_ERROR = "DELETE_DAILY_REPORTS_ERROR"



export const ADD_CUSTOMER = "ADD_CUSTOMER"
export const ADD_CUSTOMER_LOADING = "ADD_CUSTOMER_LOADING"
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR"

export const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER"
export const GET_ALL_CUSTOMER_LOADING = "GET_ALL_CUSTOMER_LOADING"
export const GET_ALL_CUSTOMER_ERROR = "GET_ALL_CUSTOMER_ERROR"

export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID"
export const GET_CUSTOMER_BY_ID_LOADING = "GET_CUSTOMER_BY_ID_LOADING"
export const GET_CUSTOMER_BY_ID_ERROR = "GET_CUSTOMER_BY_ID_ERROR"

export const EDIT_CUSTOMER = "EDIT_CUSTOMER"
export const EDIT_CUSTOMER_LOADING = "EDIT_CUSTOMER_LOADING"
export const EDIT_CUSTOMER_ERROR = "EDIT_CUSTOMER_ERROR"

export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_LOADING = "DELETE_CUSTOMER_LOADING"
export const DELETE_CUSTOMER_ERROR = "DELETE_CUSTOMER_ERROR"

export const GET_CUSTOMER_DROPDOWN = "GET_CUSTOMER_DROPDOWN"
export const GET_CUSTOMER_DROPDOWN_LOADING = "GET_CUSTOMER_DROPDOWN_LOADING"
export const GET_CUSTOMER_DROPDOWN_ERROR = "GET_CUSTOMER_DROPDOWN_ERROR"

export const GET_SUPPLIER_DROPDOWN = "GET_SUPPLIER_DROPDOWN"
export const GET_SUPPLIER_DROPDOWN_LOADING = "GET_SUPPLIER_DROPDOWN_LOADING"
export const GET_SUPPLIER_DROPDOWN_ERROR = "GET_SUPPLIER_DROPDOWN_ERROR"



export const ADD_PRODUCT = "ADD_PRODUCT"
export const ADD_PRODUCT_LOADING = "ADD_PRODUCT_LOADING"
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR"

export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID"
export const GET_PRODUCT_BY_ID_LOADING = "GET_PRODUCT_BY_ID_LOADING"
export const GET_PRODUCT_BY_ID_ERROR = "GET_PRODUCT_BY_ID_ERROR"

export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT"
export const GET_ALL_PRODUCT_LOADING = "GET_ALL_PRODUCT_LOADING"
export const GET_ALL_PRODUCT_ERROR = "GET_ALL_PRODUCT_ERROR"

export const GET_ALL_PRODUCT_VALUE = "GET_ALL_PRODUCT_VALUE"
export const GET_ALL_PRODUCT_VALUE_LOADING = "GET_ALL_PRODUCT_VALUE_LOADING"
export const GET_ALL_PRODUCT_VALUE_ERROR = "GET_ALL_PRODUCT_VALUE_ERROR"

export const EDIT_PRODUCT = "EDIT_PRODUCT"
export const EDIT_PRODUCT_LOADING = "EDIT_PRODUCT_LOADING"
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR"

export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_LOADING = "DELETE_PRODUCT_LOADING"
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR"

export const GET_PRODUCT_DROPDOWN_DATA = "GET_PRODUCT_DROPDOWN_DATA"
export const GET_PRODUCT_DROPDOWN_DATA_LOADING = "GET_PRODUCT_DROPDOWN_DATA_LOADING"
export const GET_PRODUCT_DROPDOWN_DATA_ERROR = "GET_PRODUCT_DROPDOWN_DATA_ERROR"

export const IMPORT_FILE = "IMPORT_FILE"
export const IMPORT_FILE_LOADING = "IMPORT_FILE_LOADING"
export const IMPORT_FILE_ERROR = "IMPORT_FILE_ERROR"

export const GET_PRODUCT_BY_FINAL_MATERIAL = "GET_PRODUCT_BY_FINAL_MATERIAL"
export const GET_PRODUCT_BY_FINAL_MATERIAL_LOADING = "GET_PRODUCT_BY_FINAL_MATERIAL_LOADING"
export const GET_PRODUCT_BY_FINAL_MATERIAL_ERROR = "GET_PRODUCT_BY_FINAL_MATERIAL_ERROR"

export const GET_PRODUCT_LEDGER = "GET_PRODUCT_LEDGER"
export const GET_PRODUCT_LEDGER_LOADING = "GET_PRODUCT_LEDGER_LOADING"
export const GET_PRODUCT_LEDGER_ERROR = "GET_PRODUCT_LEDGER_ERROR"



export const ADD_PURCHASE = "ADD_PURCHASE"
export const ADD_PURCHASE_LOADING = "ADD_PURCHASE_LOADING"
export const ADD_PURCHASE_ERROR = "ADD_PURCHASE_ERROR"

export const GET_PURCHASE_BY_ID = "GET_PURCHASE_BY_ID"
export const GET_PURCHASE_BY_ID_LOADING = "GET_PURCHASE_BY_ID_LOADING"
export const GET_PURCHASE_BY_ID_ERROR = "GET_PURCHASE_BY_ID_ERROR"

export const GET_ALL_PURCHASE = "GET_ALL_PURCHASE"
export const GET_ALL_PURCHASE_LOADING = "GET_ALL_PURCHASE_LOADING"
export const GET_ALL_PURCHASE_ERROR = "GET_ALL_PURCHASE_ERROR"

export const EDIT_PURCHASE = "EDIT_PURCHASE"
export const EDIT_PURCHASE_LOADING = "EDIT_PURCHASE_LOADING"
export const EDIT_PURCHASE_ERROR = "EDIT_PURCHASE_ERROR"

export const DELETE_PURCHASE = "DELETE_PURCHASE"
export const DELETE_PURCHASE_LOADING = "DELETE_PURCHASE_LOADING"
export const DELETE_PURCHASE_ERROR = "DELETE_PURCHASE_ERROR"



export const ADD_SALE = "ADD_SALES"
export const ADD_SALE_LOADING = "ADD_SALES_LOADING"
export const ADD_SALE_ERROR = "ADD_SALES_ERROR"

export const GET_SALE_BY_ID = "GET_SALE_BY_ID"
export const GET_SALE_BY_ID_LOADING = "GET_SALE_BY_ID_LOADING"
export const GET_SALE_BY_ID_ERROR = "GET_SALE_BY_ID_ERROR"

export const GET_ALL_SALE = "GET_ALL_SALE"
export const GET_ALL_SALE_LOADING = "GET_ALL_SALE_LOADING"
export const GET_ALL_SALE_ERROR = "GET_ALL_SALE_ERROR"

export const EDIT_SALE = "EDIT_SALE"
export const EDIT_SALE_LOADING = "EDIT_SALE_LOADING"
export const EDIT_SALE_ERROR = "EDIT_SALE_ERROR"

export const DELETE_SALE = "DELETE_SALE"
export const DELETE_SALE_LOADING = "DELETE_SALE_LOADING"
export const DELETE_SALE_ERROR = "DELETE_SALE_ERROR"



export const ADD_TASK = "ADD_TASK"
export const ADD_TASK_LOADING = "ADD_TASK_LOADING"
export const ADD_TASK_ERROR = "ADD_TASK_ERROR"

export const GET_TASK_BY_ID = "GET_TASK_BY_ID"
export const GET_TASK_BY_ID_LOADING = "GET_TASK_BY_ID_LOADING"
export const GET_TASK_BY_ID_ERROR = "GET_TASK_BY_ID_ERROR"

export const GET_ALL_TASK = "GET_ALL_TASK"
export const GET_ALL_TASK_LOADING = "GET_ALL_TASK_LOADING"
export const GET_ALL_TASK_ERROR = "GET_ALL_TASK_ERROR"

export const EDIT_TASK = "EDIT_TASK"
export const EDIT_TASK_LOADING = "EDIT_TASK_LOADING"
export const EDIT_TASK_ERROR = "EDIT_TASK_ERROR"

export const DELETE_TASK = "DELETE_TASK"
export const DELETE_TASK_LOADING = "DELETE_TASK_LOADING"
export const DELETE_TASK_ERROR = "DELETE_TASK_ERROR"

export const EDIT_TASK_FOR_KANBAN = "EDIT_TASK_FOR_KANBAN"
export const EDIT_TASK_FOR_KANBAN_LOADING = "EDIT_TASK_FOR_KANBAN_LOADING"
export const EDIT_TASK_FOR_KANBAN_ERROR = "EDIT_TASK_FOR_KANBAN_ERROR"

export const TASK_DASHBOARD = "TASK_DASHBOARD"
export const TASK_DASHBOARD_LOADING = "TASK_DASHBOARD_LOADING"
export const TASK_DASHBOARD_ERROR = "TASK_DASHBOARD_ERROR"



export const ADD_TASK_COMMENT = "ADD_TASK_COMMENT"
export const ADD_TASK_COMMENT_LOADING = "ADD_TASK_COMMENT_LOADING"
export const ADD_TASK_COMMENT_ERROR = "ADD_TASK_COMMENT_ERROR"

export const GET_ALL_TASK_COMMENT = "GET_ALL_TASK_COMMENT"
export const GET_ALL_TASK_COMMENT_LOADING = "GET_ALL_TASK_COMMENT_LOADING"
export const GET_ALL_TASK_COMMENT_ERROR = "GET_ALL_TASK_COMMENT_ERROR"

export const GET_TASK_COMMENT_BY_ID = "GET_TASK_COMMENT_BY_ID"
export const GET_TASK_COMMENT_BY_ID_LOADING = "GET_TASK_COMMENT_BY_ID_LOADING"
export const GET_TASK_COMMENT_BY_ID_ERROR = "GET_TASK_COMMENT_BY_ID_ERROR"

export const GET_COMMENTS_BY_TASK_ID = "GET_COMMENTS_BY_TASK_ID"
export const GET_COMMENTS_BY_TASK_ID_LOADING = "GET_COMMENTS_BY_TASK_ID_LOADING"
export const GET_COMMENTS_BY_TASK_ID_ERROR = "GET_COMMENTS_BY_TASK_ID_ERROR"

export const EDIT_TASK_COMMENT = "EDIT_TASK_COMMENT"
export const EDIT_TASK_COMMENT_LOADING = "EDIT_TASK_COMMENT_LOADING"
export const EDIT_TASK_COMMENT_ERROR = "EDIT_TASK_COMMENT_ERROR"

export const DELETE_TASK_COMMENT = "DELETE_TASK_COMMENT"
export const DELETE_TASK_COMMENT_LOADING = "DELETE_TASK_COMMENT_LOADING"
export const DELETE_TASK_COMMENT_ERROR = "DELETE_TASK_COMMENT_ERROR"



export const ADD_BILL_ENTRY = "ADD_BILL_ENTRY"
export const ADD_BILL_ENTRY_LOADING = "ADD_BILL_ENTRY_LOADING"
export const ADD_BILL_ENTRY_ERROR = "ADD_BILL_ENTRY_ERROR"

export const GET_BILL_ENTRY_BY_ID = "GET_BILL_ENTRY_BY_ID"
export const GET_BILL_ENTRY_BY_ID_LOADING = "GET_BILL_ENTRY_BY_ID_LOADING"
export const GET_BILL_ENTRY_BY_ID_ERROR = "GET_BILL_ENTRY_BY_ID_ERROR"

export const GET_ALL_BILL_ENTRY = "GET_ALL_BILL_ENTRY"
export const GET_ALL_BILL_ENTRY_LOADING = "GET_ALL_BILL_ENTRY_LOADING"
export const GET_ALL_BILL_ENTRY_ERROR = "GET_ALL_BILL_ENTRY_ERROR"

export const EDIT_BILL_ENTRY = "EDIT_BILL_ENTRY"
export const EDIT_BILL_ENTRY_LOADING = "EDIT_BILL_ENTRY_LOADING"
export const EDIT_BILL_ENTRY_ERROR = "EDIT_BILL_ENTRY_ERROR"

export const DELETE_BILL_ENTRY = "DELETE_BILL_ENTRY"
export const DELETE_BILL_ENTRY_LOADING = "DELETE_BILL_ENTRY_LOADING"
export const DELETE_BILL_ENTRY_ERROR = "DELETE_BILL_ENTRY_ERROR"



export const GET_ACCOUNT = "GET_ACCOUNT"
export const GET_ACCOUNT_LOADING = "GET_ACCOUNT_LOADING"
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR"

export const GET_ALL_ACCOUNT_COUNT = "GET_ALL_ACCOUNT_COUNT"
export const GET_ALL_ACCOUNT_COUNT_LOADING = "GET_ALL_ACCOUNT_COUNT_LOADING"
export const GET_ALL_ACCOUNT_COUNT_ERROR = "GET_ALL_ACCOUNT_COUNT_ERROR"

export const GET_ALL_BILL_ENTRY_BY_CUSTOMER = "GET_ALL_BILL_ENTRY_BY_CUSTOMER"
export const GET_ALL_BILL_ENTRY_BY_CUSTOMER_LOADING = "GET_ALL_BILL_ENTRY_BY_CUSTOMER_LOADING"
export const GET_ALL_BILL_ENTRY_BY_CUSTOMER_ERROR = "GET_ALL_BILL_ENTRY_BY_CUSTOMER_ERROR"


export const GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER = "GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER"
export const GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING = "GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING"
export const GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_ERROR = "GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_ERROR"



export const ADD_BANK = "ADD_BANK"
export const ADD_BANK_LOADING = "ADD_BANK_LOADING"
export const ADD_BANK_ERROR = "ADD_BANK_ERROR"

export const GET_BANK_BY_ID = "GET_BANK_BY_ID"
export const GET_BANK_BY_ID_LOADING = "GET_BANK_BY_ID_LOADING"
export const GET_BANK_BY_ID_ERROR = "GET_BANK_BY_ID_ERROR"

export const GET_ALL_BANK = "GET_ALL_BANK"
export const GET_ALL_BANK_LOADING = "GET_ALL_BANK_LOADING"
export const GET_ALL_BANK_ERROR = "GET_ALL_BANK_ERROR"

export const EDIT_BANK = "EDIT_BANK"
export const EDIT_BANK_LOADING = "EDIT_BANK_LOADING"
export const EDIT_BANK_ERROR = "EDIT_BANK_ERROR"

export const DELETE_BANK = "DELETE_BANK"
export const DELETE_BANK_LOADING = "DELETE_BANK_LOADING"
export const DELETE_BANK_ERROR = "DELETE_BANK_ERROR"

export const GET_BANK_DROP_DOWN = "GET_BANK_DROP_DOWN"
export const GET_BANK_DROP_DOWN_LOADING = "GET_BANK_DROP_DOWN_LOADING"
export const GET_BANK_DROP_DOWN_ERROR = "GET_BANK_DROP_DOWN_ERROR"



export const ADD_PURCHASE_REQUEST = "ADD_PURCHASE_REQUEST"
export const ADD_PURCHASE_REQUEST_LOADING = "ADD_PURCHASE_REQUEST_LOADING"
export const ADD_PURCHASE_REQUEST_ERROR = "ADD_PURCHASE_REQUEST_ERROR"

export const GET_PURCHASE_REQUEST_BY_ID = "GET_PURCHASE_REQUEST_BY_ID"
export const GET_PURCHASE_REQUEST_BY_ID_LOADING = "GET_PURCHASE_REQUEST_BY_ID_LOADING"
export const GET_PURCHASE_REQUEST_BY_ID_ERROR = "GET_PURCHASE_REQUEST_BY_ID_ERROR"

export const GET_PURCHASE_REQUEST_BY_PROJECT = "GET_PURCHASE_REQUEST_BY_PROJECT"
export const GET_PURCHASE_REQUEST_BY_PROJECT_LOADING = "GET_PURCHASE_REQUEST_BY_PROJECT_LOADING"
export const GET_PURCHASE_REQUEST_BY_PROJECT_ERROR = "GET_PURCHASE_REQUEST_BY_PROJECT_ERROR"

export const GET_ALL_PURCHASE_REQUEST = "GET_ALL_PURCHASE_REQUEST"
export const GET_ALL_PURCHASE_REQUEST_LOADING = "GET_ALL_PURCHASE_REQUEST_LOADING"
export const GET_ALL_PURCHASE_REQUEST_ERROR = "GET_ALL_PURCHASE_REQUEST_ERROR"

export const EDIT_PURCHASE_REQUEST = "EDIT_PURCHASE_REQUEST"
export const EDIT_PURCHASE_REQUEST_LOADING = "EDIT_PURCHASE_REQUEST_LOADING"
export const EDIT_PURCHASE_REQUEST_ERROR = "EDIT_PURCHASE_REQUEST_ERROR"

export const DELETE_PURCHASE_REQUEST = "DELETE_PURCHASE_REQUEST"
export const DELETE_PURCHASE_REQUEST_LOADING = "DELETE_PURCHASE_REQUEST_LOADING"
export const DELETE_PURCHASE_REQUEST_ERROR = "DELETE_PURCHASE_REQUEST_ERROR"



export const ADD_CONTRACTOR = "ADD_CONTRACTOR"
export const ADD_CONTRACTOR_LOADING = "ADD_CONTRACTOR_LOADING"
export const ADD_CONTRACTOR_ERROR = "ADD_CONTRACTOR_ERROR"

export const GET_ALL_CONTRACTOR = "GET_ALL_CONTRACTOR"
export const GET_ALL_CONTRACTOR_LOADING = "GET_ALL_CONTRACTOR_LOADING"
export const GET_ALL_CONTRACTOR_ERROR = "GET_ALL_CONTRACTOR_ERROR"

export const GET_CONTRACTOR_BY_ID = "GET_CONTRACTOR_BY_ID"
export const GET_CONTRACTOR_BY_ID_LOADING = "GET_CONTRACTOR_BY_ID_LOADING"
export const GET_CONTRACTOR_BY_ID_ERROR = "GET_CONTRACTOR_BY_ID_ERROR"

export const EDIT_CONTRACTOR = "EDIT_CONTRACTOR"
export const EDIT_CONTRACTOR_LOADING = "EDIT_CONTRACTOR_LOADING"
export const EDIT_CONTRACTOR_ERROR = "EDIT_CONTRACTOR_ERROR"

export const DELETE_CONTRACTOR = "DELETE_CONTRACTOR"
export const DELETE_CONTRACTOR_LOADING = "DELETE_CONTRACTOR_LOADING"
export const DELETE_CONTRACTOR_ERROR = "DELETE_CONTRACTOR_ERROR"



export const ADD_ASSETS = "ADD_ASSETS"
export const ADD_ASSETS_LOADING = "ADD_ASSETS_LOADING"
export const ADD_ASSETS_ERROR = "ADD_ASSETS_ERROR"

export const GET_ASSETS_BY_ID = "GET_ASSETS_BY_ID"
export const GET_ASSETS_BY_ID_LOADING = "GET_ASSETS_BY_ID_LOADING"
export const GET_ASSETS_BY_ID_ERROR = "GET_ASSETS_BY_ID_ERROR"

export const GET_ALL_ASSETS = "GET_ALL_ASSETS"
export const GET_ALL_ASSETS_LOADING = "GET_ALL_ASSETS_LOADING"
export const GET_ALL_ASSETS_ERROR = "GET_ALL_ASSETS_ERROR"

export const GET_ALL_ASSETS_NON_PROJECT = "GET_ALL_ASSETS_NON_PROJECT"
export const GET_ALL_ASSETS_NON_PROJECT_LOADING = "GET_ALL_ASSETS_NON_PROJECT_LOADING"
export const GET_ALL_ASSETS_NON_PROJECT_ERROR = "GET_ALL_ASSETS_NON_PROJECT_ERROR"

export const GET_ASSETS_BY_PROJECT = "GET_ASSETS_BY_PROJECT"
export const GET_ASSETS_BY_PROJECT_LOADING = "GET_ASSETS_BY_PROJECT_LOADING"
export const GET_ASSETS_BY_PROJECT_ERROR = "GET_ASSETS_BY_PROJECT_ERROR"

export const EDIT_ASSETS = "EDIT_ASSETS"
export const EDIT_ASSETS_LOADING = "EDIT_ASSETS_LOADING"
export const EDIT_ASSETS_ERROR = "EDIT_ASSETS_ERROR"

export const DELETE_ASSETS = "DELETE_ASSETS"
export const DELETE_ASSETS_LOADING = "DELETE_ASSETS_LOADING"
export const DELETE_ASSETS_ERROR = "DELETE_ASSETS_ERROR"



export const GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT"
export const GET_NOTIFICATION_COUNT_LOADING = "GET_NOTIFICATION_COUNT_LOADING"
export const GET_NOTIFICATION_COUNT_ERROR = "GET_NOTIFICATION_COUNT_ERROR"

export const READ_NOTIFICATION = "READ_NOTIFICATION"
export const READ_NOTIFICATION_LOADING = "READ_NOTIFICATION_LOADING"
export const READ_NOTIFICATION_ERROR = "READ_NOTIFICATION_ERROR"

export const GET_NOTIFICATION_BY_USER_ID = "GET_NOTIFICATION_BY_USER_ID"
export const GET_NOTIFICATION_BY_USER_ID_LOADING = "GET_NOTIFICATION_BY_USER_ID_LOADING"
export const GET_NOTIFICATION_BY_USER_ID_ERROR = "GET_NOTIFICATION_BY_USER_ID_ERROR"



export const FILE_UPLOAD = "FILE_UPLOAD"
export const FILE_UPLOAD_LOADING = "FILE_UPLOAD_LOADING"
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR"

export const FILE_REMOVE = "FILE_REMOVE"
export const FILE_REMOVE_LOADING = "FILE_REMOVE_LOADING"
export const FILE_REMOVE_ERROR = "FILE_REMOVE_ERROR"



export const GET_RECENT_ENQUIRY = "GET_RECENT_ENQUIRY"
export const GET_RECENT_ENQUIRY_LOADING = "GET_RECENT_ENQUIRY_LOADING"
export const GET_RECENT_ENQUIRY_ERROR = "GET_RECENT_ENQUIRY_ERROR"

export const GET_ENQUIRY_BY_MONTH_YEAR_WISE = "GET_ENQUIRY_BY_MONTH_YEAR_WISE"
export const GET_ENQUIRY_BY_MONTH_YEAR_WISE_LOADING = "GET_ENQUIRY_BY_MONTH_YEAR_WISE_LOADING"
export const GET_ENQUIRY_BY_MONTH_YEAR_WISE_ERROR = "GET_ENQUIRY_BY_MONTH_YEAR_WISE_ERROR"

export const GET_ENQUIRY_COUNT_BY_STATUS = "GET_ENQUIRY_COUNT_BY_STATUS"
export const GET_ENQUIRY_COUNT_BY_STATUS_LOADING = "GET_ENQUIRY_COUNT_BY_STATUS_LOADING"
export const GET_ENQUIRY_COUNT_BY_STATUS_ERROR = "GET_ENQUIRY_COUNT_BY_STATUS_ERROR"



export const ADD_RETURN_MATERIAL = "ADD_RETURN_MATERIAL"
export const ADD_RETURN_MATERIAL_LOADING = "ADD_RETURN_MATERIAL_LOADING"
export const ADD_RETURN_MATERIAL_ERROR = "ADD_RETURN_MATERIAL_ERROR"

export const EDIT_RETURN_MATERIAL = "EDIT_RETURN_MATERIAL"
export const EDIT_RETURN_MATERIAL_LOADING = "EDIT_RETURN_MATERIAL_LOADING"
export const EDIT_RETURN_MATERIAL_ERROR = "EDIT_RETURN_MATERIAL_ERROR"

export const GET_ALL_RETURN_MATERIAL = "GET_ALL_RETURN_MATERIAL"
export const GET_ALL_RETURN_MATERIAL_LOADING = "GET_ALL_RETURN_MATERIAL_LOADING"
export const GET_ALL_RETURN_MATERIAL_ERROR = "GET_ALL_RETURN_MATERIAL_ERROR"

export const GET_RETURN_MATERIAL_BY_ID = "GET_RETURN_MATERIAL_BY_ID"
export const GET_RETURN_MATERIAL_BY_ID_LOADING = "GET_RETURN_MATERIAL_BY_ID_LOADING"
export const GET_RETURN_MATERIAL_BY_ID_ERROR = "GET_RETURN_MATERIAL_BY_ID_ERROR"

export const GET_RETURN_MATERIAL_BY_PROJECT = "GET_RETURN_MATERIAL_BY_PROJECT"
export const GET_RETURN_MATERIAL_BY_PROJECT_LOADING = "GET_RETURN_MATERIAL_BY_PROJECT_LOADING"
export const GET_RETURN_MATERIAL_BY_PROJECT_ERROR = "GET_RETURN_MATERIAL_BY_PROJECT_ERROR"

export const DELETE_RETURN_MATERIAL = "DELETE_RETURN_MATERIAL"
export const DELETE_RETURN_MATERIAL_LOADING = "DELETE_RETURN_MATERIAL_LOADING"
export const DELETE_RETURN_MATERIAL_ERROR = "DELETE_RETURN_MATERIAL_ERROR"



export const ADD_FINAL_MATERIAL = "ADD_FINAL_MATERIAL"
export const ADD_FINAL_MATERIAL_LOADING = "ADD_FINAL_MATERIAL_LOADING"
export const ADD_FINAL_MATERIAL_ERROR = "ADD_FINAL_MATERIAL_ERROR"

export const ADD_FINAL_MATERIAL_SUB_PRODUCT = "ADD_FINAL_MATERIAL_SUB_PRODUCT"
export const ADD_FINAL_MATERIAL_SUB_PRODUCT_LOADING = "ADD_FINAL_MATERIAL_SUB_PRODUCT_LOADING"
export const ADD_FINAL_MATERIAL_SUB_PRODUCT_ERROR = "ADD_FINAL_MATERIAL_SUB_PRODUCT_ERROR"

export const GET_FINAL_MATERIAL_BY_ID = "GET_FINAL_MATERIAL_BY_ID"
export const GET_FINAL_MATERIAL_BY_ID_LOADING = "GET_FINAL_MATERIAL_BY_ID_LOADING"
export const GET_FINAL_MATERIAL_BY_ID_ERROR = "GET_FINAL_MATERIAL_BY_ID_ERROR"

export const GET_FINAL_MATERIAL_BY_PARANT_ID = "GET_FINAL_MATERIAL_BY_PARANT_ID"
export const GET_FINAL_MATERIAL_BY_PARANT_ID_LOADING = "GET_FINAL_MATERIAL_BY_PARANT_ID_LOADING"
export const GET_FINAL_MATERIAL_BY_PARANT_ID_ERROR = "GET_FINAL_MATERIAL_BY_PARANT_ID_ERROR"

export const GET_ALL_FINAL_MATERIAL = "GET_ALL_FINAL_MATERIAL"
export const GET_ALL_FINAL_MATERIAL_LOADING = "GET_ALL_FINAL_MATERIAL_LOADING"
export const GET_ALL_FINAL_MATERIAL_ERROR = "GET_ALL_FINAL_MATERIAL_ERROR"

export const EDIT_FINAL_MATERIAL = "EDIT_FINAL_MATERIAL"
export const EDIT_FINAL_MATERIAL_LOADING = "EDIT_FINAL_MATERIAL_LOADING"
export const EDIT_FINAL_MATERIAL_ERROR = "EDIT_FINAL_MATERIAL_ERROR"

export const DELETE_FINAL_MATERIAL = "DELETE_FINAL_MATERIAL"
export const DELETE_FINAL_MATERIAL_LOADING = "DELETE_FINAL_MATERIAL_LOADING"
export const DELETE_FINAL_MATERIAL_ERROR = "DELETE_FINAL_MATERIAL_ERROR"

export const GET_FINAL_MATERIAL_BY_PROJECT = "GET_FINAL_MATERIAL_BY_PROJECT"
export const GET_FINAL_MATERIAL_BY_PROJECT_LOADING = "GET_FINAL_MATERIAL_BY_PROJECT_LOADING"
export const GET_FINAL_MATERIAL_BY_PROJECT_ERROR = "GET_FINAL_MATERIAL_BY_PROJECT_ERROR"



export const ADD_EMPLOYEE_REPORT = "ADD_EMPLOYEE_REPORT"
export const ADD_EMPLOYEE_REPORT_LOADING = "ADD_EMPLOYEE_REPORT_LOADING"
export const ADD_EMPLOYEE_REPORT_ERROR = "ADD_EMPLOYEE_REPORT_ERROR"

export const GET_EMPLOYEE_REPORT_BY_ID = "GET_EMPLOYEE_REPORT_BY_ID"
export const GET_EMPLOYEE_REPORT_BY_ID_LOADING = "GET_EMPLOYEE_REPORT_BY_ID_LOADING"
export const GET_EMPLOYEE_REPORT_BY_ID_ERROR = "GET_EMPLOYEE_REPORT_BY_ID_ERROR"

export const GET_ALL_EMPLOYEE_REPORT = "GET_ALL_EMPLOYEE_REPORT"
export const GET_ALL_EMPLOYEE_REPORT_LOADING = "GET_ALL_EMPLOYEE_REPORT_LOADING"
export const GET_ALL_EMPLOYEE_REPORT_ERROR = "GET_ALL_EMPLOYEE_REPORT_ERROR"

export const EDIT_EMPLOYEE_REPORT = "EDIT_EMPLOYEE_REPORT"
export const EDIT_EMPLOYEE_REPORT_LOADING = "EDIT_EMPLOYEE_REPORT_LOADING"
export const EDIT_EMPLOYEE_REPORT_ERROR = "EDIT_EMPLOYEE_REPORT_ERROR"

export const DELETE_EMPLOYEE_REPORT = "DELETE_EMPLOYEE_REPORT"
export const DELETE_EMPLOYEE_REPORT_LOADING = "DELETE_EMPLOYEE_REPORT_LOADING"
export const DELETE_EMPLOYEE_REPORT_ERROR = "DELETE_EMPLOYEE_REPORT_ERROR"

// Quotation

export const ADD_QUOTATION = "ADD_QUOTATION"
export const ADD_QUOTATION_LOADING = "ADD_QUOTATION_LOADING"
export const ADD_QUOTATION_ERROR = "ADD_QUOTATION_ERROR"

export const GET_QUOTATION_BY_ENQUIRYID = "GET_QUOTATION_BY_ENQUIRYID"
export const GET_QUOTATION_BY_ENQUIRYID_LOADING = "GET_QUOTATION_BY_ENQUIRYID_LOADING"
export const GET_QUOTATION_BY_ENQUIRYID_ERROR = "GET_QUOTATION_BY_ENQUIRYID_ERROR"

export const GET_QUOTATION_BY_ID = "GET_QUOTATION_BY_ID"
export const GET_QUOTATION_BY_ID_LOADING = "GET_QUOTATION_BY_ID_LOADING"
export const GET_QUOTATION_BY_ID_ERROR = "GET_QUOTATION_BY_ID_ERROR"


export const EDIT_QUOTATION = "EDIT_QUOTATION"
export const EDIT_QUOTATION_LOADING = "EDIT_QUOTATION_LOADING"
export const EDIT_QUOTATION_ERROR = "EDIT_QUOTATION_ERROR"

export const DELETE_QUOTATION = "DELETE_QUOTATION"
export const DELETE_QUOTATION_LOADING = "DELETE_QUOTATION_LOADING"
export const DELETE_QUOTATION_ERROR = "DELETE_QUOTATION_ERROR"

// Term And Condition

export const ADD_TERM_AND_CONDITION = "ADD_TERM_AND_CONDITION"
export const ADD_TERM_AND_CONDITION_LOADING = "ADD_TERM_AND_CONDITION_LOADING"
export const ADD_TERM_AND_CONDITION_ERROR = "ADD_TERM_AND_CONDITION_ERROR"

export const EDIT_TERM_AND_CONDITION = "EDIT_TERM_AND_CONDITION"
export const EDIT_TERM_AND_CONDITION_LOADING = "EDIT_TERM_AND_CONDITION_LOADING"
export const EDIT_TERM_AND_CONDITION_ERROR = "EDIT_TERM_AND_CONDITION_ERROR"

export const GET_ALL_TERM_AND_CONDITION = "GET_ALL_TERM_AND_CONDITION"
export const GET_ALL_TERM_AND_CONDITION_LOADING = "GET_ALL_TERM_AND_CONDITION_LOADING"
export const GET_ALL_TERM_AND_CONDITION_ERROR = "GET_ALL_TERM_AND_CONDITION_ERROR"

export const DELETE_TERM_AND_CONDITION = "DELETE_TERM_AND_CONDITION"
export const DELETE_TERM_AND_CONDITION_LOADING = "DELETE_TERM_AND_CONDITION_LOADING"
export const DELETE_TERM_AND_CONDITION_ERROR = "DELETE_TERM_AND_CONDITION_ERROR"

export const ADD_PROJECT_MATERIAL = "ADD_PROJECT_MATERIAL"
export const ADD_PROJECT_MATERIAL_LOADING = "ADD_PROJECT_MATERIAL_LOADING"
export const ADD_PROJECT_MATERIAL_ERROR = "ADD_PROJECT_MATERIAL_ERROR"
export const APPROVE_OR_REJECT_LABOR_BILL_RESPONSE = "APPROVE_OR_REJECT_LABOR_BILL_RESPONSE"

export const EDIT_PROJECT_MATERIAL = "EDIT_PROJECT_MATERIAL"
export const EDIT_PROJECT_MATERIAL_LOADING = "EDIT_PROJECT_MATERIAL_LOADING"
export const EDIT_PROJECT_MATERIAL_ERROR = "EDIT_PROJECT_MATERIAL_ERROR"

export const DELETE_PROJECT_MATERIAL = "DELETE_PROJECT_MATERIAL"
export const DELETE_PROJECT_MATERIAL_LOADING = "DELETE_PROJECT_MATERIAL_LOADING"
export const DELETE_PROJECT_MATERIAL_ERROR = "DELETE_PROJECT_MATERIAL_ERROR"