import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import dashbord from "../../assets/img/dashboard.png"
import dashbordWhite from "../../assets/img/dashboardWhite.png"
import product from "../../assets/img/product.png"
import productWhite from "../../assets/img/productWhite.png"
import purchase from "../../assets/img/purchase.png"
import purchaseWhite from "../../assets/img/purchaseWhite.png"
import customer from "../../assets/img/customer.png"
import customerWhite from "../../assets/img/customerWhite.png"
import term from "../../assets/img/term.png"
import termwhite from "../../assets/img/term-white.png"
import summary from "../../assets/img/summary.png"
import summaryWhite from "../../assets/img/summaryWhite.png"
import enquiry from "../../assets/img/enquiry.png"
import enquiryWhite from "../../assets/img/enquiryWhite.png"
import project from "../../assets/img/project.png"
import projectWhite from "../../assets/img/projectWhite.png"
import dailyreport from "../../assets/img/dailyreport.png"
import dailyreportWhite from "../../assets/img/dailyreportWhite.png"
import noc from "../../assets/img/noc.png"
import nocWhite from "../../assets/img/nocWhite.png"
import users from "../../assets/img/users.png"
import usersWhite from "../../assets/img/usersWhite.png"
import setting from "../../assets/img/setting.png"
import settingWhite from "../../assets/img/settingWhite.png"
// import logo from "../../assets/img/fire-safety-white.png"  //  for Local
import logo from "../../assets/img/logo.bmp"  // for shhradha firesafety
import sale from "../../assets/img/sales.png"
import saleWhite from "../../assets/img/salesWhite.png"
import task from "../../assets/img/task.png"
import taskWhite from "../../assets/img/taskWhite.png"
import account from "../../assets/img/account.png"
import accountWhite from "../../assets/img/accountWhite.png"
import assets from "../../assets/img/assets.png"
import assetsWhite from "../../assets/img/assetsWhite.png"
import pr from "../../assets/img/pr.png"
import prWhite from "../../assets/img/prWhite.png"
import roles from "../../assets/img/roles.png"
import rolesWhite from "../../assets/img/rolesWhite.png"
import AuthStorage from '../../helper/AuthStorage'
import logoutImg from "../../assets/img/logout-arrow.png"
import arrowImg from "../../assets/image/white-select.png"
import { LOGIN } from '../../redux/type'
import { useDispatch } from 'react-redux'
import STORAGEKEY from '../../config/APP/app.config'
import { UserRoles } from '../../helper/Constant'
import { RoleContext } from '../../helper/RoleProvider'
import SweetAlert from 'react-bootstrap-sweetalert'
import poweredByLogo from "../../assets/img/powerd_by_white.png"
import returnDark from "../../assets/img/return.png"
import returnWhite from "../../assets/img/return-white.png"
import dailyReportStaff from "../../assets/img/daily-report-staff.png"
import dailyReportStaffWhite from "../../assets/img/daily-report-staff-white.png"


const Sidebar = ({ showSidebar, setSidebar }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const userRole = useContext(RoleContext);
    const [openSubMenu, setOpenSubMenu] = useState('')
    const [sidebarBtn, setSidebarBtn] = useState()
    const [logOutTog, setLogOutTog] = useState(false)
    const navigate = useNavigate()

    const adminSidebarMenu = [
        {
            img: dashbordWhite,
            img1: dashbord,
            text: "Dashboard",
            path: '/dashboard',
        },
        {
            img: enquiryWhite,
            img1: enquiry,
            text: "Sales",
            path: '/enquiry',
        },
        {
            img: taskWhite,
            img1: task,
            text: "Task",
            path: '/task',
        },
        {
            img: projectWhite,
            img1: project,
            text: "Project Mng.",
            subMenu: [
                {
                    img: projectWhite,
                    img1: project,
                    text: "Projects",
                    path: '/project',
                },
                {
                    img: dailyreportWhite,
                    img1: dailyreport,
                    text: "Daily Report",
                    path: '/daily-report',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequest',
                },
            ]
        },
        {
            img: summaryWhite,
            img1: summary,
            text: "Store",
            subMenu: [
                {
                    img: summaryWhite,
                    img1: summary,
                    text: "Summary",
                    path: '/stock-summary',
                },
                {
                    img: productWhite,
                    img1: product,
                    text: "Product",
                    path: '/product',
                },
                {
                    img: saleWhite,
                    img1: sale,
                    text: "Sale",
                    path: '/sale',
                },
                {
                    img: purchaseWhite,
                    img1: purchase,
                    text: "Purchase",
                    path: '/purchase',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequests',
                },
                {
                    img: returnWhite,
                    img1: returnDark,
                    text: "Return Material",
                    path: '/returnmaterial',
                },
            ]
        },
        {
            img: dailyReportStaffWhite,
            img1: dailyReportStaff,
            text: "Staff Daily Report",
            path: '/staff-daily-report',
        },
        {
            img: accountWhite,
            img1: account,
            text: "Account",
            path: '/account',
        },
        {
            img: assetsWhite,
            img1: assets,
            text: "Assets",
            path: '/asset',
        },
        {
            img: nocWhite,
            img1: noc,
            text: "Liasoning",
            path: '/noc',
        },
        {
            img: customerWhite,
            img1: customer,
            text: "Customer",
            path: '/customer',
        },
        {
            img: termwhite,
            img1: term,
            text: "Term & Condition",
            path: '/termandcondition',
        },
        {
            img: customerWhite,
            img1: customer,
            text: "Contractor",
            path: '/contractor',
        },
        {
            img: usersWhite,
            img1: users,
            text: "Users",
            path: '/users',
        },
        {
            img: settingWhite,
            img1: setting,
            text: "Setting",
            path: '/setting',
        },
    ]

    const salesSidebarMenu = [
        {
            img: dashbordWhite,
            img1: dashbord,
            text: "Dashboard",
            path: '/dashboard',
        },
        {
            img: enquiryWhite,
            img1: enquiry,
            text: "Sales",
            path: '/enquiry',
        },
        {
            img: taskWhite,
            img1: task,
            text: "Task",
            path: '/task',
        },
        {
            img: projectWhite,
            img1: project,
            text: "Project Mng.",
            subMenu: [
                {
                    img: projectWhite,
                    img1: project,
                    text: "Projects",
                    path: '/project',
                },
                {
                    img: dailyreportWhite,
                    img1: dailyreport,
                    text: "Daily Report",
                    path: '/daily-report',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequest',
                },
            ]
        },
        {
            img: summaryWhite,
            img1: summary,
            text: "Store",
            subMenu: [
                {
                    img: summaryWhite,
                    img1: summary,
                    text: "Summary",
                    path: '/stock-summary',
                },
                {
                    img: productWhite,
                    img1: product,
                    text: "Product",
                    path: '/product',
                },
                {
                    img: saleWhite,
                    img1: sale,
                    text: "Sale",
                    path: '/sale',
                },
                {
                    img: purchaseWhite,
                    img1: purchase,
                    text: "Purchase",
                    path: '/purchase',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequests',
                },
                {
                    img: returnWhite,
                    img1: returnDark,
                    text: "Return Material",
                    path: '/returnmaterial',
                },
            ]
        },
        {
            img: dailyReportStaffWhite,
            img1: dailyReportStaff,
            text: "Staff Daily Report",
            path: '/staff-daily-report',
        },
        {
            img: assetsWhite,
            img1: assets,
            text: "Assets",
            path: '/asset',
        },
        {
            img: nocWhite,
            img1: noc,
            text: "Liasoning",
            path: '/noc',
        }
    ]

    const projectSidebarMenu = [
        {
            img: dashbordWhite,
            img1: dashbord,
            text: "Dashboard",
            path: '/dashboard',
        },
        {
            img: enquiryWhite,
            img1: enquiry,
            text: "Sales",
            path: '/enquiry',
        },
        {
            img: taskWhite,
            img1: task,
            text: "Task",
            path: '/task',
        },
        {
            img: projectWhite,
            img1: project,
            text: "Project Mng.",
            subMenu: [
                {
                    img: projectWhite,
                    img1: project,
                    text: "Projects",
                    path: '/project',
                },
                {
                    img: dailyreportWhite,
                    img1: dailyreport,
                    text: "Daily Report",
                    path: '/daily-report',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequest',
                },
            ]
        },
        {
            img: summaryWhite,
            img1: summary,
            text: "Store",
            subMenu: [
                {
                    img: summaryWhite,
                    img1: summary,
                    text: "Summary",
                    path: '/stock-summary',
                },
                {
                    img: productWhite,
                    img1: product,
                    text: "Product",
                    path: '/product',
                },
                {
                    img: saleWhite,
                    img1: sale,
                    text: "Sale",
                    path: '/sale',
                },
                {
                    img: purchaseWhite,
                    img1: purchase,
                    text: "Purchase",
                    path: '/purchase',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequests',
                },
                {
                    img: returnWhite,
                    img1: returnDark,
                    text: "Return Material",
                    path: '/returnmaterial',
                },
            ]
        },
        {
            img: dailyReportStaffWhite,
            img1: dailyReportStaff,
            text: "Staff Daily Report",
            path: '/staff-daily-report',
        },
        {
            img: assetsWhite,
            img1: assets,
            text: "Assets",
            path: '/asset',
        },
        {
            img: nocWhite,
            img1: noc,
            text: "Liasoning",
            path: '/noc',
        }
    ]

    const storeStockSidebarMenu = [
        {
            img: dashbordWhite,
            img1: dashbord,
            text: "Dashboard",
            path: '/dashboard',
        },
        {
            img: enquiryWhite,
            img1: enquiry,
            text: "Sales",
            path: '/enquiry',
        },
        {
            img: taskWhite,
            img1: task,
            text: "Task",
            path: '/task',
        },
        {
            img: projectWhite,
            img1: project,
            text: "Project Mng.",
            subMenu: [
                {
                    img: projectWhite,
                    img1: project,
                    text: "Projects",
                    path: '/project',
                },
                {
                    img: dailyreportWhite,
                    img1: dailyreport,
                    text: "Daily Report",
                    path: '/daily-report',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequest',
                },
            ]
        },
        {
            img: summaryWhite,
            img1: summary,
            text: "Store",
            subMenu: [
                {
                    img: summaryWhite,
                    img1: summary,
                    text: "Summary",
                    path: '/stock-summary',
                },
                {
                    img: productWhite,
                    img1: product,
                    text: "Product",
                    path: '/product',
                },
                {
                    img: saleWhite,
                    img1: sale,
                    text: "Sale",
                    path: '/sale',
                },
                {
                    img: purchaseWhite,
                    img1: purchase,
                    text: "Purchase",
                    path: '/purchase',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequests',
                },
                {
                    img: returnWhite,
                    img1: returnDark,
                    text: "Return Material",
                    path: '/returnmaterial',
                },
            ]
        },
        {
            img: dailyReportStaffWhite,
            img1: dailyReportStaff,
            text: "Staff Daily Report",
            path: '/staff-daily-report',
        },
        {
            img: assetsWhite,
            img1: assets,
            text: "Assets",
            path: '/asset',
        },
    ]

    const accountSidebarMenu = [
        {
            img: dashbordWhite,
            img1: dashbord,
            text: "Dashboard",
            path: '/dashboard',
        },
        {
            img: enquiryWhite,
            img1: enquiry,
            text: "Sales",
            path: '/enquiry',
        },
        {
            img: taskWhite,
            img1: task,
            text: "Task",
            path: '/task',
        },
        {
            img: projectWhite,
            img1: project,
            text: "Project Mng.",
            subMenu: [
                {
                    img: projectWhite,
                    img1: project,
                    text: "Projects",
                    path: '/project',
                },
                {
                    img: dailyreportWhite,
                    img1: dailyreport,
                    text: "Daily Report",
                    path: '/daily-report',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequest',
                },
            ]
        },
        {
            img: summaryWhite,
            img1: summary,
            text: "Store",
            subMenu: [
                {
                    img: summaryWhite,
                    img1: summary,
                    text: "Summary",
                    path: '/stock-summary',
                },
                {
                    img: productWhite,
                    img1: product,
                    text: "Product",
                    path: '/product',
                },
                {
                    img: saleWhite,
                    img1: sale,
                    text: "Sale",
                    path: '/sale',
                },
                {
                    img: purchaseWhite,
                    img1: purchase,
                    text: "Purchase",
                    path: '/purchase',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequests',
                },
                {
                    img: returnWhite,
                    img1: returnDark,
                    text: "Return Material",
                    path: '/returnmaterial',
                },
            ]
        },
        {
            img: dailyReportStaffWhite,
            img1: dailyReportStaff,
            text: "Staff Daily Report",
            path: '/staff-daily-report',
        },
        {
            img: accountWhite,
            img1: account,
            text: "Account",
            path: '/account',
        },
        {
            img: assetsWhite,
            img1: assets,
            text: "Assets",
            path: '/asset',
        },
        {
            img: nocWhite,
            img1: noc,
            text: "Liasoning",
            path: '/noc',
        }
    ]

    const LiasoningSidebarMenu = [
        {
            img: dashbordWhite,
            img1: dashbord,
            text: "Dashboard",
            path: '/dashboard',
        },
        {
            img: taskWhite,
            img1: task,
            text: "Task",
            path: '/task',
        },
        {
            img: projectWhite,
            img1: project,
            text: "Project Mng.",
            subMenu: [
                {
                    img: projectWhite,
                    img1: project,
                    text: "Projects",
                    path: '/project',
                },
                {
                    img: dailyreportWhite,
                    img1: dailyreport,
                    text: "Daily Report",
                    path: '/daily-report',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequest',
                },
            ]
        },
        {
            img: summaryWhite,
            img1: summary,
            text: "Store",
            subMenu: [
                {
                    img: summaryWhite,
                    img1: summary,
                    text: "Summary",
                    path: '/stock-summary',
                },
                {
                    img: productWhite,
                    img1: product,
                    text: "Product",
                    path: '/product',
                },
                {
                    img: saleWhite,
                    img1: sale,
                    text: "Sale",
                    path: '/sale',
                },
                {
                    img: purchaseWhite,
                    img1: purchase,
                    text: "Purchase",
                    path: '/purchase',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequests',
                },
                {
                    img: returnWhite,
                    img1: returnDark,
                    text: "Return Material",
                    path: '/returnmaterial',
                },
            ]
        },
        {
            img: dailyReportStaffWhite,
            img1: dailyReportStaff,
            text: "Staff Daily Report",
            path: '/staff-daily-report',
        },
        {
            img: assetsWhite,
            img1: assets,
            text: "Assets",
            path: '/asset',
        },
        {
            img: nocWhite,
            img1: noc,
            text: "Liasoning",
            path: '/noc',
        }
    ]

    const NoRoleSidebarMenu = [
        // {
        //     img: dashbordWhite,
        //     img1: dashbord,
        //     text: "Dashboard",
        //     path: '/dashboard',
        // }
    ]

    const viewerSidebarMenu = [
        {
            img: dashbordWhite,
            img1: dashbord,
            text: "Dashboard",
            path: '/dashboard',
        },
        {
            img: enquiryWhite,
            img1: enquiry,
            text: "Sales",
            path: '/enquiry',
        },
        {
            img: taskWhite,
            img1: task,
            text: "Task",
            path: '/task',
        },
        {
            img: projectWhite,
            img1: project,
            text: "Project Mng.",
            subMenu: [
                {
                    img: projectWhite,
                    img1: project,
                    text: "Projects",
                    path: '/project',
                },
                {
                    img: dailyreportWhite,
                    img1: dailyreport,
                    text: "Daily Report",
                    path: '/daily-report',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequest',
                },
            ]
        },
        {
            img: summaryWhite,
            img1: summary,
            text: "Store",
            subMenu: [
                {
                    img: summaryWhite,
                    img1: summary,
                    text: "Summary",
                    path: '/stock-summary',
                },
                {
                    img: productWhite,
                    img1: product,
                    text: "Product",
                    path: '/product',
                },
                {
                    img: saleWhite,
                    img1: sale,
                    text: "Sale",
                    path: '/sale',
                },
                {
                    img: purchaseWhite,
                    img1: purchase,
                    text: "Purchase",
                    path: '/purchase',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequests',
                },
                {
                    img: returnWhite,
                    img1: returnDark,
                    text: "Return Material",
                    path: '/returnmaterial',
                },
            ]
        },
        {
            img: dailyReportStaffWhite,
            img1: dailyReportStaff,
            text: "Staff Daily Report",
            path: '/staff-daily-report',
        },
        {
            img: accountWhite,
            img1: account,
            text: "Account",
            path: '/account',
        },
        {
            img: assetsWhite,
            img1: assets,
            text: "Assets",
            path: '/asset',
        },
        {
            img: nocWhite,
            img1: noc,
            text: "Liasoning",
            path: '/noc',
        },
        {
            img: customerWhite,
            img1: customer,
            text: "Customer",
            path: '/customer',
        },
        {
            img: customerWhite,
            img1: customer,
            text: "Contractor",
            path: '/contractor',
        },
        {
            img: settingWhite,
            img1: setting,
            text: "Setting",
            path: '/setting',
        },
    ]

    const MaintanceSidebarMenu = [
        {
            img: dashbordWhite,
            img1: dashbord,
            text: "Dashboard",
            path: '/dashboard',
        },
        {
            img: taskWhite,
            img1: task,
            text: "Task",
            path: '/task',
        },
        {
            img: projectWhite,
            img1: project,
            text: "Project Mng.",
            subMenu: [
                {
                    img: projectWhite,
                    img1: project,
                    text: "Projects",
                    path: '/project',
                },
                {
                    img: dailyreportWhite,
                    img1: dailyreport,
                    text: "Daily Report",
                    path: '/daily-report',
                },
                {
                    img: prWhite,
                    img1: pr,
                    text: "Purchase Request",
                    path: '/purchaserequest',
                },
            ]
        },
        {
            img: dailyReportStaffWhite,
            img1: dailyReportStaff,
            text: "Staff Daily Report",
            path: '/staff-daily-report',
        },
    ]

    useEffect(() => {
        sidebarBtn?.map((item, i) => {
            if ("path" in item) {
                if (item.path === location?.pathname) {
                    setOpenSubMenu('')
                }
            } else if ('subMenu' in item) {
                const activeSubItem = item.subMenu.find(sub => sub.path === location?.pathname)
                if (activeSubItem) {
                    setOpenSubMenu(item.text)
                }
            }

        })
    }, [location.pathname, sidebarBtn])

    const openMenu = (Item) => {
        if ('subMenu' in Item) {
            if (openSubMenu === Item.text) {
                setOpenSubMenu('')
            } else if (Item.subMenu) {
                setOpenSubMenu(Item.text)
            }
        } else {
            setOpenSubMenu('')
        }
    }

    const logOut = () => {
        AuthStorage.deauthenticateUser()
        navigate("/")
        dispatch({
            type: LOGIN,
            payload: undefined
        })
    }

    useEffect(() => {
        // const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData);
        if (userRole === UserRoles.Admin) {
            setSidebarBtn(adminSidebarMenu)
        } else if (userRole === UserRoles.Account) {
            setSidebarBtn(accountSidebarMenu)
        } else if (userRole === UserRoles.Liasoning) {
            setSidebarBtn(LiasoningSidebarMenu)
        } else if (userRole === UserRoles.Project) {
            setSidebarBtn(projectSidebarMenu)
        } else if (userRole === UserRoles.Sales) {
            setSidebarBtn(salesSidebarMenu)
        } else if (userRole === UserRoles.Store) {
            setSidebarBtn(storeStockSidebarMenu)
        } else if (userRole === UserRoles.Viewer) {
            setSidebarBtn(viewerSidebarMenu)
        } else if (userRole === UserRoles.Maintance) {
            setSidebarBtn(MaintanceSidebarMenu)
        } else {
            setSidebarBtn(NoRoleSidebarMenu)
        }
    }, [userRole])

    return (
        <div className=''>
            {/* <div className="sidebar-close-btn" onClick={() => setSidebar(!showSidebar)}>
            </div> */}
            <div className="sidebar-close-btn">
            </div>
            {/* <div className="sidebar" style={{ width: showSidebar ? "100%" : "0px" }}> */}
            <div className="sidebar">
                <div className="logo">
                    {/* <h1>LOGO</h1> */}
                    <img src={logo} alt="" />
                </div>
                <div className='sidebar-links'>
                    {
                        sidebarBtn && sidebarBtn.map((item, i) => (
                            <div key={i}>
                                {
                                    "subMenu" in item ?
                                        <div>
                                            {/* <Button className={` ${location?.pathname.includes(item.path) && 'active'} disabel d-flex justify-content-between`} onClick={() => openMenu(item)} key={i} > */}
                                            <Button className={`${location?.pathname === item.path && 'active'} disabel d-flex justify-content-between`} onClick={() => openMenu(item)} key={i} >
                                                {/* <Button className={` ${location?.pathname.includes(item.path) && 'active'} disabel d-flex justify-content-between`} onClick={() => { openSubMenu !== item.text ? setOpenSubMenu(item.text) : setOpenSubMenu('') }} key={i} > */}
                                                <div className='d-flex menu'>
                                                    <div className='icons-img'>
                                                        <img src={location?.pathname.includes(item.path) ? item.img : item.img1} alt="" width="18px" height="18px" />
                                                    </div>
                                                    <span> {item.text} </span>
                                                </div>
                                                <img src={arrowImg} className={`${openSubMenu === item.text && "rotateArrow"}`} style={{ width: '12px' }} alt='' />
                                            </Button>
                                            {openSubMenu === item.text &&
                                                item.subMenu.map((subItem, index) =>
                                                    <Link to={subItem.path} key={`${i}-${index}`} >
                                                        <Button className={` ${location?.pathname === subItem.path && 'active'} disabel ms-4 w-88 menu`} onClick={() => { }} >
                                                            <div className='icons-img'>
                                                                <img src={location?.pathname.includes(subItem.path) ? subItem.img : subItem.img1} alt="" width="18px" height="18px" />
                                                            </div>
                                                            <span> {subItem.text} </span>
                                                        </Button>
                                                    </Link>
                                                )
                                            }
                                            {/* {openSubMenu && <Link to={item.subMenu.path} key={i} >
                                                <Button className={` ${location?.pathname.includes(item.subMenu.path) && 'active'} disabel `} onClick={() => { }} >
                                                    <div className='icons-img'>
                                                        <img src={location?.pathname.includes(item.subMenu.path) ? item.subMenu.img : item.subMenu.img1} alt="" width="18px" height="18px" />
                                                    </div>
                                                    <span> {item.subMenu.text} </span>
                                                </Button>
                                            </Link>} */}
                                        </div>
                                        :
                                        <Link to={item.path}>
                                            <Button className={` ${location.pathname.includes(item.path) && 'active'} disabel menu`} onClick={() => { }} >
                                                <div className='icons-img'>
                                                    <img src={location.pathname.includes(item.path) ? item.img : item.img1} alt="" width="18px" height="18px" />
                                                </div>
                                                <span> {item.text} </span>
                                            </Button>
                                        </Link>
                                }

                            </div>
                        ))
                    }
                </div>
                <footer className='copyright' >
                    <button style={{ borderRadius: "5px" }} onClick={() => { setLogOutTog(true) }} className="logout-button disabel menu">
                        <img src={logoutImg} alt="" width="20px" className="me-2" />
                        Logout
                    </button>
                    <div className='d-flex w-100 h-100 justify-content-center mt-4'>
                        <img src={poweredByLogo} width='130' />
                    </div>
                </footer>
            </div>
            {logOutTog &&
                <SweetAlert
                    success
                    showCancel
                    confirmBtnText="Logout"
                    confirmBtnBsStyle="success"
                    title="Are you sure?"
                    onConfirm={() => logOut()}
                    onCancel={() => { setLogOutTog(false) }}
                    focusCancelBtn
                >
                    You want to Logout
                </SweetAlert>}
        </div>
    )
}

export default Sidebar