import { ADD_BANK, ADD_BANK_ERROR, ADD_BANK_LOADING, DELETE_BANK, DELETE_BANK_ERROR, DELETE_BANK_LOADING, EDIT_BANK, EDIT_BANK_ERROR, EDIT_BANK_LOADING, GET_ALL_BANK, GET_ALL_BANK_ERROR, GET_ALL_BANK_LOADING, GET_BANK_BY_ID, GET_BANK_BY_ID_ERROR, GET_BANK_BY_ID_LOADING, GET_BANK_DROP_DOWN, GET_BANK_DROP_DOWN_ERROR, GET_BANK_DROP_DOWN_LOADING } from "../type"

const addBankData = {
    loading:false,
    addBank : [],
    error:'',
};

const getBankByIdData = {
    loading:false,
    getBankById : [],
    error:'',
};

const getAllBankData = {
    loading:false,
    getAllBank : [],
    error:'',
};

const editBankData = {
    loading:false,
    editBank : [],
    error:'',
};

const deleteBankData = {
    loading:false,
    deleteBank : [],
    error:'',
};

const getBankDropDownData = {
    loading:false,
    getBankDropDown : [],
    error:'',
};

export const addBankReducer = (state = addBankData, action) => {
    switch (action.type) {
        case ADD_BANK_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_BANK:
            return {
                ...state,
                addBank: action.payload,
            };
        case ADD_BANK_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getBankByIdReducer = (state = getBankByIdData, action) => {
    switch (action.type) {
        case GET_BANK_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_BANK_BY_ID:
            return {
                ...state,
                getBankById: action.payload,
            };
        case GET_BANK_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllBankReducer = (state = getAllBankData, action) => {
    switch (action.type) {
        case GET_ALL_BANK_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_BANK:
            return {
                ...state,
                getAllBank: action.payload,
            };
        case GET_ALL_BANK_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editBankReducer = (state = editBankData, action) => {
    switch (action.type) {
        case EDIT_BANK_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_BANK:
            return {
                ...state,
                editBank: action.payload,
            };
        case EDIT_BANK_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteBankReducer = (state = deleteBankData, action) => {
    switch (action.type) {
        case DELETE_BANK_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_BANK:
            return {
                ...state,
                deleteBank: action.payload,
            };
        case DELETE_BANK_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getBankDropDownReducer = (state = getBankDropDownData, action) => {
    switch (action.type) {
        case GET_BANK_DROP_DOWN_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_BANK_DROP_DOWN:
            return {
                ...state,
                getBankDropDown: action.payload,
            };
        case GET_BANK_DROP_DOWN_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}