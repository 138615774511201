import { ADD_SITE_REPORTS, ADD_SITE_REPORTS_ERROR, ADD_SITE_REPORTS_LOADING, DELETE_DAILY_REPORTS, DELETE_DAILY_REPORTS_ERROR, DELETE_DAILY_REPORTS_LOADING, EDIT_SITE_REPORTS_BY_ID, EDIT_SITE_REPORTS_BY_ID_ERROR, EDIT_SITE_REPORTS_BY_ID_LOADING, GET_ALL_SITE_REPORTS, GET_ALL_SITE_REPORTS_ERROR, GET_ALL_SITE_REPORTS_ERROR_NO_AUTH, GET_ALL_SITE_REPORTS_LOADING, GET_ALL_SITE_REPORTS_LOADING_NO_AUTH, GET_ALL_SITE_REPORTS_NO_AUTH, GET_SITE_REPORTS_BY_ID, GET_SITE_REPORTS_BY_ID_ERROR, GET_SITE_REPORTS_BY_ID_LOADING, GET_SITE_REPORTS_BY_PROJECT_ID, GET_SITE_REPORTS_BY_PROJECT_ID_ERROR, GET_SITE_REPORTS_BY_PROJECT_ID_LOADING } from "../type";

const getAllSiteReportsData = {
    loading: false,
    getAllSiteReports: [],
    error: '',
}

const getAllSiteReportsNoAuthData = {
    loading: false,
    getAllSiteReportsNoAuth: [],
    error: '',
}

const getSiteReportsByIdData = {
    loading: false,
    getSiteReportsById: [],
    error: '',
}

const getSiteReportsNoAuthByIdData = {
    loading: false,
    getSiteReportsNoAuthById: [],
    error: '',
}

const addSiteReportsData = {
    loading: false,
    addSiteReports: [],
    error: '',
}

const getSiteReportsByProjectIdData = {
    loading: false,
    getSiteReportsByProjectId: [],
    error: '',
}

const editSiteReportsData = {
    loading: false,
    editSiteReports: [],
    error: '',
}

const deleteSiteReportsData = {
    loading: false,
    deleteSiteReports: [],
    error: '',
}

export const getAllSiteReportsReducer = (state = getAllSiteReportsData, action) => {
    switch (action.type) {
        case GET_ALL_SITE_REPORTS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_SITE_REPORTS:
            return {
                ...state,
                getAllSiteReports: action.payload,
            };
        case GET_ALL_SITE_REPORTS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllSiteReportsNoAuthReducer = (state = getAllSiteReportsNoAuthData, action) => {
    switch (action.type) {
        case GET_ALL_SITE_REPORTS_LOADING_NO_AUTH:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_SITE_REPORTS_NO_AUTH:
            return {
                ...state,
                getAllSiteReportsNoAuth: action.payload,
            };
        case GET_ALL_SITE_REPORTS_ERROR_NO_AUTH:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getSiteReportsByIdReducer = (state = getSiteReportsByIdData, action) => {
    switch (action.type) {
        case GET_SITE_REPORTS_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_SITE_REPORTS_BY_ID:
            return {
                ...state,
                getSiteReportsById: action.payload,
            };
        case GET_SITE_REPORTS_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getSiteReportsNoAuthByIdReducer = (state = getSiteReportsNoAuthByIdData, action) => {
    switch (action.type) {
        case GET_SITE_REPORTS_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_SITE_REPORTS_BY_ID:
            return {
                ...state,
                getSiteReportsById: action.payload,
            };
        case GET_SITE_REPORTS_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const addSiteReportsReducer = (state = addSiteReportsData, action) => {
    switch (action.type) {
        case ADD_SITE_REPORTS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_SITE_REPORTS:
            return {
                ...state,
                addSiteReports: action.payload,
            };
        case ADD_SITE_REPORTS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getSiteReportsByProjectIdReducer = (state = getSiteReportsByProjectIdData, action) => {
    switch (action.type) {
        case GET_SITE_REPORTS_BY_PROJECT_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_SITE_REPORTS_BY_PROJECT_ID:
            return {
                ...state,
                getSiteReportsByProjectId: action.payload,
            };
        case GET_SITE_REPORTS_BY_PROJECT_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editSiteReportsReducer = (state = editSiteReportsData, action) => {
    switch (action.type) {
        case EDIT_SITE_REPORTS_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_SITE_REPORTS_BY_ID:
            return {
                ...state,
                editSiteReports: action.payload,
            };
        case EDIT_SITE_REPORTS_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteSiteReportsReducer = (state = deleteSiteReportsData, action) => {
    switch (action.type) {
        case DELETE_DAILY_REPORTS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_DAILY_REPORTS:
            return {
                ...state,
                deleteSiteReports: action.payload,
            };
        case DELETE_DAILY_REPORTS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
