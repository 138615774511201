import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deleteNocAction, getAllNocsAction } from '../../redux/actions/nocAction'
import { AgGridReact } from 'ag-grid-react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import removeImg from '../../assets/image/delete-red.png'
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import { API } from '../../config/API/api.config';
import editIcon from '../../assets/image/edit.svg';
import { FILE_UPLOAD, GET_NOC_BY_ID } from '../../redux/type';
import ViewNoc from '../../components/view-modals/ViewNoc';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Noc = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useContext(RoleContext);
  const RolesForAdd = [UserRoles.Admin, UserRoles.Liasoning]
  const [liasoning, setLiasoning] = useState([])
  const [deletepopup, setDeletepopup] = useState(false);
  const [paramsValue, setParamsValue] = useState();
  const toastSuccess = () => toast.success('NOC deleted successfully');
  const [columnDefs, setColumnDefs] = useState([]);
  const [nocTog, setNocTog] = useState(false);
  const [nocId, setNocId] = useState('');
  const { getAllNoc } = usePaginationHooks()
  const [gridApi, setGridApi] = useState(null);
  const [limit, setlimit] = useState(Paginationlimit);
  const [filter, setFilter] = useState({ search: "" });
  const [search, setSearch] = useState("");
  const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

  // const getAllNocData = useSelector((state) => state.get_All_Nocs.getAllNocs.data)
  const editNocData = useSelector((state) => state.edit_Noc.editNoc)
  const deleteNocData = useSelector((state) => state.delete_Noc.deleteNoc)

  const onEdit = (id) => {
    navigate(`edit?nocId=${id}`)
  }

  const handleRowClick = (params) => {
    if (params.colDef.field !== "_id" && params.colDef.field !== "displayCertificateImage") {
      return onEdit(params.data._id);
    }
  }

  // useEffect(() => {
  //   if (getAllNocData) {
  //     setLiasoning(getAllNocData.map((ele) => {
  //       return {
  //         customer: ele.customer ? ele.customer : '',
  //         partyName: ele.partyName ? ele.partyName : '',
  //         location: ele.location ? ele.location : '',
  //         cityOrOutcity: ele.cityOrOutcity ? ele.cityOrOutcity : '',
  //         provisionalOrFinal: ele.provisionalOrFinal ? ele.provisionalOrFinal : '',
  //         refBy: ele.refBy ? ele.refBy : '',
  //         certificateImage: ele.certificateImage ? ele.certificateImage : '',
  //         createdBy: ele.createdBy ? ele.createdBy : '',
  //         _id: ele._id ? ele._id : '',
  //       }
  //     }))
  //   }
  // }, [getAllNocData])


  const onDelete = (id) => {
    dispatch(deleteNocAction(id))
    setDeletepopup(false)
    toastSuccess(true)
  }

  const downloadFile = (params) => {
    window.open(`${API?.endpoint}/${params?.data?.certificateImage}`, "_blank")
  }

  const columnDef = [
    { headerName: 'Customer', field: 'customer.accountName', minWidth: 100, },
    { headerName: 'PartyName', field: 'partyName', minWidth: 100, },
    { headerName: 'Location', field: 'location', minWidth: 100, },
    { headerName: 'City', field: 'cityOrOutcity', maxWidth: 100, },
    { headerName: 'Provisional or Final', field: 'provisionalOrFinal', maxWidth: 160, },
    { headerName: 'Ref By', field: 'refBy', minWidth: 100, },
    {
      headerName: 'Certificate Image',
      maxWidth: 140,
      field: 'displayCertificateImage',
      sortable: false,
      cellRendererFramework: (params) => <div style={{ margin: "5px auto" }}>
        {params?.data?.certificateImage ?
          <button className='edit-button' style={{ height: '32px', width: '32px', lineHeight: '34px' }} onClick={() => downloadFile(params)}>
            <img src="./assets/image/download.png" alt="" width="15px" />
          </button>
          :
          <p>-</p>
        }
      </div>
    },
    // {
    //   field: "CreatedBy",
    //   minWidth: 150,
    //   cellRendererFramework: (params) => <div>
    //     {params?.value?.name}
    //   </div>
    // },
    {
      headerName: 'Action',
      field: "_id",
      minWidth: 100,
      maxWidth: 140,
      sortable: false,
      cellRendererFramework: (params) =>
        <div>
          {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <>
            {/* <img src={editIcon} onClick={() => onEdit(params?.value)} style={{ cursor: "pointer", height: "20px" }} title="Edit" alt='' /> */}
            <img src={removeImg} onClick={() => { setDeletepopup(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' /></>}
          <img src={viewImg} onClick={() => { setNocTog(true); setNocId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
        </div>
    }
  ]

  const NoRolecolumnDef = [
    { headerName: 'Customer', field: 'customer.accountName', minWidth: 100, },
    { headerName: 'PartyName', field: 'partyName', minWidth: 100, },
    { headerName: 'Location', field: 'location', minWidth: 100, },
    { headerName: 'City', field: 'cityOrOutcity', maxWidth: 100, },
    { headerName: 'Provisional or Final', field: 'provisionalOrFinal', maxWidth: 160, },
    { headerName: 'Ref By', field: 'refBy', minWidth: 100, },
    {
      headerName: 'Certificate Image',
      maxWidth: 140,
      field: 'displayCertificateImage',
      sortable: false,
      cellRendererFramework: (params) => <div style={{ margin: "5px auto" }}>
        {params?.data?.certificateImage ?
          <button className='edit-button' style={{ height: '32px', width: '32px', lineHeight: '34px' }} onClick={() => downloadFile(params)}>
            <img src="./assets/image/download.png" alt="" width="15px" />
          </button>
          :
          <p>-</p>
        }
      </div>
    },
    // {
    //   field: "CreatedBy",
    //   minWidth: 150,
    //   cellRendererFramework: (params) => <div>
    //     {params?.value?.name}
    //   </div>
    // },
    {
      headerName: 'Action',
      field: "_id",
      minWidth: 100,
      maxWidth: 140,
      sortable: false,
      cellRendererFramework: (params) =>
        <div>
          <img src={viewImg} onClick={() => { setNocTog(true); setNocId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
        </div>
    }
  ]

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      width: 'auto',
      filter: false,
      flex: 1
    };
  }, []);

  useEffect(() => {
    if (RolesForAdd.includes(userRole)) {
      setColumnDefs(columnDef)
    } else {
      setColumnDefs(NoRolecolumnDef)
    }
    // dispatch(getAllNocsAction())
  }, [])

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const clearFilter = () => {
    setFilter({ search: '' });
    setSearch('');
  }

  const debouncedHandleSearch = useCallback(debounce((value) => {
    setFilter({ ...filter, ["search"]: value });
  }, 1000), []);

  useEffect(() => {
    debouncedHandleSearch(search);
  }, [search]);

  useEffect(() => {
    if (gridApi || deleteNocData?.data?.status === 200 || editNocData?.data?.status === 200 || filter.search) {
      const dataSource = {
        getRows: (params) => {
          const page = params.endRow / limit;
          const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
          const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

          getAllNoc(page, limit, filter.search, sorted_column, sorted_order)
            .then(res => {
              // setgetAllReturnMaterialdata(res.data)
              params.successCallback(res?.data.map((ele) => {
                return {
                  customer: ele.customer ? ele.customer : '',
                  partyName: ele.partyName ? ele.partyName : '',
                  location: ele.location ? ele.location : '',
                  cityOrOutcity: ele.cityOrOutcity ? ele.cityOrOutcity : '',
                  provisionalOrFinal: ele.provisionalOrFinal ? ele.provisionalOrFinal : '',
                  refBy: ele.refBy ? ele.refBy : '',
                  certificateImage: ele.certificateImage ? ele.certificateImage : '',
                  createdBy: ele.createdBy ? ele.createdBy : '',
                  _id: ele._id ? ele._id : '',
                }
              }), res.totalCount ? res.totalCount : 0)

              if (res?.data?.length <= 0) {
                gridApi.showNoRowsOverlay();
              } else {
                gridApi.hideOverlay();
              }
            })
        }
      };
      gridApi?.setDatasource(dataSource);
    }

    // setisFilter(false)
  }, [gridApi, limit, deleteNocData, editNocData, filter.search]);

  return (
    <>
      <div className='position-relative p-4'>
        <div className='mb-4 set-page-heade'>
          <div className='d-flex flex-column'>
            <h2 className='mb-0'>NOC</h2>
          </div>
          <div className='d-flex'>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value.trimStart())}
                autoComplete="off"
              />
            </div>
            <button className='adduser-btn ms-3' onClick={() => clearFilter()}>Clear Filter</button>
            {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => { navigate('add'); dispatch({ type: FILE_UPLOAD, payload: [] }); dispatch({ type: GET_NOC_BY_ID, payload: [] }) }}>Add NOC</button>}
          </div>
        </div>
        <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

          <AgGridReact
            pagination={true}
            columnDefs={columnDefs}
            rowModelType={"infinite"}
            paginationPageSize={limit}
            cacheBlockSize={limit}
            onGridReady={onGridReady}
            rowSelection={'single'}
            onCellClicked={(event) => {
              const rowData = event.data;
              if (rowData?.createdBy?._id === userData.id || userRole === UserRoles.Admin) {
                handleRowClick(event)
              }
            }}
            rowHeight={45}
            defaultColDef={defaultColDef}
            overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
          />

          <div className='set-pagination-dropdaun'>
            <p>Show</p>
            <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
              <option value="50">
                50
              </option>
              <option value="100" selected={true}>100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
            <p>Entries</p>
          </div>
        </div>
        {nocTog && <ViewNoc show={nocTog} onHide={() => { setNocTog(false); setNocId('') }} nocId={nocId} />}
        {deletepopup &&
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Delete"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={() => { onDelete(paramsValue) }}
            onCancel={() => { setDeletepopup(false); setParamsValue('') }}
            focusCancelBtn
          >
            You want to delete it
          </SweetAlert>
        }
      </div>
    </>
  )
}

export default Noc