import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../redux/actions/userAction';
// import logo from "../../assets/img/fire-safety.png"  //  for Local
import logo from "../../assets/img/logo.bmp"  // for shhradha firesafety
import poweredByLogo from "../../assets/img/powerd_by_grey.png"
import hide from '../../assets/image/hide-password.svg'
import show from '../../assets/image/show-password.svg'
import { UserRoles } from '../../helper/Constant';

function Login() {

  const loginError = useSelector((state) => state.login.login)

  const [userData, setUserData] = useState({
    emailId: "",
    password: "",
  })
  const [type, setType] = useState("password")
  const [formErrors, setFormErrors] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const RolesArr = [UserRoles.Account, UserRoles.Admin, UserRoles.Liasoning, UserRoles.Project, UserRoles.Viewer, UserRoles.Sales, UserRoles.Store, UserRoles.Maintance]

  const onChangeHandler = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const Data = useSelector((state) => state.login.login)

  useEffect(() => {
    if (Data && Data.message === "Login Successfully") {
      if (Data.data.role === "" || !Data.data.role || !RolesArr.includes(Data.data.role)) {
        navigate('/error')
      } else {
        navigate('/dashboard')
      }
    }
  }, [Data])

  const validation = () => {
    let flag = false
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!userData.emailId) {
      error.email = "Please Enter Email ";
      flag = true
    } else if (!userData.emailId.match(regex)) {
      error.email = "Enter Valid Email";
      flag = true
    }
    if (!userData.password) {
      error.password = "Please Enter Password";
      flag = true
    }
    else if (userData.password.length < 8) {
      error.password = "Please Password Must be 8 Character"
      flag = true
    }
    setFormErrors(error);
    return flag;
  }

  const onSubmit = () => {
    if (validation()) {
      return
    }
    const data = {
      email: userData.emailId,
      password: userData.password
    }
    dispatch(loginAction(data))
  }

  const passwordDisplay = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <div className='w-100
    '>
      <div className='login'>
        <div className='login-logo'>
          <img src={logo} alt="" />
        </div>
        <div className='login-head d-flex flex-column w-100 text-center mt-3'>
          <h3>Sign In</h3>
          <h6>Sign in to stay connected.</h6>
        </div>
        <div className='login-body w-100 mt-3 d-flex flex-column'>
          <div className='d-flex flex-column users-label'>
            <label >Email</label>
            <input type='email' placeholder='Email' value={userData.emailId} name="emailId" onChange={(e) => onChangeHandler(e)} />
            {formErrors?.email !== undefined && <label style={{ color: "red" }}>{formErrors.email}</label>}
          </div>
          <div className='d-flex flex-column users-label position-relative'>
            <label >Password</label>
            <input type={type} placeholder='Password' value={userData.password} name="password" onChange={(e) => onChangeHandler(e)} />
            <img src={type === "password" ? show : hide} alt="" onClick={() => passwordDisplay()} />
            {formErrors?.password !== undefined && <label style={{ color: "red" }}>{formErrors.password}</label>}
          </div>
          {/* <div className='d-flex flex-column w-100 text-end '>
            <span className='input-link' >Forgot Password?</span>
          </div> */}
          {loginError?.message && <label style={{ color: "red" }}>{loginError.message}</label>}
          <div className='d-flex w-100 justify-content-center mt-4'>
            <button className='save-button' onClick={onSubmit}>Sign in</button>
          </div>
          <div className='d-flex w-100 h-100 justify-content-end mt-5'>
            <img src={poweredByLogo} width='150' />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login