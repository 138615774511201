import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import AddCustomer from '../../components/modals/AddCustomer'
import { toast } from 'react-toastify';
import { deleteCustomerAction, getAllCustomerAction } from '../../redux/actions/customerAction';
import viewImg from '../../assets/image/eye.svg'
import removeImg from '../../assets/image/delete-red.png'
import { DELETE_CUSTOMER } from '../../redux/type';
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import editIcon from '../../assets/image/edit.svg';
import ViewCustomer from '../../components/view-modals/ViewCustomer';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Customer = () => {
    const dispatch = useDispatch()
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin]
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [customerTog, setCustomerTog] = useState(false);
    const [customerId, setCustomerId] = useState('')
    const [columnDefs, setColumnDefs] = useState([]);
    const [viewTog, setViewTog] = useState(false);
    const [viewId, setViewId] = useState('');
    const { getAllCustomer } = usePaginationHooks()
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit);
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    const toastSuccess = () => toast.success('Customer delete successfully');

    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

    // const allCustomer = useSelector((state) => state.get_All_Customer.getAllCustomer)
    const deleteUserData = useSelector((state) => state.delete_Customer.deleteCustomer)
    const editUserData = useSelector((state) => state.edit_Customer.editCustomer)
    const addCustomer = useSelector((state) => state.add_Customer.addCustomer)

    // useEffect(() => {
    //     if (allCustomer && allCustomer.data) {
    //         setCustomerData(allCustomer.data.map((ele) => {
    //             return {
    //                 gstin: ele.gstin ? ele.gstin : '',
    //                 accountName: ele.accountName ? ele.accountName : '',
    //                 address: ele.address ? ele.address : '',
    //                 shippingAddress: ele.shippingAddress ? ele.shippingAddress : '',
    //                 contactPersonName: ele.contactPersonName ? ele.contactPersonName : '',
    //                 contactPersonNumber: ele.contactPersonNumber ? ele.contactPersonNumber : '',
    //                 createdBy: ele.createdBy ? ele.createdBy : '',
    //                 _id: ele._id ? ele._id : '',
    //             }
    //         }))
    //     }
    // }, [allCustomer])

    const columnDef = [
        {
            field: 'gstin',
            minWidth: 60,
            headerName: 'GSTIN'
        },
        { field: 'accountName', flex: 1, },
        { field: 'address', minWidth: 60, },
        { field: 'shippingAddress', minWidth: 60, },
        { field: 'contactPersonName', minWidth: 60, },
        { field: 'contactPersonNumber', maxWidth: 120, },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 240,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 100,
            maxWidth: 140,
            sortable: false,
            cellRendererFramework: (params) => <div>
                {/* {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <><img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" />
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Reject" /></>} */}
                {/* <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" /> */}
                <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Reject" />
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="View" />
            </div>
        }
    ]

    const NoRolecolumnDef = [
        {
            field: 'gstin',
            minWidth: 60,
            headerName: 'GSTIN'
        },
        { field: 'accountName', flex: 1, },
        { field: 'address', minWidth: 60, },
        { field: 'shippingAddress', minWidth: 60, },
        { field: 'contactPersonName', minWidth: 60, },
        { field: 'contactPersonNumber', maxWidth: 120, },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 240,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 100,
            maxWidth: 140,
            sortable: false,
            cellRendererFramework: (params) => <div>
                {(params?.data?.createdBy?._id === userData.id) && <>
                    {/* <img src={viewImg} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" /> */}
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Delete" /></>}
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="View" />
            </div>
        }
    ]

    const onEdit = (id) => {
        setCustomerTog(true)
        setCustomerId(id)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    const onDelete = (id) => {
        dispatch(deleteCustomerAction(id))
        setReject(false)
    }

    useEffect(() => {
        if (deleteUserData && deleteUserData.data) {
            if (deleteUserData?.data?.message === "Customer deleted successfully.") {
                dispatch({
                    type: DELETE_CUSTOMER,
                    payload: []
                })
                // dispatch(getAllCustomerAction())
                toastSuccess(true)
            }
        }
    }, [deleteUserData])

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        if (RolesForAdd.includes(userRole)) {
            setColumnDefs(columnDef)
        } else {
            setColumnDefs(NoRolecolumnDef)
        }
        // dispatch(getAllCustomerAction())
    }, [])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || deleteUserData?.data?.status === 200 || editUserData?.data?.status === 200 || addCustomer?.data?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllCustomer(page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            // setgetAllReturnMaterialdata(res.data)
                            params.successCallback(res?.data?.map((ele) => {
                                return {
                                    gstin: ele.gstin ? ele.gstin : '-',
                                    accountName: ele.accountName ? ele.accountName : '',
                                    address: ele.address ? ele.address : '',
                                    shippingAddress: ele.shippingAddress ? ele.shippingAddress : '',
                                    contactPersonName: ele.contactPersonName ? ele.contactPersonName : '',
                                    contactPersonNumber: ele.contactPersonNumber ? ele.contactPersonNumber : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res?.totalCount ? res?.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

        // setisFilter(false)
    }, [gridApi, limit, deleteUserData, editUserData, addCustomer, filter.search]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>Customer</h2>
                    </div>
                    <div className='d-flex'>
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn ms-3' onClick={() => clearFilter()}>Clear Filter</button>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => setCustomerTog(true)}>Add Customer</button>}
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        onCellClicked={(event) => {
                            if (RolesForAdd.includes(userRole) || event?.data?.createdBy?._id === userData.id) {
                                handleRowClick(event)
                            }
                        }}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />

                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
            </div>
            {customerTog && <AddCustomer show={customerTog} onHide={() => { setCustomerTog(false); setCustomerId('') }} customerId={customerId} />}
            {viewTog && <ViewCustomer show={viewTog} onHide={() => { setViewTog(false); setViewId('') }} viewId={viewId} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}

export default Customer   