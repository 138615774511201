import { ADD_TASK, ADD_TASK_ERROR, ADD_TASK_LOADING, DELETE_TASK, DELETE_TASK_ERROR, DELETE_TASK_LOADING, EDIT_TASK, EDIT_TASK_ERROR, EDIT_TASK_FOR_KANBAN, EDIT_TASK_FOR_KANBAN_ERROR, EDIT_TASK_FOR_KANBAN_LOADING, EDIT_TASK_LOADING, GET_ALL_TASK, GET_ALL_TASK_ERROR, GET_ALL_TASK_LOADING, GET_TASK_BY_ID, GET_TASK_BY_ID_ERROR, GET_TASK_BY_ID_LOADING, TASK_DASHBOARD, TASK_DASHBOARD_ERROR, TASK_DASHBOARD_LOADING } from "../type";

const addTaskData = {
    loading: false,
    addTask: [],
    error: '',
}

const getTaskByIdData = {
    loading: false,
    getTaskById: [],
    error: '',
}

const getAllTaskData = {
    loading: false,
    getAllTask: [],
    error: '',
}

const editTaskData = {
    loading: false,
    editTask: [],
    error: '',
}

const deleteTaskData = {
    loading: false,
    deleteTask: [],
    error: '',
}

const editTaskForKanbanData = {
    loading: false,
    editTaskForKanban: [],
    error: '',
}

const taskDashboardData = {
    loading: false,
    taskDashboard: [],
    error: '',
}

export const addTaskReducer = (state = addTaskData, action) => {
    switch (action.type) {
        case ADD_TASK_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_TASK:
            return {
                ...state,
                addTask: action.payload,
            };
        case ADD_TASK_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getTaskByIdReducer = (state = getTaskByIdData, action) => {
    switch (action.type) {
        case GET_TASK_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_TASK_BY_ID:
            return {
                ...state,
                getTaskById: action.payload,
            };
        case GET_TASK_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllTaskReducer = (state = getAllTaskData, action) => {
    switch (action.type) {
        case GET_ALL_TASK_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_TASK:
            return {
                ...state,
                getAllTask: action.payload,
            };
        case GET_ALL_TASK_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editTaskReducer = (state = editTaskData, action) => {
    switch (action.type) {
        case EDIT_TASK_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_TASK:
            return {
                ...state,
                editTask: action.payload,
            };
        case EDIT_TASK_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteTaskReducer = (state = deleteTaskData, action) => {
    switch (action.type) {
        case DELETE_TASK_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_TASK:
            return {
                ...state,
                deleteTask: action.payload,
            };
        case DELETE_TASK_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editTaskForKanbanReducer = (state = editTaskForKanbanData, action) => {
    switch (action.type) {
        case EDIT_TASK_FOR_KANBAN_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_TASK_FOR_KANBAN:
            return {
                ...state,
                editTaskForKanban: action.payload,
            };
        case EDIT_TASK_FOR_KANBAN_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default: return state;
    }
}

export const taskDashboardReducer = (state = taskDashboardData, action) => {
    switch (action.type) {
        case TASK_DASHBOARD_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case TASK_DASHBOARD:
            return {
                ...state,
                taskDashboard: action.payload,
            };
        case TASK_DASHBOARD_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default: return state;
    }
}
