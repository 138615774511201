import React, { useEffect, useState } from 'react'
import ViewPieDesk from '../../../components/dashboard/ViewPieDesk'
import ViewBarDesk from '../../../components/dashboard/ViewBarDesk'
import ViewTableDesk from '../../../components/dashboard/ViewTableDesk'
import { ApiGet, } from '../../../helper/API/ApiData'
import { useSelector } from 'react-redux'


const LiasoningDashboard = () => {
  const [countStatus, setcountStatus] = useState([])
  const [countByMonth, setcountByMonth] = useState([])
  const [recentNoc, setrecentNoc] = useState([])
  const [taskData, settaskData] = useState([])
  const taskList = useSelector((state) => state.task_Dashboard.taskDashboard)
  

  let startYear = 2021
  let currentYear = new Date().getFullYear() 
  const yearOption = [...Array(currentYear - startYear + 1).keys()].map((e) => {
    return { label : (e + startYear).toString() , value : (e + startYear).toString()};
  })
  const [year, setyear] = useState({label: currentYear.toString(), value: currentYear.toString()})
  
  useEffect(() => {
    ApiGet('noc/get_noc_count_by_status')
    .then((res) => {
      setcountStatus([['Label', 'Count'], ...(res.data.map((element) => { 
        return ([element.label, element.count]);
      }))])
    }).catch((err) => {
      console.log(err);
    })
  }, [])

  useEffect(() => {
    ApiGet(`noc/get_noc_count_by_month_year?year=${year.value}`)
    .then((res) => {
      setcountByMonth([['Month', 'Count'], ...(res.data.map((element) => { 
        return ([element.month.substring(0,3), element.count]);
      }))])
    }).catch((err) => {
      console.log(err);
    })
    
  }, [year])
  
  useEffect(() => {
    ApiGet('noc/get_recent_noc')
    .then((res) => {
      setrecentNoc(res.data.map((element) => {
        const {customer, location, partyName, ...remains} = element
        const res = {
          'Customer Name' : customer?.accountName ? customer.accountName : '',
          'Party Name' : partyName ? partyName : '',
          'location' : location ? location : '',
        }
        return res;
      }))
    }).catch((err) => {
      console.log(err);
    })
  }, [])
  
  useEffect(() => {
    if(taskList && taskList.data) {
      settaskData(taskList.data.map((element) => {
        const {projectName, status, subject, ...remains} = element
        const res = {
          'Subject' : subject ? subject : '',
          'Project Name' : projectName ? projectName : '',
          'Status' : status ? status : '',
        }
        return res;
      }))
    }
  }, [taskList])
  
  
  
  return (
    <div className='m-0 p-0'>
      <div className='row'>
      
        <div className='col-6 mb-4'>
          {countStatus && countStatus?.length > 0 && <ViewPieDesk 
            data={countStatus}
            title = 'NOC Status Summary'
          />}
        </div>
        <div className='col-6 mb-4'>
          {countByMonth && countByMonth?.length > 0 && 
            <ViewBarDesk 
              data={countByMonth}
              title='NOC Summary'
              range='Year'
              rangeOptions={yearOption}
              initialRangeVal={year}
              RangeValMethod={setyear}
            />
          }
          
        </div>
      
        <div className='col-6 mb-4'>
          {recentNoc && recentNoc.length  > 0 && <ViewTableDesk 
            data={recentNoc}
            title = "Recent NOC" 
            link = '/noc'
          /> }
        </div>
        <div className='col-6'>
        {taskData && taskData.length > 0 && <ViewTableDesk 
            data={taskData}
            title = 'Recent Task'  
            link = '/task'
          />}
        </div>
      </div>
    </div>
  )
}

export default LiasoningDashboard