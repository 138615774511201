import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ViewDeskCard = (props) => {
  const {content, count, color, bgColor, fontsz, icon} = props.data;
  
  return (
    <>
      <div
        style={{ backgroundColor: `${bgColor ?? '#FFFFFF'}` }}
        className="card-div me-2 mb-4"
      >
        <div
          style={icon ? {
            flex: 0.2,
            justifyContent: 'right',
            display: 'flex',
            marginTop: '10%',
            color: `${color ?? '#919191'}`,
          }: {
            display: 'none'
          }}>
          {icon && <FontAwesomeIcon className='fa-2x' icon={icon} />}
        </div>

        <div className='content' style={{ flex: `${icon ? '0.8' : '1'}` }}>
          <span 
            style={{
                fontSize: '1.5rem', 
                color: `${color ?? '#919191'}`,
              }}
            >
            {content}
          </span>
          <span 
            style={{
              fontSize: `${fontsz ?? '1.7rem'}`, 
              color: `${color ?? '#000000'}`,
              fontWeight: 'bold',
            }}>
            {count}
          </span>
        </div>
      </div>
    </>
  )
}

export default ViewDeskCard