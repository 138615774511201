import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_CONTRACTOR, ADD_CONTRACTOR_ERROR, ADD_CONTRACTOR_LOADING, DELETE_CONTRACTOR, DELETE_CONTRACTOR_ERROR, DELETE_CONTRACTOR_LOADING, EDIT_CONTRACTOR, EDIT_CONTRACTOR_ERROR, EDIT_CONTRACTOR_LOADING, GET_ALL_CONTRACTOR, GET_ALL_CONTRACTOR_ERROR, GET_ALL_CONTRACTOR_LOADING, GET_CONTRACTOR_BY_ID, GET_CONTRACTOR_BY_ID_ERROR, GET_CONTRACTOR_BY_ID_LOADING, IS_LOADING } from "../type"

export const addContractorAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_CONTRACTOR_LOADING,
            payload: true
        })
        await ApiPost('contractor/add_contractor', body)
            .then((res) => {
                dispatch({
                    type: ADD_CONTRACTOR,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: ADD_CONTRACTOR_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_CONTRACTOR_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_CONTRACTOR_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAllContractorAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_CONTRACTOR_LOADING,
            payload: true
        })
        await ApiGet('contractor/get_all_contractors')
            .then((res) => {
                dispatch({
                    type: GET_ALL_CONTRACTOR,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_CONTRACTOR_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_CONTRACTOR_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_CONTRACTOR_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getContractorByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_CONTRACTOR_BY_ID_LOADING,
            payload: true
        })
        await ApiGet(`contractor/get_contractor_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_CONTRACTOR_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_CONTRACTOR_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_CONTRACTOR_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_CONTRACTOR_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const editContractorAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_CONTRACTOR_LOADING,
            payload: true
        })
        await ApiPut(`contractor/edit_contractor`,body)
            .then((res) => {
                dispatch({
                    type: EDIT_CONTRACTOR,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: EDIT_CONTRACTOR_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_CONTRACTOR_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_CONTRACTOR_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const deleteContractorAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_CONTRACTOR_LOADING,
            payload: true
        })
        await ApiDelete(`contractor/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_CONTRACTOR,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: DELETE_CONTRACTOR_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_CONTRACTOR_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_CONTRACTOR_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}