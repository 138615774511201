import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthStorage from "../../helper/AuthStorage";
import { isLogin } from "../../redux/actions/loginAction";

const Wrong = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = () => {
    dispatch(isLogin(false))
      AuthStorage.deauthenticateUser()
    navigate("/");
  };

  return (
    <>
      <div className="wront-error">
        <img src="./assets/image/wrong.png" alt="wrong" width={"40%"} />
        <h1>Something went wrong</h1>
        <p>Please Log-in again </p>
        <button className="adduser-btn" onClick={() => login()}>
          Log-In
        </button>
      </div>
    </>
  );
};

export default Wrong;
