import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { dateFormate } from '../../helper/utils'
import { getBillEntryByIdAction } from '../../redux/actions/addBillAction'
import { getAllCustomerAction } from '../../redux/actions/customerAction'

const ViewBill = ({ show, onHide, viewBillId }) => {

    const dispatch = useDispatch()

    const [bill, setBill] = useState({
        projectId: '',
        projectType: '',
        date: '',
        totalMenPower: '',
        workDetails: '',
        nextDayPlaning: '',
        remark: '',
    })

    const billEntryData = useSelector((state) => state.get_Bill_Entry_By_Id.getBillEntryById)
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer.data)

    useEffect(() => {
        dispatch(getAllCustomerAction())
    }, [])

    useEffect(() => {
        if (viewBillId) {
            dispatch(getBillEntryByIdAction(viewBillId))
        }
    }, [viewBillId])

    useEffect(() => {
        if (billEntryData && billEntryData.data && customerData && viewBillId) {
            setBill({
                billType: billEntryData.data.billType,
                date: new Date(billEntryData.data.date),
                invoiceNo: billEntryData.data.invoiceNo,
                amount: billEntryData.data.amount,
                paymentType: billEntryData.data.paymentType,
                customer: selectValue(billEntryData.data.customer, "customer"),
            })
        }
    }, [billEntryData])

    const selectValue = (value, name) => {
        if (name === "customer") {
            return customerData.find((ele) => ele._id === value)?.accountName
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Bill</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Bill Type</td>
                                    <td>{bill.billType}</td>
                                </tr>
                                <tr>
                                    <td>Invoice No</td>
                                    <td>{bill?.invoiceNo}</td>

                                </tr>
                                <tr>
                                <td>Payment Type</td>
                                    <td>{bill.paymentType}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Date</td>
                                    <td>{dateFormate(bill.date, 'Date')}</td>
                                </tr>
                                <tr>
                                    <td>Customer</td>
                                    <td>{bill.customer}</td>
                                </tr>
                                <tr>
                                    <td>Amount</td>
                                    <td>{bill.amount}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewBill