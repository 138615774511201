import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import removeImg from '../../assets/image/delete-red.png'
import AddBank from '../../components/modals/AddBank'
import { deleteBankAction, getAllBankAction } from '../../redux/actions/bankAction';
import { DELETE_BANK } from '../../redux/type';
import STORAGEKEY from '../../config/APP/app.config';
import AuthStorage from '../../helper/AuthStorage';
import { UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import editIcon from '../../assets/image/edit.svg';

const Bank = () => {
    const gridRef = useRef();
    const dispatch = useDispatch()
    const userRole = useContext(RoleContext);
    const [bank, setBank] = useState([]);
    const [perPage, setPerPage] = useState("10");
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [bankId, setBankId] = useState('')
    const [bankTog, setBankTog] = useState(false)
    const toastSuccess = () => toast.success('Bank delete successfully');

    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

    const bankData = useSelector((state) => state.get_All_Bank.getAllBank)
    const deleteBankData = useSelector((state) => state.delete_Bank.deleteBank)

    const onFirstDataRendered = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    useEffect(() => {
        dispatch(getAllBankAction())
    }, [])

    useEffect(() => {
        if (bankData) {
            setBank(bankData.data)
        }
    }, [bankData])

    const columnDefs = [
        { field: 'bankName', },
        { field: 'accountNumber' },
        { field: 'ifscCode' },
        {
            field: "createdBy",
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",

            cellRendererFramework: (params) => <div>
                {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <><img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title='Edit' />
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title='Delete' /></>}
            </div>
        }
    ]

    const onEdit = (id) => {
        setBankTog(true)
        setBankId(id)
    }

    const onDelete = (id) => {
        dispatch(deleteBankAction(id))
        setReject(false)
    }

    useEffect(() => {
        if (deleteBankData && deleteBankData.data) {
            if (deleteBankData.data.message === "Bank deleted successfully.") {
                dispatch({
                    type: DELETE_BANK,
                    payload: []
                })
                dispatch(getAllBankAction())
                toastSuccess(true)
            }
        }
    }, [deleteBankData])


    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1
        };
    }, []);

    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById('page-size').value;
        setPerPage(value)
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onGridSizeChanged = () => {
        sizeToFit()
    }

    const sizeToFit = useCallback(() => {
        if (gridRef?.current !== undefined) {
            gridRef?.current?.api?.sizeColumnsToFit();
        }
    }, []);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>Bank</h2>
                    </div>
                    <button className='adduser-btn ms-3' onClick={() => setBankTog(true)}>Add Bank</button>
                </div>
                {bank &&
                    <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={bank}
                            columnDefs={columnDefs}
                            pagination={true}
                            defaultColDef={defaultColDef}
                            paginationPageSize={perPage}
                            style={{ width: "100%" }}
                            onFirstDataRendered={onFirstDataRendered}
                            onGridSizeChanged={onGridSizeChanged}
                        />
                        <div className='set-pagination-dropdaun'>
                            <p>Show</p>

                            <select onChange={onPageSizeChanged} id="page-size" className='mx-3 pagination-select'>
                                <option value="10" selected={true}>
                                    10
                                </option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>

                            <p>Entries</p>
                        </div>
                    </div>
                }
            </div>
            {bankTog && <AddBank show={bankTog} onHide={() => { setBankTog(false); setBankId('') }} bankId={bankId} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}

export default Bank