import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_PROJECT, ADD_PROJECT_ERROR, ADD_PROJECT_LOADING, DELETE_PROJECT, DELETE_PROJECT_ERROR, DELETE_PROJECT_LOADING, EDIT_PROJECT, EDIT_PROJECT_ERROR, EDIT_PROJECT_LOADING, GET_ALL_PROJECT, GET_ALL_PROJECT_ERROR, GET_ALL_PROJECT_LOADING, GET_PROJECT_BY_CUSTOMER, GET_PROJECT_BY_CUSTOMER_ERROR, GET_PROJECT_BY_CUSTOMER_LOADING, GET_PROJECT_BY_ID, GET_PROJECT_BY_ID_ERROR, GET_PROJECT_BY_ID_LOADING, IS_LOADING } from "../type"
import { getAllEnquiryAction } from "./enquiryAction"

export const addProjectAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_PROJECT_LOADING,
            payload: true
        })

        await ApiPost(`project/add_project`, body)
            .then((res) => {
                dispatch({
                    type: ADD_PROJECT,
                    payload: res
                })
                dispatch(getAllEnquiryAction())
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}
let Type = ''
export const getAllProjectAction = (type, role = '') => async (dispatch) => {
    Type = type
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_PROJECT_LOADING,
            payload: true
        })

        await ApiGet(`project/get_all_projects?type=${type}&role=${role}`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_PROJECT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getProjectByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PROJECT_BY_ID_LOADING,
            payload: true
        })


        await ApiGet(`project/get_project_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_PROJECT_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_PROJECT_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PROJECT_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PROJECT_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteProjectAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_PROJECT_LOADING,
            payload: true
        })

        await ApiDelete(`project/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_PROJECT,
                    payload: res
                })
                // let body = '';
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editProjectAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_PROJECT_LOADING,
            payload: true
        })

        await ApiPut(`project/edit_project`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_PROJECT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getProjectByCustomerAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PROJECT_BY_CUSTOMER_LOADING,
            payload: true
        })

        await ApiGet(`project/get_project_by_customer/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_PROJECT_BY_CUSTOMER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_PROJECT_BY_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PROJECT_BY_CUSTOMER_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PROJECT_BY_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}