import { GET_ACCOUNT, GET_ACCOUNT_ERROR, GET_ACCOUNT_LOADING, GET_ALL_ACCOUNT_COUNT, GET_ALL_ACCOUNT_COUNT_ERROR, GET_ALL_ACCOUNT_COUNT_LOADING, GET_ALL_BILL_ENTRY_BY_CUSTOMER, GET_ALL_BILL_ENTRY_BY_CUSTOMER_ERROR, GET_ALL_BILL_ENTRY_BY_CUSTOMER_LOADING, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_ERROR, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING } from "../type"

const getAccountData = {
    loading:false,
    getAccount : [],
    error:'',
};

const getAllAccountCountData = {
    loading:false,
    getAllAccountCount : [],
    error:'',
};

const getAllBillEntryByCustomerData = {
    loading:false,
    getAllBillEntryByCustomer : [],
    error:'',
};

const AllPendingBillByCoustmerData = {
    loading:false,
    getAllPendingBill : [],
    error:'',

};

export const getAccountReducer = (state = getAccountData, action) => {
    switch (action.type) {
        case GET_ACCOUNT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ACCOUNT:
            return {
                ...state,
                getAccount: action.payload,
            };
        case GET_ACCOUNT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllAccountCountReducer = (state = getAllAccountCountData, action) => {
    switch (action.type) {
        case GET_ALL_ACCOUNT_COUNT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_ACCOUNT_COUNT:
            return {
                ...state,
                getAllAccountCount: action.payload,
            };
        case GET_ALL_ACCOUNT_COUNT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}


export const getAllPendingBillByCoustmerReducer = (state = AllPendingBillByCoustmerData , action) => {
    switch (action.type) {
        case GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER:
            return {
                ...state,
                getAllPendingBill: action.payload,
            };
        case GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllBillByCustomerReducer = (state = getAllBillEntryByCustomerData, action) => {
    switch (action.type) {
        case GET_ALL_BILL_ENTRY_BY_CUSTOMER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_BILL_ENTRY_BY_CUSTOMER:
            return {
                ...state,
                getAllBillEntryByCustomer: action.payload,
            };
        case GET_ALL_BILL_ENTRY_BY_CUSTOMER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}