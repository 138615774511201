import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react'
import debounce from "lodash/debounce";
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AgGridReact } from 'ag-grid-react';
import { DELETE_PROJECT_MATERIAL, EDIT_PROJECT_MATERIAL } from '../../redux/type';
import { Paginationlimit, Unit, UserRoles } from '../../helper/Constant';
import editIcon from '../../assets/image/edit.svg';
import removeImg from '../../assets/image/delete-red.png'
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import usePaginationHooks from '../../Hooks/paginationHooks';
import ReactDatePicker from 'react-datepicker';
// import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { getProjectByIdAction } from '../../redux/actions/projectAction';
import { getAllContractorAction } from '../../redux/actions/contractorAction';
import moment from 'moment';
import { deleteProjectMaterialAction, editProjectMaterialAction } from '../../redux/actions/projectMaterialAction';
import img from '../../assets/image/select.png';

const ProjectMaterialHistory = ({ show, onHide, productId, delQty }) => {

    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(location.search)
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({ search: "" });
    const { getProjectHistorybyId } = usePaginationHooks()
    const [contractorOption, setContractorOption] = useState([])
    const projectId = queryParams.get("projectId")
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit)
    const [formErrors, setformErrors] = useState({})
    const getAllContractor = useSelector((state) => state.get_All_Contractor.getAllContractor)
    const userData = useSelector((state) => state.get_Project_By_Id.getProjectById)
    const deleteProjectMaterial = useSelector((state) => state.delete_Project_material.deleteProjectMaterial)
    const editProjectMaterial = useSelector((state) => state.edit_Project_material.editProjectMaterial)
    const [product, setProduct] = useState({
        _id: '',
        productCode: '',
        productId: '',
        productName: '',
        used: '',
        date: '',
        contractor: ''
    })
    const [rotateArrow1, setrotateArrow1] = useState(false);

    const columnDefs = [
        { field: 'productName', flex: 1, },
        { field: 'productCode', maxWidth: 150, },
        { field: 'contractorName', headerName: 'Contractor', maxWidth: 150, },
        { field: 'used', maxWidth: 80, },
        { field: 'status', maxWidth: 120, },
        { field: 'date', maxWidth: 150, },
        { field: 'unit', maxWidth: 100, },
        {
            headerName: 'Action',
            field: "_id",
            maxWidth: 150,
            sortable: false,
            cellRendererFramework: (params) =>
                <div>
                    {params?.data?.status === "UnBilled" ? <img src={editIcon} onClick={() => { onEdit(params.data) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='Edit' alt='' /> : <img src={editIcon} style={{ cursor: "not-allowed", height: "20px", opacity: 0.5 }} className='ms-3' alt='' />}
                    {params?.data?.status === "UnBilled" ? <img src={removeImg} onClick={() => { onDelete(params.data._id) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' /> : <img src={removeImg} style={{ cursor: "not-allowed", height: "20px", opacity: 0.5 }} className='ms-3' alt='' />}
                </div>
        }
    ];

    const onDelete = (id) => {
        dispatch(deleteProjectMaterialAction(id))
    }

    useEffect(() => {
        if (deleteProjectMaterial && deleteProjectMaterial.data && deleteProjectMaterial.data.status === 200) {
            toast.success(deleteProjectMaterial.data.message)
            dispatch({
                type: DELETE_PROJECT_MATERIAL,
                payload: []
            });
        }
    }, [deleteProjectMaterial])

    useEffect(() => {

        if (editProjectMaterial && editProjectMaterial.data?.status === 200) {
            toast.success(editProjectMaterial.data.message);
            dispatch({
                type: EDIT_PROJECT_MATERIAL,
                payload: []
            });
            setProduct({
                productName: '',
                productCode: '',
                delQuantity: '',
                productId: '',
                used: '',
                date: '',
                contractor: ''
            });
        }
        if (editProjectMaterial && editProjectMaterial.status === 500) {
            // toast.error(editProjectMaterial.message);
            toast.error('ef');
            dispatch({
                type: EDIT_PROJECT_MATERIAL,
                payload: []
            });
        }
    }, [editProjectMaterial])

    const onEdit = (data) => {

        if (data.used) {
            formErrors.used = ""
        }
        if (data.date) {
            formErrors.date = ""
        }
        if (data.contractor) {
            formErrors.contractor = ""
        }

        setProduct({
            productCode: data?.productCode ? data?.productCode : '',
            productId: data?.productId ? data?.productId : '',
            productName: data?.productName ? data?.productName : '',
            used: data?.used ? data?.used : '',
            date: data?.date ? data?.date : '',
            contractor: data?.contractor ? data?.contractor : '',
            _id: data?._id ? data?._id : ''
        })

    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1
        };
    }, []);

    useEffect(() => {
        dispatch(getProjectByIdAction(projectId))
        dispatch(getAllContractorAction())
    }, [projectId])

    useEffect(() => {
        if (userData && userData.data && userData.data.contractor.length && getAllContractor.data) {
            const temp = getAllContractor.data?.filter((ele) => {
                if (userData.data.contractor.includes(ele._id)) {
                    return ele
                }
            })
            const temp2 = temp.length ? temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.name,
                }
            }) : []
            setContractorOption(temp2)
        }
    }, [getAllContractor, userData])

    const validatation = () => {
        let flag = false;
        let formError = {};
        // if (!product.productName) {
        //     formError = { ...formError, productName: "Required" }
        //     flag = true
        // }
        if (!product.used) {
            formError = { ...formError, used: "Required" }
            flag = true
        }
        if (!product.date) {
            formError = { ...formError, date: "Required" }
            flag = true
        }
        if (!product.contractor) {
            formError = { ...formError, contractor: "Required" }
            flag = true
        }
        setformErrors(formError)
        return flag
    }

    const onSubmit = () => {
        if (validatation()) {
            return
        }
        const body = {
            productId: productId,
            projectId: projectId,
            id: product?._id ? product._id : '',
            usedQty: product?.used ? +product.used : '',
            usedDate: product?.date,
            contractor: product?.contractor ? product.contractor : '',
            totalDeliveredProductQuantity: delQty ? delQty : 0
        }
        dispatch(editProjectMaterialAction(body))
    }

    const onClear = () => {
        setProduct({
            productCode: '',
            productId: '',
            productName: '',
            used: '',
            date: '',
            contractor: ''
        });
        setformErrors({});
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (gridApi || editProjectMaterial.data?.status === 200 || deleteProjectMaterial.data?.status === 200) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getProjectHistorybyId(search, projectId, productId, page, limit, sorted_column, sorted_order)
                        .then(res => {
                            params.successCallback(res.data.map((ele) => {
                                return {
                                    // productCode: ele?.productCode ? ele?.productCode : '',
                                    productName: ele?.productId?.productName ? ele.productId.productName : '',
                                    productCode: ele?.productId?.productCode ? ele.productId.productCode : '',
                                    productId: ele?.productId ? ele.productId : '',
                                    unit: ele?.productId?.unitOfMeasurement ? ele?.productId.unitOfMeasurement : '',
                                    used: ele?.usedQty ? ele.usedQty : 0,
                                    status: ele?.isGenerateLaborBill === true ? 'Billed' : "UnBilled",
                                    date: ele?.usedDate ? moment(ele.usedDate).format("YYYY-MM-DD") : '',
                                    contractor: ele?.contractor?._id ? ele.contractor._id : '',
                                    contractorName: ele?.contractor?.name ? ele.contractor.name : '',
                                    _id: ele?._id ? ele._id : '',
                                }
                            }), res?.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi?.showNoRowsOverlay();
                            } else {
                                gridApi?.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

    }, [gridApi, limit, editProjectMaterial, deleteProjectMaterial, filter.search]);

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch("");
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            // size="xl"
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <div className='d-flex final-material-modal-title'>
                    <Modal.Title>{"Project Material History"}</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-end border-bottom pb-3'>
                    <div>
                        <input
                            type="text"
                            className="form-control search-button"
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value.trimStart())}
                            autoComplete="off"
                        />
                    </div>
                    <button className='adduser-btn mt-2 mt-sm-0 ms-3' onClick={() => clearFilter()}>Clear Filter</button>
                </div>
                <div className='final-material-modal'>
                    <Row className='mb-4'>
                        <Col xxl={2} lg={4} sm={6}>
                            <div className="mt-3 small-input">
                                <div className="users-form-info ms-0 ms-xxl-3 w-100">
                                    <div className="multi-select">
                                        <label>Product Name</label>
                                        <input
                                            placeholder="Enter Product"
                                            type="text"
                                            className="form-control"
                                            disabled
                                            value={product.productName}
                                        />
                                    </div>
                                    {formErrors?.productName && <label style={{ color: "red" }}>{formErrors.productName}</label>}
                                </div>
                            </div>
                        </Col>
                        <Col xxl={2} lg={4} sm={6}>
                            <div className="mt-3 small-input">
                                <div className="users-form-info ms-0 ms-xxl-3 w-100">
                                    <div className="multi-select">
                                        <label>Used Qty</label>
                                        <input
                                            placeholder="Enter Used Qty"
                                            type="text"
                                            className="form-control"
                                            value={product.used}
                                            onChange={(e) => {
                                                let numberRex = /^(?:\d*\.\d{0,2}|\d+)$/;
                                                if (!e.target.value ||
                                                    e.target.value === "" ||
                                                    numberRex.test(e.target.value)) {
                                                    setProduct({ ...product, used: e.target.value })
                                                }
                                                if (e.target.value) {
                                                    setformErrors({ ...formErrors, used: '' })
                                                }
                                            }}
                                        />
                                    </div>
                                    {formErrors?.used && <label style={{ color: "red" }}>{formErrors.used}</label>}
                                </div>
                            </div>
                        </Col>
                        <Col xxl={2} lg={4} sm={6}>
                            <div className="mt-3 small-input">
                                <div className="users-form-info ms-0 ms-xxl-3 w-100">
                                    <div className="users-label enquery-datepicker">
                                        <label>Date</label>
                                        <ReactDatePicker
                                            id="EnquiryDatePicker"
                                            dateFormat="yyyy-MM-dd"
                                            selected={product.date ? new Date(product.date) : null}
                                            placeholderText="Select date"
                                            onChange={(e) => {
                                                setProduct({ ...product, date: moment(e).format('YYYY-MM-DD') })
                                                if (e) {
                                                    setformErrors({ ...formErrors, date: '' })
                                                }
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>}
                                </div>
                            </div>
                        </Col>
                        <Col xxl={2} lg={4} sm={6}>
                            <div className="mt-3 small-input">
                                <div className="users-form-info ms-0 ms-xxl-3 w-100">
                                    <div className="users-label">
                                        <label>Contractor</label>
                                        <div className="position-relative header-select w-100" style={{ width: "max-content", backgroundColor: "#fff" }}>
                                            <img src={img} className={`${rotateArrow1 && "rotateArrow"}`} width="18px" alt="" />
                                            <select
                                                className="form-control w-100"
                                                value={product.contractor}
                                                onBlur={() => { setrotateArrow1(false) }}
                                                onClick={() => { setrotateArrow1((pre) => !pre) }}
                                                onChange={(e) => {
                                                    setProduct({ ...product, contractor: e.target.value })
                                                    if (e.target.value) {
                                                        setformErrors({ ...formErrors, contractor: '' })
                                                    }
                                                }}
                                            >
                                                <option value="" style={{ display: "none" }}>Select</option>
                                                {contractorOption?.length && contractorOption?.map((ele) => (<option value={ele.value}>{ele.label}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    {formErrors?.contractor && <label style={{ color: "red" }}>{formErrors.contractor}</label>}
                                </div>
                            </div>
                        </Col>
                        <Col xxl={3} lg={4} sm={6}>
                            <div className="mt-3 small-input">
                                <div className="users-form-info ms-2 w-100">
                                    <div className="users-label">
                                        <label></label>
                                        <div className="form-control border-0">
                                            <Button className='adduser-btn me-3' disabled={!product.productId} onClick={() => { onSubmit() }} >{'Save'}</Button>
                                            <button className='cancel-button' onClick={() => { onClear() }} >{'Clear'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="ag-theme-alpine final-material-table" style={{ height: 600 }}>
                        <AgGridReact
                            pagination={true}
                            columnDefs={columnDefs}
                            rowModelType={"infinite"}
                            paginationPageSize={limit}
                            cacheBlockSize={limit}
                            onGridReady={onGridReady}
                            rowHeight={45}
                            defaultColDef={defaultColDef}
                            overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                        />
                        <div className='set-pagination-dropdaun'>
                            <p>Show</p>
                            <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                                <option value="50">
                                    50
                                </option>
                                <option value="100" selected={true}>100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                            <p>Entries</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ProjectMaterialHistory