import { ApiGet, ApiPost } from "../../helper/API/ApiData"
import { GET_ACCOUNT, GET_ACCOUNT_ERROR, GET_ACCOUNT_LOADING, GET_ALL_ACCOUNT_COUNT, GET_ALL_ACCOUNT_COUNT_ERROR, GET_ALL_ACCOUNT_COUNT_LOADING, GET_ALL_BILL_ENTRY_BY_CUSTOMER, GET_ALL_BILL_ENTRY_BY_CUSTOMER_ERROR, GET_ALL_BILL_ENTRY_BY_CUSTOMER_LOADING, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_ERROR, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING, IS_LOADING } from "../type"

export const getAccountAction = (startDate, endDate, type, body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ACCOUNT_LOADING,
            payload: true
        })
        await ApiPost(`account/get_all_account?startDate=${startDate}&endDate=${endDate}&type=${type}`, body)
            .then((res) => {
                dispatch({
                    type: GET_ACCOUNT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ACCOUNT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ACCOUNT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ACCOUNT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAllAccountCountAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_ACCOUNT_COUNT_LOADING,
            payload: true
        })
        await ApiGet('account/get_all_account_count')
            .then((res) => {
                dispatch({
                    type: GET_ALL_ACCOUNT_COUNT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_ACCOUNT_COUNT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_ACCOUNT_COUNT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_ACCOUNT_COUNT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}

export const getAllBillEntryByCustomerAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_BILL_ENTRY_BY_CUSTOMER_LOADING,
            payload: true
        })
        await ApiGet(`bill_entry/get_all_bill_entry_by_customer?cId=${id}`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_BILL_ENTRY_BY_CUSTOMER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_BILL_ENTRY_BY_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_BILL_ENTRY_BY_CUSTOMER_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_BILL_ENTRY_BY_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}


export const getAllPendingBillByCoustmerAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING,
            payload: true
        })
        await ApiGet(`bill_entry/get_all_bill_entry_by_customer?cId=${body.cId}&type=${body.type}&pay=${body.pay}`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })
    }
}