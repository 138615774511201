import { ADD_CUSTOMER, ADD_CUSTOMER_ERROR, ADD_CUSTOMER_LOADING, DELETE_CUSTOMER, DELETE_CUSTOMER_ERROR, DELETE_CUSTOMER_LOADING, EDIT_CUSTOMER, EDIT_CUSTOMER_ERROR, EDIT_CUSTOMER_LOADING, GET_ALL_CUSTOMER, GET_ALL_CUSTOMER_ERROR, GET_ALL_CUSTOMER_LOADING, GET_CUSTOMER_BY_ID, GET_CUSTOMER_BY_ID_ERROR, GET_CUSTOMER_BY_ID_LOADING, GET_CUSTOMER_DROPDOWN, GET_CUSTOMER_DROPDOWN_ERROR, GET_CUSTOMER_DROPDOWN_LOADING, GET_SUPPLIER_DROPDOWN, GET_SUPPLIER_DROPDOWN_ERROR, GET_SUPPLIER_DROPDOWN_LOADING } from "../type"

const addCustomerData = {
    loading:false,
    addCustomer : [],
    error:'',
};

const getAllCustomerData = {
    loading:false,
    getAllCustomer : [],
    error:'',
};

const getCustomerByIdData = {
    loading:false,
    getCustomerById:[],
    error:'',
}

const editCustomerData = {
    loading:false,
    editCustomer:[],
    error:'',
}

const deleteCustomerData = {
    loading:false,
    deleteCustomer:[],
    error:'',
}

const getCustomerDropDownData = {
    loading:false,
    getCustomerDropDown:[],
    error:'',
}

const getSupplierDropDownData = {
    loading:false,
    getSupplierDropDown:[],
    error:'',
}

export const addCustomerReducer = (state = addCustomerData, action) => {
    switch (action.type) {
        case ADD_CUSTOMER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_CUSTOMER:
            return {
                ...state,
                addCustomer: action.payload,
            };
        case ADD_CUSTOMER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllCustomerReducer = (state = getAllCustomerData, action) => {
    switch (action.type) {
        case GET_ALL_CUSTOMER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_CUSTOMER:
            return {
                ...state,
                getAllCustomer: action.payload,
            };
        case GET_ALL_CUSTOMER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getCustomerByIdReducer = (state = getCustomerByIdData, action) => {
    switch (action.type) {
        case GET_CUSTOMER_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_CUSTOMER_BY_ID:
            return {
                ...state,
                getCustomerById: action.payload,
            };
        case GET_CUSTOMER_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editCustomerReducer = (state = editCustomerData, action) => {
    switch (action.type) {
        case EDIT_CUSTOMER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_CUSTOMER:
            return {
                ...state,
                editCustomer: action.payload,
            };
        case EDIT_CUSTOMER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteCustomerReducer = (state = deleteCustomerData, action) => {
    switch (action.type) {
        case DELETE_CUSTOMER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_CUSTOMER:
            return {
                ...state,
                deleteCustomer: action.payload,
            };
        case DELETE_CUSTOMER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getCustomerDropDownReducer = (state = getCustomerDropDownData, action) => {
    switch (action.type) {
        case GET_CUSTOMER_DROPDOWN_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_CUSTOMER_DROPDOWN:
            return {
                ...state,
                getCustomerDropDown: action.payload,
            };
        case GET_CUSTOMER_DROPDOWN_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getSupplierDropDownReducer = (state = getSupplierDropDownData, action) => {
    switch (action.type) {
        case GET_SUPPLIER_DROPDOWN_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_SUPPLIER_DROPDOWN:
            return {
                ...state,
                getSupplierDropDown: action.payload,
            };
        case GET_SUPPLIER_DROPDOWN_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}