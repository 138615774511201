import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from "xlsx";
import { getAllProductAction, importFileAction } from '../../redux/actions/productAction';
import { Unit } from '../../helper/Constant';
import { toast } from 'react-toastify';
import sampleFile from '../../assets/sample-file/product.xlsx';
import { IMPORT_FILE } from '../../redux/type';
import FileSaver from 'file-saver';

const ImportFile = ({ show, onHide }) => {

    const unitData = [Unit.KG, Unit.LTR, Unit.NOS, Unit.MTR, Unit.FEET]
    const dispatch = useDispatch()
    const [fileData, setFileData] = useState([]);
    const [errorTog, setErrorTog] = useState(false);
    const [error, setError] = useState('');
    const [productCodeErr, setProductCodeErr] = useState(false);
    const toastSuccess = () => toast.success('File Imported successfully');
    const importFileData = useSelector((state) => state.import_File.importFile)

    useEffect(() => {
        if (importFileData && importFileData.message === "File save successfully.") {
            if (importFileData.data.length) {
                fileReturnData(importFileData.data)
            } else {
                onHide()
                dispatch(getAllProductAction())
            }
            toastSuccess(true)
            dispatch({
                type: IMPORT_FILE,
                payload: []
            })
        }
    }, [importFileData])

    const fileReturnData = (data) => {
        if (data.length) {
            setError('')
            setFileData(data)
            setProductCodeErr(true)
        } else {
            onHide()
            dispatch(getAllProductAction())
        }
    }

    const fileOnChnage = (e) => {
        if (e.target.files[0].name.split(".").lastIndexOf('xlsx') === 1) {
            setError('')
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsBinaryString(file)
            reader.onload = (event) => {
                const binaryData = event.target.result;
                const workBook = XLSX.read(binaryData, { type: "binary" });
                workBook.SheetNames.forEach(sheet => {
                    const data = XLSX.utils.sheet_to_csv(workBook.Sheets[sheet]);
                    setFileData(convertToJson(data))
                });
            };
        } else {
            setError('Please select valid file')
            e.target.value = '';
        }
    }

    const convertToJson = (csv) => {
        var lines = csv.split("\n");
        var result = [];
        var headers = lines[0].split(",");
        headers = ['productName', 'productCode', 'hsnsacCode', 'retailSalePrice', 'buyingPrice', 'gst', 'unitOfMeasurement', 'openingStock']

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }
        return result;
    }

    const exportToCSV = () => {
        const ws = XLSX.utils.json_to_sheet(fileData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(data, 'product' + '.xlsx');
    }

    const importClick = () => {
        if (fileData.length > 0) {
            if (!errorTog) {
                dispatch(importFileAction(fileData))
            }
        } else {
            // toastSelectFile(true)
            setError('Please first select file')
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            // size="xl"
            dialogClassName="modal-70w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Import File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column'>
                    <label>Download Sample File : <a href={sampleFile} target='_blank' download="product.xlsx">Product.xlsx</a></label>
                    <input
                        type='file'
                        placeholder='select File'
                        onChange={(e) => fileOnChnage(e)}
                        accept="*"
                        className='mt-2'
                    />
                </div>
                {fileData.length > 0 &&
                    <Table striped bordered hover className='mt-5'>
                        <thead style={{ borderBottom: '2px solid #000' }}>
                            <th>ProductCode</th>
                            <th>ProductName</th>
                            <th>HAN/SAC Code</th>
                            <th>Retail Sale Price</th>
                            <th>Buying Price</th>
                            <th>GST</th>
                            <th>Unit of Measurement</th>
                            <th>Opening Stock</th>
                        </thead>
                        <tbody>
                            {fileData.map((item, index) => {
                                return (<tr key={index}>
                                    <td>{item.productCode}</td>
                                    <td>{item.productName}</td>
                                    <td>{item.hsnsacCode}</td>
                                    <td>{item.retailSalePrice}</td>
                                    <td>{item.buyingPrice}</td>
                                    <td>{item.gst}</td>
                                    {unitData.includes(item.unitOfMeasurement) ? <td>{item.unitOfMeasurement}</td> : <><td style={{ color: "red" }}>{item.unitOfMeasurement}</td>{!errorTog && <td>{setErrorTog(true)}</td>}</>}
                                    <td>{item.openingStock}</td>
                                </tr>)

                            })}
                        </tbody>
                    </Table>}
            </Modal.Body>
            {error !== '' && <label style={{ color: 'red' }}>{error}</label>}
            {errorTog && <div style={{ color: "red" }}><label>Unit of Measurement Should be one of these : </label><label>KG, LTR, NOS, MTR, FEET</label></div>}
            {productCodeErr && <div style={{ color: "red" }}>Product Code Repeated so Change first product code</div>}
            {productCodeErr && <div style={{ cursor: "pointer" }} onClick={() => exportToCSV()}>File Download</div>}
            <Modal.Footer>
                {/* <Button className='adduser-btn' onClick={() => importClick()} disabled={fileData.length ? false : !errorTog ? false : true}>Import</Button> */}
                <Button className='adduser-btn' onClick={() => importClick()} disabled={fileData.length ? !errorTog && !productCodeErr ? false : true : true}>Import</Button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>

        </Modal >
    )
}

export default ImportFile