import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_PURCHASE, ADD_PURCHASE_ERROR, ADD_PURCHASE_LOADING, DELETE_PURCHASE, DELETE_PURCHASE_ERROR, DELETE_PURCHASE_LOADING, EDIT_PURCHASE, EDIT_PURCHASE_ERROR, EDIT_PURCHASE_LOADING, GET_ALL_PURCHASE, GET_ALL_PURCHASE_ERROR, GET_ALL_PURCHASE_LOADING, GET_PURCHASE_BY_ID, GET_PURCHASE_BY_ID_ERROR, GET_PURCHASE_BY_ID_LOADING, IS_LOADING } from "../type"

export const addPurchaseAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_PURCHASE_LOADING,
            payload: true
        })

        await ApiPost(`purchase/add_purchase`, body)
            .then((res) => {
                dispatch({
                    type: ADD_PURCHASE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_PURCHASE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_PURCHASE_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_PURCHASE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getPurchaseByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PURCHASE_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`purchase/get_purchase_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_PURCHASE_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_PURCHASE_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PURCHASE_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PURCHASE_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllPurchaseAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_PURCHASE_LOADING,
            payload: true
        })

        await ApiGet(`purchase/get_all_purchase`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_PURCHASE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_PURCHASE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_PURCHASE_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_PURCHASE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editPurchaseAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_PURCHASE_LOADING,
            payload: true
        })

        await ApiPut(`purchase/edit_purchase`,body)
            .then((res) => {
                dispatch({
                    type: EDIT_PURCHASE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_PURCHASE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_PURCHASE_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_PURCHASE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deletePurchaseAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_PURCHASE_LOADING,
            payload: true
        })

        await ApiDelete(`purchase/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_PURCHASE,
                    payload: res
                })
                dispatch(getAllPurchaseAction())
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_PURCHASE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_PURCHASE_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_PURCHASE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}