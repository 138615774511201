import { FILE_REMOVE, FILE_REMOVE_ERROR, FILE_REMOVE_LOADING, FILE_UPLOAD, FILE_UPLOAD_ERROR, FILE_UPLOAD_LOADING } from "../type";


const fileUploadData = {
    loading:false,
    fileUpload:[],
    error:'',
}

const fileRemoveData = {
    loading:false,
    fileRemove:[],
    error:'',
}

export const fileUploadReducer = (state = fileUploadData, action) => {
    switch (action.type) {
        case FILE_UPLOAD_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case FILE_UPLOAD:
            return {
                ...state,
                fileUpload: action.payload,
            };
        case FILE_UPLOAD_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const fileRemoveReducer = (state = fileRemoveData, action) => {
    switch (action.type) {
        case FILE_REMOVE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case FILE_REMOVE:
            return {
                ...state,
                fileRemove: action.payload,
            };
        case FILE_REMOVE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
