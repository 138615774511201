import moment from "moment"
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_SALE, ADD_SALE_ERROR, ADD_SALE_LOADING, DELETE_SALE, DELETE_SALE_ERROR, DELETE_SALE_LOADING, EDIT_SALE, EDIT_SALE_ERROR, EDIT_SALE_LOADING, GET_ALL_SALE, GET_ALL_SALE_ERROR, GET_ALL_SALE_LOADING, GET_SALE_BY_ID, GET_SALE_BY_ID_ERROR, GET_SALE_BY_ID_LOADING, IS_LOADING } from "../type"

export const addSaleAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_SALE_LOADING,
            payload: true
        })

        await ApiPost(`sell/add_sell`, body)
            .then((res) => {
                dispatch({
                    type: ADD_SALE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_SALE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_SALE_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_SALE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getSaleByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_SALE_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`sell/get_sell_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_SALE_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_SALE_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_SALE_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_SALE_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllSaleAction = (page, limit, filter) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_SALE_LOADING,
            payload: true
        })

        await ApiGet(`sell/get_all_sell/?page=${page}&limit=${limit}&from=${filter.From ? moment(filter.From).format('YYYY-MM-DD') : ''}&to=${filter.To ? moment(filter.To).format('YYYY-MM-DD') : ''}&search=${filter.Search ? filter.Search : ''}&searchType=${filter.SearchType ? filter.SearchType : ''}`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_SALE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_SALE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_SALE_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_SALE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editSaleAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_SALE_LOADING,
            payload: true
        })

        await ApiPut(`sell/edit_sell`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_SALE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_SALE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_SALE_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_SALE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteSaleAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_SALE_LOADING,
            payload: true
        })

        await ApiDelete(`sell/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_SALE,
                    payload: res
                })
                dispatch(getAllSaleAction())
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: DELETE_SALE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_SALE_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_SALE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}