import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ViewTableDesk from '../../../components/dashboard/ViewTableDesk'
import ViewPieDesk from '../../../components/dashboard/ViewPieDesk'
import ViewBarDesk from '../../../components/dashboard/ViewBarDesk'
import { useDispatch, useSelector } from 'react-redux'
import { ApiGet } from '../../../helper/API/ApiData'
import { UserRoles } from '../../../helper/Constant'
import { getAllProjectAction } from '../../../redux/actions/projectAction'
import { RoleContext } from '../../../helper/RoleProvider'

const MaintenanceDashboard = () => {
    const [task, setTask] = useState([])
    const dispatch = useDispatch();
    const userRole = useContext(RoleContext);

    let startYear = 2021
    let currentYear = new Date().getFullYear()
    const yearOption = [...Array(currentYear - startYear + 1).keys()].map((e) => {
      return { label: (e + startYear).toString(), value: (e + startYear).toString() };
    })
    
    const [project, setProject] = useState([])
    const [year, setYear] = useState({ label: currentYear.toString(), value: currentYear.toString() })
    const [projectByMonthYearWise, setProjectByMonthYearWise] = useState([])
    const [projectCountByStatus, setProjectCountByStatus] = useState([])

    const projectData = useSelector((state) => state.get_All_Project.getAllProject.data)

    useEffect(() => {
        let body = '';
        let role = '';
        if(userRole === UserRoles.Maintance){
          role = 'Maintenance'
        }
        dispatch(getAllProjectAction(body, role))
    }, [])

     useEffect(() => {
        
        ApiGet(`task/get_task_dashboard`)
        .then((res) => {
            setTask(res.data.map((ele) => {
                return {
                    'Project Name': ele.projectName,
                    'Subject': ele.subject,
                    'Status': ele.status
                }
            }))
        }).catch((error) => {
            console.log(error);
        })
        
        

        ApiGet(`project/get_project_count_by_status?projectType=Maintenance`)
        .then((res) => {
            let tempProjectCount = [['Label', 'Count']];
            res.data.map((ele) => {
                tempProjectCount.push([ele.label, ele.count])
            })
            setProjectCountByStatus(tempProjectCount)
        }).catch((err) => {
            console.log(err);
        })

    }, [])

    useEffect(() => {
        ApiGet('project/get_project_dashboard?projectType=Maintenance')
        .then((res) => {
            if(projectData) {
                setProject(res.data.map((ele) => {
                    return {
                        'ProjectName': ele.partyName,
                        'ProjectManager': ele.projectManager.name
                    }
                }))
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [projectData])

    useEffect(() => {
        ApiGet(`project/get_project_count_by_month_year_wise?year=${year.value}&projectType=Maintenance`)
          .then((res) => {
            let tempProject = [['Month', 'Count']];
            res.data.map((ele) => {
              tempProject.push([ele.month.substring(0, 3), ele.count])
            })
            setProjectByMonthYearWise(tempProject)
          }).catch((err) => {
            console.log(err);
          })
      }, [year])
        
    return (
        <div className="p-0 m-0">
            <Row>
                <Col lg={6} md={6} className="mt-3">
                    <Col lg={12}>
                        {projectCountByStatus.length > 0 && <ViewPieDesk data={projectCountByStatus} title="Project  Status Summary" />}
                    </Col>
                </Col>

                <Col lg={6} md={6} className='mt-3'>
                    {/* <Col lg={12}> */}
                        {projectByMonthYearWise.length > 0 && <ViewBarDesk
                        data={projectByMonthYearWise}
                        title="Project Summary"
                        range='Year'
                        rangeOptions={yearOption}
                        initialRangeVal={year}
                        RangeValMethod={setYear}
                        />}
                    {/* </Col> */}
                </Col>

                <Col lg={6} md={6}>
                    <Col lg={12} className="mt-3">
                        {task.length > 0 && <ViewTableDesk data={task} title={"Recent Task"} link="/task"/>}
                    </Col>
                </Col>

                <Col lg={6} md={6}>
                    <Col lg={12} className="mt-3">
                        {project.length > 0 && <ViewTableDesk data={project} title={"Recent Project"} link="/project"/>}
                    </Col>
                </Col>
            </Row>
        </div>
    )
}

export default MaintenanceDashboard
