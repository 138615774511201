import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import removeImg from '../../assets/image/delete-red.png'
import AddTask from '../../components/modals/AddTask';
import { deleteTaskAction, editTaskForKanbanAction, getAllTaskAction } from '../../redux/actions/taskAction';
import { EDIT_TASK_FOR_KANBAN, GET_COMMENTS_BY_TASK_ID, GET_TASK_BY_ID } from '../../redux/type';
import Avatar from 'react-avatar';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import { Paginationlimit, Status, UserRoles } from '../../helper/Constant';
import { Col } from 'react-bootstrap';
import Select from 'react-select';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import { getAllUserAction } from '../../redux/actions/userAction';
import { RoleContext } from '../../helper/RoleProvider';
import editIcon from '../../assets/image/edit.svg';
import ViewTask from '../../components/view-modals/ViewTask';
import { dateFormate } from '../../helper/utils';
import Board from "react-trello";
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Task = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    const gridRef = useRef();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin]
    const queryParams = new URLSearchParams(location.search)
    const type = queryParams.get("type")
    const [task, setTask] = useState([]);
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [taskTog, setTaskTog] = useState(false);
    const [taskId, setTaskId] = useState('')
    const [kanBagTog, setKanBagTog] = useState(false)
    const [boardData, setBoardData] = useState()
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({
        aasignTo: [],
        createdBy: [],
        status: '',
        project: '',
        types: { label: "All", value: "" },
        search: ""
    })
    const [taskData, setTaskData] = useState([])
    const { getAllTask } = usePaginationHooks()
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit);
    const [projectOption, setProjectOption] = useState([])
    const [userOption, setUserOption] = useState([])
    const [createdByOption, setCreatedByOption] = useState([])
    const [view, setView] = useState(false)
    const [isFilter, setisFilter] = useState(false)
    const [viewId, setViewId] = useState('')
    const [columnDefs, setColumnDefs] = useState([]);
    const toastSuccess = () => toast.success('Task delete successfully');
    const toastUpdate = () => toast.success('Task status update successfully');

    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

    const kanbantaskData = useSelector((state) => state.get_All_Task.getAllTask)
    const editTask = useSelector((state) => state.edit_Task.editTask)
    const addTask = useSelector((state) => state.add_Task.addTask)
    const deleteTask = useSelector((state) => state.delete_Task)
    const updateKanbanData = useSelector((state) => state.edit_Task_For_Kanban.editTaskForKanban)
    const projectData = useSelector((state) => state.get_All_Project.getAllProject.data)
    const getAlluserData = useSelector((state) => state.get_All_User.getAllUser)

    const statusOption = [
        { label: 'To Do', value: Status.ToDo },
        { label: 'In Progress', value: Status.InProgress },
        { label: 'Review', value: Status.Review },
        { label: 'Hold', value: Status.Hold },
        { label: 'Done', value: Status.Done },
        { label: 'Approved', value: Status.Approved },
    ]

    // useEffect(() => {
    //     onFirstDataRendered()
    // }, [width,height]);

    useEffect(() => {
        if (type) {
            if (type !== "kanban" && type !== "list") {
                setTaskId(type)
                setTaskTog(true)
            }
        }
    }, [type])

    useEffect(() => {
        if (type === 'kanban') {
            let taskBody = {
                status: "",
                project: "",
                aasignTo: [],
                createdBy: [],
                type: ""
            }
            dispatch(getAllTaskAction(taskBody))
        }

    }, [type])

    useEffect(() => {
        let body = '';
        dispatch(getAllProjectAction(body))
        dispatch(getAllUserAction())

    }, [])

    useEffect(() => {
        if (projectData) {
            if (userRole === UserRoles.Project) {
                let option = [];
                option.push({ label: "Others", value: "Others" })
                let temp = projectData.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
                temp.filter((ele) => ele.projectType !== 'Maintenance').map((item) => option.push({ value: item._id, label: item.partyName }))
                setProjectOption(option)
            } else if (userRole === UserRoles.Maintance) {
                let option = [];
                option.push({ label: "Others", value: "Others" })
                let temp = projectData.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
                temp.filter((ele) => ele.projectType === 'Maintenance').map((item) => option.push({ value: item._id, label: item.partyName }))
                setProjectOption(option)
            } else {
                let option = [];
                option.push({ label: "Others", value: "Others" })
                let temp = projectData.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
                temp.map((ele) => option.push({ value: ele._id, label: ele.partyName }))
                setProjectOption(option)
            }
        }
    }, [projectData])

    useEffect(() => {
        if (getAlluserData && getAlluserData.data) {
            let tempAssign = [
                { label: '@Me', value: userData.id },
                { label: 'Unassigned', value: '' },
            ]
            let tempCreated = [
                { label: '@Me', value: userData.id },
            ]
            getAlluserData.data.map((item) => {
                if (item._id !== userData.id) {
                    tempAssign.push({
                        label: item.name,
                        value: item._id
                    })
                }
            })
            getAlluserData.data.map((item) => {
                if (item._id !== userData.id) {
                    tempCreated.push({
                        label: item.name,
                        value: item._id
                    })
                }
            })
            setUserOption(tempAssign)
            setCreatedByOption(tempCreated)
        }
    }, [getAlluserData])

    useEffect(() => {
        return (() => {
            setUserOption([])
            setCreatedByOption([])
        })
    }, [])

    const datav = (elem) => {
        return (
            <>
                <div className='date-kanban-task'>
                    {`${elem.startDate ? dateFormate(elem.startDate, 'Date') : ''} - ${elem.startDate ? dateFormate(elem.dueDate, 'Date') : ''}`}
                </div>
                <div>
                    {elem.assignToData?.map((item) => <Avatar name={item.name} round={true} size="30px" className='avtar-kanban-task' />)}
                </div>
            </>
        )
    }

    useEffect(() => {
        if (kanbantaskData && kanbantaskData.data) {
            const uniqueStatus = ['To Do', 'In Progress', 'Review', 'Hold', 'Done', 'Approved']
            let temp = [];
            temp.push({
                lanes: uniqueStatus?.map((ele, i) => {
                    return {
                        id: ele,
                        title: ele,
                        style: {
                            width: 300
                        },
                        cards: kanbantaskData.data.filter((data) => data.status === ele)?.map((elem) => {
                            return {
                                id: elem._id,
                                title: elem.subject,
                                description: datav(elem)
                            }
                        })
                    }
                })
            })
            setBoardData(temp[0])
        }
    }, [kanbantaskData])

    useEffect(() => {
        if (boardData && boardData.lanes.length > 0) {
            if (type) {
                if (type === "kanban") {
                    setKanBagTog(true)
                } else {
                    setKanBagTog(false)
                }
            }
            else {
                setKanBagTog(false)
            }
            // }
        }
    }, [boardData])

    const onView = (id) => {
        setView(true)
        setViewId(id)
    }

    const columnDefsNews = [
        { field: 'subject', flex:1, },
        { field: 'projectName', flex:1, },
        {
            field: "startDate",
            maxWidth: 120,
            cellRendererFramework: (params) =>
                <div>
                    <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
                </div>
        },
        {
            field: "dueDate",
            maxWidth: 120,
            cellRendererFramework: (params) =>
                <div>
                    <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
                </div>
        },
        { field: 'priority', maxWidth: 100, },
        { field: 'status', maxWidth: 110, },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 150,
            cellRendererFramework: (params) => <div>
                {params.value?.name}
            </div>
        },
        {
            field: "createdAt",
            minWidth: 60,
            maxWidth:180,
            cellRendererFramework: (params) =>
                <div>
                    <span>{params.value ? dateFormate(params.value, 'FullDateTime') : ''}</span>
                </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth:140,
            maxWidth:180,
            sortable: false,
            cellRendererFramework: (params) => {
                return (
                    <div>
                        {/* {<img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" />} */}
                        {userRole === UserRoles.Admin && <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Delete" />}
                        <img src={viewImg} onClick={() => { onView(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
                    </div>
                )
            }
        }
    ]

    const NoRolecolumnDefs = [
        { field: 'subject', flex:1, },
        { field: 'projectName', flex:1, },
        {
            field: "startDate",
            maxWidth: 120,
            cellRendererFramework: (params) =>
                <div>
                    <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
                </div>
        },
        {
            field: "dueDate",
            maxWidth: 120,
            cellRendererFramework: (params) =>
                <div>
                    <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
                </div>
        },
        { field: 'priority', maxWidth: 80, },
        { field: 'status', maxWidth: 120, },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 240,
            cellRendererFramework: (params) => <div>
                {params.value?.name}
            </div>
        },
        {
            field: "createdAt",
            minWidth: 60,
            maxWidth:180,
            cellRendererFramework: (params) =>
                <div>
                    <span>{params.value ? dateFormate(params.value, 'FullDateTime') : ''}</span>
                </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth:140,
            maxWidth:180,
            sortable: false,
            cellRendererFramework: (params) => {
                return (
                    <div>
                        <img src={viewImg} onClick={() => { onView(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt="" />
                    </div>
                )
            }
        }
    ]

    const onEdit = (id) => {
        setTaskTog(true)
        setTaskId(id)
    }

    const onDelete = (id) => {
        dispatch(deleteTaskAction(id))
        setReject(false)
        toastSuccess(true)
    }

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            flex: 1,
            sortable: true,
            width: 'auto',
            filter: false,
        };
    }, []);

    const hide = () => {
        setTaskTog(false);
        setTaskId('');
        navigate('/task')
        dispatch({
            type: GET_COMMENTS_BY_TASK_ID,
            payload: []
        })
        dispatch({
            type: GET_TASK_BY_ID,
            payload: []
        })
    }

    const DragnDrop = (id, from, to, lane, data) => {
        const body = {
            id: id,
            status: to
        }
        dispatch(editTaskForKanbanAction(body))
    }

    useEffect(() => {
        if (updateKanbanData.status === 200) {
            toastUpdate(true)
            dispatch({
                type: EDIT_TASK_FOR_KANBAN,
                payload: []
            })
        }
    }, [updateKanbanData])

    const Switch = () => {
        setKanBagTog(!kanBagTog);
        if (kanBagTog) {
            navigate(`?type=list`)
        } else {
            navigate(`?type=kanban`)
        }
    }

    const handleChangeFilter = (e, name) => {
        if (name === 'status') {
            setFilter({ ...filter, [name]: e.value })
        } else if (name === 'project') {
            setFilter({ ...filter, [name]: e.value })
        } else if (name === 'types') {
            setFilter({ ...filter, [name]: e.value })
        } else if (name === 'aasignTo') {
            setFilter({ ...filter, [name]: e })
        } else if (name === 'createdBy') {
            setFilter({ ...filter, [name]: e })
        } else if (name === '') {
            setFilter({ ...filter, [name]: e.target.value })
        }
    }

    const applyFilter = () => {
        setisFilter(true)
        if (type === "kanban") {
            let body = {
                aasignTo: filter.aasignTo.map((item) => item.value),
                createdBy: filter.createdBy.map((ele) => ele.value),
                status: filter.status,
                project: filter.project,
                type: filter.types
            }
            dispatch(getAllTaskAction(body))
        }
    }

    const clearFilter = () => {
        setFilter({
            aasignTo: [],
            createdBy: [],
            status: '',
            project: '',
            types: '',
            search: '',
        })
        setSearch("");
        setisFilter(true)
        if (type === "kanban") {
            dispatch(getAllTaskAction({ status: "", project: "", aasignTo: [], createdBy: [], type: "", }))
        }
    }

    const onHide = () => {
        setView(false)
        dispatch({
            type: GET_TASK_BY_ID,
            payload: []
        })
    }

    const typesOption = [
        { label: "All", value: "" },
        { label: "Assign To Me", value: "assignToMe" },
        { label: "Created By Me", value: "createdByMe" },
    ]

    const selectValue = (value, name) => {
        if (name === "status") {
            return value ? statusOption.find((ele) => ele.value === value) : ''
        } else if (name === "project") {
            return value ? projectOption.find((ele) => ele.value === value) : ''
        } else if (name === "types") {
            return value ? typesOption.find((ele) => ele.value === value) : ''
        }
    }

    useEffect(() => {
        if (taskData) {
            if (UserRoles.Viewer === userRole) {
                setColumnDefs(NoRolecolumnDefs)
            } else {
                setColumnDefs(columnDefsNews)
            }
        }
    }, [taskData])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || deleteTask?.status === 200 || editTask.data?.status === 200 || addTask.data?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    let body = {
                        aasignTo: filter.aasignTo ? filter.aasignTo.map((item) => item.value) : [],
                        createdBy: filter.createdBy ? filter.createdBy.map((ele) => ele.value) : [],
                        status: filter.status ? filter.status : '',
                        project: filter.project ? filter.project : '',
                        type: filter.types ? filter.types.value : '',
                        search: filter.search ? filter.search : '',
                        sorted_column: sorted_column,
                        sorted_order: sorted_order
                    }
                    getAllTask(body, page, limit)
                        .then(res => {
                            setTaskData(res)
                            params.successCallback(res.data.map((ele) => {
                                return {
                                    subject: ele.subject ? ele.subject : '',
                                    projectName: ele.projectName ? ele.projectName : '',
                                    startDate: ele.startDate ? ele.startDate : '',
                                    dueDate: ele.dueDate ? ele.dueDate : '',
                                    priority: ele.priority ? ele.priority : '',
                                    status: ele.status ? ele.status : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    createdAt: ele.createdAt ? ele.createdAt : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            setisFilter(false)
            gridApi?.setDatasource(dataSource);
        }
        // setisFilter(false)
    }, [gridApi, limit, isFilter, deleteTask, editTask, addTask, filter.search]);

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>Task</h2>
                    </div>
                    <div className='d-flex'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn' onClick={() => Switch()}> {kanBagTog ? "Switch to List" : "Switch to Kanban"}</button>
                        {userRole !== UserRoles.Viewer && <button className='adduser-btn ms-3' onClick={() => setTaskTog(true)}>Add Task</button>}
                    </div>
                </div>
                <div className='mb-4 set-page-heade align-items-end'>
                    {RolesForAdd.includes(userRole) &&
                        <>
                            <Col lg={2}>
                                <div className="users-form-info add-remark-modal mx-0">
                                    <div className="multi-select">
                                        <label>Assign To</label>
                                        <Select
                                            value={filter.aasignTo}
                                            onChange={(e) => handleChangeFilter(e, 'aasignTo')}
                                            options={userOption}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div className="users-form-info add-remark-modal mx-0">
                                    <div className="multi-select">
                                        <label>Created By</label>
                                        <Select
                                            value={filter.createdBy}
                                            onChange={(e) => handleChangeFilter(e, 'createdBy')}
                                            options={createdByOption}
                                            isMulti={true}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </>
                    }
                    <Col lg={2}>
                        <div className="users-form-info add-remark-modal mx-0">
                            <div className="multi-select">
                                <label>Status</label>
                                <Select
                                    value={selectValue(filter.status, "status")}
                                    onChange={(e) => handleChangeFilter(e, 'status')}
                                    options={statusOption}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="users-form-info add-remark-modal mx-0">
                            <div className="multi-select">
                                <label>Project</label>
                                <Select
                                    value={selectValue(filter.project, "project")}
                                    onChange={(e) => handleChangeFilter(e, 'project')}
                                    options={projectOption}
                                />
                            </div>
                        </div>
                    </Col>
                    {!RolesForAdd.includes(userRole) && <Col lg={2}>
                        <div className="users-form-info add-remark-modal mx-0">
                            <div className="multi-select">
                                <label>Types</label>
                                <Select
                                    value={selectValue(filter.types, "types")}
                                    onChange={(e) => handleChangeFilter(e, 'types')}
                                    options={typesOption}
                                />
                            </div>
                        </div>
                    </Col>}
                    <button className='adduser-btn' onClick={() => applyFilter()}>Apply Filter</button>
                    <button className='adduser-btn' onClick={() => clearFilter()}>Clear Filter</button>
                </div>
                {!kanBagTog &&
                    <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                        {/* <AgGridReact
                            ref={gridRef}
                            rowData={task}
                            columnDefs={columnDefs}
                            pagination={true}
                            defaultColDef={defaultColDef}
                            paginationPageSize={perPage}
                            style={{ width: "100%" }}
                            onFirstDataRendered={onFirstDataRendered}
                            onGridSizeChanged={onGridSizeChanged}
                        /> */}
                        <AgGridReact
                            ref={gridRef}
                            pagination={true}
                            columnDefs={columnDefs}
                            rowModelType={"infinite"}
                            paginationPageSize={limit}
                            cacheBlockSize={limit}
                            onGridReady={onGridReady}
                            rowHeight={45}
                            defaultColDef={defaultColDef}
                            rowSelection={'single'}
                            onCellClicked={handleRowClick}
                            overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                        />

                        {/* <Pagination setlimit={setlimit} setPage={setPage} page={page} limit={limit} data={saleData} totalCount={totalCount} /> */}
                        <div className='set-pagination-dropdaun'>
                            <p>Show</p>
                            <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                                <option value="50">
                                    50
                                </option>
                                <option value="100" selected={true}>100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                            <p>Entries</p>
                        </div>
                    </div>
                }
                {boardData && kanBagTog &&
                    <Board
                        data={boardData}
                        draggable
                        onCardClick={(e) => {
                            setTaskId(e)
                            setTaskTog(true)
                        }}
                        handleDragEnd={(id, from, to, lane, data) => {
                            DragnDrop(id, from, to, lane, data)
                        }}
                        style={{
                            height: '75vh',
                            overflowY: 'auto',
                            backgroundColor: '#ffffff'
                        }}
                    />
                }
            </div>
            {taskTog && <AddTask show={taskTog} onHide={() => hide()} taskId={taskId} />}
            {view && <ViewTask show={view} onHide={() => onHide()} taskId={viewId} />}
            {
                reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}
export default Task   