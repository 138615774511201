import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { dateFormate } from '../../helper/utils'
import { getAllCustomerAction, getCustomerByIdAction } from '../../redux/actions/customerAction'

const ViewCustomer = ({ show, onHide, viewId }) => {

    const dispatch = useDispatch()

    const [customer, setCustomer] = useState({
        gstin: '',
        accountName: '',
        address: '',
        shippingAddress: '',
        contactPersonName: '',
        contactPersonNumber: '',
        userType: '',
    })

    const getCustomerByIdData = useSelector((state) => state.get_Customer_By_Id.getCustomerById)

    useEffect(() => {
        if (viewId) {
            dispatch(getCustomerByIdAction(viewId))
        }
    }, [viewId])

    useEffect(() => {
        if (getCustomerByIdData && getCustomerByIdData.data && viewId) {
            setCustomer({
                gstin: getCustomerByIdData.data.gstin,
                accountName: getCustomerByIdData.data.accountName,
                address: getCustomerByIdData.data.address,
                shippingAddress: getCustomerByIdData.data.shippingAddress,
                contactPersonName: getCustomerByIdData.data.contactPersonName,
                contactPersonNumber: getCustomerByIdData.data.contactPersonNumber,
                userType: getCustomerByIdData.data.userType,
            })
        }
    }, [getCustomerByIdData])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>GST In</td>
                                    <td>{customer.gstin}</td>
                                </tr>
                                <tr>
                                    <td>Account Name</td>
                                    <td>{customer?.accountName}</td>

                                </tr>
                                <tr>
                                    <td>User Type</td>
                                    <td>{customer.userType}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Contact Person Name</td>
                                    <td>{customer.contactPersonNumber}</td>
                                </tr>
                                <tr>
                                    <td>Contact Person Number</td>
                                    <td>{customer?.contactPersonNumber}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-customer-address-modal'>
                                <tr>
                                    <td className='vertical-top' >Address</td>
                                    <td className='vertical-top' >{customer?.address}</td>

                                </tr>
                                <tr>
                                    <td className='vertical-top' >Shipping Address</td>
                                    <td className='vertical-top' >{customer.shippingAddress}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewCustomer