import { ADD_SALE, ADD_SALE_ERROR, ADD_SALE_LOADING, DELETE_SALE, DELETE_SALE_ERROR, DELETE_SALE_LOADING, EDIT_SALE, EDIT_SALE_ERROR, EDIT_SALE_LOADING, GET_ALL_SALE, GET_ALL_SALE_ERROR, GET_ALL_SALE_LOADING, GET_SALE_BY_ID, GET_SALE_BY_ID_ERROR, GET_SALE_BY_ID_LOADING } from "../type";

const addSaleData = {
    loading: false,
    addSale: [],
    error: '',
}

const getSaleByIdData = {
    loading: false,
    getSaleById: [],
    error: '',
}

const getAllSaleData = {
    loading: false,
    getAllSale: [],
    error: '',
}

const editSaleData = {
    loading: false,
    editSale: [],
    error: '',
}

const deleteSaleData = {
    loading: false,
    deleteSale: [],
    error: '',
}

export const addSaleReducer = (state = addSaleData, action) => {
    switch (action.type) {
        case ADD_SALE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_SALE:
            return {
                ...state,
                addSale: action.payload,
            };
        case ADD_SALE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getSaleByIdReducer = (state = getSaleByIdData, action) => {
    switch (action.type) {
        case GET_SALE_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_SALE_BY_ID:
            return {
                ...state,
                getSaleById: action.payload,
            };
        case GET_SALE_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllSaleReducer = (state = getAllSaleData, action) => {
    switch (action.type) {
        case GET_ALL_SALE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_SALE:
            return {
                ...state,
                getAllSale: action.payload,
            };
        case GET_ALL_SALE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editSaleReducer = (state = editSaleData, action) => {
    switch (action.type) {
        case EDIT_SALE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_SALE:
            return {
                ...state,
                editSale: action.payload,
            };
        case EDIT_SALE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteSaleReducer = (state = deleteSaleData, action) => {
    switch (action.type) {
        case DELETE_SALE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_SALE:
            return {
                ...state,
                deleteSale: action.payload,
            };
        case DELETE_SALE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}