import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import removeImg from '../../assets/image/delete-red.png'
import downloadImg from '../../assets/image/download.png'
import { useNavigate } from 'react-router-dom';
import { deletePurchaseAction, getAllPurchaseAction } from '../../redux/actions/purchaseAction';
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import editIcon from '../../assets/image/edit.svg';
import ViewPurchase from '../../components/view-modals/ViewPurchase';
import { dateFormate, downloadFile } from '../../helper/utils';
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';
import PaymentAmmountConfirmation from '../../components/view-modals/PaymentAmmountConfirmation';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Purchase = () => {
    const navigate = useNavigate()
    const gridRef = useRef();
    const dispatch = useDispatch()

    const { getAllpurchase } = usePaginationHooks()
    const [limit, setlimit] = useState(Paginationlimit);
    const [gridApi, setGridApi] = useState(null);
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Store]
    const [purchase, setPurchase] = useState([]);
    const [perPage, setPerPage] = useState("10");
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [columnDefs, setColumnDefs] = useState([]);
    const [viewTog, setViewTog] = useState(false);
    const [viewId, setViewId] = useState('');
    const [paymentAmmountConfirmations, setPaymentAmmountConfirmations] = useState(false);
    const [paramvalues, setparamvalues] = useState();
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ column_name: '', column_order: '' });
    const toastSuccess = () => toast.success('Product delete successfully');

    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)
    // const purchaseData = useSelector((state) => state.get_All_Purchase.getAllPurchase)
    const deletePurchase = useSelector((state) => state.delete_Purchase.deletePurchase)
    const editPurchase = useSelector((state) => state.edit_Purchase.editPurchase)

    // useEffect(() => {
    //     dispatch(getAllPurchaseAction())
    // }, [])

    // useEffect(() => {
    //     if (purchaseData && purchaseData.data) {
    //         setPurchase(purchaseData.data.map((ele) => {
    //             return {
    //                 invoiceDate: ele.invoiceDate ? ele.invoiceDate : '',
    //                 customerName: ele.customerName ? ele.customerName : '',
    //                 invoiceNo: ele.invoiceNo ? ele.invoiceNo : '',
    //                 productAmount: ele.productAmount ? ele.productAmount : '',
    //                 discountAmount: ele.discountAmount ? ele.discountAmount : 0,
    //                 totalAmount: ele.totalAmount ? ele.totalAmount : '',
    //                 createdBy: ele.createdBy ? ele.createdBy : '',
    //                 _id: ele._id ? ele._id : '',
    //             }
    //         }))
    //     }
    // }, [purchaseData])

    const columnDefsNews = [
        {
            field: 'invoiceDate',
            maxWidth: 110,
            cellRendererFramework: (params) => <div>
                <span>{params?.value ? dateFormate(params?.value, 'Date') : ''}</span>
            </div>
        },
        {
            headerName: 'Supplier Name',
            flex: 1,
            field: 'customerName'
        },
        { field: 'invoiceNo', minWidth: 60, maxWidth: 180, },
        { field: 'productAmount', maxWidth: 120,
            cellRendererFramework: (params) => <div>
                {params?.value?.toFixed(2)}
            </div>
        },
        { field: 'discountAmount', maxWidth: 90, },
        { field: 'totalAmount', maxWidth: 120,
            cellRendererFramework: (params) => <div>
                {params?.value?.toFixed(2)}
            </div>
        },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 240,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 140,
            maxWidth: 180,
            sortable: false,
            cellRendererFramework: (params) => <div>
                {(params.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) &&
                    <>
                        {/* <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} title="Edit" alt='' /> */}
                        <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' />
                    </>
                }
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
                <img src={downloadImg} onClick={() => { setPaymentAmmountConfirmations(true); setparamvalues(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Download" />
            </div>
        }
    ]

    const NoRolecolumnDefsNew = [
        {
            field: 'invoiceDate',
            maxWidth: 110,
            cellRendererFramework: (params) => <div>
                <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
            </div>
        },
        {
            headerName: 'Supplier Name',
            flex: 1,
            field: 'customerName'
        },
        { field: 'invoiceNo', minWidth: 60, maxWidth: 180, },
        { field: 'productAmount', maxWidth: 120,
            cellRendererFramework: (params) => <div>
                {params?.value?.toFixed(2)}
            </div>
        },
        { field: 'discountAmount', maxWidth: 90, },
        { field: 'totalAmount', maxWidth: 120,
            cellRendererFramework: (params) => <div>
                {params?.value?.toFixed(2)}
            </div>
        },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 240,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 140,
            maxWidth: 180,
            sortable: false,
            cellRendererFramework: (params) => <div>
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
            </div>
        }
    ]

    const onDownload = async (id, showAmmount) => {
        try {
            const data = purchase.data.find(ele => ele._id === id)
            downloadFile(data, 'Purchase Challan', showAmmount)
        } catch (err) {
            console.log(err)
        }
    }

    const onEdit = (id) => {
        navigate(`edit?purchaseId=${id}`)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    const onDelete = (id) => {
        dispatch(deletePurchaseAction(id))
        setReject(false)
        toastSuccess(true)
    }

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        if (purchase) {
            if (RolesForAdd.includes(userRole)) {
                setColumnDefs(columnDefsNews)
            } else {
                setColumnDefs(NoRolecolumnDefsNew)
            }
        }
    }, [purchase])

    const exportToCSV = async () => {
        getAllpurchase("", "", filter.search, sorting.column_name, sorting.column_order).then(res => {
            if (res && res.data) {
                const newData = res?.data?.map((item) => {
                    return {
                        ...item,
                        purchaseItems: JSON.stringify(item.purchaseItems)
                    }
                })
                const ws = XLSX.utils.json_to_sheet(newData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                FileSaver.saveAs(data, 'all_purchases' + '.xlsx');
            }
        })
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || editPurchase?.data?.status === 200 || deletePurchase?.data?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    setSorting({ column_name: sorted_column, column_order: sorted_order });

                    getAllpurchase(page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            setPurchase(res)
                            params.successCallback(res.data.map((ele) => {
                                return {
                                    invoiceDate: ele.invoiceDate ? ele.invoiceDate : '',
                                    customerName: ele.customerName ? ele.customerName : '',
                                    invoiceNo: ele.invoiceNo ? ele.invoiceNo : '',
                                    productAmount: ele.productAmount ? ele.productAmount : '',
                                    discountAmount: ele.discountAmount ? ele.discountAmount : 0,
                                    totalAmount: ele.totalAmount ? ele.totalAmount : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

    }, [gridApi, limit, editPurchase, deletePurchase, filter.search]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>Purchase</h2>
                    </div>
                    <div className='d-flex'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn' onClick={() => clearFilter()}>Clear Filter</button>
                        <button className='adduser-btn ms-3' onClick={exportToCSV}>Export All</button>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => { navigate('add') }}>Add Purchase</button>}
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        onCellClicked={(event) => {
                            const rowData = event.data;
                            if (rowData?.createdBy?._id === userData.id || userRole === UserRoles.Admin) {
                                handleRowClick(event)
                            }
                        }}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />

                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
            </div>
            {viewTog && <ViewPurchase show={viewTog} onHide={() => { setViewTog(false); setViewId('') }} viewId={viewId} />}
            {paymentAmmountConfirmations && <PaymentAmmountConfirmation show={paymentAmmountConfirmations} onHide={() => { setPaymentAmmountConfirmations(false); setPaymentAmmountConfirmations('') }} ondownloadprint={(e) => onDownload(paramvalues, e)} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}

export default Purchase   