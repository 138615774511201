import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import Datepickericon from '../../assets/image/date-picker-icon.png'

const AddRemarkModal = ({ show, onHide, addData, dataForEdit }) => {

    const [remark, setRemark] = useState({
        date: "",
        remark: ""
    })

    useEffect(() => {
        if (dataForEdit) {
            setRemark({
                date: dataForEdit.date,
                remark: dataForEdit.remark
            })
        }
    }, [dataForEdit])

    const openCalender = (id) => {
        document.getElementById(id).click()
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>test</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="users-form-info date-picker add-remark-modal mx-0 mb-3">
                    <div className="users-label">
                        <label>Date</label>
                        <ReactDatePicker id='Remarkdate' dateFormat="dd/MM/yyyy" selected={remark.date} placeholderText="Select remark date" onChange={(e) => setRemark({ ...remark, date: e })} autoComplete='off' />
                    </div>
                    <img src={Datepickericon} onClick={() => openCalender('Remarkdate')} alt="" className='datepickericon' />
                </div>
                <div className="users-form-info date-picker add-remark-modal mx-0">
                    <div className="users-label">
                        <label>Remark</label>
                        <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter remark"
                            name="remark"
                            value={remark.remark}
                            onChange={(e) => setRemark({ ...remark, remark: e.target.value })}
                            autoComplete="off"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { addData(remark) }}>{dataForEdit?.date || dataForEdit?.remark ? "Edit" : "Add"}</button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>

        </Modal>
    )
}

export default AddRemarkModal