import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addBankAction, editBankAction, getAllBankAction, getBankByIdAction } from '../../redux/actions/bankAction';
import { ADD_BANK, EDIT_BANK } from '../../redux/type';
import { toast } from "react-toastify";

const AddBill = ({ show, onHide, bankId }) => {

    const dispatch = useDispatch()
    const [bank, setBank] = useState({
        bankName: "",
        accountNumber: "",
        ifscCode: "",
    })
    const [formErrors, setFormErrors] = useState()
    const error = "Account number already exists."
    const toastAdd = () => toast.success("Bank added successfully");
    const toastEdit = () => toast.success("Bank update successfully");
    const toastError = () => toast.error(error);
    const bankData = useSelector((state) => state.get_Bank_By_Id.getBankById)
    const addBankData = useSelector((state) => state.add_Bank.addBank)
    const editBankData = useSelector((state) => state.edit_Bank.editBank)

    useEffect(() => {
        if (bankId !== "") {
            dispatch(getBankByIdAction(bankId))
        }
    }, [bankId])

    useEffect(() => {
        if (bankData && bankData.data && bankId) {
            setBank({
                bankName: bankData.data.bankName,
                accountNumber: bankData.data.accountNumber,
                ifscCode: bankData.data.ifscCode,
            })
        }
    }, [bankData])

    const handleChange = (e, name) => {
        let re = /^[0-9\b]+$/;
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "accountNumber") {
            if (e.target.value === '' || re.test(e.target.value)) {
                if (e.target.value.length <= 18) {
                    setBank({ ...bank, [name]: e.target.value })
                }
            }
        }
        else if (name === "ifscCode") {
            setBank({ ...bank, [name]: e.target.value.toUpperCase() })
        } else {
            setBank({ ...bank, [name]: e.target.value })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        if (!bank.bankName) {
            error.bankName = "Please enter bank name"
            flag = true
        }
        if (!bank.accountNumber) {
            error.accountNumber = "Please enter account number"
            flag = true
        }
        if (!bank.ifscCode) {
            error.ifscCode = "Please enter ifsc code"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const Save = () => {
        if (validation()) {
            return
        }
        dispatch(addBankAction(bank))
    }

    useEffect(() => {
        if (addBankData && addBankData.message === error) {
            dispatch({
                type: ADD_BANK,
                payload: []
            })
            toastError(true)
        }
        else if (addBankData && addBankData.data) {
            if (addBankData.message === "Bank created successfully.") {
                dispatch({
                    type: ADD_BANK,
                    payload: []
                })
                dispatch(getAllBankAction())
                toastAdd(true)
                onHide()
            }
        }
    }, [addBankData])


    const Edit = () => {
        if (validation()) {
            return
        }
        let data = {
            ...bank,
            id: bankId,
        }
        dispatch(editBankAction(data))
    }

    useEffect(() => {
        if (editBankData && editBankData.data && editBankData.data.message === error) {
            dispatch({
                type: EDIT_BANK,
                payload: []
            })
            toastError(true)
        }
        else if (editBankData && editBankData.data) {
            if (editBankData.data.message === "Bank updated successfully.") {
                dispatch({
                    type: EDIT_BANK,
                    payload: []
                })
                dispatch(getAllBankAction())
                toastEdit(true)
                onHide()
            }
        }
    }, [editBankData])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{bankId ? "Edit Bank" : "Add Bank"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Bank Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter name"
                                    value={bank.bankName}
                                    onChange={(e) => handleChange(e, 'bankName')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.bankName && <span style={{ color: "red" }}>{formErrors.bankName}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Account Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter account name"
                                    value={bank.accountNumber}
                                    onChange={(e) => handleChange(e, 'accountNumber')}
                                    autoComplete="off"
                                // maxLength={14}
                                />
                            </div>
                            {formErrors?.accountNumber && <span style={{ color: "red" }}>{formErrors.accountNumber}</span>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>IFSC Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter ifsc code"
                                    value={bank.ifscCode}
                                    onChange={(e) => handleChange(e, 'ifscCode')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.ifscCode && <span style={{ color: "red" }}>{formErrors.ifscCode}</span>}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { bankId ? Edit() : Save() }}>{bankId ? "Edit" : "Add"}</button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>

        </Modal>
    )
}

export default AddBill