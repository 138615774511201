import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Datepickericon from '../../assets/image/date-picker-icon.png'
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify'
import { ADD_EMPLOYEE_REPORT, EDIT_EMPLOYEE_REPORT } from '../../redux/type';
import ReactDatePicker from 'react-datepicker';
import { Col, Row } from 'react-bootstrap';
import { UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import { addEmployeeReport, editEmployeeReport, getEmployeeReportById } from '../../redux/actions/employeeReportAction';
import { getAllUserAction } from '../../redux/actions/userAction';
import Select from 'react-select';
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";

const StaffDailySiteReport = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const dailyreportId = queryParams.get("dailyreportId")
  const [formErrors, setFormErrors] = useState()
  const userRole = useContext(RoleContext);

  const addEmployeeReportData = useSelector((state) => state.add_Employee_Report.addEmployeeReport)
  const employeeReportByIdData = useSelector((state) => state.get_Employee_Report_By_Id.getEmployeeReportById)
  const editEmployeeReportData = useSelector((state) => state.edit_Employee_Report.editEmployeeReport)
  const allUserData = useSelector((state) => state.get_All_User.getAllUser)

  const [dailySiteReportData, setDailySiteReportData] = useState({
    employeeId: '',
    date: new Date(),
    km: '',
  })

  const [extraDetail, setExtraDetail] = useState([{
    startTime: '',
    endTime: '',
    description: ''
  }])

  const [empOption, setEmpOption] = useState([])

  useEffect(() => {
    dispatch(getAllUserAction())
  }, [])

  useEffect(() => {
    if (dailyreportId) {
      dispatch(getEmployeeReportById(dailyreportId))
    }
  }, [dailyreportId])

  useEffect(() => {
    if (allUserData && allUserData.data && allUserData.data.length > 0) {
      setEmpOption(allUserData.data.map((ele, i) => {
        return {
          value: ele._id,
          label: ele.name
        }
      }))
    }
  }, [allUserData])


  useEffect(() => {
    if (employeeReportByIdData && employeeReportByIdData.data && dailyreportId) {
      setDailySiteReportData({
        employeeId: employeeReportByIdData.data?.employeeId,
        date: employeeReportByIdData.data?.date && new Date(employeeReportByIdData.data.date),
        km: employeeReportByIdData.data?.km,
      })
      setExtraDetail(employeeReportByIdData.data.items.length > 0 ?
        employeeReportByIdData.data.items.map((e) => {
          return {
            startTime: e.startTime,
            endTime: e.endTime,
            description: e.description
          }
        })
        : [])
    }
  }, [employeeReportByIdData])

  const onSave = () => {
    if (validation()) {
      return
    }
    let body = {
      employeeId: dailySiteReportData.employeeId,
      date: dailySiteReportData.date,
      km: dailySiteReportData.km,
      items: [...extraDetail]
    }
    dispatch(addEmployeeReport(body))
  }

  useEffect(() => {
    if (addEmployeeReportData && addEmployeeReportData.status === 200) {
      dispatch({
        type: ADD_EMPLOYEE_REPORT,
        payload: []
      })
      navigate('/staff-daily-report')
      toast.success(addEmployeeReportData.message);
    }
  }, [addEmployeeReportData])

  const onEdit = () => {
    if (validation()) {
      return
    }
    let body = {
      ...dailySiteReportData,
      items: [...extraDetail],
      id: dailyreportId
    }
    dispatch(editEmployeeReport(body))
  }

  useEffect(() => {
    if (editEmployeeReportData && editEmployeeReportData.data && editEmployeeReportData.data.status === 200) {
      dispatch({
        type: EDIT_EMPLOYEE_REPORT,
        payload: []
      })
      toast.success(editEmployeeReportData.data.message);
      navigate('/staff-daily-report')
    }
  }, [editEmployeeReportData])

  const validation = () => {
    let flag = false
    const error = {};
    if (userRole === UserRoles.Admin && !dailySiteReportData.employeeId) {
      error.employeeId = "Please select employeeId"
      flag = true
    }
    if (!dailySiteReportData.km) {
      error.km = "Please enter km"
      flag = true
    }
    if (extraDetail.length) {
      extraDetail.map((item, i) => {
        if (item.startTime === "") {
          error[`startTime${i + 1}`] = 'Required'
          flag = true;
        }
        if (item.endTime === "") {
          error[`endTime${i + 1}`] = 'Required'
          flag = true;
        }
        if (item.description === "") {
          error[`description${i + 1}`] = 'Required'
          flag = true;
        }
      })
    }
    setFormErrors(error);
    return flag
  }

  const onChangeHandler = (e, name) => {
    setFormErrors({ ...formErrors, [name]: '' })
    let regex = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/;
    if (name === "date") {
      setDailySiteReportData({ ...dailySiteReportData, [name]: e })
    } else if (name === "employeeId") {
      setDailySiteReportData({ ...dailySiteReportData, [name]: e.value })
    } else if (name === 'km') {
      if (e.target.value === "" || regex.test(e.target.value)) {
        setDailySiteReportData({ ...dailySiteReportData, [name]: e.target.value })
      }
    }
  }

  const handleChange = (e, i, name) => {
    setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' })
    let val = [...extraDetail];
    if (name === "startTime") {
      val[i].startTime = e.target.value
    } else if (name === "endTime") {
      val[i].endTime = e.target.value
    } else if (name === "description") {
      val[i].description = e.target.value
    }
  }

  const addData = () => {
    let val = [...extraDetail];
    val.push({
      startTime: '',
      endTime: '',
      description: ''
    });
    setExtraDetail(val);
  };

  const Remove = (i) => {
    if (extraDetail.length > 1) {
      let val = [...extraDetail];
      val.splice(i, 1);
      setExtraDetail(val);
    }
  }

  const openCalender = (id) => {
    document.getElementById(id).click()
  }

  const selectValue = (value, name) => {
    if (name === "employeeId") {
      return empOption.find((ele) => ele.value === value)
    }
  }

  return (
    <>
      <div className='p-4'>
        <div className='mb-4 set-page-heade'>
          <div>
            <h2 className='mb-0'>Staff Daily Report</h2>
          </div>
          <div className='mt-3 mt-sm-0'>
            <button className="adduser-btn" onClick={() => dailyreportId ? onEdit() : onSave()}>{dailyreportId ? 'Edit' : 'Save'}</button>
            <button className="cancel-button ms-3" onClick={() => navigate("/staff-daily-report")}>Cancel</button>
          </div>
        </div>
        <div className="users-form addEnquiry">
          <Row>
            {userRole === UserRoles.Admin && <Col lg={4} md={4}>
              <div className="users-form-info add-remark-modal mx-0 mt-3">
                <div className="multi-select ">
                  <label>Employee</label>
                  <Select
                    placeholder="Select Employee"
                    value={selectValue(dailySiteReportData.employeeId, "employeeId")}
                    onChange={(e) => onChangeHandler(e, "employeeId")}
                    options={empOption}
                    styles={{ width: "100%" }}
                  />
                </div>
                {formErrors?.employeeId && <span style={{ color: "red" }}>{formErrors.employeeId}</span>}
              </div>
            </Col>}
            <Col lg={4} md={4}>
              <div className="users-form-info date-picker mt-3">
                <div className="users-label description-label enquery-datepicker">
                  <label>Date</label>
                  <ReactDatePicker id='Remarkdate' dateFormat="dd/MM/yyyy" selected={dailySiteReportData.date} placeholderText="Select date" onChange={(e) => { onChangeHandler(e, "date") }} autoComplete='off' disabled={userRole !== UserRoles.Admin} />
                </div>
                <img src={Datepickericon} onClick={() => openCalender('Remarkdate')} alt="" className='datepickericon img-set' id='Remarkdate' style={{ right: "5%" }} />
                {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>}
              </div>
            </Col>
            <Col lg={4} md={4}>
              <div className="users-form-info mt-3">
                <div className="users-label">
                  <label>Total KM Travel Today</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder='Enter total km'
                    onChange={(e) => { onChangeHandler(e, "km") }}
                    value={dailySiteReportData.km}
                  />
                </div>
                {formErrors?.km && <label style={{ color: "red" }}>{formErrors.km}</label>}
              </div>
            </Col>
          </Row>
          <div className='over-flow-scroll'>
            {extraDetail.map((item, i) => (
              <div key={i}>
                <Row>
                  <Col lg={4} sm={6}>
                    <div className="users-form-info mt-3">
                      <div className="users-label description-label">
                        <label>Start Time</label>
                        <input
                          type="time"
                          className="form-control"
                          onChange={(e) => { handleChange(e, i, "startTime") }}
                          value={item.startTime}
                        />
                      </div>
                      {formErrors && formErrors[`startTime${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`startTime${i + 1}`]}</label>}
                    </div>
                  </Col>
                  <Col lg={4} sm={6}>
                    <div className="users-form-info mt-3">
                      <div className="users-label description-label">
                        <label>End Time</label>
                        <input
                          type="time"
                          className="form-control"
                          onChange={(e) => { handleChange(e, i, "endTime") }}
                          value={item.endTime}
                        />
                      </div>
                      {formErrors && formErrors[`endTime${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`endTime${i + 1}`]}</label>}
                    </div>
                  </Col>
                  <Col lg={3} sm={6}>
                    <div className="users-form-info mt-3">
                      <div className="users-label description-label">
                        <label>Description</label>
                        <textarea
                          type="time"
                          style={{ height: 'auto', marginTop: '2px', width: "100%" }}
                          className="form-control"
                          placeholder="Enter description"
                          onChange={(e) => { handleChange(e, i, "description") }}
                          value={item.description}
                          rows="1"
                        />
                      </div>
                      {formErrors && formErrors[`description${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`description${i + 1}`]}</label>}
                    </div>
                  </Col>
                  <Col lg={1} sm={6} className="ms-xl-0 mt-md-5 mt-xl-5 mt-5">
                    <div className="users-form-info text-xl-center mx-0">
                      <div className="">
                        <label> </label>
                        {i === extraDetail.length - 1 && (
                          <img
                            src={Plusicon}
                            onClick={() => { addData() }}
                            width="30"
                            style={{ cursor: "pointer" }}
                            alt=""
                          />
                        )}
                        {extraDetail.length > 1 && <img
                          src={Minusicon}
                          onClick={() => Remove(i)}
                          width="35"
                          style={{ cursor: "pointer" }}
                          alt=""
                        />}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default StaffDailySiteReport