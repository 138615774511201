import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import removeImg from '../../assets/image/delete-red.png'
import { DELETE_ASSETS, FILE_UPLOAD } from '../../redux/type';
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import { API } from '../../config/API/api.config';
import editIcon from '../../assets/image/edit.svg';
import { deleteAssetsAction, getAllAssetsNonProjectAction } from '../../redux/actions/assetsAction';
import AssetsNonProject from '../../components/modals/AssetsNonProject';
import viewImg from '../../assets/image/eye.svg'
import ViewAsset from '../../components/view-modals/ViewAsset';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Assets = () => {
    const gridRef = useRef();
    const dispatch = useDispatch()
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin]
    const [assetsNonProject, setAssetsNonProject] = useState([]);
    const [AssetsId, setassetsId] = useState('')
    const [perPage, setPerPage] = useState("10");
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [assetsTog, setAssetsTog] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);
    const [viewTog, setViewTog] = useState(false);
    const [viewId, setViewId] = useState('');
    const { getAllAssetsNonProjects } = usePaginationHooks()
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit);
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    const toastSuccess = () => toast.success('Assets delete successfully');

    // const allAssetNonProject = useSelector((state) => state.get_All_Assets_Non_Project.getAllAssetsNonProject)
    const addAssetsData = useSelector((state) => state.add_Assets.addAssets)
    const deleteAssetsData = useSelector((state) => state.delete_Assets.deleteAssets)
    const editAssetsData = useSelector((state) => state.edit_Assets.editAssets)

    // useEffect(() => {
    //     if (allAssetNonProject && allAssetNonProject.data) {
    //         setAssetsNonProject(allAssetNonProject.data.map((ele) => {
    //             return {
    //                 name: ele.name ? ele.name : '',
    //                 file: ele.file ? ele.file : '',
    //                 createdBy: ele.createdBy ? ele.createdBy : '',
    //                 _id: ele._id ? ele._id : '',
    //             }
    //         }))
    //     }
    // }, [allAssetNonProject])

    const downloadFile = (name) => {
        window.open(`${API.endpoint}/${name}`, "_blank")
    }

    const columnDef = [
        { field: 'name', minWidth: 150, },
        {
            field: "file",
            minWidth: 150,
            cellRendererFramework: (params) => <div>{params.value?.split('/')[1]}</div>
        },
        {
            headerName: 'File Download',
            field: 'file',
            minWidth: 150,
            sortable: false,
            cellRendererFramework: (params) => <div style={{ margin: "5px auto" }}>
                {params.data?.file ? <button className='edit-button' style={{ height: '32px', width: '32px', lineHeight: '34px' }}
                    onClick={() => downloadFile(params.data.file)}>
                    <img src="./assets/image/download.png" alt="" width="15px" />
                </button> : <p>-</p>}
            </div>
        },
        {
            field: "createdBy",
            minWidth: 150,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 150,
            sortable: false,
            cellRendererFramework: (params) => <div>
                {/* <img src={editIcon} onClick={() => { Edit(params.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" /> */}
                <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Delete" />
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Viewer" />
            </div>
        }
    ]

    const NoRolecolumnDef = [
        { field: 'name', minWidth: 150, },
        {
            field: "file",
            minWidth: 150,
            cellRendererFramework: (params) => <div>{params?.value?.split('/')[1]}</div>
        },
        {
            headerName: 'File Download',
            field: 'file',
            minWidth: 150,
            sortable: false,
            cellRendererFramework: (params) => <div style={{ margin: "5px auto" }}>
                {params?.data?.file ? <button className='edit-button' style={{ height: '32px', width: '32px', lineHeight: '34px' }}
                    onClick={() => downloadFile(params?.data?.file)}>
                    <img src="./assets/image/download.png" alt="" width="15px" />
                </button> : <p>-</p>}
            </div>
        },
        {
            field: "createdBy",
            minWidth: 150,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 150,
            sortable: false,
            cellRendererFramework: (params) => <div>
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Viewer" />
            </div>
        }
    ]

    const Edit = (id) => {
        setassetsId(id)
        setAssetsTog(true)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id" && params.colDef.headerName !== "File Download") {
            return Edit(params.data._id);
        }
    }

    const onDelete = (id) => {
        dispatch(deleteAssetsAction(id))
        setReject(false)
    }

    useEffect(() => {
        if (deleteAssetsData && deleteAssetsData?.data) {
            if (deleteAssetsData?.data?.message === "Assets deleted successfully.") {
                dispatch({
                    type: DELETE_ASSETS,
                    payload: []
                })
                // dispatch(getAllAssetsNonProjectAction())
                toastSuccess(true)
            }
        }
    }, [deleteAssetsData])

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        if (RolesForAdd.includes(userRole)) {
            setColumnDefs(columnDef)
        } else {
            setColumnDefs(NoRolecolumnDef)
        }
        // dispatch(getAllAssetsNonProjectAction())
    }, [])

    const Hide = () => {
        setassetsId('')
        setAssetsTog(false)
        dispatch({
            type: FILE_UPLOAD,
            payload: []
        })
    }

    useEffect(() => {
        if (assetsTog) {
            dispatch({
                type: FILE_UPLOAD,
                payload: []
            })
        }
    }, [assetsTog])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || deleteAssetsData?.data?.status === 200 || editAssetsData?.data?.status === 200 || addAssetsData?.data?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllAssetsNonProjects(page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            // setgetAllReturnMaterialdata(res.data)
                            params.successCallback(res?.data.map((ele) => {
                                return {
                                    name: ele.name ? ele.name : '',
                                    file: ele.file ? ele.file : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

        // setisFilter(false)
    }, [gridApi, limit, deleteAssetsData, editAssetsData, addAssetsData, filter.search]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>Assets</h2>
                    </div>
                    <div className='d-flex'>
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn ms-3' onClick={() => clearFilter()}>Clear Filter</button>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => setAssetsTog(true)}>Add</button>}
                    </div>
                </div>
                {assetsNonProject &&
                    <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                        <AgGridReact
                            pagination={true}
                            columnDefs={columnDefs}
                            rowModelType={"infinite"}
                            paginationPageSize={limit}
                            cacheBlockSize={limit}
                            onGridReady={onGridReady}
                            rowSelection={'single'}
                            onCellClicked={handleRowClick}
                            rowHeight={45}
                            defaultColDef={defaultColDef}
                            overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                        />

                        <div className='set-pagination-dropdaun'>
                            <p>Show</p>
                            <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                                <option value="50">
                                    50
                                </option>
                                <option value="100" selected={true}>100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                            <p>Entries</p>
                        </div>
                    </div>
                }
            </div>
            {assetsTog && <AssetsNonProject show={assetsTog} onHide={() => Hide()} AssetsId={AssetsId} />}
            {viewTog && <ViewAsset show={viewTog} onHide={() => { setViewTog(false); setViewId('') }} viewId={viewId} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}

export default Assets