import { ADD_RETURN_MATERIAL, ADD_RETURN_MATERIAL_ERROR, ADD_RETURN_MATERIAL_LOADING, DELETE_RETURN_MATERIAL, DELETE_RETURN_MATERIAL_ERROR, DELETE_RETURN_MATERIAL_LOADING, EDIT_RETURN_MATERIAL, EDIT_RETURN_MATERIAL_ERROR, EDIT_RETURN_MATERIAL_LOADING, GET_ALL_RETURN_MATERIAL, GET_ALL_RETURN_MATERIAL_ERROR, GET_ALL_RETURN_MATERIAL_LOADING, GET_RETURN_MATERIAL_BY_ID, GET_RETURN_MATERIAL_BY_ID_ERROR, GET_RETURN_MATERIAL_BY_ID_LOADING, GET_RETURN_MATERIAL_BY_PROJECT, GET_RETURN_MATERIAL_BY_PROJECT_ERROR, GET_RETURN_MATERIAL_BY_PROJECT_LOADING } from "../type";

const addReturnMaterialData = {
    loading:false,
    addReturnMaterial:[],
    error:'',
}

const editReturnMaterialData = {
    loading:false,
    editReturnMaterial:[],
    error:'',
}

const getAllReturnMaterialData = {
    loading:false,
    getAllReturnMaterial:[],
    error:'',
}

const getReturnMaterialByIdData = {
    loading:false,
    getReturnMaterialById:[],
    error:'',
}

const getReturnMaterialByProjectData = {
    loading:false,
    getReturnMaterialByProject:[],
    error:'',
}

const deleteReturnMaterialData = {
    loading:false,
    deleteReturnMaterial:[],
    error:'',
}

export const addReturnMaterialReducer = (state = addReturnMaterialData, action) => {
    switch (action.type) {
        case ADD_RETURN_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_RETURN_MATERIAL:
            return {
                ...state,
                addReturnMaterial: action.payload,
            };
        case ADD_RETURN_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editReturnMaterialReducer = (state = editReturnMaterialData, action) => {
    switch (action.type) {
        case EDIT_RETURN_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_RETURN_MATERIAL:
            return {
                ...state,
                editReturnMaterial: action.payload,
            };
        case EDIT_RETURN_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllReturnMaterialReducer = (state = getAllReturnMaterialData, action) => {
    switch (action.type) {
        case GET_ALL_RETURN_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_RETURN_MATERIAL:
            return {
                ...state,
                getAllReturnMaterial: action.payload,
            };
        case GET_ALL_RETURN_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getReturnMaterialByIdReducer = (state = getReturnMaterialByIdData, action) => {
    switch (action.type) {
        case GET_RETURN_MATERIAL_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_RETURN_MATERIAL_BY_ID:
            return {
                ...state,
                getReturnMaterialById: action.payload,
            };
        case GET_RETURN_MATERIAL_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getReturnMaterialByProjectReducer = (state = getReturnMaterialByProjectData, action) => {
    switch (action.type) {
        case GET_RETURN_MATERIAL_BY_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_RETURN_MATERIAL_BY_PROJECT:
            return {
                ...state,
                getReturnMaterialByProject: action.payload,
            };
        case GET_RETURN_MATERIAL_BY_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteReturnMaterialReducer = (state = deleteReturnMaterialData, action) => {
    switch (action.type) {
        case DELETE_RETURN_MATERIAL_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_RETURN_MATERIAL:
            return {
                ...state,
                deleteReturnMaterial: action.payload,
            };
        case DELETE_RETURN_MATERIAL_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}