import React from 'react'
import { Form } from 'react-bootstrap'

const AddRole = () => {
    return (
        <div className='position-relative p-4'>
            <div className='mb-4 set-page-heade'>
                <h2>Role</h2>
            </div>
            <div className="users-form">
                <div className="d-flex flex-column justify-content-between" style={{height:"72vh"}}>
                    <div>
                        <div className=" row">
                            <div className="col-lg-6 mt-4">
                                <div className="users-form-info ">
                                    <div className="users-label">
                                        <label> Name</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter Role Name"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-form-info">
                            <div className='mt-4 checkbox-input'>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="All" />
                                </Form.Group>
                            </div>
                            <div className='mt-4 checkbox-input'>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="Enquiry" />
                                </Form.Group>
                            </div>
                            <div className='mt-4 checkbox-input'>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="Project" />
                                </Form.Group>
                            </div>
                        </div>
                    </div>

                    <div className="noc-form-btn px-5 pb-3">
                        <button className="save-button" >Save</button>
                        <button className="cancel-button" onClick={() => { }}>Cancel</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddRole