import { IS_LOGIN } from "../type"

const initialState = {
    is_Login: false
};

export const isLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_LOGIN:
            return {
                ...state,
                is_Login: action.payload,
            };
        default:
            return state;
    }
}