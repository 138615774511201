import { DELETE_PURCHASE, DELETE_PURCHASE_ERROR, DELETE_PURCHASE_LOADING, EDIT_PURCHASE, EDIT_PURCHASE_ERROR, EDIT_PURCHASE_LOADING, GET_ALL_PURCHASE, GET_ALL_PURCHASE_ERROR, GET_ALL_PURCHASE_LOADING, GET_PURCHASE_BY_ID, GET_PURCHASE_BY_ID_ERROR, GET_PURCHASE_BY_ID_LOADING } from "../type";

const getPurchaseByIdData = {
    loading: false,
    getPurchaseById: [],
    error: '',
}

const getAllPurchaseData = {
    loading: false,
    getAllPurchase: [],
    error: '',
}

const editPurchaseData = {
    loading: false,
    editPurchase: [],
    error: '',
}
const deletePurchaseData = {
    loading: false,
    deletePurchase: [],
    error: '',
}

export const getPurchaseByIdReducer = (state = getPurchaseByIdData, action) => {
    switch (action.type) {
        case GET_PURCHASE_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PURCHASE_BY_ID:
            return {
                ...state,
                getPurchaseById: action.payload,
            };
        case GET_PURCHASE_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllPurchaseReducer = (state = getAllPurchaseData, action) => {
    switch (action.type) {
        case GET_ALL_PURCHASE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_PURCHASE:
            return {
                ...state,
                getAllPurchase: action.payload,
            };
        case GET_ALL_PURCHASE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editPurchaseReducer = (state = editPurchaseData, action) => {
    switch (action.type) {
        case EDIT_PURCHASE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_PURCHASE:
            return {
                ...state,
                editPurchase: action.payload,
            };
        case EDIT_PURCHASE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
export const deletePurchaseReducer = (state = deletePurchaseData, action) => {
    switch (action.type) {
        case DELETE_PURCHASE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_PURCHASE:
            return {
                ...state,
                deletePurchase: action.payload,
            };
        case DELETE_PURCHASE_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}