import React from 'react'

const Setting = () => {
  return (
    <div className='p-4'>
      <div className='mb-4 set-page-heade'>
        <h2 className='mb-0'>Setting</h2>
      </div>
      {/* <div className="users-form"> */}
      <div className='dashboard-fack-text'>
        <h1>Coming soon</h1>
      </div>
      {/* </div> */}
    </div>
  )
}

export default Setting