import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { API } from '../../config/API/api.config';
import Viewicon from '../../assets/image/view.png'

const ViewFinalQuotation = ({ show, onHide, select }) => {

    const fileOpen = (name) => {
        window.open(`${API.endpoint}/${name}`, "_blank")
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                centered
                size="lg"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Final Quotation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {select?.length > 0 ?
                        select.map((ele, i) => ele &&
                            <>
                                <label style={{ color: "green", marginTop: "10px" }} key={i}>
                                    <img src={Viewicon} alt="View" width="20" style={{ marginRight: "5px" }} onClick={() => fileOpen(ele.value)} />
                                    {ele.value.split('/')[1]}
                                </label>
                                <br />
                            </>
                        )
                        : ''}
                </Modal.Body>
                <Modal.Footer>
                    <button className='cancel-button' onClick={onHide}>Cancel</button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ViewFinalQuotation