import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addProductAction, editProductAction, getProductByIdAction } from '../../redux/actions/productAction';
import img from "../../assets/image/select.png";
import { Unit } from '../../helper/Constant';
import { toast } from 'react-toastify';
import { ADD_PRODUCT } from '../../redux/type';

const AddProduct = ({ show, onHide, productId }) => {

    const dispatch = useDispatch()
    const [product, setProduct] = useState({
        productName: "",
        labourCharge: "",
        productCode: "",
        hsnsacCode: "",
        retailSalePrice: "",
        buyingPrice: "",
        gst: "18",
        unitOfMeasurement: "",
        openingStock: "",
        minQuntity: "",
    })
    const [onHand, setOnHand] = useState(0)
    const [formErrors, setFormErrors] = useState()
    const [rotateArrow, setrotateArrow] = useState(false);
    const productData = useSelector((state) => state.get_Product_By_Id.getProductById)
    const addProductData = useSelector((state) => state.add_Product.addProduct)
    const toastAdd = () => toast.error("Product Code is Already Exists.");
    const toastAddSuccess = () => toast.success("Product created successfully.");

    useEffect(() => {
        if (productId !== "") {
            dispatch(getProductByIdAction(productId))
        }
    }, [productId])

    useEffect(() => {
        if (productData && productData.data && productId) {
            setProduct({
                productName: productData.data.productName,
                productCode: productData.data?.productCode,
                hsnsacCode: productData.data.hsnsacCode,
                retailSalePrice: productData.data.retailSalePrice,
                buyingPrice: productData.data.buyingPrice,
                gst: productData.data.gst,
                labourCharge: productData.data.labourCharge,
                unitOfMeasurement: productData.data.unitOfMeasurement,
                openingStock: productData.data.openingStock,
                minQuntity: productData.data.minQuntity,
            })
            setOnHand(productData.data.purchase + productData.data.openingStock - productData.data.sell)
        }
    }, [productData])

    const handleChange = (e, name) => {
        let re = /^[0-9\b]+$/;
        let regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        let numAlph = /^[a-zA-Z0-9]*$/;
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "retailSalePrice" || name === "buyingPrice" || name === "gst" || name === "openingStock" || name === "labourCharge") {
            if (e.target.value === '' || regexwithdecimal.test(e.target.value)) {
                setProduct({ ...product, [name]: e.target.value })
            }
        }
        else if (name === "minQuntity") {
            // if (product.openingStock === "") {
            //     setFormErrors({ ...formErrors, minQuntity: "Please first enter opening stock" })
            // }
            // else if (e.target.value === '' || re.test(e.target.value) && parseInt(product.openingStock) >= parseInt(e.target.value)) {
            if (e.target.value === '' || regexwithdecimal.test(e.target.value)) {
                setProduct({ ...product, [name]: e.target.value })
            }
        }
        else if (name === "productCode") {
            if (e.target.value === "" || numAlph.test(e.target.value)) {
                setProduct({ ...product, [name]: e.target.value })
            }
        }
        else {
            setProduct({ ...product, [name]: e.target.value })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        if (!product.productName) {
            error.productName = "Please enter product name"
            flag = true
        }
        if (!product.productCode) {
            error.productCode = "Please enter product code"
            flag = true
        }
        if (!product.hsnsacCode) {
            error.hsnsacCode = "Please enter hsn/sac code"
            flag = true
        }
        if (!product.retailSalePrice) {
            error.retailSalePrice = "Please enter retail sale price"
            flag = true
        }
        if (!product.buyingPrice) {
            error.buyingPrice = "Please enter buying price"
            flag = true
        }
        // if (!product.labourCharge) {
        //     error.labourCharge = "Please enter Labour Charge"
        //     flag = true
        // }
        if (!product.gst) {
            error.gst = "Please enter gst"
            flag = true
        }
        if (!product.unitOfMeasurement) {
            error.unitOfMeasurement = "Please select unit of measurement"
            flag = true
        }
        if (!productId && !product.openingStock) {
            error.openingStock = "Please enter opening stock"
            flag = true
        }
        if (product.minQuntity === '' || product.minQuntity === undefined)  {
            error.minQuntity = "Please enter minimum quantity"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const Save = () => {
        if (validation()) {
            return
        }
        dispatch(addProductAction(product))
    }

    useEffect(() => {
        if (addProductData && addProductData.status === 200) {
            if (addProductData && addProductData.message === "Product Code is Already Exists.") {
                toastAdd(true)
                dispatch({ type: ADD_PRODUCT, payload: [] })
            } else if (addProductData && addProductData.message === "Product created successfully.") {
                toastAddSuccess(true)
                onHide()
            }
        }
    }, [addProductData])


    const Edit = () => {
        if (validation()) {
            return
        }
        let data = {
            ...product,
            id: productId
        }
        dispatch(editProductAction(data))
        onHide()
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{productId ? `Edit Product (On Hand: ${onHand})` : "Add Product"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Product Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter product name"
                                    value={product.productName}
                                    onChange={(e) => handleChange(e, 'productName')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.productName && <span style={{ color: "red" }}>{formErrors.productName}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Product Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter product code"
                                    value={product.productCode}
                                    onChange={(e) => handleChange(e, 'productCode')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.productCode && <span style={{ color: "red" }}>{formErrors.productCode}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>HSN/SAC Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter hsn/sac code"
                                    value={product.hsnsacCode}
                                    onChange={(e) => handleChange(e, 'hsnsacCode')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.hsnsacCode && <span style={{ color: "red" }}>{formErrors.hsnsacCode}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Retail Sale Price</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter retail sale price"
                                    value={product.retailSalePrice}
                                    onChange={(e) => handleChange(e, 'retailSalePrice')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.retailSalePrice && <span style={{ color: "red" }}>{formErrors.retailSalePrice}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Buying Price</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter buying price"
                                    value={product.buyingPrice}
                                    onChange={(e) => handleChange(e, 'buyingPrice')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.buyingPrice && <span style={{ color: "red" }}>{formErrors.buyingPrice}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>GST(%)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter GST"
                                    value={product.gst}
                                    onChange={(e) => handleChange(e, 'gst')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.gst && <span style={{ color: "red" }}>{formErrors.gst}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Labour Charge</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Labour Charge"
                                    value={product.labourCharge}
                                    onChange={(e) => handleChange(e, 'labourCharge')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.labourCharge && <span style={{ color: "red" }}>{formErrors.labourCharge}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Unit Of Measurement</label>
                                <div className="position-relative select-icon user-type">
                                    <img src={img} className={`${rotateArrow && "rotateArrow"}`} alt="" />
                                    <select className="form-control " value={product.unitOfMeasurement} onBlur={() => { setrotateArrow(false) }} onClick={() => { setrotateArrow(!rotateArrow) }} onChange={(e) => handleChange(e, "unitOfMeasurement")}>
                                        <option value="">Select measurement</option>
                                        <option value={Unit.KG}>KG</option>
                                        <option value={Unit.LTR}>LTR</option>
                                        <option value={Unit.NOS}>NOS</option>
                                        <option value={Unit.MTR}>MTR</option>
                                        <option value={Unit.FEET}>FEET</option>
                                    </select>
                                </div>
                            </div>
                            {formErrors?.unitOfMeasurement && <span style={{ color: "red" }}>{formErrors.unitOfMeasurement}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Opening Stock</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter opening stock"
                                    value={product.openingStock == 0 ? "" : product.openingStock}
                                    onChange={(e) => handleChange(e, 'openingStock')}
                                    autoComplete="off"
                                    maxLength={10}
                                />
                            </div>
                            {formErrors?.openingStock && <span style={{ color: "red" }}>{formErrors.openingStock}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Minimum Quantity</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter minimum quantity"
                                    value={product.minQuntity}
                                    onChange={(e) => handleChange(e, 'minQuntity')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.minQuntity && <span style={{ color: "red" }}>{formErrors.minQuntity}</span>}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { productId ? Edit() : Save() }}>{productId ? "Edit" : "Save"}</button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>

        </Modal>
    )
}

export default AddProduct