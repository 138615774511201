import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAssetsByIdAction } from '../../redux/actions/assetsAction'
import { getAllCustomerAction } from '../../redux/actions/customerAction'

const ViewAsset = ({ show, onHide, viewId }) => {

    const dispatch = useDispatch()

    const [asset, setAsset] = useState({
        name: '',
        file: '',
    })

    const getAssetsByIdData = useSelector((state) => state.get_Assets_By_Id.getAssetsById)

    useEffect(() => {
        dispatch(getAllCustomerAction())
    }, [])

    useEffect(() => {
        if (viewId) {
            dispatch(getAssetsByIdAction(viewId))
        }
    }, [viewId])

    useEffect(() => {
        if (getAssetsByIdData && getAssetsByIdData.data && viewId) {
            setAsset({
                name: getAssetsByIdData.data.name,
                file: getAssetsByIdData.data.file,
            })
        }
    }, [getAssetsByIdData])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="md"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={12}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Invoice Date</td>
                                    <td>{asset.name}</td>
                                </tr>
                                <tr>
                                    <td>File</td>
                                    <td>{asset.file ? asset.file.split('/')[1] : ''}</td>

                                </tr>
                            </table>
                        </div>
                    </Col>
                    </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewAsset