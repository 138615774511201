import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_PRODUCT, ADD_PRODUCT_ERROR, ADD_PRODUCT_LOADING, DELETE_PRODUCT, DELETE_PRODUCT_ERROR, DELETE_PRODUCT_LOADING, EDIT_PRODUCT, EDIT_PRODUCT_ERROR, EDIT_PRODUCT_LOADING, GET_ALL_PRODUCT, GET_ALL_PRODUCT_ERROR, GET_ALL_PRODUCT_LOADING, GET_PRODUCT_BY_FINAL_MATERIAL, GET_PRODUCT_BY_FINAL_MATERIAL_ERROR, GET_PRODUCT_BY_FINAL_MATERIAL_LOADING, GET_PRODUCT_BY_ID, GET_PRODUCT_BY_ID_ERROR, GET_PRODUCT_BY_ID_LOADING, GET_PRODUCT_DROPDOWN_DATA, GET_PRODUCT_DROPDOWN_DATA_ERROR, GET_PRODUCT_DROPDOWN_DATA_LOADING, GET_PRODUCT_LEDGER, GET_PRODUCT_LEDGER_ERROR, GET_PRODUCT_LEDGER_LOADING, IMPORT_FILE, IMPORT_FILE_ERROR, IMPORT_FILE_LOADING, IS_LOADING, GET_ALL_PRODUCT_VALUE, GET_ALL_PRODUCT_VALUE_ERROR, GET_ALL_PRODUCT_VALUE_LOADING } from "../type"

export const addProductAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_PRODUCT_LOADING,
            payload: true
        })
        await ApiPost('product/add_product', body)
            .then((res) => {
                dispatch({
                    type: ADD_PRODUCT,
                    payload: res
                })
                // dispatch(getAllProductAction())
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: ADD_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_PRODUCT_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getProductByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PRODUCT_BY_ID_LOADING,
            payload: true
        })
        await ApiGet(`product/get_product_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_PRODUCT_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_PRODUCT_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PRODUCT_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PRODUCT_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllProductAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_PRODUCT_LOADING,
            payload: true
        })
        await ApiGet('product/get_all_products')
            .then((res) => {
                dispatch({
                    type: GET_ALL_PRODUCT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_ALL_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_PRODUCT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editProductAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_PRODUCT_LOADING,
            payload: true
        })
        await ApiPut('product/edit_product', body)
            .then((res) => {
                dispatch({
                    type: EDIT_PRODUCT,
                    payload: res
                })
                dispatch(getAllProductAction())
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: EDIT_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_PRODUCT_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteProductAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_PRODUCT_LOADING,
            payload: true
        })
        await ApiDelete(`product/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_PRODUCT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: DELETE_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_PRODUCT_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getProductDropDownAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PRODUCT_DROPDOWN_DATA_LOADING,
            payload: true
        })
        await ApiGet(`product/get_product`)
            .then((res) => {
                dispatch({
                    type: GET_PRODUCT_DROPDOWN_DATA,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_PRODUCT_DROPDOWN_DATA_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PRODUCT_DROPDOWN_DATA_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PRODUCT_DROPDOWN_DATA_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const importFileAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: IMPORT_FILE_LOADING,
            payload: true
        })
        await ApiPost(`product/import_file`, body)
            .then((res) => {
                dispatch({
                    type: IMPORT_FILE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: IMPORT_FILE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: IMPORT_FILE_ERROR,
            payload: err
        })

        dispatch({
            type: IMPORT_FILE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getProductByFinalMaterialAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PRODUCT_BY_FINAL_MATERIAL_LOADING,
            payload: true
        })
        await ApiGet(`project/get_product_by_final_material/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_PRODUCT_BY_FINAL_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_PRODUCT_BY_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PRODUCT_BY_FINAL_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PRODUCT_BY_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getProductLedger = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PRODUCT_LEDGER_LOADING,
            payload: true
        })
        await ApiGet(`product/get_product_ledger/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_PRODUCT_LEDGER,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })
        dispatch({
            type: GET_PRODUCT_LEDGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PRODUCT_LEDGER_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PRODUCT_LEDGER_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllProductValueAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_PRODUCT_VALUE_LOADING,
            payload: true
        })

        await ApiGet('product/get_all_products_value')
            .then((res) => {
                dispatch({
                    type: GET_ALL_PRODUCT_VALUE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_PRODUCT_VALUE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_PRODUCT_VALUE_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_PRODUCT_VALUE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}