import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import removeImg from '../../assets/image/delete-red.png'
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import { deletePurchaseRequestAction, getAllPurchaseRequestAction } from '../../redux/actions/purchaseRequest';
import AddPurchaseRequest from '../../components/modals/AddPurchaseRequest';
import editIcon from '../../assets/image/edit.svg';
import { ADD_RETURN_MATERIAL, DELETE_PURCHASE_REQUEST, DELETE_RETURN_MATERIAL, EDIT_RETURN_MATERIAL, GET_PRODUCT_BY_FINAL_MATERIAL, GET_PURCHASE_REQUEST_BY_ID, GET_RETURN_MATERIAL_BY_ID } from '../../redux/type';
import { useLocation, useNavigate } from 'react-router-dom';
import { dateFormate } from '../../helper/utils';
import ViewPurchaseRequest from '../../components/view-modals/ViewPurchaseRequest';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import { deleteReturnMaterialAction, getAllReturnMaterialAction } from '../../redux/actions/returnMaterialAction';
import AddReturnMaterial from '../../components/modals/AddReturnMaterial';
import ViewReturnMaterial from '../../components/view-modals/ViewReturnMaterial';
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const ReturnMaterial = () => {
    const gridRef = useRef();
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Store]
    const [allReturnMaterial, setAllReturnMaterial] = useState([]);
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [addReturnMTog, setAddReturnMTog] = useState(false);
    const [returnId, setReturnId] = useState('')
    const [columnDefs, setColumnDefs] = useState([]);
    const [viewPRTog, setViewPRTog] = useState(false);
    const [viewId, setViewId] = useState('');
    const { getAllReturnMaterials } = usePaginationHooks()
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit);
    const [getAllReturnMaterialdata, setgetAllReturnMaterialdata] = useState([])
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ column_name: '', column_order: '' });
    const toastSuccess = () => toast.success('Return material delete successfully');
    // const getAllReturnMaterialdata = useSelector((state) => state.get_All_Return_Material.getAllReturnMaterial)
    const deleteReturnMaterialdata = useSelector((state) => state.delete_Return_Material.deleteReturnMaterial)
    const editReturnMaterialdata = useSelector((state) => state.edit_Return_Material.editReturnMaterial)

    const columnDefsNews = [
        {
            headerName: 'Project Name',
            field: 'project.partyName',
            flex: 1
        },
        {
            field: "returnDate",
            maxWidth: 180,
            cellRendererFramework: (params) => <div>
                {params.value ? dateFormate(params.value, 'Date') : ''}
            </div>
        },
        {
            field: "createdBy",
            maxWidth: 180,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            sortable: false,
            maxWidth: 160,
            cellRendererFramework: (params) => <div>
                {userRole === UserRoles.Admin && <>
                    {/* <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} title="Edit" alt='' /> */}
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' /></>}
                <img src={viewImg} onClick={() => { setViewPRTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
            </div>
        }
    ]

    const NoRolecolumnDefsNew = [
        {
            headerName: 'Project Name',
            field: 'project.partyName',
        },
        {
            field: "returnDate",
            cellRendererFramework: (params) => <div>
                {params.value ? dateFormate(params.value, 'Date') : ''}
            </div>
        },
        {
            field: "createdBy",
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            sortable: false,
            cellRendererFramework: (params) => <div>
                <img src={viewImg} onClick={() => { setViewPRTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
            </div>
        }
    ]

    const onEdit = (id) => {
        setAddReturnMTog(true)
        setReturnId(id)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    const onDelete = (id) => {
        dispatch(deleteReturnMaterialAction(id))
        setReject(false)
    }

    useEffect(() => {
        if (deleteReturnMaterialdata && deleteReturnMaterialdata.data) {
            if (deleteReturnMaterialdata.data.status === 200) {
                dispatch({
                    type: DELETE_RETURN_MATERIAL,
                    payload: []
                })
                // dispatch(getAllReturnMaterialAction())
                toastSuccess(true)
            }
        }
    }, [deleteReturnMaterialdata])

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        if (getAllReturnMaterialdata) {
            if (RolesForAdd.includes(userRole)) {
                setColumnDefs(columnDefsNews)
            } else {
                setColumnDefs(NoRolecolumnDefsNew)
            }
        }
    }, [getAllReturnMaterialdata])

    const hide = () => {
        dispatch({
            type: ADD_RETURN_MATERIAL,
            payload: []
        })
        dispatch({
            type: EDIT_RETURN_MATERIAL,
            payload: []
        })
        dispatch({
            type: GET_RETURN_MATERIAL_BY_ID,
            payload: []
        })
        dispatch({
            type: GET_PRODUCT_BY_FINAL_MATERIAL,
            payload: []
        })
        setAddReturnMTog(false);
        setReturnId('')
    }

    const onHide = () => {
        setViewPRTog(false)
        setViewId('')
    }

    const exportToCSV = async () => {
        getAllReturnMaterials("", "", filter.search, sorting.column_name, sorting.column_order).then(res => {
            if (res && res.data) {
                const newData = res.data?.map((item) => {
                    return {
                        ...item,
                        project: item.project.partyName,
                        createdBy: item.createdBy.name,
                        items: JSON.stringify(item.items)
                    }
                })
                const ws = XLSX.utils.json_to_sheet(newData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                FileSaver.saveAs(data, 'all_return_materials' + '.xlsx');
            }
        })
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || deleteReturnMaterialdata?.data?.status === 200 || editReturnMaterialdata?.data?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    setSorting({ column_name: sorted_column, column_order: sorted_order });

                    getAllReturnMaterials(page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            setgetAllReturnMaterialdata(res.data)
                            params.successCallback(res?.data.map((ele) => {
                                return {
                                    project: ele.project ? ele.project : '',
                                    returnDate: ele.returnDate ? ele.returnDate : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

        // setisFilter(false)
    }, [gridApi, limit, deleteReturnMaterialdata, editReturnMaterialdata, filter.search]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2>Return Material</h2>
                    </div>
                    <div className='d-flex'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn' onClick={() => clearFilter()}>Clear Filter</button>
                        <button className='adduser-btn ms-3' onClick={exportToCSV}>Export All</button>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => setAddReturnMTog(true)}>Add Return Material</button>}
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        onCellClicked={(event) => {
                            if (userRole === UserRoles.Admin) {
                                handleRowClick(event)
                            }
                        }}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />

                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
            </div>
            {addReturnMTog && <AddReturnMaterial show={addReturnMTog} onHide={() => hide()} EditId={returnId} />}
            {viewPRTog && <ViewReturnMaterial show={viewPRTog} onHide={() => onHide()} viewId={viewId} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}

export default ReturnMaterial