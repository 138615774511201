import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Datepickericon from "../../assets/image/date-picker-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import img from "../../assets/image/select.png";
import { addEnquiryAction, editEnquiryAction, getEnquiryByIdAction, approvalForAdminEnquiryAction } from "../../redux/actions/enquiryAction";
import { toast } from "react-toastify";
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";
import { fileRemoveAction, fileUploadAction } from "../../redux/actions/generalAction";
import { ApiPost } from "../../helper/API/ApiData";
import { v4 as uuidv4 } from "uuid";
import Viewicon from '../../assets/image/view.png'
import { ADD_ENQUIRY, ADD_QUOTATION, DELETE_QUOTATION, EDIT_ENQUIRY, EDIT_QUOTATION, GET_QUOTATION_BY_ID } from "../../redux/type";
import { API } from "../../config/API/api.config";
import { Button, Col, Row, Tabs, Tab } from "react-bootstrap";
import { SalesType } from "../../helper/Constant";
import uploadPDF from '../../assets/image/uploadPDF.png'
import removeImg from '../../assets/image/delete-red.png'
import GenerateQuatation from "../../components/modals/generateQuatation";
import Finalize from "../../components/modals/finalizeQuatation";
import editIcon from '../../assets/image/edit.svg';
import greenDot from "../../assets/image/green-dot.png";
import { getAllUserAction } from "../../redux/actions/userAction";
import { deleteQuotationAction, getQuotationByEnquiryIdAction } from "../../redux/actions/quotationAction";
import { downloadQuoatationFile } from "../../helper/utils";
import { getAllProductAction } from "../../redux/actions/productAction";
import moment from "moment";
import SweetAlert from 'react-bootstrap-sweetalert';

const AddEnquiry = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const enquiryId = queryParams.get("enquiryId")
  const toastSuccess = () => toast.success("Enquiry added successfully");
  const toastEditSuccess = () => toast.success("Enquiry update successfully");
  const [rotateArrow, setrotateArrow] = useState('');
  const [file, setFile] = useState(undefined);
  const [formErrors, setFormErrors] = useState({});
  const [quotation, setQuatation] = useState([])
  const [userOption, setUserOption] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isFinal, setIsFinal] = useState(false)
  const [addEnquiry, setAddEnquiry] = useState({
    projectType: "",
    partyName: "",
    email: "",
    location: "",
    googleLocation: "",
    contactPersonName: "",
    contactPersonNumber: "",
    enquiryDate: "",
    visitDate: "",
    assignPerson: '',
    // quotationNumber: "",
    nextFollowups: "",
    referenceBy: "",
    workOrder: "",
  });
  const [delete_quotation, setDelete_Quotation] = useState({ id: "", open: false });
  const [quotationId, setQuotationId] = useState('')
  const [editId, setEditId] = useState()
  const fileData = useSelector((state) => state.file_Upload.fileUpload);
  const enquiryById = useSelector((state) => state.get_Enquiry_By_Id.getEnquiryById)
  const addEnquiryData = useSelector((state) => state.add_Enquiry.addEnquiry)
  const editEnquiryData = useSelector((state) => state.edit_Enquiry.editEnquiry)
  const getAllUserData = useSelector((state) => state.get_All_User.getAllUser)
  const getQuotationByEnquiryIdData = useSelector((state) => state.get_Quotation_By_ProjectId.getQuotationByEnquiryId)
  const addQuotationData = useSelector((state) => state.add_Quotation.addQuotation)
  const editQuotationData = useSelector((state) => state.edit_Quotation.editQuotation)
  const deleteQuotationData = useSelector((state) => state.delete_Quotation.deleteQuotation)
  const getAllProduct = useSelector((state) => state.get_All_Product.getAllProduct)
  const [followUps, setFollowUps] = useState([
    {
      date: "",
      status: "",
      description: "",
      remark: "",
      quoationUpdate: "",
      id: uuidv4(),
    },
  ]);

  useEffect(() => {
    if (enquiryById && enquiryById.data && enquiryId) {

      setAddEnquiry({
        ...addEnquiry,
        projectType: enquiryById.data.projectType,
        partyName: enquiryById.data.partyName,
        email: enquiryById.data.email,
        location: enquiryById.data.location,
        googleLocation: enquiryById.data.googleLocation,
        contactPersonName: enquiryById.data.contactPersonName,
        contactPersonNumber: enquiryById.data.contactPersonNumber,
        enquiryDate: new Date(enquiryById.data.enquiryDate),
        visitDate: new Date(enquiryById.data.visitDate),
        quotationNumber: enquiryById.data.quotationNumber,
        referenceBy: enquiryById.data.referenceBy,
        workOrder: enquiryById.data.workOrder,
        assignPerson: enquiryById.data.assignPerson,
      })
      setFollowUps(enquiryById.data.nextFollowups.map((item) => {
        return {
          date: item.date ? new Date(item.date) : '',
          status: item.status,
          description: item.description,
          remark: item.remark,
          quoationUpdate: item.quoationUpdate,
          id: item.id,
        }
      }))
    }
  }, [enquiryById])


  useEffect(() => {
    if (enquiryId) {
      setEditId(enquiryId)
      dispatch(getEnquiryByIdAction(enquiryId))
    }
  }, [enquiryId])

  useEffect(() => {
    if (enquiryId) {
      dispatch(getQuotationByEnquiryIdAction(enquiryId))
    }

  }, [enquiryId])

  useEffect(() => {
    if (getQuotationByEnquiryIdData && getQuotationByEnquiryIdData.data) {
      const data = getQuotationByEnquiryIdData.data.map((item) => {
        return {
          id: item._id,
          project: item.project,
          isFinal: item.isFinal,
          items: item.items,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
          quotationNo: item.quotationNo,
          quotationName: item.quotationName,
          commonDiscount: item.commonDiscount
        }
      })
      setQuatation(data)
    }
  }, [getQuotationByEnquiryIdData])


  useEffect(() => {
    if (fileData) {
      setAddEnquiry({
        ...addEnquiry, workOrder: fileData.url
      })
    }
  }, [fileData])


  useEffect(() => {
    setAddEnquiry({ ...addEnquiry, nextFollowups: followUps })
  }, [followUps])

  useEffect(() => {
    if (file) {
      let formData = new FormData();
      formData.append("files", file);
      dispatch(fileUploadAction(formData));
      if (addEnquiry.workOrder) {
        fileRemove(addEnquiry.workOrder, '', '')
      }
    }
  }, [file]);

  const fileUpload = (file, i, name) => {
    setFormErrors({ ...formErrors, [name[i]]: '' })
    if (followUps[i].quoationUpdate) {
      fileRemove(followUps[i].quoationUpdate, i, '')
    }
    let formData = new FormData();
    formData.append("files", file);
    ApiPost("general/file_upload", formData)
      .then((res) => {
        let newfollowUps = [...followUps];
        newfollowUps[i].quoationUpdate = res.url;
        setFollowUps(newfollowUps);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fileRemove = (fileData, i, name) => {
    let body = {
      file: fileData.split('/')[1] ? fileData.split('/')[1] : fileData
    }
    dispatch(fileRemoveAction(body))
    if (name === 'remove') {
      let newfollowUps = [...followUps];
      newfollowUps[i].quoationUpdate = '';
      setFollowUps(newfollowUps);
    } else if (name === "workOrderRemove") {
      setAddEnquiry({ ...addEnquiry, workOrder: '' })
    }
  }

  const handleChange = (e, name) => {
    setFormErrors({ ...formErrors, [name]: '' })
    let numberRex = /^[0-9\b]+$/;
    if (name === "contactPersonNumber") {
      if (
        !e.target.value ||
        e.target.value === "" ||
        numberRex.test(e.target.value)
      ) {
        setAddEnquiry({
          ...addEnquiry,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setAddEnquiry({
        ...addEnquiry,
        [e.target.name]: e.target.value,
      });
    }
  };

  const Submit = () => {
    if (validation()) {
      return
    }
    dispatch(addEnquiryAction({ ...addEnquiry, quotation }))
  };

  useEffect(() => {
    if (addEnquiryData && addEnquiryData.data) {
      if (addEnquiryData.message === "Equiry created successfully.") {
        dispatch({
          type: ADD_ENQUIRY,
          payload: []
        })
        toastSuccess()
        navigate('/enquiry')
      }
    }
  }, [addEnquiryData])

  useEffect(() => {
    if (addQuotationData && addQuotationData.data) {
      if (addQuotationData.status === 200) {
        dispatch({
          type: ADD_QUOTATION,
          payload: []
        })
        toast.success(addQuotationData.message)
        dispatch({
          type: ADD_QUOTATION,
          payload: []
        });
        dispatch(getQuotationByEnquiryIdAction(enquiryId))
      }
    }
  }, [addQuotationData])

  useEffect(() => {
    if (editQuotationData && editQuotationData.data) {
      if (editQuotationData.status === 200) {
        dispatch({
          type: GET_QUOTATION_BY_ID,
          payload: []
        })
        toast.success(editQuotationData.data.message)
        dispatch({
          type: EDIT_QUOTATION,
          payload: []
        });
        dispatch(getQuotationByEnquiryIdAction(enquiryId))
      }
    }
  }, [editQuotationData])

  useEffect(() => {
    if (deleteQuotationData && deleteQuotationData.data) {
      if (deleteQuotationData.data.status === 200) {
        setDelete_Quotation({ id: "", open: false });
        toast.success(deleteQuotationData.data.message)
        dispatch({
          type: DELETE_QUOTATION,
          payload: []
        });
        dispatch(getQuotationByEnquiryIdAction(enquiryId))
      }
    }
  }, [deleteQuotationData])

  const Edit = () => {
    if (validation()) {
      return
    }
    const quotationNo = quotation.length ? quotation[quotation.length - 1]?.quotationNo : ''
    dispatch(editEnquiryAction({ ...addEnquiry, id: editId, quotationNumber: quotationNo.toString() }))
  };

  const approvalForAdmin = () => {
    if (!addEnquiry.workOrder) {
      toast.warning("Please Add Work Order");
    } else if (!quotation.length) {
      toast.warning("Please Add Quotation");
    } else {

      const quotationNo = quotation.length ? quotation[quotation.length - 1]?.quotationNo : '';
      dispatch(editEnquiryAction({ ...addEnquiry, id: editId, quotationNumber: quotationNo.toString() }))

      dispatch(approvalForAdminEnquiryAction(editId));
      toast.success("Send To Admin For Approval");
      navigate('/enquiry');
    }
  };

  useEffect(() => {
    if (editEnquiryData && editEnquiryData.data) {
      dispatch({
        type: EDIT_ENQUIRY,
        payload: []
      })
      navigate('/enquiry')
      toastEditSuccess(true)
    }
  }, [editEnquiryData])

  const addData = () => {
    let val = [...followUps];
    val.push({
      date: "",
      status: "",
      description: "",
      remark: "",
      quoationUpdate: "",
      id: uuidv4(),
    });
    setFollowUps(val);
  };

  const Remove = (i) => {
    if (followUps.length > 1) {
      let val = [...followUps];
      val.splice(i, 1);
      setFollowUps(val);
    }
  };

  useEffect(() => {
    dispatch(getAllUserAction())
  }, [])

  useEffect(() => {
    if (getAllUserData.data) {
      const option = getAllUserData.data.map((ele) => {
        return {
          key: ele.name,
          value: ele._id
        }
      });
      setUserOption(option)
    }
  }, [getAllUserData])

  const handleChangeData = (e, i, name) => {
    setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' })
    let val = [...followUps];
    if (name === 'followUpsDate') {
      val[i].date = e;
      setFollowUps(val);
    } else if (name === "followUpsStatus") {
      val[i].status = e.target.value;
      setFollowUps(val);
    } else if (name === "followUpsDescription") {
      val[i].description = e.target.value;
      setFollowUps(val);
    } else if (name === "followUpsRemark") {
      val[i].remark = e.target.value;
      setFollowUps(val);
    }
  };

  const validation = () => {
    let flag = false;
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!addEnquiry.projectType) {
      error.projectType = "Please select type";
      flag = true;
    }
    if (!addEnquiry.partyName) {
      error.partyName = "Please enter party name";
      flag = true;
    }
    if (addEnquiry.email && !addEnquiry.email.match(regex)) {
      error.email = "Enter valid email";
      flag = true
    }
    if (!addEnquiry.location) {
      error.location = "Please enter loaction";
      flag = true;
    }
    if (!addEnquiry.contactPersonName) {
      error.contactPersonName = "Please enter contact person name";
      flag = true;
    }
    if (addEnquiry.contactPersonNumber.length < 10 || addEnquiry.contactPersonNumber.length >= 11) {
      error.contactPersonNumber = "Please enter valid contact person number";
      flag = true;
    }
    if (addEnquiry.contactPersonNumber.length === 0) {
      error.contactPersonNumber = "Please enter contact person number";
      flag = true;
    }
    if (!addEnquiry.enquiryDate) {
      error.enquiryDate = "Please select enquiry date";
      flag = true;
    }
    // if (!addEnquiry.assignPerson) {
    //   error.assignPerson = "Please select Assign person";
    //   flag = true;
    // }
    if (!addEnquiry.visitDate) {
      error.visitDate = "Please select visit date";
      flag = true;
    }
    // if (!addEnquiry.quotationNumber) {
    //   error.quotationNumber = "Please enter quotation number";
    //   flag = true;
    // }
    if (followUps.length) {
      followUps.map((item, i) => {
        if( i > 0 ){
          if (item.date === "") {
          error[`followUpsDate${i + 1}`] = `Please select date ${i + 1}`
          flag = true;
          }
          if (item.status === "") {
          error[`followUpsStatus${i + 1}`] = `Please select status ${i + 1}`
          flag = true;
          }
          if (item.description === "") {
          error[`followUpsDescription${i + 1}`] = `Please enter description ${i + 1}`
          flag = true;
          }
          if (item.remark === "") {
          error[`followUpsRemark${i + 1}`] = `Please enter remarks ${i + 1}`
          flag = true;
          }
      }
      })
    }
    setFormErrors(error);
    return flag;
  };

  const blur = (i) => {
    setrotateArrow('')
  }

  const click = (name) => {
    if (name === rotateArrow) {
      setrotateArrow('')
    } else {
      setrotateArrow(name)
    }
  }

  const openCalender = (id) => {
    document.getElementById(id).click();
  };

  const UploadClick = () => {
    document.getElementById('workOrder').click()
  }

  const QuotationUploadClick = (i) => {
    document.getElementById(`quotation${i}`).click()
  }

  const workOrderFileClick = (name) => {
    window.open(`${API.endpoint}/${name}`, "_blank")
  }

  const Blur = () => {
    setrotateArrow('')
  }

  const RemoveQuotation = (id) => {
    dispatch(deleteQuotationAction(id));
  }
  const onDownload = async (data) => {
    try {
      let productdata = []

      data.items.map((element) => {
          const ele = getAllProduct.data.find((ele) => ele?.productCode === element?.productCode)
          productdata.push({ ...ele, quantity: element?.reqQuantity, discount: element?.discount ?? 0, retailSalePrice: element?.retailSalePrice ?? 0 })
      })

      // productdata.sort((a, b) => {
      //   if (a.productName < b.productName) return -1;
      //   if (a.productName > b.productName) return 1;
      //   return 0;
      // });
      
      const datas = {
        ...data,
        commonDiscount: data?.commonDiscount,
        partyName: addEnquiry.partyName,
        googleLocation: addEnquiry.googleLocation,
        email: addEnquiry.email,
        contactPersonNumber: addEnquiry.contactPersonNumber,
        enquiryDate: moment(addEnquiry.enquiryDate).format("DD/MM/YYYY"),
        visitDate: moment(addEnquiry.visitDate).format("DD/MM/YYYY"),
        location: addEnquiry.location,
      }
      downloadQuoatationFile(productdata, 'Quotation', datas, "Enquiry");
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    dispatch(getAllProductAction())
  }, [])

  return (
    <>
      <div className="addEnquiry">
        <div className="position-relative p-4">
          <div className="mb-4 set-page-heade">
            <div className="d-flex flex-column">
              <h2 className='mb-0'>{editId ? "Edit Enquiry" : "Add Enquiry"}</h2>
            </div>
            <div>
              {editId && <button className="adduser-btn" onClick={() => { setIsOpen(true) }}>Quotation</button>}
              {enquiryById?.data?.status === "New" && editId ? <button className="adduser-btn ms-3 mt-3 mt-md-0" onClick={() => { approvalForAdmin(editId) }}>Send for Admin Approval</button> : <></>}
              {editId ? <button className="adduser-btn ms-3 mt-3 mt-md-0" onClick={() => Edit()}>Edit</button> : <button className="adduser-btn ms-3" onClick={() => Submit()}>Save</button>}
              <button className="cancel-button ms-3 mt-3 mt-md-0" onClick={() => navigate("/enquiry")}>Cancel</button>
            </div>
          </div>
          <div className="users-form">
            <div className="row">
              <div className="col-lg-3 col-sm-6 mt-3">
                <div className="users-form-info date-picker add-remark-modal mx-0">
                  <div className="users-label">
                    <label>Type</label>
                    <div className="position-relative select-icon user-type">
                      <img src={img} className={`${rotateArrow === 'projectType' && "rotateArrow"}`} alt="" />
                      <select className="form-control " value={addEnquiry.projectType} onBlur={() => Blur()} onClick={() => click('projectType')} onChange={(e) => handleChange(e, "projectType")} name="projectType">
                        <option value="">Select type</option>
                        <option value={SalesType.NewProject}>{SalesType.NewProject}</option>
                        <option value={SalesType.Maintenance}>{SalesType.Maintenance}</option>
                        <option value={SalesType.MaterialTrading}>{SalesType.MaterialTrading}</option>
                        <option value={SalesType.Consulting}>{SalesType.Consulting}</option>
                      </select>
                    </div>
                  </div>
                  {formErrors?.projectType && <span style={{ color: "red" }}>{formErrors.projectType}</span>}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Party Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter party name"
                      value={addEnquiry.partyName}
                      name="partyName"
                      onChange={(e) => handleChange(e, "partyName")}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.partyName && (<label style={{ color: "red" }}>{formErrors.partyName}</label>)}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      value={addEnquiry.email}
                      name="email"
                      onChange={(e) => handleChange(e, "email")}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.email && (<label style={{ color: "red" }}>{formErrors.email}</label>)}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter location"
                      value={addEnquiry.location}
                      name="location"
                      onChange={(e) => handleChange(e, "location")}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.location && (<label style={{ color: "red" }}>{formErrors.location}</label>)}
                </div>
              </div>
              <div className="col-xxl-3 col-md-4 col-sm-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Google Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Google Location"
                      value={addEnquiry.googleLocation ? addEnquiry.googleLocation : ""}
                      name="googleLocation"
                      onChange={(e) => handleChange(e, "googleLocation")}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-md-4 col-sm-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Contact Person Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter contact person name"
                      value={addEnquiry.contactPersonName}
                      name="contactPersonName"
                      onChange={(e) => handleChange(e, 'contactPersonName')}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.contactPersonName && (<label style={{ color: "red" }}>{formErrors.contactPersonName}</label>)}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Contact Person No.</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter person number"
                      maxLength={10}
                      value={addEnquiry.contactPersonNumber}
                      name="contactPersonNumber"
                      onChange={(e) => handleChange(e, "contactPersonNumber")}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.contactPersonNumber && (<label style={{ color: "red" }}>{formErrors.contactPersonNumber}</label>)}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-3">
                <div className="users-form-info date-picker enquery-datepicker">
                  <div className="users-label">
                    <label>Enquiry Date</label>
                    <ReactDatePicker
                      id="EnquiryDatePicker"
                      dateFormat="dd/MM/yyyy"
                      selected={addEnquiry.enquiryDate}
                      placeholderText="Select enquiry date"
                      onChange={(e) => { setAddEnquiry({ ...addEnquiry, enquiryDate: e }); setFormErrors({ ...formErrors, enquiryDate: '' }) }}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.enquiryDate && (<label style={{ color: "red" }}>{formErrors.enquiryDate}</label>)}
                  <img
                    src={Datepickericon}
                    onClick={() => openCalender("EnquiryDatePicker")}
                    alt=""
                    className="datepickericon"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-3">
                <div className="users-form-info date-picker enquery-datepicker">
                  <div className="users-label ">
                    <label>Visit Date</label>
                    <ReactDatePicker
                      id="VisiteDatePicker"
                      dateFormat="dd/MM/yyyy"
                      selected={addEnquiry.visitDate}
                      minDate={addEnquiry.enquiryDate}
                      placeholderText="Select visit date"
                      onChange={(e) => { setAddEnquiry({ ...addEnquiry, visitDate: e }); setFormErrors({ ...formErrors, visitDate: '' }) }}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.visitDate && (<label style={{ color: "red" }}>{formErrors.visitDate}</label>)}
                  <img
                    src={Datepickericon}
                    onClick={() => openCalender("VisiteDatePicker")}
                    alt=""
                    className="datepickericon"
                  />
                </div>
              </div>
              {/* <div className="col-lg-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Quotation No</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter quotation number"
                      value={addEnquiry.quotationNumber}
                      name="quotationNumber"
                      onChange={(e) => handleChange(e, "quotationNumber")}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.quotationNumber && (<label style={{ color: "red" }}>{formErrors.quotationNumber}</label>)}
                </div>
              </div> */}
              <div className="col-lg-6 col-sm-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Assign Person</label>
                    <div
                      className="position-relative select-icon"
                    >
                      <img src={img} alt="" className={`${rotateArrow === 'AssignPerson' && "rotateArrow"}`} />
                      <select className="form-control assign-person-select" value={addEnquiry.assignPerson} onClick={() => click('AssignPerson')} onChange={(e) => { setAddEnquiry({ ...addEnquiry, assignPerson: e.target.value }) }}>
                        <option value="" disabled>Select User</option>
                        {userOption.length && userOption.map((ele) => {
                          return (
                            <option value={ele.value}>{ele.key}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  {formErrors?.assignPerson && (<label style={{ color: "red" }}>{formErrors.assignPerson}</label>)}
                </div>
              </div>


              <div className="col-lg-6 col-sm-6 mt-3">
                <div className="users-form-info">
                  <div className="">
                    <label style={{ fontWeight: "600" }}>Work Order</label>
                    <div className="upload-file mt-1 small-file-input w-100" onClick={() => UploadClick()}>
                      <label className='upload-file-lable' >Upload here</label>
                      <input
                        type="file"
                        placeholder="Enter work order"
                        className="form-control d-none"
                        id='workOrder'
                        onChange={(e) => setFile(e.target.files[0])}
                        name="workOrder"
                      />
                    </div>
                    {addEnquiry.workOrder ?
                      <label style={{ color: "green", display: "flex", marginTop: '10px' }}>
                        <img src={Viewicon} alt="View" width="20px" height="20px" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => workOrderFileClick(addEnquiry.workOrder)} />
                        <img src={removeImg} alt="Remove" width="20px" height="20px" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => fileRemove(addEnquiry.workOrder, '', 'workOrderRemove')} />
                        Uploaded File : {addEnquiry.workOrder.split('/')[1] ? addEnquiry.workOrder.split('/')[1] : addEnquiry.workOrder}
                      </label>
                      :
                      formErrors?.workOrder ? <label style={{ color: "red" }}>{formErrors.workOrder}</label> : ''}
                  </div>
                </div>
              </div>
            </div>
            <Tabs
              defaultActiveKey="quotation"
              id="uncontrolled-tab-example"
              className="mb-3 mt-5">
              <Tab eventKey="quotation" title="Quotation">
                {quotation?.length && editId ?
                  <>
                    <div className="row mt-3">
                      <div className="users-label users-form-info">
                        <Row>
                          {/* <Col sm={6}>
                            <h3 className='Milestores-title mb-0'>Quotation</h3>
                          </Col> */}
                          <Col sm={12} align="right">
                            {editId && <button className="adduser-btn" onClick={() => setIsFinal(true)}>Finalize Quotation</button>}
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <Row>
                      <Col sm={12}>
                        {
                          <div className="quotation-edit-section">
                            <table className="w-100">
                              <thead>
                                <tr>
                                  <th className="ps-3">
                                    Name
                                  </th>
                                  <th className="ps-3">
                                    Edit
                                  </th>
                                  <th className="ps-3">
                                    Delete
                                  </th>
                                  <th className="ps-3">
                                    Download
                                  </th>
                                  <th className="ps-3">
                                    Create Date
                                  </th>
                                  <th className="ps-3">
                                    Update Date
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {quotation?.map((ele, i) => {
                                  return (
                                    <tr>
                                      <td className="p-1">
                                        <div className="quotation-title ps-3">
                                          <h6 className="mb-0">{ele.isFinal && ele.isFinal === true ? <img src={greenDot} height={10} width={10} alt="Green Dot" /> : null} {ele.quotationName}</h6>
                                        </div>
                                      </td>
                                      <td className="p-1">
                                        <div className="icon-details ps-3">
                                          <img className="edit-icon" src={editIcon} onClick={() => { setQuotationId(ele.id); setIsOpen(true) }} />
                                        </div>
                                      </td>
                                      <td className="p-1">
                                        <div className="icon-details ps-3">
                                          <img className="edit-icon" onClick={() => setDelete_Quotation({ id: ele.id, open: true })} src={removeImg} />
                                        </div>
                                      </td>
                                      <td className="p-1">
                                        <div className="icon-details ps-3">
                                          <img src={uploadPDF} onClick={() => { onDownload(ele) }} style={{ cursor: "pointer", height: "15px" }} className='' title="View" alt='' />
                                        </div>
                                      </td>
                                      <td className="p-1">
                                        <div className="quotation-title ps-3">
                                          <h6 className="mb-0">{moment(ele.createdAt).format("DD-MM-YYYY")}</h6>
                                        </div>
                                      </td>
                                      <td className="p-1">
                                        <div className="quotation-title ps-3">
                                          <h6 className="mb-0">{moment(ele.updatedAt).format("DD-MM-YYYY")}</h6>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        }
                      </Col>
                    </Row>
                  </> : <span style={{ fontWeight: "bold", margin: '5px' }}>No Quotation Found.</span>
                }
              </Tab>
              <Tab eventKey="followUp" title="FollowUps">
                {/* <div className="row mt-4">
                  <div className="users-label users-form-info border-top pt-3">
                    <h3 className='Milestores-title mb-0'>FollowUps</h3>
                  </div>
                </div> */}
                <div className="over-flow-scroll border-bottom pb-4">
                  {
                    followUps && followUps.map((data, i) => (
                      <>
                        <Row>
                          {/* <Col xs={1} style={{ width: '50px' }} className="text-center">
                        <h5 className='mt-5'>quotation {i + 1}</h5>
                      </Col> */}
                          <Col sm={11} xs={10} style={{ width: '96%' }}>
                            <Row>
                              <Col xl={2} md={6} sm={6}>
                                <div className="mt-3 small-input">
                                  <div className="users-form-info ms-0 me-0 enquery-datepicker">
                                    <div className="users-label">
                                      <label>Date</label>
                                      <ReactDatePicker
                                        id="Date"
                                        dateFormat="dd/MM/yyyy"
                                        selected={data.date}
                                        placeholderText="Select date"
                                        onChange={(e) => handleChangeData(e, i, 'followUpsDate')}
                                        autoComplete="off"
                                      />
                                    </div>
                                    {formErrors && formErrors[`followUpsDate${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsDate${i + 1}`]}</label>}
                                  </div>
                                </div>
                              </Col>
                              <Col xl={3} md={6} sm={6}>
                                <div className="mt-3 small-input">
                                  <div className="users-form-info">
                                    <div className="users-label">
                                      <label>Status</label>
                                      <div className="users-label">
                                        <div
                                          className="position-relative select-icon"
                                        >
                                          <img src={img} className={`${rotateArrow === `status${i}` && "rotateArrow"}`} alt="" />
                                          <select className="form-control" value={followUps[i].status} onBlur={() => blur()} onClick={() => click(`status${i}`)} onChange={(e) => handleChangeData(e, i, 'followUpsStatus')} name="status">
                                            <option value="">Select Status</option>
                                            <option value="Pending">Pending For Admin Approval</option>
                                            <option value="In Progress">In Progress</option>
                                            <option value="On Hold">On Hold</option>
                                            <option value="Done">Done</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    {formErrors && formErrors[`followUpsStatus${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsStatus${i + 1}`]}</label>}
                                  </div>
                                </div>
                              </Col>
                              {/* <Col xxl={3} lg={3}>
                              <div className="mt-3 small-input">
                                <div className="users-form-info">
                                  <div className="">
                                    <label style={{ fontWeight: "600" }}>Quotation Update</label>
                                    <div className="upload-file mt-1" onClick={() => QuotationUploadClick(i)}>
                                      <label className='upload-file-lable' >Upload here</label>
                                      <input
                                        type="file"
                                        className="form-control d-none"
                                        id={`quotation${i}`}
                                        onChange={(e) => fileUpload(e.target.files[0], i, 'followUpsQuoationUpdate')}
                                        name="quoationUpdate"
                                      />
                                    </div>
                                    {followUps[i].quoationUpdate ?
                                      <label style={{ color: "green", display: "flex", marginTop: '10px' }}>
                                        <img src={Viewicon} alt="View" width="20" style={{ marginRight: "5px", cursor: "pointer", height: '20px' }} onClick={() => workOrderFileClick(followUps[i].quoationUpdate)} />
                                        <img src={removeImg} alt="Delete" width="20" height='20px' style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => fileRemove(followUps[i].quoationUpdate, i, 'remove')} />
                                        Uploaded File : {followUps[i].quoationUpdate.split('/')[1]}
                                      </label>
                                      :
                                      formErrors && formErrors[`followUpsQuoationUpdate${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsQuoationUpdate${i + 1}`]}</label>}
                                  </div>
                                </div>
                              </div>
                            </Col> */}
                              <Col xl={3} md={5} sm={6}>
                                <div className="mt-3 small-input enquiry-textarea">
                                  <div className="users-form-info">
                                    <div className="users-label">
                                      <label>Description</label>
                                      <textarea
                                        type="text"
                                        className="form-control mt-1"
                                        placeholder="Description"
                                        onChange={(e) => handleChangeData(e, i, 'followUpsDescription')}
                                        rows={1}
                                        name="description"
                                        value={data.description}
                                      />
                                    </div>
                                    {formErrors && formErrors[`followUpsDescription${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsDescription${i + 1}`]}</label>}
                                  </div>
                                </div>
                              </Col>
                              <Col xl={3} md={5} sm={6}>
                                <div className="mt-3 small-input enquiry-textarea">
                                  <div className="users-form-info">
                                    <div className="users-label">
                                      <label>Remarks</label>
                                      <textarea
                                        type="text"
                                        className="form-control mt-1"
                                        placeholder="Remarks"
                                        onChange={(e) => handleChangeData(e, i, 'followUpsRemark')}
                                        rows={1}
                                        name="remark"
                                        value={data.remark}
                                      />
                                    </div>
                                    {formErrors && formErrors[`followUpsRemark${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsRemark${i + 1}`]}</label>}
                                  </div>
                                </div>
                              </Col>
                              <Col xl={1} md={2} sm={6}>
                                <div className="sm-picker ms-xl-0 ms-3 mt-3 mt-md-5">
                                  <div className="users-form-info date-picker ">
                                    <div className="d-flex">
                                      <label></label>
                                      {i === followUps.length - 1 && (
                                        <img
                                          src={Plusicon}
                                          onClick={() => { addData() }}
                                          width="35"
                                          className="me-2"
                                          style={{ cursor: "pointer" }}
                                          alt=""
                                        />
                                      )}
                                      {followUps.length > 1 && <img
                                        src={Minusicon}
                                        onClick={() => Remove(i)}
                                        width="35"
                                        style={{ cursor: "pointer" }}
                                        alt=""
                                      />}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* <div className=" row mb-5">
                    <div className="col-6">
                      <div className="users-form-info d-flex align-items-center">
                        <div className='col-1'>
                          <h5 className='mt-4 pt-3 ms-3'>{i + 1}</h5>
                        </div>
                        <div className="col-lg-4 mt-3 small-input">
                          <div className="users-form-info ms-0 me-0">
                            <div className="users-label">
                              <label>Date</label>
                              <ReactDatePicker
                                id="Date"
                                selected={data.date}
                                placeholderText="Select date"
                                onChange={(e) => handleChangeData(e, i, 'followUpsDate')}
                                autoComplete="off"
                              />
                            </div>
                            {formErrors && formErrors[`followUpsDate${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsDate${i + 1}`]}</label>}
                          </div>
                        </div>
                        <div className="col-lg-5 mt-3 small-input">
                          <div className="users-form-info ms-3 me-5">
                            <div className="users-label">
                              <label>Status</label>
                              <div className="users-label">
                                <div
                                  className="position-relative select-icon"
                                >
                                  <img src={img} className={`${rotateArrow === `status${i}` && "rotateArrow"}`} alt="" />
                                  <select className="form-control" value={followUps[i].status} onBlur={() => blur()} onClick={() => click(`status${i}`)} onChange={(e) => handleChangeData(e, i, 'followUpsStatus')} name="status">
                                    <option value="">Select Status</option>
                                    <option value={AllStatus.Pending}>{AllStatus.Pending}</option>
                                    <option value={AllStatus.InProgress}>{AllStatus.InProgress}</option>
                                    <option value={AllStatus.OnHold}>{AllStatus.OnHold}</option>
                                    <option value={AllStatus.Done}>{AllStatus.Done}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            {formErrors && formErrors[`followUpsStatus${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsStatus${i + 1}`]}</label>}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3 small-input">
                        <div className="users-form-info">
                          <div className="ms-5 w-470">
                            <label style={{ fontWeight: "600" }}>Quotation Update</label>
                            <div className="upload-file mt-1" onClick={() => QuotationUploadClick(i)}>
                              <label className='upload-file-lable' >Upload here</label>
                              <input
                                type="file"
                                className="form-control d-none"
                                id={`quotation${i}`}
                                onChange={(e) => fileUpload(e.target.files[0], i, 'followUpsQuoationUpdate')}
                                name="quoationUpdate"
                              />
                            </div>
                            {followUps[i].quoationUpdate ?
                              <label style={{ color: "green", display: "flex" }}>
                                <img src={Viewicon} alt="View" width="25" style={{ marginRight: "5px", cursor: "pointer", height: '25px' }} onClick={() => workOrderFileClick(followUps[i].quoationUpdate)} />
                                Uploaded File : {followUps[i].quoationUpdate}
                              </label>
                              :
                              formErrors && formErrors[`followUpsQuoationUpdate${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsQuoationUpdate${i + 1}`]}</label>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <div className="mt-3 small-input">
                            <div className="users-form-info ">
                              <div className="users-label">
                                <label>Description</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  placeholder="Description"
                                  onChange={(e) => handleChangeData(e, i, 'followUpsDescription')}
                                  name="description"
                                  value={data.description}
                                />
                              </div>
                              {formErrors && formErrors[`followUpsDescription${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsDescription${i + 1}`]}</label>}
                            </div>
                          </div>
                          <div className="mt-3 small-input">
                            <div className="users-form-info ">
                              <div className="users-label">
                                <label>Remarks</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  placeholder="Remarks"
                                  onChange={(e) => handleChangeData(e, i, 'followUpsRemark')}
                                  name="remark"
                                  value={data.remark}
                                />
                              </div>
                              {formErrors && formErrors[`followUpsRemark${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`followUpsRemark${i + 1}`]}</label>}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mt-4 sm-picker">
                          <div className="users-form-info date-picker">
                            <div className="" style={{ marginTop: "1.7rem" }}>
                              <label></label>
                              {i === followUps.length - 1 && (
                                <img
                                  src={Plusicon}
                                  onClick={() => { addData() }}
                                  width="30"
                                  style={{ cursor: "pointer" }}
                                  alt=""
                                />
                              )}
                              {followUps.length > 1 && <img
                                src={Minusicon}
                                onClick={() => Remove(i)}
                                width="35"
                                style={{ cursor: "pointer" }}
                                alt=""
                              />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                      </>
                    ))
                  }
                </div>
              </Tab>
            </Tabs>
            {/* <div className=" row">
              <div className="col-lg-6 mt-3">
                <div className="users-form-info ">
                  <div className="users-label">
                    <label>Reference By</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Reference"
                      value={addEnquiry.referenceBy}
                      name="referenceBy"
                      onChange={(e) => handleChange(e, 'referenceBy')}
                      autoComplete="off"
                    />
                  </div>
                  {formErrors?.referenceBy !== undefined && (
                    <label style={{ color: "red" }}>
                      {formErrors.referenceBy}
                    </label>
                  )}
                </div>
              </div>
              <div className="col-lg-6 mt-3">
                <div className="users-form-info ">
                  <div className="">
                    <label style={{ fontWeight: "600" }}>Work Order</label>
                    <div className="upload-file mt-1" onClick={UploadClick}>
                      <label className='upload-file-lable' >Upload here</label>
                      <input
                        type="file"
                        placeholder="Enter email"
                        className="form-control d-none"
                        id='email'
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        name="email"
                        ref={ElementClick}
                      />
                    </div>
                    {addEnquiry.workOrder ?
                      <label style={{ color: "green", display: "flex" }}>
                        <img src={Viewicon} alt="View" width="25" style={{ marginRight: "5px", cursor: "pointer" }} onClick={() => workOrderFileClick(addEnquiry.workOrder)} />
                        Uploaded File : {addEnquiry.workOrder}
                      </label>
                      :
                      formErrors?.workOrder ? <label style={{ color: "red" }}>{formErrors.workOrder}</label> : ''}
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="noc-form-btn px-5 pb-3 mt-5">
              {editId ?
                <button className="adduser-btn ms-3" onClick={() => Edit()}>Edit</button> : <button className="adduser-btn ms-3" onClick={() => Submit()}>
                  Save
                </button>}
              <button
                className="cancel-button ms-3"
                onClick={() => navigate("/enquiry")}
              >
                Cancel
              </button>
            </div> */}

          </div>
        </div>
      </div>

      {isOpen && <GenerateQuatation show={isOpen} onHide={() => setIsOpen(false)} enquiryId={enquiryId} quotationId={quotationId} setQuotationId={setQuotationId} IsShowEditButton={(quotation.length && quotation[quotation.length - 1]?.id === quotationId) ? true : false} />}

      {(delete_quotation.id && delete_quotation.open) &&
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => RemoveQuotation(delete_quotation.id)}
          onCancel={() => setDelete_Quotation({ id: "", open: false })}
          focusCancelBtn
        >
          You want to Delete it
        </SweetAlert>
      }
      {isFinal && <Finalize show={isFinal} enquiryId={enquiryId} onHide={() => setIsFinal(false)} />}
    </>
  );
};

export default AddEnquiry;
