import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import removeImg from '../../assets/image/delete-red.png'
import downloadImg from '../../assets/image/download.png'
import { useNavigate } from 'react-router-dom';
import { deleteSaleAction, getAllSaleAction } from '../../redux/actions/salesAction';
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import editIcon from '../../assets/image/edit.svg';
import ViewSale from '../../components/view-modals/ViewSale';
import { dateFormate, downloadFile } from '../../helper/utils';
import PaymentAmmountConfirmation from '../../components/view-modals/PaymentAmmountConfirmation';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import usePaginationHooks from '../../Hooks/paginationHooks';
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';
import img from '../../assets/image/select.png';

const Sales = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const toastSuccess = () => toast.success('Product delete successfully');
    const { getAllSales, ExportAllSales } = usePaginationHooks()
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Store]

    const [saleData, setSaleData] = useState([])
    const [limit, setlimit] = useState(Paginationlimit);
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [columnDefs, setColumnDefs] = useState([]);
    const [viewTog, setViewTog] = useState(false);
    const [paymentAmmountConfirmation, setPaymentAmmountConfirmation] = useState(false);
    const [viewId, setViewId] = useState('');
    const [paramvalues, setparamvalues] = useState()
    const [gridApi, setGridApi] = useState(null);
    const [rotateArrow, setrotateArrow] = useState(false);
    const [isFilter, setisFilter] = useState(false)
    const [filter, setFilter] = useState({
        From: '',
        To: '',
        Search: '',
        SearchType: 'projectName',
    });
    const [sorting, setSorting] = useState({ column_name: '', column_order: '' });
    const deletesale = useSelector((state) => state.delete_Sale.deleteSale)
    const editSale = useSelector((state) => state.edit_Sale.editSale)
    const addSale = useSelector((state) => state.add_Sale.addSale)
    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

    const columnDefsNews = [
        {
            field: 'invoiceDate',
            maxWidth: 110,
            cellRendererFramework: (params) => <div>
                <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
            </div>
        },
        { field: 'customerName', flex: 1, },
        { field: 'projectName', flex: 1, },
        { field: 'invoiceNo', minWidth: 60, maxWidth: 180, },
        { field: 'productAmount', maxWidth: 120, },
        { field: 'totalAmount', maxWidth: 120, },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 160,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            minWidth: 140,
            maxWidth: 150,
            field: "_id",
            sortable: false,
            cellRendererFramework: (params) => <div>
                {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <>
                    {/* <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" /> */}
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Delete" /></>}
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="View" />
                <img src={downloadImg} onClick={() => { setPaymentAmmountConfirmation(true); setparamvalues(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Download" />
            </div>
        }
    ]

    const NoRolecolumnDefsNew = [
        {
            field: 'invoiceDate',
            maxWidth: 110,
            cellRendererFramework: (params) => <div>
                <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
            </div>
        },
        { field: 'customerName', flex: 1, },
        { field: 'projectName', flex: 1, },
        { field: 'invoiceNo', minWidth: 60, maxWidth: 180, },
        { field: 'productAmount', maxWidth: 120, },
        { field: 'totalAmount', maxWidth: 120, },
        {
            field: "createdBy",
            minWidth: 60,
            maxWidth: 240,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 140,
            maxWidth: 180,
            sortable: false,
            cellRendererFramework: (params) => <div>
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="View" />
            </div>
        }
    ]

    const onEdit = (id) => {
        navigate(`edit?saleId=${id}`)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    const OnFilter = () => {
        setisFilter(true)
    }

    useEffect(() => {
        if (filter.From === '' && filter.To === '' && filter.Search === '' && filter.SearchType === 'projectName') {
            setisFilter(true)
        }
    }, [filter])

    const OnReset = () => {
        setFilter({
            From: '',
            To: '',
            Search: '',
            SearchType: 'projectName',
        })
    }

    const onDelete = (id) => {
        dispatch(deleteSaleAction(id))
        setReject(false)
        toastSuccess(true)
    }

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    const exportToCSV = async () => {
        getAllSales("", "", filter, sorting.column_name, sorting.column_order).then(res => {
            if (res.data) {
                const newData = res?.data?.map((item) => {
                    return {
                        ...item,
                        purchaseItems: JSON.stringify(item.purchaseItems)
                    }
                })
                const ws = XLSX.utils.json_to_sheet(newData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                FileSaver.saveAs(data, 'all_purchases' + '.xlsx');
            }
        })
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (gridApi || deletesale.data || deletesale.data?.status === 200 || editSale.data || editSale.data?.status === 200 || addSale.data || addSale.data?.status === 200) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    setSorting({ column_name: sorted_column, column_order: sorted_order });

                    getAllSales(page, limit, filter, sorted_column, sorted_order)
                        .then(res => {
                            setSaleData(res)
                            params.successCallback(res.data.map((ele) => {
                                return {
                                    invoiceDate: ele.invoiceDate ? ele.invoiceDate : '',
                                    customerName: ele.customerName ? ele.customerName : '',
                                    projectName: ele.projectName ? ele.projectName : 'Trading',
                                    invoiceNo: ele.invoiceNo ? `${ele.financelayer ?? '-'}/${ele.invoiceNo}` : '',
                                    productAmount: ele.productAmount ? ele.productAmount : '',
                                    totalAmount: ele.totalAmount ? ele.totalAmount : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

        setisFilter(false)
    }, [gridApi, isFilter, limit, deletesale, editSale]);

    const onDownload = async (id, showAmmount) => {
        try {
            const data = await saleData.data.find(ele => ele._id === id)
            downloadFile(data, 'Sale Challan', showAmmount)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (saleData) {
            if (RolesForAdd.includes(userRole)) {
                setColumnDefs(columnDefsNews)
            } else {
                setColumnDefs(NoRolecolumnDefsNew)
            }
        }
    }, [saleData])

    return (
        <>
            <div className='sale-datetpicker position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>Sales</h2>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', flexWrap: 'wrap' }}>
                        <div className='date-picker users-label'>
                            <lable>From</lable>
                            <div>
                                <ReactDatePicker
                                    id="EnquiryDatePicker"
                                    dateFormat="yyyy-MM-dd"
                                    selected={filter.From ? new Date(filter.From) : null}
                                    placeholderText="Select invoice date"
                                    onChange={(e) => { setFilter({ ...filter, From: moment(e).format('YYYY-MM-DD') }) }}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className='date-picker users-label'>
                            <lable>To</lable>
                            <div>
                                <ReactDatePicker
                                    id="EnquiryDatePicker"
                                    dateFormat="yyyy-MM-dd"
                                    selected={filter.To ? new Date(filter.To) : null}
                                    placeholderText="Select invoice date"
                                    onChange={(e) => { setFilter({ ...filter, To: moment(e).format('YYYY-MM-DD') }) }}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div>
                            <input
                                type="search"
                                className="form-control"
                                placeholder="Search"
                                value={filter.Search}
                                onChange={(e) => { setFilter({ ...filter, Search: e.target.value }) }}
                                autoComplete="off"
                            />
                        </div>
                        <div className="date-picker position-relative header-select users-label" style={{ width: "max-content" }}>
                            <img src={img} className={`${rotateArrow && "rotateArrow"}`} width="18px" alt="" />
                            <select className="form-control mt-0" style={{ width: "max-content", paddingRight: filter.SearchType ? 40 : 0 }} onChange={(e) => { setFilter({ ...filter, SearchType: e.target.value }) }} value={filter.SearchType} onBlur={() => { setrotateArrow(false) }} onClick={() => { setrotateArrow(!rotateArrow) }}>
                                <option value='projectName'>Project Name</option>
                                <option value='customerName'>Custmer Name</option>
                            </select>
                        </div>
                        <button className='adduser-btn' onClick={() => { OnFilter() }}>Apply Filter</button>
                        <button className='adduser-btn' onClick={() => { OnReset() }}>Clear</button>
                        <button className='adduser-btn' onClick={exportToCSV}>Export All</button>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => { navigate('add') }}>Add Sale</button>}
                    </div>
                </div>
                {
                    <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                        {/* <AgGridReact
                            ref={gridRef}
                            rowData={sale}
                            columnDefs={columnDefs}
                            pagination={false}
                            defaultColDef={defaultColDef}
                            paginationPageSize={limit}
                            style={{ width: "100%" }}
                            onFirstDataRendered={onFirstDataRendered}
                            onGridSizeChanged={onGridSizeChanged}
                        /> */}

                        <AgGridReact
                            pagination={true}
                            columnDefs={columnDefs}
                            rowModelType={"infinite"}
                            paginationPageSize={limit}
                            cacheBlockSize={limit}
                            onGridReady={onGridReady}
                            rowSelection={'single'}
                            onCellClicked={(event) => {
                                const rowData = event.data;
                                if (rowData?.createdBy?._id === userData.id || userRole === UserRoles.Admin) {
                                    handleRowClick(event)
                                }
                            }}
                            rowHeight={45}
                            defaultColDef={defaultColDef}
                            overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                        />

                        <div className='set-pagination-dropdaun'>
                            <p>Show</p>
                            <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                                <option value="50">
                                    50
                                </option>
                                <option value="100" selected={true}>100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                            <p>Entries</p>
                        </div>
                    </div>
                }
            </div>
            {viewTog && <ViewSale show={viewTog} onHide={() => { setViewTog(false); setViewId('') }} viewId={viewId} />}
            {paymentAmmountConfirmation && <PaymentAmmountConfirmation show={paymentAmmountConfirmation} onHide={() => { setPaymentAmmountConfirmation(false); setPaymentAmmountConfirmation('') }} ondownloadprint={(e) => onDownload(paramvalues, e)} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}

export default Sales   