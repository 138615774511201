import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { dateFormate, dateFunction } from '../../helper/utils'
import { getBillEntryByIdAction } from '../../redux/actions/addBillAction'
import { getAllCustomerAction } from '../../redux/actions/customerAction'
import { getNocByIdAction } from '../../redux/actions/nocAction'

const ViewNoc = ({ show, onHide, nocId }) => {

    const dispatch = useDispatch()

    const [noc, setNoc] = useState({
        customer: '',
        project: '',
        location: '',
        workType: '',
        cityOrOutcity: '',
        provisionalOrFinal: '',
        contactPersonNumber: '',
        refBy: '',
        status: '',
        certificateImage: '',
        officersRemarks: []
    })

    const getNocByIdData = useSelector((state) => state.get_Noc_By_Id.getNocById)
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer.data)

    useEffect(() => {
        dispatch(getAllCustomerAction())
    }, [])

    useEffect(() => {
        if (nocId) {
            dispatch(getNocByIdAction(nocId))
        }
    }, [nocId])

    useEffect(() => {
        if (getNocByIdData && getNocByIdData.data && customerData && nocId) {
            setNoc({
                customer: selectValue(getNocByIdData.data.customer, 'customer'),
                project: getNocByIdData.data.partyName,
                location: getNocByIdData.data.location,
                workType: getNocByIdData.data.workType,
                cityOrOutcity: getNocByIdData.data.cityOrOutcity,
                provisionalOrFinal: getNocByIdData.data.provisionalOrFinal,
                contactPersonNumber: getNocByIdData.data.contactPersonNumber,
                refBy: getNocByIdData.data.refBy,
                status: getNocByIdData.data.status,
                certificateImage: getNocByIdData.data.certificateImage,
                officersRemarks: getNocByIdData.data.officersRemarks.map((item) => {
                    return {
                        date: item?.date,
                        remark: item?.remark
                    }
                })
            })
        }
    }, [getNocByIdData])

    const selectValue = (value, name) => {
        if (name === "customer") {
            return customerData.find((ele) => ele._id === value)?.accountName
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>NOC</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Customer</td>
                                    <td>{noc.customer}</td>
                                </tr>
                                <tr>
                                    <td>Project</td>
                                    <td>{noc?.project}</td>

                                </tr>
                                <tr>
                                    <td>Location</td>
                                    <td>{noc.location}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>{noc?.cityOrOutcity}</td>

                                </tr>
                                <tr>
                                    <td>Ref By</td>
                                    <td>{noc?.refBy}</td>

                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Work Type</td>
                                    <td>{noc.workType}</td>
                                </tr>
                                <tr>
                                    <td>Provisional Or Final</td>
                                    <td>{noc?.provisionalOrFinal}</td>
                                </tr>
                                <tr>
                                    <td>Contact Person Number</td>
                                    <td>{noc.contactPersonNumber}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>{noc?.status}</td>
                                </tr>
                                <tr>
                                    <td>Certificate Image</td>
                                    <td>{noc.certificateImage ? noc.certificateImage.split('/')[1] : ''}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="">
                        <h5 className="Steps-header">Remarks</h5>
                    </Col>
                </Row>
                        <div className="mx-0">
                            <table className='table viewer-noc-remarks-modal'>
                                <tr>
                                    <th>Date</th>
                                    <th>Remark</th>
                                </tr>
                                {noc?.officersRemarks?.length > 0 && noc.officersRemarks.map((ele,ind) =>
                                <tr key={ind}>
                                    <td className='vertical-top' >{ele?.date ? dateFormate(ele.date, 'Date') : ''}</td>
                                    <td className='vertical-top' >{ele?.remark}</td>
                                </tr>
                                )}
                            </table>
                        </div>
                        {/* <Row>
                            <Col lg={6}>
                                <div className="mx-0 mt-1">
                                    <div className="users-label bill-type d-flex">
                                        <label className='d-block me-3'>Date :</label><span>{ele?.date ? dateFormate(ele.date, 'Date') : ''}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mx-0 mt-1">
                                    <div className="users-label bill-type d-flex">
                                        <label className='d-block me-3'>Remark :</label><span>{ele?.remark}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewNoc