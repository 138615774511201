import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import uploadPDF from '../../assets/image/uploadPDF.png'
import removeImg from '../../assets/image/delete-red.png'
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import { deletePurchaseRequestAction, getAllPurchaseRequestAction } from '../../redux/actions/purchaseRequest';
import AddPurchaseRequest from '../../components/modals/AddPurchaseRequest';
import editIcon from '../../assets/image/edit.svg';
import { ADD_PURCHASE_REQUEST, DELETE_PURCHASE_REQUEST, GET_PRODUCT_BY_FINAL_MATERIAL, GET_PURCHASE_REQUEST_BY_ID } from '../../redux/type';
import { useLocation, useNavigate } from 'react-router-dom';
import { dateFormate, downloadPRFile } from '../../helper/utils';
import ViewPurchaseRequest from '../../components/view-modals/ViewPurchaseRequest';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";
import img from '../../assets/image/select.png';
import { Col, Row } from 'react-bootstrap';

const PurchaseRequest = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [status, setstatus] = useState('')
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Project, UserRoles.Maintance]
    const queryParams = new URLSearchParams(location.search)
    const PRid = queryParams.get("prid")
    const ProjectId = queryParams.get("ProjectId")
    const [purchaseRequest, setPurchaseRequest] = useState([]);
    const [limit, setlimit] = useState(Paginationlimit);
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [addPRTog, setAddPRTog] = useState(false);
    const [addPRId, setAddPRId] = useState('')
    const [columnDefs, setColumnDefs] = useState([]);
    const [viewPRTog, setViewPRTog] = useState(false);
    const [PRId, setPRId] = useState('');
    const [gridApi, setGridApi] = useState(null);
    const [getAllPRdata, setgetAllPRdata] = useState([])
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    const [rotateArrow, setrotateArrow] = useState(false);

    const { getAllPurchaseRequests } = usePaginationHooks()

    const toastSuccess = () => toast.success('Purchase Request delete successfully');

    // const getAllPRdata = useSelector((state) => state.get_All_Purchase_Request.getAllPurchaseRequest)
    const deletePurchaseReqData = useSelector((state) => state.delete_Purchase_Request.deletePurchaseRequest)
    const addPurchaseRequestData = useSelector((state) => state.add_Purchase_Request.addPurchaseRequest)
    const editPurchaseRequest = useSelector((state) => state.edit_Purchase_Request.editPurchaseRequest)
    const getAllProject = useSelector((state) => state.get_All_Project.getAllProject.data)

    useEffect(() => {
        let body = ''
        let role = '';
        if (userRole === UserRoles.Maintance) {
            role = 'Maintenance'
        } else if (userRole === UserRoles.Project) {
            role = 'Project'
        }
        dispatch(getAllProjectAction(body, role))
    }, [])

    useEffect(() => {
        if (PRid) {
            setAddPRId(PRid)
            setAddPRTog(true)
        }
    }, [PRid])

    const columnDefsNews = [
        {
            headerName: 'Project Name',
            field: 'project.partyName',
            minWidth: 150,
        },
        // { headerName: 'PR Number', field: 'purchaseRequestNumber', maxWidth: 130 },
        { headerName: 'Y-M-Count', field: 'PRcount', maxWidth: 110 },
        { field: 'status', maxWidth: 100, },
        {
            field: "createdBy",
            maxWidth: 130,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            field: 'createdAt',
            maxWidth: 120,
            cellRendererFramework: (params) => <div>
                <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            maxWidth: 180,
            sortable: false,
            cellRendererFramework: (params) => <div>
                {/* {(params?.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <><img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} title="Edit" />
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" /></>} */}
                {userRole === UserRoles.Admin && <>
                    {/* <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} title="Edit" alt='' /> */}
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' /></>}
                <img src={viewImg} onClick={() => { setViewPRTog(true); setPRId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
                <img src={uploadPDF} onClick={() => { onDownload(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
            </div>
        }
    ]

    const NoRolecolumnDefsNew = [
        {
            headerName: 'Project Name',
            field: 'project.partyName',
            minWidth: 150,
        },
        // { headerName: 'PR Number', field: 'purchaseRequestNumber', maxWidth: 130, },
        { headerName: 'Y-M-Count', field: 'PRcount', maxWidth: 110 },
        { field: 'status', maxWidth: 100 },
        {
            field: "createdBy",
            maxWidth: 130,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            field: 'createdAt',
            maxWidth: 120,
            cellRendererFramework: (params) => <div>
                <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            maxWidth: 180,
            sortable: false,
            cellRendererFramework: (params) => <div>
                <img src={viewImg} onClick={() => { setViewPRTog(true); setPRId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
                <img src={uploadPDF} onClick={() => { onDownload(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
            </div>
        }
    ]

    const onDownload = async (id) => {
        try {
            const data = getAllPRdata.find(ele => ele._id === id)
            downloadPRFile(data, 'Purchase Request')
        } catch (err) {
            console.log(err)
        }
    }

    const onEdit = (id) => {
        setAddPRTog(true)
        setAddPRId(id)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    const onDelete = (id) => {
        dispatch(deletePurchaseRequestAction(id))
        setReject(false)
    }

    useEffect(() => {
        if (deletePurchaseReqData && deletePurchaseReqData.data) {
            if (deletePurchaseReqData.data.message === "Purchase Request deleted successfully.") {
                dispatch({
                    type: DELETE_PURCHASE_REQUEST,
                    payload: []
                })
                toastSuccess(true)
            }
        }
    }, [deletePurchaseReqData])

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        if (getAllPRdata) {
            if (RolesForAdd.includes(userRole)) {
                setColumnDefs(columnDefsNews)
            } else {
                setColumnDefs(NoRolecolumnDefsNew)
            }
        }
    }, [getAllPRdata])

    const hide = () => {
        setAddPRTog(false);
        setAddPRId('')
        dispatch({
            type: GET_PURCHASE_REQUEST_BY_ID,
            payload: []
        })
        navigate('/purchaserequest')
    }

    const onHide = () => {
        setViewPRTog(false)
        setPRId('')
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
        setstatus('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || deletePurchaseReqData.data?.status === 200 || editPurchaseRequest.data?.status === 200 || addPurchaseRequestData?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllPurchaseRequests(page, limit, filter.search, status, sorted_column, sorted_order, false)
                        .then(res => {
                            setgetAllPRdata(res.data)
                            let data = res.data
                            if (userRole !== UserRoles.Admin) {
                                data = data.filter((ele) => getAllProject.find((project) => project?._id === ele?.project._id) !== undefined)
                            }
                            params.successCallback(data.map((ele) => {
                                return {
                                    project: ele.project ? ele.project : '',
                                    purchaseRequestNumber: ele?.purchaseRequestNumber ? ele.purchaseRequestNumber : '',
                                    status: ele.status ? ele.status : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    createdAt: ele.createdAt ? ele.createdAt : '',
                                    PRcount: ele.PRcount ? ele.PRcount : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

        // setisFilter(false)
    }, [gridApi, limit, userRole, deletePurchaseReqData, editPurchaseRequest, addPurchaseRequestData, filter.search, status]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2>Purchase Request</h2>
                    </div>
                    <div className='d-flex'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control search-button"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <div className="position-relative header-select me-3 users-label">
                            <img src={img} className={`${rotateArrow && "rotateArrow"}`} width="18px" alt="" />
                            <select className="form-control mt-0" style={{ maxWidth: "200px", paddingRight: status ? 40 : 0 }} value={status} onBlur={() => { setrotateArrow(false) }} onClick={() => { setrotateArrow(!rotateArrow) }} onChange={(e) => setstatus(e.target.value)}>
                                <option value="">Select type</option>
                                <option value='Waiting For Approval'>Waiting For Approval</option>
                                <option value='Rejected'>Rejected</option>
                                <option value='Delivered'>Delivered</option>
                                <option value='Pending'>Pending</option>
                            </select>
                        </div>
                        <button className='adduser-btn' onClick={() => clearFilter()}>Clear Filter</button>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => { setAddPRTog(true); dispatch({ type: GET_PRODUCT_BY_FINAL_MATERIAL, payload: [] }) }}>Add Purchase Request</button>}
                    </div>
                </div>

                {purchaseRequest &&
                    <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                        <AgGridReact
                            pagination={true}
                            columnDefs={columnDefs}
                            rowModelType={"infinite"}
                            paginationPageSize={limit}
                            cacheBlockSize={limit}
                            onGridReady={onGridReady}
                            rowSelection={'single'}
                            onCellClicked={(event) => {
                                if (userRole === UserRoles.Admin) {
                                    handleRowClick(event)
                                }
                            }}
                            rowHeight={45}
                            defaultColDef={defaultColDef}
                            overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                        />
                        <div className='set-pagination-dropdaun'>
                            <p>Show</p>
                            <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                                <option value="50">
                                    50
                                </option>
                                <option value="100" selected={true}>100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                            </select>
                            <p>Entries</p>
                        </div>
                    </div>
                }
            </div>
            {addPRTog && <AddPurchaseRequest show={addPRTog} onHide={() => hide()} addPRId={addPRId} PR='project' />}
            {viewPRTog && <ViewPurchaseRequest show={viewPRTog} onHide={() => onHide()} PRId={PRId} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}

export default PurchaseRequest