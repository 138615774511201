import { ADD_QUOTATION, ADD_QUOTATION_ERROR, ADD_QUOTATION_LOADING, DELETE_QUOTATION, DELETE_QUOTATION_ERROR, DELETE_QUOTATION_LOADING, EDIT_QUOTATION, EDIT_QUOTATION_ERROR, EDIT_QUOTATION_LOADING, GET_QUOTATION_BY_ENQUIRYID, GET_QUOTATION_BY_ENQUIRYID_ERROR, GET_QUOTATION_BY_ENQUIRYID_LOADING, GET_QUOTATION_BY_ID, GET_QUOTATION_BY_ID_ERROR, GET_QUOTATION_BY_ID_LOADING } from "../type";



const getQuotationByEnquiryIdData = {
    loading: false,
    getQuotationByEnquiryId: [],
    error: '',
}

const addQuotationData = {
    loading: false,
    addQuotation: [],
    error: '',
}

const editQuotationData = {
    loading: false,
    editQuotation: [],
    error: '',
}
const deleteQuotationData = {
    loading: false,
    deleteQuotation: [],
    error: '',
}

const getQuotationByIdData = {
    loading: false,
    getQuotationById: [],
    error: '',
}

export const addQuotationReducer = (state = addQuotationData, action) => {
    switch (action.type) {
        case ADD_QUOTATION_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_QUOTATION:
            return {
                ...state,
                addQuotation: action.payload,
            };
        case ADD_QUOTATION_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editQuotationReducer = (state = editQuotationData, action) => {
    switch (action.type) {
        case EDIT_QUOTATION_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_QUOTATION:
            return {
                ...state,
                editQuotation: action.payload,
            };
        case EDIT_QUOTATION_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
export const deleteQuotationReducer = (state = deleteQuotationData, action) => {
    switch (action.type) {
        case DELETE_QUOTATION_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_QUOTATION:
            return {
                ...state,
                deleteQuotation: action.payload,
            };
        case DELETE_QUOTATION_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getQuotationByIdReducer = (state = getQuotationByIdData, action) => {
    switch (action.type) {
        case GET_QUOTATION_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_QUOTATION_BY_ID:
            return {
                ...state,
                getQuotationById: action.payload,
            };
        case GET_QUOTATION_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getQuotationByEnquiryIdReducer = (state = getQuotationByEnquiryIdData, action) => {
    switch (action.type) {
        case GET_QUOTATION_BY_ENQUIRYID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_QUOTATION_BY_ENQUIRYID:
            return {
                ...state,
                getQuotationByEnquiryId: action.payload,
            };
        case GET_QUOTATION_BY_ENQUIRYID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}