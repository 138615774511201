import { ADD_PURCHASE_REQUEST, ADD_PURCHASE_REQUEST_ERROR, ADD_PURCHASE_REQUEST_LOADING, DELETE_PURCHASE_REQUEST, DELETE_PURCHASE_REQUEST_ERROR, DELETE_PURCHASE_REQUEST_LOADING, EDIT_PURCHASE_REQUEST, EDIT_PURCHASE_REQUEST_ERROR, EDIT_PURCHASE_REQUEST_LOADING, GET_ALL_PURCHASE_REQUEST, GET_ALL_PURCHASE_REQUEST_ERROR, GET_ALL_PURCHASE_REQUEST_LOADING, GET_PURCHASE_REQUEST_BY_ID, GET_PURCHASE_REQUEST_BY_ID_ERROR, GET_PURCHASE_REQUEST_BY_ID_LOADING, GET_PURCHASE_REQUEST_BY_PROJECT, GET_PURCHASE_REQUEST_BY_PROJECT_ERROR, GET_PURCHASE_REQUEST_BY_PROJECT_LOADING } from "../type";

const addPurchaseRequestData = {
    loading:false,
    addPurchaseRequest:[],
    error:'',
}

const getPurchaseRequestByIdData = {
    loading:false,
    getPurchaseRequestById:[],
    error:'',
}

const getPurchaseRequestByProjectData = {
    loading:false,
    getPurchaseRequestByProject:[],
    error:'',
}

const getAllPurchaseRequestData = {
    loading:false,
    getAllPurchaseRequest:[],
    error:'',
}

const editPurchaseRequestData = {
    loading:false,
    editPurchaseRequest:[],
    error:'',
}

const deletePurchaseRequestData = {
    loading:false,
    deletePurchaseRequest:[],
    error:'',
}

export const addPurchaseRequestReducer = (state = addPurchaseRequestData, action) => {
    switch (action.type) {
        case ADD_PURCHASE_REQUEST_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_PURCHASE_REQUEST:
            return {
                ...state,
                addPurchaseRequest: action.payload,
            };
        case ADD_PURCHASE_REQUEST_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getPurchaseRequestByIdReducer = (state = getPurchaseRequestByIdData, action) => {
    switch (action.type) {
        case GET_PURCHASE_REQUEST_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PURCHASE_REQUEST_BY_ID:
            return {
                ...state,
                getPurchaseRequestById: action.payload,
            };
        case GET_PURCHASE_REQUEST_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getPurchaseRequestByProjectReducer = (state = getPurchaseRequestByProjectData, action) => {
    switch (action.type) {
        case GET_PURCHASE_REQUEST_BY_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_PURCHASE_REQUEST_BY_PROJECT:
            return {
                ...state,
                getPurchaseRequestByProject: action.payload,
            };
        case GET_PURCHASE_REQUEST_BY_PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllPurchaseRequestReducer = (state = getAllPurchaseRequestData, action) => {
    switch (action.type) {
        case GET_ALL_PURCHASE_REQUEST_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_PURCHASE_REQUEST:
            return {
                ...state,
                getAllPurchaseRequest: action.payload,
            };
        case GET_ALL_PURCHASE_REQUEST_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editPurchaseRequestReducer = (state = editPurchaseRequestData, action) => {
    switch (action.type) {
        case EDIT_PURCHASE_REQUEST_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_PURCHASE_REQUEST:
            return {
                ...state,
                editPurchaseRequest: action.payload,
            };
        case EDIT_PURCHASE_REQUEST_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deletePurchaseRequestReducer = (state = deletePurchaseRequestData, action) => {
    switch (action.type) {
        case DELETE_PURCHASE_REQUEST_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_PURCHASE_REQUEST:
            return {
                ...state,
                deletePurchaseRequest: action.payload,
            };
        case DELETE_PURCHASE_REQUEST_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}