import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { deleteProjectAction, getAllProjectAction } from '../../redux/actions/projectAction';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import CopyImg from '../../assets/image/copy.png'
import removeImg from '../../assets/image/delete-red.png'
import downloadWhite from "../../assets/image/download-white.png";
import { RoleContext } from '../../helper/RoleProvider';
import { AllStatus, Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import img from '../../assets/image/select.png';
import editIcon from '../../assets/image/edit.svg';
import ViewProject from '../../components/view-modals/ViewProject';
import { dateFormate } from '../../helper/utils';
import viewNoc from '../../assets/image/viewNoc.png';
import { GET_ENQUIRY_BY_ID } from '../../redux/type';
import { getAllCustomerAction } from '../../redux/actions/customerAction';
import { getAllContractorAction } from '../../redux/actions/contractorAction';
import { getProjectManagerAction } from '../../redux/actions/userAction';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";
import moment from "moment";
import { downloadQuoatationFile } from "../../helper/utils";
import { getQuotationByIdAction } from '../../redux/actions/quotationAction';
import { getAllProductAction } from "../../redux/actions/productAction";
import { getEnquiryByIdAction } from '../../redux/actions/enquiryAction';
import { ApiGet } from '../../helper/API/ApiData';

const Project = () => {

  const gridRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useContext(RoleContext);
  const { getAllProjects } = usePaginationHooks()
  const RolesForAdd = [UserRoles.Admin, UserRoles.Project, UserRoles.Maintance]
  const [userData, setUserData] = useState([]);
  const [limit, setlimit] = useState(Paginationlimit);
  const [getAllProject, setgetAllProject] = useState([])
  const [perPage, setPerPage] = useState("10");
  const [deletepopup, setDeletepopup] = useState(false);
  const [paramsValue, setParamsValue] = useState();
  const [columnDef, setColumnDefs] = useState([]);
  const [select, setSelect] = useState('')
  const [rotateArrow, setrotateArrow] = useState(false);
  const [view, setView] = useState(false)
  const [viewId, setViewId] = useState('')
  const [gridApi, setGridApi] = useState(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({ search: "" });
  const toastSuccess = () => toast.success('Project deleted successfully ');
  const copyLinkSuccess = () => toast.success('Project report link copied.');

  const usrData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

  // const getAllProject = useSelector((state) => state.get_All_Project.getAllProject)
  const deleteProject = useSelector((state) => state.delete_Project.deleteProject)
  const editProject = useSelector((state) => state.edit_Project.editProject)
  // const getQuotationByIdData = useSelector((state) => state.get_Quotation_By_Id.getQuotationById)
  // const getEnquiryByIdData = useSelector((state) => state.get_Enquiry_By_Id.getEnquiryById)
  const [downloadQuotation, setDownloadQuotation] = useState(false)
  // useEffect(() => {
  //   dispatch(getAllProjectAction(select))
  // }, [select])
  const getAllProductData = useSelector((state) => state.get_All_Product.getAllProduct)
  useEffect(() => {
    dispatch(getAllCustomerAction())
    dispatch(getAllContractorAction())
    dispatch(getProjectManagerAction())
    dispatch(getAllProductAction())
  }, [])

  // useEffect(() => {
  //   if(downloadQuotation){
  //   if (getQuotationByIdData && getQuotationByIdData.status === 200 && getQuotationByIdData.data && getEnquiryByIdData && getEnquiryByIdData.status === 200 && getEnquiryByIdData.data) {
  //     const productdata = getQuotationByIdData?.data?.items?.map((element) => {
  //       return element;
  //     })
  //     productdata.sort((a, b) => {
  //       if (a.productName < b.productName) return -1;
  //       if (a.productName > b.productName) return 1;
  //       return 0;
  //     });

  //     const data = {
  //       createdAt: getQuotationByIdData?.data?.createdAt,
  //       partyName: getEnquiryByIdData?.data?.partyName,
  //       email: getEnquiryByIdData?.data?.email,
  //       quotationNo: getQuotationByIdData?.data?.quotationNo,
  //       contactPersonNumber: getEnquiryByIdData?.data?.contactPersonNumber,
  //       enquiryDate: moment(getEnquiryByIdData?.data?.enquiryDate).format("DD/MM/YYYY"),
  //       visitDate: moment(getEnquiryByIdData?.data?.visitDate).format("DD/MM/YYYY"),
  //       location: getEnquiryByIdData?.data?.location,
  //       googleLocation: getEnquiryByIdData?.data?.googleLocation,
  //       commonDiscount: getQuotationByIdData.data?.commonDiscount,
  //     }

  //     downloadQuoatationFile(productdata, 'Quotation', data, "Enquiry");
  //     setDownloadQuotation(false)
  //   }
  //   }
  // }, [getQuotationByIdData, getEnquiryByIdData,]);

  const getData = async (quotationId, enqId) => {
    // dispatch(getEnquiryByIdAction(enqId))
    // dispatch(getQuotationByIdAction(quotationId))
    // setDownloadQuotation(true)
    const getEnquiryByIdData =  await ApiGet(`enquiry/get_enquiry_by_id/${enqId}`)
    const getQuotationByIdData = await ApiGet(`quotation/quotation_by_id/${quotationId}`)
    
    if(getQuotationByIdData && getQuotationByIdData.status === 200 && getQuotationByIdData.data && getEnquiryByIdData && getEnquiryByIdData.status === 200 && getEnquiryByIdData.data){
      let productdata = []
      getQuotationByIdData.data?.items.map((element) => {
        const ele = getAllProductData.data.find((ele) => ele?.productCode === element?.productCode)
        productdata.push({ ...ele, quantity: element?.reqQuantity, discount: element?.discount ?? 0, retailSalePrice: element?.retailSalePrice ?? 0 })
      })

      const data = {
        createdAt: getQuotationByIdData?.data?.createdAt,
        partyName: getEnquiryByIdData?.data?.partyName,
        email: getEnquiryByIdData?.data?.email,
        quotationNo: getQuotationByIdData?.data?.quotationNo,
        contactPersonNumber: getEnquiryByIdData?.data?.contactPersonNumber,
        enquiryDate: moment(getEnquiryByIdData?.data?.enquiryDate).format("DD/MM/YYYY"),
        visitDate: moment(getEnquiryByIdData?.data?.visitDate).format("DD/MM/YYYY"),
        location: getEnquiryByIdData?.data?.location,
        googleLocation: getEnquiryByIdData?.data?.googleLocation,
        commonDiscount: getQuotationByIdData.data?.commonDiscount,
      }

      downloadQuoatationFile(productdata, 'Quotation', data, "Enquiry");
    }
  }

  const onEdit = (id) => {
    navigate(`edit?projectId=${id}`)
  }

  const onDelete = (id) => {
    dispatch(deleteProjectAction(id))
    setDeletepopup(false)
    toastSuccess(true)
  }

  const onView = (id) => {
    setView(true)
    setViewId(id)
  }

  const onCopy = (id) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/project-report?id=${id}`);
    copyLinkSuccess(true)
  }

  const noc = (id) => {
    dispatch({
      type: GET_ENQUIRY_BY_ID,
      payload: []
    })
    navigate(`/noc/add?projectId=${id}`)
  }

  const columnDefs = [
    {
      field: 'partyName',
      headerName: 'Project Name',
      flex: 1
    },
    {
      field: 'completed_steps_progress',
      sortable: false,
      headerName: 'Completed Steps',
      maxWidth: 140
    },
    // {
    //   field: 'customerName',
    //   headerName: 'Customer Name'
    // },
    { 
      field: 'location',
      maxWidth: 200,
    },
    { 
      field: 'contactPersonName',
      maxWidth: 180,
    },
    // { field: 'contactPersonNumber' },
    {
      headerName: 'Estimated Delivery',
      field: 'estimatedDelivery',
      maxWidth: 160,

      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
      </div>
    },
    // {
    //   field: "createdBy",
    //   cellRendererFramework: (params) => <div>
    //     {params?.value?.name}
    //   </div>
    // },
    // {
    //   field: "approvedBy",
    //   cellRendererFramework: (params) => <div>
    //     {params?.value?.name}
    //   </div>
    // },
    {
      field: "projectManager.name",
      headerName: "Project Manager",
      maxWidth: 150,
      cellRendererFramework: (params) => <div>
        {params?.value}
      </div>
    },
    // {
    //   field: "projectType"
    // },
    {
      headerName: 'Action',
      field: "_id",
      sortable: false,
      // minWidth: 150,
      maxWidth: 150,
      cellRendererFramework: (params) =>
        <div>
          {/* {(params.data?.createdBy?._id === usrData.id || userRole === UserRoles.Admin || params.data?.projectManager._id === usrData.id || userRole === UserRoles.Maintance) &&
            <><img src={editIcon} style={{ cursor: "pointer", height: "20px" }} onClick={() => onEdit(params?.value)} title="Edit" alt='' /></>} */}
          {userRole === UserRoles.Admin && <img src={removeImg} onClick={() => { setDeletepopup(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-2' title="Delete" alt='' />}
          <img src={viewImg} onClick={() => { onView(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-1' title='View' alt='' />
          <img src={CopyImg} onClick={() => onCopy(params?.value)} style={{ cursor: "pointer", height: "20px" }} className='ms-2' title='Copy project report link' alt='' />
          {params.data?.finalQuatation ? <img src={downloadWhite} alt="Final Quatation" title='Final Quataion' onClick={() => { getData(params.data?.finalQuatation, params.data?.enqId); }} className='ms-2' style={{ backgroundColor: "black", cursor: "pointer", height: "20px" }} /> : ""}
          {params.data?.status === "Done" && <img src={viewNoc} onClick={() => noc(params.data._id)} style={{ cursor: "pointer", height: "20px" }} className='ms-1' title='NOC' alt='' />}
        </div>
    }
  ]

  const NoRolecolumnDefs = [
    { field: 'partyName' },
    { field: 'location' },
    { field: 'contactPersonName' },
    { field: 'contactPersonNumber' },
    {
      headerName: 'Estimated Delivery',
      field: 'estimatedDelivery',

      cellRendererFramework: (params) => <div>
        <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
      </div>
    },
    // {
    //   field: "createdBy",
    //   cellRendererFramework: (params) => <div>
    //     {params.value.name}
    //   </div>
    // },
    {
      field: "approvedBy",
      cellRendererFramework: (params) => <div>
        {params.value.name}
      </div>
    },
    {
      field: "projectType"
    },
    {
      headerName: 'Action',
      field: "_id",

      cellRendererFramework: (params) =>
        <div>
          <img src={viewImg} onClick={() => { onView(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title='View' alt='' />
        </div>
    }
  ]

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      width: 'auto',
      filter: false,
      flex: 1
    };
  }, []);

  useEffect(() => {
    if (getAllProject) {
      if (RolesForAdd.includes(userRole)) {
        setColumnDefs(columnDefs)
      } else {
        setColumnDefs(NoRolecolumnDefs)
      }
      // if (getAllProject.data) {
      //   if (userRole === UserRoles.Maintance) {
      //     getAllProject.data = getAllProject.data.filter((ele) => ele.projectType === 'Maintenance')
      //   }
      //   else if (userRole === UserRoles.Project) {
      //     getAllProject.data = getAllProject.data.filter((ele) => ele.projectType !== 'Maintenance')
      //   }

      //   setUserData(getAllProject.data.map((ele) => {
      //     return {
      //       partyName: ele.partyName ? ele.partyName : '',
      //       location: ele.location ? ele.location : '',
      //       contactPersonName: ele.contactPersonName ? ele.contactPersonName : '',
      //       contactPersonNumber: ele.contactPersonNumber ? ele.contactPersonNumber : '',
      //       estimatedDelivery: ele.estimatedDelivery ? ele.estimatedDelivery : '',
      //       createdBy: ele.createdBy ? ele.createdBy : '',
      //       approvedBy: ele.approvedBy ? ele.approvedBy : '',
      //       status: ele.status ? ele.status : '',
      //       projectManager: ele.projectManager ? ele.projectManager : '',
      //       projectType: ele.projectType ? ele.projectType : '',
      //       _id: ele._id ? ele._id : '',
      //     }
      //   }))
      // }

    }
  }, [getAllProject])

  const onHide = () => {
    setView(false)
    setViewId('')
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const clearFilter = () => {
    setFilter({ search: '' });
    setSearch("");
    setSelect("");
  }

  const debouncedHandleSearch = useCallback(debounce((value) => {
    setFilter({ ...filter, ["search"]: value });
  }, 1000), []);

  useEffect(() => {
    debouncedHandleSearch(search);
  }, [search]);

  useEffect(() => {
    if (gridApi || editProject?.status === 200 || deleteProject?.status === 200 || filter.search) {
      const dataSource = {
        getRows: (params) => {
          const page = params.endRow / limit;
          const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
          const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

          getAllProjects(select, '', page, limit, filter.search, sorted_column, sorted_order)
            .then(res => {
              setgetAllProject(res)
              if (res.data) {
                if (userRole === UserRoles.Maintance) {
                  res.data = res.data.filter((ele) => ele.projectType === 'Maintenance')
                }
                else if (userRole === UserRoles.Project) {
                  res.data = res.data.filter((ele) => ele.projectType !== 'Maintenance')
                }
              }
              params.successCallback(res.data.map((ele) => {
                return {
                  partyName: ele.partyName ? ele.partyName : '',
                  customerName: ele.customer?.accountName ? ele.customer?.accountName : '',
                  location: ele.location ? ele.location : '',
                  finalQuatation: ele.finalQuatation === "" ? "" : ele.finalQuatation,
                  enqId: ele.enq_id ? ele.enq_id : "",
                  contactPersonName: ele.contactPersonName ? ele.contactPersonName : '',
                  contactPersonNumber: ele.contactPersonNumber ? ele.contactPersonNumber : '',
                  estimatedDelivery: ele.estimatedDelivery ? ele.estimatedDelivery : '',
                  createdBy: ele.createdBy ? ele.createdBy : '',
                  approvedBy: ele.approvedBy ? ele.approvedBy : '',
                  status: ele.status ? ele.status : '',
                  projectManager: ele.projectManager ? ele.projectManager : '',
                  projectType: ele.projectType ? ele.projectType : '',
                  completed_steps_progress: `${ele?.StepsDetails?.completed_steps ?? 0}/${ele?.StepsDetails?.total_steps ?? 0}`,
                  _id: ele._id ? ele._id : '',
                }
              }), res.totalCount ? res.totalCount : 0)

              if (res?.data?.length <= 0) {
                gridApi.showNoRowsOverlay();
              } else {
                gridApi.hideOverlay();
              }
            })
        }
      };
      gridApi?.setDatasource(dataSource);
    }

  }, [gridApi, select, limit, userRole, UserRoles, deleteProject, editProject, filter.search]);

  const handleRowClick = (params) => {
    if (params.colDef.field !== "_id") {
      return onEdit(params.data._id);
    }
  }

  return (
    <div className='position-relative p-4'>
      <div className='mb-4 set-page-heade'>
        <h2 className='mb-0'>Project</h2>
        <div className='d-flex'>
          <div className="me-3">
            <input
              type="text"
              className="form-control search-button"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value.trimStart())}
              autoComplete="off"
            />
          </div>
          <div className="position-relative header-select users-label" style={{ width: "max-content" }}>
            <img src={img} className={`${rotateArrow && "rotateArrow"}`} width="18px" alt="" />
            <select className="form-control mt-0" style={{ width: '162px' }} value={select} onBlur={() => { setrotateArrow(false) }} onClick={() => { setrotateArrow(!rotateArrow) }} onChange={(e) => setSelect(e.target.value)} name="role">
              <option value="">Select type</option>
              <option value={AllStatus.approved}>Newly Approved</option>
              <option value={AllStatus.active}>Active</option>
              <option value={AllStatus.Pending}>Pending</option>
              <option value={AllStatus.InProgress}>In Progress</option>
              <option value={AllStatus.OnHold}>On Hold</option>
              <option value={AllStatus.Done}>Done</option>
            </select>
          </div>
          <button className='adduser-btn ms-3' onClick={() => clearFilter()}>Clear Filter</button>
        </div>
      </div>
      {userData &&
        <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
          {/* <AgGridReact
            ref={gridRef}
            rowData={userData}
            columnDefs={columnDef}
            pagination={true}
            defaultColDef={defaultColDef}
            style={{ width: "100%" }}
            paginationPageSize={perPage}
            onFirstDataRendered={onFirstDataRendered}
            onGridSizeChanged={onGridSizeChanged}
          /> */}
          <AgGridReact
            pagination={true}
            columnDefs={columnDefs}
            rowModelType={"infinite"}
            paginationPageSize={limit}
            cacheBlockSize={limit}
            onGridReady={onGridReady}
            rowHeight={45}
            defaultColDef={defaultColDef}
            overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
            rowSelection={'single'}
            onCellClicked={(event) => {
              const rowData = event.data;
              if (rowData?.createdBy?._id === usrData.id || rowData?.projectManager._id === usrData.id || [UserRoles.Admin, UserRoles.Maintance].includes(userRole)) {
                handleRowClick(event)
              }
            }}
          />

          {/* <Pagination setlimit={setlimit} setPage={setPage} page={page} limit={limit} data={saleData} totalCount={totalCount} /> */}
          <div className='set-pagination-dropdaun'>
            <p>Show</p>
            <select value={limit} onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
            <p>Entries</p>
          </div>
        </div>
      }
      {view && <ViewProject show={view} onHide={() => onHide()} projectId={viewId} />}
      {deletepopup &&
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => { onDelete(paramsValue) }}
          onCancel={() => { setDeletepopup(false); setParamsValue('') }}
          focusCancelBtn
        >
          You want to delete it
        </SweetAlert>
      }
    </div>
  )
}

export default Project
