import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const RejectLabourBill = ({ reject_reason, setReject_Reason, OnReject }) => {
    return (<>
        <SweetAlert
            show={reject_reason?.isOpen}
            danger
            showCancel
            disabled={!Boolean(reject_reason?.text)}
            confirmBtnText="Reject"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={OnReject}
            onCancel={() => setReject_Reason({ isOpen: false, text: "", reject_data_details: null })}
            focusCancelBtn
        >
            <input
                type="text"
                className="form-control"
                value={reject_reason.text}
                onChange={(e) => setReject_Reason((pre) => ({ ...pre, text: e.target.value.trimStart() }))}
                placeholder={"Enter reason of reject labour bill"}
            />
        </SweetAlert>
    </>);
}

export default RejectLabourBill