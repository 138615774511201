import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSiteReportsNoAuthAction, getSiteReportsNoAuthByIdAction } from '../../redux/actions/dailySiteReportAction'
import Viewprojectreport from './Viewprojectreport'
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom'

const Projectreport = () => {

    const dispatch = useDispatch();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const projctId = queryParams.get("id")

    const [dailyReportTog, setDailyReportTog] = useState(false)
    const [projectReport, setProjectReport] = useState([])
    const [selectedReportId, setSelectedReportId] = useState('')


    const projectReportCardData = useSelector(state => state.get_All_Sites_Report_No_Auth.getAllSiteReportsNoAuth)

    const openReport = (id) => {
        setSelectedReportId(id)
        setDailyReportTog(true)
    }

    useEffect(() => {
        dispatch(getAllSiteReportsNoAuthAction(projctId))
    }, [])

    useEffect(() => {
        setProjectReport(projectReportCardData ? projectReportCardData?.data?.reports : [])
    }, [projectReportCardData])

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <h2 className='mb-0'>{projectReportCardData ? projectReportCardData?.data?.projectData?.partyName : 'Project Reports'}</h2>
                </div>
                <Row className=''>
                    {
                        projectReport && projectReport.length ? projectReport.map((item) => (

                            <Col lg={3} md={4} sm={6} className="my-3">
                                <div className="projectreport-card" onClick={() => openReport(item._id)}>
                                    <p className='mb-2'>Date: <span>{moment(item.date).format('DD MMM YYYY')}</span></p>
                                    <p className='mb-2'>Total men power: <span>{item.totalMenPower}</span></p>
                                    <p className='work-details mb-0'>Work details:
                                        <span>{item.workDetails}</span>
                                    </p>
                                    {/* <p className='work-details mb-0'>next Day Planing:
                                        <span>{item.s}</span>
                                    </p>
                                    <p className='work-details mb-0'>remark:
                                        <span>{item.remark}</span>
                                    </p> */}
                                </div>
                            </Col>
                        ))
                            :
                            <h1>No reports found</h1>
                    }
                </Row>
            </div>
            {dailyReportTog && <Viewprojectreport show={dailyReportTog} onHide={() => setDailyReportTog(false)} headerText="Project Report" selectedReportId={selectedReportId} />}
        </>
    )
}

export default Projectreport