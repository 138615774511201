import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_RETURN_MATERIAL, ADD_RETURN_MATERIAL_ERROR, ADD_RETURN_MATERIAL_LOADING, DELETE_RETURN_MATERIAL, DELETE_RETURN_MATERIAL_ERROR, DELETE_RETURN_MATERIAL_LOADING, EDIT_RETURN_MATERIAL, EDIT_RETURN_MATERIAL_ERROR, EDIT_RETURN_MATERIAL_LOADING, GET_ALL_RETURN_MATERIAL, GET_ALL_RETURN_MATERIAL_ERROR, GET_ALL_RETURN_MATERIAL_LOADING, GET_RETURN_MATERIAL_BY_ID, GET_RETURN_MATERIAL_BY_ID_ERROR, GET_RETURN_MATERIAL_BY_ID_LOADING, GET_RETURN_MATERIAL_BY_PROJECT, GET_RETURN_MATERIAL_BY_PROJECT_ERROR, GET_RETURN_MATERIAL_BY_PROJECT_LOADING, IS_LOADING } from "../type"

export const addReturnMaterialAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_RETURN_MATERIAL_LOADING,
            payload: true
        })

        await ApiPost(`return_material/add_return_material`, body)
            .then((res) => {
                dispatch({
                    type: ADD_RETURN_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_RETURN_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_RETURN_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_RETURN_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editReturnMaterialAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_RETURN_MATERIAL_LOADING,
            payload: true
        })

        await ApiPut(`return_material/edit_return_material`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_RETURN_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_RETURN_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_RETURN_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_RETURN_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllReturnMaterialAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_RETURN_MATERIAL_LOADING,
            payload: true
        })

        await ApiGet(`return_material/get_all_return_material`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_RETURN_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_RETURN_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_RETURN_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_RETURN_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getReturnMaterialByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_RETURN_MATERIAL_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`return_material/get_return_material_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_RETURN_MATERIAL_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_RETURN_MATERIAL_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_RETURN_MATERIAL_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_RETURN_MATERIAL_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getReturnMaterialByProjectAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_RETURN_MATERIAL_BY_PROJECT_LOADING,
            payload: true
        })

        await ApiGet(`return_material/get_return_material_by_project/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_RETURN_MATERIAL_BY_PROJECT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_RETURN_MATERIAL_BY_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_RETURN_MATERIAL_BY_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_RETURN_MATERIAL_BY_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteReturnMaterialAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_RETURN_MATERIAL_LOADING,
            payload: true
        })

        await ApiDelete(`return_material/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_RETURN_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_RETURN_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_RETURN_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_RETURN_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}