import { ApiGet } from "../../helper/API/ApiData"
import { GET_ENQUIRY_BY_MONTH_YEAR_WISE, GET_ENQUIRY_BY_MONTH_YEAR_WISE_ERROR, GET_ENQUIRY_BY_MONTH_YEAR_WISE_LOADING, GET_ENQUIRY_COUNT_BY_STATUS, GET_ENQUIRY_COUNT_BY_STATUS_ERROR, GET_ENQUIRY_COUNT_BY_STATUS_LOADING, GET_RECENT_ENQUIRY, GET_RECENT_ENQUIRY_ERROR, GET_RECENT_ENQUIRY_LOADING, IS_LOADING } from "../type"

export const getRecentEnquiryAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_RECENT_ENQUIRY_LOADING,
            payload: true
        })

        await ApiGet(`enquiry/get_recent_enquiry_dashboard`)
            .then((res) => {
                dispatch({
                    type: GET_RECENT_ENQUIRY,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_RECENT_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_RECENT_ENQUIRY_ERROR,
            payload: err
        })

        dispatch({
            type: GET_RECENT_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getEnquiryByMonthYearWiseAction = (year) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ENQUIRY_BY_MONTH_YEAR_WISE_LOADING,
            payload: true
        })

        await ApiGet(`enquiry/get_enquiry_by_month_year_wise?year=${year}`)
            .then((res) => {
                dispatch({
                    type: GET_ENQUIRY_BY_MONTH_YEAR_WISE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ENQUIRY_BY_MONTH_YEAR_WISE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ENQUIRY_BY_MONTH_YEAR_WISE_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ENQUIRY_BY_MONTH_YEAR_WISE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getEnquiryCountByStatusAction = (type) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ENQUIRY_COUNT_BY_STATUS_LOADING,
            payload: true
        })

        await ApiGet(`enquiry/get_enquiry_count_by_status?type=${type}`)
            .then((res) => {
                dispatch({
                    type: GET_ENQUIRY_COUNT_BY_STATUS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ENQUIRY_COUNT_BY_STATUS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ENQUIRY_COUNT_BY_STATUS_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ENQUIRY_COUNT_BY_STATUS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}