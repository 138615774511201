import React, { useState, useEffect } from 'react'
import ViewCardDesk from '../../../components/dashboard/ViewCardDesk'
import ViewTableDesk from '../../../components/dashboard/ViewTableDesk'
import ViewPieDesk from '../../../components/dashboard/ViewPieDesk'
import ViewBarDesk from '../../../components/dashboard/ViewBarDesk'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { ApiGet } from '../../../helper/API/ApiData'


const StoreDashboard = () => {

  const [task, setTask] = useState([])
  const [sell, setSell] = useState([])
  const [purchase, setPurchase] = useState([])

  const getTaskDashboardData = useSelector((state) => state.task_Dashboard.taskDashboard)

  useEffect(() => {
    ApiGet('sell/get_sell_dashboard')
      .then((res) => {
        setSell(res.data.map((ele)=>{
          return{
            CustomerName : ele.customerName,
            projectName : ele.projectName,
            TotalAmount : ele.totalAmount
          }
        }))
      })

    ApiGet('purchase/get_recent_purchase')
      .then((res) => {
        setPurchase(res.data.map((ele)=>{
          return{
            CustomerName : ele.customerName,
            TotalAmount : ele.totalAmount,
          }
        }))
      })

  }, [])

  useEffect(() => {
    if (getTaskDashboardData && getTaskDashboardData.data) {
      setTask(getTaskDashboardData.data.map((ele) => {
        return {
          ProjectName: ele.projectName,
          Subject: ele.subject,
          Status: ele.status,
        }
      }))
    }
  }, [getTaskDashboardData])

  return (
    <div className="p-0 m-0">
      <Row>

        <Col lg={6} md={6}>
          <Col lg={12} className="">
            {sell.length > 0 && <ViewTableDesk data={sell} title={"Recent Sale"} link="/sale"/>}
          </Col>
        </Col>
        
        <Col lg={6} md={6}>
          <Col lg={12} className="">
            {purchase.length > 0 && <ViewTableDesk data={purchase} title={"Recent Purchase"} link="/purchase"/>}
          </Col>
        </Col>

        <Col lg={6} md={6}>
          <Col lg={12} className="mt-4">
            {task.length > 0 && <ViewTableDesk data={task} title={"Recent Task"} link="/task"/>}
          </Col>
        </Col>

      </Row>
    </div>
  )
}

export default StoreDashboard