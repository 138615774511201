import React from 'react'
import { Modal } from 'react-bootstrap'

const InvalidDataConfirmation = ({ show, onHide, onConfirm }) => {

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                centered
                size="md"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Invalid Data Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className='payment-ammount-confirmation-body'>
                    <h1 className='text-center' style={{ width: '60%', margin: '0 auto', color: '#ff7800' }}>Warning!</h1>
                    <h4 className='text-center' style={{ width: '80%', margin: '0 auto', color: 'red' }}>There are some issues with products you selected</h4>
                    <p style={{ marginTop: "5px", width: "80%", margin: "0 auto" }}><b>Note : It may led to some unexpected behaviour in project material informations. So please check those details before you proceed</b></p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='adduser-btn' onClick={() => onConfirm?.()}>Yes Proceed</button>
                    <button className='cancel-button' onClick={onHide}>Cancel</button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default InvalidDataConfirmation