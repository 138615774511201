import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_TASK_COMMENT, ADD_TASK_COMMENT_ERROR, ADD_TASK_COMMENT_LOADING, DELETE_TASK_COMMENT, DELETE_TASK_COMMENT_ERROR, DELETE_TASK_COMMENT_LOADING, EDIT_TASK_COMMENT, EDIT_TASK_COMMENT_ERROR, EDIT_TASK_COMMENT_LOADING, GET_ALL_TASK_COMMENT, GET_ALL_TASK_COMMENT_ERROR, GET_ALL_TASK_COMMENT_LOADING, GET_COMMENTS_BY_TASK_ID, GET_COMMENTS_BY_TASK_ID_ERROR, GET_COMMENTS_BY_TASK_ID_LOADING, GET_TASK_COMMENT_BY_ID, GET_TASK_COMMENT_BY_ID_ERROR, GET_TASK_COMMENT_BY_ID_LOADING, IS_LOADING } from "../type"

export const addTaskCommentAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_TASK_COMMENT_LOADING,
            payload: true
        })

        await ApiPost(`task_comment/add_task_comment`,body)
            .then((res) => {
                dispatch({
                    type: ADD_TASK_COMMENT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_TASK_COMMENT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_TASK_COMMENT_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_TASK_COMMENT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getTaskCommentByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_TASK_COMMENT_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`task_comment/get_task_comment_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_TASK_COMMENT_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_TASK_COMMENT_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_TASK_COMMENT_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_TASK_COMMENT_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllTaskCommentAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_TASK_COMMENT_LOADING,
            payload: true
        })

        await ApiGet(`task_comment/get_all_task_comment`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_TASK_COMMENT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_TASK_COMMENT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_TASK_COMMENT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_TASK_COMMENT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getCommentByTaskIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_COMMENTS_BY_TASK_ID_LOADING,
            payload: true
        })

        await ApiGet(`task_comment/get_task_comment_by_task_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_COMMENTS_BY_TASK_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_COMMENTS_BY_TASK_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_COMMENTS_BY_TASK_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_COMMENTS_BY_TASK_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editTaskCommentAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_TASK_COMMENT_LOADING,
            payload: true
        })

        await ApiPut(`task_comment/edit_task_comment`,body)
            .then((res) => {
                dispatch({
                    type: EDIT_TASK_COMMENT,
                    payload: res
                })
                dispatch(getCommentByTaskIdAction(body.taskId))
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_TASK_COMMENT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_TASK_COMMENT_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_TASK_COMMENT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteTaskCommentAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_TASK_COMMENT_LOADING,
            payload: true
        })

        await ApiDelete(`task_comment/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_TASK_COMMENT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_TASK_COMMENT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_TASK_COMMENT_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_TASK_COMMENT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}