import React, { useCallback, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import img from "../../assets/image/select.png";
import ReactDatePicker from 'react-datepicker';
import Datepickericon from '../../assets/image/date-picker-icon.png'
import { addBillEntryAction, editBillEntryAction, getBillEntryByIdAction } from '../../redux/actions/addBillAction';
import { getCustomerDropDownAction, getSupplierDropDownAction } from '../../redux/actions/customerAction';
import moment from 'moment';
import { EDIT_BILL_ENTRY, GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER, GET_BILL_ENTRY_BY_ID, GET_CUSTOMER_DROPDOWN, GET_SUPPLIER_DROPDOWN } from '../../redux/type';
import Select from 'react-select'
import { toast } from 'react-toastify';
import { getAllPendingBillByCoustmerAction } from '../../redux/actions/accountAction';
import { useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';
import { dateFormate } from '../../helper/utils';
import CurrencyFormat from 'react-currency-format';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactAutocomplete from 'react-autocomplete';
import { getProjectByCustomerAction } from '../../redux/actions/projectAction';

const AddBill = ({ show, onHide, addBillEntryId }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')
    const paidAmountRef = useRef(null)
    const [bill, setBill] = useState({
        billType: "Sale",
        date: "",
        invoiceNo: "",
        amount: "",
        paymentType: "BANK",
        customer: "",
        pay: "",
        status: "Pending",
        projectId: null,
        projectName: "",
    });
    const [formErrors, setFormErrors] = useState()
    const [selectedBills, setSelectedBills] = useState([])
    const [focusPaymentAmount, setFocusPaymentAmount] = useState(false);
    const [ammountConfirmation, setAmmountConfirmation] = useState(false);
    const [focusInput, setFocusInput] = useState('');
    const [amount, setAmount] = useState(0);
    const [electedData, setSelectedData] = useState([]);
    const [rotateArrow, setrotateArrow] = useState({
        billType: false,
        paymentType: false,
        customer: false,
        status: false,
    });
    const [pendingdTableData, setPendingdTableData] = useState([])
    const [option, setOption] = useState([])
    const customerData = useSelector((state) => state.get_Customer_Drop_Down.getCustomerDropDown)
    const supplierData = useSelector((state) => state.get_Supplier_Drop_Down.getSupplierDropDown)
    const billEntryData = useSelector((state) => state.get_Bill_Entry_By_Id.getBillEntryById)
    const editBillEntryData = useSelector((state) => state.edit_Bill_Entry.editBillEntry)
    const pendingdata = useSelector((state) => state.get_ALL_Pending_Bill.getAllPendingBill)
    const gridRef = useRef();

    const [projectOption, setProjectOption] = useState([]);
    const projectData = useSelector((state) => state.get_Project_By_Customer.getProjectByCustomer.data)

    useEffect(() => {
        if (projectData) {
            let temp = projectData.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }));
            setProjectOption([...temp.map((ele) => ({ value: ele._id, label: ele.partyName }))]);
        }
    }, [projectData])

    useEffect(() => {
        if (bill?.customer) {
            dispatch(getProjectByCustomerAction(bill?.customer))
        }
    }, [bill?.customer])


    useEffect(() => {
        if (addBillEntryId !== "") {
            dispatch(getBillEntryByIdAction(addBillEntryId))
        }
    }, [addBillEntryId])

    useEffect(() => {
        if (bill.billType === "Sale") {
            dispatch(getCustomerDropDownAction())
        } else if (bill.billType === "Purchase") {
            dispatch(getSupplierDropDownAction())
        }
    }, [bill.billType])

    useEffect(() => {
        if (customerData && customerData.data) {
            let temp = customerData.data.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setOption(temp.map((item) => {
                return {
                    value: item._id,
                    label: item.accountName,
                }
            }))
        }
    }, [customerData])

    useEffect(() => {
        if (supplierData && supplierData.data) {
            let temp = supplierData.data.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setOption(temp.map((item) => {
                return {
                    value: item._id,
                    label: item.accountName
                }
            }))
        }
    }, [supplierData])

    useEffect(() => {
        if (billEntryData && billEntryData.data && addBillEntryId) {
            setBill({
                billType: billEntryData.data.billType,
                date: new Date(billEntryData.data.date),
                invoiceNo: billEntryData.data.invoiceNo,
                amount: billEntryData.data.amount,
                paymentType: billEntryData.data.paymentType,
                customer: billEntryData.data.customer,
                pay: billEntryData.data.pay,
                status: billEntryData.data.status,
                projectId: billEntryData.data?.projectId ?? null,
                projectName:  billEntryData.data?.projectName ?? "",
            })
            if (billEntryData.data.paidInvoices?.length)
                setSelectedBills(billEntryData.data.paidInvoices)
            else
                setSelectedBills([])
        }
    }, [billEntryData])

    useEffect(() => {
        if (id) {
            dispatch(getAllPendingBillByCoustmerAction({ cId: id, type: bill.billType, pay: "In" }))
        }
    }, [id, bill.billType])

    useEffect(() => {
        return (() => {
            dispatch({
                type: GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER,
                payload: []
            })
        })
    }, [])

    useEffect(() => {
        if (pendingdata && pendingdata.data) setPendingdTableData(
            pendingdata.data.map((ele) => {
                return {
                    _id: ele._id,
                    date: ele.date,
                    createdBy: ele.createdBy,
                    amount: ele.amount,
                    pay: ele.pay,
                    payType: ele?.status === "Pending" && ele?.paymentHistory?.length ? 'Partial' : 'Full',
                    // paidAmount: ele.paidAmount ? (ele.amount - ele.paidAmount) : ele.amount,
                    // pandingAmount: ele.paidAmount ? (ele.amount - ele.paidAmount) : ele.amount,
                    // totalPaid: ele.paidAmount
                }
            })
        )
    }, [pendingdata])

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1
        };
    }, []);

    const selectBill = (e, value) => {
        if (e.target.checked) {
            setSelectedBills([...selectedBills, value])
        } else {
            setSelectedBills(selectedBills.filter(item => item !== value))
        }
    }

    const handleChangeOption = (e, params) => {

        const newData = []
        pendingdTableData.map((rowNode, index) => {
            if (rowNode._id === params.data._id) {
                setFocusInput(rowNode._id)
                newData.push({ ...rowNode, payType: e.target.value })
            }
            else {
                newData.push(rowNode)
            }
        });
        setPendingdTableData(newData)
    }

    const testTemp = [];
    const runCheck = useCallback((event, params) => {
        const { data: { _id: id } } = params;
        const { target: { value } } = event;
        if (!testTemp.length) {
            testTemp.push({ id, value });
        } else {
            const i = testTemp.findIndex((ele) => ele.id === id);
            if (i >= 0) {
                testTemp[i].id = id
                testTemp[i].value = value
            } else {
                testTemp.push({ id, value });
            }
        }
        return testTemp.map((ele) => +ele.value).reduce((curr, next) => curr + next, 0);
    }, [])

    const handleChangeAmount = (e, params) => {
        const testAmount = runCheck(e, params);
        e.stopPropagation();
        e.preventDefault();
        const newData = []
        pendingdTableData.map((rowNode, index) => {
            if (rowNode._id === params.data._id) {
                setFocusInput(rowNode._id)
                newData.push({ ...rowNode, paidAmount: e.target.value ? parseInt(e.target.value) : 0 })
            }
            else
                newData.push(rowNode)
        });
        setPendingdTableData(newData)
        setFocusPaymentAmount(true)
    }

    const handleChange = (e, name) => {
        setFocusPaymentAmount(false)
        let re = /^[0-9\b]+$/;
        let regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "amount") {
            if (e.target.value === '' || regexwithdecimal.test(e.target.value)) {
                setBill({ ...bill, [name]: e.target.value })
            }
        } else if (name === "date") {
            setBill({ ...bill, [name]: e })
        } else if (name === "billType") {
            setBill({ ...bill, [name]: e.target.value, customer: '' })
        } else if (name === "status") {
            setBill({ ...bill, [name]: e.target.value })
        } else if (name === "customer") {
            setBill({ ...bill, [name]: e.value })
        } else if (name === "write-projectName") {
            setBill({ ...bill, projectName: e, projectId: null });
        } else if (name === "select-projectName") {
            const { value, label } = projectOption.find((item) => item.value === e);
            setBill({ ...bill, projectName: label, projectId: value });
        } else {
            setBill({ ...bill, [name]: e.target.value })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        let re = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (!bill.billType) {
            error.billType = "Please select bill type"
            flag = true
        }
        if (!bill.date) {
            error.date = "Please select date"
            flag = true
        }
        if (!bill.invoiceNo) {
            error.invoiceNo = "Please enter invoice no"
            flag = true
        }
        if (!bill.amount) {
            error.amount = "Please enter amount"
            flag = true
        }
        else if (!re.test(bill.amount)) {
            error.amount = "Please enter valid amount"
            flag = true
        }
        if (!bill.paymentType) {
            error.paymentType = "Please select payment type"
            flag = true
        }
        if (!bill.customer) {
            error.customer = "Please select customer"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const Save = () => {
        if (validation()) {
            return
        }
        const body = {
            ...bill,
            date: moment(bill.date).format("YYYY-MM-DD"),
            pay: "In"
        }
        dispatch(addBillEntryAction(body))
        onHide()
    }

    const Edit = () => {
        if (validation()) {
            return
        }
        let temp = pendingdTableData.filter(item => selectedBills.includes(item._id) && item.payType === "Full")
        let amount = temp.reduce((accumulator, curr) => +accumulator + +curr.amount ?? 0, 0) ?? 0
        if (+bill.amount > amount || +bill.amount == amount) {
            edit()
            return
        }
        setAmount(amount)
        setAmmountConfirmation(true)
        return
    }

    const edit = () => {

        let data = {
            billType: bill.billType,
            date: moment(bill.date).format("YYYY-MM-DD"),
            invoiceNo: bill.invoiceNo,
            amount: bill.amount,
            paymentType: bill.paymentType,
            customer: bill.customer,
            id: addBillEntryId,
            pay: bill.pay,
            paidInvoices: pendingdTableData.filter(tableItem => (selectedBills.includes(tableItem._id))),
            projectId: bill?.projectId ?? null,
            projectName:  bill?.projectName ?? "",
            // paidInvoices: pendingdTableData.filter(tableItem => (selectedBills.includes(tableItem._id))).map(item => {
            //     let newItem = { ...item, paidAmount: item.paidAmount }
            //     delete newItem.totalPaid
            //     return newItem
            // })
        }
        dispatch(editBillEntryAction(data))
    }

    useEffect(() => {
        if (editBillEntryData && editBillEntryData.data && editBillEntryData.data.status === 200) {
            dispatch({
                type: EDIT_BILL_ENTRY,
                payload: []
            })
            toast.success(editBillEntryData.data.message)
            onHide()
        }
    }, [editBillEntryData])


    const openCalender = (id) => {
        document.getElementById(id).click()
    }

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_CUSTOMER_DROPDOWN,
                payload: []
            })
            dispatch({
                type: GET_SUPPLIER_DROPDOWN,
                payload: []
            })
            dispatch({
                type: GET_BILL_ENTRY_BY_ID,
                payload: []
            })
        }
    }, [])


    const columnDefsBills = [
        {
            headerName: 'Select',
            field: "_id",
            cellRendererFramework: (params) => <div className='pendingamountchecked'>
                <input type="checkBox" checked={selectedBills.find(item => item === params.value)} onChange={(e) => selectBill(e, params.value)} />
            </div>
        },
        {
            field: 'date',
            cellRendererFramework: (params) => <div>
                <span>{dateFormate(params.value, "Date")}</span>
            </div>
        },
        { field: 'createdBy.name' },
        {
            field: 'amount',
            cellRendererFramework: (params) => <div>
                <span><CurrencyFormat value={params.value} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
            </div>
        },
        // {
        //     headerName: 'Panding Amount',
        //     field: "pandingAmount",
        //     cellRendererFramework: (params) => {
        //         return (<div>
        //             <span><CurrencyFormat value={params.value} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
        //         </div>)
        //     }
        // },
        // {
        //     field: 'pay',
        //     cellRendererFramework: (params) => <span>{params.data?.pay === "In" ? "Invoice" : "Payment"}</span>
        // },
        {
            headerName: 'PayType',
            field: "_id",
            cellRendererFramework: (params) => <div>
                <select value={params.data?.payType} onChange={(e) => { handleChangeOption(e, params) }}>
                    <option value="Full" >Full</option>
                    <option value="Partial">Partial</option>
                </select>
            </div>
        },
        // {
        //     headerName: 'Payment Amount',
        //     field: "paidAmount",
        //     cellRendererFramework: (params) =>
        //         <input type="number" max={params.data?.pandingAmount} autoFocus={params.data._id === focusInput && focusPaymentAmount} className='paidAmountInput' ref={paidAmountRef} disabled={params.data.payType === "Full"} value={params.data?.paidAmount} onChange={(e) => handleChangeAmount(e, params)} />
        // },
    ]

    const selectValue = (value, name) => {
        if (name === "customer") {
            return option.find((ele) => ele.value === value)
        }
    }

    console.log("🚀 ~ AddBill ~ bill.projectId:", bill.projectId);
    console.log("🚀 ~ AddBill ~ bill.projectName:", bill.projectName);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{addBillEntryId ? "Edit Bill Entry" : "Add Bill Entry"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Bill Type</label>
                                <div className="position-relative select-icon user-type">
                                    <img src={img} className={`${rotateArrow.billType && "rotateArrow"}`} alt="" />
                                    <select className="form-control " value={bill.billType} onBlur={() => setrotateArrow({ ...rotateArrow, billType: false })} onClick={() => { setrotateArrow({ ...rotateArrow, billType: !rotateArrow.billType }) }} onChange={(e) => handleChange(e, "billType")} disabled={addBillEntryId}>
                                        <option value="">Select bill type</option>
                                        <option value="Purchase">Purchase</option>
                                        <option value="Sale">Sale</option>
                                    </select>
                                </div>
                            </div>
                            {formErrors?.billType && <span style={{ color: "red" }}>{formErrors.billType}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal enquery-datepicker mx-0 mt-3">
                            <div className="users-label ">
                                <label>Date</label>
                                <ReactDatePicker id='Remarkdate' dateFormat="dd/MM/yyyy" selected={bill.date} placeholderText="Select select date" onChange={(e) => handleChange(e, "date")} autoComplete='off' />
                            </div>
                            <img src={Datepickericon} onClick={() => openCalender('Remarkdate')} alt="" className='datepickericon' />
                            {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Invoice No</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter invoice no"
                                    value={bill.invoiceNo}
                                    onChange={(e) => handleChange(e, 'invoiceNo')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.invoiceNo && <span style={{ color: "red" }}>{formErrors.invoiceNo}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Amount</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter amount"
                                    value={bill.amount}
                                    onChange={(e) => handleChange(e, 'amount')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.amount && <span style={{ color: "red" }}>{formErrors.amount}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Payment Type</label>
                                <div className="position-relative select-icon user-type">
                                    <img src={img} className={`${rotateArrow.paymentType && "rotateArrow"}`} alt="" />
                                    <select className="form-control " value={bill.paymentType} onBlur={() => { setrotateArrow({ ...rotateArrow, paymentType: false }) }} onClick={() => { setrotateArrow({ ...rotateArrow, paymentType: !rotateArrow.paymentType }); setFocusPaymentAmount(false) }} onChange={(e) => handleChange(e, "paymentType")}>
                                        <option value="">Select payment type</option>
                                        <option value="BANK">BANK</option>
                                        <option value="CASH">CASH</option>
                                        <option value="LABOUR">LABOUR</option>
                                    </select>
                                </div>
                            </div>
                            {formErrors?.paymentType && <span style={{ color: "red" }}>{formErrors.paymentType}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info add-remark-modal mx-0 mt-3">
                            <div className="multi-select">
                                <label>Customer</label>
                                <Select
                                    value={selectValue(bill.customer, "customer")}
                                    onChange={(e) => handleChange(e, "customer")}
                                    options={option}
                                />
                            </div>
                            {formErrors?.customer && <span style={{ color: "red" }}>{formErrors.customer}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Status</label>
                                <div className="position-relative select-icon user-type">
                                    <img src={img} className={`${rotateArrow.status && "rotateArrow"}`} alt="" />
                                    <select className="form-control " value={bill.status} onBlur={() => setrotateArrow({ ...rotateArrow, status: false })} onClick={() => { setrotateArrow({ ...rotateArrow, status: !rotateArrow.status }) }} onChange={(e) => handleChange(e, "status")} disabled={addBillEntryId}>
                                        <option value="">Select Status</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Done">Done</option>
                                    </select>
                                </div>
                            </div>
                            {formErrors?.billType && <span style={{ color: "red" }}>{formErrors.billType}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Project</label>
                                <ReactAutocomplete
                                    items={projectOption}
                                    shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                    getItemValue={item => item.value}
                                    renderItem={(item, highlighted) =>
                                        <div key={item.value} style={{ backgroundColor: highlighted ? '#0d6efd' : '#fff', color: highlighted ? '#fff' : '#000', fontSize: 16, padding: "5px 8px" }}>
                                            {item.label}
                                        </div>
                                    }
                                    value={bill.projectName}
                                    onChange={(e) => handleChange(e.target.value, "write-projectName")}
                                    onSelect={(value) => handleChange(value, "select-projectName")}
                                    renderInput={(prop) => <input {...prop} placeholder='Enter Project Name' className="form-control" />}
                                    wrapperStyle={{ width: '100%' }}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {(billEntryData && billEntryData.data && billEntryData.data.pay === "Out") &&
                        <Col lg={12}>
                            {pendingdata &&
                                <div className="ag-theme-alpine pendingInvoiceTable  mt-4 " style={{ height: "40vh", width: "100%", position: 'relative' }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={pendingdTableData}
                                        columnDefs={columnDefsBills}
                                        defaultColDef={defaultColDef}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            }
                        </Col>}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { addBillEntryId ? Edit() : Save() }}>{addBillEntryId ? "Edit" : "Save"}</button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>
            {addBillEntryId && ammountConfirmation &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText={+bill.amount < amount ? "Save" : "Manage"}
                    confirmBtnBsStyle={"danger"}
                    title="Are you sure?"
                    onConfirm={() => { +bill.amount < amount ? edit() : setAmmountConfirmation(false) }}
                    onCancel={() => { setAmmountConfirmation(false) }}
                    focusCancelBtn
                >bill amount is less then selected amount!

                </SweetAlert>
            }
        </Modal>
    )
}

export default AddBill