import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import viewImg from '../../assets/image/eye.svg'
import AddBill from '../../components/modals/AddBill';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import img from "../../assets/image/select.png";
import ReactDatePicker from 'react-datepicker';
import Datepickericon from '../../assets/image/date-picker-icon.png'
import { getAllCustomerAction } from '../../redux/actions/customerAction';
import Select from 'react-select';
import AddPaybleReceivable from '../../components/modals/PaybleReceivable';
import { getAccountAction, getAllAccountCountAction } from '../../redux/actions/accountAction';
import FollowUpPayment from '../../components/modals/FollowUpPayment';
import CurrencyFormat from 'react-currency-format';
import { ADD_BILL_ENTRY, EDIT_BILL_ENTRY } from '../../redux/type';
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import { dateFormate } from '../../helper/utils';
import AddCustomer from '../../components/modals/AddCustomer';
import * as XLSX from "xlsx";
import { useNavigate } from 'react-router-dom';
import usePaginationHooks from '../../Hooks/paginationHooks';

const Account = () => {
    const gridRef = useRef();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userRole = useContext(RoleContext);
    const { getAllAccounts } = usePaginationHooks()
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Account]
    const [accountData, setaccountData] = useState({})
    const [bill, setBill] = useState([]);
    const [followUpPayment, setFollowUpPayment] = useState([]);
    const [perPage, setPerPage] = useState("10");
    const [addBillEntryTog, setAddBillEntryTog] = useState(false);
    const [paybleTog, setPaybleTog] = useState(false);
    const [followUpTog, setFollowUpTog] = useState(false);
    const [addBillEntryId, setAddBillEntryId] = useState('')
    const [customerId, setCustomerId] = useState('')
    const [rotateArrow, setrotateArrow] = useState(false);
    const [customerTog, setCustomerTog] = useState(false);
    const [filter, setFilter] = useState({
        startDate: '',
        endDate: '',
        customer: [],
        billType: 'Purchase',
    })
    const [totalFlag, setTotalFlag] = useState('')
    const [isFilter, setisFilter] = useState(false)
    const [customerOption, setCustomerOption] = useState([])
    const [columnDefsBill, setcolumnDefsBill] = useState([]);
    const [viewPaymentId, setViewPaymentId] = useState('')
    const [billHeaderName, setBillHeaderName] = useState({
        pending: "Pending Payable",
        paid: "Paid Payments"
    });
    const [sorting, setSorting] = useState({ column_name: '', column_order: '' });
    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

    // const accountData = useSelector((state) => state.get_Account.getAccount)
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer)
    const addEntryData = useSelector((state) => state.add_Bill_Entry.addBillEntry)
    const editEntryData = useSelector((state) => state.edit_Bill_Entry.editBillEntry)
    const accountCountData = useSelector((state) => state.get_All_Account_Count.getAllAccountCount)

    useEffect(() => {
        if (customerData && customerData.data) {
            let temp = customerData.data.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setCustomerOption(temp.map((item) => {
                return {
                    value: item._id,
                    label: item.accountName
                }
            }))
        }
    }, [customerData])

    // useEffect(() => {
    //     if (accountData && accountData.data && accountData.data.inOutData) {
    //         setBill(accountData.data.inOutData.map((ele) => {
    //             return {
    //                 _id: ele.customerId ? ele.customerId : '',
    //                 name: ele.customerName ? ele.customerName : '',
    //                 amount: ele.totalIn ? ele.totalIn : 0,
    //                 pending: ele?.pending ? ele.pending : 0
    //             }
    //         }))
    //         // {
    //         //     accountData.data.inData && setBill(accountData.data.inData.map((ele) => {
    //         //         return {
    //         //             date: ele.date ? ele.date : '',
    //         //             amount: ele.amount ? ele.amount : '',
    //         //             customer: ele.customer ? ele.customer.accountName : '',
    //         //             createdBy: ele.createdBy ? ele.createdBy.name : '',
    //         //             _id: ele._id ? ele._id : '',
    //         //         }
    //         //     }))
    //         // }
    //         // {
    //         //     accountData.data.outData && setFollowUpPayment(accountData.data.outData.map((ele) => {
    //         //         return {
    //         //             date: ele.date ? ele.date : '',
    //         //             amount: ele.amount ? ele.amount : '',
    //         //             customer: ele.customer ? ele.customer.accountName : '',
    //         //             createdBy: ele.createdBy ? ele.createdBy.name : '',
    //         //             _id: ele._id ? ele._id : '',
    //         //         }
    //         //     }))
    //         // }
    //     }
    // }, [accountData])

    // const columnDefsBills = [
    //     {
    //         field: 'date',

    //         cellRendererFramework: (params) => <div>
    //             <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
    //         </div>
    //     },
    //     {
    //         field: 'amount',
    //         cellRendererFramework: (params) => <div>
    //             <span><CurrencyFormat value={params.value} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
    //         </div>
    //     },
    //     {
    //         field: 'customer',
    //         cellRendererFramework: (params) => <div>
    //             <span>{params.value}</span>
    //         </div>
    //     },
    //     {
    //         field: "createdBy",
    //         cellRendererFramework: (params) => <div>
    //             {params.value}
    //         </div>
    //     },
    //     {
    //         headerName: 'Action',
    //         field: "_id",

    //         cellRendererFramework: (params) => <div>
    //             {(params.data.createdBy._id === userData.id || userRole === UserRoles.Admin) && <img src={editIcon} onClick={() => { onEditBill(params.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" />}
    //             <img src={viewImg} onClick={() => { setViewBillTog(true); setViewBillId(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
    //         </div>
    //     }
    // ]

    const columnDefsBills = [
        { field: 'name' },
        {
            field: 'amount',
            sortable: true,
            cellRendererFramework: (params) => <div>
                <span><CurrencyFormat value={params.value?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
            </div>
        },
        {
            field: 'pending',
            sortable: true,
            cellRendererFramework: (params) => <div>
                <span><CurrencyFormat value={params.value?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            sortable: false,
            cellRendererFramework: (params) => <div>
                {/* {(params.data._id === userData.id || userRole === UserRoles.Admin) && <img src={viewImg} onClick={() => { navigate(`list?id=${params.value}`) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="View" />} */}
                <img src={viewImg} onClick={() => { navigate(`list?id=${params.value}`) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="View" />
                {/* <img src={viewImg} onClick={() => { setViewBillTog(true); setViewBillId(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' /> */}
            </div>
        }
    ]

    // const NoRolecolumnDefsBills = [
    //     {
    //         field: 'date',

    //         cellRendererFramework: (params) => <div>
    //             <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
    //         </div>
    //     },
    //     {
    //         field: 'amount',
    //         cellRendererFramework: (params) => <div>
    //             <span><CurrencyFormat value={params.value} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
    //         </div>
    //     },
    //     {
    //         field: 'customer',
    //         cellRendererFramework: (params) => <div>
    //             <span>{params.value}</span>
    //         </div>
    //     },
    //     {
    //         field: "createdBy",
    //         cellRendererFramework: (params) => <div>
    //             {params.value}
    //         </div>
    //     },
    //     {
    //         headerName: 'Action',
    //         field: "_id",

    //         cellRendererFramework: (params) => <div>
    //             <img src={viewImg} onClick={() => { setViewBillTog(true); setViewBillId(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="View" alt='' />
    //         </div>
    //     }
    // ]

    const columnDefsFollowup = [
        {
            field: 'date',

            cellRendererFramework: (params) => <div>
                <span>{params.value ? dateFormate(params.value, 'Date') : ''}</span>
            </div>
        },
        {
            field: 'amount',
            cellRendererFramework: (params) => <div>
                <span><CurrencyFormat value={params.value?.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
            </div>
        },
        {
            field: 'customer',
            cellRendererFramework: (params) => <div>
                <span>{params.value}</span>
            </div>
        },
        {
            field: "createdBy",
            cellRendererFramework: (params) => <div>
                {params?.value}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",

            cellRendererFramework: (params) => <div>
                <img src={viewImg} onClick={() => { onEditPayment(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" />
            </div>
        }
    ]

    const onEditBill = (id) => {
        setAddBillEntryTog(true)
        setAddBillEntryId(id)
    }

    const onEditPayment = (id) => {
        setFollowUpTog(true)
        setViewPaymentId(id)
    }

    const refresh = () => {
        // let body = {
        //     customer: filter.customer.map((item) => item.value)
        // }
        // dispatch(getAccountAction(filter.startDate && moment(filter.startDate).format("YYYY-MM-DD"), filter.endDate && moment(filter.endDate).format("YYYY-MM-DD"), filter.billType, body))
        setisFilter(true)
        setTotalFlag(filter.billType)
        if (filter.billType === "Purchase") {
            setBillHeaderName({
                pending: "Pending Payable",
                paid: "Paid Payments"
            })
        } else {
            setBillHeaderName({
                pending: "Pending Receivable",
                paid: "Received Payments"
            })
        }
    }

    const clearFilter = () => {
        setFilter({ startDate: '', endDate: '', customer: [], billType: 'Purchase' })
        // let body = {
        //     customer: []
        // }
        // let sDate = '';
        // let eDate = '';
        // let billType = 'Purchase';
        setisFilter(true)
        // dispatch(getAccountAction(sDate, eDate, billType, body))
        setTotalFlag('Purchase')
        setBillHeaderName({
            pending: "Pending Payable",
            paid: "Paid Payments"
        })
    }

    const handleChange = (e) => {
        setFilter({ ...filter, startDate: e[0], endDate: e[1] })
    }

    useEffect(() => {
        if (addEntryData && addEntryData.data) {
            dispatch({
                type: ADD_BILL_ENTRY,
                payload: []
            })
            refresh()
        }
        else if (editEntryData && editEntryData.data) {
            dispatch({
                type: EDIT_BILL_ENTRY,
                payload: []
            })
            refresh()
        }
    }, [addEntryData, editEntryData])

    const openCalender = (id) => {
        document.getElementById(id).click()
    }

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        // if (RolesForAdd.includes(userRole)) {
        setcolumnDefsBill(columnDefsBills)
        // } else {
        //     setcolumnDefsBill(NoRolecolumnDefsBills)
        // }
        // refresh()
        dispatch(getAllCustomerAction())
        dispatch(getAllAccountCountAction())
    }, [])

    const handleOnExport = () => {
        let body = {
            customer: filter.customer.map((item) => item.value),
            sorted_column: sorting.column_name,
            sorted_order: sorting.column_order
        }
        getAllAccounts(1, 1000, filter?.startDate && moment(filter.startDate).format("YYYY-MM-DD"), filter?.endDate && moment(filter.endDate).format("YYYY-MM-DD"), filter?.billType, body)
            .then(res => {
                if (res.data.inOutData) {
                    const billData = res.data.inOutData.map((item) => {
                        return {
                            Name: item.customerName ? item.customerName : '',
                            Amount: item.totalIn ? item.totalIn : 0,
                            Pending: item.pending ? item.pending : 0
                        }
                    })
                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(billData);
                    XLSX.utils.book_append_sheet(wb, ws1, billHeaderName.pending);
                    XLSX.writeFile(wb, "Account.xlsx");
                }
            })
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (gridApi) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    let body = {
                        customer: filter?.customer ? filter.customer.map((item) => item.value) : [],
                        sorted_column: sorted_column,
                        sorted_order: sorted_order
                    }

                    setSorting({ column_name: sorted_column, column_order: sorted_order });

                    getAllAccounts(page, limit, filter?.startDate && moment(filter.startDate).format("YYYY-MM-DD"), filter?.endDate && moment(filter.endDate).format("YYYY-MM-DD"), filter?.billType, body)
                        .then(res => {
                            setaccountData(res.data)
                            params.successCallback(res?.data?.inOutData.map((ele) => {
                                return {
                                    _id: ele.customerId ? ele.customerId : '',
                                    name: ele.customerName ? ele.customerName : '',
                                    amount: ele.totalIn ? ele.totalIn : 0,
                                    pending: ele?.pending ? ele.pending : 0
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.inOutData?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            setisFilter(false)
            gridApi?.setDatasource(dataSource);
        }

        // setisFilter(false)
    }, [gridApi, limit, isFilter]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <h2>Account</h2>
                    <div>
                        <span>Grand Payable: <span style={{ fontWeight: "600" }}>{accountCountData && accountCountData.data && <CurrencyFormat value={(accountCountData.data.totalPurchaseAmount.toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'₹'} />}</span></span>
                    </div>
                    <div>
                        <span>Grand Receivable: <span style={{ fontWeight: "600" }}>{accountCountData && accountCountData.data && <CurrencyFormat value={(accountCountData.data.totalSaleAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} />}</span></span>
                    </div>
                    <div>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3 mt-3 mt-md-0' onClick={() => setCustomerTog(true)}>Add Customer</button>}
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3 mt-3 mt-md-0' onClick={() => setAddBillEntryTog(true)}>Add Bill Entry</button>}
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3 mt-3 mt-md-0' onClick={() => setPaybleTog(true)}>Add Payble/Receivable</button>}
                        <button className='adduser-btn ms-3 mt-3 mt-md-0' onClick={() => refresh()}>Refresh</button>
                    </div>
                </div>
                <div className='mb-4 bg-white  align-items-center'>
                    <Row>
                        <Col xxl={8}>
                            <Row>
                                <Col xxl={4} xl={6} lg={6}>
                                    <div className="users-form-info date-picker add-remark-modal mx-0">
                                        <div className="users-label enquery-datepicker">
                                            <label>Date</label>
                                            <div className="position-relative select-icon user-type">
                                                <ReactDatePicker id='Remarkdate' dateFormat="dd/MM/yyyy" selectsRange={true} startDate={filter.startDate} endDate={filter.endDate} placeholderText="Select start & end date" onChange={(e) => handleChange(e)} autoComplete='off' />
                                            </div>
                                        </div>
                                        <img src={Datepickericon} onClick={() => openCalender('Remarkdate')} alt="" className='datepickericon' />
                                    </div>
                                </Col>
                                <Col xxl={4} xl={6} lg={6}>
                                    <div className="users-form-info add-remark-modal mx-0">
                                        <div className="multi-select">
                                            <label>Customer</label>
                                            <Select
                                                value={filter.customer}
                                                onChange={(e) => setFilter({ ...filter, customer: e })}
                                                options={customerOption}
                                                isMulti={true}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={4} xl={6} lg={6}>
                                    <div className="users-form-info date-picker add-remark-modal mx-0">
                                        <div className="users-label billType">
                                            <label>Payable/Receivable</label>
                                            <div className="position-relative select-icon user-type me-3">
                                                <img src={img} className={`${rotateArrow && "rotateArrow"}`} width="18px" alt="" style={{ top: 12, right: 12 }} />
                                                <select className="form-control" style={{ width: "max-content", paddingRight: 40 }} value={filter.billType} onBlur={() => { setrotateArrow(false) }} onClick={() => { setrotateArrow(!rotateArrow) }} onChange={(e) => setFilter({ ...filter, billType: e.target.value })}>
                                                    <option value="Purchase">Payable</option>
                                                    <option value="Sale">Receivable</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={4} xl={6} lg={6}>
                            <Row>
                                <Col>
                                    <button className='adduser-btn' style={{ marginTop: "35px" }} onClick={() => refresh()}>Apply Filter</button>
                                    <button className='adduser-btn ms-3' style={{ marginTop: "35px" }} onClick={() => clearFilter()}>Clear Filter</button>
                                    <button className='adduser-btn ms-3' style={{ marginTop: "35px" }} onClick={() => handleOnExport()}>Export</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className='d-flex flex-md-row flex-column'>
                        <div className='amount mt-3'>
                            {totalFlag === "Purchase" && <span style={{ fontSize: '20px' }}>Payable: <span style={{ fontWeight: "600" }}><CurrencyFormat value={(accountData && accountData.totalInAmount - accountData.totalOutAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span></span>}
                            {totalFlag === "Sale" && <span style={{ fontSize: '20px' }}>Receivable: <span style={{ fontWeight: "600" }}><CurrencyFormat value={(accountData && accountData.totalInAmount - accountData.totalOutAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span></span>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <Col lg={12}>
                        {/* <div className='d-flex justify-content-between flex-wrap'>
                            <label className='table-label'>{billHeaderName.pending}</label>
                            <span style={{ fontSize: '20px' }}>Amount : <span style={{ fontWeight: "600" }}>{accountData && accountData.data && <CurrencyFormat value={accountData.data.totalInAmount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />}</span></span>
                        </div> */}
                        {bill &&
                            <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                                <AgGridReact
                                    pagination={true}
                                    columnDefs={columnDefsBill}
                                    rowModelType={"infinite"}
                                    paginationPageSize={limit}
                                    cacheBlockSize={limit}
                                    onGridReady={onGridReady}
                                    rowHeight={45}
                                    defaultColDef={defaultColDef}
                                    overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                                />

                                <div className='set-pagination-dropdaun'>
                                    <p>Show</p>
                                    <select onChange={(e) => setlimit(+e.target.value)} id="page-size" className='mx-3 pagination-select'>
                                        <option value="50">
                                            50
                                        </option>
                                        <option value="100" selected={true}>100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                    </select>
                                    <p>Entries</p>
                                </div>
                            </div>
                        }
                    </Col>
                    {/* <Col lg={6}>
                        <div className='d-flex justify-content-between flex-wrap'>
                            <label className='table-label'>{billHeaderName.pending}</label>
                            <span style={{ fontSize: '20px' }}>Amount : <span style={{ fontWeight: "600" }}>{accountData && accountData.data && <CurrencyFormat value={accountData.data.totalInAmount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />}</span></span>
                        </div>
                        {bill &&
                            <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={bill}
                                    columnDefs={columnDefsBill}
                                    pagination={true}
                                    defaultColDef={defaultColDef}
                                    paginationPageSize={perPage}
                                    style={{ width: "100%" }}
                                    onFirstDataRendered={onFirstDataRendered}
                                    onGridSizeChanged={onGridSizeChanged}
                                />
                                <div className='set-pagination-dropdaun'>
                                    <p>Show</p>
                                    <select onChange={onPageSizeChanged} id="page-size" className='mx-3 pagination-select'>
                                        <option value="10" selected={true}>
                                            10
                                        </option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <p>Entries</p>
                                </div>
                            </div>
                        }
                    </Col>
                    <Col lg={6} className="mt-5 mt-lg-0">
                        <div className='d-flex justify-content-between flex-wrap'>
                            <label className='table-label'>{billHeaderName.paid}</label>
                            <span style={{ fontSize: '20px' }}>Amount : <span style={{ fontWeight: "600" }}>{accountData && accountData.data && <CurrencyFormat value={accountData.data.totalOutAmount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />}</span></span>
                        </div>
                        {followUpPayment &&
                            <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={followUpPayment}
                                    columnDefs={columnDefsFollowup}
                                    pagination={true}
                                    defaultColDef={defaultColDef}
                                    paginationPageSize={perPage}
                                    style={{ width: "100%" }}
                                    onFirstDataRendered={onFirstDataRendered}
                                    onGridSizeChanged={onGridSizeChanged}
                                />
                                <div className='set-pagination-dropdaun'>
                                    <p>Show</p>
                                    <select onChange={onPageSizeChanged} id="page-size" className='mx-3 pagination-select'>
                                        <option value="10" selected={true}>
                                            10
                                        </option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <p>Entries</p>
                                </div>
                            </div>
                        }
                    </Col> */}
                </div>
            </div>
            {addBillEntryTog && <AddBill show={addBillEntryTog} onHide={() => { setAddBillEntryTog(false); setAddBillEntryId(''); }} addBillEntryId={addBillEntryId} />}
            {paybleTog && <AddPaybleReceivable show={paybleTog} onHide={() => { setPaybleTog(false); setCustomerId(''); }} customerId={customerId} />}
            {followUpTog && <FollowUpPayment show={followUpTog} onHide={() => { setFollowUpTog(false); setViewPaymentId('') }} viewPaymentId={viewPaymentId} billTypes={filter.billType} />}
            {customerTog && <AddCustomer show={customerTog} onHide={() => { setCustomerTog(false); setCustomerId('') }} customerId={''} />}
            {/* {viewBillTog && <ViewBill show={viewBillTog} onHide={() => { setViewBillTog(false); setViewBillId('') }} viewBillId={viewBillId} />} */}
        </>
    )
}

export default Account