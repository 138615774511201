import { ADD_TASK_COMMENT, ADD_TASK_COMMENT_ERROR, ADD_TASK_COMMENT_LOADING, DELETE_TASK_COMMENT, DELETE_TASK_COMMENT_ERROR, DELETE_TASK_COMMENT_LOADING, EDIT_TASK_COMMENT, EDIT_TASK_COMMENT_ERROR, EDIT_TASK_COMMENT_LOADING, GET_ALL_TASK_COMMENT, GET_ALL_TASK_COMMENT_ERROR, GET_ALL_TASK_COMMENT_LOADING, GET_COMMENTS_BY_TASK_ID, GET_COMMENTS_BY_TASK_ID_ERROR, GET_COMMENTS_BY_TASK_ID_LOADING, GET_TASK_COMMENT_BY_ID, GET_TASK_COMMENT_BY_ID_ERROR, GET_TASK_COMMENT_BY_ID_LOADING } from "../type";

const addTaskCommentData = {
    loading:false,
    addTaskComment:[],
    error:'',
}

const getAllTaskCommentData = {
    loading:false,
    getAllTaskComment:[],
    error:'',
}

const getTaskCommentByIdData = {
    loading:false,
    getTaskCommentById:[],
    error:'',
}

const getCommentByTaskIdData = {
    loading:false,
    getCommentByTaskId:[],
    error:'',
}

const editTaskCommentData = {
    loading:false,
    editTaskComment:[],
    error:'',
}

const deleteTaskCommentData = {
    loading:false,
    deleteTaskComment:[],
    error:'',
}

export const addTaskCommentReducer = (state = addTaskCommentData, action) => {
    switch (action.type) {
        case ADD_TASK_COMMENT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ADD_TASK_COMMENT:
            return {
                ...state,
                addTaskComment: action.payload,
            };
        case ADD_TASK_COMMENT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getAllTaskCommentReducer = (state = getAllTaskCommentData, action) => {
    switch (action.type) {
        case GET_ALL_TASK_COMMENT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_TASK_COMMENT:
            return {
                ...state,
                getAllTaskComment: action.payload,
            };
        case GET_ALL_TASK_COMMENT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getTaskCommentByIdReducer = (state = getTaskCommentByIdData, action) => {
    switch (action.type) {
        case GET_TASK_COMMENT_BY_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_TASK_COMMENT_BY_ID:
            return {
                ...state,
                getTaskCommentById: action.payload,
            };
        case GET_TASK_COMMENT_BY_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getCommentByTaskIdReducer = (state = getCommentByTaskIdData, action) => {
    switch (action.type) {
        case GET_COMMENTS_BY_TASK_ID_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_COMMENTS_BY_TASK_ID:
            return {
                ...state,
                getCommentByTaskId: action.payload,
            };
        case GET_COMMENTS_BY_TASK_ID_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const editTaskCommentReducer = (state = editTaskCommentData, action) => {
    switch (action.type) {
        case EDIT_TASK_COMMENT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case EDIT_TASK_COMMENT:
            return {
                ...state,
                editTaskComment: action.payload,
            };
        case EDIT_TASK_COMMENT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const deleteTaskCommentReducer = (state = deleteTaskCommentData, action) => {
    switch (action.type) {
        case DELETE_TASK_COMMENT_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case DELETE_TASK_COMMENT:
            return {
                ...state,
                deleteTaskComment: action.payload,
            };
        case DELETE_TASK_COMMENT_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
