import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserAction, getAllUserAction } from '../../redux/actions/userAction';
import AuthStorage from '../../helper/AuthStorage';
import { useNavigate } from 'react-router-dom';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import STORAGEKEY from '../../config/APP/app.config';
import { UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';


const Role = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useContext(RoleContext);
  const [userData, setUserData] = useState([]);
  const [perPage, setPerPage] = useState("10");
  const data = useSelector((state) => state.get_All_User.getAllUser)

  const usrData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

  useEffect(() => {
    setUserData(data.data)
  }, [data])

  useEffect(() => {
    dispatch(getAllUserAction())
  }, [])

  const onEdit = (id) => {
    navigate(`edit?${id}`)
    let token = AuthStorage.getToken()
    dispatch(getAllUserAction(token))
  }

  const onDelete = (id) => {
    dispatch(deleteUserAction(id))

  }

  const columnDefs = [
    { field: 'name' },
    { field: 'Modules' },
    {
      headerName: 'Action',
      field: "_id",

      cellRendererFramework: (params) =>
        <div>
          {(params?.data?.createdBy === usrData.id || userRole === UserRoles.Admin) && <><button className='edit-button' onClick={() => onEdit(params?.value)}> <img src="./assets/image/edit-icon.png" alt="" title="Edit" /> </button>
            <button className='delete-button ms-3' onClick={() => onDelete(params?.value)}> <img src="./assets/image/delete-icon.png" alt="" title="Delete" /> </button></>}
        </div>
    }
  ]

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      width: 'auto',
      filter: true,
      flex: 1
    };
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById('page-size').value;
    setPerPage(value)
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onGridSizeChanged = () => {
    sizeToFit()
  }

  const sizeToFit = useCallback(() => {
    if (gridRef?.current !== undefined) {
      gridRef?.current?.api?.sizeColumnsToFit();
    }
  }, []);

  return (
    <div className='position-relative p-4'>
      <div className='mb-4 set-page-heade'>
        <h2>Role</h2>
        <button className='adduser-btn' onClick={() => { navigate('add') }}>Add Role</button>
      </div>
      {userData &&
        <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>
          <AgGridReact
            ref={gridRef}
            rowData={[]}
            columnDefs={columnDefs}
            pagination={true}
            defaultColDef={defaultColDef}
            style={{ width: "100%" }}
            paginationPageSize={perPage}
            onGridSizeChanged={onGridSizeChanged}
          />
          <div className='set-pagination-dropdaun'>
            <p>Show</p>
            <select onChange={onPageSizeChanged} id="page-size" className='mx-3 pagination-select'>
              <option value="10" selected={true}>
                10
              </option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p>Entries</p>
          </div>
        </div>
        // <PaginationTable columns={columns} data={userData}/>
      }
    </div>
  )
}

export default Role