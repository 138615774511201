import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_SITE_REPORTS, ADD_SITE_REPORTS_ERROR, ADD_SITE_REPORTS_LOADING, DELETE_DAILY_REPORTS, DELETE_DAILY_REPORTS_ERROR, DELETE_DAILY_REPORTS_LOADING, EDIT_SITE_REPORTS_BY_ID, EDIT_SITE_REPORTS_BY_ID_ERROR, EDIT_SITE_REPORTS_BY_ID_LOADING, GET_ALL_SITE_REPORTS, GET_ALL_SITE_REPORTS_ERROR, GET_ALL_SITE_REPORTS_ERROR_NO_AUTH, GET_ALL_SITE_REPORTS_LOADING, GET_ALL_SITE_REPORTS_LOADING_NO_AUTH, GET_ALL_SITE_REPORTS_NO_AUTH, GET_SITE_REPORTS_BY_ID, GET_SITE_REPORTS_BY_ID_ERROR, GET_SITE_REPORTS_BY_ID_LOADING, GET_SITE_REPORTS_BY_PROJECT_ID, GET_SITE_REPORTS_BY_PROJECT_ID_ERROR, GET_SITE_REPORTS_BY_PROJECT_ID_LOADING, GET_SITE_REPORTS_NO_AUTH_BY_ID, GET_SITE_REPORTS_NO_AUTH_BY_ID_ERROR, GET_SITE_REPORTS_NO_AUTH_BY_ID_LOADING, IS_LOADING } from "../type"

export const getAllSiteReportsAction = (projectId="",date="") => async(dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_SITE_REPORTS_LOADING,
            payload: true
        })

        await ApiGet(`daily_site_reports/get_all_site_reports?projectId=${projectId}&date=${date}`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_SITE_REPORTS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_SITE_REPORTS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_SITE_REPORTS_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_SITE_REPORTS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllSiteReportsNoAuthAction = (projectId="") => async(dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_SITE_REPORTS_LOADING_NO_AUTH,
            payload: true
        })

        await ApiGet(`daily_site_reports/get_all_site_reports_no_auth?projectId=${projectId}`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_SITE_REPORTS_NO_AUTH,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_SITE_REPORTS_LOADING_NO_AUTH,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_SITE_REPORTS_ERROR_NO_AUTH,
            payload: err
        })

        dispatch({
            type: GET_ALL_SITE_REPORTS_LOADING_NO_AUTH,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getSiteReportsNoAuthByIdAction = (id) =>async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_SITE_REPORTS_NO_AUTH_BY_ID_LOADING,
            payload: true
        })

       await ApiGet(`daily_site_reports/get_site_reports_by_id_no_auth/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_SITE_REPORTS_NO_AUTH_BY_ID,
                    payload: res
                })
            })

        dispatch({
            type: GET_SITE_REPORTS_NO_AUTH_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_SITE_REPORTS_NO_AUTH_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_SITE_REPORTS_NO_AUTH_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getSiteReportsByIdAction = (id) =>async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_SITE_REPORTS_BY_ID_LOADING,
            payload: true
        })

       await ApiGet(`daily_site_reports/get_site_reports_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_SITE_REPORTS_BY_ID,
                    payload: res
                })
            })

        dispatch({
            type: GET_SITE_REPORTS_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_SITE_REPORTS_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_SITE_REPORTS_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const addSiteReportsAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_SITE_REPORTS_LOADING,
            payload: true
        })

        await ApiPost('daily_site_reports/add_site_reports',body)
            .then((res) => {
                dispatch({
                    type: ADD_SITE_REPORTS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_SITE_REPORTS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_SITE_REPORTS_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_SITE_REPORTS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getSiteReportsByProjectIdAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_SITE_REPORTS_BY_PROJECT_ID_LOADING,
            payload: true
        })

        await ApiGet('daily_site_reports/get_all_site_reports')
            .then((res) => {
                dispatch({
                    type: GET_SITE_REPORTS_BY_PROJECT_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })


        dispatch({
            type: GET_SITE_REPORTS_BY_PROJECT_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_SITE_REPORTS_BY_PROJECT_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_SITE_REPORTS_BY_PROJECT_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editSiteReportsByIdAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_SITE_REPORTS_BY_ID_LOADING,
            payload: true
        })

        await ApiPut('daily_site_reports/edit_site_reports',body)
            .then((res) => {
                dispatch({
                    type: EDIT_SITE_REPORTS_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })


        dispatch({
            type: EDIT_SITE_REPORTS_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_SITE_REPORTS_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_SITE_REPORTS_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteSiteReportsAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_DAILY_REPORTS_LOADING,
            payload: true
        })

        await ApiDelete(`daily_site_reports/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_DAILY_REPORTS,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_DAILY_REPORTS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_DAILY_REPORTS_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_DAILY_REPORTS_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}