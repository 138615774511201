import { ApiPost } from "../../helper/API/ApiData"
import { FILE_REMOVE, FILE_REMOVE_ERROR, FILE_REMOVE_LOADING, FILE_UPLOAD, FILE_UPLOAD_ERROR, FILE_UPLOAD_LOADING, IS_LOADING } from "../type"

export const fileUploadAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: FILE_UPLOAD_LOADING,
            payload: true
        })

        await ApiPost('general/file_upload',body)
            .then((res) => {
                dispatch({
                    type: FILE_UPLOAD,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: FILE_UPLOAD_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: FILE_UPLOAD_ERROR,
            payload: err
        })

        dispatch({
            type: FILE_UPLOAD_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const fileRemoveAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: FILE_REMOVE_LOADING,
            payload: true
        })

        await ApiPost('general/file_remove',body)
            .then((res) => {
                dispatch({
                    type: FILE_REMOVE,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: FILE_REMOVE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: FILE_REMOVE_ERROR,
            payload: err
        })

        dispatch({
            type: FILE_REMOVE_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}