import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_PURCHASE_REQUEST, ADD_PURCHASE_REQUEST_ERROR, ADD_PURCHASE_REQUEST_LOADING, DELETE_PURCHASE_REQUEST, DELETE_PURCHASE_REQUEST_ERROR, DELETE_PURCHASE_REQUEST_LOADING, EDIT_PURCHASE_REQUEST, EDIT_PURCHASE_REQUEST_ERROR, EDIT_PURCHASE_REQUEST_LOADING, GET_ALL_PURCHASE_REQUEST, GET_ALL_PURCHASE_REQUEST_ERROR, GET_ALL_PURCHASE_REQUEST_LOADING, GET_PURCHASE_REQUEST_BY_ID, GET_PURCHASE_REQUEST_BY_ID_ERROR, GET_PURCHASE_REQUEST_BY_ID_LOADING, GET_PURCHASE_REQUEST_BY_PROJECT, GET_PURCHASE_REQUEST_BY_PROJECT_ERROR, GET_PURCHASE_REQUEST_BY_PROJECT_LOADING, IS_LOADING } from "../type"

export const addPurchaseRequestAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_PURCHASE_REQUEST_LOADING,
            payload: true
        })

        await ApiPost(`purchase_request/add_purchase_request`, body)
            .then((res) => {
                dispatch({
                    type: ADD_PURCHASE_REQUEST,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_PURCHASE_REQUEST_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_PURCHASE_REQUEST_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_PURCHASE_REQUEST_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getPurchaseRequestByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PURCHASE_REQUEST_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`purchase_request/get_purchase_request_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_PURCHASE_REQUEST_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_PURCHASE_REQUEST_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PURCHASE_REQUEST_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PURCHASE_REQUEST_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getPurchaseRequestByProjectAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_PURCHASE_REQUEST_BY_PROJECT_LOADING,
            payload: true
        })

        await ApiGet(`purchase_request/get_purchase_request_by_project/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_PURCHASE_REQUEST_BY_PROJECT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_PURCHASE_REQUEST_BY_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_PURCHASE_REQUEST_BY_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_PURCHASE_REQUEST_BY_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllPurchaseRequestAction = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_PURCHASE_REQUEST_LOADING,
            payload: true
        })

        await ApiGet(`purchase_request/get_all_purchase_request`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_PURCHASE_REQUEST,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_PURCHASE_REQUEST_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_PURCHASE_REQUEST_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_PURCHASE_REQUEST_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editPurchaseRequestAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_PURCHASE_REQUEST_LOADING,
            payload: true
        })

        await ApiPut(`purchase_request/edit_purchase_request`,body)
            .then((res) => {
                dispatch({
                    type: EDIT_PURCHASE_REQUEST,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_PURCHASE_REQUEST_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_PURCHASE_REQUEST_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_PURCHASE_REQUEST_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deletePurchaseRequestAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_PURCHASE_REQUEST_LOADING,
            payload: true
        })

        await ApiDelete(`purchase_request/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_PURCHASE_REQUEST,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_PURCHASE_REQUEST_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_PURCHASE_REQUEST_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_PURCHASE_REQUEST_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}