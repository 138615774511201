import React, { useEffect } from 'react'
import Chart from 'react-google-charts'
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';

const ViewBarChart = (props) => {
    const { title, data, range, rangeOptions, loader, initialRangeVal, RangeValMethod } = props;

    const Options = {
        titlePosition: 'none',
        width: '100%',
        height: 'auto',
        legend: { position: 'none' },
        vAxis: {
            format: 'none',
        },
        viewWindowMode: 'explicit',
        viewWindow: { min: 0, max: 'auto' }
    }

    return (
        <div className='chart-div'>
            {!loader ?
                <>
                    <div className='d-flex justify-content-between w-100 round-chart'>
                        <span className='chart-title' style={{ fontSize: "20px" }}>{`${title ?? 'Bar Chart'}`}</span>
                        {range && <div className="select-chart mb-2">
                            <label className="me-2">{range}: </label>
                            <Select className="select-year"
                                value={initialRangeVal}
                                onChange={RangeValMethod}
                                options={rangeOptions}
                            />
                        </div>
                        }
                    </div>
                    <div className='d-flex w-100 chart'>
                        <Chart
                            width={'100%'}
                            height={range ? '205px' : '225px'}
                            chartType="Bar"
                            loader={<div></div>}
                            data={data}
                            options={Options}
                        />
                    </div>
                </>
                :
                <Oval
                    ariaLabel="loading-indicator"
                    height={35}
                    width={35}
                    strokeWidth={5}
                    strokeWidthSecondary={3}
                    color="blue"
                    secondaryColor="white"
                />
            }
        </div>
    )
}
export default ViewBarChart