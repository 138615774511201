import React from 'react'
import { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'

const PaymentAmmountConfirmation = ({ show, onHide, ondownloadprint }) => {

    const [showAmmount, setShowAmmount] = useState(false)

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                centered
                size="md"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Payment Ammount Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className='payment-ammount-confirmation-body'>
                    <h4 className='text-center' style={{ width: '60%', margin: '0 auto' }}>are you want to show product amount?</h4>
                    <Form className='status-switch mt-3'>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            onChange={() => showAmmount ? setShowAmmount(false) : setShowAmmount(true)}
                            style={{ height: '42px' }}
                        // label="Check this switch"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='adduser-btn' onClick={() => { ondownloadprint(showAmmount); }}>Print</button>
                    <button className='cancel-button' onClick={onHide}>Cancel</button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default PaymentAmmountConfirmation