import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getContractorByIdAction } from '../../redux/actions/contractorAction'

const ViewContractor = ({ show, onHide, viewId }) => {

    const dispatch = useDispatch()

    const [contractor, setContractor] = useState({
        name: "",
        mobileNo: "",
        adhaarNo: "",
        documentFile: "",
        bank_name: "",
        account_number: "",
        ifsc: "",
        pan_number: "",
    });

    const getContractorByIdData = useSelector((state) => state.get_Contractor_By_Id.getContractorById)

    useEffect(() => {
        if (viewId) {
            dispatch(getContractorByIdAction(viewId))
        }
    }, [viewId])

    useEffect(() => {
        if (getContractorByIdData && getContractorByIdData.data && viewId) {
            setContractor({
                name: getContractorByIdData.data.name,
                mobileNo: getContractorByIdData.data.mobileNo,
                adhaarNo: getContractorByIdData.data.adhaarNo,
                documentFile: getContractorByIdData.data.documentFile,
                bank_name: getContractorByIdData.data.bank_name,
                account_number: getContractorByIdData.data.account_number,
                ifsc: getContractorByIdData.data.ifsc,
                pan_number: getContractorByIdData.data.pan_number,
            })
        }
    }, [getContractorByIdData])

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Contractor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Name</td>
                                    <td>{contractor.name ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td>Mobile No</td>
                                    <td>{contractor?.mobileNo ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td>Bank Name</td>
                                    <td>{contractor?.bank_name ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td>Account Number</td>
                                    <td>{contractor?.account_number ?? '-'}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-product-modal'>
                                <tr>
                                    <td>Adhaar No</td>
                                    <td>{contractor.adhaarNo ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td>IFSC</td>
                                    <td>{contractor?.ifsc ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td>PAN Number</td>
                                    <td>{contractor?.pan_number ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td>Document&nbsp;File</td>
                                    <td>{contractor.documentFile ? contractor.documentFile.split('/')[1] : '-'}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewContractor