import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addCustomerAction, editCustomerAction, getAllCustomerAction, getCustomerByIdAction } from '../../redux/actions/customerAction'
import img from "../../assets/image/select.png";
import verifyImg from "../../assets/image/check1.png";
import notVerifyImg from "../../assets/image/close.png";
import { ADD_CUSTOMER, EDIT_CUSTOMER } from '../../redux/type';
import { toast } from "react-toastify";

const AddCustomer = ({ show, onHide, customerId }) => {

    const dispatch = useDispatch()
    const error = "GSTIN Already Exists.";
    const toastUpdate = () => toast.success("Customer update successfully");
    const toastAdd = () => toast.success("Customer added successfully");
    const toastErr = () => toast.error(error);
    const [customer, setCustomer] = useState({
        gstin: "",
        registered: "option2",
        accountName: "",
        address: "",
        shippingAddress: "",
        contactPersonName: "",
        contactPersonNumber: "",
        userType: "",
    })
    let reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const [rotateArrow, setrotateArrow] = useState(false);
    const [formErrors, setFormErrors] = useState()
    const [verifyTog, setVerifyTog] = useState()
    const customerData = useSelector((state) => state.get_Customer_By_Id.getCustomerById)
    const addData = useSelector((state) => state.add_Customer.addCustomer)
    const editData = useSelector((state) => state.edit_Customer.editCustomer)

    useEffect(() => {
        if (customerId !== "") {
            dispatch(getCustomerByIdAction(customerId))
        }
    }, [customerId])

    useEffect(() => {
        if (customerData && customerData.data && customerId) {
            setCustomer({
                gstin: customerData.data.gstin,
                accountName: customerData.data.accountName,
                address: customerData.data.address,
                shippingAddress: customerData.data.shippingAddress,
                contactPersonName: customerData.data.contactPersonName,
                contactPersonNumber: customerData.data.contactPersonNumber,
                userType: customerData.data.userType,
                registered: customerData.data.registered === true ? "option1" : "option2",
            })
            setVerifyTog(reg.test(customerData.data.gstin) ? true : false)
        }
    }, [customerData])

    const handleChange = (e, name) => {
        let re = /^[0-9\b]+$/;
        let gstval = /^[A-Z0-9 ]+$/;
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "contactPersonNumber") {
            if (e.target.value === '' || re.test(e.target.value)) {
                setCustomer({ ...customer, [name]: e.target.value })
            }
        } else if (name === "gstin") {
            if (e.target.value === '' || gstval.test(e.target.value)) {
                setCustomer({ ...customer, [name]: e.target.value })
                if (reg.test(e.target.value)) {
                    setVerifyTog(true)
                } else {
                    setVerifyTog(false)
                }
            }
        } else if (name === "userType") {
            setCustomer({ ...customer, [name]: e.target.value })
            if (e.target.value === "Supplier") {
                setFormErrors({ ...formErrors, shippingAddress: '' })
            }
        } else {
            setCustomer({ ...customer, [name]: e.target.value })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        if (!customer.gstin && customer.registered === "option1") {
            error.gstin = "Please enter  GSTIN"
            flag = true
        }
        if (!verifyTog && customer.gstin && customer.registered === "option1") {
            error.gstin = "Please enter valid GSTIN"
            flag = true
        }
        if (!customer.accountName) {
            error.accountName = "Please enter account name"
            flag = true
        }
        if (!customer.address) {
            error.address = "Please enter address"
            flag = true
        }
        if (customer.userType !== 'Supplier' && !customer.shippingAddress) {
            error.shippingAddress = "Please enter shipping address"
            flag = true
        }
        if (!customer.contactPersonName) {
            error.contactPersonName = "Please enter contact person name"
            flag = true
        }
        if (!customer.contactPersonNumber) {
            error.contactPersonNumber = "Please enter contact person number"
            flag = true
        }
        if (customer.contactPersonNumber && customer.contactPersonNumber.length < 10) {
            error.contactPersonNumber = "Please enter valid contact person number"
            flag = true
        }
        if (!customer.userType) {
            error.userType = "Please select user type"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const Save = () => {
        if (validation()) {
            return
        }
        const body = {
            gstin: customer.gstin ? customer.gstin : "",
            registered: customer.registered === "option1" ? true : false,
            accountName: customer.accountName ? customer.accountName : "",
            address: customer.address ? customer.address : "",
            shippingAddress: customer.shippingAddress ? customer.shippingAddress : "",
            contactPersonName: customer.contactPersonName ? customer.contactPersonName : "",
            contactPersonNumber: customer.contactPersonNumber ? customer.contactPersonNumber : "",
            userType: customer.userType ? customer.userType : "",
        }
        // if (verifyTog) {
        dispatch(addCustomerAction(body))

        // }
    }

    useEffect(() => {
        if (addData && addData.message === error) {
            dispatch({
                type: ADD_CUSTOMER,
                payload: []
            })
            toastErr(true)
        } else if (addData && addData.data) {
            if (addData.message === "Customer created successfully.") {
                dispatch({
                    type: ADD_CUSTOMER,
                    payload: []
                })
                // dispatch(getAllCustomerAction())
                toastAdd(true)
                onHide()
            }
        }
    }, [addData])

    const Edit = () => {
        if (validation()) {
            return
        }
        let data = {
            gstin: customer.gstin ? customer.gstin : "",
            registered: customer.registered === "option1" ? true : false,
            accountName: customer.accountName ? customer.accountName : "",
            address: customer.address ? customer.address : "",
            shippingAddress: customer.shippingAddress ? customer.shippingAddress : "",
            contactPersonName: customer.contactPersonName ? customer.contactPersonName : "",
            contactPersonNumber: customer.contactPersonNumber ? customer.contactPersonNumber : "",
            userType: customer.userType ? customer.userType : "",
            id: customerId
        }
        // if (verifyTog) {
        dispatch(editCustomerAction(data))
        // }
    }

    useEffect(() => {
        if (editData && editData.data && editData.data.message === error) {
            dispatch({
                type: EDIT_CUSTOMER,
                payload: []
            })
            toastErr(true)
        } else if (editData && editData.data) {
            if (editData.data.message === "Customer updated successfully.") {
                dispatch({
                    type: EDIT_CUSTOMER,
                    payload: []
                })
                // dispatch(getAllCustomerAction())
                toastUpdate(true)
                onHide()
            }
        }
    }, [editData])

    const handleRadioChange = (e) => {
        setCustomer({ ...customer, registered: e.target.value })
    };
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{customerId ? "Edit Customer" : "Add Customer"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={12}>
                        <div className="d-flex radio-button">
                            <div className='d-flex registered-button'>
                                <label>GST</label>
                                <input
                                    type="radio"
                                    value="option1"
                                    checked={customer.registered === 'option1'}
                                    onChange={handleRadioChange}
                                />
                            </div>
                            <div className='d-flex ms-4 registered-button'>
                                <label>NON GST</label>
                                <input
                                    type="radio"
                                    value="option2"
                                    checked={customer.registered === 'option2'}
                                    onChange={handleRadioChange}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {customer.registered === "option1" && <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>GSTIN</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter GSTIN"
                                    name="gstin"
                                    value={customer.gstin}
                                    onChange={(e) => handleChange(e, 'gstin')}
                                    autoComplete="off"
                                />
                                {verifyTog ? <img src={verifyImg} height='15px' style={{ marginLeft: "3px", marginTop: '10px' }} alt="" /> : <img src={notVerifyImg} style={{ marginLeft: "3px", marginTop: '10px' }} height='15px' alt="" />}
                            </div>
                            {formErrors?.gstin && <span style={{ color: "red" }}>{formErrors.gstin}</span>}
                        </div>
                    </Col>}
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Account Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter account name"
                                    name="accountName"
                                    value={customer.accountName}
                                    onChange={(e) => handleChange(e, 'accountName')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.accountName && <span style={{ color: "red" }}>{formErrors.accountName}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter address"
                                    name="address"
                                    value={customer.address}
                                    onChange={(e) => handleChange(e, 'address')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.address && <span style={{ color: "red" }}>{formErrors.address}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Shipping Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter shipping address"
                                    name="shippingAddress"
                                    value={customer.shippingAddress}
                                    onChange={(e) => handleChange(e, 'shippingAddress')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.shippingAddress && <span style={{ color: "red" }}>{formErrors.shippingAddress}</span>}
                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Contact Person Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter contact person name"
                                    name="contactPersonName"
                                    value={customer.contactPersonName}
                                    onChange={(e) => handleChange(e, 'contactPersonName')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.contactPersonName && <span style={{ color: "red" }}>{formErrors.contactPersonName}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Contact Person Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter contact person number"
                                    name="contactPersonNumber"
                                    value={customer.contactPersonNumber}
                                    onChange={(e) => handleChange(e, 'contactPersonNumber')}
                                    autoComplete="off"
                                    maxLength={10}
                                />
                            </div>
                            {formErrors?.contactPersonNumber && <span style={{ color: "red" }}>{formErrors.contactPersonNumber}</span>}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>User Type</label>
                                <div className="position-relative select-icon user-type">
                                    <img src={img} className={`${rotateArrow && "rotateArrow"}`} alt="" />
                                    <select className="form-control " value={customer.userType} onBlur={() => { setrotateArrow(false) }} onClick={() => { setrotateArrow(!rotateArrow) }} onChange={(e) => handleChange(e, "userType")}>
                                        <option value="">Select usertype</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Supplier">Supplier</option>
                                    </select>
                                </div>
                            </div>
                            {formErrors?.userType && <span style={{ color: "red" }}>{formErrors.userType}</span>}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { customerId ? Edit() : Save() }}>{customerId ? "Edit" : "Save"}</button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>

        </Modal>
    )
}

export default AddCustomer