import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { addTermAndConditionAction, editTermAndConditionAction } from '../../redux/actions/termAndCondition';

const Term = ({ show, onHide }) => {

    const dispatch = useDispatch()
    const [term, setTerm] = useState('')
    const [error, seterror] = useState('')
    useEffect(() => {
        if (show.id) {
            setTerm(show.data)
        }
    }, [show])

    const Save = () => {
        if (!term) {
            seterror('please Enter Condition')
        } else if (show.id) {
            const body = {
                termAndCondition: term,
                id: show.id
            }
            dispatch(editTermAndConditionAction(body))
            onHide()

        } else {
            const body = {
                termAndCondition: term
            }
            dispatch(addTermAndConditionAction(body))
            onHide()
        }
    }
    return (
        <Modal
            show={show.open}
            onHide={onHide}
            centered
            size="lg"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Term And Condition"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Term And Condition</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Term And Condition"
                                    name="gstin"
                                    value={term}
                                    rows={4}
                                    onChange={(e) => {
                                        setTerm(e.target.value)
                                        if (e.target.value) {
                                            seterror('')
                                        }
                                    }}
                                    autoComplete="off"
                                />
                            </div>
                            {error && <span style={{ color: "red" }}>{error}</span>}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { Save() }}>{"Save"}</button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>

        </Modal>
    )
}

export default Term