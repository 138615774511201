import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_ENQUIRY, ADD_ENQUIRY_ERROR, ADD_ENQUIRY_LOADING, APPROVAL_FOR_ADMIN_ENQUIRY, APPROVAL_FOR_ADMIN_ENQUIRY_ERROR, APPROVAL_FOR_ADMIN_ENQUIRY_LOADING, DELETE_ENQUIRY, DELETE_ENQUIRY_ERROR, DELETE_ENQUIRY_LOADING, EDIT_ENQUIRY, EDIT_ENQUIRY_ERROR, EDIT_ENQUIRY_LOADING, GET_ALL_ENQUIRY, GET_ALL_ENQUIRY_ERROR, GET_ALL_ENQUIRY_LOADING, GET_ENQUIRY_BY_ID, GET_ENQUIRY_BY_ID_ERROR, GET_ENQUIRY_BY_ID_LOADING, IS_LOADING, REJECT_ENQUIRY, REJECT_ENQUIRY_ERROR, REJECT_ENQUIRY_LOADING } from "../type"

export const addEnquiryAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_ENQUIRY_LOADING,
            payload: true
        })

        await ApiPost('enquiry/add_enquiry', body)
            .then((res) => {
                dispatch({
                    type: ADD_ENQUIRY,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_ENQUIRY_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllEnquiryAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_ENQUIRY_LOADING,
            payload: true
        })

        await ApiGet('enquiry/get_all_enquiries')
            .then((res) => {
                dispatch({
                    type: GET_ALL_ENQUIRY,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_ENQUIRY_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getEnquiryByIdAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ENQUIRY_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`enquiry/get_enquiry_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_ENQUIRY_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ENQUIRY_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ENQUIRY_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ENQUIRY_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editEnquiryAction = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_ENQUIRY_LOADING,
            payload: true
        })


        await ApiPut('enquiry/edit_enquiry', body)
            .then((res) => {
                dispatch({
                    type: EDIT_ENQUIRY,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_ENQUIRY_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const approvalForAdminEnquiryAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: APPROVAL_FOR_ADMIN_ENQUIRY_LOADING,
            payload: true
        })


        await ApiGet(`enquiry/approval_for_admin/${id}`)
            .then((res) => {
                dispatch({
                    type: APPROVAL_FOR_ADMIN_ENQUIRY,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: APPROVAL_FOR_ADMIN_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: APPROVAL_FOR_ADMIN_ENQUIRY_ERROR,
            payload: err
        })

        dispatch({
            type: APPROVAL_FOR_ADMIN_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteEnquiryAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_ENQUIRY_LOADING,
            payload: true
        })

        await ApiDelete(`enquiry/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_ENQUIRY,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_ENQUIRY_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const rejectEnquiryAction = (id, body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: REJECT_ENQUIRY_LOADING,
            payload: true
        })

        await ApiPut(`enquiry/reject_enquiry/${id}`, body)
            .then((res) => {
                dispatch({
                    type: REJECT_ENQUIRY,
                    payload: res
                })
                dispatch(getAllEnquiryAction())
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: REJECT_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: REJECT_ENQUIRY_ERROR,
            payload: err
        })

        dispatch({
            type: REJECT_ENQUIRY_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}