import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProjectAction } from '../../redux/actions/projectAction'
import { getPurchaseRequestByIdAction } from '../../redux/actions/purchaseRequest'

const ViewPurchaseRequest = ({ show, onHide, PRId }) => {

    const dispatch = useDispatch()

    const [purchaseRequest, setPurchaseRequest] = useState({
        purchaseRequestNumber: '',
        projectId: '',
        projectType: '',
        status: '',
        remark: '',
    })
    const [items, setItems] = useState([
        {
            productId: '',
            reqQuantity: '',
            priority: '',
            unit: '',
            desc: '',
            make: '',
            deliveredQuantity: '',
        }
    ])

    const getPurchaseRequestByIdData = useSelector((state) => state.get_Purchase_Request_By_Id.getPurchaseRequestById)
    const getAllProjectData = useSelector((state) => state.get_All_Project.getAllProject.data)

    useEffect(() => {
        let body = '';
        dispatch(getAllProjectAction(body))
    }, [])

    useEffect(() => {
        if (PRId) {
            dispatch(getPurchaseRequestByIdAction(PRId))
        }
    }, [PRId])

    useEffect(() => {
        if (getPurchaseRequestByIdData && getPurchaseRequestByIdData.data && getAllProjectData && PRId) {
            setPurchaseRequest({
                purchaseRequestNumber: getPurchaseRequestByIdData.data?.purchaseRequestNumber,
                projectId: selectValue(getPurchaseRequestByIdData.data.project, "project"),
                projectType: selectValue(getPurchaseRequestByIdData.data.project, "projectType"),
                status: getPurchaseRequestByIdData.data.status,
                remark: getPurchaseRequestByIdData.data.remark,
            })
            setItems(getPurchaseRequestByIdData.data.items.map((ele) => {
                return {
                    productId: ele.productName,
                    reqQuantity: ele.reqQuantity,
                    priority: ele.priority,
                    unit: ele.unit,
                    desc: ele.desc,
                    make: ele.make,
                    deliveredQuantity: ele.deliveredQuantity,
                }
            }))
        }
    }, [getPurchaseRequestByIdData])

    const selectValue = (value, name) => {
        if (name === "project") {
            return getAllProjectData.find((ele) => ele._id === value)?.partyName
        } else if (name === "projectType") {
            return getAllProjectData.find((ele) => ele._id === value)?.projectType
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Purchase Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-pr-modal'>
                                <tr>
                                    <td>Purchase Request Number</td>
                                    <td>{purchaseRequest?.purchaseRequestNumber}</td>
                                </tr>
                                <tr>
                                    <td>Project Name</td>
                                    <td>{purchaseRequest?.projectId}</td>
                                </tr>
                                <tr>
                                    <td>Project Type</td>
                                    <td>{purchaseRequest?.projectType}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-pr-modal'>
                                <tr>
                                    <td>Status</td>
                                    <td>{purchaseRequest?.status}</td>
                                </tr>
                                <tr>
                                    <td>Remark</td>
                                    <td className='text-break'>{purchaseRequest?.remark}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="">
                        <h2 className="Steps-header">Product lists</h2>
                    </Col>
                </Row>
                <table className='table viewer-project-pr-steps-table'>
                    <tr>
                        <th>Sr</th>
                        <th>Product</th>
                        <th>Req Qty</th>
                        <th>Delv Qty</th>
                        <th>Priority</th>
                        <th>Unit</th>
                        <th>Description</th>
                        <th>make</th>
                    </tr>
                    {items.map((item, i) =>
                        <tr key={i}>
                            <td className='vertical-top'>{i + 1}</td>
                            <td className='vertical-top'>{item.productId}</td>
                            <td className='vertical-top'>{item.reqQuantity}</td>
                            <td className='vertical-top'>{item.deliveredQuantity}</td>
                            <td className='vertical-top'>{item.priority}</td>
                            <td className='vertical-top'>{item.unit}</td>
                            <td className='vertical-top'>{item.desc}</td>
                            <td className='vertical-top'>{item.make}</td>
                        </tr>

                    )}

                </table>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewPurchaseRequest