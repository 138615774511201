import React, { useContext, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom'
import AuthStorage from '../helper/AuthStorage'
import Login from './login/Login'
import Dashboard from './dashboard/Dashboard'
import Enquiry from './enquiry/Enquiry'
import Project from './project/Project'
import Dailyreport from './daily-report/Dailyreport'
import Noc from './noc/Noc'
import UsersOutlet from './users/UsersOutlet'
import Users from './users/Users'
import AddUsers from './users/AddUsers'
// import Roles from './roles/Role'
import Setting from './setting/Setting'
import Layout from '../layout/Layout'
import AuthLayout from '../layout/AuthLayout'
import NocOutlet from './noc/NocOutlet'
import NocForm from './noc/NocForm'
import DailyOutlet from './daily-report/DailyOutlet'
import DailySiteReport from './daily-report/DailySiteReport'
import EnquiryOutlet from './enquiry/EnquiryOutlet'
import ApproveEnquiry from './enquiry/ApproveEnquiry'
import RoleOutlet from './roles/RoleOutlet'
import Role from './roles/Role'
import AddRole from './roles/AddRole'
import ProjectOutlet from './project/ProjectOutlet'
import ProjectEdit from './project/ProjectEdit'
import AddEnquiry from './enquiry/AddEnquiry'
import Customer from './customer/Customer'
import 'react-toastify/dist/ReactToastify.css';
import Product from './product/Product'
import Purchase from './purchase/Purchase'
import PurchaseOutlet from './purchase/PurchaseOutlet'
import AddPurchase from './purchase/AddPurchase'
import StockSummary from './stock-summary/StockSummary'
import SaleOutlet from './sales/SaleOutlet'
import Sale from './sales/Sale'
import AddSale from './sales/AddSale'
import Task from './task/Task'
import Account from './account/Account'
import Bank from './bank/Bank'
import { forAuthLayout, forLayout, forOutsideLayouts, UserRoles } from '../helper/Constant'
import { RoleContext } from '../helper/RoleProvider'
import Wrong from './error-pages/Wrong'
import Contractor from './contractor/Contractor'
import PurchaseRequest from './purchase-request-project/PurchaseRequest'
import PurchaseRequestStore from './purchase-request-store/purchaseRequestStore'
import Assets from './assets/Assets'
import MaintenanceApprove from './enquiry/MaintenanceApprove'
import ReturnMaterial from './return-material/ReturnMaterial'
import StaffDailyreport from './staff-daily-report/StaffDailyReport'
import StaffDailyReportOutlet from './staff-daily-report/StaffDailyReportOutlet'
import StaffDailySiteReport from './staff-daily-report/StaffDailySiteReport'
import Projectreport from './project-report/Projectreport'
import AccountOutlet from './account/AccountOutlet'
import AccountByCustomer from './account/AccountByCustomer'
import TermAndCondition from './term-and-condition/termAndCondition'
import ProjectMaterial from './project/ProjectMaterial'

const Index = () => {
    const roleName = useContext(RoleContext);
    const location = useLocation()
    const [isRouteMatch, setIsRouteMatch] = useState(true)

    const AdminsRoutes = [
        { name: "Dashboard", path: "/dashboard", element: <Dashboard />, children: [] },
        { name: "Customer", path: "/customer", element: <Customer />, children: [] },
        { name: "Contractor", path: "/contractor", element: <Contractor />, children: [] },
        { name: "Term & Condition", path: "/termandcondition", element: <TermAndCondition />, children: [] },
        { name: "Product", path: "/product", element: <Product />, children: [] },
        { name: "Task", path: "/task", element: <Task />, children: [] },
        { name: "Purchase Request", path: "/purchaserequest", element: <PurchaseRequest />, children: [] },
        { name: "Purchase Request", path: "/purchaserequests", element: <PurchaseRequestStore />, children: [] },
        {
            name: "Account", path: "/account", element: <AccountOutlet />,
            children: [
                { name: "Account", path: "", element: <Account /> },
                { name: "List", path: "list", element: <AccountByCustomer /> },
            ]
        },
        { name: "Bank", path: "/bank", element: <Bank />, children: [] },
        { name: "Return Material", path: "/returnmaterial", element: <ReturnMaterial />, children: [] },
        // { name: "Payble Receivable", path: "/payble-receivable", element: <PaybleReceivable />, children: [] },
        { name: "Assets", path: "/asset", element: <Assets />, children: [] },
        {
            name: "Purchase", path: "/purchase", element: <PurchaseOutlet />,
            children: [
                { name: "List", path: "", element: <Purchase /> },
                { name: "Add", path: "add", element: <AddPurchase /> },
                { name: "Edit", path: "edit", element: <AddPurchase /> },
            ]
        },
        {
            name: "Sale", path: "/sale", element: <SaleOutlet />,
            children: [
                { name: "List", path: "", element: <Sale /> },
                { name: "Add", path: "add", element: <AddSale /> },
                { name: "Edit", path: "edit", element: <AddSale /> },
            ]
        },
        {
            name: "Sales", path: "/enquiry", element: <EnquiryOutlet />,
            children: [
                { name: "List", path: "", element: <Enquiry /> },
                { name: "Add", path: "add", element: <AddEnquiry /> },
                { name: "Edit", path: "edit", element: <AddEnquiry /> },
                { name: "Approve", path: "approve", element: <ApproveEnquiry /> },
                { name: "Maintenance", path: "maintenance", element: <MaintenanceApprove /> },
            ]
        },
        {
            name: "project", path: "/project", element: <ProjectOutlet />,
            children: [
                { name: "List", path: "", element: <Project /> },
                { name: "Edit", path: "edit", element: <ProjectEdit /> },
                { name: "ProjectMaterial", path: "edit/ProjectMaterial", element: <ProjectMaterial /> },
            ]
        },
        {
            name: "Daily Report", path: "/daily-report", element: <DailyOutlet />,
            children: [
                { name: "List", path: "", element: <Dailyreport /> },
                { name: "Add", path: "add", element: <DailySiteReport /> },
                { name: "Edit", path: "edit", element: <DailySiteReport /> },
            ]
        },
        {
            name: "Staff Daily Report", path: "/staff-daily-report", element: <StaffDailyReportOutlet />,
            children: [
                { name: "List", path: "", element: <StaffDailyreport /> },
                { name: "Add", path: "add", element: <StaffDailySiteReport /> },
                { name: "Edit", path: "edit", element: <StaffDailySiteReport /> },
            ]
        },
        {
            name: "Liasoning", path: "/noc", element: <NocOutlet />,
            children: [
                { name: "List", path: "", element: <Noc /> },
                { name: "Add", path: "add", element: <NocForm /> },
                { name: "Edit", path: "edit", element: <NocForm /> },
                { name: "Counsulting", path: "counsulting", element: <NocForm /> },
            ]
        },
        {
            name: "Users", path: "/users", element: <UsersOutlet />,
            children: [
                { name: "List", path: "", element: <Users /> },
                { name: "Add", path: "add", element: <AddUsers /> },
                { name: "Edit", path: "edit", element: <AddUsers /> },
            ]
        },
        {
            name: "Role", path: "/role", element: <RoleOutlet />,
            children: [
                { name: "List", path: "", element: <Role /> },
                { name: "Add", path: "add", element: <AddRole /> },
                { name: "Edit", path: "edit", element: <AddRole /> },
            ]
        },
        { name: "Stock Summary", path: "/stock-summary", element: <StockSummary />, children: [] },
        { name: "Setting", path: "/setting", element: <Setting />, children: [] },
        { name: "Error", path: "/error", element: <Wrong />, children: [] },
    ]

    const SalesRoutes = [
        { name: "Dashboard", path: "/dashboard", element: <Dashboard />, children: [] },
        { name: "Product", path: "/product", element: <Product />, children: [] },
        { name: "Task", path: "/task", element: <Task />, children: [] },
        { name: "Purchase Request", path: "/purchaserequest", element: <PurchaseRequest />, children: [] },
        { name: "Purchase Request", path: "/purchaserequests", element: <PurchaseRequestStore />, children: [] },
        // { name: "Payble Receivable", path: "/payble-receivable", element: <PaybleReceivable />, children: [] },
        { name: "Assets", path: "/asset", element: <Assets />, children: [] },
        { name: "Return Material", path: "/returnmaterial", element: <ReturnMaterial />, children: [] },
        {
            name: "Purchase", path: "/purchase", element: <PurchaseOutlet />,
            children: [
                { name: "List", path: "", element: <Purchase /> },
                { name: "Add", path: "add", element: <AddPurchase /> },
                { name: "Edit", path: "edit", element: <AddPurchase /> },
            ]
        },
        {
            name: "Sale", path: "/sale", element: <SaleOutlet />,
            children: [
                { name: "List", path: "", element: <Sale /> },
                { name: "Add", path: "add", element: <AddSale /> },
                { name: "Edit", path: "edit", element: <AddSale /> },
            ]
        },
        {
            name: "Sales", path: "/enquiry", element: <EnquiryOutlet />,
            children: [
                { name: "List", path: "", element: <Enquiry /> },
                { name: "Add", path: "add", element: <AddEnquiry /> },
                { name: "Edit", path: "edit", element: <AddEnquiry /> },
                { name: "Approve", path: "approve", element: <ApproveEnquiry /> },
                { name: "Maintenance", path: "maintenance", element: <MaintenanceApprove /> },
            ]
        },
        {
            name: "project", path: "/project", element: <ProjectOutlet />,
            children: [
                { name: "List", path: "", element: <Project /> },
                { name: "Edit", path: "edit", element: <ProjectEdit /> },
            ]
        },
        {
            name: "Daily Report", path: "/daily-report", element: <DailyOutlet />,
            children: [
                { name: "List", path: "", element: <Dailyreport /> },
                { name: "Add", path: "add", element: <DailySiteReport /> },
                { name: "Edit", path: "edit", element: <DailySiteReport /> },
            ]
        },
        {
            name: "Staff Daily Report", path: "/staff-daily-report", element: <StaffDailyReportOutlet />,
            children: [
                { name: "List", path: "", element: <StaffDailyreport /> },
                { name: "Add", path: "add", element: <StaffDailySiteReport /> },
                { name: "Edit", path: "edit", element: <StaffDailySiteReport /> },
            ]
        },
        {
            name: "Liasoning", path: "/noc", element: <NocOutlet />,
            children: [
                { name: "List", path: "", element: <Noc /> },
                { name: "Add", path: "add", element: <NocForm /> },
                { name: "Edit", path: "edit", element: <NocForm /> },
                { name: "Counsulting", path: "counsulting", element: <NocForm /> },
            ]
        },
        { name: "Stock Summary", path: "/stock-summary", element: <StockSummary />, children: [] }
    ]

    const ProjectsRoutes = [
        { name: "Dashboard", path: "/dashboard", element: <Dashboard />, children: [] },
        { name: "Product", path: "/product", element: <Product />, children: [] },
        { name: "Task", path: "/task", element: <Task />, children: [] },
        // { name: "Payble Receivable", path: "/payble-receivable", element: <PaybleReceivable />, children: [] },
        { name: "Purchase Request", path: "/purchaserequest", element: <PurchaseRequest />, children: [] },
        { name: "Purchase Request", path: "/purchaserequests", element: <PurchaseRequestStore />, children: [] },
        { name: "Assets", path: "/asset", element: <Assets />, children: [] },
        { name: "Return Material", path: "/returnmaterial", element: <ReturnMaterial />, children: [] },
        {
            name: "Purchase", path: "/purchase", element: <PurchaseOutlet />,
            children: [
                { name: "List", path: "", element: <Purchase /> },
                { name: "Add", path: "add", element: <AddPurchase /> },
                { name: "Edit", path: "edit", element: <AddPurchase /> },
            ]
        },
        {
            name: "Sale", path: "/sale", element: <SaleOutlet />,
            children: [
                { name: "List", path: "", element: <Sale /> },
                { name: "Add", path: "add", element: <AddSale /> },
                { name: "Edit", path: "edit", element: <AddSale /> },
            ]
        },
        {
            name: "Sales", path: "/enquiry", element: <EnquiryOutlet />,
            children: [
                { name: "List", path: "", element: <Enquiry /> },
                { name: "Add", path: "add", element: <AddEnquiry /> },
                { name: "Edit", path: "edit", element: <AddEnquiry /> },
                { name: "Approve", path: "approve", element: <ApproveEnquiry /> },
                { name: "Maintenance", path: "maintenance", element: <MaintenanceApprove /> },
            ]
        },
        {
            name: "project", path: "/project", element: <ProjectOutlet />,
            children: [
                { name: "List", path: "", element: <Project /> },
                { name: "Edit", path: "edit", element: <ProjectEdit /> },
                { name: "ProjectMaterial", path: "edit/ProjectMaterial", element: <ProjectMaterial /> },
            ]
        },
        {
            name: "Daily Report", path: "/daily-report", element: <DailyOutlet />,
            children: [
                { name: "List", path: "", element: <Dailyreport /> },
                { name: "Add", path: "add", element: <DailySiteReport /> },
                { name: "Edit", path: "edit", element: <DailySiteReport /> },
            ]
        },
        {
            name: "Staff Daily Report", path: "/staff-daily-report", element: <StaffDailyReportOutlet />,
            children: [
                { name: "List", path: "", element: <StaffDailyreport /> },
                { name: "Add", path: "add", element: <StaffDailySiteReport /> },
                { name: "Edit", path: "edit", element: <StaffDailySiteReport /> },
            ]
        },
        {
            name: "Liasoning", path: "/noc", element: <NocOutlet />,
            children: [
                { name: "List", path: "", element: <Noc /> },
                { name: "Add", path: "add", element: <NocForm /> },
                { name: "Edit", path: "edit", element: <NocForm /> },
                { name: "Counsulting", path: "counsulting", element: <NocForm /> },
            ]
        },
        { name: "Stock Summary", path: "/stock-summary", element: <StockSummary />, children: [] }
    ]

    const StoreStockRoutes = [
        { name: "Dashboard", path: "/dashboard", element: <Dashboard />, children: [] },
        { name: "Product", path: "/product", element: <Product />, children: [] },
        { name: "Task", path: "/task", element: <Task />, children: [] },
        // { name: "Payble Receivable", path: "/payble-receivable", element: <PaybleReceivable />, children: [] },
        { name: "Purchase Request", path: "/purchaserequest", element: <PurchaseRequest />, children: [] },
        { name: "Purchase Request", path: "/purchaserequests", element: <PurchaseRequestStore />, children: [] },
        { name: "Assets", path: "/asset", element: <Assets />, children: [] },
        { name: "Return Material", path: "/returnmaterial", element: <ReturnMaterial />, children: [] },
        {
            name: "Purchase", path: "/purchase", element: <PurchaseOutlet />,
            children: [
                { name: "List", path: "", element: <Purchase /> },
                { name: "Add", path: "add", element: <AddPurchase /> },
                { name: "Edit", path: "edit", element: <AddPurchase /> },
            ]
        },
        {
            name: "Sale", path: "/sale", element: <SaleOutlet />,
            children: [
                { name: "List", path: "", element: <Sale /> },
                { name: "Add", path: "add", element: <AddSale /> },
                { name: "Edit", path: "edit", element: <AddSale /> },
            ]
        },
        {
            name: "Sales", path: "/enquiry", element: <EnquiryOutlet />,
            children: [
                { name: "List", path: "", element: <Enquiry /> },
                { name: "Add", path: "add", element: <AddEnquiry /> },
                { name: "Edit", path: "edit", element: <AddEnquiry /> },
                { name: "Approve", path: "approve", element: <ApproveEnquiry /> },
                { name: "Maintenance", path: "maintenance", element: <MaintenanceApprove /> },
            ]
        },
        {
            name: "project", path: "/project", element: <ProjectOutlet />,
            children: [
                { name: "List", path: "", element: <Project /> },
                { name: "Edit", path: "edit", element: <ProjectEdit /> },
            ]
        },
        {
            name: "Daily Report", path: "/daily-report", element: <DailyOutlet />,
            children: [
                { name: "List", path: "", element: <Dailyreport /> },
                { name: "Add", path: "add", element: <DailySiteReport /> },
                { name: "Edit", path: "edit", element: <DailySiteReport /> },
            ]
        },
        {
            name: "Staff Daily Report", path: "/staff-daily-report", element: <StaffDailyReportOutlet />,
            children: [
                { name: "List", path: "", element: <StaffDailyreport /> },
                { name: "Add", path: "add", element: <StaffDailySiteReport /> },
                { name: "Edit", path: "edit", element: <StaffDailySiteReport /> },
            ]
        },
        { name: "Stock Summary", path: "/stock-summary", element: <StockSummary />, children: [] },
    ]

    const AccountsRoutes = [
        { name: "Dashboard", path: "/dashboard", element: <Dashboard />, children: [] },
        { name: "Product", path: "/product", element: <Product />, children: [] },
        { name: "Task", path: "/task", element: <Task />, children: [] },
        {
            name: "Account", path: "/account", element: <AccountOutlet />,
            children: [
                { name: "Account", path: "", element: <Account /> },
                { name: "List", path: "list", element: <AccountByCustomer /> },
            ]
        },
        { name: "Purchase Request", path: "/purchaserequest", element: <PurchaseRequest />, children: [] },
        { name: "Purchase Request", path: "/purchaserequests", element: <PurchaseRequestStore />, children: [] },
        // { name: "Payble Receivable", path: "/payble-receivable", element: <PaybleReceivable />, children: [] },
        { name: "Assets", path: "/asset", element: <Assets />, children: [] },
        { name: "Return Material", path: "/returnmaterial", element: <ReturnMaterial />, children: [] },
        {
            name: "Purchase", path: "/purchase", element: <PurchaseOutlet />,
            children: [
                { name: "List", path: "", element: <Purchase /> },
                { name: "Add", path: "add", element: <AddPurchase /> },
                { name: "Edit", path: "edit", element: <AddPurchase /> },
            ]
        },
        {
            name: "Sale", path: "/sale", element: <SaleOutlet />,
            children: [
                { name: "List", path: "", element: <Sale /> },
                { name: "Add", path: "add", element: <AddSale /> },
                { name: "Edit", path: "edit", element: <AddSale /> },
            ]
        },
        {
            name: "Sales", path: "/enquiry", element: <EnquiryOutlet />,
            children: [
                { name: "List", path: "", element: <Enquiry /> },
                { name: "Add", path: "add", element: <AddEnquiry /> },
                { name: "Edit", path: "edit", element: <AddEnquiry /> },
                { name: "Approve", path: "approve", element: <ApproveEnquiry /> },
                { name: "Maintenance", path: "maintenance", element: <MaintenanceApprove /> },
            ]
        },
        {
            name: "project", path: "/project", element: <ProjectOutlet />,
            children: [
                { name: "List", path: "", element: <Project /> },
                { name: "Edit", path: "edit", element: <ProjectEdit /> },
            ]
        },
        {
            name: "Daily Report", path: "/daily-report", element: <DailyOutlet />,
            children: [
                { name: "List", path: "", element: <Dailyreport /> },
                { name: "Add", path: "add", element: <DailySiteReport /> },
                { name: "Edit", path: "edit", element: <DailySiteReport /> },
            ]
        },
        {
            name: "Staff Daily Report", path: "/staff-daily-report", element: <StaffDailyReportOutlet />,
            children: [
                { name: "List", path: "", element: <StaffDailyreport /> },
                { name: "Add", path: "add", element: <StaffDailySiteReport /> },
                { name: "Edit", path: "edit", element: <StaffDailySiteReport /> },
            ]
        },
        {
            name: "Liasoning", path: "/noc", element: <NocOutlet />,
            children: [
                { name: "List", path: "", element: <Noc /> },
                { name: "Add", path: "add", element: <NocForm /> },
                { name: "Edit", path: "edit", element: <NocForm /> },
                { name: "Counsulting", path: "counsulting", element: <NocForm /> },
            ]
        },
        { name: "Stock Summary", path: "/stock-summary", element: <StockSummary />, children: [] }
    ]

    const LiasoningRoutes = [
        { name: "Dashboard", path: "/dashboard", element: <Dashboard />, children: [] },
        { name: "Product", path: "/product", element: <Product />, children: [] },
        { name: "Task", path: "/task", element: <Task />, children: [] },
        { name: "Purchase Request", path: "/purchaserequest", element: <PurchaseRequest />, children: [] },
        { name: "Purchase Request", path: "/purchaserequests", element: <PurchaseRequestStore />, children: [] },
        // { name: "Payble Receivable", path: "/payble-receivable", element: <PaybleReceivable />, children: [] },
        { name: "Assets", path: "/asset", element: <Assets />, children: [] },
        { name: "Return Material", path: "/returnmaterial", element: <ReturnMaterial />, children: [] },
        {
            name: "Purchase", path: "/purchase", element: <PurchaseOutlet />,
            children: [
                { name: "List", path: "", element: <Purchase /> },
                { name: "Add", path: "add", element: <AddPurchase /> },
                { name: "Edit", path: "edit", element: <AddPurchase /> },
            ]
        },
        {
            name: "Sale", path: "/sale", element: <SaleOutlet />,
            children: [
                { name: "List", path: "", element: <Sale /> },
                { name: "Add", path: "add", element: <AddSale /> },
                { name: "Edit", path: "edit", element: <AddSale /> },
            ]
        },
        {
            name: "project", path: "/project", element: <ProjectOutlet />,
            children: [
                { name: "List", path: "", element: <Project /> },
                { name: "Edit", path: "edit", element: <ProjectEdit /> },
            ]
        },
        {
            name: "Daily Report", path: "/daily-report", element: <DailyOutlet />,
            children: [
                { name: "List", path: "", element: <Dailyreport /> },
                { name: "Add", path: "add", element: <DailySiteReport /> },
                { name: "Edit", path: "edit", element: <DailySiteReport /> },
            ]
        },
        {
            name: "Staff Daily Report", path: "/staff-daily-report", element: <StaffDailyReportOutlet />,
            children: [
                { name: "List", path: "", element: <StaffDailyreport /> },
                { name: "Add", path: "add", element: <StaffDailySiteReport /> },
                { name: "Edit", path: "edit", element: <StaffDailySiteReport /> },
            ]
        },
        {
            name: "Liasoning", path: "/noc", element: <NocOutlet />,
            children: [
                { name: "List", path: "", element: <Noc /> },
                { name: "Add", path: "add", element: <NocForm /> },
                { name: "Edit", path: "edit", element: <NocForm /> },
                { name: "Counsulting", path: "counsulting", element: <NocForm /> },
            ]
        },
        { name: "Stock Summary", path: "/stock-summary", element: <StockSummary />, children: [] }
    ]

    const NoRole = [
        { name: "Error", path: "/error", element: <Wrong />, children: [] },
    ]

    const ViewerRoutes = [
        { name: "Dashboard", path: "/dashboard", element: <Dashboard />, children: [] },
        { name: "Customer", path: "/customer", element: <Customer />, children: [] },
        { name: "Product", path: "/product", element: <Product />, children: [] },
        { name: "Task", path: "/task", element: <Task />, children: [] },
        { name: "Contractor", path: "/contractor", element: <Contractor />, children: [] },
        {
            name: "Account", path: "/account", element: <AccountOutlet />,
            children: [
                { name: "Account", path: "", element: <Account /> },
                { name: "List", path: "list", element: <AccountByCustomer /> },
            ]
        },
        { name: "Bank", path: "/bank", element: <Bank />, children: [] },
        { name: "Purchase Request", path: "/purchaserequest", element: <PurchaseRequest />, children: [] },
        { name: "Purchase Request", path: "/purchaserequests", element: <PurchaseRequestStore />, children: [] },
        // { name: "Payble Receivable", path: "/payble-receivable", element: <PaybleReceivable />, children: [] },
        { name: "Assets", path: "/asset", element: <Assets />, children: [] },
        { name: "Return Material", path: "/returnmaterial", element: <ReturnMaterial />, children: [] },
        {
            name: "Purchase", path: "/purchase", element: <PurchaseOutlet />,
            children: [
                { name: "List", path: "", element: <Purchase /> },
                { name: "Add", path: "add", element: <AddPurchase /> },
                { name: "Edit", path: "edit", element: <AddPurchase /> },
            ]
        },
        {
            name: "Sale", path: "/sale", element: <SaleOutlet />,
            children: [
                { name: "List", path: "", element: <Sale /> },
                { name: "Add", path: "add", element: <AddSale /> },
                { name: "Edit", path: "edit", element: <AddSale /> },
            ]
        },
        {
            name: "Sales", path: "/enquiry", element: <EnquiryOutlet />,
            children: [
                { name: "List", path: "", element: <Enquiry /> },
                { name: "Add", path: "add", element: <AddEnquiry /> },
                { name: "Edit", path: "edit", element: <AddEnquiry /> },
                { name: "Approve", path: "approve", element: <ApproveEnquiry /> },
                { name: "Maintenance", path: "maintenance", element: <MaintenanceApprove /> },
            ]
        },
        {
            name: "project", path: "/project", element: <ProjectOutlet />,
            children: [
                { name: "List", path: "", element: <Project /> },
                { name: "Edit", path: "edit", element: <ProjectEdit /> },
            ]
        },
        {
            name: "Daily Report", path: "/daily-report", element: <DailyOutlet />,
            children: [
                { name: "List", path: "", element: <Dailyreport /> },
                { name: "Add", path: "add", element: <DailySiteReport /> },
                { name: "Edit", path: "edit", element: <DailySiteReport /> },
            ]
        },
        {
            name: "Staff Daily Report", path: "/staff-daily-report", element: <StaffDailyReportOutlet />,
            children: [
                { name: "List", path: "", element: <StaffDailyreport /> },
                { name: "Add", path: "add", element: <StaffDailySiteReport /> },
                { name: "Edit", path: "edit", element: <StaffDailySiteReport /> },
            ]
        },
        {
            name: "Liasoning", path: "/noc", element: <NocOutlet />,
            children: [
                { name: "List", path: "", element: <Noc /> },
                { name: "Add", path: "add", element: <NocForm /> },
                { name: "Edit", path: "edit", element: <NocForm /> },
                { name: "Counsulting", path: "counsulting", element: <NocForm /> },
            ]
        },
        {
            name: "Role", path: "/role", element: <RoleOutlet />,
            children: [
                { name: "List", path: "", element: <Role /> },
                { name: "Add", path: "add", element: <AddRole /> },
                { name: "Edit", path: "edit", element: <AddRole /> },
            ]
        },
        { name: "Stock Summary", path: "/stock-summary", element: <StockSummary />, children: [] },
        { name: "Setting", path: "/setting", element: <Setting />, children: [] },
    ]

    const MaintanceRoutes = [
        { name: "Dashboard", path: "/dashboard", element: <Dashboard />, children: [] },
        { name: "Task", path: "/task", element: <Task />, children: [] },
        { name: "Purchase Request", path: "/purchaserequest", element: <PurchaseRequest />, children: [] },
        {
            name: "project", path: "/project", element: <ProjectOutlet />,
            children: [
                { name: "List", path: "", element: <Project /> },
                { name: "Edit", path: "edit", element: <ProjectEdit /> },
            ]
        },
        {
            name: "Daily Report", path: "/daily-report", element: <DailyOutlet />,
            children: [
                { name: "List", path: "", element: <Dailyreport /> },
                { name: "Add", path: "add", element: <DailySiteReport /> },
                { name: "Edit", path: "edit", element: <DailySiteReport /> },
            ]
        },
        {
            name: "Staff Daily Report", path: "/staff-daily-report", element: <StaffDailyReportOutlet />,
            children: [
                { name: "List", path: "", element: <StaffDailyreport /> },
                { name: "Add", path: "add", element: <StaffDailySiteReport /> },
                { name: "Edit", path: "edit", element: <StaffDailySiteReport /> },
            ]
        },
    ]

    const [roleWiseRoutes, setRoleWiseRoutes] = useState()
    const forLayouts = forLayout
    const forAuthLayouts = forAuthLayout
    const allRoutes = [...forLayout, ...forAuthLayout]

    useEffect(() => {
        if (roleName === UserRoles.Admin) {
            setRoleWiseRoutes(AdminsRoutes)
        } else if (roleName === UserRoles.Account) {
            setRoleWiseRoutes(AccountsRoutes)
        } else if (roleName === UserRoles.Liasoning) {
            setRoleWiseRoutes(LiasoningRoutes)
        } else if (roleName === UserRoles.Project) {
            setRoleWiseRoutes(ProjectsRoutes)
        } else if (roleName === UserRoles.Sales) {
            setRoleWiseRoutes(SalesRoutes)
        } else if (roleName === UserRoles.Store) {
            setRoleWiseRoutes(StoreStockRoutes)
        } else if (roleName === UserRoles.Viewer) {
            setRoleWiseRoutes(ViewerRoutes)
        } else if (roleName === UserRoles.Maintance) {
            setRoleWiseRoutes(MaintanceRoutes)
        } else {
            setRoleWiseRoutes(NoRole)
        }

    }, [roleName]);

    return (
        <>
            {
                forLayouts.includes(location.pathname)
                    ?
                    <Layout>
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/project-report" element={<Projectreport />} />
                        </Routes>
                    </Layout>
                    :

                    forAuthLayouts.includes(location.pathname) &&
                    <AuthLayout>

                        <Routes>
                            {roleWiseRoutes && roleWiseRoutes.map((item, i) => (
                                item.children.length > 0 ? (
                                    <Route path={item.path} element={item.element} key={i} >
                                        {item.children.map((child, j) => (
                                            <Route path={child.path} element={child.element} key={`${i}-${j}`} />))
                                        }
                                    </Route>
                                )
                                    :
                                    (<Route path={item.path} element={item.element} key={i} />)
                            )
                                // <Route path={item.name} element={item.element} />
                            )}
                        </Routes>
                    </AuthLayout>
            }

            {!allRoutes.includes(location.pathname) &&
                <Navigate from="*" to="/dashboard" />
            }

            {!isRouteMatch &&
                <Navigate from="*" to="/dashboard" />
            }

        </>
    )
}

export default Index