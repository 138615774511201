import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import viewImg from '../../assets/image/eye.svg'
import removeImg from '../../assets/image/delete-red.png'
import { DELETE_CONTRACTOR, FILE_UPLOAD } from '../../redux/type';
import { RoleContext } from '../../helper/RoleProvider';
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import AddContractor from '../../components/modals/AddContractor';
import { deleteContractorAction, getAllContractorAction } from '../../redux/actions/contractorAction';
import { API } from '../../config/API/api.config';
import editIcon from '../../assets/image/edit.svg';
import ViewContractor from '../../components/view-modals/ViewContractor';
import PDF from '../../assets/image/uploadPDF.png';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Contractor = () => {
    const gridRef = useRef();
    const dispatch = useDispatch()
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin]
    // const [contractor, setContractor] = useState([]);
    // const [perPage, setPerPage] = useState("10");
    const [reject, setReject] = useState();
    const [paramsValue, setParamsValue] = useState();
    const [contractorTog, setContractorTog] = useState(false);
    const [contractorId, setContractorId] = useState('')
    const [columnDefs, setColumnDefs] = useState([]);
    const [viewTog, setViewTog] = useState(false);
    const [viewId, setViewId] = useState('');
    const { getAllContractor } = usePaginationHooks()
    const [gridApi, setGridApi] = useState(null);
    const [limit, setlimit] = useState(Paginationlimit);
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    const toastSuccess = () => toast.success('Contractor delete successfully');

    const userData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)

    // const allContractor = useSelector((state) => state.get_All_Contractor.getAllContractor)
    const deleteContractorData = useSelector((state) => state.delete_Contractor.deleteContractor)
    const editContractorData = useSelector((state) => state.edit_Contractor.editContractor)
    const addContractorData = useSelector((state) => state.add_Contractor.addContractor)

    // useEffect(() => {
    //     if (allContractor && allContractor.data) {
    //         setContractor(allContractor.data.map((ele) => {
    //             return {
    //                 name: ele.name ? ele.name : '',
    //                 adhaarNo: ele.adhaarNo ? ele.adhaarNo : '',
    //                 mobileNo: ele.mobileNo ? ele.mobileNo : '',
    //                 createdBy: ele.createdBy ? ele.createdBy : '',
    //                 documentFile: ele.documentFile ? ele.documentFile : '',
    //                 _id: ele._id ? ele._id : '',
    //             }
    //         }))
    //     }
    // }, [allContractor])

    const downloadFile = (params) => {
        window.open(`${API?.endpoint}/${params?.data?.documentFile}`, "_blank")
    }

    const columnDef = [
        { field: 'name', minWidth: 150, },
        {
            field: 'adhaarNo',
            minWidth: 150,
            headerName: 'Aadhaar Number'
        },
        { field: 'mobileNo', minWidth: 150, },
        {
            headerName: 'Document Image',
            minWidth: 150,
            field: 'displayDocumentFile',
            sortable: false,
            cellRendererFramework: (params) => {
                let temp = params?.data?.documentFile?.split('.');
                return (
                    <div>
                        {params.data?.documentFile && <button className='display-contractor'
                            onClick={() => { downloadFile(params) }}>
                            {temp[temp.length - 1] === 'pdf' ? <img src={PDF} alt="" width="20" title={params.data?.documentFile ? params.data?.documentFile?.split('/')[1] : ''} /> : <img src={`${API.endpoint}/${params.data?.documentFile}`} alt="" width="20" title={params.data?.documentFile ? params.data?.documentFile?.split('/')[1] : ''} />}
                        </button>}
                    </div>
                )
            }
        },
        {
            field: "createdBy",
            minWidth: 150,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 150,
            sortable: false,
            cellRendererFramework: (params) => <div>
                {/* {(params.data?.createdBy?._id === userData.id || userRole === UserRoles.Admin) && <><img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" />
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Delete" /></>} */}
                {/* <img src={editIcon} onClick={() => { onEdit(params?.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" /> */}
                <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Delete" />
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="View" />
            </div>
        }
    ]

    const NoRolecolumnDef = [
        { field: 'name', minWidth: 150, },
        {
            field: 'adhaarNo',
            minWidth: 150,
            headerName: 'Aadhaar Number'
        },
        { field: 'mobileNo', minWidth: 150, },
        {
            headerName: 'Document Image',
            minWidth: 150,
            field: 'displayDocumentFile',
            sortable: false,
            cellRendererFramework: (params) => {
                let temp = params.data.documentFile.split('.');
                return (
                    <div>
                        {params.data.documentFile && <button className='display-contractor'
                            onClick={() => { downloadFile(params) }}>
                            {temp[temp.length - 1] === 'pdf' ? <img src={PDF} alt="" width="20" title={params.data.documentFile ? params.data.documentFile?.split('/')[1] : ''} /> : <img src={`${API.endpoint}/${params.data.documentFile}`} alt="" width="20" title={params.data.documentFile ? params.data.documentFile?.split('/')[1] : ''} />}
                        </button>}
                    </div>
                )
            }
        },
        {
            field: "createdBy",
            minWidth: 150,
            cellRendererFramework: (params) => <div>
                {params?.value?.name}
            </div>
        },
        {
            headerName: 'Action',
            field: "_id",
            minWidth: 150,
            sortable: false,
            cellRendererFramework: (params) => <div>
                {(params.data.createdBy._id === userData.id) && <>
                {/* <img src={editIcon} onClick={() => { onEdit(params.value) }} style={{ cursor: "pointer", height: '20px' }} alt="" title="Edit" /> */}
                    <img src={removeImg} onClick={() => { setReject(true); setParamsValue(params.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Delete" /></>}
                <img src={viewImg} onClick={() => { setViewTog(true); setViewId(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="View" />
            </div>
        }
    ]

    const onEdit = (id) => {
        setContractorTog(true)
        setContractorId(id)
    }

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id" && params.colDef.field !== "displayDocumentFile") {
            return onEdit(params.data._id);
        }
    }

    const onDelete = (id) => {
        dispatch(deleteContractorAction(id))
        setReject(false)
    } 

    useEffect(() => {
        if (deleteContractorData && deleteContractorData.data) {
            if (deleteContractorData.data.message === "Contractor deleted successfully.") {
                dispatch({
                    type: DELETE_CONTRACTOR,
                    payload: []
                })
                // dispatch(getAllContractorAction())
                toastSuccess(true)
            }
        }
    }, [deleteContractorData])

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: false,
            flex: 1
        };
    }, []);

    useEffect(() => {
        if (RolesForAdd.includes(userRole)) {
            setColumnDefs(columnDef)
        } else {
            setColumnDefs(NoRolecolumnDef)
        }
        dispatch(getAllContractorAction())
    }, [])

    const Hide = () => {
        setContractorTog(false)
        setContractorId('')
        dispatch({
            type: FILE_UPLOAD,
            payload: []
        })
    }

    useEffect(() => {
        if (contractorTog) {
            dispatch({
                type: FILE_UPLOAD,
                payload: []
            })
        }
    }, [contractorTog])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const clearFilter = () => {
        setFilter({ search: '' });
        setSearch('');
    }

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || deleteContractorData?.data?.status === 200 || editContractorData?.data?.status === 200 || addContractorData?.data?.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";
                    getAllContractor(page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            // setgetAllReturnMaterialdata(res.data)
                            params.successCallback(res?.data.map((ele) => {
                                return {    
                                    name: ele.name ? ele.name : '',
                                    adhaarNo: ele.adhaarNo ? ele.adhaarNo : '',
                                    mobileNo: ele.mobileNo ? ele.mobileNo : '',
                                    createdBy: ele.createdBy ? ele.createdBy : '',
                                    documentFile: ele.documentFile ? ele.documentFile : '',
                                    _id: ele._id ? ele._id : '',
                                }
                            }), res.totalCount ? res.totalCount : 0)

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }
                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

        // setisFilter(false)
    }, [gridApi, limit, deleteContractorData, editContractorData, addContractorData, filter.search]);

    return (
        <>
            <div className='position-relative p-4'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>Contractor</h2>
                    </div>
                    <div className='d-flex'>
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button className='adduser-btn ms-3' onClick={() => clearFilter()}>Clear Filter</button>
                        {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => setContractorTog(true)}>Add Contractor</button>}
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                    <AgGridReact
                        pagination={true}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowSelection={'single'}
                        onCellClicked={(event) => {
                            if (RolesForAdd.includes(userRole) || event?.data?.createdBy?._id === userData.id) {
                                handleRowClick(event)
                            }
                        }}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />

                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
            </div>
            {contractorTog && <AddContractor show={contractorTog} onHide={() => Hide()} contractorId={contractorId} />}
            {viewTog && <ViewContractor show={viewTog} onHide={() => { setViewTog(false); setViewId('') }} viewId={viewId} />}
            {reject &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={() => onDelete(paramsValue)}
                    onCancel={() => { setReject(false) }}
                    focusCancelBtn
                >
                    You want to Delete it
                </SweetAlert>
            }
        </>
    )
}

export default Contractor   