import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData"
import { ADD_FINAL_MATERIAL, ADD_FINAL_MATERIAL_ERROR, ADD_FINAL_MATERIAL_LOADING, ADD_FINAL_MATERIAL_SUB_PRODUCT, ADD_FINAL_MATERIAL_SUB_PRODUCT_LOADING, DELETE_FINAL_MATERIAL, DELETE_FINAL_MATERIAL_ERROR, DELETE_FINAL_MATERIAL_LOADING, EDIT_FINAL_MATERIAL, EDIT_FINAL_MATERIAL_ERROR, EDIT_FINAL_MATERIAL_LOADING, GET_ALL_FINAL_MATERIAL, GET_ALL_FINAL_MATERIAL_ERROR, GET_ALL_FINAL_MATERIAL_LOADING, GET_FINAL_MATERIAL_BY_ID, GET_FINAL_MATERIAL_BY_ID_ERROR, GET_FINAL_MATERIAL_BY_ID_LOADING, GET_FINAL_MATERIAL_BY_PARANT_ID, GET_FINAL_MATERIAL_BY_PARANT_ID_ERROR, GET_FINAL_MATERIAL_BY_PARANT_ID_LOADING, GET_FINAL_MATERIAL_BY_PROJECT, GET_FINAL_MATERIAL_BY_PROJECT_ERROR, GET_FINAL_MATERIAL_BY_PROJECT_LOADING, IS_LOADING } from "../type"

export const addFinalMaterial = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_FINAL_MATERIAL_LOADING,
            payload: true
        })

        await ApiPost('final_material/add_final_material', body)
            .then((res) => {
                dispatch({
                    type: ADD_FINAL_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_FINAL_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}
export const addFinalMaterialSubProduct = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: ADD_FINAL_MATERIAL_SUB_PRODUCT_LOADING,
            payload: true
        })

        await ApiPost('final_material/add_product', body)
            .then((res) => {
                dispatch({
                    type: ADD_FINAL_MATERIAL_SUB_PRODUCT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: ADD_FINAL_MATERIAL_SUB_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: ADD_FINAL_MATERIAL_SUB_PRODUCT_ERROR,
            payload: err
        })

        dispatch({
            type: ADD_FINAL_MATERIAL_SUB_PRODUCT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getFinalMaterialById = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_ID_LOADING,
            payload: true
        })

        await ApiGet(`final_material/get_final_material_by_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_FINAL_MATERIAL_BY_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_FINAL_MATERIAL_BY_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}
export const getFinalMaterialByParantId = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_PARANT_ID_LOADING,
            payload: true
        })

        await ApiGet(`final_material/get_final_material_by_parant_id/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_FINAL_MATERIAL_BY_PARANT_ID,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_PARANT_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_FINAL_MATERIAL_BY_PARANT_ID_ERROR,
            payload: err
        })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_PARANT_ID_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getAllFinalMaterial = () => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_ALL_FINAL_MATERIAL_LOADING,
            payload: true
        })

        await ApiGet(`final_material/get_all_final_material`)
            .then((res) => {
                dispatch({
                    type: GET_ALL_FINAL_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_ALL_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_ALL_FINAL_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: GET_ALL_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const editFinalMaterial = (body) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: EDIT_FINAL_MATERIAL_LOADING,
            payload: true
        })

        await ApiPut(`final_material/edit_final_material`, body)
            .then((res) => {
                dispatch({
                    type: EDIT_FINAL_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: EDIT_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: EDIT_FINAL_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: EDIT_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const deleteFinalMaterial = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: DELETE_FINAL_MATERIAL_LOADING,
            payload: true
        })

        await ApiDelete(`final_material/delete/${id}`)
            .then((res) => {
                dispatch({
                    type: DELETE_FINAL_MATERIAL,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: DELETE_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: DELETE_FINAL_MATERIAL_ERROR,
            payload: err
        })

        dispatch({
            type: DELETE_FINAL_MATERIAL_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}

export const getFinalMaterialByProject = (id) => async (dispatch) => {
    try {
        dispatch({
            type: IS_LOADING,
            payload: true
        })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_PROJECT_LOADING,
            payload: true
        })

        await ApiGet(`final_material/get_final_material_by_projectId/${id}`)
            .then((res) => {
                dispatch({
                    type: GET_FINAL_MATERIAL_BY_PROJECT,
                    payload: res
                })
            }).catch((error) => {
                console.log(error);
            })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
    catch (err) {

        dispatch({
            type: GET_FINAL_MATERIAL_BY_PROJECT_ERROR,
            payload: err
        })

        dispatch({
            type: GET_FINAL_MATERIAL_BY_PROJECT_LOADING,
            payload: false
        })

        dispatch({
            type: IS_LOADING,
            payload: false
        })

    }
}