import React, { useEffect, useRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import img from "../../assets/image/select.png";
import ReactDatePicker from 'react-datepicker';
import Datepickericon from '../../assets/image/date-picker-icon.png'
import { addBillEntryAction, editBillEntryAction, getBillEntryByIdAction } from '../../redux/actions/addBillAction';
import { getCustomerByIdAction, getCustomerDropDownAction, getSupplierDropDownAction } from '../../redux/actions/customerAction';
import { getBankDropDownAction } from '../../redux/actions/bankAction';
import Select from 'react-select'
import { getAllPendingBillByCoustmerAction } from '../../redux/actions/accountAction';
import { AgGridReact } from 'ag-grid-react';
import { useCallback } from 'react';
import { dateFormate } from '../../helper/utils';
import CurrencyFormat from 'react-currency-format';
import { UserRoles } from '../../helper/Constant';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import { RoleContext } from '../../helper/RoleProvider';
import { useContext } from 'react';
import { GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER } from "../../redux/type"
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';


const AddPaybleReceivable = ({ show, onHide, customerId, billId }) => {

    const dispatch = useDispatch()

    const [gridApi, setGridApi] = useState(null);
    const [amount, setAmount] = useState(0);
    const [ammountConfirmation, setAmmountConfirmation] = useState(false);
    const [bill, setBill] = useState({
        customer: "",
        date: "",
        paymentType: "BANK",
        bankName: "",
        amount: "",
        billType: "Purchase",
        tds: "0",
        chequeNo: "",
        chequeDate: "",
        paymentNarration: "",
        nextPaymentDate: "",
    })
    const paidAmountRef = useRef(null)
    const [formErrors, setFormErrors] = useState()
    const [pendingdTableData, setPendingdTableData] = useState([])
    const [selectedBills, setSelectedBills] = useState([])
    const [rotateArrow, setrotateArrow] = useState({
        customer: false,
        paymentType: false,
        bankName: false,
    });
    const [customerOption, setCustomerOption] = useState([])
    const [paymentTypeOption, setPaymentTypeOption] = useState([
        {
            value: "BANK",
            label: "BANK"
        },
        {
            value: "CASH",
            label: "CASH"
        },
        {
            value: "LABOUR",
            label: "LABOUR"
        },
    ])

    const [focusPaymentAmount, setFocusPaymentAmount] = useState(false);
    const customerData = useSelector((state) => state.get_Customer_Drop_Down.getCustomerDropDown)
    const supplierData = useSelector((state) => state.get_Supplier_Drop_Down.getSupplierDropDown)
    const bankDropDown = useSelector((state) => state.get_Bank_Drop_Down.getBankDropDown)
    const pendingdata = useSelector((state) => state.get_ALL_Pending_Bill.getAllPendingBill)
    const billEntryData = useSelector((state) => state.get_Bill_Entry_By_Id.getBillEntryById)
    const gridRef = useRef();

    useEffect(() => {
        if(bankDropDown && bankDropDown.status === 200 && bankDropDown.data){
           const defaultBank = bankDropDown.data.find(i => i.value.includes('50200011153791'))
           if(defaultBank){
               setBill({...bill, bankName: defaultBank.value})
           } 
        }
    }, [bankDropDown])

    useEffect(() => {
        if (bill.billType === "Purchase") {
            dispatch(getSupplierDropDownAction())
            dispatch(getBankDropDownAction())
        } else if (bill.billType === "Sale") {
            dispatch(getCustomerDropDownAction())
        }
    }, [bill.billType])

    useEffect(() => {
        if (customerData && customerData.data) {
            let temp = customerData.data.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setCustomerOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.accountName
                }
            }))
        }
    }, [customerData])

    useEffect(() => {
        if (supplierData && supplierData.data) {
            let temp = supplierData.data.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setCustomerOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.accountName
                }
            }))
        }
    }, [supplierData])

    useEffect(() => {
        if (customerId !== "") {
            dispatch(getCustomerByIdAction(customerId))
        }
    }, [customerId])

    useEffect(() => {
        if (billId) {
            dispatch(getBillEntryByIdAction(billId))
        }
    }, [billId])

    useEffect(() => {
        if (billEntryData && billEntryData.data && billId) {
            setBill({
                billType: billEntryData.data.billType,
                date: new Date(billEntryData.data.date),
                // invoiceNo: billEntryData.data.invoiceNo,
                amount: billEntryData.data.amount,
                paymentType: billEntryData.data.paymentType,
                customer: billEntryData.data.customer,
                pay: billEntryData.data.pay,
                status: billEntryData.data.status,
                bankName: billEntryData.data.bankName,
                tds: billEntryData.data.tsd ? billEntryData.data.tsd : '0',
                chequeNo: billEntryData.data.chequeNo,
                chequeDate: billEntryData.data.chequeDate ? new Date(billEntryData.data.chequeDate) : '',
                paymentNarration: billEntryData.data.paymentNarration,
                nextPaymentDate: billEntryData.data.nextPaymentDate ? new Date(billEntryData.data.nextPaymentDate) : '',
            })
            if (billEntryData.data.paidInvoices?.length)
                setSelectedBills(billEntryData.data.paidInvoices)
            else
                setSelectedBills([])
        }
    }, [billEntryData])

    const handleChange = (e, name) => {
        setFocusPaymentAmount(false)
        let re = /^[0-9\b]+$/;
        let regexwithdecimal = /^(?:\d*\.\d{0,2}|\d+)$/;
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "amount" || name === "tds") {
            if (e.target.value === '' || regexwithdecimal.test(e.target.value)) {
                setBill({ ...bill, [name]: e.target.value })
            }
        } else if (name === "date" || name === "chequeDate" || name === "nextPaymentDate") {
            setBill({ ...bill, [name]: e })
        } else if (name === "billType") {
            setBill({ ...bill, [name]: e.target.value, customer: '', bankName: '' })
        } else if (name === "customer") {
            setBill({ ...bill, [name]: e.value })
        } else if (name === "paymentType") {
            setBill({ ...bill, [name]: e.value })
        } else {
            setBill({ ...bill, [name]: e.target.value })
        }
    }

    const validation = () => {
        let flag = false
        const error = {};
        let re = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (!bill.billType) {
            error.billType = "Please select type"
            flag = true
        }
        if (!bill.customer) {
            if (bill.billType === "Purchase") {
                error.customer = "Please select supplier"
                flag = true
            } else if (bill.billType === "Sale") {
                error.customer = "Please select customer"
                flag = true
            }
        }
        if (!bill.date) {
            error.date = "Please select date"
            flag = true
        }
        if (!bill.paymentType) {
            error.paymentType = "Please select payment type"
            flag = true
        }
        if (bill.billType === "Purchase") {
            if (!bill.bankName && bill.paymentType === "BANK") {
                error.bankName = "Please select bank"
                flag = true
            }
        } else {
            if (!bill.bankName && bill.paymentType === "BANK") {
                error.bankName = "Please enter bank name"
                flag = true
            }
        }
        if (!bill.amount) {
            error.amount = "Please enter amount"
            flag = true
        }
        else if (!re.test(bill.amount)) {
            error.amount = "Please enter valid amount"
            flag = true
        }
        if (bill.tds === '' || bill.tds === undefined) {
            error.tds = "Please enter tds"
            flag = true
        }
        // if (!bill.nextPaymentDate) {
        //     error.nextPaymentDate = "Please select next payment date"
        //     flag = true
        // }
        setFormErrors(error);
        return flag
    }


    useEffect(() => {
        if (bill.customer) dispatch(getAllPendingBillByCoustmerAction({ cId: bill.customer, type: bill.billType, pay: "In" }))
    }, [bill.customer])

    useEffect(() => {
        return (() => {
            dispatch({
                type: GET_ALL_PENDING_BILL_ENTRY_BY_CUSTOMER,
                payload: []
            })
        })
    }, [])

    useEffect(() => {
        if (pendingdata && pendingdata.data) setPendingdTableData(
            pendingdata.data.filter(item => item.status !== "Done").map((ele) => {
                return {
                    _id: ele._id,
                    date: ele.date,
                    createdBy: ele.createdBy,
                    amount: ele.amount,
                    pay: ele.pay,
                    payType: 'Full',
                }
            })
        )
    }, [pendingdata])


    const Save = () => {
        if (validation()) {
            return
        }
        let temp = pendingdTableData.filter(item => selectedBills.includes(item._id) && item.payType === "Full")
        let amount = temp.reduce((accumulator, curr) => +accumulator + +curr.amount ?? 0, 0) ?? 0
        if (+bill.amount > amount || +bill.amount == amount) {
            save()
            return
        }
        setAmount(amount)
        setAmmountConfirmation(true)
        return
    }
    const save = () => {
        const body = {
            ...bill,
            pay: 'Out',
            paidInvoices: pendingdTableData.filter(tableItem => (selectedBills.includes(tableItem._id)))
        }
        dispatch(addBillEntryAction(body))
        onHide()
        return
    }

    const Edit = () => {
        if (validation()) {
            return
        }
        let temp = pendingdTableData.filter(item => selectedBills.includes(item._id) && item.payType === "Full")
        let amount = temp.reduce((accumulator, curr) => +accumulator + +curr.amount ?? 0, 0) ?? 0
        if (+bill.amount > amount || +bill.amount == amount) {
            edit()
            return
        }
        setAmount(amount)
        setAmmountConfirmation(true)
        return
    }
    const edit = () => {

        const body = {
            ...bill,
            pay: 'Out',
            id: billId,
            paidInvoices: pendingdTableData.filter(tableItem => (selectedBills.includes(tableItem._id)))
        }
        dispatch(editBillEntryAction(body))
    }


    const openCalender = (id) => {
        document.getElementById(id).click()
    }

    const selectValue = (value, name) => {
        if (name === "customer") {
            return value ? customerOption.find((ele) => ele.value === value) : ''
        }
        else if (name === "paymentType") {
            return value ? paymentTypeOption.find((ele) => ele.value === value) : ''
        }
    }


    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1
        };
    }, []);

    const selectBill = (e, value) => {
        if (e.target.checked) {
            setSelectedBills([...selectedBills, value])
        } else {
            setSelectedBills(selectedBills.filter(item => item !== value))
        }
    }

    const handleChangeOption = (e, params) => {
        const newData = []
        pendingdTableData.map((rowNode, index) => {
            if (rowNode._id === params.data._id) {
                // newData.push({ ...rowNode, payType: e.target.value, paidAmount: pendingdTableData[index].pandingAmount })
                newData.push({ ...rowNode, payType: e.target.value })
            }
            else {
                newData.push({ ...rowNode, payType: "Full" })
            }
        });
        setPendingdTableData(newData)
    }
    const handleChangeAmount = (e, params) => {
        e.preventDefault();
        const newData = []
        pendingdTableData.map((rowNode, index) => {
            if (rowNode._id === params.data._id)
                // if (e.target.value <= rowNode?.pandingAmount)
                newData.push({ ...rowNode, paidAmount: e.target.value ? parseInt(e.target.value) : 0 })
            // else
            // newData.push({ ...rowNode })
            else
                newData.push({ ...rowNode })
        });
        setPendingdTableData(newData)
        setFocusPaymentAmount(true)
    }

    const columnDefsBills = [
        {
            headerName: 'Select',
            field: "_id",
            cellRendererFramework: (params) => <div className='pendingamountchecked'>
                <input type="checkBox" checked={selectedBills.find(item => item === params.value)} onChange={(e) => selectBill(e, params.value)} />
            </div>
        },
        {
            field: 'date',
            cellRendererFramework: (params) => <div>
                <span>{dateFormate(params.value, "Date")}</span>
            </div>
        },
        { field: 'createdBy.name' },
        {
            field: 'amount',
            cellRendererFramework: (params) => <div>
                <span><CurrencyFormat value={params.value} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
            </div>
        },
        // {
        //     headerName: 'Panding Amount',
        //     field: "pandingAmount",
        //     cellRendererFramework: (params) => {
        //         return (<div>
        //             <span><CurrencyFormat value={params.value} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></span>
        //         </div>)
        //     }
        // },
        // {
        //     field: 'pay',
        //     cellRendererFramework: (params) => <span>{params.data?.pay === "In" ? "Invoice" : "Payment"}</span>
        // },

        // {
        //     headerName: 'Payment Amount',
        //     field: "paidAmount",
        //     cellRendererFramework: (params) =>
        //         <input type="number" max={params.data?.pandingAmount} autoFocus={focusPaymentAmount} className='paidAmountInput' ref={paidAmountRef} disabled={params.data.payType === "Full"} value={params.data?.paidAmount} onChange={(e) => handleChangeAmount(e, params)} />
        // },
        {
            headerName: 'PayType',
            field: "_id",
            cellRendererFramework: (params) => <div>
                <select value={params.data?.payType} onChange={(e) => { handleChangeOption(e, params) }}>
                    <option value="Full" >Full</option>
                    <option value="Partial">Partial</option>
                </select>
            </div>
        },
    ]


    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Payble/Receivable</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={12}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label bill-type">
                                <label className='d-block'>Type</label>
                                <div className='d-flex mt-2'>
                                    <input
                                        type="radio"
                                        id="purchase"
                                        name="billType"
                                        value="Purchase"
                                        onChange={(e) => handleChange(e, 'billType')}
                                        checked={bill.billType === "Purchase"}
                                    />
                                    <label for="purchase" className='me-3 ms-1'>Payable</label>
                                    <input
                                        type="radio"
                                        id="sale"
                                        name="billType"
                                        value="Sale"
                                        onChange={(e) => handleChange(e, 'billType')}
                                        checked={bill.billType === "Sale"}
                                    />
                                    <label for="sale" className='ms-1'>Receivable</label>
                                </div>
                            </div>
                            {formErrors?.billType && <span style={{ color: "red" }}>{formErrors.billType}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-form-info add-remark-modal mx-0 mt-3">
                                <div className="multi-select">
                                    <label>{bill.billType === "Sale" ? "Customer" : "Supplier"}</label>
                                    <Select
                                        placeholder={bill.billType === "Sale" ? "Select customer" : "Select supplier"}
                                        value={selectValue(bill.customer, 'customer')}
                                        onChange={(e) => { handleChange(e, "customer") }}
                                        options={customerOption}
                                        isDisabled={customerId}
                                    />
                                </div>
                                {formErrors?.customer && <span style={{ color: "red" }}>{formErrors.customer}</span>}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label enquery-datepicker">
                                <label>Date</label>
                                <ReactDatePicker id='date' dateFormat="dd/MM/yyyy" selected={bill.date} placeholderText="Select date" onChange={(e) => handleChange(e, "date")} autoComplete='off' />
                            </div>
                            <img src={Datepickericon} onClick={() => openCalender('date')} alt="" className='datepickericon' />
                            {formErrors?.date && <label style={{ color: "red" }}>{formErrors.date}</label>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="multi-select">
                                <label>Payment Type</label>
                                <div className="position-relative select-icon user-type">
                                    <Select
                                        placeholder={"Select payment type"}
                                        value={selectValue(bill.paymentType, 'paymentType')}
                                        onChange={(e) => { handleChange(e, "paymentType") }}
                                        options={paymentTypeOption}
                                    />
                                </div>
                            </div>
                            {formErrors?.paymentType && <span style={{ color: "red" }}>{formErrors.paymentType}</span>}
                        </div>
                    </Col>
                    {bill.paymentType === "BANK" && <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Bank Name</label>
                                {bill.billType === "Sale" ? <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter bank name"
                                    value={bill.bankName}
                                    onChange={(e) => handleChange(e, 'bankName')}
                                    autoComplete="off"
                                />
                                    :
                                    <div className="position-relative select-icon user-type">
                                        <img src={img} className={`${rotateArrow.bankName && "rotateArrow"}`} alt="" />
                                        <select className="form-control" value={bill.bankName} onBlur={() => { setFocusPaymentAmount(false); setrotateArrow({ ...rotateArrow, bankName: false }) }} onClick={() => { setFocusPaymentAmount(false); setrotateArrow({ ...rotateArrow, bankName: !rotateArrow.bankName }) }} onChange={(e) => handleChange(e, "bankName")}>
                                            <option value="">Select bank name</option>
                                            {bankDropDown && bankDropDown.data && bankDropDown.data.map((item, i) =>
                                                <option value={item.value} key={i}>{item.value}</option>
                                            )}
                                        </select>
                                    </div>}
                            </div>
                            {formErrors?.bankName && <span style={{ color: "red" }}>{formErrors.bankName}</span>}
                        </div>
                    </Col>}
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Amount</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter enter amount"
                                    value={bill.amount}
                                    // disabled={true}
                                    onChange={(e) => handleChange(e, 'amount')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.amount && <span style={{ color: "red" }}>{formErrors.amount}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>TDS</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter TDS"
                                    value={bill.tds}
                                    onChange={(e) => handleChange(e, 'tds')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.tds && <span style={{ color: "red" }}>{formErrors.tds}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Cheque No</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter cheque no"
                                    value={bill.chequeNo}
                                    onChange={(e) => handleChange(e, 'chequeNo')}
                                    autoComplete="off"
                                />
                            </div>
                            {formErrors?.chequeNo && <span style={{ color: "red" }}>{formErrors.chequeNo}</span>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label enquery-datepicker">
                                <label>Cheque Date</label>
                                <ReactDatePicker id='Chequedate' dateFormat="dd/MM/yyyy" selected={bill.chequeDate} placeholderText="Select cheque date " onChange={(e) => handleChange(e, "chequeDate")} autoComplete='off' />
                            </div>
                            <img src={Datepickericon} onClick={() => openCalender('Chequedate')} alt="" className='datepickericon' />
                            {formErrors?.chequeDate && <label style={{ color: "red" }}>{formErrors.chequeDate}</label>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label enquery-datepicker">
                                <label>Next Payment Date</label>
                                <ReactDatePicker id='Nextpaymentdate' dateFormat="dd/MM/yyyy" selected={bill.nextPaymentDate} placeholderText="Select next payment date" onChange={(e) => handleChange(e, "nextPaymentDate")} autoComplete='off' />
                            </div>
                            <img src={Datepickericon} onClick={() => openCalender('Nextpaymentdate')} alt="" className='datepickericon' />
                            {formErrors?.nextPaymentDate && <label style={{ color: "red" }}>{formErrors.nextPaymentDate}</label>}
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="users-form-info date-picker add-remark-modal mx-0 mt-3">
                            <div className="users-label">
                                <label>Payment Narration</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter payment narration"
                                    value={bill.paymentNarration}
                                    onChange={(e) => handleChange(e, 'paymentNarration')}
                                    autoComplete="off"
                                    rows="1"
                                    style={{height:'unset'}}
                                />
                            </div>
                            {formErrors?.paymentNarration && <span style={{ color: "red" }}>{formErrors.paymentNarration}</span>}
                        </div>
                    </Col>
                    <Col lg={12}>
                        {pendingdata &&
                            <div className="ag-theme-alpine pendingInvoiceTable  mt-4 " style={{ height: "40vh", width: "100%", position: 'relative' }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={pendingdTableData}
                                    columnDefs={columnDefsBills}
                                    defaultColDef={defaultColDef}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className='adduser-btn' onClick={() => { billId ? Edit() : Save() }}>{billId ? "Edit" : "Save"}</button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>
            {ammountConfirmation &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText={"Manage"}
                    confirmBtnBsStyle={"danger"}
                    title="Are you sure?"
                    onConfirm={() => { setAmmountConfirmation(false) }}
                    onCancel={() => { setAmmountConfirmation(false) }}
                    focusCancelBtn
                >bill amount is less then selected amount!
                </SweetAlert>
            }

        </Modal>
    )
}

export default AddPaybleReceivable