import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { addNocAction, editNocAction, getNocByIdAction } from '../../redux/actions/nocAction';
import img from '../../assets/image/select.png'
import { getProjectByCustomerAction, getProjectByIdAction } from '../../redux/actions/projectAction';
import { toast } from 'react-toastify';
import Viewicon from '../../assets/image/view.png'
import AddRemarkModal from '../../components/modals/AddRemarkModal ';
import AddCustomer from '../../components/modals/AddCustomer';
import moment from 'moment'
import { EDIT_NOC, ADD_NOC, GET_PROJECT_BY_CUSTOMER, GET_ENQUIRY_BY_ID } from '../../redux/type';
import { API } from '../../config/API/api.config';
import { Col, Row } from 'react-bootstrap';
import { AllStatus } from '../../helper/Constant';
import { fileRemoveAction, fileUploadAction } from '../../redux/actions/generalAction';
import removeImg from '../../assets/image/delete-red.png'
import { getAllCustomerAction } from '../../redux/actions/customerAction';
import { getEnquiryByIdAction } from '../../redux/actions/enquiryAction';
import Select from 'react-select';

const NocForm = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(location.search)
    const enqryId = queryParams.get("enquiryId")
    const nocId = queryParams.get("nocId")
    const projecId = queryParams.get("projectId")

    const [nocData, setNocData] = useState({
        isConsulting: location.pathname?.split('/')[2] === "counsulting" ? true : false,
        customer: '',
        projectId: '',
        partyName: '',
        location: '',
        contactPersonNumber: '',
        workType: '',
        cityOrOutcity: '',
        provisionalOrFinal: '',
        refBy: '',
        status: '',
        certificateImage: '',
        officersRemarks: []
    });

    const [remarks, setRemarks] = useState([])
    const [newRemark, setNewRemark] = useState()
    const [formErrors, setFormErrors] = useState()
    const [editId, setEditId] = useState()
    const [enquiryId, setEnquiryId] = useState()
    const [selecteProject, setSelecteProject] = useState(true);
    const [customerTog, setCustomerTog] = useState(false);
    const [rotateArrow, setrotateArrow] = useState('');
    const [showAddRemark, setShowAddRemark] = useState(false);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [customerOption, setCustomerOption] = useState([]);
    const [projectOption, setProjectOption] = useState([]);
    const mes = `NOC ${editId ? "updated" : "added"} successfully`;
    const toastSuccess = () => toast.success(mes);

    const provisionalOption = [
        { value: "Provisional", label: "Provisional" },
        { value: "Final", label: "Final" },
        { value: "Renewal", label: "Renewal" },
    ]

    const getNocByIdData = useSelector((state) => state.get_Noc_By_Id.getNocById.data)
    const fileUploadData = useSelector((state) => state.file_Upload.fileUpload);
    const projectByCustomerData = useSelector((state) => state.get_Project_By_Customer.getProjectByCustomer.data)
    const customerData = useSelector((state) => state.get_All_Customer.getAllCustomer.data)
    const addNocData = useSelector((state) => state.add_Noc.addNoc)
    const editNocData = useSelector((state) => state.edit_Noc.editNoc)
    const getEnquiryByIdData = useSelector((state) => state.get_Enquiry_By_Id.getEnquiryById)
    const getProjectByIdData = useSelector((state) => state.get_Project_By_Id.getProjectById)

    useEffect(() => {
        dispatch(getAllCustomerAction())
    }, [])

    useEffect(() => {
        if (nocId) {
            dispatch(getNocByIdAction(nocId))
            setEditId(nocId)
        }
    }, [nocId])

    useEffect(() => {
        if (enqryId) {
            dispatch(getEnquiryByIdAction(enqryId))
            setEnquiryId(enqryId)
        }
    }, [enqryId])

    useEffect(() => {
        if (projecId) {
            dispatch(getProjectByIdAction(projecId))
        }
    }, [projecId])


    useEffect(() => {
        if (customerData) {
            let temp = customerData.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { case: 'upper' }))
            setCustomerOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.accountName
                }
            }))
        }
    }, [customerData])

    useEffect(() => {
        if (projectByCustomerData) {
            let test = [];
            test.push({ value: "Other", label: "Other" })
            let temp = projectByCustomerData.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
            temp.map((ele) => test.push({ value: ele._id, label: ele.partyName }))
            setProjectOption(test)
        }
    }, [projectByCustomerData])

    useEffect(() => {
        if (getProjectByIdData && getProjectByIdData.data) {
            setNocData({
                ...nocData,
                customer: getProjectByIdData.data.customer,
                projectId: getProjectByIdData.data._id,
                location: getProjectByIdData.data.location,
                partyName: getProjectByIdData.data.partyName,
                contactPersonNumber: getProjectByIdData.data.contactPersonNumber,
                status: getProjectByIdData.data.contactPersonNumber.status,
                provisionalOrFinal: "Final"
            })
        }
    }, [getProjectByIdData])


    useEffect(() => {
        if (getEnquiryByIdData && getEnquiryByIdData.data) {
            setNocData({ ...nocData, projectId: "Other" })
        }
    }, [getEnquiryByIdData])

    const onChangeHandler = (e, name) => {
        let re = /^[0-9\b]+$/;
        setFormErrors({ ...formErrors, [name]: '' })
        if (name === "contactPersonNumber") {
            if (e.target.value === "" || re.test(e.target.value)) {
                setNocData({ ...nocData, [name]: e.target.value })
            }
        }
        else if (name === "certificateImage") {
            setNocData({ ...nocData, [name]: e.target.value })
        }
        else if (name === "customer") {
            setNocData({ ...nocData, [name]: e.value })
        }
        else if (name === "projectId") {
            setFormErrors({ ...formErrors, partyName: '', [name]: '' })
            if (e.value && e.value !== "Other") {
                setNocData({ ...nocData, partyName: e.label, [name]: e.value })
            } else {
                setNocData({ ...nocData, partyName: '', [name]: e.value })
            }
        } else if (name === "provisionalOrFinal") {
            setNocData({ ...nocData, [name]: e.value })
        } else {
            setNocData({ ...nocData, [name]: e.target.value })
        }
    }

    useEffect(() => {
        if (nocData.customer) {
            dispatch(getProjectByCustomerAction(nocData.customer))
        }
    }, [nocData.customer])

    const validation = () => {
        let flag = false
        const error = {};
        if (!nocData.customer) {
            error.customer = "Please select customer"
            flag = true
        }
        if (!nocData.partyName) {
            error.partyName = "Please enter partyname"
            flag = true
        }
        if (!nocData.location) {
            error.location = "Please enter location"
            flag = true
        }
        if (!nocData.contactPersonNumber) {
            error.contactPersonNumber = "Please enter contact number"
            flag = true
        }
        else if (nocData.contactPersonNumber.length < 10) {
            error.contactPersonNumber = "Please enter valid contact number"
            flag = true
        }
        if (!nocData.workType) {
            error.workType = "Please enter work type"
            flag = true
        }
        if (!nocData.cityOrOutcity) {
            error.cityOrOutcity = "Please enter city/outcity"
            flag = true
        }
        if (!nocData.provisionalOrFinal) {
            error.provisionalOrFinal = "Please enter provisional/final"
            flag = true
        }
        if (!nocData.refBy) {
            error.refBy = "Please enter reference by."
            flag = true
        }
        if (!nocData.status || nocData.status === "Select Status") {
            error.status = "Please select status"
            flag = true
        }
        // if (!nocData.certificateImage) {
        //     error.certificateImage = "Please Enter NOC Image"
        //     flag = true
        // }
        if (selecteProject && !nocData.projectId) {
            error.projectId = "Please select project"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const addRemark = (data) => {
        if (data.date !== undefined && data.remark !== undefined) {
            setNewRemark(data)
        }
        setShowAddRemark(false)
    }

    const onSave = () => {
        if (validation()) {
            return
        }
        let body = {
            enquiryId: enquiryId ? enquiryId : '',
            isConsulting: nocData.isConsulting,
            customer: nocData.customer,
            projectId: nocData.projectId !== "Other" ? nocData.projectId : '',
            partyName: nocData.partyName,
            location: nocData.location,
            contactPersonNumber: nocData.contactPersonNumber,
            workType: nocData.workType,
            cityOrOutcity: nocData.cityOrOutcity,
            provisionalOrFinal: nocData.provisionalOrFinal,
            refBy: nocData.refBy,
            status: nocData.status,
            certificateImage: nocData.certificateImage,
            officersRemarks: newRemark ? [...remarks, newRemark] : [...remarks]
        }
        dispatch(addNocAction(body))
    }

    useEffect(() => {
        if (addNocData && addNocData.data) {
            if (addNocData.message === "NOC created successfully.") {
                dispatch({
                    type: ADD_NOC,
                    payload: []
                })
                navigate('/noc')
                toastSuccess(true)
            }
        }
    }, [addNocData])

    useEffect(() => {
        if (editNocData && editNocData.data) {
            if (editNocData.data.message === "NOC updated successfully.") {
                dispatch({
                    type: EDIT_NOC,
                    payload: []
                })
                navigate('/noc')
            }
        }
    }, [editNocData])


    const onEdit = () => {
        if (validation()) {
            return
        }
        let body = {
            id: editId,
            isConsulting: nocData.isConsulting,
            customer: nocData.customer,
            projectId: selecteProject ? nocData.projectId !== "Other" ? nocData.projectId : '' : '',
            partyName: nocData.partyName,
            location: nocData.location,
            contactPersonNumber: nocData.contactPersonNumber,
            workType: nocData.workType,
            cityOrOutcity: nocData.cityOrOutcity,
            provisionalOrFinal: nocData.provisionalOrFinal,
            refBy: nocData.refBy,
            status: nocData.status,
            certificateImage: nocData.certificateImage,
            officersRemarks: newRemark ? [...remarks, newRemark] : [...remarks]
        }
        dispatch(editNocAction(body))
        toastSuccess(true)
    }

    useEffect(() => {
        if (getNocByIdData && nocId) {
            setNocData({
                customer: getNocByIdData.customer,
                projectId: getNocByIdData.projectId ? getNocByIdData.projectId : "Other",
                partyName: getNocByIdData.partyName,
                location: getNocByIdData.location,
                contactPersonNumber: getNocByIdData.contactPersonNumber,
                workType: getNocByIdData.workType,
                cityOrOutcity: getNocByIdData.cityOrOutcity,
                provisionalOrFinal: getNocByIdData.provisionalOrFinal,
                refBy: getNocByIdData.refBy,
                status: getNocByIdData.status,
                certificateImage: getNocByIdData.certificateImage
            })
            setSelecteProject(getNocByIdData.projectId ? getNocByIdData.projectId : "Other")
            setRemarks(getNocByIdData.officersRemarks)
        }
    }, [getNocByIdData])

    useEffect(() => {
        if (selectedFile) {
            if (nocData.certificateImage) {
                fileRemove(nocData.certificateImage, '')
            }
            let formData = new FormData()
            formData.append("files", selectedFile);
            dispatch(fileUploadAction(formData));
        }
    }, [selectedFile])

    useEffect(() => {
        if (fileUploadData) {
            setNocData({ ...nocData, certificateImage: fileUploadData.url })
        }
    }, [fileUploadData])

    const fileRemove = (files, name) => {
        let body = {
            file: files.split('/')[1]
        }
        dispatch(fileRemoveAction(body))
        if (name === "removeFile") {
            setNocData({ ...nocData, certificateImage: '' })
        }
    }

    const ElementClick = useRef()

    const UploadClick = () => {
        ElementClick.current.click()
    }

    const fileOpen = (name) => {
        window.open(`${API.endpoint}/${name}`, "_blank")
    }

    const Click = (name) => {
        if (name === rotateArrow) {
            setrotateArrow('')
        } else {
            setrotateArrow(name);
        }
    }

    const Blur = () => {
        setrotateArrow('')
    }

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_PROJECT_BY_CUSTOMER,
                payload: []
            })
            dispatch({
                type: GET_ENQUIRY_BY_ID,
                payload: []
            })
        }
    }, [])

    const selectValue = (value, name) => {
        if (name === "customer") {
            return customerOption.find((item) => item.value === value)
        } else if (name === "projectId") {
            return projectOption.find((item) => item.value === value)
        } else if (name === "provisionalOrFinal") {
            return provisionalOption.find((ele) => ele.value === value)
        }
    }

    return (
        <>
            <div className='parent-dashboard'>
                <div className='mb-4 set-page-heade'>
                    <div className='d-flex flex-column'>
                        <h2 className='mb-0'>NOC</h2>
                    </div>
                    <div>
                        {editId ? <button className="adduser-btn ms-3" onClick={() => onEdit()}>Edit</button> : <button className="adduser-btn ms-3" onClick={() => onSave()} >Save</button>}
                        <button className="cancel-button ms-3" onClick={() => enquiryId ? navigate("/enquiry") : navigate("/noc")}>Cancel</button>
                    </div>
                </div>
                {getEnquiryByIdData && getEnquiryByIdData.data && <div className="mb-4 users-form">
                    {/* <Row>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>Project Type:</label><span className='ms-2'>{getEnquiryByIdData.data?.projectType}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>Party Name:</label><span className='ms-2'>{getEnquiryByIdData.data?.partyName}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>E-mail:</label><span className='ms-2'>{getEnquiryByIdData.data?.email}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>Location:</label><span className='ms-2'>{getEnquiryByIdData.data?.location}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>Contact Person Name:</label><span className='ms-2'>{getEnquiryByIdData.data?.contactPersonName}</span>
                                </div>
                            </div></Col>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>Contact Person Number:</label><span className='ms-2'>{getEnquiryByIdData.data?.contactPersonNumber}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>Visit Date:</label><span className='ms-2'>{moment(getEnquiryByIdData.data?.visitDate).format("DD MMM YYYY")}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>Quotation Number:</label><span className='ms-2'>{getEnquiryByIdData.data?.quotationNumber}</span>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={6} className="my-2">
                            <div className="mx-0">
                                <div className="users-label bill-type d-flex">
                                    <label className='d-block'>Work Order:</label><span className='ms-2'>{getEnquiryByIdData.data?.workOrder?.split('/')[1]}</span>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col lg={4}>
                            <table className='purchase-request-tabel' style={{width:"100%"}}>
                                <tbody>
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>Project Type</td>
                                        <td>{getEnquiryByIdData.data?.projectType}</td>
                                    </tr>   
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>Party Name</td>
                                        <td>{getEnquiryByIdData.data?.partyName}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>E-mail</td>
                                        <td>{getEnquiryByIdData.data?.email}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col lg={4}>
                            <table className='purchase-request-tabel' style={{width:"100%"}}>
                                <tbody>
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>Location</td>
                                        <td>{getEnquiryByIdData.data?.location}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>Contact Person Name</td>
                                        <td>{getEnquiryByIdData.data?.contactPersonName}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>Contact Person Number</td>
                                        <td>{getEnquiryByIdData.data?.contactPersonNumber}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col lg={4}>
                            <table className='purchase-request-tabel' style={{width:"100%"}}>
                                <tbody>
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>Visit Date</td>
                                        <td>{moment(getEnquiryByIdData.data?.visitDate).format("DD MMM YYYY")}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>Quotation Number</td>
                                        <td>{getEnquiryByIdData.data?.quotationNumber}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:"bold"}}>Work Order</td>
                                        <td>{getEnquiryByIdData.data?.workOrder?.split('/')[1]}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Col xl={12} className="mt-4">
                        <h2 className="Steps-header" style={{ fontWeight: "700" }}>Steps</h2>
                    </Col>
                    {getEnquiryByIdData.data.nextFollowups.length > 0 && getEnquiryByIdData.data.nextFollowups.map((item, i) =>
                        <Row key={i}>
                            {/* <div className="" style={{ width: "fit-content" }}>
                                <h5 className='me-2'>{i + 1}</h5>
                            </div> */}
                            <Col lg={12}>
                                <table className='purchase-request-tabel' style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "50px" }}>No</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Description</th>
                                            <th>Quotation Update</th>
                                            <th>Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-center'>{i + 1}</td>
                                            <td>{moment(item.date).format("DD MMM YYYY")}</td>
                                            <td>{item.status}</td>
                                            <td>{item.description}</td>
                                            <td>{item.quoationUpdate?.split('/')[1]}</td>
                                            <td>{item.remark}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            {/* <Col lg={12}>
                                <Row>
                                    <Col lg={4} className="mb-2">
                                        <div className="mx-0">
                                            <div className="users-label bill-type d-flex">
                                                <label className='d-block' >Date:</label><span className='ms-2'>{moment(item.date).format("DD MMM YYYY")}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mb-2">
                                        <div className="mx-0">
                                            <div className="users-label bill-type d-flex">
                                                <label className='d-block' >Status:</label><span className='ms-2'>{item.status}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mb-2">
                                        <div className="mx-0">
                                            <div className="users-label bill-type d-flex">
                                                <label className='d-block' >Description:</label><span className='ms-2 text-break'>{item.description}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mb-2">
                                        <div className="mx-0">
                                            <div className="users-label bill-type d-flex">
                                                <label className='d-block' >Quotation Update:</label><span className='ms-2'>{item.quoationUpdate?.split('/')[1]}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mb-2">
                                        <div className="mx-0">
                                            <div className="users-label bill-type d-flex">
                                                <label className='d-block' >Remark:</label><span className='ms-2 text-break'>{item.remark}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col> */}
                        </Row>
                    )}
                </div>}
                <div className="users-form addEnquiry noc-form">
                    <Row>
                        <Col lg={4}>
                            <div className="start-estimated-date">
                                <div className="users-form-info mt-3">
                                    <div className="users-form-info add-remark-modal approve_customer  mx-0 ">
                                        <label className='selector_label'>Customer</label>
                                        <div className="multi-select d-flex mt-1">
                                            <Select
                                                value={selectValue(nocData.customer, "customer")}
                                                onChange={(e) => onChangeHandler(e, "customer")}
                                                options={customerOption}
                                                placeholder="Select customer"
                                            />
                                            <button className="btn btn-outline-secondary contractor-btn" type="button" style={{ height: "38px", zIndex: "1" }} onClick={() => setCustomerTog(true)}>Add</button>
                                        </div>
                                    </div>
                                    {formErrors?.customer && (<label style={{ color: "red" }}>{formErrors.customer}</label>)}
                                </div>
                            </div>
                        </Col>
                        {selecteProject && <Col lg={4}>
                            <div className="users-form-info add-remark-modal mx-0 mt-3">
                                <div className="multi-select w-100">
                                    <label>Select Project</label>
                                    <Select
                                        placeholder="Select project"
                                        value={selectValue(nocData.projectId, "projectId")}
                                        onChange={(e) => onChangeHandler(e, "projectId")}
                                        options={projectOption}
                                    />
                                </div>
                                {formErrors?.projectId && <span style={{ color: "red" }}>{formErrors.projectId}</span>}
                            </div>
                        </Col>}
                        {nocData.projectId === "Other" && <Col lg={4}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Party Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter party name"
                                        onChange={(e) => { onChangeHandler(e, "partyName") }}
                                        value={nocData.partyName}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.partyName && <label style={{ color: "red" }}>{formErrors.partyName}</label>}
                            </div>
                        </Col>}
                        <Col lg={4}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Location</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter location"
                                        onChange={(e) => { onChangeHandler(e, 'location') }}
                                        value={nocData.location}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.location && <label style={{ color: "red" }}>{formErrors.location}</label>}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Work Type</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter work type"
                                        onChange={(e) => { onChangeHandler(e, "workType") }}
                                        value={nocData.workType}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.workType && <label style={{ color: "red" }}>{formErrors.workType}</label>}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>City/Outcity</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter city/outcity"
                                        onChange={(e) => { onChangeHandler(e, "cityOrOutcity") }}
                                        value={nocData.cityOrOutcity}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.cityOrOutcity && <label style={{ color: "red" }}>{formErrors.cityOrOutcity}</label>}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="users-form-info finalquotation mt-3">
                                <div className="multi-select w-100">
                                    <label>Provisional/Final</label>
                                    <Select
                                        placeholder="Enter provisional/final"
                                        value={selectValue(nocData.provisionalOrFinal, "provisionalOrFinal")}
                                        onChange={(e) => onChangeHandler(e, "provisionalOrFinal")}
                                        options={provisionalOption}
                                    />
                                </div>
                                {formErrors?.provisionalOrFinal && <span style={{ color: "red" }}>{formErrors.provisionalOrFinal}</span>}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Contact no.</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter contect number"
                                        onChange={(e) => { onChangeHandler(e, "contactPersonNumber") }}
                                        value={nocData.contactPersonNumber}
                                        autoComplete="off"
                                        maxLength={10}
                                    />
                                </div>
                                {formErrors?.contactPersonNumber && <label style={{ color: "red" }}>{formErrors.contactPersonNumber}</label>}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Reference by</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter reference by"
                                        onChange={(e) => { onChangeHandler(e, 'refBy') }}
                                        value={nocData.refBy}
                                        autoComplete="off"
                                    />
                                </div>
                                {formErrors?.refBy && <label style={{ color: "red" }}>{formErrors.refBy}</label>}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Status</label>
                                    <div className="users-label">
                                        <div
                                            className="position-relative select-icon"
                                            style={{ width: "max-content" }}
                                        >
                                            <img src={img} className={`${rotateArrow === "status" && "rotateArrow"}`} alt="" />
                                            <select className="form-control" value={nocData.status} onBlur={() => Blur()} onClick={() => Click('status')} onChange={(e) => { onChangeHandler(e, "status") }} >
                                                <option value="">Select Status</option>
                                                <option value={AllStatus.Pending}>{AllStatus.Pending}</option>
                                                <option value={AllStatus.InProgress}>{AllStatus.InProgress}</option>
                                                <option value={AllStatus.OnHold}>{AllStatus.OnHold}</option>
                                                <option value={AllStatus.Done}>{AllStatus.Done}</option>
                                            </select>
                                        </div>
                                    </div>
                                    {formErrors?.status && <span style={{ color: "red" }}>{formErrors.status}</span>}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="users-form-info mt-3">
                                <div className="">
                                    <label style={{ fontWeight: "600" }}>Certificate Image</label>
                                    <div className="upload-file mt-1" onClick={UploadClick}>
                                        <label className='upload-file-lable' >Upload here</label>
                                        <input
                                            type="file"
                                            className="form-control d-none"
                                            id='file'
                                            onChange={(e) => { setSelectedFile(e.target.files[0]) }}
                                            name="certificateImage"
                                            ref={ElementClick}
                                        />
                                    </div>
                                    {nocData.certificateImage ?
                                        <label style={{ color: "green", display: "flex", marginTop:'10px' }}>
                                            <img src={Viewicon} alt="View" width="20px" height='20px' style={{ marginRight: "5px" }} onClick={() => fileOpen(nocData.certificateImage)} />
                                            <img src={removeImg} alt="View" width="20px" height='20px' style={{ marginRight: "5px" }} onClick={() => fileRemove(nocData.certificateImage, "removeFile")} />
                                            Uploaded File : {nocData?.certificateImage?.split('/')[1]}</label>
                                        : formErrors?.certificateImage ? <label style={{ color: "red" }}>{formErrors.certificateImage}</label> : ''}
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="users-form-info mt-3">
                                <div className="users-label">
                                    <label>Officer's remarks</label><br />

                                    {
                                        remarks.length ? remarks.map((item, i) =>
                                            <div key={i}>
                                                <div style={{ marginRight: "20px" }}>
                                                    {item && item.date ? moment.utc(item.date).format("DD/MM/YYYY") : ''}
                                                </div>
                                                <div>
                                                    {item && item.remark ? item.remark : ''}
                                                </div><br />
                                            </div>
                                        ) : <></>
                                    }
                                    {
                                        (newRemark?.date || newRemark?.remark) &&
                                        <>
                                            <div style={{ marginRight: "20px" }}>
                                                {moment(newRemark.date).format("DD/MM/YYYY")}
                                            </div>
                                            <div>
                                                {newRemark.remark}
                                            </div><br />
                                        </>
                                    }
                                    <div className="noc-form-btn mt-5">
                                        <button className="save-button" onClick={() => setShowAddRemark(true)}>{newRemark ? "Edit remark" : "Add remark"}</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {showAddRemark && <AddRemarkModal show={showAddRemark} onHide={() => setShowAddRemark(false)} addData={(e) => addRemark(e)} dataForEdit={newRemark ? newRemark : {}} />}
            {customerTog && <AddCustomer show={customerTog} onHide={() => setCustomerTog(false)} customerId='' />}
            {/* {success &&
                <SweetAlert
                    success
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="success"
                    title="Are you sure?"
                    onConfirm={() => {
                        nevigate('/noc')
                    }}
                    onCancel={() => { setSuccess(false) }}
                    focusCancelBtn
                >
                    You want to update it
                </SweetAlert>
            } */}
        </>
    )
}

export default NocForm