import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AgGridReact } from 'ag-grid-react';
import { getProductDropDownAction } from '../../redux/actions/productAction';
import { useLocation } from 'react-router-dom';
import { ADD_FINAL_MATERIAL, DELETE_FINAL_MATERIAL, EDIT_FINAL_MATERIAL } from '../../redux/type';
import { Paginationlimit, Unit, UserRoles } from '../../helper/Constant';
import Select from 'react-select';
import { addFinalMaterial, deleteFinalMaterial, editFinalMaterial, getFinalMaterialByProject } from '../../redux/actions/finalMaterial';
import { RoleContext } from '../../helper/RoleProvider';
import editIcon from '../../assets/image/edit.svg';
import removeImg from '../../assets/image/delete-red.png'
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import SweetAlert from 'react-bootstrap-sweetalert';
import usePaginationHooks from '../../Hooks/paginationHooks';
import { ApiGet, ApiPut } from '../../helper/API/ApiData';
import { getPurchaseRequestByProjectAction } from '../../redux/actions/purchaseRequest';
import debounce from "lodash/debounce";

const ProductWisePr = ({ show, onHide, projectId }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const [PRdata, setPRdata] = useState([])
    const [limit, setlimit] = useState(Paginationlimit);
    const [gridApi, setGridApi] = useState(null);
    const { getPRByProjectWithPaginate } = usePaginationHooks()
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    // const getPurchaseRequestByProject = useSelector((state) => state.get_Purchase_Request_By_Project.getPurchaseRequestByProject)

    const columnDefs = [
        { field: 'productName' },
        { field: 'productCode' },
        { field: 'quantity', sortable: false },
        { field: 'delivered', sortable: false },
        { field: 'unit' },
        // { field: 'make' },
        // { field: 'desc', headerName: 'Description' },
    ]
    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1
        };
    }, []);

    // useEffect(() => {
    //     dispatch(getPurchaseRequestByProjectAction(projectId))
    // }, [projectId])

    // useEffect(() => {
    //     if (getPurchaseRequestByProject && getPurchaseRequestByProject.data) {
    //         let temp = [];
    //         getPurchaseRequestByProject.data.map((ele) => {
    //             ele.items.map((items) => {
    //                 if (temp.map((element) => element.productCode).includes(items.productCode)) {
    //                     temp.forEach((item, i) => {
    //                         if (item.productCode === items.productCode) {
    //                             temp[i] = {
    //                                 ...item,
    //                                 reqQuantity: item.reqQuantity + items.reqQuantity,
    //                                 deliveredQuantity: (item.deliveredQuantity ?? 0) + (items.deliveredQuantity ?? 0)
    //                             }
    //                         }
    //                     })
    //                 } else {
    //                     temp.push(items)
    //                 }
    //             })
    //         })
    //         const temp2 = temp.map((ele) => {
    //             return {
    //                 productCode: ele?.productCode ? ele?.productCode : '',
    //                 productName: ele?.productName ? ele?.productName : '',
    //                 quantity: ele?.reqQuantity ? ele?.reqQuantity : '',
    //                 delivered: ele?.deliveredQuantity ? ele?.deliveredQuantity : 0,
    //                 unit: ele?.unit ? ele?.unit : 0,
    //                 _id: ele?._id ? ele?._id : '',
    //             }
    //         })
    //         setPRdata(temp2)
    //     }
    // }, [getPurchaseRequestByProject])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";
                    getPRByProjectWithPaginate(projectId, page, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {

                            params.successCallback(res?.data ? res?.data?.map((ele) => {
                                return {
                                    productCode: ele?.productCode ? ele?.productCode : '',
                                    productName: ele?.productName ? ele?.productName : '',
                                    quantity: ele?.quantity ? ele?.quantity : '',
                                    delivered: ele?.delivered ? ele?.delivered : 0,
                                    unit: ele?.unit ? ele?.unit : 0,
                                    _id: ele?._id ? ele?._id : '',
                                }
                            }) : [], res?.totalCount ? res.totalCount : 0);

                            if (res?.data?.length <= 0) {
                                gridApi.showNoRowsOverlay();
                            } else {
                                gridApi.hideOverlay();
                            }

                        })
                }
            };
            gridApi?.setDatasource(dataSource);
        }

    }, [gridApi, limit, filter.search]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            // size="xl"
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Product Wise Purchase Request"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-3 d-flex justify-content-end'>
                    <div>
                        <input
                            type="text"
                            className="form-control search-button"
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value.trimStart())}
                            autoComplete="off"
                        />
                    </div>
                </div>

                <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

                    <AgGridReact
                        pagination={true}
                        // rowData={PRdata}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowHeight={40}
                        defaultColDef={defaultColDef}
                        overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
                    />

                    {/* <Pagination setlimit={setlimit} setPage={setPage} page={page} limit={limit} data={saleData} totalCount={totalCount} /> */}
                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ProductWisePr