import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserAction, getAllUserAction } from '../../redux/actions/userAction';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import removeImg from '../../assets/image/delete-red.png'
import { Paginationlimit, UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import { DELETE_USER } from '../../redux/type';
import editIcon from '../../assets/image/edit.svg';
import usePaginationHooks from '../../Hooks/paginationHooks';
import debounce from "lodash/debounce";

const Users = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useContext(RoleContext);
  const RolesForAdd = [UserRoles.Admin]
  const [deletepopup, setDeletepopup] = useState(false);
  const [paramsValue, setParamsValue] = useState();
  const [userData, setUserData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const { getAllUser } = usePaginationHooks()
  const [gridApi, setGridApi] = useState(null);
  const [limit, setlimit] = useState(Paginationlimit);
  const [filter, setFilter] = useState({ search: "" });
  const [search, setSearch] = useState("");
  const toastSuccess = () => toast.success('User deleted successfully');

  // const getUserData = useSelector((state) => state.get_All_User.getAllUser)
  const deleteData = useSelector((state) => state.delete_User.deleteUser)
  const editData = useSelector((state) => state.edit_User.editUser)

  // useEffect(() => {
  //   if (getUserData && getUserData.data) {
  //     setUserData(getUserData.data.map((ele) => {
  //       return {
  //         name: ele.name ? ele.name : '',
  //         email: ele.email ? ele.email : '',
  //         password: ele.password ? ele.password : '',
  //         role: ele.role ? ele.role : '',
  //         _id: ele._id ? ele._id : '',
  //       }
  //     }))
  //   }
  // }, [getUserData])

  const onEdit = (id) => {
    navigate(`edit?userId=${id}`)
  }

  const handleRowClick = (params) => {
    if (params.colDef.field !== "_id") {
      return onEdit(params.data._id);
    }
  }

  const onDelete = (id) => {
    dispatch(deleteUserAction(id))
    setDeletepopup(false)
  }

  useEffect(() => {
    if (deleteData && deleteData.data && deleteData.data.message === "User deleted successfully.") {
      dispatch({
        type: DELETE_USER,
        payload: []
      })
      toastSuccess(true)
      // dispatch(getAllUserAction())
    }
  }, [deleteData])

  const roleColumnDefs = [
    { field: 'name', minWidth: 150, },
    { field: 'email', minWidth: 150, },
    { field: 'password', minWidth: 150, },
    { field: 'role', minWidth: 150, },
    {
      headerName: 'Action',
      field: "_id",
      minWidth: 150,
      sortable: false,
      cellRendererFramework: (params) =>
        <div>
          <img src={editIcon} onClick={() => onEdit(params?.value)} style={{ cursor: "pointer", height: "20px" }} alt="" title="Edit" />
          <img src={removeImg} onClick={() => { setDeletepopup(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' alt="" title="Delete" />
        </div>
    }
  ]

  const NoRoleColumnDefs = [
    { field: 'name', minWidth: 150, },
    { field: 'email', minWidth: 150, },
    { field: 'role', minWidth: 150, }
  ]

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      width: 'auto',
      filter: false,
      flex: 1
    };
  }, []);

  useEffect(() => {
    if (RolesForAdd.includes(userRole)) {
      setColumnDefs(roleColumnDefs)
    } else {
      setColumnDefs(NoRoleColumnDefs)
    }
    // dispatch(getAllUserAction())
  }, [])

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const clearFilter = () => {
    setFilter({ search: '' });
    setSearch('');
  }

  const debouncedHandleSearch = useCallback(debounce((value) => {
    setFilter({ ...filter, ["search"]: value });
  }, 1000), []);

  useEffect(() => {
    debouncedHandleSearch(search);
  }, [search]);

  useEffect(() => {
    if (gridApi || deleteData?.data?.status === 200 || editData?.data?.status === 200 || filter.search) {
      const dataSource = {
        getRows: (params) => {
          const page = params.endRow / limit;
          const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
          const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

          getAllUser(page, limit, filter.search, sorted_column, sorted_order)
            .then(res => {
              // setgetAllReturnMaterialdata(res.data)
              params.successCallback(res?.data.map((ele) => {
                return {
                  name: ele.name ? ele.name : '',
                  email: ele.email ? ele.email : '',
                  password: ele.password ? ele.password : '',
                  role: ele.role ? ele.role : '',
                  _id: ele._id ? ele._id : '',
                }
              }), res.totalCount ? res.totalCount : 0)

              if (res?.data?.length <= 0) {
                gridApi.showNoRowsOverlay();
              } else {
                gridApi.hideOverlay();
              }
            })
        }
      };
      gridApi?.setDatasource(dataSource);
    }

    // setisFilter(false)
  }, [gridApi, limit, deleteData, editData, filter.search]);

  return (
    <div className='position-relative p-4'>
      <div className='mb-4 set-page-heade'>
        <div className='d-flex flex-column'>
          <h2 className='mb-0'>User</h2>
        </div>
        <div className='d-flex'>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value.trimStart())}
              autoComplete="off"
            />
          </div>
          <button className='adduser-btn ms-3' onClick={() => clearFilter()}>Clear Filter</button>
          {RolesForAdd.includes(userRole) && <button className='adduser-btn ms-3' onClick={() => { navigate('add') }}>Add User</button>}
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "70vh", width: "100%", position: 'relative' }}>

        <AgGridReact
          pagination={true}
          columnDefs={columnDefs}
          rowModelType={"infinite"}
          paginationPageSize={limit}
          cacheBlockSize={limit}
          onGridReady={onGridReady}
          rowSelection={'single'}
          onCellClicked={handleRowClick}
          rowHeight={45}
          defaultColDef={defaultColDef}
          overlayNoRowsTemplate={'<h3>No Records Found</h3>'}
        />

        <div className='set-pagination-dropdaun'>
          <p>Show</p>
          <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
            <option value="50">
              50
            </option>
            <option value="100" selected={true}>100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
          <p>Entries</p>
        </div>
      </div>
      {deletepopup &&
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={() => { onDelete(paramsValue) }}
          onCancel={() => { setDeletepopup(false); setParamsValue('') }}
          focusCancelBtn
        >
          You want to delete it
        </SweetAlert>
      }
    </div>
  )
}

export default Users