const protocol = 'http';
// const host = 'localhost';
// const host = '192.168.1.10';
// const host = '93.188.164.146';
// const host = '192.168.1.15';
const host = 'https://api.shradhhafiresafety.com';
const port = '5000';
const trailUrl = 'api';

const hostUrl = `${host}/`;
// const endpoint = `${protocol}://${host}${(port ? ':' + port : '')}`;
// const endpoint = `${protocol}://${host}${(port ? ':' + port : '')}/${trailUrl}`;
const endpoint = `${host}`;

export default {
    // protocol: protocol,
    host: host,
    // port: port,
    apiUrl: trailUrl,
    endpoint: endpoint,
    hostUrl: hostUrl,
};