import React, { useCallback, useContext, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Paginationlimit, UserRoles } from "../../helper/Constant";
import usePaginationHooks from "../../Hooks/paginationHooks";
import moment from "moment";
import { downloadFiles } from "../../helper/utils";
import { RoleContext } from "../../helper/RoleProvider";
import approveImg from "../../assets/image/check.png";
import rejectImg from "../../assets/image/reject.png";
import RejectLabourBill from "./RejectLabourBill";
import ApproveLabourBill from "./ApproveLabourBill";
import { useDispatch, useSelector } from "react-redux";
import { ApprovedOrRejectLaborBill } from "../../redux/actions/projectMaterialAction";
import { APPROVE_OR_REJECT_LABOR_BILL_RESPONSE } from "../../redux/type";
import { toast } from "react-toastify";

const LabourBillHistoryModal = ({ show, onHide, productId, delQty }) => {

    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("projectId");
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({ search: "" });
    const { getAllGenerateLabourBill, generateLabourByLabourBillInvoiceNo } = usePaginationHooks();
    const [gridApi, setGridApi] = useState(null);
    const [reject_reason, setReject_Reason] = useState({ isOpen: false, text: "", reject_data_details: null });
    const [approve_labour_bill_state, setApprove_Labour_Bill_State] = useState({ isOpen: false, approved_data_details: null });
    const [limit, setlimit] = useState(Paginationlimit);
    const userRole = useContext(RoleContext);
    const RolesForEdit = [UserRoles.Admin];
    const approved_reject_labor_bill = useSelector((state) => state.add_Project_material.approve_or_reject_labor_bill_by_admin);

    useEffect(() => {
        if (approved_reject_labor_bill && approved_reject_labor_bill.status === 200) {
            toast.success(approved_reject_labor_bill.message);
            dispatch({ type: APPROVE_OR_REJECT_LABOR_BILL_RESPONSE, payload: null });
            setReject_Reason({ isOpen: false, text: "", reject_data_details: null });
            setApprove_Labour_Bill_State({ isOpen: false, approved_data_details: null });
        }
    }, [approved_reject_labor_bill]);

    const ondownload = (labour_bill_invoice_no, total, discount) => {
        generateLabourByLabourBillInvoiceNo(labour_bill_invoice_no).then((res) => {
            downloadFiles(res.data, total, discount);
        });
    };

    const columnDefs = [
        { field: "labour_bill_invoice_no", headerName: "Invoice No." },
        { field: "labour_bill_invoice_date", headerName: "Invoice Date" },
        { field: "contractor", headerName: "Contractor Name" },
        { field: "total_labour_bill_amount", headerName: "Original Amount" },
        { field: "bill_discount", headerName: "Discount Amount" },
        { field: "grand_total", headerName: "Actual Amount" },
        {
            headerName: "Download Invoice",
            field: "file",
            sortable: false,
            cellRendererFramework: (params) => (
                <div style={{ margin: "5px auto" }}>
                    <button
                        className="edit-button"
                        style={{ height: "32px", width: "32px", lineHeight: "34px" }}
                        onClick={() => ondownload(params.data.labour_bill_invoice_no, params.data.total_labour_bill_amount, params.data.bill_discount)}
                    >
                        <img src="../../assets/image/download.png" alt="" width="15px" />
                    </button>
                </div>
            ),
        },
        {
            headerName: "Invoice Status",
            cellRendererFramework: (params) => (
                <div>
                    {RolesForEdit.includes(userRole) &&
                        params?.data?.approved_by_admin === "Pending" && (
                            <>
                                <img
                                    src={approveImg}
                                    onClick={() => setApprove_Labour_Bill_State((pre) => ({
                                        ...pre,
                                        isOpen: true,
                                        approved_data_details: { ...params?.data, final_total_of_labour_bill: params?.data?.total_labour_bill_amount }
                                    }))}
                                    style={{ cursor: "pointer", height: "20px" }}
                                    title="Approve"
                                    alt=""
                                />
                                <img
                                    src={rejectImg}
                                    onClick={() =>
                                        setReject_Reason((pre) => ({
                                            ...pre,
                                            isOpen: true,
                                            reject_data_details: params?.data,
                                        }))
                                    }
                                    style={{ cursor: "pointer", height: "20px" }}
                                    className="ms-2"
                                    title="Reject"
                                    alt=""
                                />
                            </>
                        )}
                    {!RolesForEdit.includes(userRole) && params?.data?.approved_by_admin === "Pending" && <p>Pending</p>}
                    {params?.data?.approved_by_admin === "Approved" && <p>Approved</p>}
                    {params?.data?.approved_by_admin === "Rejected" && <p>Rejected</p>}
                </div>
            ),
        },
    ];

    const debouncedHandleSearch = useCallback(
        debounce((value) => {
            setFilter({ ...filter, ["search"]: value });
        }, 1000),
        []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            sortable: true,
            width: "auto",
            filter: true,
            flex: 1,
        };
    }, []);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        if (gridApi || approved_reject_labor_bill?.status === 200) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = params?.sortModel[0]?.colId ? params?.sortModel[0]?.colId : "";
                    const sorted_order = params?.sortModel[0]?.sort ? params?.sortModel[0]?.sort : "";

                    getAllGenerateLabourBill(
                        search,
                        page,
                        limit,
                        sorted_column,
                        sorted_order,
                        projectId
                    ).then((res) => {
                        params.successCallback(
                            res.data.map((ele) => {
                                return {
                                    labour_bill_invoice_no: ele?.labour_bill_invoice_no
                                        ? ele?.labour_bill_invoice_no
                                        : "",
                                    contractor: ele?.contractor ? ele.contractor : "",
                                    labour_bill_invoice_date: ele?.labour_bill_invoice_date
                                        ? moment(ele.labour_bill_invoice_date).format("YYYY-MM-DD")
                                        : "",
                                    allProducts: ele?.allProducts ? ele.allProducts : [],
                                    approved_by_admin: ele?.approved_by_admin ?? "",
                                    total_labour_bill_amount: ele?.total_labour_bill_amount ?? 0,
                                    bill_discount: ((ele?.total_labour_bill_amount ?? 0) - (ele?.final_total_of_labour_bill ?? 0)),
                                    grand_total: ele?.final_total_of_labour_bill ?? 0,
                                };
                            }),
                            res?.totalCount ? res.totalCount : 0
                        );

                        if (res?.data?.length <= 0) {
                            gridApi?.showNoRowsOverlay();
                        } else {
                            gridApi?.hideOverlay();
                        }
                    });
                },
            };
            gridApi?.setDatasource(dataSource);
        }
    }, [gridApi, limit, filter.search, approved_reject_labor_bill]);

    const clearFilter = () => {
        setFilter({ search: "" });
        setSearch("");
    };

    const OnApprove = () => {
        if (!approve_labour_bill_state?.approved_data_details?.final_total_of_labour_bill) {
            toast.warning('Please Enter Actual Payable Amount');
        } else {
            const body = {
                approved_by_admin: "Approved",
                labour_bill_invoice_no: approve_labour_bill_state?.approved_data_details?.labour_bill_invoice_no,
                total_labour_bill_amount: approve_labour_bill_state?.approved_data_details?.total_labour_bill_amount,
                final_total_of_labour_bill: approve_labour_bill_state?.approved_data_details?.final_total_of_labour_bill,
                labour_bill_invoice_date: approve_labour_bill_state?.approved_data_details?.labour_bill_invoice_date,
                projectId
            }
            dispatch(ApprovedOrRejectLaborBill(body));
        }

    }

    const OnReject = () => {
        if (!reject_reason?.text) {
            toast.warning('Please Enter Reason!');
        } else {
            const body = {
                approved_by_admin: "Rejected",
                labour_bill_invoice_no: reject_reason?.reject_data_details?.labour_bill_invoice_no,
                final_total_of_labour_bill: reject_reason?.reject_data_details?.total_labour_bill_amount,
                rejected_reason: reject_reason?.text,
                projectId
            }
            dispatch(ApprovedOrRejectLaborBill(body));
        }
    };

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                centered
                dialogClassName="modal-90w"
                backdrop="static"
                style={approve_labour_bill_state.isOpen ? { filter: "blur(2px)" } : {}}
            >
                <Modal.Header closeButton>
                    <div className="d-flex final-material-modal-title">
                        <Modal.Title>{"Labour Bill History"}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-end border-bottom pb-3">
                        <div>
                            <input
                                type="text"
                                className="form-control search-button"
                                placeholder="Search..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value.trimStart())}
                                autoComplete="off"
                            />
                        </div>
                        <button
                            className="adduser-btn mt-2 mt-sm-0 ms-3"
                            onClick={() => clearFilter()}
                        >
                            Clear Filter
                        </button>
                    </div>
                    <div className="final-material-modal">
                        <div
                            className="ag-theme-alpine final-material-table"
                            style={{ height: 600 }}
                        >
                            <AgGridReact
                                pagination={true}
                                columnDefs={columnDefs}
                                rowModelType={"infinite"}
                                paginationPageSize={limit}
                                cacheBlockSize={limit}
                                onGridReady={onGridReady}
                                rowHeight={45}
                                defaultColDef={defaultColDef}
                                overlayNoRowsTemplate={"<h3>No Records Found</h3>"}
                            />
                            <div className="set-pagination-dropdaun">
                                <p>Show</p>
                                <select
                                    id="page-size"
                                    className="mx-3 pagination-select"
                                    value={limit}
                                    onChange={(e) => setlimit(e.target.value)}
                                >
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                </select>
                                <p>Entries</p>
                            </div>
                        </div>
                    </div>

                    {/* Reject Labour Bill Modal */}
                    <RejectLabourBill reject_reason={reject_reason} setReject_Reason={setReject_Reason} OnReject={OnReject} />

                    {/* Approve Labour Bill Modal */}
                    <ApproveLabourBill approve_labour_bill_state={approve_labour_bill_state} setApprove_Labour_Bill_State={setApprove_Labour_Bill_State} OnApprove={OnApprove} />

                </Modal.Body>
            </Modal>

        </>
    );
};

export default LabourBillHistoryModal;
